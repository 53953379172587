import Backend from '../modules/backend';
import Collection from '../_frontloader/collection';
import FasterMoment from '../modules/fasterMoment';
import moment from 'moment';
const exports = {};

/*

Example Token as of 10/7/15:

// response from POST

{
  description: "asfdasdfdsa"
  id: "56153fa4-c161-40da-9f15-f8f6ab22f85a"
  value: "56153fa4-30ac-48f5-8201-645ea1ffc560"
}

// response from GET

{
  created_at: "2015-10-07T15:54:06Z"
  description: "Zapier integration"
  id: "55423cc1-203e-49a3-8c54-12c81235a936"
  last_used: "2015-09-01T01:35:17Z"
}

*/

Collection.create('Token', exports);
exports.normalize = token => {
  if (token.last_used) {
    const hoursOld = FasterMoment.hoursOld(token.last_used);
    if (hoursOld < 24) {
      token.formatted_last_used = 'Today';
    } else {
      token.formatted_last_used = moment(token.last_used).fromNow();
    }
  }
};
exports.fetchAll = callback => {
  Backend.get('/api/private/tokens', {
    onComplete: res => {
      exports.defaultFetchAllHandler(res, callback);
    }
  });
};
exports.createToken = (data, callback) => {
  Backend.post('/api/private/tokens', {
    data,
    onComplete: res => {
      exports.defaultGetHandler(res, callback);
    }
  });
};
exports.deleteToken = (token, callback) => {
  Backend.delete('/api/private/tokens/' + token.id, {
    onComplete: (res, xhr) => {
      exports.defaultDeleteHandler(token, res, xhr, callback);
    }
  });
};
export { exports as default };