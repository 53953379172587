import { Emojify } from '@clubhouse/shared/components/Emojify';
import { TeamAutoLink } from 'components/shared/TeamAutoLink';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const TeamUpdateMembers = ({
  name,
  addedOrRemoved,
  team
}) => _jsxs("div", {
  className: "value",
  children: [_jsx("strong", {
    children: _jsx(Emojify, {
      children: name
    })
  }), addedOrRemoved === 'added' ? ' added ' : ' removed ', name === 'You' ? ' yourself ' : ' you ', addedOrRemoved === 'added' ? ' to ' : ' from ', " ", _jsx(TeamAutoLink, {
    team: team,
    showTooltip: true
  })]
});
TeamUpdateMembers.displayName = "TeamUpdateMembers";