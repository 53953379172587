import { StoryGroupByOwnerFragmentFragmentDoc } from "../../../../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { UserCohort } from '@clubhouse/shared/components/UserCohort';
import { Nouns } from '@clubhouse/shared/constants';
import { GroupByHeader } from 'components/gql/groupBy/GroupByHeader';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const StoryGroupByOwnerFragment = StoryGroupByOwnerFragmentFragmentDoc;
export const OwnerGroupHeader = ({
  owners
}) => {
  const selectedOwners = (owners?.edges || []).map(({
    node: {
      id,
      state,
      email,
      displayName,
      mentionName,
      displayIcon
    }
  }) => ({
    id,
    state,
    email_address: email,
    name: displayName,
    mention_name: mentionName,
    ...(displayIcon ? {
      display_icon: {
        url: displayIcon.thumbnailUrl
      }
    } : {})
  }));
  const avatars = _jsx(UserCohort, {
    users: selectedOwners,
    withDropdown: false
  });
  return _jsx(GroupByHeader, {
    entityType: Nouns.Owner,
    children: selectedOwners.length > 1 ? avatars : selectedOwners.length === 1 ? _jsxs(_Fragment, {
      children: [avatars, " ", selectedOwners[0].name]
    }) : 'No Owner'
  });
};
OwnerGroupHeader.displayName = "OwnerGroupHeader";