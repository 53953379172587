import rearg from 'lodash/rearg';

/*

Example installation entity:

{
  application_id: "5971f483-2f95-4347-81a3-d022b87cebd1"
  created_at: "2017-07-21T15:49:38Z"
  entity_type: "installation"
  id: "59722292-9571-4f96-8187-1ca3c726385f"
  organization_id: "5390b4a4-5b91-4e27-b80b-879e2762bf69"
  updated_at: "2017-07-21T15:49:38Z"
  uninstalled: false
}

*/

import ApplicationModel from './application';
import BaseUtils from '../_frontloader/baseUtils';
import Backend from '../modules/backend';
import Collection from '../_frontloader/collection';
import CompanyModel from './company';
import _ from 'lodash';
const exports = {};
Collection.create('Installation', exports);
exports.isValid = installation => {
  return !!(installation && installation.id && installation.entity_type === 'installation');
};
exports.isInstalled = installation => {
  return installation && !installation.uninstalled;
};
exports.getByApplicationId = id => {
  return exports.get({
    application_id: id
  });
};
exports.getMultipleByApplicationId = id => {
  return exports.search({
    application_id: id
  });
};
exports.getByApplicationAndOrgIds = (applicationId, orgId) => {
  return exports.get({
    application_id: applicationId,
    organization_id: orgId
  });
};
exports.getZendesk = () => {
  return exports.getByApplicationId(ApplicationModel.getZendeskId());
};
exports.getSentry = () => {
  return exports.getByApplicationId(ApplicationModel.getSentryId());
};
exports.getBitbucket = () => {
  return exports.getByApplicationId(ApplicationModel.APPLICATION_IDS.Bitbucket);
};
exports.getGitlab = () => {
  return exports.getByApplicationId(ApplicationModel.APPLICATION_IDS.Gitlab);
};
exports.getProductboard = () => {
  return exports.getByApplicationId(ApplicationModel.APPLICATION_IDS.Productboard);
};
exports.isGitHubEnabled = () => {
  return exports.getMultipleByApplicationId(ApplicationModel.APPLICATION_IDS.Github).filter(i => i.disabled === false).length > 0;
};
exports.isProductboardEnabled = () => {
  const productboardInstallation = exports.getProductboard();
  return productboardInstallation && !productboardInstallation.disabled;
};
exports.fetchAll = callback => {
  Backend.get('/api/private/installations', {
    onComplete: res => {
      exports.defaultFetchAllHandler(res, callback, false);
    }
  });
};
exports.getInstallationsForUser = (byApplicationId = null) => {
  return new Promise((resolve, reject) => {
    Backend.get('/api/private/installations/user', {
      onComplete: res => {
        if (res.error) {
          reject(res.error);
        } else {
          if (byApplicationId) {
            res = res.filter(i => i.application_id === byApplicationId);
          }
          resolve(res);
        }
      }
    });
  });
};
exports.installCopyToCurrentWorkspace = id => {
  return new Promise((resolve, reject) => {
    Backend.post('/api/private/integrations/github/install/copy', {
      data: {
        id
      },
      onComplete: res => {
        if (res.error) {
          reject(res.error);
        } else {
          resolve(res);
        }
      }
    });
  });
};
exports.fetchByApplicationId = (id, callback, options) => {
  _fetchInstallation(id, callback, options);
};
exports.disableByApplicationId = (id, callback) => {
  const installationId = exports.getByApplicationId(id);
  exports.disable(installationId, callback);
};
exports.enable = (installation, callback) => {
  const endpoint = `/api/private/applications/${installation.application_id}/installations/${installation.id}/enable`;
  Backend.put(endpoint, {
    onComplete: res => {
      exports.defaultGetHandler(res, callback);
    }
  });
};
exports.disable = (installation, callback) => {
  const endpoint = `/api/private/applications/${installation.application_id}/installations/${installation.id}/disable`;
  Backend.put(endpoint, {
    onComplete: res => {
      exports.defaultGetHandler(res, callback);
    }
  });
};
exports.delete = (installation, callback) => {
  const endpoint = `/api/private/installations/${installation.id}`;
  Backend.delete(endpoint, {
    onComplete: res => {
      exports.defaultDeleteHandler(res, callback);
    }
  });
};
exports.update = (installation, data, callback) => {
  const endpoint = `/api/private/applications/${installation.application_id}/installations/${installation.id}`;
  Backend.put(endpoint, {
    data,
    onComplete: res => {
      exports.defaultGetHandler(res, callback);
    }
  });
};
exports.fetchZendesk = callback => {
  exports.fetchByApplicationId(ApplicationModel.APPLICATION_IDS.Zendesk, callback);
};
exports.fetchBitbucket = callback => {
  exports.fetchByApplicationId(ApplicationModel.APPLICATION_IDS.Bitbucket, callback);
};
exports.fetchGitlab = callback => {
  exports.fetchByApplicationId(ApplicationModel.APPLICATION_IDS.Gitlab, callback);
};
exports.fetchInstallationForOrg = (applicationId, orgId, callback) => {
  exports.fetchByApplicationId(applicationId, callback, {
    company_id: _.get(CompanyModel.getFromOrg({
      id: orgId
    }), 'id'),
    organization_id: orgId
  });
};
exports.installInstallationForOrg = (applicationId, orgId, data, callback) => {
  exports.installByApplicationId(applicationId, callback, {
    data: data,
    company_id: _.get(CompanyModel.getFromOrg({
      id: orgId
    }), 'id'),
    organization_id: orgId
  });
};
exports.installByApplicationId = (id, callback, options) => {
  _install(id, callback, options);
};
exports.installZendesk = callback => {
  exports.installByApplicationId(ApplicationModel.getZendeskId(), callback);
};
exports.uninstallZendesk = (installationId, callback) => {
  _uninstall(ApplicationModel.getZendeskId(), installationId, callback);
};
exports.reinstallZendesk = (installationId, callback) => {
  _reinstall(ApplicationModel.getZendeskId(), installationId, callback);
};
function _fetchInstallation(applicationId, callback, options) {
  const endpoint = '/api/private/applications/' + applicationId + '/installations';
  Backend.get(endpoint, _.assign({}, options, {
    onComplete: res => {
      exports.defaultGetHandler(res, callback, false);
    }
  }));
}
function _install(applicationId, callback, options) {
  const endpoint = '/api/private/applications/' + applicationId + '/installations';
  Backend.post(endpoint, _.assign({}, options, {
    onComplete: res => {
      exports.defaultGetHandler(res, callback);
    }
  }));
}
function _uninstall(applicationId, installationId, callback, options) {
  const endpoint = '/api/private/applications/' + applicationId + '/installations/' + installationId + '/uninstall';
  Backend.put(endpoint, _.assign({}, options, {
    onComplete: res => {
      exports.defaultGetHandler(res, callback);
    }
  }));
}
function _reinstall(applicationId, installationId, callback, options) {
  const endpoint = '/api/private/applications/' + applicationId + '/installations/' + installationId + '/reinstall';
  Backend.put(endpoint, _.assign({}, options, {
    onComplete: res => {
      exports.defaultGetHandler(res, callback);
    }
  }));
}
exports.Promises = {};
exports.Promises.fetchAll = BaseUtils.promisify(exports.fetchAll);
exports.Promises.fetchByApplicationId = BaseUtils.promisify(exports.fetchByApplicationId);
exports.Promises.installByApplicationId = BaseUtils.promisify(exports.installByApplicationId);
exports.Promises.installByApplicationId = BaseUtils.promisify(exports.installByApplicationId);
exports.Promises.installByApplicationId = BaseUtils.promisify(exports.installByApplicationId);
exports.Promises.installByApplicationIdWithOptions = BaseUtils.promisify(rearg(exports.installByApplicationId, [0, 2, 1]));
exports.Promises.disableByApplicationId = BaseUtils.promisify(exports.disableByApplicationId);
exports.Promises.delete = BaseUtils.promisify(exports.delete);
exports.Promises.update = BaseUtils.promisify(exports.update);
export { exports as default };