import { FIELD_ID } from 'components/shared/table/types';
import { TableDate } from 'components/shared/table/base';
import { lastUpdated } from 'utils/sort';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export function LastUpdatedDateFieldComponent({
  entity
}) {
  return _jsx(TableDate, {
    value: entity.updated_at || entity.created_at
  });
}
LastUpdatedDateFieldComponent.displayName = "LastUpdatedDateFieldComponent";
export const LastUpdatedDateField = () => ({
  name: FIELD_ID.LAST_UPDATED_DATE,
  displayName: 'Last Updated',
  sort: lastUpdated,
  Component: LastUpdatedDateFieldComponent,
  width: 110
});