import moment from 'moment';
import { useState } from 'react';
import { Button } from '@clubhouse/shared/components/Button';
import { Tooltip } from '@clubhouse/shared/components/Tooltip';
import { getOldStoriesCompletedBefore, isLoadingOldStories, setLoadingOldStories, setOldStoriesCompletedBefore } from 'data/entity/column';
import { fetchOldCompletedStories } from 'data/entity/story';
import { drawStories } from 'utils/storiesView';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const LoadOldStoriesButton = ({
  column
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleLoadMore = () => {
    if (isLoadingOldStories(column)) {
      return;
    }
    setLoadingOldStories(column, true);
    setIsLoading(true);
    const lastCompletedBefore = getOldStoriesCompletedBefore(column) || moment();
    fetchOldCompletedStories({
      lastCompletedBefore,
      workflowStateId: column.id
    }, nextCompletedBefore => {
      setOldStoriesCompletedBefore(column, nextCompletedBefore);
      setLoadingOldStories(column, false);
      drawStories();
      setIsLoading(false);
    });
  };
  if (isLoading) {
    return _jsxs(Button, {
      fullWidth: true,
      isDisabled: true,
      kind: Button.KIND.TERTIARY,
      children: [_jsx("span", {
        className: "fa fa-spinner fa-spin"
      }), "Loading..."]
    });
  }
  return _jsx(Tooltip, {
    content: "Stories that were moved to done over 30 days ago are not loaded by default to keep the UI fast.",
    children: _jsxs(Button, {
      fullWidth: true,
      kind: Button.KIND.GHOST,
      onClick: handleLoadMore,
      children: ["Load 30 More Days ", _jsx("span", {
        className: "fa fa-question-circle"
      })]
    })
  });
};
LoadOldStoriesButton.displayName = "LoadOldStoriesButton";