// eslint-disable-next-line no-restricted-imports -- Required as we are wrapping the useQuery hook here
import { NetworkStatus, useQuery as _useQuery } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import { useCallback, useMemo } from 'react';
import { useDebounce, useForceUpdate } from '@clubhouse/shared/hooks';
import { createErrorHandler } from '../utils/error';
export const useQuery = (query, {
  onRerun,
  ...options
} = {}) => {
  const {
    startPolling,
    stopPolling,
    ...returnValue
  } = _useQuery(query, {
    fetchPolicy: 'cache-and-network',
    onError: createErrorHandler(getOperationName(query) ?? 'Unknown', options),
    ...options
  });
  const rerun = useRerenderOnNextTick(onRerun);

  // biome-ignore lint/correctness/useExhaustiveDependencies: We only want initial value
  const isInitialDataFromCache = useMemo(() => Boolean(returnValue.data) && returnValue.networkStatus === NetworkStatus.loading, []);
  return {
    ...returnValue,
    rerun,
    isInitialDataFromCache
  };
};
const useRerenderOnNextTick = onRerun => {
  const forceUpdate = useForceUpdate();
  return useDebounce(useCallback(() => {
    forceUpdate();
    onRerun?.();
  }, [forceUpdate, onRerun]), 0);
};