import { useRef } from 'react';
import { Button } from '@clubhouse/shared/components/Button';
import { Spaced } from '@clubhouse/shared/components/Spaced';
import { ToastText, useToast } from '@clubhouse/shared/components/Toast';
import { sanitizeAndEmojify } from '@clubhouse/shared/utils';
import { trigger } from 'utils/event';
import { addColumn, removeColumn } from 'utils/storiesView';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const HideToastMessage = ({
  name,
  undo
}) => _jsxs(Spaced, {
  vertically: true,
  amount: 8,
  children: [_jsxs(ToastText, {
    children: ["State ", _jsx("strong", {
      dangerouslySetInnerHTML: {
        __html: sanitizeAndEmojify(name)
      }
    }), " hidden. To unhide, click on the state at the bottom of the left sidebar."]
  }), _jsx(Button, {
    onClick: undo,
    kind: Button.KIND.SECONDARY,
    children: "Undo"
  })]
});
HideToastMessage.displayName = "HideToastMessage";
const UndoHideToastMessage = ({
  name
}) => _jsxs(ToastText, {
  children: ["State ", _jsx("strong", {
    dangerouslySetInnerHTML: {
      __html: sanitizeAndEmojify(name)
    }
  }), " unhidden."]
});
UndoHideToastMessage.displayName = "UndoHideToastMessage";
export const useHideColumn = column => {
  const toastRef = useRef(null);
  const {
    addToast,
    removeToast
  } = useToast();
  const hideColumn = () => {
    removeColumn(column);
    trigger('spaceUpdate');
    toastRef.current = addToast({
      kind: 'success',
      timeout: 15000,
      Content: () => _jsx(HideToastMessage, {
        name: column.name,
        undo: undoHideColumn
      })
    });
  };
  const undoHideColumn = () => {
    addColumn(column, 1);
    trigger('spaceUpdate');
    if (toastRef.current) removeToast(toastRef.current);
    addToast({
      timeout: 5000,
      kind: 'success',
      Content: () => _jsx(UndoHideToastMessage, {
        name: column.name
      })
    });
  };
  return hideColumn;
};