import { isScopedToTeam } from 'components/team-navigation';
import { IterationStatusDropdown } from 'components/shared/IterationStatusDropdown';
import { GroupFilterDropdown } from 'components/groups/GroupFilterDropdown';
import { getLoggedInUserPermissionID } from 'data/entity/user';
import { ShowAssociated } from 'components/shared/ShowAssociated';
import { getTeamScopedCollectionizeId } from 'data/entity/group';
import { IterationAutomationsCTA } from 'components/iterations/IterationAutomationsCTA';
import { useFilters } from './useFilters';
import { TextInput } from '@clubhouse/shared/components/TextInput';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
export const Filters = ({
  onFilterChange
}) => {
  const teamId = getTeamScopedCollectionizeId();
  const shouldRenderTeamsFilter = !isScopedToTeam();
  const {
    filters,
    setFilters: _setFilters
  } = useFilters();
  const setFilters = newFilters => {
    _setFilters(newFilters);
    onFilterChange();
  };
  return _jsx("div", {
    id: "iterations-page-filters",
    children: _jsx("div", {
      className: "page-filters flex-filters",
      children: _jsxs("div", {
        className: "filter-group",
        children: [_jsxs("div", {
          className: "filter",
          children: [_jsx("div", {
            className: "filter-label",
            children: _jsx("span", {
              children: "Filter"
            })
          }), _jsxs("div", {
            className: `search-query-parent ${filters.query ? 'has-query' : ''}`,
            children: [_jsx(TextInput, {
              id: "page-search-query",
              placeholder: "Filter Iterations by name",
              value: filters.query,
              onChange: value => {
                setFilters({
                  query: value || ''
                });
              },
              onKeyDown: e => {
                if (e.key === 'Escape') setFilters({
                  query: ''
                });
              },
              size: TextInput.SIZE.MEDIUM
            }), _jsx("button", {
              className: "clear-query-button",
              onClick: () => setFilters({
                query: ''
              }),
              children: _jsx("span", {
                className: "fa fa-times"
              })
            }), _jsx("span", {
              className: "fa fa-search"
            })]
          })]
        }), _jsxs("div", {
          className: "filter react-filter",
          children: [_jsx("div", {
            className: "filter-label",
            children: _jsx("span", {
              children: "States"
            })
          }), _jsx(IterationStatusDropdown, {
            selectedValues: filters.statuses,
            onChange: newStatuses => {
              setFilters({
                statuses: newStatuses
              });
            }
          })]
        }), shouldRenderTeamsFilter && _jsxs("div", {
          className: "filter react-filter",
          children: [_jsx("div", {
            className: "filter-label",
            children: _jsx("span", {
              children: "Teams"
            })
          }), _jsx(GroupFilterDropdown, {
            selectedGroupIds: filters.groupIds,
            onItemsSelected: newGroupIds => {
              setFilters({
                groupIds: newGroupIds
              });
            },
            currentProfileId: getLoggedInUserPermissionID(),
            includeNoGroup: true,
            noGroupTriggerText: "Iterations with No Team"
          })]
        }), _jsx("div", {
          className: "filter react-filter",
          children: _jsx(ShowAssociated, {
            isChecked: filters.showAssociated,
            content: teamId ? _jsx(_Fragment, {
              children: "Show other teams' iterations which contain stories owned by this team."
            }) : _jsx(_Fragment, {
              children: "Show other teams' iterations which contain stories owned by the filtered teams."
            }),
            onClick: newValue => {
              setFilters({
                showAssociated: newValue
              });
            }
          })
        }), _jsx("div", {
          id: "iteration-automations-cta",
          children: _jsx(IterationAutomationsCTA, {})
        })]
      })
    })
  });
};
Filters.displayName = "Filters";