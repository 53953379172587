import { useMemo } from 'react';
import { FilterField } from '@clubhouse/shared/components/FilterField';
import { useNonClearableFilterState } from 'components/gql/filters/hooks/useFilterState';
import { createSearchParamConfig } from 'components/gql/utils/createSearchParamConfigs';
import { GroupBySelect } from 'components/shared/table/GroupBySelect';
import { GROUP_BY, useGroupByOptions } from 'components/shared/table/GroupBySelect/stories_graphql';
import { SORT_COLUMN_VALUES } from './table/groupBy';
import { useBacklogTableSortUrlState } from './table/useBacklogTableSortUrlState';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const STATIC_GROUP_BY_VALUES = [GROUP_BY.EPIC, GROUP_BY.ITERATION, GROUP_BY.OWNER, GROUP_BY.TEAM, GROUP_BY.TYPE, GROUP_BY.STATE, GROUP_BY.WORKFLOW];
const CUSTOM_FIELDS_GROUP_BY_VALUES = [GROUP_BY.PRIORITY, GROUP_BY.PRODUCT_AREA, GROUP_BY.SEVERITY, GROUP_BY.SKILLSET, GROUP_BY.TECHNICAL_AREA];
const VALID_GROUP_BY_VALUES = [...STATIC_GROUP_BY_VALUES, ...CUSTOM_FIELDS_GROUP_BY_VALUES];
export const BacklogGroupByConfig = createSearchParamConfig({
  paramName: 'grouped_by',
  paramType: 'string',
  defaultValue: GROUP_BY.NONE,
  validValues: VALID_GROUP_BY_VALUES
});
export function BacklogGroupBySelect({
  enabledCustomFields
}) {
  const [groupBy, setGroupBy] = useNonClearableFilterState(BacklogGroupByConfig);
  const {
    sortColumn: currentSortColumn,
    onSortChange
  } = useBacklogTableSortUrlState({
    validValues: SORT_COLUMN_VALUES
  });
  const excludedCustomFields = useMemo(() => CUSTOM_FIELDS_GROUP_BY_VALUES.filter(value => value !== groupBy && !enabledCustomFields[value]), [groupBy, enabledCustomFields]);
  const groupByOptions = useGroupByOptions(excludedCustomFields);
  const handleGroupByChange = newValue => {
    if (newValue !== GROUP_BY.STATE && currentSortColumn === 'position') {
      onSortChange('name');
    }
    setGroupBy(newValue);
  };
  return _jsx(FilterField, {
    label: "Group By",
    children: _jsx(GroupBySelect, {
      items: groupByOptions,
      value: groupBy,
      onChange: handleGroupByChange
    })
  });
}
BacklogGroupBySelect.displayName = "BacklogGroupBySelect";