import CardModel from 'app/client/core/js/models/card';
import CompanyModel from 'app/client/core/js/models/company';
import InviteModel from 'app/client/core/js/models/invite';
import InvoicesModel from 'app/client/core/js/models/invoices';
import PaymentPlan2Model from 'app/client/core/js/models/paymentPlan2';
import UserModel from 'app/client/core/js/models/user';
import Async from 'app/client/core/js/modules/async';
import Backend from 'app/client/core/js/modules/backend';
import Is from 'app/client/core/js/modules/is';
import isString from 'lodash/isString';
import useAsync from 'react-use/lib/useAsync';
import { getStripe } from 'utils/globalObject';
import { error as logError } from './log';
const stripeUrl = 'https://js.stripe.com/v2/';

// NOTE: something is not necessarily a promise (can be a jqXHR object), so don't do `something instanceof Promise`
function isPromise(something) {
  return !!something && typeof something === 'object' && 'then' in something;
}
const loadOwnerDependencies = () => {
  const getKeyPromise = Backend.get('/api/private/owner/payment-plan-2/provider-publishable-key', {
    excludeOrgHeader: true
  });
  if (isPromise(getKeyPromise)) {
    getKeyPromise.then(res => {
      const Stripe = getStripe();
      if (!Stripe?.card?.createToken) {
        logError(new Error('The Stripe.js library was probably blocked by a browser extension.'));
      }
      if (res && isString(res.key)) {
        Stripe?.setPublishableKey(res.key);
      }
    }).then(() => Promise.all([UserModel.Promises.fetchAll(), InviteModel.Promises.fetchAll(), PaymentPlan2Model.Promises.fetch().catch(err => {
      logError(new Error('Failed to fetch payment plan', {
        cause: err
      }), {
        errorInfo: JSON.stringify(err)
      });
    }), InvoicesModel.Promises.fetch(), CompanyModel.Promises.fetch(CompanyModel.getCurrentID())])).then(() => CardModel.Promises.fetchAll());
  }
};
const loadDependencies = () => Is.ownerOnly(UserModel.getLoggedInUserPermission()) ? loadOwnerDependencies() : PaymentPlan2Model.Promises.fetch();
export const useStripe = ({
  force = false
} = {}) => {
  const Stripe = getStripe();
  const {
    loading,
    error,
    value
  } = useAsync(async () => {
    if (!Stripe || force) {
      await Async.Promises.loadScriptAndThen(stripeUrl, 'Stripe').then(loadDependencies);
    }
    return true;
  }, [force]);
  return {
    loading,
    ready: value,
    error,
    stripe: getStripe()
  };
};