import { Emojify } from '@clubhouse/shared/components/Emojify';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const getSection = (_, options) => ({
  items: options.map(({
    node: {
      id,
      name
    }
  }) => ({
    value: id,
    name: _jsx(Emojify, {
      children: name
    }),
    additionalData: {
      category: {
        id,
        name
      }
    }
  }))
});