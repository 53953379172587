import "core-js/modules/es.array.push.js";
import { useMemo } from 'react';
import { useMultiInlineEditing } from '@clubhouse/shared/components/Table';
import { TableOwners } from 'components/shared/table/base';
import { FIELD_ID } from 'components/shared/table/types';
import { useGroup } from 'data/entity/group';
import { getAllActiveProfileDetails, getCurrentUserProfileDetails, getProfileById, getProfileDetails } from 'data/entity/profile';
import { saveChanges } from 'data/entity/story';
import { owners } from 'utils/sort';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const OwnersFieldComponent = ({
  entity
}) => {
  const [selectedOwners, handleChange, handleClose, {
    loading
  }] = useMultiInlineEditing(entity.owner_ids.map(String), ownerIds => saveChanges(entity.id, {
    owner_ids: ownerIds
  }));
  const currentUser = getCurrentUserProfileDetails();
  const users = useMemo(() => {
    const activeUsers = getAllActiveProfileDetails();
    for (const selectedOwner of selectedOwners) {
      if (!activeUsers.find(user => user.id === selectedOwner)) {
        const profile = getProfileById(selectedOwner);
        activeUsers.push(getProfileDetails(profile));
      }
    }
    return activeUsers;
  }, [selectedOwners]);
  const team = useGroup({
    id: entity.group_id
  });
  const teamMemberIds = useMemo(() => team?.member_ids ?? [], [team]);
  return _jsx(TableOwners, {
    isSelectionDisabled: loading,
    currentUser: currentUser,
    label: "Update Story Owners",
    onChange: handleChange,
    onClose: handleClose,
    users: users,
    selectedValues: selectedOwners,
    teamMemberIds: teamMemberIds
  });
};
OwnersFieldComponent.displayName = "OwnersFieldComponent";
export const OwnersField = () => ({
  name: FIELD_ID.OWNERS,
  displayName: 'Owners',
  sort: owners,
  headerProps: {
    centered: true
  },
  Component: OwnersFieldComponent,
  width: 110
});