import { StoryGroupBySkillSetFragmentFragmentDoc } from "../../../../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { Nouns } from '@clubhouse/shared/constants';
import { GroupByHeader } from 'components/gql/groupBy/GroupByHeader';
import { SkillSetWithIcon } from 'components/gql/skillSet/SkillSetSelectTarget';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const StoryGroupBySkillSetFragment = StoryGroupBySkillSetFragmentFragmentDoc;
export const SkillSetGroupHeader = ({
  skillSet
}) => _jsx(GroupByHeader, {
  entityType: Nouns.SkillSet,
  children: typeof skillSet?.stringValue === 'string' && typeof skillSet.colorKey === 'string' ? _jsx(SkillSetWithIcon, {
    spacing: 12,
    stringValue: skillSet.stringValue,
    colorKey: skillSet.colorKey
  }) : 'No Skill Set'
});
SkillSetGroupHeader.displayName = "SkillSetGroupHeader";