import { useCallback } from 'react';
import { getTeamScopedURL } from 'components/team-navigation';
import { createBackgroundLocationState, getCurrentBackgroundLocationState } from './backgroundLocationState';
import { useReplaceState } from './useReplaceState';
export const useSetBackgroundPageTeamId = () => {
  const replaceState = useReplaceState();
  return useCallback(({
    teamId
  }) => replaceState(({
    state
  }) => {
    const {
      backgroundLocation
    } = getCurrentBackgroundLocationState(state);
    if (!backgroundLocation) {
      return {};
    }
    return {
      state: createBackgroundLocationState(getTeamScopedURL(backgroundLocation.href, teamId))
    };
  }), [replaceState]);
};