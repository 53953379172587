import { EpicLastUpdatedDateFieldFragmentFragmentDoc } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { TableDate } from 'components/shared/table/base';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const EpicLastUpdatedDateFieldFragment = EpicLastUpdatedDateFieldFragmentFragmentDoc;
function LastUpdatedDateFieldComponent({
  entity: {
    effectiveUpdatedAt
  }
}) {
  return _jsx(TableDate, {
    value: effectiveUpdatedAt
  });
}
LastUpdatedDateFieldComponent.displayName = "LastUpdatedDateFieldComponent";
export const LastUpdatedDateField = () => ({
  name: 'effectiveUpdatedAt',
  sort: true,
  displayName: 'Last Updated',
  Component: LastUpdatedDateFieldComponent,
  width: 110
});