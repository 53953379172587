import EventEmitter from 'eventemitter3';
import { useEffect, useMemo, useRef, useState } from 'react';
import { InternalTableColumnSelect } from '@clubhouse/shared/components/Table/TableColumnSelect';
import { orderColumns } from '@clubhouse/shared/components/Table/TableStateProvider';
import { emptyArray } from '@clubhouse/shared/utils';
import { useAppState } from 'utils/appState';

// This whole file is a hack to get around the fact that Milestones and Stories pages render their tables in Cavemean.
// That makes it harder to share state between the dropdown and the table. This solves that using a event emitter.
// Once those two tables are rendering filters + tables as a single React structure, this can be thrown out.
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const emitter = new EventEmitter();
const subscribe = (eventName, fn) => {
  emitter.addListener(eventName, fn);
  return () => {
    emitter.removeListener(eventName, fn);
  };
};
const emit = (eventName, ...args) => {
  emitter.emit(eventName, ...args);
};
export const useCavemanDisabledColumns = (keyPrefix, defaultDisabledColumns = []) => {
  return useAppState({
    appStateKey: `${keyPrefix}.disabledColumns`,
    default: defaultDisabledColumns
  });
};
export const useCavemanOrderedColumns = keyPrefix => {
  return useAppState({
    appStateKey: `${keyPrefix}.columnOrder`,
    default: []
  });
};
export const useCavemanColumnConfig = (keyPrefix, allColumns) => {
  const allColumnsRef = useRef(allColumns);
  useEffect(() => {
    allColumnsRef.current = allColumns;
    subscribe(`${keyPrefix}.requestAllColumns`, () => {
      emit(`${keyPrefix}.allColumns`, allColumnsRef.current);
    });
    emit(`${keyPrefix}.allColumns`, allColumns);
  }, [keyPrefix, allColumns]);
};
export function CavemanTableColumnSelect({
  keyPrefix,
  defaultDisabledColumns = emptyArray
}) {
  const [allColumns, setAllColumns] = useState([]);
  const [disabledColumns, setDisabledColumns] = useCavemanDisabledColumns(keyPrefix, defaultDisabledColumns);
  const [orderedColumns, setOrderedColumns] = useCavemanOrderedColumns(keyPrefix);
  useEffect(() => {
    const unsubscribe = subscribe(`${keyPrefix}.allColumns`, allColumns => setAllColumns(allColumns));
    emit(`${keyPrefix}.requestAllColumns`);
    return unsubscribe;
  }, [keyPrefix]);
  const columns = useMemo(() => orderColumns(allColumns, orderedColumns), [allColumns, orderedColumns]);
  return _jsx(InternalTableColumnSelect, {
    allColumns: columns,
    disabledColumns: disabledColumns,
    onToggle: values => setDisabledColumns(values),
    onReorder: columns => setOrderedColumns(columns)
  });
}
CavemanTableColumnSelect.displayName = "CavemanTableColumnSelect";