import { StoryGroupByPriorityFragmentFragmentDoc } from "../../../../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { Nouns } from '@clubhouse/shared/constants';
import { GroupByHeader } from 'components/gql/groupBy/GroupByHeader';
import { PriorityWithIcon } from 'components/gql/priority/PriorityWithIcon';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const StoryGroupByPriorityFragment = StoryGroupByPriorityFragmentFragmentDoc;
export const PriorityGroupHeader = ({
  priority
}) => _jsx(GroupByHeader, {
  entityType: Nouns.Priority,
  children: typeof priority?.stringValue === 'string' && typeof priority?.position === 'number' ? _jsx(PriorityWithIcon, {
    spacing: 12,
    stringValue: priority.stringValue,
    position: priority.position
  }) : 'No Priority'
});
PriorityGroupHeader.displayName = "PriorityGroupHeader";