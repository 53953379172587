export const createScrollSync = () => {
  let scrollHandler;
  let scrollLeft = 0;
  let previousLeaderNode;
  const destroyScrollSync = () => {
    previousLeaderNode?.removeEventListener('scroll', scrollHandler);
  };
  const initScrollSync = ({
    leaderNode,
    followerNodes
  }) => {
    destroyScrollSync();
    previousLeaderNode = leaderNode;
    [leaderNode, ...followerNodes].forEach(node => {
      node.scrollLeft = scrollLeft;
    });
    let timeout;
    scrollHandler = () => {
      if (timeout) {
        window.cancelAnimationFrame(timeout);
      }
      timeout = window.requestAnimationFrame(() => {
        scrollLeft = leaderNode.scrollLeft;
        followerNodes.forEach(n => {
          n.scrollLeft = scrollLeft;
        });
      });
    };
    leaderNode.addEventListener('scroll', scrollHandler, {
      passive: true
    });
  };
  return {
    initScrollSync,
    destroyScrollSync
  };
};