import { useMemo } from 'react';
import { isModalPath } from './paths';
const isValidBackgroundLocation = pathname => {
  if (isModalPath(pathname)) {
    return false;
  }
  return true;
};
export const createBackgroundLocationState = ({
  pathname,
  href,
  search
} = window.history.state?.backgroundLocation ?? window.location) => {
  if (!isValidBackgroundLocation(pathname)) {
    return {
      backgroundLocation: undefined
    };
  }
  return {
    backgroundLocation: {
      title: document.title,
      pathname,
      href,
      search
    }
  };
};
export const getCurrentBackgroundLocationState = (state = window.history.state) => {
  const {
    backgroundLocation
  } = state ?? {};
  return {
    backgroundLocation
  };
};
const clearCurrentBackgroundLocationState = () => {
  if (history.state?.backgroundLocation) {
    delete history.state.backgroundLocation;
  }
};
export const useClearBackgroundLocationStateOnPageRefresh = () => {
  useMemo(() => {
    clearCurrentBackgroundLocationState();
  }, []);
};