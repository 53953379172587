import CardModel from 'app/client/core/js/models/card';
import CompanyModel from 'app/client/core/js/models/company';
import OrganizationModel from 'app/client/core/js/models/organization';
import UserModel from 'app/client/core/js/models/user';
import { production } from 'app/client/core/js/modules/env';
import Is from 'app/client/core/js/modules/is';
import { getCurrentPlan, getPlanLevel, getPlanStatus } from 'data/entity/paymentPlan2';
import { NOOP_MONITOR, initializeAsync } from '../utils';
export const NAME = 'Pendo';
export const shouldInitialize = ({
  env
}) => env !== 'test';
export const shouldOwnEvent = () => false;
export const shouldOwnNamedEvent = () => false;
export const initialize = (_, {
  onError
}) => {
  const {
    result,
    callWhenReady
  } = initializeAsync(() => import(/* webpackChunkName: "pendo" */'static/lib/pendo'), {
    onError
  });
  return {
    ...NOOP_MONITOR,
    setSessionAttributes: callWhenReady(attrs => {
      if (result.current) {
        const {
          default: initPendo
        } = result.current;
        setSessionAttributes(initPendo, attrs);
      }
    })
  };
};
const setSessionAttributes = (initPendo, {
  userId,
  workspaceId,
  organizationId
}) => {
  const user = UserModel.getById(userId);
  const paymentPlan = getCurrentPlan();
  const workspace = OrganizationModel.getById(workspaceId);
  const organization = CompanyModel.getById(organizationId);
  if (!UserModel.isValid(user) || !paymentPlan || !workspace || !organization) return;
  const trialingStatus = getPlanStatus('trialing', paymentPlan);
  const options = {
    // This disables cookies to be compliant with the EU cookie law.
    // Ref: https://help.pendo.io/resources/support-library/api/index.html?bash#options
    disablePersistence: true,
    visitor: {
      id: user.id,
      email: UserModel.getPreferredEmailAddress(),
      name: user.name,
      profession: user.profession,
      appTheme: user.app_theme,
      appNavigation: user.app_navigation || 'Unknown',
      role: UserModel.getRoleOfCurrentUser()
    },
    account: {
      id: organization.id,
      name: organization.name,
      disabled: organization.disabled,
      planLevel: getPlanLevel(paymentPlan),
      orgCreatedAt: workspace.created_at,
      orgLocation: workspace.location || 'Unknown',
      orgSlug: workspace.url_slug,
      billableUsers: paymentPlan.active_seats,
      isActiveTrial: !!trialingStatus,
      trialDaysLeft: trialingStatus ? trialingStatus.trial_days_remaining : 0,
      trialEnd: trialingStatus ? new Date(trialingStatus.trial_end).toISOString() : '',
      trialStart: trialingStatus ? new Date(trialingStatus.trial_start).toISOString() : ''
    },
    disableGuides: !production()
  };
  if (Is.ownerOnly(UserModel.getLoggedInUserPermission(workspace.id))) {
    getCardData(workspace, cardData => {
      initPendo({
        ...options,
        account: {
          ...options.account,
          ...cardData
        }
      });
    });
  } else {
    initPendo(options);
  }
};
const getCardData = (workspace, callback) => {
  CardModel.fetchAll(() => {
    const card = CardModel.getCardForOrganization(workspace.id);
    callback({
      cardWasAdded: !!card,
      cardExpirationDate: CardModel.getExpirationDateForCard(card)
    });
  });
};