import { StoryNameFieldFragmentFragmentDoc } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { getHref } from 'components/gql/stories/links';
import { TableNameText, TableNameWithLabelsAndBlockers } from 'components/shared/table/base';
import { NameField as SharedNameField } from 'components/shared/table/fields';
import { navigateOnClick } from 'utils/navigation';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const StoryNameFieldFragment = StoryNameFieldFragmentFragmentDoc;
const NameFieldComponent = ({
  entity
}) => {
  const {
    publicId,
    name
  } = entity;
  const url = getHref(publicId, name);
  return _jsx(TableNameWithLabelsAndBlockers, {
    url: url,
    onClick: navigateOnClick(url),
    children: _jsx(TableNameText, {
      text: name
    })
  });
};
NameFieldComponent.displayName = "NameFieldComponent";
export const NameField = () => SharedNameField({
  Component: NameFieldComponent,
  width: 450,
  sort: true,
  headerProps: {
    fullWidth: true
  }
});