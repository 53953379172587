import Backend from './backend';
import MessageController from '../controllers/message';
const exports = {};
exports.start = (query, options = {}, format = null) => {
  Backend.post('/api/private/exports', {
    ...options,
    data: format ? {
      query,
      format
    } : {
      query
    },
    onSuccess: () => {
      MessageController.success('Your export has started.', {
        secondary: 'When the export completes, you will receive an email with a link to download the exported data.<br><br>' + 'This export will reflect the stories associated with the most up to date version of this Space from the legacy Stories Page.'
      });
    },
    onError: response => {
      MessageController.error('Unable to start export.', {
        secondary: response.error
      });
    }
  });
};
export { exports as default };