import "core-js/modules/es.array.push.js";
import * as SkinTonesTemplate from 'app/client/core/views/templates/skinTones.html';
import AssetManifest from 'static/manifest/asset-manifest.json';
import UserModel from '../models/user';
import Constants from '../modules/constants';
import Emoji from '../modules/emoji';
import Format from '../modules/format';
import Tooltip from '../modules/tooltip';
import AutocompleteController from './autocomplete';
import FloatingCaption from '../modules/floatingCaption';
import Utils from '../modules/utils';
import _ from 'lodash';
const exports = {};
exports.open = function (onApply) {
  const defaultTone = 'tone0';
  const tone = UserModel.getUserProperties().skin_tone || defaultTone;
  const items = [];
  Object.entries(Constants.EMOJI_GROUPS).forEach(([name, group]) => {
    if (items.length > 0) {
      items.push({
        hr: true
      });
    }
    items.push({
      html: `<div class="caption">${name}</div>`,
      isResult: false
    });
    Emoji.setAsSprite();
    group.forEach((emoji, i, emojis) => {
      const next = emojis[i + 1];
      const isDefaultTone = next && next.replace('_tone1', '') === emoji;
      let currentTone = '';
      if (isDefaultTone) {
        currentTone = defaultTone;
      } else if (_.includes(emoji, '_tone')) {
        currentTone = emoji.substr(emoji.length - 5);
        emoji = Emoji.mapOldToneToNew(emoji);
      }
      items.push({
        name: `${Format.emojiNameToEmoji(emoji)}<span class="hidden">:${emoji}:</span>`,
        value: `:${emoji}:`,
        displayValue: `:${emoji}:`,
        className: currentTone ? `tone ${currentTone}` : ''
      });
    });
    Emoji.setAsSingleImage();
  });
  Tooltip.close();
  const autocomplete = AutocompleteController.open({
    title: 'Add emoji',
    className: 'emoji-grid ' + tone,
    noActive: true,
    items,
    footer: getSkinTonesMarkup(tone),
    onApply,
    onOpen: function () {
      bindShortnameToggle.call(this);
    },
    onClose: function () {
      const listElement = AutocompleteController.dropdownElement.find('.list');
      FloatingCaption.destroy(listElement);
      unbindShortnameToggle.call(this);
    },
    showInput: true,
    keepOpen: true,
    target: this,
    topOffset: 4,
    width: 384
  });

  // User may have clicked on the emoji button again with the autocomplete already open,
  // which would have closed it this time. So we should guard against that case.
  if (autocomplete) {
    const listElement = AutocompleteController.dropdownElement.find('.list');
    FloatingCaption.init({
      context: listElement,
      topOffset: () => AutocompleteController.dropdownElement.offset().top
    });
  }
  return false;
};
exports.toggleSkinTone = function () {
  const tone = Utils.data(this, 'tone');
  $('.skin-tone-toggle').removeClass('selected');
  $(this).addClass('selected').closest('.emoji-grid').removeClass(Constants.EMOJI_MODIFIERS.join(' ')).addClass(tone);
  UserModel.updateSkinTone(tone);
  return false;
};
function getSkinTonesMarkup(currentTone) {
  const src = `${AssetManifest.emojiPath}/64/tone0.png?v=2.2.6`;
  const customModifiers = [{
    name: `<img class="emojione" alt="yellow" src="${src}">`,
    value: 'tone0',
    active: currentTone === 'tone0'
  }];
  const data = customModifiers.concat(_.map(Constants.EMOJI_MODIFIERS, modifier => {
    return {
      name: Format.emojiNameToEmoji(modifier),
      value: modifier,
      active: currentTone === modifier
    };
  }));
  return SkinTonesTemplate.render(data);
}
function bindShortnameToggle() {
  $(this).on('mouseenter', '.item', function () {
    $('#emoji-shortname').text(this.dropdownValue);
  }).on('mouseleave', '.item', () => {
    $('#emoji-shortname').empty();
  });
}
function unbindShortnameToggle() {
  $(this).off('mouseenter mouseleave', '.item');
}
export { exports as default };