import { useState } from 'react';
import { isLoggedInUserObserver } from 'data/entity/user';
import { StoryGroupDropdown } from './StoryGroupDropdown';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const ExistingStoryGroupEditor = ({
  story,
  onChange
}) => {
  const [saving, setSaving] = useState(false);
  const handleGroupChange = async group => {
    setSaving(true);
    await onChange(group);
    setSaving(false);
  };
  return _jsx(StoryGroupDropdown, {
    story: story,
    onChange: handleGroupChange,
    isDisabled: saving || isLoggedInUserObserver()
  });
};
ExistingStoryGroupEditor.displayName = "ExistingStoryGroupEditor";