export const createOptimisticStoryMutationResponse = (id, change, updateField = 'storyUpdate') => ({
  __typename: 'Mutation',
  [updateField]: {
    __typename: 'StoryPayload',
    story: {
      __typename: 'Story',
      id,
      ...change
    }
  }
});
export const createOptimisticEpicMutationResponse = (id, change) => ({
  __typename: 'Mutation',
  epicUpdate: {
    __typename: 'EpicPayload',
    epic: {
      __typename: 'Epic',
      id,
      ...change
    }
  }
});
export const createOptimisticObjectiveMutationResponse = (id, change) => ({
  __typename: 'Mutation',
  objectiveUpdate: {
    __typename: 'ObjectivePayload',
    objective: {
      __typename: 'Objective',
      id,
      ...change
    }
  }
});
export const createOptimisticKeyResultMutationResponse = (id, change) => ({
  __typename: 'Mutation',
  objectiveUpdate: {
    __typename: 'KeyResultPayload',
    objective: {
      __typename: 'KeyResult',
      id,
      ...change
    }
  }
});