import * as ReferralCopyFormTemplate from 'app/client/core/views/templates/referralCopyForm.html';
import * as ReferralShareTemplate from 'app/client/core/views/templates/referralShare.html';
import * as ReferralStatusTemplate from 'app/client/core/views/templates/referralStatus.html';
import * as ReferralsTemplate from 'app/client/core/views/templates/referrals.html';
import * as ReferralHelpTooltipTemplate from 'app/client/core/views/templates/referralHelpTooltip.html';
import * as IconGiftTemplate from 'app/client/core/views/templates/icons/icon-gift.html';
import * as ReferralCardTemplate from 'app/client/core/views/templates/referralCard.html';
import * as ReferralBinaryRewardTemplate from 'app/client/core/views/templates/referralBinaryReward.html';
import * as ReferralComplexRewardTemplate from 'app/client/core/views/templates/referralComplexReward.html';
import * as ReferralBasicRewardTemplate from 'app/client/core/views/templates/referralBasicReward.html';
import calendarPng from '@clubhouse/assets/png/referrals-calendar.png';
import giftPng from '@clubhouse/assets/png/referrals-gift.png';
import shapesPng from '@clubhouse/assets/png/shapes-side-by-side.png';
import Ajax from '../modules/ajax';
import Backend from '../modules/backend';
import CompanyModel from '../models/company';
import FlashController from './flash';
import Is from '../modules/is';
import Log from '../modules/log';
import MessageController from './message';
import OrganizationModel from '../models/organization';
import HelpController from './help';
import ProfileModel from '../models/profile';
import User from '../modules/user';
import UserModel from '../models/user';
import UserWarning from './userWarning';
import SideDrawer from '../modules/sideDrawer';
import Utils from '../modules/utils';
import _ from 'lodash';
const exports = {};
let readToken, writeToken;
function getTenantAlias() {
  return Is.production() ? 'a7okt8a5lwawk' : 'test_a5uc0b9jxxy24';
}
const fetchReferralProps = user => {
  return new Promise((resolve, reject) => {
    Backend.get('/api/private/permission/saasquatch', {
      onComplete: (res, xhr) => {
        if (xhr.status >= 200 && xhr.status < 400) {
          readToken = res.jwt_read;
          writeToken = res.jwt_write;
          resolve(_.assign({}, user, {
            referralCode: res.user.referralCode
          }));
        } else {
          reject(res);
        }
      }
    });
  });
};
const upsertUserToSaasquatch = userWithSaasquatchData => {
  return new Promise(resolve => {
    window.squatch.ready(() => {
      window.squatch.init({
        tenantAlias: getTenantAlias()
      });
      const member = ProfileModel.getCurrentUserRawProfile();
      const {
        email_address,
        name
      } = member.profile;
      const company = CompanyModel.getFromOrg(OrganizationModel.getById(member.org_id));
      // Note: the structure of this object has to perfectly match the content of the
      // jwt created on the backend, or the request to saasquatch will fail with a 403
      // ie, if the backend adds an 'email' property to the user data, we need to add it here:
      const saasquatchRequestPromise = window.squatch.widgets().upsertUser({
        user: {
          id: member.profile.id,
          accountId: company.id,
          referralCode: userWithSaasquatchData.referralCode,
          email: email_address,
          customFields: {
            name
          }
        },
        jwt: writeToken
      });
      resolve(saasquatchRequestPromise);
    });
  });
};
const fetchExistingAndPendingReferrals = saasquatchUserData => {
  const {
    user
  } = saasquatchUserData;
  return new Promise((resolve, reject) => {
    Ajax.ajax('GET', `https://app.referralsaasquatch.com/api/v1/${getTenantAlias()}/open/referrals?referringAccountId=${user.accountId}&referringUserId=${user.id}`, {
      headers: {
        'X-SaaSquatch-User-Token': readToken
      },
      withCredentials: false,
      onComplete: (res, xhr) => {
        if (xhr.status >= 200 && xhr.status < 400) {
          resolve({
            user: saasquatchUserData,
            referrals: res.referrals
          });
        } else {
          reject({
            res,
            xhr
          });
        }
      }
    });
  });
};
const consolidateReferralData = saasquatchUserAndReferralData => {
  const {
    user,
    referrals
  } = saasquatchUserAndReferralData;
  const notCancelled = referrals.filter(referral => {
    // Pull out referrals that haven't been explicitly ended
    return !referral.dateReferralEnded;
  });
  const [achieved, pending] = _.partition(notCancelled, referral => {
    // Split referrals that haven't been cancelled by their paid status
    return !!referral.dateReferralPaid;
  });
  return new Promise(resolve => {
    user.referrals = {
      pending: pending.length,
      achieved: achieved.length
    };
    resolve(user);
  });
};
const displayReferralLink = saasquatchAPIResponse => {
  $('#referral-copy').html(ReferralCopyFormTemplate.render(saasquatchAPIResponse));
  return saasquatchAPIResponse;
};
const displayReferralShare = saasquatchAPIResponse => {
  $('#referral-share').html(ReferralShareTemplate.render(saasquatchAPIResponse));
  return saasquatchAPIResponse;
};
const displayReferralStatus = saasquatchAPIResponse => {
  $('#referral-status').html(ReferralStatusTemplate.render(saasquatchAPIResponse));
  return saasquatchAPIResponse;
};
const displayErrors = error => {
  Log.error(error);
  $('#referral-copy').html('');
  $('#referral-errors').html('<div class="server-messages"></div>');
  FlashController.error('#referral-errors', 'Unable to load referral information.', error.message);
};
exports.open = () => {
  if (!CompanyModel.canReferOthers()) {
    Log.log('Refferal dialog prevented because organization cannot refer');
    return;
  }
  const user = User.getCurrentUser();
  SideDrawer.render({
    title: 'Earn Credits',
    width: 'var(--sideDrawerWidthMedium)',
    description: 'Earn rewards for referring your friends',
    innerHTML: `<div class="referrals-dialog">${ReferralsTemplate.render()}</div>`,
    onMount: () => {
      fetchReferralProps(user).then(upsertUserToSaasquatch).then(displayReferralLink).then(displayReferralShare).then(fetchExistingAndPendingReferrals).then(consolidateReferralData).then(displayReferralStatus).catch(displayErrors);
    },
    breadcrumbs: [{
      text: 'Help & Feedback',
      href: HelpController.route(),
      onClick: HelpController.open
    }, {
      text: 'Earn Credits'
    }],
    backgroundImage: {
      src: shapesPng,
      alt: 'Shapes'
    }
  });
  if (Is.mobile()) {
    $('#content').css({
      display: 'none'
    });
  }
  return false;
};
const TRIGGER_SELECTOR = '#referral-link';
const TRIGGER_VISIBLE_CLASS = 'visible';
exports.dismiss = () => {
  UserWarning.open({
    target: $(TRIGGER_SELECTOR),
    title: 'Are you sure you want to dismiss this referral button?',
    description: 'If you remove this button, Referral can still be found in the Settings dropdown and in the Help section.',
    question: '',
    onApprove: function () {
      $(this).find('.fa-times').removeClass('fa-times').addClass('fa-star fa-spin');
      UserModel.dismissReferralAction(err => {
        if (!err) {
          $(TRIGGER_SELECTOR).fadeOut(400, () => {
            $(TRIGGER_SELECTOR).removeClass(TRIGGER_VISIBLE_CLASS);
          });
        } else {
          MessageController.error(err, {
            secondary: 'Unable to dismiss the referral button.'
          });
        }
      });
    }
  });
};
exports.statusTooltipText = () => {
  return ReferralHelpTooltipTemplate.render();
};
exports.getReferralIcon = () => IconGiftTemplate.render();
exports.getReferralText = () => {
  const permission = UserModel.getLoggedInUserPermission();
  if (Is.admin(permission)) {
    return 'Earn Credit';
  }
  return 'Earn Rewards';
};
exports.getYourCard = () => {
  const permission = UserModel.getLoggedInUserPermission();
  const isAdmin = Is.admin(permission);
  return ReferralCardTemplate.render({
    title: isAdmin ? 'Your organization will earn...' : "You'll earn...",
    image: {
      src: giftPng,
      alt: 'Gift'
    },
    content: isAdmin ? ReferralBinaryRewardTemplate.render({
      rewardOne: '$500 Account Credit',
      rewardTwo: 'A $500 Team Dinner',
      description: 'Terms apply - The referred Org must pay for two months of positive invoices after the free period.'
    }) : ReferralComplexRewardTemplate.render()
  });
};
exports.getFriendsCard = () => {
  return ReferralCardTemplate.render({
    title: 'Your friend will receive...',
    image: {
      src: calendarPng,
      alt: 'Calendar'
    },
    content: ReferralBasicRewardTemplate.render({
      title: '2 Months',
      subtitle: 'Free',
      description: 'Your referral gets two months free on any paid plan.'
    })
  });
};
exports.openHelp = () => {
  if (CompanyModel.canReferOthers()) {
    exports.open();
  } else {
    Utils.openInNewTabWithNoopener(`https://${BRAND.DOMAIN_CORP_WEBSITE}/blog/refer-anyone-get-rewards-q4-2021/`);
  }
  return false;
};
exports.init = () => {
  const {
    ignore_referrals
  } = UserModel.getUserProperties();
  if (CompanyModel.canReferOthers() && !ignore_referrals) {
    $(TRIGGER_SELECTOR).addClass(TRIGGER_VISIBLE_CLASS);
  }
};
export { exports as default };