import "core-js/modules/esnext.set.difference.v2.js";
import "core-js/modules/esnext.set.intersection.v2.js";
import "core-js/modules/esnext.set.is-disjoint-from.v2.js";
import "core-js/modules/esnext.set.is-subset-of.v2.js";
import "core-js/modules/esnext.set.is-superset-of.v2.js";
import "core-js/modules/esnext.set.symmetric-difference.v2.js";
import "core-js/modules/esnext.set.union.v2.js";
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMapState } from '@clubhouse/shared/hooks/useMapState';
import { createContext, useContext, useContextSelector } from 'use-context-selector';
import { logError } from 'utils/monitoring';
import { useWorkspaceSlug } from 'utils/navigation';
import { useLazyStoriesInGroupQuery } from '../shared/useStoriesInGroupQuery';
import { useDataLayerUpdateEvent } from 'gql/components/updates/useDataLayerUpdateEvent';
import { useSetState, useToggleState } from '@clubhouse/shared/hooks';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const Context = createContext(null);
const getEmptyResult = () => ({
  stories: [],
  segments: [],
  headerAggregations: [],
  headers: [],
  totalSize: null
});
const createMapWithTracking = (map, onChange) => {
  return new Proxy(map, {
    get: (target, key) => {
      const prop = Reflect.get(target, key);
      if (key === 'set') {
        function setValue(key, value) {
          onChange(key);
          return prop.call(target, key, value);
        }
        return setValue;
      }
      return prop.bind(target);
    }
  });
};
const useBlockableChanges = ({
  onChange
}) => {
  const blockers = useMapState();
  const isBlockedGroup = useCallback(groupId => blockers.values().some(s => s.has(groupId)), [blockers]);
  const [isBlocked, toggleBlocked] = useToggleState(false, () => onChange(isBlockedGroup));
  return {
    isBlocked,
    isBlockedGroup,
    addBlockers: useCallback(() => {
      const uniqueKey = {};
      const blockedKeys = new Set();
      return {
        add: key => {
          blockedKeys.add(key);
          blockers.set(uniqueKey, blockedKeys);
          toggleBlocked.on();
        },
        resolve: key => {
          blockedKeys.delete(key);
          if (blockedKeys.size === 0) blockers.remove(uniqueKey);
          if (blockers.isEmpty) toggleBlocked.off();
        },
        resolveAll: () => {
          blockedKeys.clear();
          blockers.remove(uniqueKey);
          if (blockers.isEmpty) toggleBlocked.off();
        }
      };
    }, [blockers, toggleBlocked])
  };
};
export function GroupStoriesProvider({
  children,
  spaceId
}) {
  const fetchStories = useLazyStoriesInGroupQuery();
  const groupMap = useMapState();
  const currentDataMap = useMapState();
  const abortControllerRef = useRef(null);
  const slug = useWorkspaceSlug();
  const pendingFetches = useSetState();
  const {
    addBlockers,
    isBlocked,
    isBlockedGroup
  } = useBlockableChanges({
    onChange: isBlocked => {
      const allPendingGroupIds = pendingFetches.toArray();
      allPendingGroupIds.forEach(groupId => {
        if (!isBlocked(groupId)) {
          pendingFetches.remove(groupId);
          fetchStoriesInGroup({
            groupId,
            notifyOnData: true
          });
        }
      });
    }
  });
  const fetchStoriesInGroup = useCallback(async ({
    groupId,
    notifyOnData
  }) => {
    const group = groupMap.get(groupId);
    if (!group) return null;
    if (isBlockedGroup(groupId)) {
      if (notifyOnData) pendingFetches.add(groupId);
      return null;
    }
    try {
      const newData = await fetchStories({
        variables: {
          slug,
          spaceId,
          input: {
            groupId,
            orderBy: group.orderBy ? [group.orderBy] : undefined,
            limit: group.limit
          }
        }
      });

      // Check if the group became blocked while we were fetching
      if (isBlockedGroup(groupId)) {
        if (notifyOnData) pendingFetches.add(groupId);
        return null;
      }
      if (notifyOnData) {
        currentDataMap.set(groupId, newData);
        group.onDataChange(newData);
      }
      return newData;
    } catch (err) {
      console.debug(`Failed to fetch stories in group "${groupId}"`, err);
      if (err instanceof Error) logError(err);
      if (notifyOnData) {
        currentDataMap.set(groupId, getEmptyResult());
        group.onDataChange(getEmptyResult());
      }
      return null;
    }
  }, [pendingFetches, fetchStories, slug, spaceId, groupMap, currentDataMap, isBlockedGroup]);

  // This returns a tuple of the group's latest data and the group metadata
  const getGroup = useCallback(groupId => [currentDataMap.get(groupId) || getEmptyResult(), groupMap.get(groupId) || null], [currentDataMap, groupMap]);
  const registerGroup = useCallback(({
    groupId,
    limit,
    orderBy,
    onDataChange
  }) => {
    groupMap.set(groupId, {
      groupId,
      orderBy,
      limit,
      onDataChange
    });

    // If registerGroup is called multiple times for same group, we only use the latest fetch.
    const abortController = new AbortController();
    fetchStoriesInGroup({
      groupId,
      notifyOnData: false
    }).then(data => {
      if (abortController.signal.aborted) return;
      const newData = data || getEmptyResult();
      currentDataMap.set(groupId, newData);
      onDataChange(newData);
    });
    return () => {
      abortController.abort();
      groupMap.remove(groupId);
      currentDataMap.remove(groupId);
    };
  }, [fetchStoriesInGroup, currentDataMap, groupMap]);
  const updateOptimistically = useCallback(updaterFn => {
    abortControllerRef.current?.abort();
    const {
      add,
      resolveAll
    } = addBlockers();
    const snapshot = currentDataMap.clone();
    const map = currentDataMap.clone();
    const trackedMap = createMapWithTracking(map, add);
    updaterFn(trackedMap);
    groupMap.forEach(({
      groupId,
      onDataChange
    }) => {
      const newGroupData = map.get(groupId) || getEmptyResult();
      currentDataMap.set(groupId, newGroupData);
      onDataChange(newGroupData);
    });
    return {
      undo: () => {
        groupMap.forEach(({
          groupId,
          onDataChange
        }) => {
          const oldGroupData = snapshot.get(groupId) || getEmptyResult();
          currentDataMap.set(groupId, oldGroupData);
          onDataChange(oldGroupData);
        });
        resolveAll();
      },
      commit: () => {
        resolveAll();
      }
    };
  }, [currentDataMap, groupMap, addBlockers]);
  return _jsx(Context.Provider, {
    value: useMemo(() => ({
      registerGroup,
      fetchStoriesInGroup,
      updateOptimistically,
      getGroup,
      abortControllerRef,
      isBlocked,
      isBlockedGroup
    }), [registerGroup, updateOptimistically, fetchStoriesInGroup, getGroup, isBlocked, isBlockedGroup]),
    children: children
  });
}
GroupStoriesProvider.displayName = "GroupStoriesProvider";
export function useGroupStories({
  groupId,
  limit,
  orderBy,
  skip
}) {
  const context = useContext(Context);
  if (!context) throw new Error('useGroupStories must be used within a GroupStoriesProvider');
  const [data, setData] = useState(getEmptyResult());
  const {
    registerGroup,
    fetchStoriesInGroup
  } = context;
  useEffect(() => {
    if (skip) return;
    return registerGroup({
      groupId,
      limit,
      orderBy,
      onDataChange: setData
    });
  }, [skip, groupId, limit, registerGroup, orderBy]);
  useDataLayerUpdateEvent(() => fetchStoriesInGroup({
    groupId,
    notifyOnData: true
  }), {
    filter: ['Space', 'WIPLimit'],
    disable: skip
  });
  const fetchStories = useCallback(() => fetchStoriesInGroup({
    groupId,
    notifyOnData: true
  }), [groupId, fetchStoriesInGroup]);
  return {
    data,
    fetchStories
  };
}
export const useAbortControllerRef = () => useContextSelector(Context, context => {
  if (!context) throw new Error('useAbortControllerRef must be used within a GroupStoriesProvider');
  return context.abortControllerRef;
});
export const useGetGroup = () => useContextSelector(Context, context => {
  if (!context) throw new Error('useGetGroup must be used within a GroupStoriesProvider');
  return context.getGroup;
});
export const useOptimisticUpdateMaybe = () => useContextSelector(Context, context => {
  return context?.updateOptimistically || null;
});
export const useOptimisticUpdate = () => useContextSelector(Context, context => {
  if (!context) throw new Error('useOptimisticUpdate must be used within a GroupStoriesProvider');
  return context.updateOptimistically;
});
export const useIsBlocked = () => useContextSelector(Context, context => {
  if (!context) throw new Error('useIsBlocked must be used within a GroupStoriesProvider');
  return context.isBlocked;
});
export const useGetIsBlockedGroup = () => useContextSelector(Context, context => {
  if (!context) throw new Error('useGetIsBlockedGroup must be used within a GroupStoriesProvider');
  return context.isBlockedGroup;
});
export const useIsBlockedGroup = groupId => useContextSelector(Context, context => {
  if (!context) throw new Error('useIsBlockedGroup must be used within a GroupStoriesProvider');
  return context.isBlockedGroup(groupId);
});