import { StoryMoreActionsFieldFragmentDoc } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { MoreActions } from '@clubhouse/shared/components/MoreActions';
import { ArchiveStoryAction } from 'components/gql/stories/actions/ArchiveStoryAction';
import { CopyStoryLinkAction } from 'components/gql/stories/actions/CopyStoryLinkAction';
import { GoToStoryAction } from 'components/gql/stories/actions/GoToStoryAction';
import { PinStoryAction } from 'components/gql/stories/actions/PinStoryAction';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const StoryMoreActionsFieldFragment = StoryMoreActionsFieldFragmentDoc;
function MoreActionsFieldComponent({
  entity
}) {
  return _jsxs(MoreActions, {
    children: [_jsx(MoreActions.TableButton, {}), _jsxs(MoreActions.Menu, {
      children: [_jsx(GoToStoryAction, {
        story: entity
      }), _jsx(MoreActions.Divider, {}), _jsx(PinStoryAction, {
        story: entity
      }), _jsx(CopyStoryLinkAction, {
        story: entity
      }), _jsx(MoreActions.Divider, {}), _jsx(ArchiveStoryAction, {
        story: entity
      })]
    })]
  });
}
MoreActionsFieldComponent.displayName = "MoreActionsFieldComponent";
export const MoreActionsField = ({
  frozenOverride
} = {}) => ({
  name: 'more_actions',
  displayName: '',
  Component: MoreActionsFieldComponent,
  LoadingComponent: null,
  width: 44,
  frozen: frozenOverride || {
    right: -30
  }
});