import "core-js/modules/es.array.push.js";
import * as UserAutocompleteTemplate from 'app/client/core/views/templates/userAutocomplete.html';
import AutocompleteController from './autocomplete';
import EmojiPicker from './emojiPicker';
import Format from '../modules/format';
import InPlaceTextareaController from './inPlaceTextarea';
import ProfileModel from '../models/profile';
import StorySearchController from './storySearch';
import Tooltip from '../modules/tooltip';
import Utils from '../modules/utils';
import _ from 'lodash';
const exports = {};
exports.openEmoji = function () {
  const selector = '#' + $(this).closest('.in-place-textarea').attr('id');
  EmojiPicker.open.call(this, text => {
    exports.applyToTextarea(selector, text + ' ');
  });
  return false;
};
exports.openFile = function () {
  $(this).closest('.helpers').find('.textarea-helper-file-upload').click();
  return false;
};
exports.openMention = function () {
  const selector = '#' + $(this).closest('.in-place-textarea').attr('id');
  const items = [];
  ProfileModel.getAllActiveProfileDetails().forEach(profileDetails => {
    items.push({
      name: UserAutocompleteTemplate.render(profileDetails),
      displayValue: '@' + profileDetails.mention_name,
      value: profileDetails
    });
  });
  Tooltip.close();
  AutocompleteController.open({
    title: 'Mention someone',
    noActive: true,
    items: _.sortBy(items, 'value'),
    onApply: profileDetails => {
      if (ProfileModel.isValid(profileDetails)) {
        const customMarkdown = Format.generateCustomMentionMarkdown(profileDetails) + ' ';
        exports.applyToTextarea(selector, customMarkdown, Format.convertCustomMarkdownMentionsToNaked);
      }
    },
    showInput: true,
    target: this,
    width: 260
  });
  return false;
};
exports.openStoryLink = function () {
  const selector = '#' + $(this).closest('.in-place-textarea').attr('id');
  const items = [];
  Tooltip.close();
  AutocompleteController.open({
    title: 'Add a story reference',
    noActive: true,
    items,
    onFilter: query => {
      StorySearchController.filterUsingElasticSearch(query);
    },
    onApply: text => {
      exports.applyToTextarea(selector, '#' + text + ' ');
    },
    maxVisibleItems: 50,
    showInput: true,
    target: this,
    width: 320
  });
  return false;
};
exports.openMarkdown = function () {
  const selector = '#' + $(this).closest('.in-place-textarea').attr('id');
  const items = [{
    name: '<h1># Header</h1>',
    value: '\n\n# Header\n\n'
  }, {
    name: '<h2>## Subheader</h2>',
    value: '\n\n## Subheader\n\n'
  }, {
    name: '<h3>### Subheader</h3>',
    value: '\n\n### Subheader\n\n'
  }, {
    name: '[Link Name](URL)',
    value: '[Link Name](URL) '
  }, {
    name: '![Image Name](URL)',
    value: '\n\n![Image Name](URL)\n\n'
  }, {
    name: '<em>*italics*</em>',
    value: '*italics* '
  }, {
    name: '<strong>**bold**</strong>',
    value: '**bold** '
  }, {
    name: '<code>`inline code`</code>',
    value: '`inline code` '
  }, {
    name: '<pre><code>```code block```</code></pre>',
    value: '\n\n```\ncode block\n```\n\n'
  }, {
    name: '<hr />',
    value: '\n\n---\n\n'
  }, {
    name: '<ul><li>List Item 1</li><li>List Item 2</li></ul>',
    value: '\n\n- List Item 1\n- List Item 2\n\n'
  }, {
    name: '<ol><li>List Item 1</li><li>List Item 2</li></ol>',
    value: '\n\n1. List Item 1\n2. List Item 2\n\n'
  }, {
    name: '<table class="markdown-preview"><thead><tr><th>First Header</th><th>Second Header</th></tr></thead><tbody><tr><td>Content Cell</td><td>Content Cell</td></tr></tbody></table>',
    value: '\n\n| First Header  | Second Header |\n| ------------- | ------------- |\n| Content Cell  | Content Cell |\n\n'
  }];
  Tooltip.close();
  AutocompleteController.open({
    title: 'Add markdown formatting',
    className: 'markdown-formatted',
    noActive: true,
    items,
    onApply: text => {
      exports.applyToTextarea(selector, text);
    },
    showInput: true,
    target: this,
    width: 260
  });
  return false;
};
exports.applyToTextarea = (selector, value, cursorTransform) => {
  const parent = $(selector);
  const textarea = parent.find('textarea');
  let caretPosition = Utils.data(textarea, 'caret-position');
  if (!_.isNumber(caretPosition)) {
    caretPosition = textarea.val().length;
  }
  parent.find('.in-place-textarea-editor-tab').click();

  // Deferring here so the autocomplete can finish closing
  _.defer(() => {
    InPlaceTextareaController.insertValueAndUpdateCustomLinks({
      element: $(selector).find('textarea'),
      value,
      caretPosition,
      charsToReplace: 0,
      lengthTransform: cursorTransform
    });
  });
};
export { exports as default };