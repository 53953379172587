import { useContextMenu } from '@clubhouse/shared/components/ContextMenu';
import { logContextMenuAction, logContextMenuClose, logContextMenuOpen } from './logging';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const createEventLogger = (loggingContext, logOptionalEvents) => (eventType, details) => {
  if (eventType === 'open') {
    logContextMenuOpen(loggingContext);
  } else if (eventType === 'close') {
    logContextMenuClose(loggingContext);
  } else if (eventType === 'action') {
    logContextMenuAction({
      ...loggingContext,
      action: details
    });
    logOptionalEvents?.();
  }
};
export const useContextMenuWithLogging = (Menu, loggingContext) => {
  return useContextMenu(Menu ? p => ({
    Menu: props => _jsx(Menu, {
      ...p,
      ...props
    }),
    onEvent: createEventLogger(loggingContext)
  }) : null);
};
export const useContextMenuWithLoggingOptionalEvents = (Menu, loggingContext, logOptionalEvents) => {
  return useContextMenu(Menu ? p => ({
    Menu: props => _jsx(Menu, {
      ...p,
      ...props
    }),
    onEvent: createEventLogger(loggingContext, logOptionalEvents)
  }) : null);
};
export const useDynamicContextMenuWithLogging = getMenuAndLoggingContext => {
  return useContextMenu((...args) => {
    const data = getMenuAndLoggingContext(...args);
    if (!data) return null;
    const {
      loggingContext,
      Menu
    } = data;
    return {
      Menu,
      onEvent: createEventLogger(loggingContext)
    };
  });
};