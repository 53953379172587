import * as AddNewLabelTemplate from 'app/client/core/views/templates/addNewLabel.html';
import * as AddNewLabelActionsTemplate from 'app/client/core/views/templates/addNewLabelActions.html';
import * as ButtonSavingStateTemplate from 'app/client/core/views/templates/buttonSavingState.html';
import { EVENT_TYPES, dispatchEvent } from 'utils/collectionizeToApolloMessageBus';
import ColorPickerController from './colorPicker';
import DropdownController from './dropdown';
import MessageController from './message';
import PanelController from './panel';
import * as Event from '../_frontloader/event';
import LabelModel from '../models/label';
import UserModel from '../models/user';
import Constants from '../modules/constants';
import Format from '../modules/format';
import Is from '../modules/is';
import LocalStorage from '../modules/localStorage';
import Utils from '../modules/utils';
import _ from 'lodash';
const exports = {};
const PANEL_ID = 'addNewLabel';
exports.LOCALSTORAGE_KEY = 'newLabelState';
exports.getState = () => {
  return Utils.parseJSON(LocalStorage.get(exports.LOCALSTORAGE_KEY, {
    prefix: true
  })) || exports.newTemplate();
};
exports.updateState = updates => {
  const state = exports.getState();
  _.assignIn(state, updates);
  LocalStorage.set(exports.LOCALSTORAGE_KEY, state, {
    prefix: true
  });
};
exports.newTemplate = () => {
  return {
    // We're only persisting color so individual users can maintain defaults
    color: LabelModel.getDefaultColor()
  };
};
exports.open = () => {
  if (Is.readOnly(UserModel.getLoggedInUserPermission())) {
    return false;
  }

  // The user may have the Create Story menu open at this point.
  DropdownController.closeAll();
  const panel = PanelController.open({
    html: AddNewLabelTemplate.render(exports.getState()),
    id: PANEL_ID,
    showCloseButton: false,
    target: document.getElementById('add-new-button'),
    width: 240,
    onOpen: panel => {
      if (panel && panel.element) panel.element.find('input').first().focus();
    }
  });
  if (!panel || !panel.element) {
    return false;
  }
  return false;
};
exports.openColorPicker = function () {
  const context = this;
  const color = _.get(exports.getState(), 'color');
  ColorPickerController.open({
    activeValue: _.includes(Constants.LABEL_COLORS, color) ? color : LabelModel.getDefaultColor(),
    context,
    onApply: exports.updateColor.bind(context),
    palette: Constants.LABEL_COLORS
  });
};
exports.updateColor = function (color) {
  exports.updateState({
    color
  });
  $(this).find('.fa-square').css({
    color
  });
};
exports.save = () => {
  const input = $('.add-new-label input');
  const newLabel = _.assign({}, exports.getState(), {
    name: input.val().trim()
  });
  if (LabelModel.exists(newLabel)) {
    MessageController.error('A label with that name already exists.', {
      secondary: 'Unable to create <strong>' + Format.sanitize(newLabel.name) + '</strong>.'
    });
    input.focus();
  } else if (newLabel.name) {
    $('.add-new-label .form-actions .action.green').replaceWith(ButtonSavingStateTemplate.render());
    LabelModel.createNewLabel(newLabel, err => {
      if (err) {
        $('.add-new-label .form-actions').html(AddNewLabelActionsTemplate.render());
        MessageController.error(err, {
          secondary: 'Unable to create <strong>' + Format.sanitize(name) + '</strong>.'
        });
      } else {
        MessageController.success('Label <em>' + Format.sanitize(name) + '</em> created.');
        PanelController.closeById(PANEL_ID);
        Event.trigger('NewLabelCreated', name);
        dispatchEvent(EVENT_TYPES.LABEL_CREATED);
      }
    });
  } else {
    input.focus();
  }
  return false;
};
exports.close = () => {
  PanelController.closeById(PANEL_ID);
  return false;
};
export { exports as default };