import { MutateSeverityFieldDocument } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { SeverityFieldFragmentFragmentDoc } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { QueryStorySeveritySelectDocument } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { SeverityIcon } from '@useshortcut/shapes-ds';
import { useCallback } from 'react';
import { gql } from '@clubhouse/datalayer';
import { Center } from '@clubhouse/shared/components/Center';
import { NONE_VALUE, Nouns } from '@clubhouse/shared/constants';
import { SEVERITY_ICONS } from '@clubhouse/shared/utils/customFields';
import { GroupedOptionsFieldSingleSelection } from 'components/gql/GroupedOptionsField';
import { SeverityDisabled, SeveritySelectTarget } from 'components/gql/severity/SeveritySelectTarget';
import { createOptimisticStoryMutationResponse } from 'components/gql/utils/mutation';
import { useMutation } from 'gql';
import { useFieldOptionsQuery } from './useFieldOptionsQuery';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const QUERY_STORY_SEVERITY_SELECT = QueryStorySeveritySelectDocument;
export const SeverityFieldFragment = SeverityFieldFragmentFragmentDoc;
const getSection = (groupKey, options) => ({
  sectionId: groupKey,
  items: options.map(option => ({
    value: option.node.id,
    name: option.node.stringValue,
    Icon: () => _jsx(SeverityIcon, {
      icon: SEVERITY_ICONS[option.node.position]
    })
  }))
});
const MUTATE_STORY_SEVERITY_FIELD = MutateSeverityFieldDocument;
const SeverityFieldComponent = ({
  entity: {
    id: storyId,
    type,
    severity
  }
}) => {
  const fetchStorySeverity = useFieldOptionsQuery({
    query: QUERY_STORY_SEVERITY_SELECT,
    storyId,
    includeCurrentPermissionId: false
  });
  const sev = severity ? severity : null;
  const [updateStorySeverity] = useMutation(MUTATE_STORY_SEVERITY_FIELD);
  const handleChange = useCallback(async selectedId => {
    await updateStorySeverity({
      variables: {
        storyId,
        input: {
          canonicalName: 'severity',
          valueId: selectedId ?? NONE_VALUE
        }
      },
      optimisticResponse: createOptimisticStoryMutationResponse(storyId, {
        severity: selectedId ? {
          __typename: 'CustomFieldEnumValue',
          id: selectedId
        } : null
      })
    });
  }, [updateStorySeverity, storyId]);
  if (type !== 'bug') return _jsx(SeverityDisabled, {});
  return _jsx(Center, {
    children: _jsx("div", {
      children: _jsx(GroupedOptionsFieldSingleSelection, {
        selectedEntity: sev,
        fetchOptions: fetchStorySeverity,
        getSection: getSection,
        TargetComponent: SeveritySelectTarget,
        onChange: handleChange,
        unit: Nouns.Severity
      })
    })
  });
};
SeverityFieldComponent.displayName = "SeverityFieldComponent";
export const SeverityField = () => ({
  name: 'severity',
  displayName: 'Severity',
  headerProps: {
    centered: true
  },
  Component: SeverityFieldComponent,
  width: 70,
  sort: true
});