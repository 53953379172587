import { FetchEpicSelectOptionsFromKeyResultDocument } from "../../../../../datalayer/__generated_graphql_types__/graphql";
import { useCallback } from 'react';
import { gql } from '@clubhouse/datalayer';
import { buildSectionTitlesBasedOnGroupKeys } from 'components/create-objectives/utils';
import { useLazyQuery } from 'gql';
const KeyResultEpicSelectOptions = FetchEpicSelectOptionsFromKeyResultDocument;
export function useEpicsForCombobox(keyResultId) {
  const [executeQuery] = useLazyQuery(KeyResultEpicSelectOptions);
  return useCallback(async (query = '', selectedIds = []) => {
    if (!keyResultId) {
      return {
        items: [],
        totalSize: 0,
        pageSize: 0
      };
    }

    /* Because there is no selectedIds available on the KeyResult.EpicSelectOptions, we can't pass it here */
    const {
      data
    } = await executeQuery({
      variables: {
        keyResultId,
        input: {
          fuzzyName: query
        }
      }
    });
    if (!(data?.node?.__typename === 'KeyResult')) {
      return {
        items: [],
        totalSize: 0,
        pageSize: 0
      };
    }
    const items = data.node.epicSelectOptions.edges.flatMap(({
      node,
      groupKey
    }, index, allEdges) => {
      const previousGroupKey = index === 0 ? null : allEdges.at(index - 1)?.groupKey ?? null;
      return [...buildSectionTitlesBasedOnGroupKeys(groupKey, previousGroupKey), {
        kind: 'item',
        id: node.id,
        name: node.name,
        selected: selectedIds.includes(node.id)
      }];
    });
    return {
      items: items,
      totalSize: data.node.epicSelectOptions.pageInfo.totalSize,
      pageSize: data.node.epicSelectOptions.pageInfo.pageSize
    };
  }, [executeQuery, keyResultId]);
}