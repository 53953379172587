import { Icon } from '@useshortcut/shapes-ds';
import { ICON_DEFAULT_WIDTH } from '../../utils/icon';
import { DeprecatedIconAdapter } from './DeprecatedIconAdapter';

/**
 * @deprecated Use <Icon icon="Present" /> from @useshortcut/shapes-ds
 */
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const Present = ({
  fill,
  width = ICON_DEFAULT_WIDTH
}) => _jsx(DeprecatedIconAdapter, {
  width: width,
  fill: fill,
  children: _jsx(Icon, {
    icon: "Present"
  })
});
Present.displayName = "Present";