import "core-js/modules/es.array.push.js";
import ManageCardController from 'app/client/settingsShared/js/controllers/manageCard.js';
window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || {
    Controller: {},
    Model: {}
  };
  [[['Controller', 'ManageCard'], ManageCardController]].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});
const exports = {};
exports.wrapWithCardLink = (message = '') => {
  return message.replace('add a Credit Card', '<a href="#" data-controller="ManageCard" data-on-click="open">add a Credit Card</a>');
};
export { exports as default };