import { useEffect, useRef } from 'react';
import { useIsModalRoute } from './useIsModalRoute';
export const useSetPageTitle = ({
  title
}) => {
  const isModalRoute = useIsModalRoute();
  const timeoutIdRef = useRef();
  useEffect(() => {
    if (title && !isModalRoute) {
      // setTimeout is required because the old router sets the title on pushState
      // with old route functions. It's a more complicated fix to change the legacy code
      timeoutIdRef.current = window.setTimeout(() => {
        document.title = title;
      }, 0);
    }
    return () => {
      clearTimeout(timeoutIdRef.current);
    };
  }, [title, isModalRoute]);
};