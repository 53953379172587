import "core-js/modules/es.array.push.js";
import AutocompleteController from 'app/client/core/js/controllers/autocomplete.js';
window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || {
    Controller: {},
    Model: {}
  };
  [[['Controller', 'Autocomplete'], AutocompleteController], [['Controller', 'Autocomplete'], AutocompleteController]].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});
import Backend from '../modules/backend';
import Collection from '../_frontloader/collection';
import EpicModel from './epic';
import EstimateScaleModel from './estimateScale';
import Format from '../modules/format';
import Iterate from '../modules/iterate';
import Router from '../_frontloader/router';
import Url from '../modules/url';
import Utils from '../modules/utils';
import { PAGE_NAMES, generatePathForPageWithSlug } from 'utils/navigation';
import { getDefaultLabelColor } from 'data/entity/label';
import baseUtils from '../_frontloader/baseUtils';
import _ from 'lodash';
const exports = {};

/*

Example Label Entity:

created_at: "2016-02-05T18:52:31Z"
id: 6515
lowercase_name: "abc"
name: "abc"
num_stories_completed: 0
num_stories_in_progress: 0
num_stories_total: 0
updated_at: "2016-02-05T18:52:31Z"

*/

Collection.create('Label', exports);
Router.addPageTitle('label', url => {
  const path = Url.parsePathSkipCache(url);
  const label = exports.getById(path.label);
  const fallback = `Labels | ${BRAND.NAME}`;
  return label ? label.name + ' | ' + fallback : fallback;
});
exports.cavemanName = 'Label';
exports.getDefaultColor = getDefaultLabelColor;
exports.on('fetched.collection labelUpdated.collection', () => {
  exports.sortByName();
});
exports.isValid = obj => {
  return obj && obj.id && obj.name;
};
exports.normalize = label => {
  label.lowercase_name = label.name.toLowerCase();
  label.formatted_name = Format.sanitizeAndEmojify(label.name);
  label.color = Format.sanitizeColor(label.color, getDefaultLabelColor());
  exports.normalizeStats(label);
  if (exports._isALabelPage()) {
    _attachPropertiesForLabelPages(label);
  }
};
exports.normalizeStats = label => {
  // Temporary until we remove top-level properties.
  if (label.stats) {
    Iterate.each(_.keys(label.stats), key => {
      label[key] = label.stats[key];
    });
  }
};
exports.getElementInStoryDialog = label => {
  return $('.story-dialog .story-labels .tag[data-id="' + label.id + '"]');
};
exports.denormalizeLabels = labels => {
  return _.map(labels, label => {
    return {
      name: label.name,
      color: label.color || getDefaultLabelColor()
    };
  });
};
function _attachPropertiesForLabelPages(label) {
  _attachExtraStoryProperties(label);
  _attachExtraEpicProperties(label);
}
function _attachExtraStoryProperties(label) {
  if (EstimateScaleModel.isUsingPoints()) {
    _attachPointProperties(label, {
      prop: 'story_progress_bar_options',
      percentCompleteProp: 'percent_stories_completed',
      countUnestimated: label.num_stories_unestimated,
      pointsCompleted: label.num_points_completed,
      pointsInProgress: label.num_points_in_progress,
      pointsTotal: label.num_points_total,
      singular: 'Story',
      plural: 'Stories'
    });
  } else {
    _attachCountProperties(label, {
      prop: 'story_progress_bar_options',
      percentCompleteProp: 'percent_stories_completed',
      countCompleted: label.num_stories_completed,
      countInProgress: label.num_stories_in_progress,
      countTotal: label.num_stories_total,
      singular: 'Story',
      plural: 'Stories'
    });
  }
}
function _attachExtraEpicProperties(label) {
  const epics = EpicModel.getAllWithLabel(label);
  _attachCountProperties(label, {
    prop: 'epic_progress_bar_options',
    percentCompleteProp: 'percent_epics_completed',
    countCompleted: _.filter(epics, EpicModel.isDone).length,
    countInProgress: _.filter(epics, EpicModel.isInProgress).length,
    countTotal: epics.length,
    singular: 'Epic',
    plural: 'Epics'
  });
}
function _attachCountProperties(label, data) {
  const countUnstarted = data.countTotal - (data.countInProgress + data.countCompleted);
  const countPercentCompleted = data.countCompleted / data.countTotal * 100 || 0;
  const countPercentInProgress = data.countInProgress / data.countTotal * 100 || 0;
  const countPercentUnstarted = countUnstarted / data.countTotal * 100 || 0;
  label[data.percentCompleteProp] = countPercentCompleted;
  label[data.prop] = {
    title: Math.round(countPercentCompleted) + '% Completed' + (data.countInProgress > 0 ? ', ' + Math.round(countPercentInProgress) + '% In Progress' : '') + (countUnstarted > 0 ? ', ' + Math.round(countPercentUnstarted) + '% Unstarted' : '') + ' <span class="light">' + data.countCompleted + ' of ' + data.countTotal + ' ' + data.plural + ' Completed</span>',
    complete: [countPercentCompleted, countPercentInProgress, countPercentUnstarted],
    tooltips: [Format.pluralize(data.countCompleted, data.singular, data.plural) + ' Completed', Format.pluralize(data.countInProgress, data.singular, data.plural) + ' In Progress', Format.pluralize(countUnstarted, data.singular, data.plural) + ' Unstarted']
  };
}
function _attachPointProperties(label, data) {
  const pointsUnstarted = data.pointsTotal - (data.pointsInProgress + data.pointsCompleted);
  const pointsPercentCompleted = data.pointsCompleted / data.pointsTotal * 100 || 0;
  const pointsPercentInProgress = data.pointsInProgress / data.pointsTotal * 100 || 0;
  const pointsPercentUnstarted = pointsUnstarted / data.pointsTotal * 100 || 0;
  label[data.percentCompleteProp] = pointsPercentCompleted;
  label[data.prop] = {
    title: Math.round(pointsPercentCompleted) + '% Completed' + (data.pointsInProgress > 0 ? ', ' + Math.round(pointsPercentInProgress) + '% In Progress' : '') + (pointsUnstarted > 0 ? ', ' + Math.round(pointsPercentUnstarted) + '% Unstarted' : '') + ' <span class="light">' + data.pointsCompleted + ' of ' + data.pointsTotal + ' Points Completed' + (data.countUnestimated > 0 ? ', ' + Format.pluralize(data.countUnestimated, data.singular, data.plural) + ' Unestimated' : '') + '</span>',
    complete: [pointsPercentCompleted, pointsPercentInProgress, pointsPercentUnstarted],
    tooltips: [Format.pluralize(data.pointsCompleted, 'Point', 'Points') + ' Completed' + (pointsPercentCompleted === 100 && data.countUnestimated > 0 ? ', ' + Format.pluralize(data.countUnestimated, data.singular, data.plural) + ' Unestimated' : ''), Format.pluralize(data.pointsInProgress, 'Point', 'Points') + ' In Progress', Format.pluralize(pointsUnstarted, 'Point', 'Points') + ' Unstarted' + (data.countUnestimated > 0 ? ', ' + Format.pluralize(data.countUnestimated, data.singular, data.plural) + ' Unestimated' : '')]
  };
}
exports.exists = label => {
  // Filtering current label if we pass in an ID, as we don't want to check against itself
  const labels = label.id ? exports.reject({
    id: label.id
  }) : exports.all();
  return _.find(labels, l => {
    return l.lowercase_name === label.name.toLowerCase();
  });
};
exports.isArchived = label => {
  return label.archived === true;
};
exports.allNotArchived = () => {
  return exports.filter({
    archived: false
  });
};
exports.sortByLowercaseName = labels => {
  return _.sortBy(labels, label => {
    return label.name.toLowerCase();
  });
};
exports.getUrl = label => {
  return generatePathForPageWithSlug(PAGE_NAMES.SETTINGS_LABEL, {
    id: label.id,
    name: Utils.slugify(label.name)
  });
};
exports.addLabel = (existingLabels, label) => {
  if (_.isString(label)) {
    return exports.addLabelAsString(existingLabels, label);
  } else {
    return exports.addExistingLabel(existingLabels, label);
  }
};
exports.addExistingLabel = (existingLabels, label) => {
  const labels = existingLabels.slice(0);
  if (_.some(labels, {
    name: label.name
  })) {
    _.remove(labels, {
      name: label.name
    });
  } else {
    labels.push(label);
  }
  return labels;
};
exports.addLabelAsString = (existingLabels, labelString) => {
  // Guard against cases like this:
  // https://help.shortcut.com/agent/tickets/152
  const foundLabel = exports.get({
    lowercase_name: labelString.toLowerCase()
  });
  if (foundLabel) {
    existingLabels = exports.addExistingLabel(existingLabels, foundLabel);
  } else {
    Iterate.each(Utils.tokenizeQuery(labelString), label => {
      const foundLabel = exports.get({
        lowercase_name: label.toLowerCase()
      });
      existingLabels = exports.addExistingLabel(existingLabels, foundLabel || {
        name: label
      });
    });
  }
  return existingLabels;
};
exports.createNewLabelText = value => {
  const labels = Utils.tokenizeQuery(value);
  if (labels.length === 1) {
    return 'Create new label:<div class="tags"><span class="tag" data-controller="Autocomplete" ' + 'data-on-click="applyValueFromInput">' + Format.sanitizeAndEmojify(labels[0]) + '</span></div>';
  } else if (labels.length > 1) {
    return 'Create ' + labels.length + ' new labels (use quotes to combine):<div class="tags" ' + 'data-controller="Autocomplete" data-on-click="applyValueFromInput"><span class="tag">' + _.map(labels, Format.sanitizeAndEmojify).join('</span> <span class="tag">') + '</span></div>';
  }
  return '';
};
exports.getItemsForLabelsAutocompleteFromExistingLabels = (labels = []) => {
  const items = labels.reduce((_items, label) => {
    const labelFull = exports.getById(label.id) || label;

    // `label` is still used to reference piped down classname/icons
    if (!exports.isArchived(labelFull)) {
      _items.push({
        name: labelFull.formatted_name || Format.sanitizeAndEmojify(labelFull.name),
        value: label,
        className: label.className || 'selected',
        iconLeft: 'fa-circle',
        iconLeftColor: labelFull.color || getDefaultLabelColor(),
        iconRight: label.iconRight || 'fa-check'
      });
    }
    return _items;
  }, []);
  if (items.length) {
    items.push({
      hr: true
    });
  }
  exports.each(label => {
    if (!exports.hasLabel(labels, label) && !exports.isArchived(label)) {
      items.push({
        name: label.formatted_name,
        value: label,
        iconLeft: 'fa-circle',
        iconLeftColor: label.color || getDefaultLabelColor(),
        iconRight: 'fa-check'
      });
    }
  });
  return items;
};
exports.getItemsForLabelsAutocomplete = entity => exports.getItemsForLabelsAutocompleteFromExistingLabels(entity.labels);
exports.hasLabel = (labels = [], label) => {
  // support use where first argument is a "state" object with a 'labels' property:
  if (Object.prototype.hasOwnProperty.call(labels, 'labels')) {
    labels = _.get(labels, 'labels', []);
  }
  if (labels.length === 0 || !label) {
    return false;
  }
  if (_.isString(label)) {
    let match = false;
    Iterate.each(labels, obj => {
      if (obj.name === label) {
        match = true;
      }
    });
    return match;
  }
  return _.some(labels, {
    id: label.id
  });
};
exports.hasAllIds = ids => {
  const allIds = exports.map('id');
  return _.difference(ids, allIds).length === 0;
};
exports.getReadableLabelNames = entity => {
  const labels = _.get(entity, 'labels', []);
  return Utils.grammaticalJoin(_.map(labels, label => {
    return Format.emojify(label.name);
  }));
};
exports.updateMultiple = labels => {
  Iterate.each(labels, label => {
    exports.updateIfValid(label);
  });
};
exports.sortByName = () => {
  exports.flush(exports.sortBy('lowercase_name'));
};
exports.getFromUrl = url => {
  const path = Url.parsePath(url);
  return exports.getById(path.label);
};
exports.fetch = (label, callback) => {
  Backend.get('/api/private/labels/' + label.id, {
    onComplete: res => {
      exports.defaultGetHandler(res, callback);
    }
  });
};
exports.fetchAll = callback => {
  exports.fetchAllByUrl('/api/private/labels', callback);
};
exports.fetchAllSlim = callback => {
  exports.fetchAllByUrl('/api/private/labels?slim=true', callback);
};
exports.fetchAllByUrl = (url, callback) => {
  Backend.get(url, {
    onComplete: res => {
      exports.defaultFetchSomeHandler(res, callback);
    }
  });
};
exports.createNewLabel = (label, callback) => {
  Backend.post('/api/private/labels', {
    data: label,
    onComplete: res => {
      exports.defaultGetHandler(res, callback, 'labelCreated');
    }
  });
};
exports.archiveLabel = (label, callback) => {
  exports.updateLabel(label, {
    archived: true
  }, callback);
};
exports.unArchiveLabel = (label, callback) => {
  exports.updateLabel(label, {
    archived: false
  }, callback);
};
exports.updateLabel = (label, updates, callback) => {
  Backend.put('/api/private/labels/' + label.id, {
    data: updates,
    onComplete: res => {
      exports.defaultGetHandler(res, callback, 'labelUpdated');
    }
  });
};
exports.deleteLabel = (label, callback) => {
  Backend.delete('/api/private/labels/' + label.id, {
    onComplete: (res, xhr) => {
      exports.defaultDeleteHandler(label, res, xhr, callback);
    }
  });
};

// Covers both label and labels pages
exports._isALabelPage = () => {
  return _.startsWith(Url.getCurrentPathname(), Url.getSlugPath() + '/label');
};
exports.Promises = {
  fetch: baseUtils.promisify(exports.fetch)
};
export { exports as default };