import { getTokens } from '@useshortcut/shapes-ds';
import { THEME_NAMES } from '@clubhouse/shared/utils/theme';
import { COLORS } from '../../colors';
import { KIND, SWITCH_SIZE } from './types';
const tokens = getTokens();
const CHECKED_COLOR = COLORS.GREEN38;
const SWITCH_THEME = {
  [THEME_NAMES.DARK]: {
    CHECKED: {
      background: CHECKED_COLOR,
      knob: COLORS.WHITE
    },
    UNCHECKED: {
      background: tokens.color.raw.slate40,
      knob: COLORS.GRAY70
    }
  },
  [THEME_NAMES.LIGHT]: {
    CHECKED: {
      background: CHECKED_COLOR,
      knob: COLORS.WHITE
    },
    UNCHECKED: {
      background: COLORS.GRAY95,
      knob: COLORS.WHITE
    }
  }
};
const CONTAINER_BY_SIZE = {
  [SWITCH_SIZE.SMALL]: {
    containerBoxShadow: 'inset 0px 1px 5px rgba(0, 0, 0, 0.17)',
    containerHeight: '22px',
    containerWidth: '40px'
  },
  [SWITCH_SIZE.LARGE]: {
    containerBoxShadow: 'inset 0px 1.45455px 7.27273px rgba(0, 0, 0, 0.17)',
    containerHeight: '32px',
    containerWidth: '72px'
  }
};
const KNOB_BY_SIZE = {
  [SWITCH_SIZE.SMALL]: {
    knobBorderRadius: '22px',
    knobHeightWidth: '18px',
    knobMarginLeftChecked: '20px'
  },
  [SWITCH_SIZE.LARGE]: {
    knobBorderRadius: '44px',
    knobHeightWidth: '28px',
    knobMarginLeftChecked: '42px'
  }
};
const getSwitchTheme = ({
  checked,
  size,
  themeName
}) => ({
  ...SWITCH_THEME[themeName][checked ? 'CHECKED' : 'UNCHECKED'],
  ...CONTAINER_BY_SIZE[size],
  ...KNOB_BY_SIZE[size]
});
const CHECKMARK_THEME = {
  [THEME_NAMES.DARK]: {
    CHECKED: {
      background: CHECKED_COLOR,
      border: CHECKED_COLOR,
      iconFill: COLORS.WHITE
    },
    UNCHECKED: {
      background: 'transparent',
      border: COLORS.GRAY50,
      iconFill: COLORS.GRAY70
    }
  },
  [THEME_NAMES.LIGHT]: {
    CHECKED: {
      background: CHECKED_COLOR,
      border: CHECKED_COLOR,
      iconFill: COLORS.WHITE
    },
    UNCHECKED: {
      background: 'transparent',
      border: COLORS.GRAY87,
      iconFill: COLORS.GRAY70
    }
  }
};
const getCheckmarkTheme = ({
  checked,
  themeName
}) => ({
  ...CHECKMARK_THEME[themeName][checked ? 'CHECKED' : 'UNCHECKED']
});
const themeGetterMap = {
  [KIND.CHECKMARK]: getCheckmarkTheme,
  [KIND.SWITCH]: getSwitchTheme
};
export const getTheme = ({
  checked,
  kind,
  size,
  themeName
}) => themeGetterMap[kind]({
  checked,
  size,
  themeName
});