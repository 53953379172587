import * as StoryCommentOrderToggleTemplate from 'app/client/core/views/templates/storyCommentOrderToggle.html';

// Models
import CommentModel from '../models/comment';

// Modules
import Format from 'app/client/core/js/modules/format';
import Hash from 'app/client/core/js/modules/hash';
import Router from '../_frontloader/router';
import Snapshot from 'app/client/core/js/modules/snapshot';
import Tooltip from 'app/client/core/js/modules/tooltip';
import Utils from 'app/client/core/js/modules/utils';

// Controllers
import MessageController from './message';
import StoryController from './story';

// misc
import { copyToClipboard } from '@clubhouse/shared/utils/copyToClipboard';
import { created } from 'utils/sort';
import View from '../modules/view';
import { StoryActivityList } from 'components/stories/StoryActivityList';
const exports = {};
let commentListMap = new Map([]);
const getCommentElement = ({
  id
}) => document.querySelector(`.comment[data-id="${id}"]`);
const overrideCommentText = (comment, html) => {
  comment.querySelector('.text').innerHTML = html;
};

// Cloned from controller/story.copyURLToClipboard
const copyHrefToClipboard = (context, event) => {
  Tooltip.flashNewTooltipMessage(context, event, 'Copied!');
  copyToClipboard(context.getAttribute('href'));
};
exports.handleOnUpdate = function () {
  exports.updateComment.apply(this, arguments);
};
exports.toggleCommentOrder = () => {
  CommentModel.toggleCommentSortOrder();
  const toggle = StoryCommentOrderToggleTemplate.render();
  $('.comment-order-toggle').html(toggle);
  const threadedCommentsElement = document.querySelector('.threaded-comments');
  const commentsContainer = threadedCommentsElement.querySelector('.threaded-comments-container');
  const commentFormContainer = threadedCommentsElement.querySelector('[data-new-comment-form]')?.parentElement;
  if (commentsContainer && commentFormContainer) {
    const sortedCommentElements = [...commentsContainer.children].reverse();
    commentsContainer.replaceChildren();
    commentsContainer.append.apply(commentsContainer, sortedCommentElements);
    threadedCommentsElement.removeChild(commentFormContainer);
    if (CommentModel.commentsAreAscending()) {
      $(commentFormContainer).insertAfter('.threaded-comments-container');
    } else {
      $(commentFormContainer).insertBefore('.threaded-comments-container');
    }

    // TODO: Decouple the Comments order from the Story history order
    const entity = Utils.getModelFromContext(commentsContainer);
    StoryController.reverseStoryHistoryOrder(entity);
  }
  return false;
};
exports.getSortedComments = comments => {
  const sortedComments = comments.sort(created);
  return CommentModel.commentsAreAscending() ? sortedComments.reverse() : sortedComments;
};
exports.renderComments = entity => {
  return exports.renderActivityList(entity.id);
};
exports.renderActivityList = storyId => {
  return View.renderComponentDelayed({
    componentKey: 'story-activity-' + storyId,
    component: StoryActivityList,
    props: {
      storyId
    }
  }).html;
};
exports.deleteComment = ({
  target
}) => {
  const entity = Utils.getModelFromContext(target, 'Story');
  const commentWrapper = target.closest('.comment-wrapper');
  const commentId = Utils.data(commentWrapper, 'id');
  const comment = commentListMap.get(commentId);
  const commentElement = commentWrapper.querySelector('.comment');
  if (window.confirm('Are you sure you want to delete this comment?')) {
    overrideCommentText(commentElement, `<p><span class="ghost">${CommentModel.CONSTANTS.DELETING}</span></p>`);
    CommentModel.deleteComment(entity.id, commentId, err => {
      if (err) {
        MessageController.error(err, {
          secondary: 'Unable to delete comment.'
        });
        overrideCommentText(commentElement, Format.sanitize(Format.markdownify(comment.text, 'comment-delete-error')));
      } else {
        const snapshot = Snapshot.getSnapshot(entity);
        if (snapshot && snapshot[`edit${commentId}`]) {
          const textarea = $(commentElement).find('.in-place-textarea')[0];
          exports.cancelCommentUpdate.call(textarea);
        }
        MessageController.success('Comment was successfully deleted.');
      }
    });
  }
  return false;
};
const toggleHighlight = (commentWrapper, force) => {
  const highlightClassName = 'highlighted-anchor';
  const commentClasslist = commentWrapper.classList;
  const shouldHighlight = typeof force === 'undefined' ? !commentClasslist.contains(highlightClassName) : force;
  if (shouldHighlight) {
    document.querySelectorAll(`.${highlightClassName}`).forEach(match => match.classList.remove(highlightClassName));
    commentClasslist.add(highlightClassName);
  } else {
    commentClasslist.remove(highlightClassName);
  }
  return shouldHighlight;
};
exports.handleAnchorClick = event => {
  updateAnchorHighlight(event.target.closest('.comment-wrapper'));
  copyHrefToClipboard(event.target, event);
  return false;
};
exports.updateAnchorHighlightForComment = (id, force) => {
  const commentElement = getCommentElement({
    id
  });
  if (!commentElement) {
    return;
  }
  updateAnchorHighlight(commentElement, force);
  // Deferring one second so that images are more likely to load.
  setTimeout(() => {
    document.querySelector(`.comment-wrapper[data-id="${id}"] .comment-meta a`)?.scrollIntoView({
      behavior: 'smooth'
    });
  }, 1000);
};
const updateAnchorHighlight = (commentWrapper, force) => {
  const anchor = commentWrapper?.querySelector('.date a');
  if (!anchor) {
    return;
  }
  const hashString = Hash.extractHashFromRaw(anchor.href.split('#')[1]);

  // We're temporarily disabling the Router to avoid a page reload caused by updating the URL hash.
  Router.disableRouter();
  const newHighlightState = toggleHighlight(commentWrapper, force);
  Hash.set(newHighlightState ? hashString : '');
  Router.enableRouter();
  return false;
};
export { exports as default };