import { Emojify } from '@clubhouse/shared/components/Emojify';
import { Spaced } from '@clubhouse/shared/components/Spaced';
import { Text } from '@clubhouse/shared/components/Typography/Text';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const WorkflowState = ({
  columnName,
  storyCount,
  workflowName,
  children
}) => _jsxs(Spaced, {
  vertically: true,
  amount: 4,
  children: [_jsxs("div", {
    children: [_jsx(Text, {
      el: "span",
      size: Text.SIZE.XSMALL,
      bold: true,
      children: _jsx(Emojify, {
        children: columnName
      })
    }), ' ', _jsxs(Text, {
      el: "span",
      color: Text.COLOR.LIGHT,
      size: Text.SIZE.XSMALL,
      bold: true,
      children: ["(", storyCount, ")"]
    }), !!workflowName && _jsx(Text, {
      el: "span",
      color: Text.COLOR.LIGHT,
      size: Text.SIZE.XSMALL,
      children: _jsxs("em", {
        children: [' ', _jsx(Emojify, {
          children: workflowName
        })]
      })
    })]
  }), children]
});
WorkflowState.displayName = "WorkflowState";