import { ContextHookProvider } from '@clubhouse/shared/utils/useContextHook/useContextHook';
import { ErrorBoundary } from 'components/shared/ErrorBoundary';
import { useRenderSpansWithPropChanges } from 'utils/profiler/sentry';
import { BootPage } from './BootPage';
import { ClearCacheAndReload } from './ClearCacheAndReload';
import { SetSearchParamsFromApplicationState } from './SetSearchParamsFromApplicationState';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const contextHookProviderValue = {
  useHook: ({
    ComponentType,
    props
  }) => useRenderSpansWithPropChanges(ComponentType.displayName ?? 'missing-display-name', props)
};
export const GraphQlPage = props => {
  const {
    children,
    pageName,
    applicationFilterStateKey,
    persistedSearchParamKeys,
    TranslateSearchParams,
    renderId
  } = props;
  useRenderSpansWithPropChanges(pageName, props);
  return _jsx(ErrorBoundary, {
    pageName: pageName,
    errorNode: _jsx(ClearCacheAndReload, {}),
    children: _jsx(ContextHookProvider, {
      value: contextHookProviderValue,
      children: _jsx(BootPage, {
        pageName: pageName,
        renderId: renderId,
        children: _jsx(TranslateSearchParams, {
          children: _jsx(SetSearchParamsFromApplicationState, {
            applicationStateKey: applicationFilterStateKey,
            searchParamKeys: persistedSearchParamKeys,
            children: children
          })
        })
      })
    })
  });
};
GraphQlPage.displayName = "GraphQlPage";