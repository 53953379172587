import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.set.difference.v2.js";
import "core-js/modules/esnext.set.intersection.v2.js";
import "core-js/modules/esnext.set.is-disjoint-from.v2.js";
import "core-js/modules/esnext.set.is-subset-of.v2.js";
import "core-js/modules/esnext.set.is-superset-of.v2.js";
import "core-js/modules/esnext.set.symmetric-difference.v2.js";
import "core-js/modules/esnext.set.union.v2.js";
import noop from 'lodash/noop';
import { ageInDays } from '@clubhouse/shared/dates';
import { convertAccentedCharacters, getIsOverdue, getProfileNameInitials } from '@clubhouse/shared/utils';
import { purify } from '@clubhouse/shared/utils/format';
import { getNormalizedStoryCustomFields } from 'data/entity/customField';
import { OptionalProject } from 'data/entity/project';
import * as StoryData from 'data/entity/story';
import { EVENT_TYPES, dispatchEvent } from 'utils/collectionizeToApolloMessageBus';
import { EVENTS, logEvent } from 'utils/monitoring';
import { simpleCompleteTask } from 'utils/quickstart';
import BranchModel from './branch';
import BulkSelectionModel from './bulkSelection';
import ColumnModel from './column';
import CustomFieldModel from './customField';
import EpicModel from './epic';
import FileModel from './file';
import FilterModel from './filter';
import GroupModel from './group';
import IterationModel from './iteration';
import LabelModel from './label';
import LinkedFileModel from './linkedFile';
import OrganizationModel from './organization';
import ProfileModel from './profile';
import ProjectModel from './project';
import PullRequestModel from './pullRequest';
import RepositoryModel from './repository';
import SpaceModel from './space';
import StoryHistoryModel from './storyHistory';
import StoryLinkModel from './storyLink';
import TaskModel from './task';
import TeamModel from './team';
import UserModel from './user';
import WorkflowModel from './workflow';
import { isValidWorkflowForGroup } from '../../../../../data/entity/group';
import BaseUtils from '../_frontloader/baseUtils';
import Collection from '../_frontloader/collection';
import * as Event from '../_frontloader/event';
import Globals from '../_frontloader/globals';
import Async from '../modules/async';
import Backend from '../modules/backend';
import Constants from '../modules/constants';
import Format from '../modules/format';
import Iterate from '../modules/iterate';
import Log from '../modules/log';
import Tests from '../modules/tests';
import Url from '../modules/url';
import Utils from '../modules/utils';
import _ from 'lodash';
import moment from 'moment';
const exports = {
  Promises: {}
};

/*

{
  branches: [
    {
      commits: [Commit, ...]
      created_at: "2015-11-04T22:48:29Z"
      deleted: true
      id: 4151
      merges: [ { id: 3163, name: 'master' } ]
      name: "ac/4129/allow-free-plan-users-to-upgrade-to-paid"
      persistent: false
      pull_requests: [
        {
          closed: true
          created_at: "2015-11-05T03:52:12Z"
          id: 4162
          num_added: 365
          num_commits: 8
          num_modified: 18
          num_removed: 146
          number: 82
          title: "Allow free plan users to upgrade to paid and vice versa"
          updated_at: "2015-11-05T04:15:07Z"
          url: "https://github.com/useshortcut/app-frontend/pull/82"
        }
      ]
      repository_name: "node-app"
      updated_at: "2015-11-05T04:15:08Z"
      url: "https://github.com/useshortcut/app-frontend/tree/ac/4129/allow-free-plan-users-to-upgrade-to-paid"
    }
  ]
}

*/

// We should fetch stories automatically that were completed within the last number of days:
const DAYS_OLD_TO_FETCH = 30;
Collection.create('Story', exports);
exports.on('beforeAdd.collection beforeUpdate.collection', story => {
  Iterate.each(story.tasks, task => {
    TaskModel.update(task);
  });
});
exports.isValid = story => {
  return story && story.id;
};
exports.updateMultiple = stories => {
  _.each(stories, exports.updateIfValid);
};
exports.isUpdated = story => {
  const existing = exports.getById(story.id);
  return !existing || existing.updated_at !== story.updated_at;
};
exports.fetchUpdatedInLastWeek = callback => {
  callback = _.isFunction(callback) ? callback : _.noop;
  Backend.post('/api/private/stories/search', {
    data: {
      archived: false,
      updated_at_start: moment().subtract(8, 'days').format() // 1 extra day to sidestep timezone issues
    },
    onComplete: res => {
      exports.defaultFetchSomeHandler(res);
      exports.sortStoriesByPosition();
      callback();
      (async () => {
        await StoryData.fetchRecentStoriesForWorkflows({
          workflowIds: WorkflowModel.all().map(w => w.id)
        });
      })();
    }
  });
};
exports.fetchOwnedByMe = callback => {
  const query = {
    owner_ids: [UserModel.getLoggedInUserPermissionID()]
  };
  exports.chunkFetchByStateType(query, callback);
};
exports.generateCustomQueryFetchFn = query => {
  return callback => {
    exports.chunkFetchByStateType(query, callback);
  };
};
exports.chunkFetchByStateType = (query, callback) => {
  const firstQuery = _.assignIn({
    archived: false,
    workflow_state_types: ['backlog', 'unstarted', 'started']
  }, query);

  // We're adding an extra day here to sidestep any timezone issues.
  const secondQuery = _.assignIn({
    archived: false,
    completed_at_start: moment().subtract(DAYS_OLD_TO_FETCH + 1, 'days').format(),
    workflow_state_types: ['done']
  }, query);
  const fns = [callback => {
    _searchStoryWrapper(firstQuery, callback);
  }, callback => {
    _searchStoryWrapper(secondQuery, callback);
  }];
  Globals.set('fetchingAllStories', true);
  Async.eachInParallelThen(fns, () => {
    _searchResponseHandler(callback);
  });
};
exports.searchStoriesAndThenFetchAllActiveProjects = (query, callback) => {
  exports.searchStories(query, () => {
    _searchResponseHandler(callback);
  });
};
function _searchResponseHandler(callback) {
  exports.sortStoriesByPosition();
  callback();
  (async () => {
    await StoryData.fetchRecentStoriesForWorkflows({
      workflowIds: WorkflowModel.all().map(w => w.id)
    });
  })();
}
function _searchStoryWrapper(query, callback) {
  Backend.post('/api/private/stories/search', {
    data: query,
    onComplete: res => {
      exports.defaultFetchSomeHandler(res, callback);
    }
  });
}
exports.isNew = story => {
  return story.id === 'addNewStory';
};
exports.isArchived = story => {
  return story.archived === true;
};
exports.hasProject = story => Boolean(story.project_id);
exports.hasArchivedParent = story => {
  if (story.epic && story.epic.archived === true) {
    return true;
  }
  if (exports.hasProject(story)) {
    const project = ProjectModel.getById(story.project_id);

    // Possible that project has been deleted.
    if (!project || project.archived === true) {
      return true;
    }
  }
  return false;
};
exports.getNumberOfCompletedTasks = story => {
  return story.tasks ? story.tasks.filter(t => {
    return t.complete === true;
  }).length : story.num_tasks_completed;
};
exports.hasCommentEntities = story => {
  return _.isArray(story.comments);
};
exports.isFullyLoaded = story => {
  return story && exports.hasCommentEntities(story);
};
exports.generateStoryURL = story => {
  return Url.getSlugPath() + '/story/' + story.id + '/' + Utils.slugify(story.name, {
    limit: 120
  });
};
exports.generateStoryLinkForTemplate = story => {
  return '<a href="' + exports.generateStoryURL(story) + '">' + exports.getFormattedName(story) + '</a>';
};
exports.getFormattedName = story => {
  return Format.sanitizeAndEmojify(story.name) || '<em>Untitled Story</em>';
};
exports.normalize = story => {
  _updatePropsFromOverrides(story);
  exports.relateUsersAndEpics(story);
  Utils.attachFormattedDeadlines(story);
  story.timeline = story.timeline || [];
  story.is_overdue = getIsOverdue(story.deadline);
  story.story_type_icon = exports.getStoryTypeIcon(story);
  story.custom_fields = getNormalizedStoryCustomFields(story);
  if (Tests.usesIterations()) {
    story.iteration = IterationModel.getById(story.iteration_id);
  }
  if (story.workflow_state_id && story.state) {
    story.state = null;
  }
  const column = ColumnModel.getById(story.workflow_state_id);
  const team = ColumnModel.getTeamFromColumn(column) || TeamModel.getActive();
  const workflowForSelectedProject = WorkflowModel.getById(team.workflow.id);
  const currentState = workflowForSelectedProject.states.find(state => state.id === story.workflow_state_id);
  story.stateObject = {
    ...currentState
  };
  story.project = ProjectModel.getById(story.project_id) || {};
  return story;
};
exports.normalizeActivity = (story, options) => {
  options = options || {};
  if (options.attachmentsOnly) {
    story.activity = [];
  } else {
    story.activity = story.comments || [];
  }
  const files = (story.files || []).map(file => ({
    ...file,
    entity_type: 'story-file-comment'
  }));
  const linkedFiles = (story.linked_files || []).map(file => ({
    ...file,
    entity_type: 'story-linked-file-comment'
  }));
  story.activity = story.activity.concat(files, linkedFiles);
  story.activity = _.sortBy(story.activity, ['created_at', 'id']);
};
function _updatePropsFromOverrides(story) {
  if (story.created_at_override) {
    story.created_at = story.created_at_override;
  }
  if (story.completed_at_override) {
    story.completed_at = story.completed_at_override;
  }
}
exports.getTeamName = story => {
  const column = ColumnModel.getById(story.workflow_state_id);
  return ColumnModel.getTeamName(column);
};
exports.getWorkflowName = story => {
  const column = WorkflowModel.getById(story.workflow_id) || {};
  return column.name || 'Unknown';
};
exports.getWorkflowStateName = story => {
  const column = ColumnModel.getById(story.workflow_state_id) || {};
  return column.name || 'Unknown';
};
exports.getWorkflowStateIcon = story => {
  return ColumnModel.renderStateIconFromStateType(exports.getWorkflowStateType(story));
};
exports.getWorkflowStateType = story => {
  const column = ColumnModel.getById(story.workflow_state_id) || {};
  return column.type || 'unstarted';
};
exports.getPreviousIterations = story => {
  return IterationModel.filter(iteration => story.previous_iteration_ids.includes(iteration.id));
};
exports.relateUsersAndEpics = story => {
  story.requested_by = ProfileModel.getAllDetailsById(story.requested_by_id);
  story.followers = ProfileModel.mapIDsToProfileDetails(story.follower_ids);
  story.owners = ProfileModel.mapIDsToProfileDetails(story.owner_ids);
  story.epic = EpicModel.getById(story.epic_id);
};
exports.relateAuthorsAndUploaders = story => {
  Iterate.each(story.comments, (comment, k) => {
    story.comments[k].author = ProfileModel.getAllDetailsById(comment.author_id);
  });
};
exports.normalizeStoryDetails = story => {
  story.formatted_description = story.description ? Format.markdownify(story.description, 'story-description-normalize') : '<span class="ghost">No description given.</span>';
  story.url = exports.generateStoryURL(story);
  exports.normalizeTasks(story);
  exports.normalizeComments(story);
  exports.normalizeFiles(story);
  exports.normalizeLinkedFiles(story);
  exports.normalizeCode(story);
  exports.normalizeActivity(story);
  exports.relateAuthorsAndUploaders(story);
};
exports.normalizeTasks = story => {
  if (_.isArray(story.tasks)) {
    story.tasks = _.sortBy(story.tasks, 'position');
  }

  // This is too expensive to do for every story, which is
  // why we're only doing this when a story is opened.
  TaskModel.remove(task => {
    const threshold = 15;
    const age = moment().diff(task.created_at, 'seconds');
    return task.story_id === story.id && age > threshold;
  });
  Iterate.each(story.tasks, task => {
    // We lose the reference to the normalized task, so let's normalize here.
    TaskModel.normalize(task);
    TaskModel.updateIfValid(task);
  });
};
exports.normalizeComments = story => {
  Iterate.each(story.comments, comment => {
    comment.type = 'comment';
    comment.formatted_created_at = moment(comment.created_at).format(Constants.SHORT_DATE_TIME_FORMAT);
    comment.formatted_text = comment.text ? Format.markdownify(comment.text, 'story-comment-normalize') : '';
    comment.permalink = story.url + '#activity-' + comment.id;
    comment.reactions = exports.normalizeReactions(comment.reactions);
  });
};
exports.normalizeReactions = reactions => {
  return reactions.map(reaction => {
    return {
      ...reaction,
      formattedEmoji: Format.sanitizeAndEmojify(reaction.emoji),
      byCurrentUser: new Set(reaction.permission_ids).has(UserModel.getLoggedInUserPermissionID())
    };
  });
};
exports.normalizeFiles = story => {
  story.files = story.files || [];
  if (!_.isEmpty(story.file_ids)) {
    story.files = _.uniqBy(story.files.concat(FileModel.mapIDsToFiles(story.file_ids)), 'id');
  } else {
    story.file_ids = _.map(story.files, 'id');
  }
  Iterate.each(story.files, file => {
    file.story_id = story.id;
    file.permalink = story.url + '#activity-' + file.id;
    file.formatted_description = file.description ? Format.markdownify(file.description, 'story-file-normalize') : '';
    // We lose the reference to the normalized file, so let's normalize here.
    FileModel.normalize(file);
    FileModel.updateIfValid(file);
  });
};
exports.normalizeLinkedFiles = story => {
  Iterate.each(LinkedFileModel.mergeInStory(story).linked_files, file => {
    file.story_id = story.id;
    file.permalink = story.url + '#activity-' + file.id;
    // We lose the reference to the normalized file, so let's normalize here.
    LinkedFileModel.normalize(file);
    LinkedFileModel.updateIfValid(file);
  });
};
exports.normalizeRepo = change => {
  if (!change.repository && change.repository_id) {
    change.repository = RepositoryModel.getById(change.repository_id);
  }

  // Token-based commits don't have the repository map filled out
  if (change.repository && !change.repository.name && change.url) {
    const urlSegments = change.url.split('/');
    change.repository.name = urlSegments[4];
    change.repository.full_name = urlSegments[3] + '/' + change.repository.name;
    change.repository.url = 'https://github.com/' + change.repository.full_name;
  }
};
exports.normalizeCode = story => {
  let lastCommit = null;
  Iterate.each(story.commits, commit => {
    exports.normalizeRepo(commit);
    commit.type = 'commit';
    if (commit.repository) {
      commit.github_project_name = commit.repository.name;
      commit.github_project_full_name = commit.repository.full_name;
      commit.github_project_link = commit.repository.url;
    }
    commit.formatted_created_at = moment(commit.created_at).fromNow();
    commit.user = ProfileModel.getAllDetailsById(commit.author_id);
    commit.groupedCommits = [];
    const c = {
      id: commit.id,
      sha: commit.hash.substr(0, 7),
      url: commit.url,
      formatted_text: commit.message ? exports.formatCommitMessage(commit.message) : '',
      formatted_created_at: commit.formatted_created_at
    };
    const noOtherActivity = lastCommit && !exports.storyHasActivityBetweenDates(story, lastCommit.created_at, commit.created_at);
    if (lastCommit && lastCommit.github_project_name === commit.github_project_name && lastCommit.author_email === commit.author_email && noOtherActivity) {
      commit.skip = true;
      lastCommit.groupedCommits.push(c);
    } else {
      commit.groupedCommits = [c];
      lastCommit = commit;
    }
  });
  Iterate.each(story.branches, branch => {
    exports.normalizeRepo(branch);
    BranchModel.update(branch);
  });
  Iterate.each(story.pull_requests, pr => {
    exports.normalizeRepo(pr);
    PullRequestModel.update(pr);
  });
};
exports.formatCommitMessage = message => {
  message = Format.sanitize(message.split('\n')[0]);
  return Format.emojify(message);
};
const BRANCH_ATTRIBUTE_FORMATTER = {
  '[current_username]': {
    formatStory: () => {
      return ProfileModel.getCurrentUserProfileDetails().mention_name;
    },
    formatDemo: () => {
      return ProfileModel.getCurrentUserProfileDetails().mention_name;
    }
  },
  '[epic_name]': {
    formatStory: s => {
      return s.epic ? Utils.slugify(s.epic.name) : 'no-epic';
    },
    formatDemo: () => {
      return 'my-epic-name';
    }
  },
  '[iteration_id]': {
    formatStory: s => {
      return s.iteration_id || 'no-iteration-id';
    },
    formatDemo: () => {
      return '456';
    }
  },
  '[owner_initials]': {
    formatStory: s => {
      return getProfileNameInitials(exports.getOwnerOfStoryOrCurrentProfile(s)).toLowerCase();
    },
    formatDemo: () => {
      return getProfileNameInitials(ProfileModel.getCurrentUserProfileDetails()).toLowerCase();
    }
  },
  '[owner_name]': {
    formatStory: s => {
      return Utils.slugify(exports.getOwnerOfStoryOrCurrentProfile(s).name);
    },
    formatDemo: () => {
      return Utils.slugify(ProfileModel.getCurrentUserProfileDetails().name);
    }
  },
  '[owner_username]': {
    formatStory: s => {
      return exports.getOwnerOfStoryOrCurrentProfile(s).mention_name;
    },
    formatDemo: () => {
      return ProfileModel.getCurrentUserProfileDetails().mention_name;
    }
  },
  '[project_id]': {
    formatStory: s => {
      return s.project_id || 'no-project-id';
    },
    formatDemo: () => {
      return '234';
    }
  },
  '[project_name]': {
    formatStory: s => {
      return s.project_id ? Utils.slugify(ProjectModel.getById(s.project_id).name) : 'no-project';
    },
    formatDemo: () => {
      return 'my-project-name';
    }
  },
  '[story_id]': {
    formatStory: s => {
      return BRAND.STORY_NUMBER_PREFIX + s.id;
    },
    formatDemo: () => {
      return BRAND.STORY_NUMBER_PREFIX + '123';
    }
  },
  '[story_name]': {
    formatStory: s => {
      return Utils.slugify(s.name);
    },
    formatDemo: () => {
      return 'my-story-name';
    }
  },
  '[story_type]': {
    formatStory: s => {
      return s.story_type;
    },
    formatDemo: () => {
      return 'feature';
    }
  },
  '[team_mention_name]': {
    formatStory: s => {
      return s.group_id ? Utils.slugify(GroupModel.getById(s.group_id).mention_name) : 'no-team';
    },
    formatDemo: () => {
      return 'my-team-mention-name';
    }
  },
  '[team_name]': {
    formatStory: s => {
      return s.group_id ? Utils.slugify(GroupModel.getById(s.group_id).name) : 'no-team';
    },
    formatDemo: () => {
      return 'my-team-name';
    }
  }
};
exports.toBranchName = story => {
  const formatter = OrganizationModel.getBranchFormat();
  return exports.toCustomBranchName(story, formatter);
};
exports.formatBranchAttribute = (story, attribute) => {
  const propFn = story ? 'formatStory' : 'formatDemo';
  return BRANCH_ATTRIBUTE_FORMATTER[attribute][propFn](story);
};
exports.toDemoBranchName = formatter => {
  return exports.toCustomBranchName(null, purify(formatter));
};
exports.toCustomBranchName = (story, format) => {
  let branch_name = format;
  Object.keys(BRANCH_ATTRIBUTE_FORMATTER).forEach(attribute => {
    if (branch_name.indexOf(attribute) !== -1) {
      branch_name = branch_name.replace(attribute, exports.formatBranchAttribute(story, attribute));
    }
  });

  // This strips the accented and some accented and special characters from the
  // branch name. These are legal in a git ref name, but cause GitHub to put
  // warnings on PRs of "The head ref may contain hidden characters". This
  // doesn't prevent all cases where GitHub will give that warning, but reduces
  // the cases for common characters.
  return convertAccentedCharacters(branch_name);
};
exports.getOwnerOfStoryOrCurrentProfile = story => {
  const profile = ProfileModel.getCurrentUserProfileDetails();
  if (story.owners && story.owners.length > 0 && !story.owners.find(o => {
    return o.id === profile.id;
  })) {
    return story.owners[0];
  }
  return profile;
};
exports.storyHasActivityBetweenDates = (story, start, end) => {
  let activityFound = false;
  const momentStart = moment(start);
  const momentEnd = moment(end);
  function checkCollection(collection) {
    Iterate.each(collection, obj => {
      const created = moment(obj.created_at);
      if (!activityFound && created > momentStart && created < momentEnd) {
        activityFound = true;
      }
    });
  }
  checkCollection(story.comments);
  checkCollection(exports.normalizeLinkedFiles(story));
  return activityFound;
};
exports.transformToTemplate = story => ({
  name: story.name,
  description: story.description,
  deadline: story.deadline,
  project: ProjectModel.getById(story.project_id),
  project_id: story.project_id,
  group_id: story.group_id,
  story_links: _.map(story.story_links, link => {
    const relationship = link.isSubject ? {
      object_id: link.object_id
    } : {
      subject_id: link.subject_id
    };
    return _.assign(relationship, {
      verb: link.verb
    });
  }),
  story_type: story.story_type,
  requested_by: story.requested_by,
  requested_by_id: story.requested_by_id,
  followers: story.followers,
  follower_ids: story.follower_ids || [],
  owners: story.owners,
  owner_ids: story.owner_ids || [],
  estimate: story.estimate,
  external_links: story.external_links,
  workflow_id: story.workflow_id,
  workflow_state_id: story.workflow_state_id,
  epic_id: story.epic_id,
  labels: story.labels || [],
  custom_fields: CustomFieldModel.generateEmptyStoryCustomFields(story),
  tasks: (story.tasks || []).map(task => {
    return {
      // To avoid collisions with existing tasks:
      id: Utils.generateUUID(),
      description: task.description,
      complete: false,
      position: task.position,
      owner_ids: task.owner_ids || []
    };
  }),
  ...getIterationIdIfEnabled(story)
});
const getIterationIdIfEnabled = story => {
  if (!Tests.usesIterations()) {
    return {};
  }
  return {
    iteration_id: story.iteration_id
  };
};
exports.transformToNewStory = story => {
  return {
    name: story.name,
    description: story.description,
    deadline: story.deadline,
    file_ids: _.map(story.files, 'id'),
    group_id: story.group_id,
    linked_file_ids: _.map(story.linked_files, 'id'),
    story_links: StoryLinkModel.dedupeByKey(story.story_links || []),
    story_type: story.story_type,
    requested_by_id: story.requested_by_id,
    follower_ids: story.follower_ids,
    owner_ids: story.owner_ids,
    estimate: story.estimate,
    external_links: story.external_links,
    tasks: exports.transformTasksForDuplication(story.tasks || []),
    workflow_state_id: story.workflow_state_id,
    epic_id: story.epic_id,
    labels: LabelModel.denormalizeLabels(story.labels || []),
    story_template_id: story.template_id,
    custom_fields: CustomFieldModel.generateEmptyStoryCustomFields(story),
    ...getIterationIdIfEnabled(story),
    ...OptionalProject.getNewStoryValue(story)
  };
};
exports.transformTasksForDuplication = tasks => {
  return _.map(_.sortBy(tasks, 'position') || [], task => {
    return {
      complete: task.complete,
      description: task.description,
      owner_ids: task.owner_ids || []
    };
  });
};
exports.formatEstimate = story => {
  return _.isNumber(story.estimate) ? Format.pluralize(story.estimate, 'point', 'points') : '<em>Unestimated</em>';
};
exports.isUnstartedState = story => {
  const column = ColumnModel.getById(story.workflow_state_id);
  return column && column.type === Constants.WORKFLOW_STATE_TYPES.UNSTARTED;
};
exports.isActiveState = story => {
  const column = ColumnModel.getById(story.workflow_state_id);
  return column && column.type === Constants.WORKFLOW_STATE_TYPES.STARTED;
};
exports.isDoneState = story => {
  const column = ColumnModel.getById(story.workflow_state_id);
  return column && column.type === Constants.WORKFLOW_STATE_TYPES.DONE;
};
exports.isInColumn = (story, column) => {
  const storyColumn = ColumnModel.getById(story.workflow_state_id);
  return storyColumn && column.id === storyColumn.id;
};
exports.groupByWorkflowState = stories => {
  const partitions = _.values(_.groupBy(stories, 'workflow_state_id'));
  return _.sortBy(_.map(partitions, stories => {
    const column = ColumnModel.getById(_.get(_.head(stories), 'workflow_state_id'));
    return {
      column,
      stories,
      teamName: ColumnModel.getTeamName(column)
    };
  }), 'column.position');
};
exports.areAllInDoneState = stories => {
  return _.every(stories, exports.isDoneState);
};
const skipFilterStoryIds = new Set();
exports.shouldSkipFilters = storyId => skipFilterStoryIds.has(storyId);
exports.clearSkipFilterStories = () => skipFilterStoryIds.clear();
exports.isNotFiltered = story => {
  const activeFilters = FilterModel.filter({
    active: true
  });
  const filterIterator = exports.isFilteredByFilter.bind(this, story);
  if (activeFilters.length === 0) {
    // NO filtering
    return true;
  } else if (FilterModel.getCachedFilterType() === 'AND') {
    // AND filtering
    return activeFilters.every(filterIterator);
  } else {
    // OR filtering
    return activeFilters.some(filterIterator);
  }
};
exports.isFilteredByFilter = (story, filter) => {
  let filtered = true;
  try {
    const result = filter.fn(story);
    if (filter.inverse === true && result || !filter.inverse && !result) {
      filtered = false;
    }
  } catch (e) {
    Log.error(e, {
      type: 'Filter error',
      filter
    });
    FilterModel.remove({
      id: filter.id
    });
  }
  return filtered;
};
exports.isOverdue = story => {
  const column = ColumnModel.getById(story.workflow_state_id);
  return column && !ColumnModel.isDone(column) && story.deadline && getIsOverdue(story.deadline);
};
exports.getStoryTypeIcon = story => {
  return Constants.STORY_TYPE_ICONS[story.story_type] || Constants.STORY_TYPE_ICONS.feature;
};
exports.isFeature = story => {
  return story.story_type === 'feature';
};
exports.isBug = story => {
  return story.story_type === 'bug';
};
exports.isChore = story => {
  return story.story_type === 'chore';
};
exports.hasEpic = (story, epic) => {
  if (story.epic_id && epic) {
    return story.epic_id === epic.id;
  }
  return false;
};
exports.getImpliedLabels = story => {
  let labels = [];
  if (!story.epic_id) {
    return labels;
  }
  const epic = EpicModel.getById(story.epic_id);
  if (epic && epic.labels) {
    labels = labels.concat(epic.labels);
  }
  return labels;
};
exports.hasImpliedLabel = (story, labelName) => {
  if (!story.epic_id) {
    return false;
  }
  const epic = EpicModel.getById(story.epic_id);
  if (epic && LabelModel.hasLabel(epic, labelName)) {
    return true;
  }
  return false;
};
exports.isInMilestone = (story, milestone) => {
  if (!story.epic_id || !story.epic) {
    return false;
  }
  return !!(story.epic.milestone_id && story.epic.milestone_id === milestone.id);
};
exports.noMilestone = story => {
  const epic = story.epic || EpicModel.getById(story.epic_id);
  return !epic || !epic.milestone_id;
};
exports.userIsRelated = (story, profile) => {
  return exports.isOwner(story, profile) || exports.isRequester(story, profile);
};
exports.isRequester = (story, profile) => {
  return story.requested_by_id === profile.id;
};
exports.isOwner = (story, profile) => {
  return _.includes(story.owner_ids, profile.id);
};
exports.getProject = story => {
  return ProjectModel.getById(story.project_id);
};
exports.getElements = story => $(`.story-${story.id}`);
exports.getStoryCardElements = story => {
  const selector = `#cid-storyCard-${story.id}`;
  return $(selector);
};
exports.elementsToStories = elements => {
  return elements.map(function () {
    return exports.getById($(this).data('id'));
  });
};
exports.totalPoints = stories => {
  let total = 0;
  Iterate.each(stories, story => {
    total += story.estimate;
  });
  return total;
};
exports.removeOwner = (story, profile, callback) => {
  exports.saveOwnerChange(_.assign({}, story, {
    owner_ids: _.without(story.owner_ids, profile.id)
  }), callback);
};
exports.addOwner = (story, profile, callback) => {
  const ids = _.isArray(story.owner_ids) ? story.owner_ids.slice(0) : [];
  ids.push(profile.id);
  exports.saveOwnerChange(_.assign({}, story, {
    owner_ids: ids
  }), callback);
};
exports.toggleOwner = (story, profile, callback) => {
  if (exports.isOwner(story, profile)) {
    exports.removeOwner(story, profile, callback);
  } else {
    exports.addOwner(story, profile, callback);
  }
};
exports.removeAllOwners = (story, callback) => {
  exports.saveOwnerChange(_.assign({}, story, {
    owner_ids: []
  }), callback);
};
exports.saveOwnerChange = (story, callback) => {
  exports.trigger('ownersSaved', story);
  callback && callback();
  return exports.Promises.serverSave(story, {
    owner_ids: story.owner_ids
  });
};
exports.toggleFollower = (story, profile, callback) => {
  if (exports.isFollower(story, profile)) {
    exports.removeFollower(story, profile, callback);
  } else {
    exports.addFollower(story, profile, callback);
  }
};
exports.currentUserIsFollower = story => {
  const profile = ProfileModel.getCurrentUserProfileDetails();
  return exports.isFollower(story, profile);
};
exports.isFollower = (story, profile) => {
  return _.includes(story.follower_ids, profile.id);
};
exports.addFollower = (story, profile, callback) => {
  const ids = _.isArray(story.follower_ids) ? story.follower_ids.slice(0) : [];
  ids.push(profile.id);
  exports.saveFollowers(_.assign({}, story, {
    follower_ids: ids
  }), callback);
};
exports.removeFollower = (story, profile, callback) => {
  exports.saveFollowers(_.assign({}, story, {
    follower_ids: _.without(story.follower_ids, profile.id)
  }), callback);
};
exports.saveFollowers = (story, callback) => {
  callback = _.isFunction(callback) ? callback : _.noop;
  exports.serverSave(story, {
    follower_ids: story.follower_ids
  }, {
    callback: function (err) {
      if (!err) {
        exports.trigger('followersSaved', story);
      }
      callback.apply(this, arguments);
    }
  });
};
exports.addMeAsFollower = (story, callback) => {
  _addOrRemoveMeAsFollower(story, 'follow', callback);
};
exports.removeMeAsFollower = (story, callback) => {
  _addOrRemoveMeAsFollower(story, 'unfollow', callback);
};

// This is a seperate from saveFollowers, as it's open to observers as well
function _addOrRemoveMeAsFollower(story, type, callback) {
  type = type === 'unfollow' ? 'unfollow' : 'follow';
  callback = _.isFunction(callback) ? callback : _.noop;

  // This is only referenced for consistent logging
  const updates = {
    follower_ids: [UserModel.getLoggedInUserPermissionID()]
  };
  Backend.post('/api/private/stories/' + story.id + '/' + type, {
    onComplete: res => {
      storyUpdateResponseHandler(res, story, updates, {
        callback: function (err) {
          if (!err) {
            exports.trigger('followersSaved', story);
          }
          callback.apply(this, arguments);
        }
      });
    }
  });
}
exports.describeFollowersOrNull = story => {
  if (story.follower_ids.length === 0) {
    return null;
  }
  if (exports.currentUserIsFollower(story)) {
    if (story.follower_ids.length === 1) {
      return 'Just You';
    } else {
      return 'You +' + (story.follower_ids.length - 1);
    }
  } else {
    return story.follower_ids.length;
  }
};
exports.describeFollowers = story => exports.describeFollowersOrNull(story) || '<em>Nobody</em>';
exports.addEpic = (story, epic, callback) => {
  const updates = {
    epic_id: epic.id
  };
  if (!story.group_id) {
    const epic_group_id = EpicModel.getById(epic.id).group_id;

    /** We should only attempt to auto-assign the story's team to be the same as the epic's team
     *  if the story's current workflow is compatible with the epic's team workflow.
     **/
    if (epic_group_id && isValidWorkflowForGroup(epic_group_id, story.workflow_id)) {
      updates.group_id = epic_group_id;
    }
  }
  exports.serverSave(story, updates, {
    callback
  });
};
exports.removeEpic = (story, callback) => {
  exports.serverSave(story, {
    epic_id: null
  }, {
    callback
  });
};
exports.addTeam = (story, teamId, callback) => {
  exports.serverSave(story, {
    group_id: teamId
  }, {
    callback
  });
};
exports.removeTeam = (story, callback) => {
  exports.serverSave(story, {
    group_id: null
  }, {
    callback
  });
};
exports.setWorkflowState = (story, workflowStateId, callback) => {
  exports.serverSave(story, {
    workflow_state_id: workflowStateId
  }, {
    callback
  });
};
exports.setIteration = (story, iterationId, options) => {
  const callback = options?.callback || _.noop;
  const updates = {
    iteration_id: iterationId
  };
  if (!story.group_id) {
    const iteration_group_id = IterationModel.getById(iterationId)?.group_ids[0];
    if (iteration_group_id) {
      updates.group_id = iteration_group_id;
    }
  }
  exports.serverSave(story, updates, {
    ...options,
    callback
  });
};
exports.removeLabel = (story, label, callback) => {
  exports.saveLabels(story, _.reject(_.get(story, 'labels'), {
    name: _.get(label, 'name')
  }), callback);
};
exports.saveLabels = (story, labels, callback) => {
  const updates = {
    labels: LabelModel.denormalizeLabels(labels)
  };
  const options = {
    callback: () => {
      LabelModel.fetchAllSlim(callback);
    }
  };
  exports.serverSave(story, updates, options);
};
exports.saveTitle = (story, name, callback) => {
  exports.serverSave(story, {
    name
  }, {
    callback
  });
};
exports.saveDescription = (story, description, callback) => {
  exports.serverSave(story, {
    description
  }, {
    beforeRender: () => {},
    callback
  });
};
exports.setType = (story, type, callback) => {
  exports.serverSave(story, {
    story_type: type
  }, {
    callback
  });
};
exports.associateBranch = (story, branchId, callback) => {
  const branchIds = _.map(story.branches, 'id');
  branchIds.push(branchId);
  exports.serverSave(story, {
    branch_ids: branchIds
  }, {
    callback
  });
};
exports.dissociateBranch = (story, branchId, callback) => {
  exports.serverSave(story, {
    branch_ids: _.without(_.map(story.branches, 'id'), branchId)
  }, {
    callback
  });
};
exports.dissociatePullRequest = (story, pullRequestId, callback) => {
  exports.serverSave(story, {
    pull_request_ids: _.without(_.map(story.pull_requests, 'id'), pullRequestId)
  }, {
    callback
  });
};
exports.associateCommit = (story, commitId, callback) => {
  const commitIds = _.map(story.commits, 'id');
  commitIds.push(commitId);
  exports.serverSave(story, {
    commit_ids: commitIds
  }, {
    callback
  });
};
exports.dissociateCommit = (story, commitId, callback) => {
  exports.serverSave(story, {
    commit_ids: _.without(_.map(story.commits, 'id'), commitId)
  }, {
    callback
  });
};
exports.addExternalLink = (story, externalLink, callback) => {
  const externalLinks = story.external_links;
  const uniqueExternalLinks = new Set(externalLinks);
  uniqueExternalLinks.add(externalLink);
  exports.serverSave(story, {
    external_links: [...uniqueExternalLinks]
  }, {
    callback
  });
};
exports.removeExternalLink = (story, externalLink, callback) => {
  const externalLinks = story.external_links;
  const uniqueExternalLinks = new Set(externalLinks);
  uniqueExternalLinks.delete(externalLink);
  exports.serverSave(story, {
    external_links: [...uniqueExternalLinks]
  }, {
    callback
  });
};
exports.saveComment = (story, comment, callback) => {
  const url = '/api/private/stories/' + story.id + '/comments';
  Backend.post(url, {
    data: {
      text: comment
    },
    onComplete: res => {
      if (_.get(res, 'error')) {
        exports.defaultErrorHandler(res, callback);
      } else {
        exports.fetchStory(story.id, callback);
      }
    }
  });
};
exports.updateComment = (story, comment_id, comment, callback) => {
  const url = '/api/private/stories/' + story.id + '/comments/' + comment_id;
  Backend.put(url, {
    data: {
      text: comment
    },
    onComplete: res => {
      if (_.get(res, 'error')) {
        exports.defaultErrorHandler(res, callback);
      } else {
        exports.fetchStory(story.id, callback);
      }
    }
  });
};
exports.deleteComment = (story, comment_id, callback) => {
  const url = '/api/private/stories/' + story.id + '/comments/' + comment_id;
  Backend.delete(url, {
    onComplete: (res, xhr) => {
      if (xhr.status === 204 || xhr.status === 404) {
        story.comment_ids = _.without(story.comment_ids, comment_id);
        _.remove(story.comments, {
          id: comment_id
        });
        callback();
      } else {
        exports.defaultErrorHandler(res, callback);
      }
    }
  });
};
exports.serverSave = (story, updates, options) => {
  Backend.put('/api/private/stories/' + story.id, {
    data: updates,
    onComplete: res => {
      storyUpdateResponseHandler(res, story, updates, options);
      dispatchEvent(EVENT_TYPES.STORY_UPDATED);
    }
  });
};
exports.serverSaveBasic = ({
  id,
  ...changes
}, callback) => {
  Backend.put(`/api/private/stories/${id}`, {
    data: changes,
    onSuccess: res => {
      exports.defaultGetHandler(res);
      callback(null, res);
      dispatchEvent(EVENT_TYPES.STORY_UPDATED);
    },
    onError: res => {
      exports.defaultErrorHandler(res, callback);
    }
  });
};
exports.Promises.serverSave = (story, updates, options) => new Promise((resolve, reject) => {
  Backend.put('/api/private/stories/' + story.id, {
    data: updates,
    onComplete: res => {
      if (exports.isValid(res)) {
        if (updates.workflow_state_id) {
          // We need to update num_stories now.
          WorkflowModel.fetchAll();
        }
        if (updates.labels && res.labels) {
          LabelModel.updateMultiple(res.labels);
        }
        exports.updateStoryOnResponse(res, {
          ...options,
          callback: (err, data) => {
            if (err) {
              reject(err);
            } else {
              resolve(data);
              dispatchEvent(EVENT_TYPES.STORY_UPDATED);
            }
          }
        });
      } else {
        reject(res);
      }
    }
  });
});
function storyUpdateResponseHandler(response, story, updates, options) {
  const callback = _.isFunction(_.get(options, 'callback')) ? options.callback : _.noop;
  if (exports.isValid(response)) {
    if (updates.workflow_state_id) {
      // We need to update num_stories now.
      WorkflowModel.fetchAll();
    }
    if (updates.labels && response.labels) {
      LabelModel.updateMultiple(response.labels);
    }
    exports.updateStoryOnResponse(response, options);
  } else {
    exports.defaultErrorHandler(response, callback);
    // TODO: Find out why we fetch on error after saving...
    exports.fetchStory(story.id);
  }
}
exports.updateStoryOnResponse = (res, options) => {
  options = options || {};
  options.callback = _.isFunction(options.callback) ? options.callback : _.noop;
  if (exports.isValid(res)) {
    if (_.isFunction(options.beforeRender)) {
      options.beforeRender(res);
    }
    exports.update(res);
    const story = exports.getById(res.id);
    exports.trigger('storySaved', story);
    if (_.isFunction(options.afterRender)) {
      options.afterRender(res);
    }
    StoryHistoryModel.fetch(story);
    options.callback(null, story);
  } else {
    exports.defaultErrorHandler(res, options.callback);
  }
};
const getCurrentlyDisplayedStoryId = () => {
  const mobileEl = $('.story-page');
  const dialogEl = $('.story-dialog');
  if (mobileEl.length > 0) {
    return Utils.getModelFromContext(mobileEl)?.id || null;
  }
  if (dialogEl.length > 0) {
    return Utils.getModelFromContext(dialogEl)?.id || null;
  }
  return null;
};
exports.fetchStory = (id, callback) => {
  callback = _.isFunction(callback) ? callback : _.noop;
  Backend.get('/api/private/stories/' + id, {
    onComplete: res => {
      if (exports.isValid(res)) {
        if (getCurrentlyDisplayedStoryId() === id) {
          exports.normalizeStoryDetails(res);
        }
        exports.update(res);
        const story = exports.getById(res.id);
        exports.trigger('latestVersionFetched', story);
        callback(null, story);
      } else {
        exports.defaultErrorHandler(res, callback);
      }
    }
  });
};
exports.getOrFetchStory = id => {
  const story = exports.getById(id);
  return story ? Promise.resolve(story) : exports.Promises.fetchStory(id);
};
exports.fetchStoryPromise = async id => {
  const res = await Backend.get('/api/private/stories/' + id);
  if (exports.isValid(res)) {
    exports.update(res);
    return exports.getById(res.id);
  } else {
    throw 'fetched invalid Story data';
  }
};
exports.addStory = (story, options, callback) => {
  options = _.isPlainObject(options) ? options : {};
  callback = _.isFunction(callback) ? callback : _.noop;

  // If the story was created from a template or cloned from another story, we
  //  need to clean some stuff out of the custom fields list
  const storyCustomFields = CustomFieldModel.generateEmptyStoryCustomFields(story);
  if (storyCustomFields.length) {
    story.custom_fields = CustomFieldModel.sanitizeStoryCustomFieldsPatch(storyCustomFields);
  }
  Backend.post('/api/private/stories', {
    data: {
      ...exports.transformToNewStory(story),
      move_to: options.move_to
    },
    actionContext: options.actionContext,
    onComplete: res => {
      if (exports.isValid(res)) {
        // The current user may have just created a story with a new label,
        // which we don't otherwise know about, so let's add those new labels here.
        // Ref: https://app.shortcut.com/internal/story/21200/creating-a-label-from-the-story
        Iterate.each(res.labels, label => {
          if (!LabelModel.getById(label.id)) {
            LabelModel.updateIfValid(label);
          }
        });
        if (options.skipFilters) {
          skipFilterStoryIds.add(res.id);
        }
        if (options.actionContext === 'stories') {
          logEvent(EVENTS.Story_Created_In_Space, {
            space_name: SpaceModel.getActive()?.name ?? 'Unknown',
            workspace_id: OrganizationModel.getCurrentID(),
            workflow_id: res.workflow_id
          });
        }
        exports.update(res);
        TaskModel.deleteTemporaryTasks();
        if (story.source_task_id && story.source_task_story_id) {
          const sourceTask = (exports.getById(story.source_task_story_id)?.tasks || []).find(t => t.id === story.source_task_id);
          if (sourceTask) {
            TaskModel.saveChanges(sourceTask, {
              complete: true,
              description: `${sourceTask.description} _was converted to_ ${res.app_url}`
            }, (err, newTask) => {
              if (!err) TaskModel.update(newTask);
            });
          }
        }
        StoryLinkModel.addStoryLinksToStories(res);
        exports.sortStoriesByPosition();
        exports.trigger('newStorySaved');
        simpleCompleteTask('create-story');
        callback(null, res);
      } else {
        Log.log('There was a problem saving the story.', {
          response: JSON.stringify(res),
          type: 'API'
        });
        exports.defaultErrorHandler(res, callback);
      }
    }
  });
};
exports.createMultiple = (stories, callback) => {
  callback = _.isFunction(callback) ? callback : _.noop;
  Backend.post('/api/private/stories/bulk', {
    data: {
      stories
    },
    onComplete: res => {
      exports.defaultFetchSomeHandler(res, callback);
    }
  });
};
exports.deleteStory = (story, callback) => {
  callback = _.isFunction(callback) ? callback : _.noop;
  Backend.delete('/api/private/stories/' + story.id, {
    onComplete: (res, xhr) => {
      if (xhr.status === 204) {
        exports.remove({
          id: story.id
        });
        BulkSelectionModel.removeFromSelection(story.id);
        Event.trigger('storyDeleted', story);
        callback(null);
      } else {
        Log.log('There was a problem deleting the story.', {
          response: res,
          type: 'API'
        });
        exports.defaultErrorHandler(res, callback);
      }
    }
  });
};
exports.archiveStory = (story, callback) => {
  callback = _.isFunction(callback) ? callback : _.noop;
  exports.serverSave(story, {
    archived: true
  }, {
    callback: function () {
      BulkSelectionModel.removeFromSelection(story.id);
      callback.apply(this, arguments);
    }
  });
};
exports.unarchiveStory = (story, callback) => {
  exports.serverSave(story, {
    archived: false
  }, {
    callback
  });
};
exports.getStoriesById = (storyIds, callback) => {
  callback = _.isFunction(callback) ? callback : _.noop;
  if (!_.isArray(storyIds) || storyIds.length === 0) {
    callback();
    return false;
  }
  Backend.post('/api/private/stories/lookup', {
    data: {
      story_ids: storyIds
    },
    onComplete: res => {
      if (_.isPlainObject(res) && res.found_stories) {
        exports.updateMany(res.found_stories, {
          force: true
        });
        callback(null, res.not_found_story_ids, res.found_stories.length > 0);
      } else {
        Log.log('There was a problem fetching the stories.', {
          response: JSON.stringify(res),
          type: 'API'
        });
        exports.defaultErrorHandler(res, callback);
      }
    }
  });
};
exports.fetchStoriesApproachingDuedate = callback => {
  exports.searchStories({
    archived: false,
    deadline_start: moment().subtract(2, 'weeks').format(),
    deadline_end: moment().add(1, 'month').format(),
    workflow_state_types: ['backlog', 'unstarted', 'started']
  }, callback);
};
const _subtractOldFetchWindowDays = m => m.clone().subtract(DAYS_OLD_TO_FETCH, 'days');

// Fetches stories that were completed between 30 and 60 days prior to lastCompletedBefore
// The new completedBefore boundary date is passed to the callback.
exports.fetchOldCompletedStories = ({
  lastCompletedBefore,
  workflowStateId
}, callback) => {
  // Using 30 days instead of 31 days here to avoid any timing/time zone issues
  const completedBefore = _subtractOldFetchWindowDays(lastCompletedBefore);
  const completedAfter = _subtractOldFetchWindowDays(completedBefore);
  exports.searchStories({
    archived: false,
    completed_at_start: completedAfter.format(),
    completed_at_end: completedBefore.format(),
    workflow_state_id: workflowStateId
  }, () => callback(completedBefore));
};

// Fetches stories that were completed more than 30 days prior to lastCompletedBefore
exports.fetchAllOldCompletedStories = ({
  lastCompletedBefore,
  workflowStateId
}, callback) => {
  // Using 30 days instead of 31 days here to avoid any timing/time zone issues
  const completedBefore = _subtractOldFetchWindowDays(lastCompletedBefore);
  exports.searchStories({
    archived: false,
    completed_at_end: completedBefore.format(),
    workflow_state_id: workflowStateId
  }, callback);
};
exports.searchStories = (query, callback) => {
  callback = _.isFunction(callback) ? callback : _.noop;
  Backend.post('/api/private/stories/search', {
    data: query,
    onComplete: res => {
      if (_.isArray(res)) {
        exports.updateMany(res);
      }
      callback();
    }
  });
};
const searchAndHandleWithDefaultHandler = ({
  query
}, callback = noop) => {
  Backend.post('/api/private/stories/search', {
    data: query,
    onSuccess: res => {
      exports.defaultFetchAllHandler(res, noop, false);
      callback(null, res);
    },
    onError: res => {
      exports.defaultErrorHandler(res, callback);
    }
  });
};
exports.updateMany = (stories, options) => {
  options = options || {};
  if (stories.length > 0) {
    exports.trigger('bulkStart');
    Iterate.each(stories, story => {
      // We do an isUpdated check here so we execute the expensive Story.normalize function only when needed.
      if (exports.isValid(story) && (exports.isUpdated(story) || options.force === true)) {
        exports.updateById(story);
      }
    });
    exports.trigger('bulkEnd');
  }
  exports.sortStoriesByPosition();
  Event.trigger('manyStoriesSaved');
};
exports.attachLinkedFileToStory = (story, file) => {
  story.linked_files = story.linked_files || [];
  story.linked_file_ids = story.linked_file_ids || [];
  story.linked_files.push(file);
  story.linked_file_ids = _.map(story.linked_files, 'id');

  // We don't save the story to server as the transaction
  // of uploading a linked file does that on the backend.
  Event.trigger('filesAttached', story);
};
exports.attachFilesToStory = (story, files) => {
  if (!_.isArray(story.files)) {
    story.files = [];
  }

  // TODO: Not sure if we need this guard:
  if (_.isPlainObject(files)) {
    files = [files];
  }
  story.files = story.files.concat(files);
  story.file_ids = _.map(story.files, 'id');
  exports.serverSave(story, {
    file_ids: story.file_ids
  });
  Event.trigger('filesAttached', story);
};
exports.deleteFileAttachment = (story, file_id) => {
  _.remove(story.files, {
    id: file_id
  });
  story.file_ids = _.map(story.files, 'id');
  exports.serverSave(story, {
    file_ids: story.file_ids
  });
  exports.trigger('fileDeleted', story);
};
exports.deleteLinkedFileAttachment = (story, file_id) => {
  LinkedFileModel.removeStoryFromFile({
    id: file_id
  }, story);
  _.remove(story.linked_files, {
    id: file_id
  });
  story.linked_file_ids = _.map(story.linked_files, 'id');
  exports.serverSave(story, {
    linked_file_ids: story.linked_file_ids
  });
  exports.trigger('fileDeleted', story);
};
exports.allRecentlyCompletedAndOwnedByMe = () => {
  const profile = ProfileModel.getCurrentUserProfileDetails();
  if (!profile) {
    return [];
  }
  return exports.filter(story => {
    return !exports.isArchived(story) && !exports.hasArchivedParent(story) && _.includes(story.owner_ids, profile.id) && _wasCompletedWithinLastBusinessDay(story);
  });
};
exports.allIncludingRecentlyCompleted = () => {
  return exports.filter(story => {
    return !exports.isArchived(story) && !exports.hasArchivedParent(story) && (exports.isActive(story) || _wasCompletedWithinLastBusinessDay(story));
  });
};
exports.allIncludingCompletedSince = startTime => {
  return exports.filter(story => {
    return !exports.isArchived(story) && !exports.hasArchivedParent(story) && (exports.isActive(story) || _wasCompletedSince(startTime, story));
  });
};
exports.fetchActiveStoriesForActiveUsers = callback => {
  const profiles = ProfileModel.getAllActiveProfileDetails();
  const query = {
    archived: false,
    owner_ids: _.map(profiles, 'id'),
    workflow_state_types: ['backlog', 'unstarted', 'started']
  };
  exports.searchStories(query, callback);
};
const fetchCompletedStoriesForProfiles = (profiles, startDateMoment, callback) => {
  const query = {
    archived: false,
    owner_ids: _.map(profiles, 'id'),
    workflow_state_types: ['done'],
    completed_at_start: startDateMoment.format()
  };
  exports.searchStories(query, callback);
};
exports.fetchRecentlyCompletedStoriesForActiveUsers = (startDateMoment, callback) => {
  const profiles = ProfileModel.getAllActiveProfileDetails();
  fetchCompletedStoriesForProfiles(profiles, startDateMoment, callback);
};
exports.fetchRecentlyCompletedStoriesForProfiles = (profiles, callback) => {
  fetchCompletedStoriesForProfiles(profiles, Utils.getLastBusinessDay(), callback);
};
function _wasCompletedWithinLastBusinessDay(story) {
  return moment.utc(story.completed_at).isBetween(Utils.getLastBusinessDay(), moment(), null, '[]') && exports.isDoneState(story);
}
function _wasCompletedSince(startDate, story) {
  return moment.utc(story.completed_at).isBetween(startDate, moment(), null, '[]') && exports.isDoneState(story);
}
exports.allWithLabel = label => {
  return exports.filter(story => {
    return LabelModel.hasLabel(story, label);
  });
};
exports.allInActiveState = () => {
  return exports.filter(story => {
    return exports.isActiveState(story);
  });
};
exports.allActiveAndOwnedByMe = () => {
  const profile = ProfileModel.getCurrentUserProfileDetails();
  if (!profile) {
    return [];
  }
  return exports.filter(story => {
    return exports.isActive(story) && _.includes(story.owner_ids, profile.id);
  });
};
exports.allActive = () => {
  return exports.filter(exports.isActive);
};
exports.isActive = story => {
  if (exports.isArchived(story)) {
    return false;
  }
  if (exports.hasArchivedParent(story)) {
    return false;
  }
  if (!exports.isDoneState(story)) {
    return true;
  }
};
exports.withoutUnfinishedArchivedStories = stories => {
  stories = stories || exports.all();
  return _.reject(stories, story => {
    return exports.isArchived(story) && !exports.isDoneState(story);
  });
};
exports.sortStoriesByPosition = () => {
  exports.db.sort((a, b) => {
    return a.position - b.position;
  });
};
exports.getLastUpdated = stories => {
  return _.last(_.map(_.map(stories, 'updated_at'), d => {
    return new Date(d).getTime();
  }).sort());
};
exports.deleteLastEmptyTask = story => {
  const lastTask = _.last(story.tasks);
  if (lastTask && lastTask.description === '') {
    TaskModel.deleteTask(lastTask);
  }
};
exports.getPullRequests = story => {
  if (!story.pull_requests) {
    return _.sortBy(_.flatMap(story.branches, 'pull_requests'), 'number');
  } else {
    return _.sortBy(story.pull_requests, 'number');
  }
};
exports.isUsingGitLab = story => {
  // If we have  multiple providers, we default to `github`.
  if (story.pull_requests?.every(pr => pr?.repository?.type === 'gitlab')) {
    return true;
  }
  return false;
};
exports.getSoloBranches = story => {
  const branches = [];
  Iterate.each(story.branches, branch => {
    if (branch.pull_requests.length === 0) {
      branches.push(branch);
    }
  });
  return branches;
};
exports.getStaleIcon = (project, daysOld) => {
  const delta = daysOld - project.days_to_thermometer;
  let icon = '';
  if (delta > 0 && delta <= 7) {
    icon = 'fa-hourglass-start';
  } else if (delta >= 8 && delta <= 14) {
    icon = 'fa-hourglass-half';
  } else if (delta >= 15) {
    icon = 'fa-hourglass-end';
  }
  return icon;
};
exports.isStale = (story, daysOld) => {
  // A Story cannot be stale without a project since the project has the stale meta data
  if (!exports.hasProject(story)) return false;
  if (!exports.isActiveState(story)) {
    return false;
  }
  daysOld = daysOld || ageInDays(story.updated_at);
  const project = story.project || ProjectModel.getById(story.project_id);
  return project.show_thermometer && daysOld > project.days_to_thermometer;
};
exports.isValidForAutoLink = (story // We can render the minimal amount, with the tooltip and dialog lazy loading the story.
) => {
  return Utils.hasKeys(story, [
  // 'archived',
  'id', 'name', 'story_type']);
};
exports.changePriority = (story, options = {}) => {
  const storyCardSelector = '.js-story-card';
  const storyElement = exports.getStoryCardElements(story);
  const updates = {};
  if (options.last) {
    updates.move_to = 'last';
  } else if (options.first) {
    updates.move_to = 'first';
  } else if (storyElement.length === 0) {
    return {};
  } else {
    const targets = storyElement.prevAll(storyCardSelector);
    if (targets.length === 0) {
      const target = storyElement.nextAll(storyCardSelector)[0];
      if (target) {
        const id = Utils.data(target, 'id');
        if (!exports.isDoneState(exports.getById(id))) {
          updates.move_to = 'first';
        }
      }
    } else {
      updates.after_id = Utils.data(targets[0], 'id');
    }
  }
  return updates;
};
exports.deleteReaction = (storyId, commentId, emoji) => {
  Backend.delete(`/api/private/stories/${storyId}/comments/${commentId}/reactions`, {
    data: {
      emoji
    }
  });
};
exports.addReaction = (storyId, commentId, emoji) => {
  Backend.post(`/api/private/stories/${storyId}/comments/${commentId}/reactions`, {
    data: {
      emoji
    }
  });
};
const fetchRecentStoriesForWorkflowCount = ({
  id
}, callback) => {
  Backend.get(`/api/private/workflows/${id}/count-relevant-stories`, {
    onSuccess: res => {
      callback(null, res);
    },
    onError: res => {
      exports.defaultErrorHandler(res, callback);
    }
  });
};
const fetchRecentStoriesForWorkflowPage = ({
  id,
  offset,
  limit = 1000
}, callback = noop) => {
  Backend.post(`/api/private/workflows/${id}/relevant-stories-with-pages`, {
    data: {
      limit,
      offset
    },
    onSuccess: res => {
      exports.defaultFetchAllHandler(res.edges, noop, false);
      callback(null, res);
    },
    onError: res => {
      exports.defaultErrorHandler(res, callback);
    }
  });
};
const fetchRecentStoriesForWorkflow = ({
  id
}, callback = noop) => {
  Backend.get(`/api/private/workflows/${id}/relevant-stories`, {
    onSuccess: res => {
      exports.defaultFetchAllHandler(res.edges, noop, false);
      callback(null, res);
    },
    onError: res => {
      exports.defaultErrorHandler(res, callback);
    },
    onComplete: () => {
      Event.trigger(`doneFetchingStories.${id}`, id);
    }
  });
};
exports.Promises.fetchStory = BaseUtils.promisify(exports.fetchStory);
exports.Promises.saveChanges = exports.Promises.serverSave;
exports.Promises.serverSaveBasic = BaseUtils.promisify(exports.serverSaveBasic);
exports.Promises.searchStories = BaseUtils.promisify(searchAndHandleWithDefaultHandler);
exports.Promises.addStory = BaseUtils.promisify(exports.addStory);
exports.Promises.deleteStory = BaseUtils.promisify(exports.deleteStory);
exports.Promises.fetchRecentStoriesForWorkflow = BaseUtils.promisify(fetchRecentStoriesForWorkflow);
exports.Promises.fetchRecentStoriesForWorkflowPage = BaseUtils.promisify(fetchRecentStoriesForWorkflowPage);
exports.Promises.fetchRecentStoriesForWorkflowCount = BaseUtils.promisify(fetchRecentStoriesForWorkflowCount);
export { exports as default };