import { useCallback, useRef } from 'react';
export function useSingleClick(_fn) {
  const wasClicked = useRef(false);
  const fn = useCallback((...args) => {
    if (!wasClicked.current) {
      wasClicked.current = true;
      _fn(...args);
    }
  }, [_fn]);
  const reset = useCallback(() => {
    wasClicked.current = false;
  }, []);
  return {
    fn,
    reset
  };
}