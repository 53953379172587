import "core-js/modules/es.array.push.js";
import ProfileModel from 'app/client/core/js/models/profile.js';
window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || {
    Controller: {},
    Model: {}
  };
  [[['Model', 'Profile'], ProfileModel], [['Model', 'Profile'], ProfileModel]].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});
import { LoadingDots } from '@clubhouse/shared/animations';
import { Avatar } from '@clubhouse/shared/components/Avatar';
import { SizedIcon } from '@clubhouse/shared/components/SizedIcon';
import { UnstyledButton } from '@clubhouse/shared/components/UnstyledButton';
import { useToggleState } from '@clubhouse/shared/hooks';
import { capitalize } from '@clubhouse/shared/utils';
import { isLoggedInUserAdmin } from 'data/entity/user';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export function DisabledUser({
  profile,
  scimEnabled,
  onRestoreUser
}) {
  const [isRestoring, toggleRestoring] = useToggleState();
  return _jsxs("div", {
    className: "profile-container",
    "data-model": "Profile",
    "data-id": profile.id,
    children: [_jsx(Avatar, {
      profile: profile,
      size: Avatar.SIZE.XL
    }), _jsxs("div", {
      className: "info-container",
      children: [_jsx("div", {
        className: "name",
        children: profile.name
      }), _jsxs("div", {
        className: "username",
        children: ["@", profile.mention_name]
      }), _jsx("div", {
        className: "email",
        children: _jsx("a", {
          href: `mailto:${profile.email_address}`,
          children: profile.email_address
        })
      })]
    }), _jsx("div", {
      className: "role-dropdown-container",
      children: _jsx("div", {
        className: "role disabled",
        children: capitalize(profile.role)
      })
    }), isLoggedInUserAdmin() && !scimEnabled && _jsx("div", {
      className: "actions",
      children: _jsx(UnstyledButton, {
        disabled: isRestoring,
        onClick: async () => {
          try {
            toggleRestoring.on();
            await onRestoreUser(profile.id);
          } catch {} finally {
            toggleRestoring.off();
          }
        },
        "data-tooltip": `Restore ${profile.name}`,
        children: isRestoring ? _jsx(LoadingDots, {}) : _jsx(SizedIcon, {
          icon: "Sync",
          label: `Restore ${profile.name}`
        })
      })
    })]
  });
}
DisabledUser.displayName = "DisabledUser";