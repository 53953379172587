import { Button } from '@clubhouse/shared/components/Button';
import { CopyValue } from '@clubhouse/shared/components/CopyValue';
import { SizedIcon } from '@clubhouse/shared/components/SizedIcon';
import { Spaced } from '@clubhouse/shared/components/Spaced';
import { TextInput } from '@clubhouse/shared/components/TextInput';
import { Layout } from './Layout';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const CopyWebhookUrl = ({
  label,
  installation
}) => {
  const {
    copyValue,
    tooltipVisible
  } = CopyValue.useCopyWithTooltip(installation.webhook_url);
  return _jsxs(Spaced, {
    vertically: true,
    amount: "space2",
    children: [_jsxs(Spaced, {
      horizontally: true,
      amount: "max",
      align: "center",
      children: [_jsx("div", {
        style: {
          marginBottom: -10
        },
        children: _jsx(Layout.SectionTitle, {
          children: label
        })
      }), _jsx(CopyValue.Tooltip, {
        visible: tooltipVisible,
        children: _jsx(Button, {
          kind: Button.KIND.TERTIARY,
          size: Button.SIZE.SMALL,
          fit: Button.FIT.SMALL,
          IconRight: () => _jsx(SizedIcon, {
            icon: "Copy",
            size: 14
          }),
          onClick: () => copyValue(),
          children: "Copy to clipboard"
        })
      })]
    }), _jsx(TextInput, {
      value: installation.webhook_url,
      isReadOnly: true
    })]
  });
};
CopyWebhookUrl.displayName = "CopyWebhookUrl";