import { CopyInput } from '@clubhouse/shared/components/CopyInput';
import { generateStoryUrl } from 'data/entity/story';
import { getCurrentOrigin } from 'utils/navigation';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const StoryPermalink = ({
  story
}) => {
  return _jsxs("div", {
    className: "attribute",
    children: [_jsx("span", {
      className: "name",
      children: "Permalink"
    }), _jsx(CopyInput, {
      value: `${getCurrentOrigin()}${generateStoryUrl(story)}`,
      className: "clipboard"
    })]
  });
};
StoryPermalink.displayName = "StoryPermalink";