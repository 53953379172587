export const createKeyCountFn = ({
  map = new Map()
} = {
  map: new Map()
}) => {
  return id => {
    const count = map.get(id) || 0;
    map.set(id, count + 1);
    return count;
  };
};