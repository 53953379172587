import { FilterField } from '@clubhouse/shared/components/FilterField';
import { TableColumnSelect } from '@clubhouse/shared/components/Table/TableColumnSelect';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export function TableColumnSelectFilterField({
  permaId
}) {
  return _jsx(FilterField, {
    label: "Display",
    permaId: permaId,
    children: _jsx(TableColumnSelect, {})
  });
}
TableColumnSelectFilterField.displayName = "TableColumnSelectFilterField";