import "core-js/modules/es.array.push.js";
import Tooltip from 'app/client/core/js/modules/tooltip.js';
window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || {
    Controller: {},
    Model: {}
  };
  const Tooltip = exports;
  [[['Tooltip'], Tooltip], [['Tooltip'], Tooltip]].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});
import * as TooltipTemplate from 'app/client/core/views/templates/tooltip.html';
import * as TooltipContentsTemplate from 'app/client/core/views/templates/tooltipContents.html';
import Dom from './dom';
import * as Event from '../_frontloader/event';
import Format from './format';
import Utils from './utils';
import View from './view';
import _ from 'lodash';
const exports = {};
const DEFAULT_DELAY = 300;
const TOP_PADDING = 15;
const HORIZONTAL_PADDING = 12;
const NS = '.App.Tooltip'; // Event namespace

const self = {
  active: false,
  align: 'top',
  fixed: false,
  tooltip: null,
  target: null,
  timeout: null,
  interval: null,
  throttledMouseMove: null,
  lastMousemoveEvent: null
};
exports.resetPosition = () => {
  setPosition(self.lastMousemoveEvent);
};
function setPosition(e) {
  if (self.tooltip && self.target) {
    const offset = self.target.offset();
    let left = getLeftPosition(e);
    let top = 0;
    if (self.align === 'top') {
      top = offset.top - self.tooltip.outerHeight() - window.scrollY - TOP_PADDING;
    } else {
      top = offset.top + self.target.outerHeight() - window.scrollY + TOP_PADDING;
    }
    left = Math.max(HORIZONTAL_PADDING, Math.min($(window).width() - HORIZONTAL_PADDING - self.tooltip.outerWidth(), left));
    top = Math.max(HORIZONTAL_PADDING, Math.min($(window).height() - HORIZONTAL_PADDING - self.tooltip.outerHeight(), top));
    self.tooltip.css({
      left,
      top
    });
    exports.alignArrow(e.pageX);
  }
}
function getLeftPosition(e) {
  let left;
  let delta;
  if (self.fixed) {
    delta = self.tooltip.outerWidth() - self.target.outerWidth();
    if (delta > 0) {
      left = self.target.offset().left - Math.abs(delta) / 2;
    } else {
      left = self.target.offset().left + Math.abs(delta) / 2;
    }
  } else {
    left = e.pageX - self.tooltip.outerWidth() / 2 - window.scrollX;
  }
  return left;
}
function setClosingBoundaries() {
  Utils.nextMoveOutsideElement(self.target, () => {
    exports.close();
  });
}
exports.close = () => {
  $('body').off('mousemove' + NS);
  clearTimeout(self.timeout);
  clearInterval(self.interval);
  self.target = null;
  self.active = false;
  if (self.tooltip) {
    self.tooltip.remove();
  }
  self.tooltip = null;
  self.throttledMouseMove?.cancel();
  self.throttledMouseMove = null;
  self.lastMousemoveEvent = null;
};
exports.closeIfRemovedFromDocument = () => {
  if (self.target && !$.contains(document, self.target[0])) {
    exports.close();
  }
};
exports.isActive = () => {
  return self.active;
};
exports.alignArrow = x => {
  if (self.tooltip) {
    const arrowWidth = 16;
    const edgePaddingLeft = 20;
    const edgePaddingRight = 20 + arrowWidth;
    const center = self.fixed ? self.tooltip.outerWidth() / 2 : x - Number.parseInt(self.tooltip.css('left'), 10);
    const left = center - arrowWidth / 2;
    self.tooltip.find('.tooltip-arrow').css({
      left: Math.max(edgePaddingLeft, Math.min(self.tooltip.width() - edgePaddingRight, left))
    });
  }
};
exports.renderTooltip = options => {
  const html = TooltipTemplate.render({
    align: self.align,
    maxWidth: options.maxWidth,
    extraClass: Utils.data(options.target, 'tooltip-class') || '',
    html: options.html
  });
  if (self.tooltip) {
    self.tooltip.remove();
  }
  self.tooltip = View.attach(html, 'body');
};
exports.open = options => {
  if (!self.active) {
    self.align = Utils.data(options.target, 'tooltip-align') || 'top';
    self.fixed = Utils.data(options.target, 'tooltip-fixed') || false;
    self.active = true;
    self.target = $(options.target);
    exports.renderTooltip(options);
    exports.handleEdgePositioning(options);
    setPosition(options.e);
    setClosingBoundaries();

    // Let's hide after setting tooltip position.
    self.tooltip.hide();
    if (!self.fixed) {
      self.throttledMouseMove = _.throttle(e => {
        setPosition(e);
        self.lastMousemoveEvent = e;
      }, 20);
      $('body').on('mousemove' + NS, self.throttledMouseMove);
    }
    self.timeout = setTimeout(() => {
      if (self.tooltip && self.target) {
        self.tooltip.fadeIn(60);
      }
    }, options.delay || 0);
    self.interval = setInterval(exports.closeIfRemovedFromDocument, 250);
  }
};
exports.handleEdgePositioning = options => {
  if (self.tooltip && self.target) {
    const offset = self.target.offset();
    if (self.align === 'top' && offset.top < self.tooltip.height() + 80) {
      self.align = 'bottom';
      exports.renderTooltip(options);
    }
  }
};

// Extracted from controller/story.copyURLToClipboard
exports.flashNewTooltipMessage = (context, event, message) => {
  const tooltipDataAttribute = 'data-tooltip';
  const initialTooltip = context.getAttribute(tooltipDataAttribute);

  // Manually close and reinit the tooltip, because modules/Tooltip
  // does not update automatically when a data-attribute is updated.
  exports.close();
  context.setAttribute(tooltipDataAttribute, message);
  exports.initTooltip(event);
  setTimeout(() => {
    context.setAttribute(tooltipDataAttribute, initialTooltip);
  }, 2000);
};
exports.initTooltip = e => {
  // Makes sense to optimize this since this happens so often.
  const target = Dom.closest(e.target, '[data-tooltip]');

  // Could be that the cursor is not over any element with a data-tooltip attribute.
  if (!target) {
    return;
  }

  // Don't continually re-render tooltips if the tooltip is already active.
  if (self.target && self.active && target === self.target[0]) {
    return;
  }
  const reactTooltipFnName = Utils.data(target, 'tooltip-react-fn');
  if (reactTooltipFnName) {
    const fn = Utils.strToFunction(reactTooltipFnName);
    return fn(target);
  }
  const text = Format.sanitize(Utils.data(target, 'tooltip'));
  // When providing dynamic content via a function, it is that
  // functions responsibility to properly sanitize the data.
  const tooltipFnText = Utils.data(target, 'tooltip-fn');
  if (text || tooltipFnText) {
    const tooltipFn = tooltipFnText ? Utils.strToFunction(tooltipFnText) : false;
    const tooltipTitle = _.isFunction(tooltipFn) ? tooltipFn.call(target) : text;
    const tooltipDescription = Format.sanitize(Utils.data(target, 'tooltip-description')) || '';
    const tooltipShortcut = Format.sanitize(Utils.data(target, 'tooltip-shortcut')) || '';
    if (tooltipTitle || tooltipDescription) {
      exports.open({
        e,
        target,
        delay: Utils.data(target, 'tooltip-delay') || DEFAULT_DELAY,
        maxWidth: Utils.data(target, 'tooltip-max-width'),
        html: TooltipContentsTemplate.render({
          title: tooltipTitle,
          shortcut: tooltipShortcut,
          description: tooltipDescription
        })
      });
    }
  }
};
exports.init = () => {
  Event.onlyOn('mouseMoved.Tooltip', exports.initTooltip);
};
export { exports as default };