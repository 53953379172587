import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const Up = ({
  fill,
  width,
  className
}) => _jsx("svg", {
  className: className,
  width: width,
  height: width,
  viewBox: "0 0 8 6",
  fill: fill,
  children: _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M6.71943e-06 4.75C6.73126e-06 4.61467 0.0493398 4.49733 0.148007 4.398L3.64801 0.898C3.74667 0.799333 3.86401 0.75 4.00001 0.75C4.13601 0.75 4.25334 0.799333 4.35201 0.898L7.85201 4.398C7.95067 4.49667 8.00001 4.614 8.00001 4.75C8.00001 4.886 7.95067 5.00333 7.85201 5.102C7.75334 5.20067 7.63601 5.25 7.50001 5.25L0.500007 5.25C0.364673 5.25 0.24734 5.20067 0.148006 5.102C0.0486731 5.00333 -0.000659959 4.886 6.71943e-06 4.75Z"
  })
});
Up.displayName = "Up";