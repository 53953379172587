import { useMemo } from 'react';
import { getTeamColor } from '@clubhouse/shared/utils/teams';
import { Tooltip } from '../Tooltip';
import { TeamTooltipContent as TeamTooltipContentPrimitive } from './components';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const DefaultTeamTooltipContent = ({
  associatedEntityType,
  associatedTeams,
  imageUrl,
  members,
  mentionName,
  name,
  owningTeamNumberOfStories,
  totalAssociatedTeamsCount,
  totalMemberCount,
  teamColor
}) => {
  return _jsxs(TeamTooltipContentPrimitive, {
    associatedEntityType: associatedEntityType,
    associatedTeams: associatedTeams,
    children: [_jsx(TeamTooltipContentPrimitive.OwningTeam, {
      imageUrl: imageUrl,
      teamColor: teamColor,
      name: name,
      mentionName: mentionName,
      members: members,
      totalMemberCount: totalMemberCount || members?.length,
      owningTeamNumberOfStories: owningTeamNumberOfStories
    }), _jsx(TeamTooltipContentPrimitive.AssociatedTeams, {
      totalTeamsCount: totalAssociatedTeamsCount
    })]
  });
};
DefaultTeamTooltipContent.displayName = "DefaultTeamTooltipContent";
export const TeamTooltip = ({
  associatedEntityType,
  children,
  associatedTeams,
  team,
  owningTeamNumberOfStories,
  totalAssociatedTeamsCount,
  users,
  visible
}) => {
  const members = useMemo(() => users.filter(u => team?.member_ids.includes(`${u.id}`)).map(user => ({
    email: user.email_address,
    fullName: user.name,
    id: String(user.id),
    imageUrl: user.display_icon?.url,
    mentionName: user.mention_name
  })), [team, users]);
  return _jsx(Tooltip, {
    content: _jsx(DefaultTeamTooltipContent, {
      associatedEntityType: associatedEntityType,
      associatedTeams: associatedTeams,
      imageUrl: team?.display_icon?.url,
      teamColor: getTeamColor(team),
      members: members,
      mentionName: team?.mention_name,
      name: team?.name,
      totalMemberCount: members.length,
      owningTeamNumberOfStories: owningTeamNumberOfStories,
      totalAssociatedTeamsCount: totalAssociatedTeamsCount
    }),
    delay: 300,
    visible: visible,
    children: children
  });
};
TeamTooltip.displayName = "TeamTooltip";
TeamTooltip.DefaultTeamTooltipContent = DefaultTeamTooltipContent;
TeamTooltip.TeamTooltipContent = TeamTooltipContentPrimitive;