import "core-js/modules/esnext.set.difference.v2.js";
import "core-js/modules/esnext.set.intersection.v2.js";
import "core-js/modules/esnext.set.is-disjoint-from.v2.js";
import "core-js/modules/esnext.set.is-subset-of.v2.js";
import "core-js/modules/esnext.set.is-superset-of.v2.js";
import "core-js/modules/esnext.set.symmetric-difference.v2.js";
import "core-js/modules/esnext.set.union.v2.js";
import { useCallback, useMemo, useRef } from 'react';
import { useDocumentVisibility } from '@clubhouse/shared/hooks/useDocumentVisibility';
import { getCurrentOrgId, isOrgDisabled } from 'data/entity/organization';
import { useConsolidatedDataFetched } from 'utils/consolidatedFetch';
import { PAGE_NAMES, getCurrentPage } from 'utils/navigation';
import { useWorkspace2PublicId } from '../../hooks/useWorkspace2PublicId';
import { DataLayerUpdatesContext } from './DataLayerUpdatesContext';
import { useEventSource } from './useEventSource';
import { useOnlineStatus } from './useOnlineStatus';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const isBillingPageAndOrgOrWorkspaceIsDisabled = () => {
  return getCurrentPage() === PAGE_NAMES.SETTINGS_MANAGE_BILLING && isOrgDisabled(getCurrentOrgId());
};
function EventSource({
  onUpdate,
  onLegacyUpdate,
  publicId
}) {
  useEventSource({
    onUpdate,
    onLegacyUpdate,
    publicId
  });
  return null;
}
export function DataLayerUpdatesSSEProvider({
  children
}) {
  const listenersRef = useRef(new Set());
  const legacyListenersRef = useRef(new Set());
  const publicId = useWorkspace2PublicId();
  const isReady = useConsolidatedDataFetched(); // TODO: We should use useUserOrgAndApplicationStateFetched when we no longer have Service Worker to start listening earlier
  const isActive = useDocumentVisibility() === 'visible';
  const isOnline = useOnlineStatus();
  const enabled = isOnline && isReady && !isBillingPageAndOrgOrWorkspaceIsDisabled() && isActive;
  const registerListener = useCallback(fn => {
    listenersRef.current.add(fn);
    return () => {
      listenersRef.current.delete(fn);
    };
  }, []);
  const registerLegacyListener = useCallback(fn => {
    legacyListenersRef.current.add(fn);
    return () => {
      legacyListenersRef.current.delete(fn);
    };
  }, []);
  const onUpdate = useCallback(data => listenersRef.current.forEach(fn => fn(data)), []);
  const onLegacyUpdate = useCallback(event => legacyListenersRef.current.forEach(fn => fn(event)), []);
  return _jsxs(DataLayerUpdatesContext.Provider, {
    value: useMemo(() => ({
      registerListener,
      registerLegacyListener
    }), [registerListener, registerLegacyListener]),
    children: [enabled && _jsx(EventSource, {
      publicId: publicId,
      onUpdate: onUpdate,
      onLegacyUpdate: onLegacyUpdate
    }), children]
  });
}
DataLayerUpdatesSSEProvider.displayName = "DataLayerUpdatesSSEProvider";