import { ICON_DEFAULT_WIDTH } from '../../utils/icon';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const ReportBurndown = ({
  width = ICON_DEFAULT_WIDTH
}) => _jsxs("svg", {
  width: width,
  height: width,
  viewBox: "0 0 46 46",
  fill: "none",
  children: [_jsx("path", {
    d: "M29.9001 19.32H20.8534L13.2634 11.6533H4.14005V35.19C4.14005 36.57 5.29005 37.72 6.67005 37.72H39.1767C40.5567 37.72 41.7067 36.57 41.7067 35.19V31.1267L29.9001 19.32Z",
    fill: "#EAEFF7"
  }), _jsx("path", {
    d: "M13.2634 11.6533V38.5633",
    stroke: "white",
    strokeWidth: "2",
    strokeMiterlimit: "10",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), _jsx("path", {
    d: "M22.77 19.32V38.5633",
    stroke: "white",
    strokeWidth: "2",
    strokeMiterlimit: "10",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), _jsx("path", {
    d: "M32.3533 21.62V38.5633",
    stroke: "white",
    strokeWidth: "2",
    strokeMiterlimit: "10",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), _jsx("path", {
    d: "M1.91669 11.6533H13.2634L20.8534 19.32H29.9L44.0833 33.58",
    stroke: "#346AB1",
    strokeWidth: "2",
    strokeMiterlimit: "10",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  })]
});
ReportBurndown.displayName = "ReportBurndown";