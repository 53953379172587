import { makeVar, useReactiveVar } from '@apollo/client';
import AddNewController from 'app/client/core/js/controllers/addNew';
import AddNewDocController from 'app/client/core/js/controllers/addNewDoc';
import AddNewEpicController from 'app/client/core/js/controllers/addNewEpic';
import AddNewIterationController from 'app/client/core/js/controllers/addNewIteration';
import AddNewLabelController from 'app/client/core/js/controllers/addNewLabel';
import AddNewStoryController from 'app/client/core/js/controllers/addNewStory';
import GroupController from 'app/client/core/js/controllers/group';
import View from 'app/client/core/js/modules/view';
import InviteUsersController from 'app/client/settingsShared/js/controllers/inviteUsers';
import ManageTemplatesController from 'app/client/settingsShared/js/controllers/manageTemplates';
import { CreateObjectiveModal } from '../components/create-objectives/CreateObjectiveModal';
import { getHref } from '../components/gql/objective/links';
import { fetchMilestone, getMilestoneById } from '../data/entity/milestone';
import { isReadOnly } from '../data/entity/user';
import { getCurrentPathname, navigateTo, parsePath } from './navigation';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
function isId(x) {
  return typeof x !== 'boolean';
}
export const getCurrentButton = pageName => AddNewController.getCurrentButton(pageName);
export const openDropdown = () => AddNewController.openDropdown();
export const renderAddNewEpic = state => {
  AddNewEpicController.updateState(state);
  AddNewEpicController.render();
};
export const renderFromContentAddNewEpic = (...args) => AddNewEpicController.renderFromContent(...args);
export const renderAddNewEpicInObjective = async () => {
  if (isReadOnly()) return false;
  const publicId = parsePath(getCurrentPathname(), {
    force: true
  }).objective;
  let objective = getMilestoneById(Number.parseInt(String(publicId)));

  /**  When we attempt to create an epic within a newly created objective,
   *  before a page refresh the objective will not be available in the Milestone Model,
   *  therefore we need to fetch the objective.
   **/
  if (!objective && isId(publicId)) {
    objective = await fetchMilestone(publicId);
  }

  // if (exports.isEditingMilestoneDescription()) {
  //   exports.saveDescription.call(exports.getMilestoneDescriptionTextarea()[0]);
  // }

  if (objective) AddNewEpicController.updateState({
    objective_ids: [objective.id]
  });
  AddNewEpicController.render();
};

/**
 * @note This is not recommended to use. It is created to support Docs only.
 */
export const renderFromContentAddNewObjective = values => {
  const root = document.createElement('DIV');
  document.body.appendChild(root);
  const result = View.renderComponent({
    mountNode: root,
    componentKey: 'add-new-objective-from-caveman',
    component: ({
      onClose
    }) => _jsx(CreateObjectiveModal, {
      defaultValues: {
        name: values?.name,
        description: values?.description
      },
      onSave: objective => {
        values?.onSave({
          ...objective,
          app_url: getHref(objective.publicId, {
            includeDomain: true
          })
        });
      },
      anchor: null,
      onClose: onClose
    }),
    getProps() {
      return {
        onClose: () => {
          result.unmountComponent();
          root.remove();
        }
      };
    }
  });
};
export const renderAddNewStory = (state, {
  context = 'story',
  useCreateAnother = true
} = {}) => {
  const options = {
    context,
    useCreateAnother
  };
  AddNewStoryController.updateState(state);
  AddNewStoryController.render(options);
};
export const renderFromContentAddNewStory = (...args) => AddNewStoryController.renderFromContent(...args);
export const getStoryState = () => AddNewStoryController.getState();
export const renderAddNewStoryFromTemplate = (template, content) => {
  AddNewStoryController.renderFromTemplate(template, content);
};
export const renderStoryLinks = (...args) => AddNewStoryController.renderStoryLinks(...args);
export const renderAddNewGroup = ({
  onCreate,
  triggerNode
} = {}) => GroupController.openCreateDialog({
  onCreate,
  triggerNode
});
export const renderAddNewLabel = () => AddNewLabelController.open();
export const renderAddNewIteration = state => {
  AddNewIterationController.updateState(state);
  AddNewIterationController.render();
};
export const renderFromContentAddNewIteration = (...args) => AddNewIterationController.renderFromContent(...args);
export const switchToInviteUsers = () => navigateTo({
  url: InviteUsersController.route()
});
export const createNewDoc = () => AddNewDocController.createDoc();
export const updateStoryEpic = id => AddNewStoryController.updateStoryEpic(id);
export const updateState = data => AddNewStoryController.updateState(data);
export const updateStoryWorkflowState = id => AddNewStoryController.updateStoryWorkflowState(id);
export const updateStoryEstimate = newEstimate => AddNewStoryController.updateStoryEstimate(newEstimate);
export const updateStoryType = value => AddNewStoryController.updateStoryType(value);
export const updateStoryLinks = value => AddNewStoryController.updateStoryLinks(value);
export const toggleOwnerState = profile => AddNewStoryController.toggleOwnerState(profile);
export const removeMeAsFollower = () => AddNewStoryController.removeMeAsFollower();
export const addMeAsFollower = () => AddNewStoryController.addMeAsFollower();
export const toggleFollowerState = profile => AddNewStoryController.toggleFollowerState(profile);
export const isOpen = () => AddNewStoryController.isOpen();
export const openManageTemplates = () => {
  return ManageTemplatesController.open();
};
const currentlyOpenCreateDialog = makeVar(null);
export const useCurrentlyOpenCreateDialog = () => useReactiveVar(currentlyOpenCreateDialog);
export const openCreateDialog = dialog => {
  currentlyOpenCreateDialog(dialog);
};
export const closeCreateDialog = () => {
  currentlyOpenCreateDialog(null);
};
export const openCreateObjectiveDialog = () => {
  openCreateDialog('objective');
};