import { ICON_DEFAULT_WIDTH } from '../../utils/icon';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const Exclamation = ({
  width = ICON_DEFAULT_WIDTH,
  fill
}) => _jsx("svg", {
  fill: fill,
  height: width,
  width: width,
  viewBox: "0 0 13 13",
  children: _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M6.001 0C7.08967 0 8.09367 0.268333 9.013 0.805C9.93233 1.34167 10.6603 2.06967 11.197 2.989C11.7337 3.90833 12.002 4.91233 12.002 6.001C12.002 7.08967 11.7337 8.09367 11.197 9.013C10.6603 9.93233 9.93233 10.6603 9.013 11.197C8.09367 11.7337 7.08967 12.002 6.001 12.002C4.91233 12.002 3.90833 11.7337 2.989 11.197C2.06967 10.6603 1.34167 9.93233 0.805 9.013C0.268333 8.09367 0 7.08967 0 6.001C0 4.91233 0.268333 3.90833 0.805 2.989C1.34167 2.06967 2.06967 1.34167 2.989 0.805C3.90833 0.268333 4.91233 0 6.001 0ZM7.00098 9.742V8.258C7.00098 8.18533 6.97764 8.124 6.93098 8.074C6.88431 8.024 6.82698 7.99933 6.75898 8H5.25898C5.19098 8 5.13098 8.026 5.07898 8.078C5.02698 8.13 5.00098 8.19 5.00098 8.258V9.742C5.00098 9.81 5.02698 9.87 5.07898 9.922C5.13098 9.974 5.19098 10 5.25898 10H6.75898C6.82698 10 6.88431 9.97533 6.93098 9.926C6.97764 9.87666 7.00098 9.81533 7.00098 9.742ZM7.12595 2.203L6.98495 7.055H6.98595C6.98129 7.107 6.95395 7.15267 6.90395 7.192C6.85395 7.23133 6.79262 7.251 6.71995 7.251H5.27495C5.20229 7.251 5.13995 7.23133 5.08795 7.192C5.03595 7.15267 5.00995 7.107 5.00995 7.055L4.87695 2.203C4.87695 2.14033 4.90295 2.09333 4.95495 2.062C5.00695 2.02067 5.06929 2 5.14195 2H6.86095C6.93362 2 6.99595 2.02067 7.04795 2.062C7.09995 2.09333 7.12595 2.14033 7.12595 2.203Z"
  })
});
Exclamation.displayName = "Exclamation";