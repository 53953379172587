import { getDisplayName } from 'data/typeConfigs';
import { DeselectAllItems } from './DeselectAllItems';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const AllItem = props => _jsx(DeselectAllItems, {
  ...props,
  isSelected: props.item.isSelected,
  text: props.item.text,
  IconLeft: null
});
AllItem.displayName = "AllItem";
export const createAllItem = ({
  selectedItemValues,
  type
}) => ({
  component: AllItem,
  isSelected: selectedItemValues.length === 0,
  selectedItemValues: [],
  text: `All ${getDisplayName({
    type,
    isSingular: false
  })}`,
  value: 'ALL'
});