import "core-js/modules/es.array.push.js";
import { FEATURES, usesFeature } from 'utils/features';
import ApplicationState from '../modules/applicationState';
import Backend from '../modules/backend';
import Collection from '../_frontloader/collection';
import EstimateScaleModel from './estimateScale';
import * as Event from '../_frontloader/event';
import { TimeUnit, addDays, getDateFromUserInput, getRelativeRange, isTimeUnit } from '@clubhouse/shared/utils/date';
import { workspaceUtcOffset } from 'utils/date';
import StoryModel from './story';
import UserModel from './user';
import Url from '../modules/url';
import { capitalize, insertIf } from '@clubhouse/shared/utils';
import { ADVANCED_CHARTS as _ADVANCED_CHARTS, CHARTS as _CHARTS } from '@clubhouse/shared/types';
import moment from 'moment';
import LocalStorage from '../modules/localStorage';
import { groupValueIdsByFieldId } from 'data/entity/customField';
import BaseUtils from 'app/client/core/js/_frontloader/baseUtils';
import { FEATURE_TOGGLES, getFeatureToggle } from '@clubhouse/feature-toggles';
import _ from 'lodash';
const exports = {};
const CACHED_REPORT_ID = 1;
const CACHED_REPORT_STRUCTURE = {
  id: CACHED_REPORT_ID,
  data: {}
};
exports.ADVANCED_CHARTS = _ADVANCED_CHARTS;
exports.CHARTS = _CHARTS;
exports.TIME_SPENT_IN_WF_STATE_NUM_LEAD_DAYS = 6;
exports.AGGR_TYPES = {
  points: {
    id: 'points',
    name: 'points',
    label: 'Points'
  },
  stories: {
    id: 'stories',
    name: 'stories',
    label: 'Story count'
  }
};
const APPSTATE_AGGR_NAME = 'Reports.AggregationType';
Collection.create('Report', exports);
exports.DEFAULT_DATE_FORMAT = 'YYYY-MM-DD'; // Formatted safely for Firefox
exports.CUTOFF_DATE = '2016-01-10'; // When support on backend was implemented

exports.PROGRESS_UPDATE_EVENT = 'reportsProgressUpdated';
exports.REPORT_TYPE_TO_LABEL = {
  story: 'Stories',
  point: 'Points'
};
exports.DEFAULT_REPORT_PERIOD_LENGTH = 7;
exports.progress = {
  start: null,
  end: null,
  current: null
};

// This is a function so we can easily test longer ranges in the future
exports.getVelocityDayLimit = () => {
  return 180;
};

// This is a function so we can easily test longer ranges in the future
exports.getDefaultMaxDayRange = () => {
  return 100;
};
exports.getMaxDayRange = () => Number.POSITIVE_INFINITY;
exports.hasCachedReports = () => {
  return exports.getCachedReports() !== CACHED_REPORT_STRUCTURE;
};
exports.getCachedReports = () => {
  return exports.getById(CACHED_REPORT_ID) || CACHED_REPORT_STRUCTURE;
};
exports.setCachedReports = reportData => {
  exports.flush();
  exports.add({
    id: CACHED_REPORT_ID,
    data: reportData
  });
};
exports.resetCachedReports = () => {
  exports.setCachedReports(CACHED_REPORT_STRUCTURE.data);
};
exports.isValidDate = date => {
  const cutoffDate = moment(exports.CUTOFF_DATE);
  return moment(date).isValid() && moment.utc(date).isAfter(cutoffDate) && moment.utc(date).isBefore(moment.utc().add(1, 'day'));
};
exports.getMinSupportedDate = () => {
  const cutoffDate = moment(exports.CUTOFF_DATE);
  return cutoffDate.toDate();
};
const stringToBoolean = value => {
  if (value === 'true') return true;else if (value === 'false') return false;else return undefined;
};
const stringToTimeUnit = value => {
  if (isTimeUnit(value)) return value;
};
const stringToNumber = value => {
  const num = Number.parseInt(value);
  if (!Number.isNaN(num)) return num;
};

/**
 * Looks for url parameter `display` which takes the format
 *   display=chart1,!chart2,inaccessibleChart1
 *
 * and returns an array of objects with inaccessible charts removed. Note that a key
 * prepended with '!' means the chart should not be displayed. It may still be included
 * to keep reports in a particular order on the page.
 *   [
 *      {key: chart1, displayed: true},
 *      {key: chart2, displayed: false},
 *   ]
 *
 * If multiple keys for the same chart exist, only the first will be used. If no keys or
 * only OFF keys (!) are provided, all accessible charts will be used instead.
 */
exports.getReportsConfiguration = () => {
  const display = exports.getCachedReports().data?.display || Url.parseLocationSearch().display;
  const inaccessibleCharts = usesFeature(FEATURES.REPORTS_PAGE_ADVANCED) ? [] : Object.values(exports.ADVANCED_CHARTS);

  // Remove inaccessible chart keys
  let allToggledOff = true;
  const validKeys = [];
  const validKeysAndDisplayState = [];
  if (typeof display === 'string') {
    display.split(',').forEach(keyAndState => {
      const isToggledOff = keyAndState[0] === '!';
      let key = isToggledOff ? keyAndState.slice(1) : keyAndState;

      // Fix for sc-240989: we no longer use the -entity ending on this key.
      if (key === 'cumulative-flow-entity') key = 'cumulative-flow';
      // Remove inaccessible and duplicated keys
      if (inaccessibleCharts.includes(key) || validKeys.includes(key)) return;
      if (!isToggledOff) allToggledOff = false;
      validKeys.push(key);
      validKeysAndDisplayState.push({
        key,
        isToggledOff
      });
    });
  }

  // If all charts are toggled off, display all charts instead
  return !allToggledOff ? validKeysAndDisplayState : Object.values(exports.CHARTS).filter(key => !inaccessibleCharts.includes(key)).map(key => ({
    key,
    isToggledOff: false
  }));
};
exports.getReportsConfigurationUrlParam = () => {
  const reportsConfiguration = exports.getReportsConfiguration();
  return reportsConfiguration.map(reportConfig => `${reportConfig.isToggledOff ? '!' : ''}${reportConfig.key}`).join(',');
};
exports.getToday = () => moment().startOf('day');

/**
 * Expects URL params object, and figures out what the exact start and end date of the range are, regardless
 * of whether we're in "specific dates" or "relative dates" mode. Although this method is more performant than
 * `getDateRangeFromUrlParams` because it allows the parent to get URL params, it is still not recommended to use
 * in a loop because creating new Moment objects is expensive and can compound quickly.
 *
 * Returns:
 *
 *      {
 *        startDate: Moment,
 *        endDate: Object,
 *        daysInRange: number,
 *      }
 */
exports.getDateRange = ({
  relative,
  timeUnit,
  duration,
  startDate,
  endDate
}) => {
  if (relative) {
    const range = getRelativeRange(duration, timeUnit, workspaceUtcOffset());
    const endDateMoment = moment(range.end);
    return {
      startDate: moment(range.start),
      endDate: endDateMoment,
      daysInRange: endDateMoment.diff(range.start, 'days')
    };
  } else {
    const endDateMoment = moment(endDate);
    return {
      startDate: moment(startDate),
      endDate: endDateMoment,
      daysInRange: endDateMoment.diff(startDate, 'days')
    };
  }
};

/**
 * Looks in the URL, and figures out what the exact start and end date of the range are, regardless
 * of whether we're in "specific dates" or "relative dates" mode. Returns:
 *
 *      {
 *        startDate: Moment,
 *        endDate: Object,
 *        daysInRange: number,
 *      }
 *
 * NOTE: getValidUrlParams can be slow. If you must use within a loop, get the url params once and use
 *  `getDateRange` within the loop instead.
 */
exports.getDateRangeFromUrlParams = () => exports.getDateRange(exports.getValidUrlParams());

/**
 * Returns a valid set of URL parameters, no matter what!
 *
 * Returns EITHER:
 *
 *      {
 *        relative: true,
 *        timeUnit: 'days' | 'months' | 'weeks' | etc
 *        duration: [some number]
 *      }
 *
 * OR...
 *
 *      {
 *        relative: false,
 *        startDate: 'YYYY-MM-DD',
 *        endDate: 'YYYY-MM-DD',
 *      }
 *
 * The values come from several possible different places, depending on a series of fallbacks:
 *
 *  1) If we're on a legacy url (e.g. /internal/reports/2020-01-01/days/7...) then we return
 *     relative: false and use those dates from the legacy URL.
 *
 *  2) If we've previously cached some ValidUrlParams in localStorage and there are no params in the
 *     current URL (e.g. /internal/reports) then we use the cached params.
 *
 *  3) If there are valid params in the URL (e.g.
 *     /internal/reports?relative=true&timeUnit=days&duration=7) then we use those params
 *
 *  4) Otherwise we fall back to a default of { relative: true, timeUnit: 'days', duration: 14 }
 */
exports.getValidUrlParams = () => {
  /**
   * Legacy URLs
   **/

  const legacyUrlMatch = document.location.pathname.match(/\/reports\/([0-9-]+)\/days\/([0-9]+)$/);
  if (legacyUrlMatch) {
    const startDate = getDateFromUserInput(legacyUrlMatch[1]);
    const days = Number.parseInt(legacyUrlMatch[2]);
    if (startDate && !Number.isNaN(days)) {
      return {
        relative: false,
        startDate,
        endDate: addDays(startDate, days)
      };
    }
  }

  /**
   * Params cached in localStorage (i.e. coming to the Reports page via the nav)
   **/

  const cachedParams = LocalStorage.get('reportsCachedUrlParams');
  if (cachedParams && Url.getParamFromUrl('relative') == null) {
    return JSON.parse(cachedParams);
  }

  /**
   * Getting the params from the query string
   **/
  const relative = stringToBoolean(Url.getParamFromUrl('relative'));
  const start = getDateFromUserInput(Url.getParamFromUrl('startDate'));
  const end = getDateFromUserInput(Url.getParamFromUrl('endDate'));
  const timeUnit = stringToTimeUnit(Url.getParamFromUrl('timeUnit'));
  const duration = stringToNumber(Url.getParamFromUrl('duration'));
  const info = {
    relative
  };
  if (relative && timeUnit && duration > 0) {
    info.relative = true;
    info.duration = duration;
    info.timeUnit = timeUnit;
  } else if (!relative && start && end) {
    info.startDate = start;
    info.endDate = end;
  } else {
    /**
     * Default fallback, when the URL is invalid
     **/
    info.relative = true;
    info.duration = 14;
    info.timeUnit = TimeUnit.DAYS;
  }

  // And whatever we decide is the right set of params, we save it for next time
  LocalStorage.set('reportsCachedUrlParams', info);
  return info;
};
exports.isDateWithinPeriod = (date, period) => {
  return date.isBetween(period.start, period.end, null, '[]');
};
function getReportPeriods(options = {}) {
  const count = _.isNumber(options.count) ? options.count : 1;
  const date = moment(options.date || undefined);
  const startDate = moment(exports.getMinSupportedDate()).startOf('day');
  const endDate = startDate.clone().add(1, 'weeks').endOf('day');
  const reportPeriods = [{
    start: startDate,
    end: endDate
  }];
  let nextStart;
  let nextEnd;
  if (startDate.isBefore(date)) {
    while (!exports.isDateWithinPeriod(date, _.last(reportPeriods))) {
      nextStart = _.last(reportPeriods).end.clone().startOf('day');
      nextEnd = nextStart.clone().add(1, 'weeks').endOf('day');
      reportPeriods.push({
        start: nextStart,
        end: nextEnd
      });
    }
  }
  return _.takeRight(reportPeriods, count);
}
exports.getReportPeriodsForVelocityRange = entity => {
  const completed = _.get(entity, 'completed_at');
  return getReportPeriods({
    count: Math.round(exports.getVelocityDayLimit() / exports.DEFAULT_REPORT_PERIOD_LENGTH),
    date: completed
  });
};
exports.getReportingType = () => {
  return EstimateScaleModel.isUsingPoints() ? 'Points' : 'Stories';
};
exports.canUsePoints = () => {
  return EstimateScaleModel.isUsingPoints();
};
exports.fetchEpicBurndown = (id, teamID, callback) => {
  const data = teamID ? {
    team_id: teamID
  } : {};
  fetchBurndown('epics', id, data, callback);
};
exports.fetchMilestoneBurndown = (id, teamID, callback) => {
  const data = teamID ? {
    team_id: teamID
  } : {};
  fetchBurndown('milestones', id, data, callback);
};
exports.fetchIterationBurndown = (id, teamID, callback, version = '') => {
  const data = teamID ? {
    team_id: teamID
  } : {};
  fetchBurndown('iterations', id, data, callback, version);
};
exports.fetchEpicVelocity = (epic, callback) => {
  fetchVelocity('epics', epic, callback);
};
exports.fetchEpicCumulativeFlow = (id, teamID, callback) => {
  const data = teamID ? {
    team_id: teamID
  } : {};
  fetchCumulativeFlow('epics', id, data, callback);
};
exports.fetchIterationCumulativeFlow = (id, teamID, callback, version = '') => {
  const data = teamID ? {
    team_id: teamID
  } : {};
  fetchCumulativeFlow('iterations', id, data, callback, version);
};
function _updateProgress(date, duration) {
  exports.progress.start = moment.utc(date).startOf('day');
  exports.progress.end = moment.utc(date).add(duration, 'days').endOf('day');
  Event.trigger(exports.PROGRESS_UPDATE_EVENT);
}
exports.fetchStoriesSummary = (date, duration, callback) => {
  fetchReport({
    endpoint: '/api/private/reports/stories/summary',
    date,
    duration,
    callback: wrappedCallback,
    accumulator,
    state: [],
    onNext
  });
  function wrappedCallback(err, stories) {
    if (!err) {
      StoryModel.updateMultiple(stories);
    }
    callback.apply(this, arguments);
  }
  function accumulator(res, prevState) {
    return prevState.concat(_.flatten(_.map(_.get(res, 'data'), 'stories')));
  }
  function onNext(res) {
    exports.progress.current = _.last(_.map(res.data, 'completed_at'));
    Event.trigger(exports.PROGRESS_UPDATE_EVENT);
  }
};
exports.fetchEpicsSummary = (date, duration, callback) => {
  fetchReport({
    endpoint: '/api/private/reports/epics/counts',
    date,
    duration,
    callback,
    accumulator,
    state: {}
  });
  function accumulator(res, prevState) {
    return _.reduce(res.data, (result, n, key) => {
      if (_.isArray(result[key])) {
        // Combining partial results for a date
        result[key] = _.uniqBy(result[key].concat(n), 'date');
      } else {
        result[key] = n;
      }
      return result;
    }, prevState);
  }
};
function fetchVelocity(entityType, entity, callback) {
  const periods = exports.getReportPeriodsForVelocityRange(entity);
  const data = {
    start_day: _.head(periods).start.format(exports.DEFAULT_DATE_FORMAT),
    duration: exports.getVelocityDayLimit()
  };
  fetchEntityReport({
    reportType: 'velocity',
    entityType,
    id: entity.id,
    data,
    callback
  });
}
function fetchBurndown(entityType, id, data, callback, version = '') {
  fetchEntityReport({
    reportType: `burndown${version}`,
    entityType,
    id,
    data,
    callback
  });
}
function fetchCumulativeFlow(entityType, id, data, callback, version = '') {
  fetchEntityReport({
    reportType: `cumulative-flow${version}`,
    entityType,
    id,
    data,
    callback
  });
}
function fetchEntityReport({
  reportType,
  entityType,
  id,
  data,
  callback
}) {
  data = _.assign({}, data);
  Backend.post('/api/private/reports/' + entityType + '/' + id + '/' + reportType, {
    data,
    onComplete: res => {
      if (res.error || res.message) {
        exports.defaultErrorHandler(res, callback);
      } else {
        callback(null, res);
      }
    }
  });
}
function fetchReport({
  endpoint,
  date,
  duration,
  callback,
  accumulator,
  state,
  onNext
}) {
  onNext = _.isFunction(onNext) ? onNext : _.noop; // optional

  makeRequest(date, duration, null, callback);
  function makeRequest(date, duration, afterTxId, callback) {
    const data = {
      start_day: date,
      duration
    };
    if (afterTxId) {
      data.after_tx_id = afterTxId;
    }
    Backend.post(endpoint, {
      data,
      onComplete: res => {
        state = accumulator(res, state);
        if (res.next) {
          onNext(res);
          makeRequest(res.next.start_day, res.next.duration, res.next.after_tx_id, callback);
        } else {
          if (res.error) {
            exports.defaultErrorHandler(res, callback);
          } else {
            callback(null, state);
          }
        }
      }
    });
  }
}
const defaultRootAndFilter = (formattedQuery, [key, value]) => {
  // Backend requires that a filter be truthy, no empty arrays
  if (!(typeof value === 'undefined' || Array.isArray(value) && !value.length)) {
    return addToRootAnd(formattedQuery, {
      [key]: value
    });
  }
  return formattedQuery;
};
const addToRootAnd = (formattedQuery, item) => {
  // Avoid using .push so as not to encourage using references to objects in code
  formattedQuery.AND = [...formattedQuery.AND, item];
  return formattedQuery;
};
const filterWhitelist = [{
  key: 'project_ids',
  handler: defaultRootAndFilter
}, {
  key: 'workflow_ids',
  handler: defaultRootAndFilter
}, {
  key: 'milestone_ids',
  handler: defaultRootAndFilter
}, {
  key: 'iteration_ids',
  handler: (formattedQuery, filter, query) => {
    // Try to set query for iteration ids only if iteration_group_ids is not set
    //  the iteration_group_ids handler will do the rest
    if (!query.iteration_group_ids?.length) {
      return defaultRootAndFilter(formattedQuery, filter);
    }
    return formattedQuery;
  }
}, {
  key: 'iteration_group_ids',
  handler: (formattedQuery, [key, value], query) => {
    if (value?.length) {
      return query.iteration_ids?.length ? addToRootAnd(formattedQuery, {
        OR: [{
          iteration_ids: [...query.iteration_ids]
        }, {
          iteration_group_ids: [...query.iteration_group_ids]
        }]
      }) : defaultRootAndFilter(formattedQuery, [key, value]);
    }
    return formattedQuery;
  }
}, {
  key: 'epic_ids',
  handler: defaultRootAndFilter
}, {
  key: 'label_ids',
  handler: defaultRootAndFilter
}, {
  key: 'story_types',
  handler: defaultRootAndFilter
}, {
  key: 'team_ids',
  handler: (formattedQuery, [, value]) => defaultRootAndFilter(formattedQuery, ['group_ids', value])
}, {
  key: 'owner_ids',
  handler: defaultRootAndFilter
}, {
  key: 'custom_field_value_ids',
  handler: (formattedQuery, [, value]) => {
    if (!Array.isArray(value)) return formattedQuery;
    const valueIdsByFieldId = groupValueIdsByFieldId(value, 'NONE');
    const fieldQuery = {
      custom_fields: Object.entries(valueIdsByFieldId).map(([fieldId, valueIds]) => ({
        field_id: fieldId,
        value_ids: Array.from(valueIds)
      }))
    };
    if (value.includes('NONE')) {
      return addToRootAnd(formattedQuery, {
        OR: [{
          custom_fields: ['NONE']
        }, fieldQuery]
      });
    } else {
      return addToRootAnd(formattedQuery, fieldQuery);
    }
  }
}];
export const _formatChartQuery = query => {
  const formattedQuery = filterWhitelist.reduce((acc, {
    key,
    handler
  }) => {
    const value = query[key];
    return handler(acc, [key, value], query);
  }, {
    AND: []
  });
  if (formattedQuery.AND.length === 0) {
    delete formattedQuery.AND;
  }
  return formattedQuery;
};
function _chartConfigIsValid({
  type,
  interval = {},
  range
}) {
  const usesIterationIntervals = getFeatureToggle(FEATURE_TOGGLES.REPORTS_ITERATION_INTERVALS);
  // This is a deny list
  const invalidChartForGroupBy = {
    none: [],
    day: [],
    week: [exports.CHARTS.CYCLE_TIME],
    month: [exports.CHARTS.CYCLE_TIME],
    iteration: [exports.CHARTS.TIME_SPENT_IN_WORKFLOW_STATE, exports.CHARTS.CREATED_VS_COMPLETED, ...insertIf(!usesIterationIntervals, [exports.CHARTS.BURNDOWN, exports.CHARTS.CUMULATIVE_FLOW, exports.CHARTS.CYCLE_TIME])]
  };
  if (invalidChartForGroupBy[interval.id]?.includes(type) || interval.supportedByRange && !interval.supportedByRange(moment.utc(range.start), moment.utc(range.end))) {
    return false;
  }
  return true;
}
exports.fetchChart = (chartConfig, callback) => {
  const {
    type,
    startDate,
    duration,
    interval,
    query,
    context
  } = chartConfig;
  const range = {
    ...chartConfig.range
  };
  if (query) {
    callback = _.isFunction(callback) ? callback : _.noop;
    _updateProgress(startDate, duration);
    if (!_chartConfigIsValid({
      type,
      interval,
      range
    })) {
      callback();
      return;
    }
    if (type === exports.CHARTS.TIME_SPENT_IN_WORKFLOW_STATE) {
      range.start = moment.utc(range.start).subtract(exports.TIME_SPENT_IN_WF_STATE_NUM_LEAD_DAYS, 'days').format(exports.DEFAULT_DATE_FORMAT);
    }
    const data = {
      type,
      range,
      report_context: context,
      query: _formatChartQuery(query)
    };
    if (interval && interval.id !== 'none') {
      data.interval = interval.id;
    }
    Backend.post(`/api/private/chart?type=${type}`, {
      data,
      onComplete: res => {
        exports.progress = {
          start: null,
          end: null,
          current: null
        };
        if (res.error) {
          exports.defaultErrorHandler(res, callback);
        } else {
          callback(null, res.data || res);
        }
      }
    });
  }
};
exports.fetchLabelReports = (label, data, callback) => {
  callback = _.isFunction(callback) ? callback : _.noop;
  Backend.post('/api/private/labels/' + label.id + '/reports', {
    data,
    onComplete: res => {
      if (res.error) {
        exports.defaultErrorHandler(res, callback);
      } else {
        _fetchGeneratedReportData(res.id, callback);
      }
    }
  });
};
function _fetchGeneratedReportData(reportId, callback) {
  Backend.get('/api/private/reports/' + reportId + '/data', {
    onComplete: res => {
      if (res.error) {
        exports.defaultErrorHandler(res, callback);
      } else {
        callback(null, res);
      }
    }
  });
}
exports.setAggregationType = type => {
  ApplicationState.set(APPSTATE_AGGR_NAME, exports.AGGR_TYPES[type]);
};
exports.getAggregationType = () => {
  if (!exports.canUsePoints()) return exports.AGGR_TYPES.stories;
  return ApplicationState.get(APPSTATE_AGGR_NAME) || exports.AGGR_TYPES.points;
};
exports.getAggregateTypesAsDropdownOptions = () => {
  const types = exports.AGGR_TYPES;
  return Object.keys(types).map(type => ({
    name: `${capitalize(types[type].label)}`,
    value: types[type].id
  }));
};
exports.getValueForIterationViewType = pointsAndCountData => {
  const REPORT_TYPE_TO_OBJECT_KEY = {
    story: 'count',
    point: 'points'
  };
  return pointsAndCountData[REPORT_TYPE_TO_OBJECT_KEY[UserModel.getIterationReportsAggregationType()]];
};
exports.getLabelForIterationViewType = () => {
  return exports.REPORT_TYPE_TO_LABEL[UserModel.getIterationReportsAggregationType()];
};
exports.reportStringToMoment = dateString => moment(dateString, 'YYYY-MM-DD');
exports.Promises = {
  fetchChart: BaseUtils.promisify(exports.fetchChart)
};
export { exports as default };