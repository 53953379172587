import { Nouns } from '@clubhouse/shared/constants';
import { EpicStateWithIcon } from 'components/gql/epicState/EpicStateWithIcon';
import { GroupByHeader } from 'components/gql/groupBy/GroupByHeader';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const config = {
  getKey: ({
    epicState
  }) => epicState?.id,
  getHeader: ({
    epicState
  }) => () => _jsx(GroupByHeader, {
    entityType: Nouns.State,
    children: epicState ? _jsx(EpicStateWithIcon, {
      spacing: 10,
      name: epicState.name,
      type: epicState.type
    }) : 'No State'
  })
};