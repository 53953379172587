import { Duplicates } from '@clubhouse/shared/components/Icons';
import { sanitize } from '@clubhouse/shared/utils';
import { archiveStory, createTemplateFromStory } from 'data/entity/story';
import { TableMoreActions } from 'components/shared/table/base';
import { ArchiveItem, CopyLinkItem, DividerItem, GoToItem, MoreActionsField as SharedMoreActionsField } from 'components/shared/table/fields/MoreActionsField';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const DuplicateItem = onClick => ({
  Icon: Duplicates,
  name: 'Duplicate',
  value: 'duplicate',
  onClick
});
export function MoreActionsFieldComponent({
  entity,
  onOpenConfirmationDialog,
  onCloseConfirmationDialog
}) {
  return _jsx(TableMoreActions, {
    items: [GoToItem('Story', entity.app_url, entity.url), CopyLinkItem(entity.app_url), DividerItem(), DuplicateItem(() => createTemplateFromStory(entity)), ArchiveItem(() => new Promise(resolve => {
      onOpenConfirmationDialog('archive', {
        onClick: () => {
          archiveStory(entity, err => {
            if (err) {
              resolve({
                failureMessage: `Unable to archive ${sanitize(entity.name)}.`
              });
            } else {
              resolve({
                successMessage: `Story ${sanitize(entity.name)} archive.`
              });
            }
          });
          onCloseConfirmationDialog();
        }
      });
    }))],
    label: "Story actions"
  });
}
MoreActionsFieldComponent.displayName = "MoreActionsFieldComponent";
export const MoreActionsField = (onOpenConfirmationDialog, onCloseConfirmationDialog) => SharedMoreActionsField({
  Component: ({
    entity
  }) => _jsx(MoreActionsFieldComponent, {
    entity: entity,
    onOpenConfirmationDialog: onOpenConfirmationDialog,
    onCloseConfirmationDialog: onCloseConfirmationDialog
  })
});