import { useCallback } from 'react';
import { FIELD_ID } from 'components/shared/table/types';
import { getById, saveNew } from 'data/entity/epic';
import { addEpic, removeEpic } from 'data/entity/story';
import { storyEpic } from 'utils/sort';
import { TableEpic } from './tableComponents';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export function EpicFieldComponent({
  entity
}) {
  const onChange = useCallback(async epicId => {
    return new Promise(resolve => {
      if (!epicId) {
        removeEpic(entity, resolve);
        return;
      }
      const selectedEpic = getById(epicId);
      if (!selectedEpic) {
        saveNew({
          name: epicId
        }, (err, res) => {
          if (err) throw 'Error saving new epic.';
          const epic = getById(res.id);
          if (epic) addEpic(entity, epic, resolve);
        });
        return;
      }
      addEpic(entity, selectedEpic, resolve);
    });
  }, [entity]);
  return _jsx(TableEpic, {
    groupId: entity.group_id,
    epic: entity.epic,
    onChange: onChange
  });
}
EpicFieldComponent.displayName = "EpicFieldComponent";
export const EpicField = () => ({
  name: FIELD_ID.EPIC,
  displayName: 'Epic',
  sort: storyEpic,
  Component: EpicFieldComponent,
  width: 200
});