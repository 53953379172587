import { Header } from './Header';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const StaticHeader = ({
  children,
  isNavExpanded,
  isActive,
  icon
}) => {
  return _jsx(Header, {
    isNavExpanded: isNavExpanded,
    icon: icon,
    isActive: isActive,
    children: children
  });
};
StaticHeader.displayName = "StaticHeader";