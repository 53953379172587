import { useCallback, useEffect, useRef } from 'react';
import { useBaseKeyboardGridNavigation } from '@clubhouse/shared/hooks';
import { focusCell, getFirstFocusTarget, getLastFocusTarget, getMovementDirection, move, moveToBeginning, moveToEnd } from '@clubhouse/shared/hooks/useBaseKeyboardGridNavigation/utils';
import { focusWithoutScroll } from '@clubhouse/shared/utils/focus';
export function useKeyboardGridNavigation({
  isReactSortableHoc
} = {}) {
  const isTabbing = useRef(false);
  const activeCell = useRef(null);
  useEffect(() => {
    const onKeyDown = e => {
      if (e.key === 'Tab') isTabbing.current = true;
    };
    const onKeyUp = () => {
      isTabbing.current = false;
    };
    document.addEventListener('keydown', onKeyDown);
    document.addEventListener('keyup', onKeyUp);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
      document.removeEventListener('keyup', onKeyUp);
    };
  }, []);
  const onKeyDown = useCallback(({
    table,
    key,
    element,
    altKey,
    shiftKey
  }) => {
    let newCell = element;
    const isInputElement = element.matches('input[type="text"],input[type="search"],textarea');
    switch (key) {
      case 'ArrowUp':
      case 'ArrowDown':
      case 'ArrowLeft':
      case 'ArrowRight':
        {
          if (isInputElement) return false;
          newCell = move(table, element, getMovementDirection(key), altKey);
          break;
        }
      case 'PageUp':
      case 'PageDown':
        {
          if (isInputElement) return false;
          newCell = move(table, element, getMovementDirection(key), false);
          break;
        }
      case 'Home':
        {
          if (isInputElement) return false;
          if (altKey) newCell = moveToBeginning(table);else newCell = move(table, element, getMovementDirection(key), true);
          break;
        }
      case 'End':
        {
          if (isInputElement) return false;
          if (altKey) newCell = moveToEnd(table);else newCell = move(table, element, getMovementDirection(key), true);
          break;
        }
      case 'Tab':
        {
          focusWithoutScroll(shiftKey ? getFirstFocusTarget(table) : getLastFocusTarget(table));
          return false;
        }
      default:
        return false;
    }
    activeCell.current = newCell;
    return true;
  }, []);
  const onFocus = useCallback(({
    element
  }) => {
    if (isTabbing.current) {
      if (!activeCell.current) activeCell.current = element;
      focusCell(activeCell.current);
      return true;
    }
    return false;
  }, []);
  const {
    getGridProps: _getGridProps,
    getGridCellHeaderProps: _getGridCellHeaderProps,
    getGridCellProps: _getGridCellProps
  } = useBaseKeyboardGridNavigation({
    onKeyDown,
    onFocus
  });
  const getGridProps = useCallback(() => {
    const {
      ref,
      ...props
    } = _getGridProps();
    return {
      ...props,
      // When using `react-sortable-hoc` table ref isn't exposed the "normal" way.
      ref: isReactSortableHoc ? instance => {
        ref.current = instance?.getWrappedInstance() || null;
      } : ref
    };
  }, [_getGridProps, isReactSortableHoc]);
  const getGridCellProps = useCallback(({
    header
  } = {}) => header ? _getGridCellHeaderProps({
    header,
    hasAction: true
  }) : _getGridCellProps({
    isFocusTarget: true
  }), [_getGridCellHeaderProps, _getGridCellProps]);
  return {
    getGridProps,
    getGridCellProps
  };
}