import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const AvatarImage = ({
  imageUrl,
  fullName,
  className
}) => {
  return _jsx("img", {
    src: imageUrl,
    className: className,
    alt: `${fullName} avatar`
  });
};
AvatarImage.displayName = "AvatarImage";