import * as ManageEstimateScaleTemplate from 'app/client/settingsShared/views/templates/manageEstimateScale.html';
import * as ReportingMetricsSwitcherTemplate from 'app/client/settingsShared/views/templates/reportingMetricsSwitcher.html';
import Constants from '../../../core/js/modules/constants';
import DropdownController from '../../../core/js/controllers/dropdown';
import EstimateScaleModel from '../../../core/js/models/estimateScale';
import FlashController from '../../../core/js/controllers/flash';
import Is from '../../../core/js/modules/is';
import RadioListController from '../../../core/js/controllers/radioList';
import ReportsController from '../../../core/js/controllers/reports';
import Url from '../../../core/js/modules/url';
import UserModel from '../../../core/js/models/user';
import Utils from '../../../core/js/modules/utils';
import Validator from '../../../core/js/modules/validator';
import View from '../../../core/js/modules/view';
import SettingsPage from '../../../settings/js/controllers/settingsPage';
import { AskAnOwnerAlert } from 'components/settings/AskAnOwnerAlert';
import _ from 'lodash';
const exports = {};
const PARENT_SELECTOR = '#manage-estimate-scale';
exports.title = `Edit your Estimate Scale | ${BRAND.NAME}`;
exports.route = () => {
  return Url.getSlugPath() + '/settings/estimates';
};
exports.open = () => {
  SettingsPage.loadPane(exports);
};
exports.render = () => {
  const me = UserModel.getLoggedInUserPermission();
  View.renderWithComponents({
    template: ManageEstimateScaleTemplate,
    templateMountNode: document.querySelector(SettingsPage.PARENT_SELECTOR),
    templateData: {
      active: 'manageEstimateScale',
      me,
      activeScale: EstimateScaleModel.getActiveScaleID(),
      estimateScale: EstimateScaleModel.getCurrent(),
      readOnly: !Is.admin(me)
    },
    components: {
      readOnlyMessage: {
        component: AskAnOwnerAlert,
        props: {
          description: 'Only owners and admins can update your estimate scale.'
        }
      }
    }
  });
  Validator.init(PARENT_SELECTOR);
  SettingsPage.onDestroy('ManageEstimateScale', () => {
    Validator.destroy(PARENT_SELECTOR);
    View.unmountComponents({
      templateMountNode: document.querySelector(SettingsPage.PARENT_SELECTOR)
    });
  });
};
exports.saveChanges = () => {
  const parent = $(PARENT_SELECTOR);
  if (parent.find('.save-changes').hasClass('disabled')) {
    return false;
  }
  const scaleType = parent.find('.radio-list-item.active').data('type');
  const useEstimateScale = exports.isPointsSelected();
  const estimateScale = EstimateScaleModel.getCurrent();
  const data = {};
  let estimates;
  if (scaleType === 'custom') {
    if (!Validator.isValid(parent)) {
      return false;
    }
    estimates = parent.find('.custom-estimate-values').val().split(/[\s\,]+/g);
    estimates = _.map(estimates, estimate => {
      return estimate.trim();
    });
  } else if (scaleType === 'disabled') {
    estimates = [];
  } else {
    estimates = _.find(Constants.READYMADE_ESTIMATE_SCALES, {
      id: scaleType
    }).values;
  }
  data.estimates = estimates;
  data.using_estimates = useEstimateScale;
  View.changeButtonToSaving(PARENT_SELECTOR + ' .save-changes');
  EstimateScaleModel.updateEstimateScale(estimateScale, data, err => {
    exports.render();
    if (err) {
      FlashController.error(PARENT_SELECTOR, 'Unable to update estimate scale.', err);
    } else {
      FlashController.success(PARENT_SELECTOR, 'Estimate scale updated.');
      ReportsController.reRender();
    }
  });
  return false;
};
exports.toggleScale = function () {
  return RadioListController.toggle.call(this);
};
exports.isPointsSelected = () => {
  return Utils.data($('.update-reporting-metrics-switcher .action'), 'using-points') === 'true';
};
exports.updateReportingMetrics = function () {
  const usingEstimates = exports.isPointsSelected();
  DropdownController.open({
    items: [{
      className: usingEstimates ? '' : 'active',
      name: 'Story Count (default)',
      value: false
    }, {
      className: usingEstimates ? 'active' : '',
      name: 'Estimate Points',
      value: true
    }],
    title: 'Use:',
    target: this,
    onApply: useEstimates => {
      $('.update-reporting-metrics-switcher').html(ReportingMetricsSwitcherTemplate.render({
        using_estimates: useEstimates
      }));
      if (useEstimates !== usingEstimates) {
        $(PARENT_SELECTOR).find('.save-changes.disabled').removeClass('disabled').removeAttr('disabled');
      }
    },
    width: 180
  });
  return false;
};
export { exports as default };