import { TableText } from '@clubhouse/shared/components/Table';
import { FIELD_ID } from 'components/shared/table/types';
import { points } from 'utils/sort';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const PointsFieldComponent = ({
  entity
}) => {
  return _jsx(TableText, {
    text: entity.num_points
  });
};
PointsFieldComponent.displayName = "PointsFieldComponent";
export const PointsField = (overrides = {}) => ({
  name: FIELD_ID.POINTS,
  displayName: 'Points',
  sort: points,
  headerProps: {
    centered: true
  },
  Component: PointsFieldComponent,
  width: 70,
  ...overrides
});