import { StoryCreatedOnFieldFragmentDoc } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { TableDate } from 'components/shared/table/base/TableDate';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const StoryCreatedOnFieldFragment = StoryCreatedOnFieldFragmentDoc;
const StoryCreatedDate = ({
  entity: {
    createdAt
  }
}) => {
  return _jsx(TableDate, {
    value: createdAt
  });
};
StoryCreatedDate.displayName = "StoryCreatedDate";
export const StoryCreatedOnField = () => ({
  name: 'createdAt',
  displayName: 'Created On',
  Component: StoryCreatedDate,
  sort: true,
  width: 110
});