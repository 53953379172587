import { MutateStoryPriorityFieldDocument } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { QueryStoryPrioritySelectDocument } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { StoryPriorityFieldFragmentFragmentDoc } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { PriorityIcon } from '@useshortcut/shapes-ds';
import { useCallback } from 'react';
import { gql } from '@clubhouse/datalayer';
import { Center } from '@clubhouse/shared/components/Center';
import { NONE_VALUE, Nouns } from '@clubhouse/shared/constants';
import { PRIORITY_ICONS } from '@clubhouse/shared/utils/customFields';
import { GroupedOptionsFieldSingleSelection } from 'components/gql/GroupedOptionsField';
import { PrioritySelectTarget } from 'components/gql/priority/PrioritySelectTarget';
import { useFieldOptionsQuery } from 'components/gql/stories/table/fields/useFieldOptionsQuery';
import { createOptimisticStoryMutationResponse } from 'components/gql/utils/mutation';
import { useMutation } from 'gql';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const PriorityFieldFragment = StoryPriorityFieldFragmentFragmentDoc;
const QUERY_STORY_PRIORITY_SELECT = QueryStoryPrioritySelectDocument;
const MUTATE_STORY_PRIORITY_FIELD = MutateStoryPriorityFieldDocument;
export const getSection = (groupKey, options) => ({
  sectionId: groupKey,
  items: options.map(option => ({
    value: option.node.id,
    name: option.node.stringValue,
    Icon: () => option.node.position >= 0 ? _jsx(PriorityIcon, {
      icon: PRIORITY_ICONS[option.node.position]
    }) : null
  }))
});
export const PriorityFieldComponent = ({
  entity: {
    id: storyId,
    priority
  }
}) => {
  const fetchPriorities = useFieldOptionsQuery({
    query: QUERY_STORY_PRIORITY_SELECT,
    storyId,
    includeCurrentPermissionId: false
  });
  const [updatePriority] = useMutation(MUTATE_STORY_PRIORITY_FIELD);
  const handleChange = useCallback(async selectedId => {
    await updatePriority({
      variables: {
        storyId,
        input: {
          canonicalName: 'priority',
          valueId: selectedId ?? NONE_VALUE
        }
      },
      optimisticResponse: createOptimisticStoryMutationResponse(storyId, {
        priority: selectedId ? {
          __typename: 'CustomFieldEnumValue',
          id: selectedId
        } : null
      })
    });
  }, [updatePriority, storyId]);
  return _jsx(Center, {
    children: _jsx("div", {
      children: _jsx(GroupedOptionsFieldSingleSelection, {
        selectedEntity: priority,
        fetchOptions: fetchPriorities,
        getSection: getSection,
        onChange: handleChange,
        TargetComponent: PrioritySelectTarget,
        unit: Nouns.Priority
      })
    })
  });
};
PriorityFieldComponent.displayName = "PriorityFieldComponent";
PriorityFieldComponent.displayName = 'PriorityFieldComponent';
export const PriorityField = () => ({
  // The priority name is special in that it is also used to represent
  // the user-defined priority column. We override the sort column
  // instead of changing the name
  name: 'priorityIds',
  displayName: 'Priority',
  headerProps: {
    centered: true
  },
  Component: PriorityFieldComponent,
  width: 70,
  sort: 'priority'
});