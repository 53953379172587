import FeedbackController, { SNAPSHOT_KEY } from '../app/client/core/js/controllers/feedback';
import Url from '../app/client/core/js/modules/url';
import { getGlobal, setGlobal } from './globals';
export const feedbackRoute = () => FeedbackController.route();
export const openFeedbackForm = ({
  subject,
  body,
  permission
} = {}) => {
  // Make sure `close` button takes user back to correct page
  const initialHref = getGlobal('initialHref');
  setGlobal('initialHref', initialHref?.includes('organizations') ? initialHref : Url.getCurrentPathname());

  // Prefill form
  setGlobal(SNAPSHOT_KEY, {
    subject,
    body,
    permission
  });
  FeedbackController.open();
};