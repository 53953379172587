import { LoadingDots } from '@clubhouse/shared/animations/LoadingDots';
import { Button } from '@clubhouse/shared/components/Button';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const LoadMoreButton = ({
  loading,
  onClick
}) => {
  return _jsx(Button, {
    kind: Button.KIND.SECONDARY,
    IconLeft: loading ? LoadingDots : null,
    onClick: onClick,
    isDisabled: loading,
    children: "Load more results"
  });
};
LoadMoreButton.displayName = "LoadMoreButton";