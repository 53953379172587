import { StoryCardInternal } from '../../internal';
import { Group, Owners } from './components';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const GroupAndOwners = ({
  children
}) => _jsx(StoryCardInternal.GroupAndOwners, {
  children: children
});
GroupAndOwners.displayName = "GroupAndOwners";
GroupAndOwners.Group = Group;
GroupAndOwners.Owners = Owners;