import { ContextMenu, ContextMenuDivider, ContextMenuLoading } from '@clubhouse/shared/components/ContextMenu';
import { Nouns } from '@clubhouse/shared/constants';
import { getHref as getObjectiveHref } from 'components/gql/objective/links';
import { useMilestoneWithFetch } from 'data/entity/milestone';
import { CopyContextMenuItem, OpenInNewTabContextMenuItem, PinContextMenuItem } from './actions';
import { useContextMenuWithLogging, useContextMenuWithLoggingOptionalEvents } from './useContextMenuWithLogging';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const ObjectiveContextMenu = ({
  id
}) => {
  const {
    milestone: objective,
    loading
  } = useMilestoneWithFetch({
    id
  });
  if (loading) return _jsx(ContextMenuLoading, {});
  if (!objective) return null;
  return _jsxs(ContextMenu, {
    children: [_jsx(OpenInNewTabContextMenuItem, {
      url: getObjectiveHref(id)
    }), _jsx(ContextMenuDivider, {}), _jsx(CopyContextMenuItem, {
      icon: "Link",
      value: getObjectiveHref(id, {
        includeDomain: true
      }),
      label: "Link"
    }), _jsx(CopyContextMenuItem, {
      value: String(id),
      label: "ID"
    }), _jsx(ContextMenuDivider, {}), _jsx(PinContextMenuItem, {
      id: objective.global_id,
      entityType: Nouns.Objective,
      loggingContext: {
        typename: 'Objective'
      }
    })]
  });
};
ObjectiveContextMenu.displayName = "ObjectiveContextMenu";
export const useObjectiveContextMenu = objectiveId => {
  const {
    props
  } = useContextMenuWithLogging(() => _jsx(ObjectiveContextMenu, {
    id: objectiveId
  }), {
    entityType: 'Objective'
  });
  return props;
};
export const useObjectiveContextMenuWithQuickSearchSessionLogging = (objectiveId, logQuickSearchInteractions) => {
  const {
    props
  } = useContextMenuWithLoggingOptionalEvents(() => _jsx(ObjectiveContextMenu, {
    id: objectiveId
  }), {
    entityType: 'Objective'
  }, logQuickSearchInteractions);
  return props;
};