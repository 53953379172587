import { useCallback, useReducer } from 'react';
import { useCancelablePromises } from '@clubhouse/shared/hooks';
const actions = {
  REQUEST_START: 'REQUEST_START',
  REQUEST_END: 'REQUEST_END',
  RESET: 'RESET'
};
const initialState = () => ({
  error: null,
  loading: false,
  next: '',
  total: 0,
  data: [],
  previousData: [],
  hasFetched: false
});
const searchStoriesReducer = (state, {
  type,
  payload
}) => {
  switch (type) {
    case actions.REQUEST_START:
      return {
        ...state,
        previousData: state.data,
        loading: true,
        error: null
      };
    case actions.REQUEST_END:
      if (payload?.error) {
        return {
          ...state,
          loading: false,
          error: payload.error
        };
      } else {
        return {
          ...state,
          loading: false,
          hasFetched: true,
          total: payload?.total,
          next: payload?.next,
          data: payload.query ? payload?.data || [] : state.data.concat(payload?.data || [])
        };
      }
    case actions.RESET:
      return initialState();
    default:
      return state;
  }
};
export const useSearch = (requestFn, dataMapper) => {
  const searchRequest = useRequestFactory(requestFn);
  const [state, dispatch] = useReducer(searchStoriesReducer, initialState());
  const search = useCallback(async ({
    query = null,
    url = null
  }) => {
    dispatch({
      type: actions.REQUEST_START
    });
    try {
      const response = await searchRequest({
        queryText: query,
        url
      });
      if (!response.error) {
        const data = dataMapper ? response.data.map(dataMapper).filter(Boolean) : response.data;
        dispatch({
          type: actions.REQUEST_END,
          payload: {
            ...response,
            data,
            query
          }
        });
      }
    } catch (err) {
      //nothing
    }
  }, [dataMapper, searchRequest]);
  const reset = useCallback(() => {
    dispatch({
      type: actions.RESET
    });
  }, []);
  const loadMore = useCallback(() => {
    if (state.hasFetched && state.next) {
      search({
        url: state.next
      });
    }
  }, [search, state.hasFetched, state.next]);
  return [state, {
    search,
    reset,
    loadMore
  }];
};
const useRequestFactory = requestFn => {
  const {
    makeCancelable,
    cancelAll: cancelPending
  } = useCancelablePromises();
  const request = useCallback(({
    queryText,
    url
  }) => {
    cancelPending();
    return makeCancelable(new Promise(resolve => {
      if (queryText) {
        return resolve(requestFn(queryText));
      }
      return resolve(requestFn(undefined, undefined, {
        url
      }));
    }));
  }, [cancelPending, makeCancelable, requestFn]);
  return request;
};