import moment from 'moment';
import { useEffect } from 'react';
import { useThemeName } from '@clubhouse/shared/utils/theme';
import { createTemplateFromStory, generateStoryUrl, isNew, save } from 'data/entity/story';
import * as StoryLinkData from 'data/entity/storyLink';
import { getCurrentUser, isValidUser } from 'data/entity/user';
import { updateState } from 'utils/addNew';
import { error, success } from 'utils/message';
import { getCurrentOrigin } from 'utils/navigation';
import * as StoryUtils from 'utils/story';
import { getCommandBar } from '../../getCommandBar';
import { isUserReadOnly } from '../../isUserReadOnly';
import { getCurrentStory } from './getCurrentStory';
import { addContextForStoryEpic } from './storyEpic';
import { addContextForStoryEstimate } from './storyEstimate';
import { addContextForStoryFollowers } from './storyFollowers';
import { addContextForStoryIteration } from './storyIteration';
import { addContextForStoryLabels } from './storyLabels';
import { addContextForStoryOwners } from './storyOwners';
import { addContextForStoryState } from './storyState';
import { addContextForStoryTeam } from './storyTeam';
import { addContextForStoryType } from './storyType';
const setHasActiveStory = value => {
  getCommandBar().addContext('hasActiveStory', value);
};
const addStoryRecords = themeName => eventName => {
  if (eventName !== 'opened') {
    return;
  }
  const user = getCurrentUser();
  if (!isValidUser(user)) {
    return;
  }
  const currentStory = getCurrentStory();
  if (!currentStory) {
    setHasActiveStory(false);
    return;
  }
  setHasActiveStory(true);
  updateContextForStory(currentStory, themeName);
};
export const useStoryBehavior = () => {
  const themeName = useThemeName();
  useEffect(() => {
    const handler = addStoryRecords(themeName);
    getCommandBar().addEventHandlers([handler]);
    return () => {
      getCommandBar().removeEventHandlers([handler]);
    };
  }, [themeName]);
};
const updateContextForStory = (story, themeName) => {
  addContextForStoryOwners(story);
  addContextForStoryEpic(story, themeName);
  addContextForStoryFollowers(story);
  addContextForStoryLabels(story);
  addContextForStoryIteration(story);
  addContextForStoryState(story);
  addContextForStoryTeam(story);
  addContextForStoryEstimate(story);
  addContextForStoryType(story);
  if (!isUserReadOnly()) {
    if (!isNew(story)) getCommandBar().addCallback('convertStoryToEpic', convertStoryToEpic(story));
    if (!isNew(story)) getCommandBar().addCallback('duplicateStory', duplicateStory(story));
    getCommandBar().addCallback('updateDueDate', updateDueDate(story));
    getCommandBar().addCallback('markAsRelatedFor', addStoryRelationship(story, StoryLinkData.VERBS.RELATE, true));
    getCommandBar().addCallback('markAsBlockerFor', addStoryRelationship(story, StoryLinkData.VERBS.BLOCK, true));
    getCommandBar().addCallback('markAsBlockedBy', addStoryRelationship(story, StoryLinkData.VERBS.BLOCK, false));
    getCommandBar().addCallback('markAsDuplicateFor', addStoryRelationship(story, StoryLinkData.VERBS.DUPLICATE, true));
    getCommandBar().addCallback('markAsDuplicatedBy', addStoryRelationship(story, StoryLinkData.VERBS.DUPLICATE, false));
    getCommandBar().addCallback('copyPermalinkToClipboard', copyPermalinkToClipboard(story));
  }
};
const convertStoryToEpic = story => () => {
  StoryUtils.convertStoryToEpic(story);
};
const duplicateStory = story => () => {
  createTemplateFromStory(story);
};
const updateDueDate = story => async ({
  deadline
}) => {
  const formattedDeadline = moment(deadline).format();
  if (isNew(story)) {
    updateState({
      deadline: formattedDeadline
    });
  } else {
    await save({
      id: story.id,
      deadline: formattedDeadline
    });
  }
};
const addStoryRelationship = (story, verb, isSubject) => async ({
  relatedStory
}) => {
  const createRelationship = (story, relatedStory, verb, isSubject) => {
    const link = StoryLinkData.RELATIONSHIPS[verb];
    const relationshipTemplate = {
      [isSubject ? 'subject_id' : 'object_id']: story.id,
      verb: link.verb
    };
    const relationship = {
      ...relationshipTemplate,
      [isSubject ? 'object_id' : 'subject_id']: relatedStory.id
    };
    return relationship;
  };
  const storyLinks = story.story_links || [];
  const relationship = createRelationship(story, relatedStory, verb, isSubject);
  const isDuplicate = storyLinks.some(link => link.verb === relationship.verb && link.subject_id === relationship.subject_id && link.object_id === relationship.object_id);
  if (isDuplicate) {
    error("You can't create a duplicated story link.", {
      secondary: 'No story relationship created.'
    });
    return false;
  }
  StoryLinkData.save(relationship, err => {
    if (err) {
      error(err, {
        secondary: 'No story relationship created.'
      });
    }
  });
};
const copyPermalinkToClipboard = story => async () => {
  const permalink = `${getCurrentOrigin()}${generateStoryUrl(story)}`;
  await navigator.clipboard.writeText(permalink);
  success('Permalink copied to clipboard');
};