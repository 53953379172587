import Backend from '../modules/backend';
import Collection from '../_frontloader/collection';
import LabelModel from './label';
import OrganizationModel from './organization';
import GroupModel from './group';
import Utils from '../modules/utils';
import _ from 'lodash';
const exports = {};
Collection.create('StoryTemplate', exports);
exports.fetchAll = callback => {
  if (OrganizationModel.templatesAreEnabled()) {
    Backend.get('/api/private/entity-templates', {
      onComplete: res => {
        exports.defaultFetchAllHandler(res, callback);
      }
    });
  } else {
    callback();
  }
};
exports.normalize = storyTemplate => {
  storyTemplate.story_contents = {
    group_id: null,
    ...storyTemplate.story_contents
  };
};
exports.create = (name, templateData) => {
  const story_contents = _transformStateToTemplate(templateData);
  return new Promise((resolve, reject) => {
    Backend.post('/api/private/entity-templates', {
      data: {
        name,
        story_contents
      },
      onComplete: res => {
        if (_.get(res, 'error')) {
          reject(res);
        } else {
          exports.update(res);
          resolve(res);
        }
      }
    });
  });
};
exports.updateTemplate = (id, newTemplate) => {
  newTemplate = _.pick(newTemplate, 'name', 'story_contents');
  if (newTemplate.story_contents) {
    newTemplate.story_contents = _transformStateToTemplate(newTemplate.story_contents);
  }
  return new Promise((resolve, reject) => {
    Backend.put('/api/private/entity-templates/' + id, {
      data: newTemplate,
      onComplete: res => {
        if (_.get(res, 'error')) {
          reject(res);
        } else {
          exports.update(res);
          resolve(res);
        }
      }
    });
  });
};
exports.getGroupedTemplates = () => {
  const groupsNotArchived = GroupModel.allNotArchived();
  const groupIds = _.map(groupsNotArchived, 'id');
  const sortedTemplates = _.sortBy(exports.all(), template => {
    return template.name.toLowerCase();
  });
  const groupedTemplates = _.groupBy(sortedTemplates, template => {
    if (template.story_contents.group_id === null) {
      return 'noGroups';
    }
    return template.story_contents.group_id;
  });
  return _.pick(groupedTemplates, [...groupIds, 'noGroups']);
};
exports.deleteTemplate = template => {
  return new Promise((resolve, reject) => {
    Backend.delete('/api/private/entity-templates/' + template.id, {
      onComplete: (res, xhr) => {
        exports.defaultDeleteHandler(template, res, xhr, resolve);
      },
      onError: reject
    });
  });
};
function _transformStateToTemplate(state) {
  const templateContents = _.pick(state, 'name', 'description', 'project_id', 'iteration_id', 'story_type', 'follower_ids', 'owner_ids', 'estimate', 'workflow_state_id', 'epic_id', 'deadline', 'group_id', 'custom_fields');
  return {
    ...templateContents,
    file_ids: _.map(state.files, 'id'),
    external_links: state.external_links,
    tasks: Utils.pluckAll(state.tasks, ['complete', 'description', 'owner_ids']),
    labels: LabelModel.denormalizeLabels(state.labels)
  };
}
export { exports as default };