import { Button } from '@clubhouse/shared/components/Button';
import { Dialog, DialogText, DialogTitle } from '@clubhouse/shared/components/Dialog';
import { PLAN_TABLE_TRIGGER_LOCATIONS, sendPlanTableOpenedEvent } from 'components/settings/manage-billing/tracking';
import { openUpgradeDialog } from 'utils/manageBillingController';
import { useEntitlementViolationMessage } from 'utils/useEntitlementViolation';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const EntitlementViolationDialog = () => {
  const {
    message: violation,
    clear: clearViolation
  } = useEntitlementViolationMessage();
  return _jsx(EntitlementViolationDialogComponent, {
    violation: violation,
    clearViolation: clearViolation
  });
};
EntitlementViolationDialog.displayName = "EntitlementViolationDialog";
const EntitlementViolationDialogComponent = ({
  violation,
  clearViolation = () => {}
}) => {
  const message = violation?.message ?? _jsxs(_Fragment, {
    children: ["You attempted to use a feature that your current plan does not allow.", _jsx("strong", {
      children: " Upgrade"
    }), " to a higher tier to get access to this feature."]
  });
  const onCancel = () => {
    // Clear error message to close dialog
    clearViolation();
  };
  const onConfirm = () => {
    if (violation.triggerLocation) {
      sendPlanTableOpenedEvent(violation.triggerLocation, {
        violation
      });
    } else {
      // send the violation without a trigger location to tracking to parse
      sendPlanTableOpenedEvent(PLAN_TABLE_TRIGGER_LOCATIONS.UNKNOWN, {
        violation
      });
    }
    openUpgradeDialog();
    clearViolation();
  };
  const ActionButtons = ({
    onClose
  }) => {
    return _jsxs(_Fragment, {
      children: [_jsx(Button, {
        kind: Button.KIND.SECONDARY,
        onClick: onClose,
        children: "Cancel"
      }), _jsx(Button, {
        kind: Button.KIND.PRIMARY,
        onClick: onConfirm,
        children: "Upgrade"
      })]
    });
  };
  return _jsxs(Dialog, {
    visible: !!violation,
    onClose: onCancel,
    ActionComponent: ActionButtons,
    children: [_jsx(DialogTitle, {
      children: "Upgrade your plan"
    }), _jsx(DialogText, {
      children: message
    })]
  });
};
EntitlementViolationDialogComponent.displayName = "EntitlementViolationDialogComponent";