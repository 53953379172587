import Async from '../../../core/js/modules/async';
import * as Event from '../../../core/js/_frontloader/event';
import MultiOrgModel from '../../../core/js/models/multiOrg';
import OrganizationModel from '../../../core/js/models/organization';
import View from '../../../core/js/modules/view';
import { OrganizationsDashboardPage } from 'pages/organizations/OrganizationsDashboardPage';
import _ from 'lodash';
const exports = {};
exports.render = () => {
  exports.updateDetailsForDisabledOrgs();
  _redrawPage();
  Event.trigger('pageRendered', 'Dashboard');
};
function _redrawPage() {
  View.renderComponentDelayed({
    mountNode: document.getElementById('organizations'),
    component: OrganizationsDashboardPage
  });
  Event.trigger('resize');
}
exports.updateDetailsForDisabledOrgs = () => {
  const orgFns = _.map(OrganizationModel.getOwnersDisabledOrgs(), function (org) {
    return exports.updateDisabledOrg.bind(this, org);
  });
  Async.eachInParallelThen(orgFns, () => {
    // Hide actions to enable requests to avoid any race conditions
    $('#organizations').addClass('page-loaded');
  });
};
exports.updateDisabledOrg = function (org, callback) {
  Async.eachInParallelThen([MultiOrgModel.fetchAllUsers.bind(this, org), MultiOrgModel.fetchPaymentPlan2.bind(this, org), MultiOrgModel.fetchAllCards.bind(this, org)], () => {
    callback();
  });
};
exports.init = () => {};
export { exports as default };