import { getDisplayName } from 'data/typeConfigs';
export const itemToString = item => item ? item.value : '';
export const getSelectedItemsText = ({
  type
}) => ({
  items
}) => {
  if (items.length === 0) return 'All';
  return `${items.length} ${getDisplayName({
    type,
    isSingular: items.length === 1
  })}`;
};