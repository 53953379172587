import { useEffect, useState } from 'react';
import { useLazyQuery } from 'gql';
import { getSlug, useSearchParams } from 'utils/navigation';
const hasOldIds = queryParams => {
  return Object.values(queryParams).filter(values => values.length > 0).some(values => !values.join('').includes('v'));
};
const swapKeys = ({
  reverseLookup,
  input
}) => {
  const lookup = Object.fromEntries([...Object.entries(reverseLookup).map(keyValue => keyValue.reverse()), ...Object.entries(reverseLookup)]);
  return Object.fromEntries(Object.entries(input || {}).filter(([key]) => lookup[key]).map(([key, value]) => [lookup[key], value]));
};
export const useTranslateUrl = ({
  query,
  searchParamsToFieldLookup
}) => {
  const {
    params: queryParams,
    setSearchParams
  } = useSearchParams(Object.keys(searchParamsToFieldLookup));
  const [isReady, setIsReady] = useState(false);
  const slug = getSlug();
  const [getTranslatedIds] = useLazyQuery(query, {
    fetchPolicy: 'no-cache'
  });
  useEffect(() => {
    (async () => {
      if (hasOldIds(queryParams)) {
        const {
          data
        } = await getTranslatedIds({
          variables: {
            slug,
            input: swapKeys({
              reverseLookup: searchParamsToFieldLookup,
              input: queryParams
            })
          }
        });
        const translatedParams = swapKeys({
          reverseLookup: searchParamsToFieldLookup,
          input: data?.workspace2?.filters
        });
        setSearchParams(translatedParams);
      }
      setIsReady(true);
    })();
  }, [getTranslatedIds, queryParams, searchParamsToFieldLookup, setSearchParams, slug]);
  return {
    isReady
  };
};