import { useCallback } from 'react';
import { NONE_VALUE } from '@clubhouse/shared/constants';
import { insertIf } from '@clubhouse/shared/utils/object';
import { useLazyQuery } from 'gql';
import { useLazyWorkspaceCurrentPermissionIdQuery } from 'gql/queries/useWorkspaceCurrentPermissionIdQuery';
import { useWorkspaceSlug } from 'utils/navigation';
export function useFilterOptionsQuery({
  query,
  selectedIds,
  includeCurrentPermissionId
}) {
  const slug = useWorkspaceSlug();
  const [executeQuery] = useLazyQuery(query);
  const lazyCurrentPermission = useLazyWorkspaceCurrentPermissionIdQuery();
  return useCallback(async ({
    inputValue
  }) => {
    const {
      data
    } = await executeQuery({
      variables: {
        workspaceSlug: slug,
        input: {
          ...insertIf(includeCurrentPermissionId, {
            currentPermissionId: await lazyCurrentPermission()
          }),
          selectedIds: selectedIds.filter(value => value !== NONE_VALUE),
          ...(inputValue ? {
            fuzzyName: inputValue.trim()
          } : {})
        }
      }
    });
    return data?.workspace2?.options;
  }, [executeQuery, includeCurrentPermissionId, lazyCurrentPermission, selectedIds, slug]);
}