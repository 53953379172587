import { Combobox } from '@clubhouse/shared/components/Combobox';
import { EmojiMenu, EmojiPicker } from 'components/shared/EmojiPicker';
import { MentionMenu, MentionPicker } from 'components/shared/MarkdownTextArea/MentionMenu';
import { SearchMenu, StoryPicker } from 'components/shared/MarkdownTextArea/SearchMenu';
import { getById as getGroupById } from 'data/entity/group';
import { getProfileById } from 'data/entity/profile';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const mapToMention = fn => id => {
  const member = getProfileById(id);
  if (member) return fn(`@${member.profile.mention_name}`);
  const group = getGroupById(id);
  if (group) fn(`@${group.mention_name}`);
};
export const Autocompletes = {
  Emoji: {
    label: 'Add emoji',
    icon: 'Emoji',
    Picker: ({
      onSelect,
      children
    }) => _jsx(EmojiPicker, {
      onSelect: onSelect,
      children: children
    }),
    Menu: ({
      onSelect,
      triggerRef,
      onClose
    }) => _jsx(Combobox, {
      onSelect: onSelect,
      onClose: onClose,
      children: _jsx(EmojiMenu, {
        triggerRef: triggerRef
      })
    })
  },
  Mention: {
    label: 'Add mention',
    icon: 'Mention',
    Picker: ({
      onSelect,
      children
    }) => _jsx(MentionPicker, {
      onSelect: mapToMention(onSelect),
      children: children
    }),
    Menu: ({
      onSelect,
      triggerRef,
      onClose
    }) => _jsx(Combobox, {
      onSelect: mapToMention(onSelect),
      onClose: onClose,
      children: _jsx(MentionMenu, {
        triggerRef: triggerRef
      })
    })
  },
  Story: {
    label: 'Add story reference',
    icon: 'Story',
    Picker: ({
      onSelect,
      children
    }) => _jsx(StoryPicker, {
      onSelect: onSelect,
      children: children
    }),
    Menu: ({
      onSelect,
      triggerRef,
      onClose
    }) => _jsx(Combobox, {
      onSelect: onSelect,
      onClose: onClose,
      children: _jsx(SearchMenu, {
        triggerRef: triggerRef
      })
    })
  }
};