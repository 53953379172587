import { Icon } from '@useshortcut/shapes-ds';
import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const getIconForStoryType = ({
  story_type
}) => {
  switch (story_type) {
    case 'feature':
      return ({
        width
      }) => _jsx(DeprecatedIconAdapter, {
        width: width,
        fill: "var(--iconYellowColor)",
        children: _jsx(Icon, {
          icon: "Feature"
        })
      });
    case 'bug':
      return ({
        width
      }) => _jsx(DeprecatedIconAdapter, {
        width: width,
        fill: "var(--iconRedColor)",
        children: _jsx(Icon, {
          icon: "Bug"
        })
      });
    case 'chore':
      return ({
        width
      }) => _jsx(DeprecatedIconAdapter, {
        width: width,
        children: _jsx(Icon, {
          icon: "Chore",
          fill: "main"
        })
      });
    default:
      throw new Error('Invalid story type: ' + story_type);
  }
};
export const getIconNameForStoryType = type => type === 'feature' ? 'Feature' : type === 'bug' ? 'Bug' : 'Chore';