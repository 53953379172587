import { EVENTS, logEvent } from 'utils/monitoring';
const CATEGORY = 'signup';
const FORM_VERSION = 4;
export const segmentEventByPage = [EVENTS.Signup_Form_Page1_Visited, EVENTS.Signup_Form_Page2_Visited, EVENTS.Signup_Form_Page3_Visited, EVENTS.Signup_Form_Page4_Visited, EVENTS.Signup_Form_Page5_Visited];
export const logSignupPageVisited = (newStepIndex, isUserGDPR) => logEvent(segmentEventByPage[newStepIndex], {
  ...(newStepIndex === 1 ? {
    is_gdpr: isUserGDPR,
    form_version: FORM_VERSION
  } : {
    form_version: FORM_VERSION
  })
});
export const logCompanyNameFieldMiss = workspaceName => logEvent(EVENTS.Company_Name_Field_Miss, {
  original_value: workspaceName,
  edited_value: workspaceName,
  form_version: FORM_VERSION
});
export const logJoinWorkspacesPage = () => logEvent(EVENTS.Join_Workspaces_Page_Visited, {
  form_version: FORM_VERSION
});
export const logSignupPageCompleted = ({
  pageName: page_name,
  signupStep: signup_step,
  signup
}) => {
  return logEvent(EVENTS.Signup_Page_Completed, {
    form_version: FORM_VERSION,
    page_name,
    signup_step,
    ...(signup && {
      is_oauth: signup.is_oauth,
      signup_id: signup.id,
      user_id: signup.user_id
    })
  });
};
export const logSignupFormCompleted = organization_name => logEvent(EVENTS.Signup_Form_Completed, {
  organization_name,
  category: CATEGORY,
  form_version: FORM_VERSION
});
export const logSkipInvite = data => logEvent(EVENTS.Inviting_Skipped, {
  ...data,
  form_version: FORM_VERSION
});
export const logInviteRegistrationPageCompleted = data => logEvent(EVENTS.Invite_Registration_Page_Completed, {
  ...data,
  add_another_clicked: false,
  form_version: FORM_VERSION
});