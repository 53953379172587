import { bumpProfileRenderId } from 'components/layout/profile/profileRenderId';
import Is from './is';
import LocalStorage from './localStorage';
import Tests from './tests';
import Url from './url';
import User from './user';
import Utils from './utils';
import IntegrationsController from '../../../integrations/js/controllers/integrations';
import EditOrganizationController from '../../../settingsShared/js/controllers/editOrganization';
import EditProfileController from '../../../settingsShared/js/controllers/editProfile';
import InviteUsersController from '../../../settingsShared/js/controllers/inviteUsers';
import HotReloadController from '../controllers/hotReload';
import LogoutController from '../controllers/logout';
import StoryDialogController from '../controllers/storyDialog';
const exports = {};
const _getContentElement = () => document.querySelector('#app-root > #content');
exports.getLayoutContextProps = () => ({
  Is,
  Url,
  LocalStorage,
  Tests,
  Utils,
  Controller: {
    HotReload: HotReloadController,
    StoryDialog: StoryDialogController
  }
});

// Legacy page initializers use this to render their caveman template into the layout
exports.renderContent = (html = '') => {
  const content = _getContentElement();
  content.innerHTML = html;
};
exports.getProfileProps = () => ({
  openSettings: EditOrganizationController.open,
  openIntegrations: IntegrationsController.open,
  openProfileSettings: EditProfileController.open,
  logOut: LogoutController.logoutAndRedirect,
  showExitImpersonation: User.isImpersonationMode,
  exitImpersonation: LogoutController.endImpersonation,
  routes: {
    editProfile: EditProfileController.route(),
    settings: EditOrganizationController.route(),
    integrations: IntegrationsController.route(),
    inviteUsers: InviteUsersController.route()
  }
});
exports.renderProfileFromCurrentUser = () => {
  bumpProfileRenderId();
};
export default exports;