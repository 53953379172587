import { UpdateStoryIterationDocument } from "../../../../../datalayer/__generated_graphql_types__/graphql";
import { MoveToIterationContextMenuItemFragmentDoc } from "../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { Button } from '@clubhouse/shared/components/Button';
import { ContextMenuItem } from '@clubhouse/shared/components/ContextMenu';
import { Emojify } from '@clubhouse/shared/components/Emojify';
import { Spaced } from '@clubhouse/shared/components/Spaced';
import { ToastText, addToast } from '@clubhouse/shared/components/Toast';
import { getHref } from 'components/gql/iteration/links';
import { isLoggedInUserObserver } from 'data/entity/user';
import { useMutation } from 'gql';
import { loadURL } from 'utils/hotReload';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const MoveToIterationContextMenuItemFragment = MoveToIterationContextMenuItemFragmentDoc;
const UpdateStoryIteration = UpdateStoryIterationDocument;
const getIterationId = (moveTo, team) => {
  if (moveTo === 'current') return team?.currentIteration || null;
  if (moveTo === 'next') return team?.nextIteration || null;
  return null;
};
export function MoveToIterationContextMenuItem({
  id,
  moveTo,
  iteration,
  team
}) {
  const moveToIteration = getIterationId(moveTo, team);
  const isAlreadyAssignedToIteration = moveToIteration?.id === iteration?.id;
  const canMoveIteration = moveToIteration && !isAlreadyAssignedToIteration && !isLoggedInUserObserver();
  const [save] = useMutation(UpdateStoryIteration);
  return _jsx(ContextMenuItem, {
    isDisabled: !canMoveIteration,
    icon: "Iteration",
    label: `Move to ${moveTo} iteration`,
    onClick: async () => {
      if (!canMoveIteration) return;
      try {
        await save({
          variables: {
            storyId: id,
            input: {
              iteration: moveToIteration?.id
            }
          }
        });
        addToast({
          kind: 'success',
          timeout: 5000,
          Content: () => _jsxs(Spaced, {
            vertically: true,
            amount: "space2",
            children: [_jsxs(ToastText, {
              children: ["Story was moved to", ' ', _jsx("strong", {
                children: _jsx(Emojify, {
                  children: moveToIteration?.name || ''
                })
              })]
            }), _jsx(Button, {
              kind: Button.KIND.SECONDARY,
              onClick: () => loadURL(getHref(moveToIteration?.publicId), 'Iteration'),
              children: "View Iteration"
            })]
          })
        });
      } catch {
        addToast({
          kind: 'alert',
          timeout: 10_000,
          Content: () => _jsx(ToastText, {
            children: "Failed to move story to iteration."
          })
        });
      }
    }
  });
}
MoveToIterationContextMenuItem.displayName = "MoveToIterationContextMenuItem";