import "core-js/modules/esnext.set.difference.v2.js";
import "core-js/modules/esnext.set.intersection.v2.js";
import "core-js/modules/esnext.set.is-disjoint-from.v2.js";
import "core-js/modules/esnext.set.is-subset-of.v2.js";
import "core-js/modules/esnext.set.is-superset-of.v2.js";
import "core-js/modules/esnext.set.symmetric-difference.v2.js";
import "core-js/modules/esnext.set.union.v2.js";
export const applicationStateKey = 'teamNavigation';
export const maxNumberOfTeams = 20;
export const ALL_WORK = 'all-work';
export const getValidExpandedTeamIds = ({
  expandedTeamIds,
  selectedTeamIds
}) => [...new Set(expandedTeamIds.filter(id => id === ALL_WORK || selectedTeamIds.includes(id)))];
export const createTeamNavigationState = state => ({
  expandedTeamIds: (state?.expandedTeamIds ?? []).filter(Boolean).slice(0, maxNumberOfTeams),
  selectedTeamIds: (state?.selectedTeamIds ?? []).filter(Boolean).filter(v => v !== ALL_WORK).slice(0, maxNumberOfTeams),
  isInitialized: state?.isInitialized ?? false
});