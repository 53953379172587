import noop from 'lodash/noop';
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useLatestRef } from '@clubhouse/shared/hooks';
import { useHandleChangeRef, useItemsRef, useObserverRef } from './ListRangeObserver';

/**
 * @param shouldReturnVisibility If false, there are less component renders. Set to false if `isVisible` isn't needed.
 */
export const useItem = ({
  index,
  shouldReturnVisibility = true
}) => {
  const currentItemRef = useRef(null);
  const observerRef = useObserverRef();
  const itemsRef = useItemsRef();
  const indexRef = useLatestRef(index);
  const shouldReturnVisibilityRef = useLatestRef(shouldReturnVisibility);
  const handleChangeRef = useHandleChangeRef();
  const [isVisible, setIsVisible] = useState(false);
  const getObservedItem = useCallback(item => {
    if (!item) return;
    return itemsRef.current.get(item);
  }, [itemsRef]);
  const updateItemCallbackRef = useCallback(item => {
    currentItemRef.current = item;
    if (item && !getObservedItem(item)) {
      itemsRef.current.set(item, {
        entry: null,
        isIntersecting: false,
        index: indexRef.current,
        relativeLeft: null,
        relativeTop: null,
        ...(shouldReturnVisibilityRef.current ? {
          setIsVisible
        } : {
          setIsVisible: noop
        })
      });
      observerRef.current?.observe(item);
    }
  }, [getObservedItem, indexRef, itemsRef, observerRef, shouldReturnVisibilityRef]);
  useEffect(() => {
    const items = itemsRef.current;
    const handleChange = handleChangeRef.current;
    const item = currentItemRef.current;
    return () => {
      const observedItem = getObservedItem(item);
      if (observedItem && item) {
        observerRef.current?.unobserve(item);
        items.delete(item);
        handleChange?.();
      }
    };
  }, [getObservedItem, handleChangeRef, itemsRef, observerRef]);
  useLayoutEffect(() => {
    const observedItem = getObservedItem(currentItemRef.current);
    if (observedItem) {
      observedItem.index = index;
      observedItem.setIsVisible = shouldReturnVisibility ? setIsVisible : noop;
      handleChangeRef.current();
    }
  }, [getObservedItem, handleChangeRef, index, shouldReturnVisibility]);

  // Calling getBoundingClientRect forces browser layout, which causes performance problems: https://gist.github.com/paulirish/5d52fb081b3570c81e3a#getting-box-metrics
  // Using boundingClientRect from the intersection observer entry does not force layout
  const {
    width,
    height
  } = getObservedItem(currentItemRef.current)?.entry?.boundingClientRect ?? {};
  return {
    itemRef: updateItemCallbackRef,
    width,
    height,
    ...(shouldReturnVisibility ? {
      isVisible
    } : {})
  };
};