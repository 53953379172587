import { EntityProgressBar } from '@clubhouse/shared/components/EntityProgressBar';
import { Nouns } from '@clubhouse/shared/constants';
import { getProgressStats, useOptimizedEpic } from 'data/entity/epic';
import { isUsingPoints } from 'data/entity/estimateScale';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const PROGRESS_DETAILS = {
  tooltipStatsPercent: true
};
export function EpicProgressBar({
  epicId,
  height = 8
}) {
  const epic = useOptimizedEpic(epicId);
  if (!epic) return null;
  const progressUnit = isUsingPoints() ? Nouns.Point : Nouns.Story;
  const stats = getProgressStats(epic);
  return _jsx(EntityProgressBar, {
    entityName: Nouns.Epic.singular,
    detail: PROGRESS_DETAILS,
    stats: stats,
    unit: progressUnit.singular,
    unitPlural: progressUnit.plural,
    tooltipType: "individual",
    height: height
  });
}
EpicProgressBar.displayName = "EpicProgressBar";