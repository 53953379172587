import * as Sentry from '@sentry/browser';
import { FEATURE_TOGGLES, getFeatureToggle } from '@clubhouse/feature-toggles';
import { isPlaywrightTestEnv } from 'utils/isPlaywrightTestEnv';
import { SEMANTIC_ATTRIBUTE_SENTRY_SOURCE } from '@sentry/browser';
import { getValue } from '../../localStorage';
import { NOOP_MONITOR } from '../utils';

/* Override value should be: {"val": true} */
export const enablePerformanceProfilingOverrideKey = 'enable_performance_profiling_override';
export const NAME = 'Sentry';
export const shouldInitialize = ({
  env
}) => {
  if (isPlaywrightTestEnv()) {
    return false;
  }
  return getValue(enablePerformanceProfilingOverrideKey) === true || ['staging', 'production'].includes(env);
};
export const shouldOwnEvent = () => false;
export const shouldOwnNamedEvent = () => false;

// Unused by the docs team: https://useshortcut.slack.com/archives/CC14REP2M/p1696002749384689
const ckEditorErrorsToIgnore = ['CKEditorError', 'CKEditorCloudServicesServerError'];
export const initialize = ({
  debug,
  env,
  deployId
}) => {
  Sentry.init({
    dsn: 'https://46bca5e4310547e38265df18b5f4fbd6@o59735.ingest.sentry.io/5806751',
    integrations: [Sentry.browserTracingIntegration({
      /*
        We manually start navigation transactions because Sentry starts a new navigation transaction each time the URL changes.
        For some pages, we change the URL immediately after page render (to set URL params), which causes two navigation events.
        This causes navigation timings to look shorter than they really are.
         For example, visiting /epics without any url params (when clicking on the epics icon in the nav sidebar),
        will start to render the page, then set any previously set filters in the URL.
      */
      instrumentNavigation: false,
      beforeStartSpan: context => {
        return {
          ...context,
          status: document.visibilityState === 'hidden' ? 'cancelled' : undefined
        };
      }
    }), Sentry.replayIntegration()],
    tracePropagationTargets: [/^http:\/\/localhost/, /^http[s]?:\/\/[^/]*?.shortcut(-[^/]*)?.com\/backend/],
    replaysSessionSampleRate: 0,
    // https://docs.sentry.io/platforms/javascript/session-replay/#sampling
    replaysOnErrorSampleRate: 0.3,
    debug: debug,
    environment: env,
    release: env === 'debug' ? 'localhost' : deployId,
    ignoreErrors: [
    // Some entities will fail because they are deleted.
    'NetworkError',
    // Some entities will fail because they are deleted.
    'Failed to fetch',
    // Caused by requests being aborted
    'AbortError',
    // Caused by us exceeding our Launch Darkly quota
    'QuotaExceededError', 'ResizeObserver loop limit exceeded', 'ResizeObserver loop completed with undelivered notifications',
    // SSL connection errors
    'Es ist ein SSL-Fehler aufgetreten',
    // Caused by people using unsupported browsers.
    'Promise.allSettled is not a function',
    // Network errors when fetching Launch Darkly flags
    'Error fetching flag settings: network error',
    // Thrown when a component unmounts that uses useCancelablePromises. This is usually intended, and the stack trace in sentry doesn't provide actionable info.
    'Promise was canceled',
    // Thrown when a user visits a disabled workspace (maybe other scenarios too), but it is expected when the workspace is disabled
    'Workspace not found or you do not have access to it.',
    // Reproduced by a short timeout in ajax requests, but not sure how this occurs in production.
    // A few replays show the "Reconnecting..." toast message. Probably not actionable at the moment.
    'Non-Error promise rejection captured with value: The request failed. If you have any browser extensions that affect network requests, please try disabling them. Otherwise <a href="mailto:support@shortcut.com">email us</a>.',
    // Apollo errors due to expired session or private data
    'Response not successful: Received status code 401', 'Response not successful: Received status code 403',
    // Error emitted from CefSharp (library for automating chromium in C#)
    'Object Not Found Matching Id', ...ckEditorErrorsToIgnore],
    denyUrls: [
    // Ignore errors caused by browser extensions:
    /(chrome|safari|moz)-extension:/i, /safari-web-extension:/i,
    // Ignore errors on localhost (when environment is production):
    ...(env === 'production' ? [/^https?:\/\/localhost/] : [])],
    tracesSampler(samplingContext) {
      if (getValue(enablePerformanceProfilingOverrideKey) === true) return 1;

      // Always report transaction in staging, development and debug as well.
      if (['development', 'debug', 'staging'].includes(env)) return 1;

      // We want to collect page loads
      if (samplingContext.attributes?.[SEMANTIC_ATTRIBUTE_SENTRY_SOURCE] === 'url') return 1;
      if (getFeatureToggle(FEATURE_TOGGLES.ENABLE_FULL_PERF_TRACING)) return 1;

      // Update on 5/2/2024: I'm changing the default sampling rate to 10%.
      // Main reason for this is because I'm increasing the sample rate for initial page loads to 100%,
      // so to avoid very high transaction counts, I'm lowering the other ones.
      // I think this is an okay trade-off because initial page load is more important to track for us.
      // We can also undo this as needed later of course.
      //
      // ---
      // 25% sampling rate by default across all of our pages.
      //
      // as of 4/17/2023, the last 30 days had about 8.2 M page load
      // events
      // https://management-logs.production.shortcut-internal.com/_plugin/kibana/app/visualize#/edit/d453f5e0-dfaa-11ed-9b27-6921d03464af?_g=(filters%3A!()%2CrefreshInterval%3A(pause%3A!t%2Cvalue%3A0)%2Ctime%3A(from%3Anow-30d%2Cto%3Anow))
      // however, according to our previous sample rate of 2.5%, we
      // sent Sentry 1.2M transactions in the past 90 days or 400k per
      // month. If we blindly 10x that, we get about 4M transactions
      // and our Sentry plan now allows us 5M transactions per
      // month. This leaves us with about 1M transactions of
      // headroom if we wanted to periodically capture more
      // transactions for a particular circumstance.
      return 0.1;
    }
  });
  return {
    ...NOOP_MONITOR,
    setSessionAttributes,
    setCurrentPage,
    logError,
    logEvent,
    startTimedBlock,
    setTags
  };
};
const startTimedBlock = (namespace, name) => {
  const activeSpan = Sentry.getActiveSpan();
  const span = activeSpan ? Sentry.withActiveSpan(activeSpan, () => Sentry.startInactiveSpan({
    op: namespace,
    name
  })) : null;
  return {
    end: data => {
      if (span) {
        if (data) {
          Object.entries(data).forEach(([key, value]) => {
            if (key && value != null) {
              span.setAttribute(key, value);
            }
          });
        }
        span.end();
      }
    }
  };
};
const setCurrentPage = (currentPage, pattern) => {
  if (pattern) {
    Sentry.getActiveSpan()?.updateName(pattern);
  }
  setTags({
    'L.page': currentPage
  });
};
const setSessionAttributes = attrs => {
  const {
    userId,
    username,
    workspaceSlug,
    workspaceId,
    organizationSlug,
    organizationId
  } = attrs;
  Sentry.setUser({
    id: userId,
    username,
    workspaceSlug,
    workspaceId,
    organizationSlug,
    organizationId
  });
  setTags({
    'U.workspaceSlug': workspaceSlug,
    'U.organizationSlug': organizationSlug,
    'U.workspaceId': workspaceId,
    'U.organizationId': organizationId
  });
};
const logError = (err, extra, contexts) => {
  Sentry.captureException(err, {
    extra,
    contexts
  });
};
const logEvent = async (event, data) => {
  addBreadcrumbs(event, data);
};
const addBreadcrumbs = (event, data) => {
  const segments = event.split(':');
  const namespace = segments.length > 1 ? segments.shift() : 'misc';
  Sentry.addBreadcrumb({
    category: namespace,
    message: segments.join(':'),
    level: 'info',
    data
  });
};
const setTags = tags => {
  Object.entries(tags).forEach(([key, value]) => {
    Sentry.setTag(key, value);
  });
};