import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const LinkDots = ({
  fill = '#3A95C9'
}) => {
  return _jsxs("svg", {
    width: "33",
    height: "4",
    viewBox: "0 0 33 4",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    children: [_jsx("rect", {
      x: "0.5",
      width: "4",
      height: "4",
      rx: "2",
      fill: fill
    }), _jsx("g", {
      opacity: "0.8",
      children: _jsx("rect", {
        x: "7.5",
        width: "4",
        height: "4",
        rx: "2",
        fill: fill
      })
    }), _jsx("g", {
      opacity: "0.6",
      children: _jsx("rect", {
        x: "14.5",
        width: "4",
        height: "4",
        rx: "2",
        fill: fill
      })
    }), _jsx("g", {
      opacity: "0.4",
      children: _jsx("rect", {
        x: "21.5",
        width: "4",
        height: "4",
        rx: "2",
        fill: fill
      })
    }), _jsx("g", {
      opacity: "0.2",
      children: _jsx("rect", {
        x: "28.5",
        width: "4",
        height: "4",
        rx: "2",
        fill: fill
      })
    })]
  });
};
LinkDots.displayName = "LinkDots";