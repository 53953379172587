import * as LabelContainerTemplate from 'app/client/core/views/templates/labelContainer.html';
import * as AddLabelButtonTemplate from 'app/client/core/views/templates/addLabelButton.html';

/*
 * Shared methods for other controllers to attach labels.
 */
import AutocompleteController from './autocomplete';
import LabelModel from '../models/label';
import Utils from '../modules/utils';
const exports = {};
const PARENT_SELECTOR = '.add-new-dialog';
exports.openDropdown = function (updateEntity, existingLabels = []) {
  let labels = existingLabels.slice(0);
  const onLabelsChange = () => {
    updateEntity({
      labels
    });
    exports.redraw(labels);
  };
  const setLabels = newLabels => {
    labels = newLabels;
    onLabelsChange();
  };
  AutocompleteController.open({
    items: LabelModel.getItemsForLabelsAutocompleteFromExistingLabels(labels),
    target: this,
    noActive: true,
    footer: `
      <div class="autocomplete-footer-tip">
      You can use quotes to create labels with spaces, e.g. "label with spaces". Labels
      can be edited on the Labels page.</div>`,
    multiSelect: true,
    title: 'Add or Remove Labels',
    showInput: true,
    placeholder: 'mockup, "tech debt"',
    onResultsUpdate: ({
      hasResults,
      setNoResults,
      value
    }) => {
      if (!hasResults) {
        setNoResults(LabelModel.createNewLabelText(value));
      }
    },
    onApply: label => {
      setLabels(LabelModel.addLabel(labels, label));
    },
    topOffset: 4,
    width: 280
  });
  return false;
};
exports.remove = function (updateEntity, existingLabels = []) {
  const $tag = $(this).closest('.tag');
  const name = $tag.data('id') ? Utils.getModelFromContext(this).name : $tag.text().trim();
  const labels = existingLabels.filter(label => {
    return label.name !== name;
  });
  updateEntity({
    labels
  });
  exports.redraw(labels);
  return false;
};
exports.redraw = (labels = []) => {
  // can this just redraw the newEpicLabelsControlController component now?
  let html = LabelContainerTemplate.render({
    labels,
    onClick: 'removeLabel',
    onRemove: 'removeLabel'
  });
  html += AddLabelButtonTemplate.render({
    onClick: 'openLabelDropdown'
  });
  $(PARENT_SELECTOR).find('.labels').html(html);
};
export { exports as default };