import { StoryGroupByWorkflowFragmentFragmentDoc } from "../../../../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { ButtonText } from '@clubhouse/shared/components/Button';
import { Emojify } from '@clubhouse/shared/components/Emojify';
import { Nouns } from '@clubhouse/shared/constants';
import { GroupByHeader } from 'components/gql/groupBy/GroupByHeader';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const StoryGroupByWorkflowFragment = StoryGroupByWorkflowFragmentFragmentDoc;
export const WorkflowNameGroupHeader = ({
  workflowState
}) => {
  const workflow = workflowState?.workflow;
  return _jsx(GroupByHeader, {
    entityType: Nouns.Workflow,
    children: typeof workflow?.name === 'string' ? _jsx(ButtonText, {
      children: _jsx(Emojify, {
        children: workflow.name
      })
    }) : 'No Workflow'
  });
};
WorkflowNameGroupHeader.displayName = "WorkflowNameGroupHeader";