import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo } from 'react';
import { useMountedState } from 'react-use';
const defaultOptions = {
  trailing: true
};
export const useDebounce = (fn, timeout, options = defaultOptions) => {
  const isMounted = useMountedState();
  const debouncedFunction = useMemo(() => debounce(fn, timeout, options), [fn, options, timeout]);
  useEffect(() => debouncedFunction.cancel, [debouncedFunction]);
  const wrappedFn = useCallback((...args) => {
    if (isMounted()) {
      return debouncedFunction(...args);
    }
    return undefined;
  }, [debouncedFunction, isMounted]);
  return wrappedFn;
};