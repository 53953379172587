import BaseUtils from '../_frontloader/baseUtils';
import Dom from './dom';
import Iterate from './iterate';
import _ from 'lodash';
const exports = {};
exports.callbackTimer = options => {
  let id;
  const speed = options.speed || 10;
  const max = options.max || 250;
  const done = _.after(Math.round(max / speed), () => {
    clearInterval(id);
  });
  id = setInterval(() => {
    options.fn();
    done();
  }, speed);
};

// All functions are run in parallel, then the callback is executed.
// Expects an array of functions that accept a single callback as an argument.
exports.eachInParallelThen = (fnArray, callback) => {
  let i = 0;
  const results = [];
  if (fnArray.length === 0) {
    return callback();
  }
  Iterate.each(fnArray, (fn, n) => {
    fn((err, result) => {
      i++;
      results[n] = result;
      if (i === fnArray.length && _.isFunction(callback)) {
        callback(results);
      }
    });
  });
};

// All functions are run in parallel, then the callback is executed.
// Expects an array of functions that accept a single callback as an argument.
exports.eachInParallelThenWithShortCircuiting = (fnArray, callback) => {
  let i = 0;
  const results = [];
  let exited = false;
  if (fnArray.length === 0) {
    return callback();
  }
  Iterate.each(fnArray, (fn, n) => {
    fn((err, result) => {
      if (!exited) {
        i++;
        results[n] = result;
        if ((err || i === fnArray.length) && _.isFunction(callback)) {
          exited = true;
          callback(err, results);
        }
      }
    });
  });
};

// All functions are run in sequence, then the callback is executed.
// Expects an array of functions that accept a single callback as an argument.
exports.eachInSequenceThen = (fnArray, callback) => {
  if (fnArray.length === 0) {
    return callback();
  }
  const fn = fnArray.shift();
  fn(() => {
    exports.eachInSequenceThen(fnArray, callback);
  });
};
exports.eachInSequence = fnArray => {
  if (fnArray.length === 0) {
    return;
  }
  const fn = fnArray.shift();
  fn(() => {
    exports.eachInSequence(fnArray);
  });
};

// takes an array of functions that return promises
exports.eachPromiseInSequence = promiseArray => {
  if (promiseArray.length === 0) {
    return Promise.resolve();
  }
  const p = promiseArray.shift();
  return p().then(() => {
    return exports.eachPromiseInSequence(promiseArray);
  });
};
exports.waitFor = (testFn, callback, poll) => {
  if (testFn()) {
    return callback();
  }
  setTimeout(() => {
    exports.waitFor(testFn, callback, poll);
  }, poll || 250);
};
exports.loadScriptAndWaitFor = (scriptSrc, testFn, callback) => {
  if (testFn()) {
    return callback();
  }
  Dom.loadScript(scriptSrc);
  exports.waitFor(testFn, callback, 50);
};
exports.loadScriptAndThen = (scriptSrc, name, callback) => {
  const testFn = () => {
    return !!window[name];
  };
  exports.loadScriptAndWaitFor(scriptSrc, testFn, callback);
};
exports.Promises = {};
exports.Promises.loadScriptAndThen = BaseUtils.promisify(exports.loadScriptAndThen);
export { exports as default };