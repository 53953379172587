import Iterate from './iterate';
const exports = {};

/** @deprecated use loadScript from @clubhouse/shared/utils/dom */
exports.loadScript = (src, options) => {
  options = options || {};
  options.attributes = options.attributes || {};
  options.properties = options.properties || {};
  if (options.attributes.id) {
    $(`#${options.attributes.id}`).remove();
  }
  const element = document.createElement('script');
  Iterate.each(options.attributes, (v, k) => {
    element.setAttribute(k, v);
  });
  Iterate.each(options.properties, (v, k) => {
    element[k] = v;
  });
  element.src = src;
  (document.head || document.body).appendChild(element);
  return element;
};
exports.writeCSS = (id, css) => {
  exports.removeCSS(id);
  const element = document.createElement('style');
  element.id = 'style-' + id;
  element.type = 'text/css';
  if (element.styleSheet) {
    element.styleSheet.cssText = css;
  } else {
    element.appendChild(document.createTextNode(css));
  }
  (document.head || document.body).appendChild(element);
};
exports.removeCSS = id => {
  if ($('#style-' + id).length > 0) {
    $('#style-' + id).remove();
  }
};
exports.closest = (element, selector) => {
  const matches = element.matches || element.webkitMatchesSelector || element.mozMatchesSelector || element.msMatchesSelector;
  if (!matches) {
    return $(element).closest(selector);
  }
  while (element && element.parentNode) {
    if (matches.call(element, selector)) {
      return element;
    } else {
      element = element.parentNode;
    }
  }
  return false;
};
exports.hide = node => {
  node.classList.add('hidden');
  node.ariaHidden = true;
};
exports.show = node => {
  node.classList.remove('hidden');
  node.ariaHidden = false;
};
exports.Promises = {};

/** @deprecated Use loadScript in @clubhouse/shared/utils/dom */
exports.Promises.loadScript = (...args) => {
  return new Promise(resolve => {
    // Maybe there's a reason why we currently use polling instead of `.onload`?
    exports.loadScript(...args).onload = () => {
      resolve();
    };
  });
};
export { exports as default };