import { createContext, useContextSelector } from 'use-context-selector';
import { Nouns } from '@clubhouse/shared/constants';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const Context = createContext({
  isLoading: false,
  type: Nouns.Epic.singular
});
export const ContextProvider = ({
  children,
  isLoading,
  type
}) => _jsx(Context.Provider, {
  value: {
    isLoading,
    type
  },
  children: children
});
ContextProvider.displayName = "ContextProvider";
export const useIsLoading = () => useContextSelector(Context, ({
  isLoading
}) => isLoading);
export const useType = () => useContextSelector(Context, ({
  type
}) => type);