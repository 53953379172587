import { DuplicateEpicDocument } from "../../../../../datalayer/__generated_graphql_types__/graphql";
import { DuplicateEpicActionFragmentDoc } from "../../../../../datalayer/__generated_graphql_types__/graphql";
import { useCallback } from 'react';
import { gql } from '@clubhouse/datalayer';
import { ObjectivePageDocument } from '@clubhouse/datalayer/types';
import { Button } from '@clubhouse/shared/components/Button';
import { MoreActions } from '@clubhouse/shared/components/MoreActions';
import { Spaced } from '@clubhouse/shared/components/Spaced';
import { ToastText, addToast } from '@clubhouse/shared/components/Toast';
import { Nouns } from '@clubhouse/shared/constants';
import { sanitize } from '@clubhouse/shared/utils';
import { useMutation } from 'gql';
import { navigateOnClick } from 'utils/navigation';
import { isLoggedInUserObserver } from 'data/entity/user';
import { getHref } from '../links';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const DuplicateEpicActionFragment = DuplicateEpicActionFragmentDoc;
const DuplicateEpicMutation = DuplicateEpicDocument;
export const DuplicateEpicAction = ({
  epic
}) => {
  const [duplicateEpic] = useMutation(DuplicateEpicMutation, {
    refetchQueries: [ObjectivePageDocument]
  });
  const onDuplicate = useCallback(async () => {
    try {
      const result = await duplicateEpic({
        variables: {
          epicId: epic.id
        }
      });
      const {
        name,
        publicId
      } = result?.data?.epicDuplicate?.epic ?? {};
      if (!name || !publicId) throw new Error('Response from duplication is missing expected properties.');
      addToast({
        kind: 'success',
        timeout: 5000,
        Content: () => _jsxs(Spaced, {
          vertically: true,
          amount: "space2",
          children: [_jsxs(ToastText, {
            children: ["Duplicate of ", _jsx("strong", {
              children: sanitize(name)
            }), " was created."]
          }), _jsx(Button, {
            kind: Button.KIND.SECONDARY,
            size: Button.SIZE.SMALL,
            onClick: navigateOnClick(getHref(publicId)),
            children: "View Epic"
          })]
        })
      });
    } catch {
      addToast({
        kind: 'alert',
        timeout: 5000,
        Content: () => _jsx(ToastText, {
          children: "Duplication failed. Try again or contact support."
        })
      });
    }
  }, [duplicateEpic, epic.id]);
  return _jsxs(MoreActions.Item, {
    icon: "Copy",
    onClick: onDuplicate,
    isDisabled: isLoggedInUserObserver(),
    children: ["Duplicate ", Nouns.Epic.singular]
  });
};
DuplicateEpicAction.displayName = "DuplicateEpicAction";