import { useRef } from 'react';
import { PAGE_SIZE } from '@clubhouse/shared/components/Table/config';
import { insertIf as insertArrayIf } from '@clubhouse/shared/utils/array';
import { insertIf as insertObjectIf } from '@clubhouse/shared/utils/object';
import { useEpicsTableQuery } from 'components/gql/epics/table/useEpicsTableQuery';
import { useCurrentPageIndex } from 'components/gql/pagination/TablePagination';
import { useFilters } from 'pages/epics-apollo/components/filters/useFilters';
import { useGroupBy } from './groupBy';
export { ALL_SORTABLE_VALUES } from 'components/gql/epics/table/useEpicsTableQuery';

// We don't change the url at this point. Not sure if we want to break peoples saved urls etc?
const translatedSortColumn = {
  objective_name: 'objective_name'
};
export function useEpicsTableData({
  sortColumn,
  sortDirection
}) {
  const queryMetaDataMap = useRef(new WeakMap());
  const groupBy = useGroupBy();
  const filters = useFilters();
  const page = useCurrentPageIndex();
  const limit = PAGE_SIZE;
  const where = {
    ...insertObjectIf(filters.query.trim().length > 0, {
      name: filters.query.trim()
    }),
    ...insertObjectIf(filters.projectIds.length > 0, {
      projects: filters.projectIds
    }),
    ...insertObjectIf(filters.ownerIds.length > 0, {
      owners: filters.ownerIds
    }),
    ...insertObjectIf(filters.milestoneIds.length > 0, {
      objectives: filters.milestoneIds
    }),
    ...insertObjectIf(filters.objectiveIds.length > 0, {
      objectives: filters.objectiveIds
    }),
    ...insertObjectIf(filters.stateIds.length > 0, {
      epicStates: filters.stateIds
    }),
    ...insertObjectIf(filters.labelIds.length > 0, {
      labels: filters.labelIds
    }),
    ...insertObjectIf(filters.groupIds.length > 0, {
      teams: filters.groupIds
    }),
    archived: filters.showArchived
  };
  const {
    data,
    previousData,
    ...result
  } = useEpicsTableQuery({
    pagination: {
      limit,
      offset: page * limit
    },
    orderBy: [...insertArrayIf(!!groupBy, [{
      value: groupBy,
      direction: 'ascending'
    }]), {
      value: translatedSortColumn[sortColumn] || sortColumn,
      direction: sortDirection
    }],
    where
  }, undefined);
  if (data) {
    queryMetaDataMap.current.set(data, {
      groupBy
    });
  }
  return {
    ...result,
    ...(queryMetaDataMap.current.get(data || previousData) ?? {})
  };
}