import _ from 'lodash';
import Utils from '../modules/utils';
import View from '../modules/view';
const exports = {};
const NS = 'ClubhouseScrollTabs'; // Event namespace
const ROOT_SELECTOR = '.scroll-tabs';
const OFFSET_TOP = 200;
exports.isScrolling = false;
let _scrollContainer;

/**
 * @param {config.widthElSelector} [string] Selector to use for calculating width
 */
exports.init = ({
  widthElSelector,
  scrollContainer
} = {}) => {
  const thResizeHandler = _.throttle(() => resizeHandler(widthElSelector), 100);
  const thScrollHandler = _.throttle(scrollHandler, 100);
  $(scrollContainer || window).on('scroll.' + NS, thScrollHandler);
  $(window).on('resize.' + NS, thResizeHandler);
  _scrollContainer = scrollContainer;
  resizeHandler(widthElSelector);
};
exports.destroy = () => {
  $(_scrollContainer || window).off('scroll.' + NS);
  $(_scrollContainer || window).off('resize.' + NS);
};
exports.selectTab = function () {
  if (exports.isScrolling) {
    return false;
  }
  const me = $(this);
  const id = Utils.data(this, 'tab');
  if (!me.hasClass('active')) {
    Utils.fireEventHandler(me, 'on-tab-focus');
  }
  exports.isScrolling = true;
  Utils.scrollToElement($('#' + id), () => {
    exports.isScrolling = false;
  }, _scrollContainer);
  markTabAsActive(this);
  return false;
};
exports.defaultFocusHandler = () => {
  View.genericResizeHandler();
};
function resizeHandler(widthElSelector) {
  const $el = $(ROOT_SELECTOR);
  $el.width((widthElSelector ? $(widthElSelector) : $el.parent()).outerWidth());
  $el.parent().css({
    paddingTop: $el.outerHeight()
  });
}
function scrollHandler() {
  // If user explicitly clicked on a tab, let's force that tab to remain active
  // until the scrolling stops, then we can resume normal scroll handling.
  if (exports.isScrolling) {
    return false;
  }
  const container = $(_scrollContainer || window);
  const isUsingRavenNav = document.body.classList.contains('using-raven-nav');
  const tabs = $(ROOT_SELECTOR).find('.tab');
  const top = container.scrollTop();
  const position = top + (isUsingRavenNav ? $('.scroll-tabs').outerHeight() + $(_scrollContainer).offset().top : OFFSET_TOP);
  if (container.scrollTop() === 0) {
    markTabAsActive(tabs.first());
  } else if (container.scrollTop() + container.height() === $(_scrollContainer || document).height()) {
    markTabAsActive(tabs.last());
  } else {
    tabs.reverse().each(function () {
      const id = $(this).data('tab');
      const container = $('#' + id);
      const sectionTop = isUsingRavenNav ? Math.floor(_.get(container.offset(), 'top', 0) + top) : _.get(container.offset(), 'top', 0);
      if (sectionTop <= position) {
        markTabAsActive(this);
        return false;
      }
    });
  }
}
function markTabAsActive(element) {
  const me = $(element);
  const parent = me.closest(ROOT_SELECTOR);
  parent.find('.tab.active').removeClass('active');
  me.addClass('active');
}
export { exports as default };