import { StoryGroupByProductAreaFragmentFragmentDoc } from "../../../../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { Nouns } from '@clubhouse/shared/constants';
import { GroupByHeader } from 'components/gql/groupBy/GroupByHeader';
import { ProductAreaWithIcon } from 'components/gql/productArea/ProductAreaWithIcon';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const StoryGroupByProductAreaFragment = StoryGroupByProductAreaFragmentFragmentDoc;
export const ProductAreaGroupHeader = ({
  productArea
}) => _jsx(GroupByHeader, {
  entityType: Nouns.ProductArea,
  children: typeof productArea?.stringValue === 'string' ? _jsx(ProductAreaWithIcon, {
    spacing: 12,
    name: productArea.stringValue,
    colorKey: productArea.colorKey
  }) : 'No Product Area'
});
ProductAreaGroupHeader.displayName = "ProductAreaGroupHeader";