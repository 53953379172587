import "core-js/modules/es.array.push.js";
import TooltipController from 'app/client/core/js/controllers/tooltip.js';
import GroupModel from 'app/client/core/js/models/group.js';
import Tooltip from 'app/client/core/js/modules/tooltip.js';
window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || {
    Controller: {},
    Model: {}
  };
  [[['Controller', 'Tooltip'], TooltipController], [['Model', 'Group'], GroupModel], [['Tooltip'], Tooltip], [['Controller', 'Tooltip'], TooltipController], [['Model', 'Group'], GroupModel], [['Tooltip'], Tooltip]].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});
import { Emojify } from '@clubhouse/shared/components/Emojify';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
function _TeamAutoLink({
  team,
  showTooltip
}) {
  return _jsx("span", {
    "data-model": "Group",
    "data-id": team.id,
    children: _jsx("span", {
      className: "inline-mention mention-found",
      ...(showTooltip && {
        'data-tooltip': true,
        'data-kind': 'autolink',
        'data-tooltip-react-fn': 'App.Controller.Tooltip.renderGroupTooltip'
      }),
      children: _jsx(Emojify, {
        children: team.name
      })
    })
  });
}
_TeamAutoLink.displayName = "_TeamAutoLink";
export const TeamAutoLink = ({
  team,
  showTooltip = false
}) => {
  if (!team) return _jsx("em", {
    children: "Deleted Team"
  });
  return _jsx(_TeamAutoLink, {
    team: team,
    showTooltip: showTooltip
  });
};
TeamAutoLink.displayName = "TeamAutoLink";