import HotReloadController from 'app/client/core/js/controllers/hotReload';
import { getGlobal } from './globals';
const IS_INITIAL_PAGE_RENDER_COMPLETE = 'utils.isInitialPageRenderComplete';
export const loadPageFromMenu = (...args) => HotReloadController.loadPageFromMenu(...args);
export const loadURL = (url, page, options = null) => HotReloadController.loadURL(url, page, options);
export const loadURLWithDelay = (url, page, delay = 1000, options = null) => setTimeout(() => HotReloadController.loadURL(url, page, options), delay);
export const isInitialPageRenderComplete = () => {
  return Boolean(getGlobal(IS_INITIAL_PAGE_RENDER_COMPLETE));
};
export const load = (...args) => HotReloadController.load(...args);
export const resetPage = () => HotReloadController.resetPage();