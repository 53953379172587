import { EpicHealthHistoryQueryDocument } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { useCallback, useMemo } from 'react';
import { gql } from '@clubhouse/datalayer';
import { emptyArray } from '@clubhouse/shared/utils';
import * as Result from '@clubhouse/shared/utils/result';
import { HealthStatusPopover } from 'components/shared/healthStatus/HealthStatusPopover';
import { useCreateHealthReactionMutation, useDeleteHealthReactionMutation, useUpdateHealthStatusMutation } from 'components/shared/healthStatus/hooks/mutations';
import { useQuery } from 'gql';
import { useConsolidatedDataFetched } from 'utils/consolidatedFetch';
import { getCurrentSlug } from 'utils/navigation';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const PAGE_SIZE = 10;
const EpicHealthHistoryQuery = EpicHealthHistoryQueryDocument;
export const EpicHealthPopover = ({
  id,
  triggerRef,
  currentHealthStatus,
  onClose,
  onSaveCurrent,
  onChangeHistory,
  snapshotPrefix = ''
}) => {
  const isReady = useConsolidatedDataFetched();
  const {
    data,
    previousData,
    error,
    refetch,
    fetchMore
  } = useQuery(EpicHealthHistoryQuery, {
    variables: {
      id,
      input: {
        pagination: {
          limit: PAGE_SIZE,
          offset: 0
        }
      }
    }
  });
  const currentData = data || previousData;
  const isLoading = !isReady || !currentData && !error;
  const healthHistory = currentData?.node && 'healthHistory' in currentData.node ? currentData.node.healthHistory : null;
  const history = useMemo(() => isLoading ? HealthStatusPopover.LOADING : (healthHistory?.edges || emptyArray).map(({
    node
  }) => node), [healthHistory?.edges, isLoading]);
  const loadedItems = healthHistory?.edges?.length || 0;
  const update = useUpdateHealthStatusMutation();
  const createReaction = useCreateHealthReactionMutation();
  const deleteReaction = useDeleteHealthReactionMutation();
  const handleSave = useCallback(async (id, newStatus, newComment) => {
    try {
      await update({
        variables: {
          id,
          input: {
            status: newStatus,
            // We don't send `null` for empty comment here if user has edited the field, because the backend will consider that "unchanged".
            // This means that if a user removes a comment when editing, the stats don't consider this an "empty comment".
            // Product considers this an edge case that is okay to ignore for now (as of 2023-12-04).
            text: newComment
          }
        },
        optimisticResponse: {
          healthUpdate: {
            __typename: 'HealthPayload',
            health: {
              __typename: 'Health',
              id,
              status: newStatus,
              text: newComment
            }
          }
        }
      });
      onChangeHistory?.(newStatus, newComment);
      refetch();
      return Result.ok(true);
    } catch (error) {
      return Result.err(error);
    }
  }, [onChangeHistory, refetch, update]);
  const addEmoji = useCallback(async (id, emoji) => {
    await createReaction({
      variables: {
        slug: getCurrentSlug(),
        input: {
          health: id,
          emoji
        }
      }
    });
    refetch();
    return Result.ok(true);
  }, [createReaction, refetch]);
  const removeEmoji = useCallback(async emojiId => {
    await deleteReaction({
      variables: {
        id: emojiId
      }
    });
    refetch();
    return Result.ok(true);
  }, [deleteReaction, refetch]);
  const onLoadMore = useCallback(() => {
    fetchMore({
      variables: {
        id,
        input: {
          pagination: {
            limit: PAGE_SIZE,
            offset: loadedItems
          }
        }
      }
    });
  }, [fetchMore, id, loadedItems]);
  return _jsx(HealthStatusPopover, {
    triggerRef: triggerRef,
    currentHealthStatus: currentHealthStatus,
    history: history,
    totalHistorySize: healthHistory?.pageInfo.totalSize || 0,
    onClose: onClose,
    onSaveCurrent: onSaveCurrent,
    onChangeHistory: handleSave,
    onLoadMore: onLoadMore,
    onAddReaction: addEmoji,
    onRemoveReaction: removeEmoji,
    snapshotKey: `${snapshotPrefix}.newHealthUpdate.${id}`
  });
};
EpicHealthPopover.displayName = "EpicHealthPopover";