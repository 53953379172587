import IntegrationModel from '../../../core/js/models/integration';
const exports = {};
exports.createAndAuthorize = ({
  code,
  redirect_uri
}) => {
  return new Promise((resolve, reject) => {
    return IntegrationModel.createNew({
      type: 'slack'
    }, (err, integration) => {
      if (err) {
        return reject(err);
      }
      return resolve(exports.authorize({
        integration,
        code,
        redirect_uri
      }));
    });
  });
};
exports.authorize = ({
  integration,
  code,
  redirect_uri
}) => {
  return IntegrationModel.authorizeSlackIntegrationV2({
    integration,
    code,
    redirect_uri
  });
};
export { exports as default };