import "core-js/modules/es.array.push.js";
import HotReloadController from 'app/client/core/js/controllers/hotReload.js';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || {
    Controller: {},
    Model: {}
  };
  [[['Controller', 'HotReload'], HotReloadController], [['Controller', 'HotReload'], HotReloadController]].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});
export function HotReload({
  page,
  url,
  tooltip
}) {
  return _jsx("div", {
    className: "attribute-toggle",
    children: _jsx("a", {
      href: url,
      "data-controller": "HotReload",
      "data-on-click": "load",
      "data-page": page,
      "data-tabindex": true,
      "data-tooltip": tooltip,
      children: _jsx("span", {
        className: "fa fa-external-link"
      })
    })
  });
}
HotReload.displayName = "HotReload";