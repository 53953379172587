import { NONE_VALUE } from '@clubhouse/shared/constants';
import { getDisplayName } from 'data/typeConfigs';
export const createNoneItem = ({
  type,
  linkedType
}) => {
  const displayName = getDisplayName({
    type
  });
  return {
    text: `No ${displayName}`,
    triggerText: `${getDisplayName({
      type: linkedType,
      isSingular: false
    })} with no ${displayName}`,
    value: NONE_VALUE
  };
};