import { Icon } from '@useshortcut/shapes-ds';
import { COLORS } from '../../colors';
import { DeprecatedIconAdapter } from './DeprecatedIconAdapter';

/**
 * @deprecated Use <Icon icon="Pin" /> from @useshortcut/shapes-ds
 */
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const PinStory = ({
  fill = COLORS.BLACK,
  width = '14'
}) => _jsx(DeprecatedIconAdapter, {
  width: width,
  fill: fill,
  children: _jsx(Icon, {
    icon: "Pin"
  })
});
PinStory.displayName = "PinStory";