import { useCallback, useEffect, useRef } from 'react';
import { focusWithoutScroll, getFocusableChildren } from '../utils/focus';
const getSibling = (items, current, direction, moveFullLength) => {
  if (moveFullLength) {
    if (direction < 0) return items[0];else if (direction > 0) return items[items.length - 1];
  }
  let siblingIndex = items.indexOf(current) + direction;
  if (siblingIndex < 0) siblingIndex = items.length - 1;else if (siblingIndex >= items.length) siblingIndex = 0;
  return items[siblingIndex];
};
const focusNext = (items, ref, toLast) => {
  if (!ref.current) return;
  ref.current = getSibling(items, ref.current, 1, toLast);
  ref.current.focus();
};
const focusPrev = (items, ref, toFirst) => {
  if (!ref.current) return;
  ref.current = getSibling(items, ref.current, -1, toFirst);
  ref.current.focus();
};
const getAll = parent => getFocusableChildren(parent);
const getFirst = parent => getAll(parent)[0] || null;
const getLast = parent => {
  const all = getAll(parent);
  return all[all.length - 1] || null;
};
/**
 * @description Used to navigate between multiple selectable options in a list.
 */
export function useKeyboardListNavigation({
  listRef,
  activeRef,
  onSelect,
  direction = 'both'
}) {
  const focusRef = useRef(null);
  useEffect(() => {
    focusRef.current = activeRef.current;
  }, [activeRef]);
  const onKeyDown = useCallback(e => {
    const items = getAll(listRef.current);
    if (!focusRef.current) focusRef.current = items[0];
    const goPrev = e => {
      e.preventDefault();
      e.stopPropagation();
      focusPrev(items, focusRef, e.altKey);
    };
    const goNext = e => {
      e.preventDefault();
      e.stopPropagation();
      focusNext(items, focusRef, e.altKey);
    };
    switch (e.key) {
      case 'ArrowUp':
        {
          if (direction !== 'horizontal') goPrev(e);
          break;
        }
      case 'ArrowLeft':
        {
          if (direction !== 'vertical') goPrev(e);
          break;
        }
      case 'ArrowDown':
        {
          if (direction !== 'horizontal') goNext(e);
          break;
        }
      case 'ArrowRight':
        {
          if (direction !== 'vertical') goNext(e);
          break;
        }
      case 'Tab':
        {
          const el = e.shiftKey ? getFirst(listRef.current) : getLast(listRef.current);
          if (el && el.getAttribute('tabindex') !== '-1') focusWithoutScroll(el);
          break;
        }
      case ' ':
      case 'Enter':
        {
          if (typeof onSelect === 'function') {
            e.preventDefault();
            e.stopPropagation();
            onSelect(focusRef.current);
          }
          break;
        }
    }
  }, [direction, onSelect, listRef]);
  const onBlur = useCallback(() => {
    focusRef.current = null;
  }, []);
  const onFocus = useCallback(e => {
    focusRef.current = e.target;
  }, []);
  return {
    onKeyDown,
    onBlur,
    onFocus
  };
}