import { useFinishActiveTransaction } from './useFinishActiveTransaction';
export const useGqlActiveTransaction = ({
  isInitialDataFromCache,
  isLoading,
  tags
}) => {
  useFinishActiveTransaction({
    shouldFinishTransaction: !isLoading,
    tags: {
      'A.cacheHit': isInitialDataFromCache ? 'true' : 'false',
      ...tags
    }
  });
};