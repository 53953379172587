import { WORKFLOW_STATE_TYPES } from '@clubhouse/shared/constants';
import MilestoneModel, { updateMilestonePromise } from 'app/client/core/js/models/milestone';
import { useEntities, useEntity, useEntityWithFetch, useOptimizedEntities, useOptimizedEntity } from './collection';
import { isUsingPoints } from './estimateScale';
export const all = () => MilestoneModel.all();
export const isMilestoneDone = milestone => MilestoneModel.isDone(milestone);
export const deleteMilestone = (milestone, callback) => MilestoneModel.deleteMilestone(milestone, callback);
export const archiveMilestone = (milestone, callback) => MilestoneModel.archiveMilestone(milestone, callback);
export const unarchiveMilestone = (milestone, callback) => MilestoneModel.unarchiveMilestone(milestone, callback);
export const updateMilestone = (milestone, updates) => MilestoneModel.Promises.updateMilestone(milestone, updates);
export const updateMilestoneAndEpics = (milestone, state) => MilestoneModel.Promises.moveMilestoneAndEpics(milestone, state);
export const getMilestoneByGlobalId = globalId => MilestoneModel.get({
  global_id: globalId
});
export const getMilestoneById = id => MilestoneModel.getById(id);
export const getMilestoneProgressStats = milestone => {
  const unit = isUsingPoints() ? 'points' : 'stories';
  return {
    [WORKFLOW_STATE_TYPES.BACKLOG]: milestone[`num_${unit}_backlog`] ?? 0,
    [WORKFLOW_STATE_TYPES.UNSTARTED]: milestone[`num_${unit}_unstarted`],
    [WORKFLOW_STATE_TYPES.STARTED]: milestone[`num_${unit}_started`],
    [WORKFLOW_STATE_TYPES.DONE]: milestone[`num_${unit}_done`]
  };
};
export const normalizeMilestone = milestone => MilestoneModel.normalize(milestone);
export const saveMilestoneChanges = (milestone, changes) => updateMilestonePromise(milestone, changes);
export const useMilestones = ({
  archived
} = {}) => {
  let {
    entities: milestones
  } = useEntities({
    model: MilestoneModel
  });
  if (typeof archived === 'boolean') {
    milestones = milestones.filter(milestone => milestone.archived === archived);
  }
  return {
    milestones
  };
};
export const useOptimizedMilestones = ({
  fields,
  filter
}) => useOptimizedEntities({
  model: MilestoneModel,
  fields,
  filter
});
export const useMilestone = ({
  id
}) => {
  const {
    entity
  } = useEntity({
    model: MilestoneModel,
    id
  });
  return entity;
};
export const useMilestoneWithFetch = ({
  id
}) => {
  const {
    entity,
    loading
  } = useEntityWithFetch({
    id,
    model: MilestoneModel,
    fetchById: fetchMilestone
  });
  return {
    milestone: entity,
    loading
  };
};
export const fetchMilestone = id => MilestoneModel.Promises.fetch({
  id
});
const FIELDS_TO_COMPARE = ['last_story_update', 'updated_at'];
export const useOptimizedMilestone = id => {
  const milestone = useOptimizedEntity(id, {
    model: MilestoneModel,
    hasChanges: (oldValue, newValue) => {
      // biome-ignore lint/suspicious/noDoubleEquals: We want null and undefined to compare as the same
      return FIELDS_TO_COMPARE.some(field => oldValue?.[field] != newValue?.[field]);
    }
  });
  return milestone;
};