import { TableRow, TableRowForInfiniteScroll } from '@clubhouse/shared/components/Table';
import { StatelessTable } from '@clubhouse/shared/components/Table/StatelessTable';
import { LOADING_STATE } from 'components/gql/utils/Loading';
import { StaleLoading } from 'components/shared/StaleLoading';
import { useEpicReordering } from 'pages/epics-apollo/entities/epics/hooks/useEpicReordering'; // eslint-disable-line import/no-restricted-paths
import { useEpicContextMenu } from 'utils/contextMenus/useEpicContextMenu';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const EpicRow = props => {
  const contextMenuProps = useEpicContextMenu(props.item.id);
  return _jsx(TableRow, {
    ...props,
    contextMenuProps: contextMenuProps
  });
};
EpicRow.displayName = "EpicRow";
export const EpicRowForInfiniteScroll = props => {
  const contextMenuProps = useEpicContextMenu(props.item.id);
  return _jsx(TableRowForInfiniteScroll, {
    ...props,
    contextMenuProps: contextMenuProps
  });
};
EpicRowForInfiniteScroll.displayName = "EpicRowForInfiniteScroll";
export function EpicsTable({
  epics,
  columns,
  isLoading,
  isStale,
  footer,
  sortColumn,
  sortDirection,
  fixedBottomRow,
  infiniteScroll,
  onSort,
  updateQuery
}) {
  const onReorder = useEpicReordering({
    epics,
    sortDirection,
    updateQuery
  });
  return _jsxs(_Fragment, {
    children: [_jsx(StatelessTable, {
      allItems: epics,
      columns: columns,
      sortColumn: sortColumn,
      sortDirection: sortDirection,
      onSort: onSort,
      onReorder: onReorder,
      items: epics,
      disableHeader: false,
      footer: footer,
      RowComponent: infiniteScroll ? EpicRowForInfiniteScroll : EpicRow,
      fixedBottomRow: fixedBottomRow,
      ...(isLoading && LOADING_STATE)
    }), isStale && _jsx(StaleLoading, {})]
  });
}