import * as SettingsDialogTemplate from 'app/client/core/views/templates/settingsDialog.html';
import Dialog from './dialog';
import Is from './is';
import SettingsController from '../controllers/settings';
import SampleWorkspaceController from '../controllers/sampleWorkspace';
import _ from 'lodash';
const exports = {};
exports.init = () => {
  SettingsController.init();
};
exports.isOpen = () => {
  return $('#settings').length > 0;
};
exports.open = options => {
  if (SampleWorkspaceController.isSampleObserver()) {
    return false;
  }
  options = options || {};
  const html = SettingsDialogTemplate.render({
    content: options.template.render(options.data)
  });
  Dialog.open(html, {
    id: 'settingsModal',
    fullscreen: true,
    closeOnEscape: _.isBoolean(options.closeOnEscape) ? options.closeOnEscape : true,
    route: options.route,
    title: options.title,
    slideshow: options.slideshow,
    classNames: options.classNames,
    width: options.width,
    verticallyCenter: options.verticallyCenter,
    onClose: () => {
      if (Is.mobile()) {
        $('#content').css({
          display: 'block'
        });
      }
      if (_.isFunction(options.onClose)) {
        options.onClose();
      }
    },
    onOpen: options.onOpen,
    onPrev: options.onPrev,
    onNext: options.onNext
  });
  if (Is.mobile()) {
    $('#content').css({
      display: 'none'
    });
  }
  return false;
};
export { exports as default };