import { useRef, useState } from 'react';
import { Combobox } from '@clubhouse/shared/components/Combobox';
import { Spaced } from '@clubhouse/shared/components/Spaced';
import { FNS } from '../MarkdownDisplay/tableFns';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export function TableFnsMenu({
  triggerRef,
  filter
}) {
  const [query, setQuery] = useState(filter ?? '');
  const lastFilter = useRef(filter);
  if (typeof filter === 'string' && lastFilter.current !== filter) {
    lastFilter.current = filter;
    setQuery(filter);
  }
  const usedQuery = query.toLowerCase().replace(/\(\)?$/, '');
  const matchingItems = Object.entries(FNS).filter(([key]) => key.toLowerCase().startsWith(usedQuery));
  if (!matchingItems.length) return null;
  return _jsx(Combobox.Menu, {
    triggerRef: triggerRef,
    isModal: false,
    children: _jsx(Combobox.List, {
      children: matchingItems.map(([key, {
        label
      }]) => _jsx(Combobox.SelectableItem, {
        value: `=${key}`,
        children: _jsxs(Spaced, {
          vertically: true,
          amount: 2,
          children: [key, _jsx("small", {
            children: label
          })]
        })
      }, key))
    })
  });
}
TableFnsMenu.displayName = "TableFnsMenu";