import "core-js/modules/es.array.push.js";
import * as AboutTheCreatedVsCompletedChartTemplate from 'app/client/core/views/templates/reports/aboutTheCreatedVsCompletedChart.html';
import { getOrgWorkingDays } from 'data/entity/organization';
import Url from '../modules/url';
import View from '../modules/view';
import ReportModel from '../models/report';
import ReportsController from './reports';
import { NON_WORKING_DAYS_LABEL, importChartingLibraries, mergeNonWorkingData } from 'utils/charts';
import { CreatedVsCompletedReportCard } from 'components/reports/createdVsCompleted/Report';
import CreatedVsCompletedScreenshot from '@clubhouse/assets/png/screenshots/example-created-vs-completed-chart.png';
import moment from 'moment';
const exports = {};
const CHART_ID = ReportModel.CHARTS.CREATED_VS_COMPLETED;
const DATE_FORMAT = ReportModel.DEFAULT_DATE_FORMAT;
exports.renderChartComponent = ({
  chartData,
  errorMsg,
  options
}) => {
  const mountNode = document.getElementById(`${CHART_ID}-chart`);
  if (mountNode) {
    View.renderComponent({
      mountNode,
      component: CreatedVsCompletedReportCard,
      props: {
        chartId: CHART_ID,
        chartData,
        errorMsg,
        renderChart: () => exports.renderChart({
          chartData,
          options
        })
      }
    });
  }
};
exports.openHelp = () => {
  ReportsController.openAboutThisChart({
    template: AboutTheCreatedVsCompletedChartTemplate
  }, {
    imgSrc: CreatedVsCompletedScreenshot
  });
  return false;
};
exports.CONFIG_OPTIONS = {
  cc_aggregate: {
    title: 'Sum using: ',
    defaultValue: 'count',
    values: [{
      name: 'Points',
      value: 'points'
    }, {
      name: 'Story count',
      value: 'count'
    }]
  }
};
exports.getChartConfig = key => {
  return Url.getParamFromUrl(key) || exports.CONFIG_OPTIONS[key]?.defaultValue;
};
const _formatData = ({
  chartData,
  sortedDates,
  aggregateType
}) => {
  return sortedDates.reduce((acc, date) => {
    acc.dateStrings.push(date);
    acc.created.push(chartData[date].created[aggregateType]);
    acc.completed.push(chartData[date].completed[aggregateType]);
    return acc;
  }, {
    dateStrings: [],
    created: [],
    completed: []
  });
};
exports.renderChart = async ({
  chartData,
  options = {}
}) => {
  const {
    c3
  } = await importChartingLibraries();
  const data = {
    ...chartData
  };
  const sortedDates = Object.keys(data).sort((a, b) => a.localeCompare(b));
  const aggregateType = exports.getChartConfig('cc_aggregate');
  const aggregateLabel = aggregateType === 'count' ? 'Stories' : 'Points';
  const {
    dateStrings,
    created,
    completed
  } = _formatData({
    chartData: data,
    sortedDates,
    aggregateType
  });
  const labels = {
    created: `${aggregateLabel} Created`,
    completed: `${aggregateLabel} Completed`
  };
  const workingDays = getOrgWorkingDays();
  const c3Input = {
    bindto: options.element || document.getElementById(CHART_ID),
    data: {
      x: 'x',
      xFormat: '%Y-%m-%d',
      columns: [['x', ...dateStrings], [labels.created, ...created], [labels.completed, ...completed]],
      types: {
        [labels.created]: 'area-spline',
        [labels.completed]: 'area-spline'
      },
      colors: {
        [labels.created]: '#3469B2',
        [labels.completed]: '#72d090'
      },
      order: () => {
        return 0;
      }
    },
    point: {
      show: true
    },
    axis: {
      x: {
        type: 'timeseries',
        tick: {
          format: date => {
            const MAX_LABEL_COUNT = 5;
            const mod = Math.floor(dateStrings.length / MAX_LABEL_COUNT);
            const index = dateStrings.indexOf(moment.utc(date).format(DATE_FORMAT));
            if (dateStrings.length <= MAX_LABEL_COUNT || index % mod === 0) {
              return moment.utc(date).format('MMM D, YYYY');
            }
          },
          values: dateStrings
        }
      }
    },
    tooltip: {
      format: {
        title: date => moment(date).format('dddd, MMM D, YYYY'),
        value: (count, _, id) => {
          if (id === NON_WORKING_DAYS_LABEL) {
            return undefined;
          }
          return count;
        }
      }
    }
  };
  const workingDaysTooltips = {
    tooltip: {
      format: {
        title: date => {
          const myMoment = moment.utc(date);
          const longDate = myMoment.format('dddd, MMM D, YYYY');
          if (!workingDays.includes(myMoment.day())) {
            return `${longDate} (No Work)`;
          }
          return longDate;
        }
      }
    }
  };
  const c3InputWithNonWorkingDays = mergeNonWorkingData(workingDays, options.interval.id, c3Input, workingDaysTooltips);
  return c3.generate(c3InputWithNonWorkingDays);
};
export { exports as default };