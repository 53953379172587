import { useState } from 'react';
import { FEATURE_TOGGLES } from '@clubhouse/feature-toggles';
import { CHARTS } from '@clubhouse/shared/types';
import { createSearchParamConfig } from 'components/gql/utils/createSearchParamConfigs';
import { Explanation } from 'components/reports/burndown/Explanation';
import { useScrollTarget } from 'components/shared/scrollTabs/shared';
import { useWorkspaceUtcOffset } from 'data/entity/organization';
import { getCurrentAggregationType } from 'data/entity/report';
import { useVariation } from 'utils/launch-darkly';
import { getCurrentPage, getParamFromUrl, useSearchParam } from 'utils/navigation';
import { ChartErrorBoundary } from '../reports-v2/ChartErrorBoundary';
import { CHART_NO_DATA_HEIGHT } from '../reports-v2/ChartUnstartedUtils';
import { ReportCard } from '../reports-v2/ReportCard';
import { ReportCardHeaderEmptyState } from '../reports-v2/ReportCardHeaderEmptyState';
import { StoryPointsToggle } from '../reports-v2/StoriesPointsToggle';
import { BURNDOWN_CHART_HEIGHT } from '../reports-v2/constants';
import { BurndownSelect } from './BurndownSelect';
import { DailyBurndown } from './DailyBurndown';
import { DetailedBurndown } from './DetailedBurndown';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const BURNDOWN_CHART_TYPE = 'burndown_chart_type';
const CHART_ID = CHARTS.BURNDOWN;
const burndownChartConfig = createSearchParamConfig({
  paramName: BURNDOWN_CHART_TYPE,
  paramType: 'string',
  defaultValue: 'daily',
  shouldSetDefaultValueInUrl: true
});
export const BurndownReportCard = ({
  iterationId,
  startDate,
  endDate,
  iterationStatus
}) => {
  const {
    value: usesDetailedBurndown
  } = useVariation(FEATURE_TOGGLES.ITERATION_REPORTS_V2_DETAILED_BURNDOWN);
  const {
    param: burndownChartType,
    setSearchParam: setBurndownChartType
  } = useSearchParam(burndownChartConfig);
  const [aggregateFilterVal, setAggregateFilterVal] = useState(getParamFromUrl('burndown_aggregate_id') || getCurrentAggregationType());
  const [isEmptyChartData, setIsEmptyChartData] = useState(false);
  const workspaceUtcOffset = useWorkspaceUtcOffset();
  const scrollTargetProps = useScrollTarget('burndown-report-card');
  const isUnstartedIteration = iterationStatus === 'unstarted';
  const handleFilterChange = val => {
    setAggregateFilterVal(val);
  };
  const pageName = getCurrentPage();
  return (
    // This div is used as a scroll target for the chart's scroll into view functionality.
    _jsx("div", {
      ...scrollTargetProps,
      children: _jsx(ChartErrorBoundary, {
        chart: "burndown",
        pageName: pageName,
        chartId: CHART_ID,
        children: _jsx(ReportCard, {
          title: "Burndown Chart",
          className: "burndown-report-header",
          description: "Visualize remaining work and time to complete it.",
          Explanation: Explanation,
          Filters: isEmptyChartData ? _jsx(ReportCardHeaderEmptyState, {
            iterationStartDate: startDate,
            workspaceUtcOffset: workspaceUtcOffset
          }) : _jsxs(_Fragment, {
            children: [usesDetailedBurndown && _jsx(BurndownSelect, {
              onChange: setBurndownChartType,
              value: burndownChartType
            }), _jsx(StoryPointsToggle, {
              checkedValue: aggregateFilterVal,
              onChange: handleFilterChange
            })]
          }),
          chartId: CHART_ID,
          children: _jsx("div", {
            style: {
              minHeight: isEmptyChartData ? CHART_NO_DATA_HEIGHT : BURNDOWN_CHART_HEIGHT
            },
            id: "burndown-container",
            children: burndownChartType === 'detailed' && usesDetailedBurndown ? _jsx(DetailedBurndown, {
              iterationId: iterationId,
              startDate: startDate,
              endDate: endDate,
              aggregateFilterVal: aggregateFilterVal,
              setIsEmptyChartData: setIsEmptyChartData,
              isUnstartedIteration: isUnstartedIteration
            }) : _jsx(DailyBurndown, {
              iterationId: iterationId,
              startDate: startDate,
              endDate: endDate,
              aggregateFilterVal: aggregateFilterVal,
              isUnstartedIteration: isUnstartedIteration,
              setIsEmptyChartData: setIsEmptyChartData
            })
          })
        })
      })
    })
  );
};
BurndownReportCard.displayName = "BurndownReportCard";