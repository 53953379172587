import { useEffect, useState } from 'react';
import { getById } from 'data/entity/storyTemplate';
import { renderAddNewStory, renderAddNewStoryFromTemplate, renderFromContentAddNewStory } from 'utils/addNew';
import { useConsolidatedDataFetched } from 'utils/consolidatedFetch';
import { PAGE_NAMES, matchPathForPage } from 'utils/navigation';
import { getQueryParams } from 'utils/navigation/getQueryParams';
export const OpenAddNewStoryDialogOnce = ({
  pathname,
  search
}) => {
  const [initialLocation] = useState({
    pathname,
    search
  });
  const match = matchPathForPage(initialLocation.pathname, {
    pageName: PAGE_NAMES.STORIES
  });
  const isNew = match?.params?.spaceOrEntityType === 'new';
  const {
    template_id: [templateId],
    name: [name],
    title: [title],
    description: [description]
  } = getQueryParams(['template_id', 'name', 'title', 'description'], initialLocation.search);
  const isFetched = useConsolidatedDataFetched();
  const [hasOpened, setHasOpened] = useState(false);
  useEffect(() => {
    if (hasOpened || !isFetched || !isNew) {
      return;
    }
    const content = {
      name: name || title,
      description
    };
    if (templateId) {
      const template = getById(templateId);
      if (template) {
        renderAddNewStoryFromTemplate(template, content);
      }
    } else if (Object.values(content).some(value => value !== undefined)) {
      renderFromContentAddNewStory(content);
    } else {
      renderAddNewStory();
    }
    setHasOpened(true);
  }, [hasOpened, isFetched, isNew, templateId, name, title, description]);
  return null;
};