import { ICON_DEFAULT_WIDTH } from '../../utils/icon';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const ReportCreatedVsCompleted = ({
  width = ICON_DEFAULT_WIDTH
}) => _jsxs("svg", {
  width: width,
  height: width,
  viewBox: "0 0 46 46",
  fill: "none",
  children: [_jsx("path", {
    d: "M5.13658 35.19H40.8632V19.9333C40.8632 18.63 39.7899 17.5567 38.4866 17.5567H33.5032L26.2199 13.6467H19.6266L14.1066 8.12666H7.12991C6.05658 8.12666 5.13658 9.04666 5.13658 10.12V35.19Z",
    fill: "#C4E3DE"
  }), _jsx("path", {
    d: "M36.9532 25.99L29.2099 20.1633L25.6066 22.08L19.7799 18.7833L15.1032 22.9233L9.58324 17.5567L5.13658 15.1033V34.5H40.8632V25.07L36.9532 25.99Z",
    fill: "#9DD6E9"
  }), _jsx("path", {
    d: "M7.35991 37.8733H38.7166C39.9432 37.8733 40.9399 36.8767 40.9399 35.65V33.1967L38.2566 33.81L25.4532 29.9C25.4532 29.9 20.4699 33.6567 20.0099 33.8867C19.4732 34.1167 15.4866 30.2833 15.4866 30.2833L5.13658 32.2767V35.65C5.13658 36.8767 6.13324 37.8733 7.35991 37.8733Z",
    fill: "#ADD293"
  })]
});
ReportCreatedVsCompleted.displayName = "ReportCreatedVsCompleted";