import { EpicProgressFieldFragmentFragmentDoc } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { useMemo } from 'react';
import { gql } from '@clubhouse/datalayer';
import { Nouns } from '@clubhouse/shared/constants';
import { TableProgress } from 'components/shared/table/base';
import { getProgressStats } from '../../progress';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
EpicProgressFieldFragmentFragmentDoc;
function ProgressFieldComponent({
  entity: {
    stats
  },
  unit
}) {
  const isUsingPoints = unit === 'point';
  const progressStats = useMemo(() => getProgressStats(stats, isUsingPoints), [stats, isUsingPoints]);
  return _jsx(TableProgress, {
    entityType: Nouns.Epic.singular,
    progressStats: progressStats,
    unit: isUsingPoints ? Nouns.Point : Nouns.Story
  });
}
ProgressFieldComponent.displayName = "ProgressFieldComponent";
export const ProgressField = unit => ({
  name: 'progress',
  displayName: 'Progress',
  Component: props => _jsx(ProgressFieldComponent, {
    ...props,
    unit: unit
  }),
  width: 110,
  sort: true
});