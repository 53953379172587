import "core-js/modules/es.array.push.js";
import MilestoneModel from 'app/client/core/js/models/milestone.js';
window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || {
    Controller: {},
    Model: {}
  };
  [[['Model', 'Milestone'], MilestoneModel], [['Model', 'Milestone'], MilestoneModel]].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});
import { sanitizeAndEmojify } from '@clubhouse/shared/utils';
import { getHref } from 'components/gql/objective/links';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const isValid = props => !!props.id && !!props.name;
export function ObjectiveAutoLink(props) {
  return isValid(props) ? _jsx("a", {
    href: getHref(props.id),
    className: "entity-link-with-tooltip entity-title-link",
    "data-model": "Milestone",
    "data-id": props.id,
    children: sanitizeAndEmojify(props.name)
  }) : _jsx("em", {
    children: "Deleted Objective"
  });
}