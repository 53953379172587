import { GROUP_BY } from 'utils/groupBy';
import { mapSearchParamValue } from 'utils/navigation';
import { getAllGroupBy } from '../shared/stories/groupBy';
export const QUERY_KEY = 'query';
export const PROJECT_IDS_KEY = 'project_ids';
export const GROUP_IDS_KEY = 'group_ids';
export const OWNER_IDS_KEY = 'owner_ids';
export const LABEL_IDS_KEY = 'label_ids';
export const WORKFLOW_STATE_IDS_KEY = 'workflow_state_ids';
export const CUSTOM_FIELD_VALUE_IDS_KEY = 'custom_field_value_ids';
export const GROUP_BY_KEY = 'group_by';
export const SORT_BY_KEY = 'sort_by';
export const SORT_DIR_KEY = 'sort_dir';
export const VALID_FILTER_KEYS = [QUERY_KEY, PROJECT_IDS_KEY, GROUP_IDS_KEY, OWNER_IDS_KEY, LABEL_IDS_KEY, WORKFLOW_STATE_IDS_KEY, CUSTOM_FIELD_VALUE_IDS_KEY];
export const URL_KEYS = [QUERY_KEY, PROJECT_IDS_KEY, GROUP_IDS_KEY, OWNER_IDS_KEY, LABEL_IDS_KEY, WORKFLOW_STATE_IDS_KEY, CUSTOM_FIELD_VALUE_IDS_KEY, GROUP_BY_KEY];
/**
 * Note: When _any_ url parameter exist, all app state values will be ignored.
 * This is intended, because it ensures that sharing links always results in the exact same state.
 */
export function createInitialState({
  urlState,
  appState
}) {
  const hasUrlParams = Object.values(urlState).some(arr => arr.length > 0);
  const getInitialValue = (key, type, defaultValue, validValues) => {
    const {
      value: urlValue
    } = mapSearchParamValue(urlState[key] ?? [], type, undefined, validValues);

    // If we have any url parameters set, we should ignore all app state values to avoid breaking bookmarks:
    if (hasUrlParams) return {
      [key]: urlValue ?? defaultValue
    };

    // If we have no url parameters we look for saved app state:
    let appStateValue = appState[key];

    // Some existing app state values are arrays even though we can only have a single value
    if (Array.isArray(appStateValue) && !type.endsWith('[]')) appStateValue = appStateValue[0];
    return {
      [key]: appStateValue ?? defaultValue
    };
  };
  return {
    ...getInitialValue(QUERY_KEY, 'string', ''),
    ...getInitialValue(PROJECT_IDS_KEY, 'number[]', []),
    ...getInitialValue(GROUP_IDS_KEY, 'string[]', []),
    ...getInitialValue(OWNER_IDS_KEY, 'string[]', []),
    ...getInitialValue(LABEL_IDS_KEY, 'number[]', []),
    ...getInitialValue(WORKFLOW_STATE_IDS_KEY, 'number[]', []),
    ...getInitialValue(CUSTOM_FIELD_VALUE_IDS_KEY, 'string[]', []),
    ...getInitialValue(GROUP_BY_KEY, 'string', GROUP_BY.NONE, Object.keys(getAllGroupBy())),
    [SORT_BY_KEY]: appState[SORT_BY_KEY] || 'id',
    [SORT_DIR_KEY]: appState[SORT_DIR_KEY] || 'ascending'
  };
}
export const removeNoOpFilters = appState => {
  return Object.entries(appState).reduce((acc, [key, value]) => {
    if (!value) return acc;
    if (Array.isArray(value) && !value.length) return acc;
    return {
      ...acc,
      [key]: value
    };
  }, {});
};