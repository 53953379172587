import "core-js/modules/es.array.push.js";
import User from 'app/client/core/js/modules/user.js';
import ManageCardController from 'app/client/settingsShared/js/controllers/manageCard.js';
import ManageUsersController from 'app/client/settingsShared/js/controllers/manageUsers.js';
window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || {
    Controller: {},
    Model: {}
  };
  [[['User'], User], [['Controller', 'ManageCard'], ManageCardController], [['Controller', 'ManageUsers'], ManageUsersController], [['User'], User]].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});
import Is from 'app/client/core/js/modules/is';
import OrganizationProfileModel from '../models/organizationProfile';
import PaymentPlan2Model from '../models/paymentPlan2';
import SystemAlertsController from './systemAlerts';
import Url from '../modules/url';
const exports = {};
exports.init = () => {
  const plan = PaymentPlan2Model.getPlanForCurrentOrg();
  if (plan) {
    _updateBanner(plan);
  }
};

// At the moment this is the same as init, but will keep separate to avoid
// triggering additional events and in case we need to extend this logic.
exports.updateBanner = () => {
  PaymentPlan2Model.fetch((err, plan) => {
    if (plan) {
      _updateBanner(plan);
    }
  });
};
function _updateBanner(plan) {
  const notLoadingPage = !!Url.getCurrentPage();
  if (notLoadingPage && PaymentPlan2Model.isDelinquent(plan)) {
    exports._displayDelinquentBanner(PaymentPlan2Model.getDelinquentStatus(plan));
  } else {
    SystemAlertsController.removePersistantBannerOnly();
  }
}

// TODO (toby): remove non-date owner option once all delinquent customers have a date (sc-229318)
function _bannerMessage(is_owner, delinquent_status) {
  const disable_on_date = delinquent_status.disable_on_date;
  if (is_owner) {
    if (disable_on_date) {
      return `We were unable to charge your card. Click here to update your card details to avoid your organization being disabled on ${disable_on_date}.`;
    }
    return 'We were unable to charge your card. Click here to update your card details to avoid your organization being disabled.';
  }
  return 'We were unable to charge the card on file. Please contact your account owner to avoid your organization being disabled.';
}
exports._displayDelinquentBanner = delinquent_status => {
  const profile = OrganizationProfileModel.getOrgProfileForLoggedInUser();
  const canChangeCard = Is.ownerOnly(profile);
  SystemAlertsController.renderBanner({
    severity: 'medium',
    message: _bannerMessage(canChangeCard, delinquent_status),
    onClick: canChangeCard ? 'App.Controller.ManageCard.open' : 'App.Controller.ManageUsers.open'
  }, true);
};
export { exports as default };