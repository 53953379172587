import { DockedKeyResultFragmentDoc } from "../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { SizedIcon } from '@clubhouse/shared/components/SizedIcon';
import { getHref } from 'components/gql/keyResults/links';
import { useKeyResultContextMenu } from 'utils/contextMenus/useKeyResultContextMenu';
import { DockedItemTemplate } from './shared/DockedItemTemplate';
import { Title } from './shared/Title';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const DockedKeyResultFragment = DockedKeyResultFragmentDoc;
export const isKeyResult = node => {
  return node instanceof Object && '__typename' in node && typeof node.__typename === 'string' && node.__typename === 'KeyResult';
};
export function DockedKeyResult({
  node,
  onUnpinClicked,
  index
}) {
  const url = getHref(node.objective.publicId, node.id);
  const contextMenuProps = useKeyResultContextMenu(node.id, node.objective.publicId);
  return _jsx(DockedItemTemplate, {
    id: node.id,
    index: index,
    onUnpinClicked: onUnpinClicked,
    icon: _jsx(SizedIcon, {
      icon: "KeyResult",
      fill: "interactive",
      size: 14
    }),
    title: _jsx(Title, {
      url: url,
      children: node.keyResultName
    }),
    contextMenuProps: contextMenuProps
  });
}
DockedKeyResult.displayName = "DockedKeyResult";