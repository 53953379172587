import * as focusTrap from 'focus-trap';
import View from 'app/client/core/js/modules/view';
import { ArchiveWarning } from 'components/shared/ArchiveWarning';
import PanelController from './panel';
import PanelModel from '../models/panel';
import StoryModel from '../models/story';
import _ from 'lodash';
const exports = {};
const PANEL_ID = 'archive-entity';
function _getPanel() {
  return PanelModel.getById(PANEL_ID);
}
function _open(options) {
  let trap;
  options = options || {};
  PanelController.open({
    id: PANEL_ID,
    center: true,
    css: {
      'z-index': 2001
    },
    html: View.componentToTemplate(ArchiveWarning, 'archive-warning').render(options),
    onOpen: panel => {
      panel.overlay.css({
        'z-index': 2000
      });
      trap = focusTrap.createFocusTrap(panel.element[0], {
        initialFocus: '#no-archive-button'
      });
      trap.activate();
    },
    onApprove: options.onApprove,
    onClose: () => {
      trap?.deactivate();
    },
    target: this,
    width: 500,
    aria: {
      role: 'alertdialog',
      labelledby: 'archive-label',
      describedby: 'archive-description'
    }
  });
  return false;
}
exports.approve = () => {
  const panel = _getPanel();
  const approvalFn = _.get(panel, 'options.onApprove', _.noop);
  approvalFn(panel);
  return exports.close();
};
exports.close = () => {
  const panel = _getPanel();
  if (panel) {
    PanelController.close(panel);
  }
  return false;
};
exports.epic = function (epic, options) {
  return _open.call(this, _.assign({
    type: 'Epic',
    title: epic.name
  }, options));
};
exports.project = function (project, options) {
  return _open.call(this, _.assign({
    type: 'Project',
    title: project.name
  }, options));
};
exports.story = function (story, options) {
  return _open.call(this, _.assign({
    type: 'Story',
    title: StoryModel.getFormattedName(story)
  }, options));
};
exports.label = function (label, options) {
  return _open.call(this, _.assign({
    type: 'Label',
    title: label.formatted_name
  }, options));
};
export { exports as default };