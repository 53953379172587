import "core-js/modules/es.array.push.js";
import { setOnlyIfMissing } from '../globals';
import * as Chameleon from './monitors/chameleon';
import * as Console from './monitors/console';
import * as InternalLog from './monitors/internalLog';
import * as InternalSegment from './monitors/internalSegment';
import * as Pendo from './monitors/pendo';
import * as Pyne from './monitors/pyne';
import * as Segment from './monitors/segment';
import * as Sentry from './monitors/sentry';
import { NOOP_MONITOR, createLogGroup, logInternally } from './utils';
export { processError } from './utils';
const MONITOR_FACTORIES = [Console, InternalLog, Sentry, InternalSegment, Segment, Pendo, Chameleon, Pyne];
const monitors = [];
const monitorsByName = {};

// Do not export, keep this private
const tracesRegistry = new Map();
export const destroyRegistry = () => {
  tracesRegistry.clear();
};
const errorHandler = (err, logRemotely = true) => {
  logInternally(`Failed monitor call`, err);
  if (logRemotely) logError(err);
};
const callSafely = (fn, logRemotely = true) => {
  try {
    return fn();
  } catch (err) {
    errorHandler(err, logRemotely);
  }
};
const isAdblockDetected = () => {
  const w = window;
  return !w?.ga;
};
export const initializeMonitoring = config => {
  const now = Date.now();
  setOnlyIfMissing('firstPageLoadTime', now);
  const group = createLogGroup('Monitor:initialize');
  const errors = [];
  for (const factory of MONITOR_FACTORIES) {
    if (factory.shouldInitialize(config)) {
      try {
        const monitor = factory.initialize(config, {
          onError: error => logError(new Error(`Failed to initialize monitor "${factory.NAME}" with error: ${error?.message || '-'}`, {
            cause: error
          }), {
            ...config,
            adblockDetected: isAdblockDetected(),
            errorInfo: error?.toString?.() || JSON.stringify(error)
          })
        });
        monitors.push(monitor);
        monitorsByName[factory.NAME] = monitor;
        group.log(`✅ ${factory.NAME}`);
      } catch (err) {
        group.log(`📛 ${factory.NAME}`, err);
        if (err instanceof Error) errors.push(err);
      }
    } else {
      group.log(`⛔ ${factory.NAME}`);
    }
  }
  group.end();

  // Try to log errors from monitoring setup
  errors.forEach(error => logError(error));
};
export const destroyMonitoring = () => {
  destroyRegistry();
  while (monitors.length) {
    const monitor = monitors.pop();
    callSafely(() => monitor?.destroy());
  }
  for (const name of Object.keys(monitorsByName)) {
    delete monitorsByName[name];
  }
};
export const setCurrentPage = (currentPage, pattern) => {
  for (const monitor of monitors) {
    callSafely(() => monitor.setCurrentPage(currentPage, pattern));
  }
};
export const startTimedBlock = (namespace, name, startAttrs) => {
  const instances = monitors.map(monitor => {
    const instance = callSafely(() => monitor.startTimedBlock(namespace, name));
    if (instance) return instance;
    return NOOP_MONITOR.startTimedBlock(namespace, name);
  });
  return {
    end: endAttrs => {
      for (const instance of instances) {
        callSafely(() => instance.end({
          ...(startAttrs || {}),
          ...(endAttrs || {})
        }));
      }
    }
  };
};
/** Provided an traceId, a namespace and name, this function will
 * attempt to create a new trace object and store that object in the
 * global trace object registry.
 *
 * Please use startTimedBlock where possible
 * @param traceID
 * @param namespace
 * @param name
 * @deprecated
 */
export const startTraceIfUnstarted = (traceID, namespace, name) => {
  const trace = tracesRegistry.get(traceID);
  if (trace == null) {
    const {
      end
    } = startTimedBlock(namespace, name);
    tracesRegistry.set(traceID, end);

    // TODO (Ivan) destroy trace when this "end" function is called.
    // match the return value of startTimeBlock
    return {
      end,
      newTrace: true
    };
  }
  return {
    end: trace,
    newTrace: false
  };
};

/** Provided a TraceId and ending args, attempts to end the trace with the provided args
 * @param traceID
 * @param data
 * @deprecated
 */
export const endTrace = (traceID, data) => {
  const traceEndCallback = tracesRegistry.get(traceID);
  if (traceEndCallback) {
    traceEndCallback(data);
    tracesRegistry.delete(traceID);
    return true;
  }
  return false;
};
export const setSessionAttributes = keyValues => {
  for (const monitor of monitors) {
    callSafely(() => monitor.setSessionAttributes(keyValues));
  }
};
export const logError = (err, extra, contexts) => {
  for (const monitor of monitors) {
    callSafely(() => monitor.logError(err, extra, contexts), false); // Do not log remotely since that could end up in endless loop.
  }
};
export const logNamedEvent = (eventName, data) => {
  if (!eventName) {
    logError(new Error('No event provided.'), {
      eventName: eventName,
      ...data
    });
    return Promise.resolve();
  }
  const owners = MONITOR_FACTORIES.filter(factory => callSafely(() => factory.shouldOwnNamedEvent(eventName)) || false);
  const promises = owners.length ? owners.map(({
    NAME
  }) => {
    const monitor = monitorsByName[NAME];
    if (monitor) return monitor.logNamedEvent(eventName, data).catch(errorHandler);else return Promise.resolve(); // Monitor was not initialized at start-up.
  }) : monitors.map(monitor => monitor.logNamedEvent(eventName, data).catch(errorHandler));
  return Promise.allSettled(promises).then(() => {});
};
export const logEvent = (event, data) => {
  if (!event) {
    logError(new Error('No event provided.'), {
      event,
      ...data
    });
    return Promise.resolve();
  }
  const owners = MONITOR_FACTORIES.filter(factory => callSafely(() => factory.shouldOwnEvent(event)) || false);
  const promises = owners.length ? owners.map(({
    NAME
  }) => {
    const monitor = monitorsByName[NAME];
    if (monitor) return monitor.logEvent(event, data).catch(errorHandler);else return Promise.resolve(); // Monitor was not initialized at start-up.
  }) : monitors.map(monitor => monitor.logEvent(event, data).catch(errorHandler));
  return Promise.allSettled(promises).then(() => {});
};
export const setTags = tags => {
  for (const monitor of monitors) {
    callSafely(() => monitor.setTags(tags));
  }
};