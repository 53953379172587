import { useScrollTarget } from './shared';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const makeScrollTarget = Component => {
  return function ScrollTarget({
    id,
    ...rest
  }) {
    const props = useScrollTarget(id);
    return _jsx(Component, {
      ...rest,
      ...props
    });
  };
};