import Mousetrap from 'mousetrap';
import { toggleHelpHub } from 'components/shared/command-bar/CommandBar';
import FullscreenController from './fullscreen';
import HotReloadController from './hotReload';
import MessageController from './message';
import StoryController from './story';
import OrganizationModel from '../models/organization';
import UserModel from '../models/user';
import Format from '../modules/format';
import Is from '../modules/is';
import Url from '../modules/url';
import Utils from '../modules/utils';
import _ from 'lodash';
const exports = {};
exports.title = `Shortcuts | ${BRAND.NAME}`;
exports.init = () => {
  // We've already destroyed all previous Mousetrap bindings in Boot.initLibrariesImmediately.

  Mousetrap.bind('enter', _forceClickOnNonLinks, 'keyup');

  // Workspaces
  OrganizationModel.getAllActive().forEach((org, i) => {
    if (i < 9) {
      Mousetrap.bind('g ' + (i + 1), _redirectFn('/' + org.url_slug, {
        prefix: '',
        org
      }));
    }
  });

  // Settings
  Mousetrap.bind('h', toggleHelpHub);

  // Toggle Follower or Fullscreen
  Mousetrap.bind('f', () => {
    // if user has write permissions try and toggle the story follower
    // if user does not have write permissions or there is no story in view then toggle fullscreen
    if (Is.readOnly(UserModel.getLoggedInUserPermission()) || !StoryController.toggleCurrentUserAsFollower()) {
      FullscreenController.toggle();
    }
  });

  // Shortcut `t` => Show Story Templates menu. See `AddNewButton.tsx`.
};
function _forceClickOnNonLinks() {
  const focused = $(document.activeElement);
  const target = focused.closest('[data-on-click]');
  if (!focused.is('a, button') && target.length > 0) {
    target.click();
    return false;
  }
}
function _redirectFn(url, options) {
  options = options || {};
  if (!options.prefix && options.prefix !== '') {
    options.prefix = Url.getSlugPath();
  }
  return e => {
    if (Is.withinOrg() && OrganizationModel.getCurrentID() === _.get(options.org, 'id')) {
      e.stopImmediatePropagation();
      MessageController.alert("You're already within the <strong>" + Format.sanitizeAndEmojify(_.get(options.org, 'name', '')) + '</strong> organization.');
      return false;
    }
    if (options.page && HotReloadController.isReady()) {
      HotReloadController.loadURL(options.prefix + url, options.page);
      return false;
    }
    if (Url.getCurrentPathname() !== url) {
      Utils.redirect(options.prefix + url);
    }
    return false;
  };
}
export { exports as default };