import { useCallback, useRef } from 'react';
import { useOnUnmount } from './useOnUnmount';
import { useToggleState } from './useToggleState';
export function useAutoResetToggle(delayMs = 2000) {
  const [value, toggle] = useToggleState();
  const lastTimeoutId = useRef();
  const resetTimeout = useCallback(() => {
    if (lastTimeoutId.current) {
      clearTimeout(lastTimeoutId.current);
      lastTimeoutId.current = null;
    }
  }, []);
  const enable = useCallback(() => {
    resetTimeout();
    toggle.on();
    lastTimeoutId.current = setTimeout(toggle.off, delayMs);
  }, [delayMs, resetTimeout, toggle]);
  const disable = useCallback(() => {
    resetTimeout();
    toggle.off();
  }, [resetTimeout, toggle]);
  useOnUnmount(resetTimeout);
  return [value, enable, disable];
}