import * as IterationData from 'data/entity/iteration';
import { isNew, setIteration } from 'data/entity/story';
import { updateState } from 'utils/addNew';
import { formatIterationByStory, formatIterationsByStory } from '../../formatters/iteration';
import { getCommandBar } from '../../getCommandBar';
import { isUserReadOnly } from '../../isUserReadOnly';
import { lazilyLoadArgumentChoices } from '../../lazilyLoadArgumentChoices';
export const addContextForStoryIteration = story => {
  lazilyLoadArgumentChoices('currentIterationForStory', () => getCurrentIterationForStory(story));
  lazilyLoadArgumentChoices('iterationsForCurrentStory', () => getIterationsForCurrentStory(story));
  if (!isUserReadOnly()) {
    getCommandBar().addCallback('updateStoryIteration', updateStoryIteration(story));
  }
};
const getCurrentIterationForStory = story => {
  if (!story || !story.iteration_id) return null;
  const iteration = IterationData.getById(story.iteration_id);
  if (!iteration) {
    return null;
  }
  return formatIterationByStory(story.group_id)(iteration);
};
const getIterationsForCurrentStory = story => {
  if (!story) return [];
  const iterations = IterationData.getAll();
  return formatIterationsByStory(story.group_id)(iterations);
};
const updateStoryIteration = story => ({
  iteration
}) => {
  const newIterationId = story.iteration_id !== iteration.id ? iteration.id : null;
  if (isNew(story)) {
    updateState({
      iteration_id: newIterationId
    });
  } else {
    setIteration(story, newIterationId);
  }
};