import "core-js/modules/es.array.push.js";
import _ from 'lodash';
import IterationModel from 'app/client/core/js/models/iteration';
import { deprecatedGetTeamScopeIdForNonReactAccess, getTeamScopedURL, removeTeamScopeFromHref } from 'components/team-navigation';
import ColumnModel from './column';
import EpicModel from './epic';
import FeatureModel from './feature';
import LabelModel from './label';
import ProfileModel from './profile';
import ProjectModel from './project';
import BaseUtils from '../_frontloader/baseUtils';
import ApplicationState from '../modules/applicationState';
import Collection from '../_frontloader/collection';
import * as Event from '../_frontloader/event';
import Format from '../modules/format';
import Iterate from '../modules/iterate';
import Log from '../modules/log';
import Router from '../_frontloader/router';
import StoryLinkModel from './storyLink';
import StoryModel from './story';
import Url from '../modules/url';
import Utils from '../modules/utils';
const exports = {};
Collection.create('Filter', exports);
exports.LOCALSTORAGE_KEY = 'StoriesView.FilterType';
exports.setID = filter => {
  filter.id = Utils.cssify(filter.type + '-' + filter.name);
};
exports.normalize = filter => {
  if (filter.fnName === 'noMilestone') {
    filter.name = 'No Objective';
  }
  filter.added = filter.added || Date.now();
  filter.active = filter.active === false ? false : true;
  filter.archived = false;
  exports.setID(filter);
};
function _updateURL(filterObj) {
  const path = Url.getCurrentPathname();
  const url = path + Url.formatQueryParams(_cleanFiltersForUrl(filterObj));
  const teamId = deprecatedGetTeamScopeIdForNonReactAccess();
  Router.replaceState(getTeamScopedURL(`${window.location.origin}${url}`, teamId).href, document.title);
}
function _cleanFiltersForUrl(filterObj = {}) {
  if (!filterObj.query) {
    delete filterObj.query;
  }
  if (!filterObj.show_archived) {
    delete filterObj.show_archived;
  }
  if (!filterObj.page) {
    delete filterObj.page;
  }
  return filterObj;
}
exports.getFiltersFromURL = () => {
  const url = removeTeamScopeFromHref(window.location.href);
  return _.mapValues(Url.parseLocationSearch(url.search), value => {
    return _.isArray(value) ? _.map(value, BaseUtils.toNumber) : [BaseUtils.toNumber(value)];
  });
};
exports.setFiltersFromUrlAt = storageKey => {
  const filters = exports.getFiltersFromURL();
  return ApplicationState.set(storageKey, filters);
};
function _hasFiltersSet(filters) {
  return !!_.compact(_.values(_.omit(filters, ['group_by']))).length;
}
exports.getCurrentFilters = storageKey => {
  const urlFilters = exports.getFiltersFromURL();
  const filters = _hasFiltersSet(urlFilters) ? urlFilters : ApplicationState.get(storageKey);
  if (!FeatureModel.isProjectsFeatureEnabled()) {
    return toValidFilter(_.omit(filters, ['project_ids', 'project_id']));
  }
  return toValidFilter(filters);
};
const toValidFilter = filters => {
  if (_.isPlainObject(filters)) {
    return filters;
  }
  return {};
};
exports.setCurrentFilters = (storageKey, filter) => {
  filter = _.isPlainObject(filter) ? filter : {};
  _updateURL(filter);
  return ApplicationState.set(storageKey, filter);
};
exports.setCurrentFiltersAt = (storageKey, path, values) => {
  const filter = exports.getCurrentFilters(storageKey);
  if (values === undefined) {
    delete filter[path];
  } else {
    filter[path] = values;
  }
  _updateURL(filter);
  return ApplicationState.set(storageKey, filter);
};
exports.getCurrentFiltersAt = (storageKey, path) => {
  const filters = exports.getCurrentFilters(storageKey);
  return filters[path];
};
exports.toggleFilterValueAt = (storageKey, path, value) => {
  let filteredValues = exports.getCurrentFiltersAt(storageKey, path) || [];
  if (_.includes(filteredValues, value)) {
    filteredValues = _.without(filteredValues, value);
  } else {
    filteredValues.push(value);
  }
  exports.setCurrentFiltersAt(storageKey, path, filteredValues);
};
exports.areAllValuesSelected = (storageKey, path, values) => {
  const selectedValues = exports.getCurrentFiltersAt(storageKey, path);
  return _.difference(values, selectedValues).length === 0;
};
exports.addAllValuesAt = (storageKey, path, newValues) => {
  const currentValues = exports.getCurrentFiltersAt(storageKey, path);
  exports.setCurrentFiltersAt(storageKey, path, _.union(currentValues, newValues));
};
exports.removeAllValuesAt = (storageKey, path, values) => {
  const currentValues = exports.getCurrentFiltersAt(storageKey, path);
  exports.setCurrentFiltersAt(storageKey, path, _.xor(currentValues, values));
};
exports.setFilterType = type => {
  return ApplicationState.set(exports.LOCALSTORAGE_KEY, type);
};
exports.setFiltersForSpace = spaceData => {
  exports.flush();
  Iterate.each(spaceData.Filter, filterData => {
    const filter = exports.translateDataToFilter(filterData);
    if (filter) {
      exports.add(filter);
    }
  });
};
exports.translateDataToFilter = data => {
  const filter = {
    active: data.active !== false,
    added: exports.size() + 1,
    icon: data.icon,
    inverse: data.inverse,
    name: data.name,
    type: data.type,
    fnName: data.fnName,
    value: data.value
  };
  exports.setID(filter);
  if (filter.fnName && !_.isFunction(exports.StoryFilters[filter.fnName])) {
    return false;
  }
  const fn = exports.translateDataToFilterFn(filter);
  if (fn) {
    filter.fn = fn;
  }
  return filter;
};
exports.translateDataToFilterFn = filter => {
  if (filter.fnName && _.isFunction(exports.StoryFilters[filter.fnName])) {
    return exports.StoryFilters[filter.fnName](filter);
  }
  if (filter.type === 'user') {
    return story => {
      let user = null;
      Iterate.each(['name', 'email_address', 'mention_name'], prop => {
        const query = {};
        if (!user) {
          query[prop] = filter.name;
          user = ProfileModel.findBy(query);
        }
      });
      return StoryModel.userIsRelated(story, user);
    };
  }
  if (filter.type === 'epic') {
    return story => {
      return story.epic_id === filter.value;
    };
  }
  if (filter.type === 'tag') {
    return story => {
      return LabelModel.hasLabel(story, filter.name) || StoryModel.hasImpliedLabel(story, filter.name);
    };
  }
  if (filter.type === 'search') {
    const terms = Utils.tokenizeQuery(filter.name);
    return exports.searchStories(terms);
  }
};
exports.translateFilterToData = filter => {
  return {
    active: filter.active,
    inverse: filter.inverse,
    fnName: filter.fnName,
    icon: filter.icon,
    name: filter.name,
    type: filter.type,
    value: _.isPlainObject(filter.value) && filter.value.id ? filter.value.id : filter.value
  };
};
const stringContainsTerm = (str, term) => (str || '').toLowerCase().indexOf(term) !== -1;
exports.searchStories = terms => {
  return story => {
    if (terms.length === 0) {
      return true;
    }
    let allMatch = true;
    Iterate.each(terms, term => {
      let match = false;
      term = term.toLowerCase();

      // Search by ID
      if (story.id === Number.parseInt(term, 10) || '#' + story.id === term || 'ch' + story.id === term) {
        match = true;
      }

      // Fuzzy property search
      if (term.indexOf(':') !== -1) {
        if (term.indexOf('!') === 0) {
          if (!exports.fuzzySearchStory(story, term.substr(1))) {
            match = true;
          }
        } else if (exports.fuzzySearchStory(story, term)) {
          match = true;
        }
      }

      // Title search
      if (stringContainsTerm(story.name, term)) {
        match = true;
      }

      // Tag search
      if (exports.fuzzyLabelMatch(story, term)) {
        match = true;
      }

      // Search comments
      Iterate.each(story.comments, comment => {
        if (stringContainsTerm(comment.text, term)) {
          match = true;
        }
      });
      if (!match) {
        allMatch = false;
      }
    });
    return allMatch;
  };
};
exports.fuzzySearchStory = (story, term) => {
  if (term.indexOf('has:attachment') === 0) {
    return story.files && story.files.length > 0 || story.file_ids && story.file_ids.length > 0;
  }
  if (term.indexOf('has:comment') === 0) {
    return story.comments && story.comments.length > 0 || story.comment_ids && story.comment_ids.length > 0;
  }
  if (term === 'has:task') {
    return story.tasks && story.tasks.length > 0 || story.task_ids && story.task_ids.length > 0;
  }
  if (term === 'has:deadline') {
    return !!story.deadline;
  }
  if (term === 'has:epic') {
    return !!story.epic_id;
  }
  if (term === 'has:owner') {
    return story.owners && story.owners.length > 0;
  }
  if (term === 'is:overdue') {
    return story.is_overdue;
  }
  if (term === 'is:archived') {
    return story.archived;
  }
  if (term === 'is:blocked') {
    return StoryLinkModel.hasBlocker(story) || LabelModel.hasLabel(story, 'blocked');
  }
  if (term === 'is:blocker') {
    return StoryLinkModel.isBlocker(story) || LabelModel.hasLabel(story, 'blocker');
  }
  if (term === 'is:unestimated') {
    return story.estimate === null;
  }
  if (term === 'is:unstarted') {
    return StoryModel.isUnstartedState(story);
  }
  if (term === 'is:started') {
    return StoryModel.isActiveState(story);
  }
  if (term === 'is:done') {
    return StoryModel.isDoneState(story);
  }
  term = term.split(':');
  const prop = term[0];
  const str = Utils.stripQuotes(term[1]);
  if (!prop || !str) {
    return false;
  }
  if (prop === 'estimate') {
    return story.estimate === Number.parseInt(str, 10);
  }
  if (prop === 'id') {
    return story.id === Number.parseInt(str, 10);
  }
  if (prop === 'epic') {
    const epics = EpicModel.fuzzyMatch(str);
    let epicMatch = false;
    Iterate.each(epics, epic => {
      if (StoryModel.hasEpic(story, epic)) {
        epicMatch = true;
      }
    });
    return epicMatch;
  }
  if (prop === 'project') {
    const projects = ProjectModel.fuzzyMatch(str);
    let projectMatch = false;
    Iterate.each(projects, project => {
      if (project.id === story.project_id) {
        projectMatch = true;
      }
    });
    return projectMatch;
  }
  if (prop === 'type') {
    return story.story_type.toLowerCase().indexOf(str) === 0;
  }
  if (prop === 'state') {
    const workflowState = ColumnModel.getById(story.workflow_state_id) || {};
    const workflowStateName = workflowState.name || '';
    return workflowStateName.toLowerCase().indexOf(str) !== -1 || Number.parseInt(str, 10) === workflowState.id;
  }
  if (prop === 'tag' || prop === 'label') {
    return exports.fuzzyLabelMatch(story, str);
  }
  let users;
  let userMatch = false;
  if (prop.indexOf('own') === 0) {
    users = ProfileModel.fuzzyMatch(str);
    Iterate.each(users, user => {
      if (StoryModel.isOwner(story, user)) {
        userMatch = true;
      }
    });
    return userMatch;
  }
  if (prop.indexOf('request') === 0) {
    users = ProfileModel.fuzzyMatch(str);
    Iterate.each(users, user => {
      if (StoryModel.isRequester(story, user)) {
        userMatch = true;
      }
    });
    return userMatch;
  }
  if (prop === 'user') {
    users = ProfileModel.fuzzyMatch(str);
    Iterate.each(users, user => {
      if (StoryModel.userIsRelated(story, user)) {
        userMatch = true;
      }
    });
    return userMatch;
  }
  return false;
};
exports.fuzzyLabelMatch = (story, str) => {
  return _.some(story.labels, label => {
    return (label.name || '').toLowerCase().indexOf(str) === 0;
  });
};

// ## Programming note
//
// We're separating Fixtures from StoryFilters for two reasons:
// 1. more compact data can be stored in localStorage, vs. the entire filter object.
// 2. This is more resilient to model changes. Previously model changes would require
//    a client-side migration, and we want to use client-side migrations sparingly.

exports.StoryFilters = {};
exports.StoryFilters.ownedByMe = () => {
  return story => {
    const profile = ProfileModel.getCurrentUserProfileDetails();
    return StoryModel.isOwner(story, profile);
  };
};
exports.StoryFilters.requestedByMe = () => {
  return story => {
    const profile = ProfileModel.getCurrentUserProfileDetails();
    return StoryModel.isRequester(story, profile);
  };
};
exports.StoryFilters.followedByMe = () => {
  return story => {
    const profile = ProfileModel.getCurrentUserProfileDetails();
    return StoryModel.isFollower(story, profile);
  };
};

/*
exports.StoryFilters.mentionsMe = function () {
  return function (story) {
    var mentioned = false;
    var username = (ProfileModel.getCurrentUserProfileDetails() || {}).mention_name;
    if (!username) {
      return mentioned;
    }

    var mention = '@' + username.toLowerCase();
    Iterate.each(story.comments, function (comment) {
      if (comment.text.toLowerCase().indexOf(mention) !== -1) {
        mentioned = true;
        return false;
      }
    });

    return mentioned;
  };
};
*/

exports.StoryFilters.noOwner = () => {
  return story => {
    return !story.owners || story.owners.length === 0;
  };
};
exports.StoryFilters.hasAttachments = () => {
  return story => {
    return _.isArray(story.file_ids) && story.file_ids.length > 0 || _.isArray(story.files) && story.files.length > 0;
  };
};
exports.StoryFilters.hasComments = () => {
  return story => {
    return _.isArray(story.comment_ids) && story.comment_ids.length > 0 || _.isArray(story.comments) && story.comments.length > 0;
  };
};
exports.StoryFilters.hasExternalLinks = () => {
  return story => {
    return Array.isArray(story.external_links) && story.external_links.length > 0;
  };
};
exports.StoryFilters.hasTasks = () => {
  return story => {
    return _.isArray(story.task_ids) && story.task_ids.length > 0 || _.isArray(story.tasks) && story.tasks.length > 0;
  };
};
exports.StoryFilters.isBlocked = () => {
  return story => {
    return StoryLinkModel.hasBlocker(story) || LabelModel.hasLabel(story, 'blocked');
  };
};
exports.StoryFilters.isBlocker = () => {
  return story => {
    return StoryLinkModel.isBlocker(story) || LabelModel.hasLabel(story, 'blocker');
  };
};
exports.StoryFilters.hasDeadline = () => {
  return story => {
    return !!story.deadline;
  };
};
exports.StoryFilters.overdue = () => {
  return story => {
    return !!story.is_overdue;
  };
};
exports.StoryFilters.noEpic = () => {
  return story => {
    return !story.epic_id;
  };
};
exports.StoryFilters.unlabeled = () => {
  return story => {
    return !story.labels || story.labels.length === 0;
  };
};
exports.StoryFilters.updatedToday = () => {
  return story => {
    const oneDay = 1000 * 60 * 60 * 24;
    const updated = new Date(story.updated_at).getTime();
    return updated > new Date().getTime() - oneDay;
  };
};
exports.StoryFilters.updatedInTheLastWeek = () => {
  return story => {
    const oneWeek = 1000 * 60 * 60 * 24 * 7;
    const updated = new Date(story.updated_at).getTime();
    return updated > new Date().getTime() - oneWeek;
  };
};
exports.StoryFilters.createdToday = () => {
  return story => {
    const oneDay = 1000 * 60 * 60 * 24;
    const created = new Date(story.created_at).getTime();
    return created > new Date().getTime() - oneDay;
  };
};
exports.StoryFilters.createdInTheLastWeek = () => {
  return story => {
    const oneWeek = 1000 * 60 * 60 * 24 * 7;
    const created = new Date(story.created_at).getTime();
    return created > new Date().getTime() - oneWeek;
  };
};
exports.StoryFilters.stale = () => {
  return story => {
    return StoryModel.isStale(story);
  };
};
exports.StoryFilters.hasStoryType = filter => {
  return story => {
    return story.story_type === filter.value;
  };
};
exports.StoryFilters.hasEstimate = filter => {
  return story => {
    return story.estimate === filter.value;
  };
};
exports.StoryFilters.isUnestimated = () => {
  return story => {
    return story.estimate === null;
  };
};
exports.StoryFilters.ownedBy = filter => {
  return story => {
    return StoryModel.isOwner(story, {
      id: filter.value
    });
  };
};
exports.StoryFilters.requestedBy = filter => {
  return story => {
    return StoryModel.isRequester(story, {
      id: filter.value
    });
  };
};
exports.StoryFilters.inMilestone = filter => {
  return story => {
    return StoryModel.isInMilestone(story, {
      id: filter.value
    });
  };
};
exports.StoryFilters.noMilestone = () => {
  return story => {
    return StoryModel.noMilestone(story);
  };
};
exports.StoryFilters.inUnstartedEpic = () => {
  return story => {
    return EpicModel.isToDo(story.epic);
  };
};
exports.StoryFilters.inStartedEpic = () => {
  return story => {
    return EpicModel.isInProgress(story.epic);
  };
};
exports.StoryFilters.inFinishedEpic = () => {
  return story => {
    return EpicModel.isDone(story.epic);
  };
};
exports.StoryFilters.inUnstartedIteration = () => {
  return story => {
    return story.iteration ? story.iteration.status === 'unstarted' : false;
  };
};
exports.StoryFilters.inStartedIteration = () => {
  return story => {
    return story.iteration ? story.iteration.status === 'started' : false;
  };
};
exports.StoryFilters.inDoneIteration = () => {
  return story => {
    return story.iteration ? story.iteration.status === 'done' : false;
  };
};
exports.StoryFilters.inIterationWithGroup = filter => {
  return story => {
    return story.iteration ? IterationModel.isAssignedToGroup(story.iteration, filter.value) : false;
  };
};
exports.StoryFilters.isBug = () => {
  return StoryModel.isBug;
};
exports.Fixtures = {};
exports.Fixtures.inUnstartedEpic = () => {
  return exports.translateDataToFilter({
    name: 'To Do Epic',
    type: 'epic',
    fnName: 'inUnstartedEpic'
  });
};
exports.Fixtures.inStartedEpic = () => {
  return exports.translateDataToFilter({
    name: 'In Progress Epic',
    type: 'epic',
    fnName: 'inStartedEpic'
  });
};
exports.Fixtures.inFinishedEpic = () => {
  return exports.translateDataToFilter({
    name: 'Done Epic',
    type: 'epic',
    fnName: 'inFinishedEpic'
  });
};
exports.Fixtures.ownedByMe = () => {
  return exports.translateDataToFilter({
    name: 'Owned by me',
    type: 'user',
    fnName: 'ownedByMe'
  });
};
exports.Fixtures.requestedByMe = () => {
  return exports.translateDataToFilter({
    name: 'Requested by me',
    type: 'user',
    fnName: 'requestedByMe'
  });
};
exports.Fixtures.followedByMe = () => {
  return exports.translateDataToFilter({
    name: 'Followed by me',
    type: 'user',
    fnName: 'followedByMe'
  });
};
exports.Fixtures.mentionsMe = () => {
  return exports.translateDataToFilter({
    name: 'Mentions me',
    type: 'user',
    fnName: 'mentionsMe'
  });
};
exports.Fixtures.noOwner = () => {
  return exports.translateDataToFilter({
    name: 'No owner',
    type: 'user',
    fnName: 'noOwner'
  });
};
exports.Fixtures.hasAttachments = () => {
  return exports.translateDataToFilter({
    name: 'Has attachments',
    type: 'file',
    fnName: 'hasAttachments'
  });
};
exports.Fixtures.hasComments = () => {
  return exports.translateDataToFilter({
    name: 'Has comments',
    type: 'comment',
    fnName: 'hasComments'
  });
};
exports.Fixtures.hasExternalLinks = () => {
  return exports.translateDataToFilter({
    name: 'Has External Links',
    type: 'external-link',
    icon: 'fa-link',
    fnName: 'hasExternalLinks'
  });
};
exports.Fixtures.hasTasks = () => {
  return exports.translateDataToFilter({
    name: 'Has tasks',
    type: 'task',
    fnName: 'hasTasks'
  });
};
exports.Fixtures.isBlocked = () => {
  return exports.translateDataToFilter({
    name: 'Is blocked',
    type: 'link',
    fnName: 'isBlocked'
  });
};
exports.Fixtures.isBlocker = () => {
  return exports.translateDataToFilter({
    name: 'Is blocker',
    type: 'link',
    fnName: 'isBlocker'
  });
};
exports.Fixtures.hasDeadline = () => {
  return exports.translateDataToFilter({
    name: 'Has Due Date',
    type: 'calendar',
    fnName: 'hasDeadline'
  });
};
exports.Fixtures.overdue = () => {
  return exports.translateDataToFilter({
    name: 'Overdue',
    type: 'calendar',
    fnName: 'overdue'
  });
};
exports.Fixtures.noEpic = () => {
  return exports.translateDataToFilter({
    name: 'Not in an epic',
    type: 'epic',
    fnName: 'noEpic'
  });
};
exports.Fixtures.updatedToday = () => {
  return exports.translateDataToFilter({
    name: 'Updated today',
    type: 'time',
    fnName: 'updatedToday'
  });
};
exports.Fixtures.updatedInTheLastWeek = () => {
  return exports.translateDataToFilter({
    name: 'Updated in last week',
    type: 'time',
    fnName: 'updatedInTheLastWeek'
  });
};
exports.Fixtures.createdToday = () => {
  return exports.translateDataToFilter({
    name: 'Created today',
    type: 'time',
    fnName: 'createdToday'
  });
};
exports.Fixtures.createdInTheLastWeek = () => {
  return exports.translateDataToFilter({
    name: 'Created in last week',
    type: 'time',
    fnName: 'createdInTheLastWeek'
  });
};
exports.Fixtures.stale = () => {
  return exports.translateDataToFilter({
    name: 'Stale',
    type: 'time',
    fnName: 'stale'
  });
};
exports.Fixtures.unlabeled = () => {
  return exports.translateDataToFilter({
    name: 'Unlabeled',
    type: 'tag',
    fnName: 'unlabeled'
  });
};
exports.Fixtures.features = () => {
  return exports.translateDataToFilter({
    name: 'Features',
    type: 'type',
    icon: 'fa-certificate',
    fnName: 'hasStoryType',
    value: 'feature'
  });
};
exports.Fixtures.bugs = () => {
  return exports.translateDataToFilter({
    name: 'Bugs',
    type: 'type',
    icon: 'fa-bug',
    fnName: 'hasStoryType',
    value: 'bug'
  });
};
exports.Fixtures.chores = () => {
  return exports.translateDataToFilter({
    name: 'Chores',
    type: 'type',
    icon: 'fa-wrench',
    fnName: 'hasStoryType',
    value: 'chore'
  });
};
exports.Fixtures.isUnestimated = () => {
  return exports.translateDataToFilter({
    name: 'Unestimated',
    type: 'estimate',
    fnName: 'isUnestimated'
  });
};
exports.Fixtures.zeroPoints = () => {
  return exports.translateDataToFilter({
    name: '0 points',
    type: 'estimate',
    fnName: 'hasEstimate',
    value: 0
  });
};
exports.Fixtures.onePoint = () => {
  return exports.translateDataToFilter({
    name: '1 point',
    type: 'estimate',
    fnName: 'hasEstimate',
    value: 1
  });
};
exports.Fixtures.twoPoints = () => {
  return exports.translateDataToFilter({
    name: '2 points',
    type: 'estimate',
    fnName: 'hasEstimate',
    value: 2
  });
};
exports.Fixtures.fourPoints = () => {
  return exports.translateDataToFilter({
    name: '4 points',
    type: 'estimate',
    fnName: 'hasEstimate',
    value: 4
  });
};
exports.Fixtures.hasEstimate = value => {
  return exports.translateDataToFilter({
    name: Format.pluralize(value, 'point', 'points'),
    type: 'estimate',
    fnName: 'hasEstimate',
    value
  });
};
exports.Fixtures.byLabel = label => {
  return exports.translateDataToFilter({
    name: label.name,
    type: 'tag',
    value: label.id
  });
};
exports.Fixtures.byEpic = epic => {
  return exports.translateDataToFilter({
    name: epic.name,
    type: 'epic',
    value: epic.id
  });
};
exports.Fixtures.ownedBy = user => {
  return exports.translateDataToFilter({
    fnName: 'ownedBy',
    name: 'Owned by ' + user.name,
    type: 'user',
    value: user.id
  });
};
exports.Fixtures.requestedBy = user => {
  return exports.translateDataToFilter({
    fnName: 'requestedBy',
    name: 'Requested by ' + user.name,
    type: 'user',
    value: user.id
  });
};
exports.Fixtures.inMilestone = milestone => {
  return exports.translateDataToFilter({
    fnName: 'inMilestone',
    name: milestone.name,
    type: 'milestone',
    value: milestone.id
  });
};
exports.Fixtures.noMilestone = () => {
  return exports.translateDataToFilter({
    name: 'No Objective',
    type: 'milestone',
    fnName: 'noMilestone'
  });
};
exports.Fixtures.inIterationWithGroup = group => {
  return exports.translateDataToFilter({
    fnName: 'inIterationWithGroup',
    name: 'Iterations in ' + group.name,
    type: 'iterationGroup',
    value: group.id
  });
};
exports.Fixtures.inUnstartedIteration = () => {
  return exports.translateDataToFilter({
    fnName: 'inUnstartedIteration',
    name: 'Unstarted Iterations',
    type: 'iterationUnstarted'
  });
};
exports.Fixtures.inStartedIteration = () => {
  return exports.translateDataToFilter({
    fnName: 'inStartedIteration',
    name: 'Started Iterations',
    type: 'iterationStarted'
  });
};
exports.Fixtures.inDoneIteration = () => {
  return exports.translateDataToFilter({
    fnName: 'inDoneIteration',
    name: 'Done Iterations',
    type: 'iterationDone'
  });
};
exports.Fixtures.search = query => {
  return exports.translateDataToFilter({
    name: query,
    type: 'search'
  });
};
exports.addFixture = (name, model, options) => {
  options = options || {
    quiet: false
  };
  const fixture = exports.Fixtures[name];
  if (_.isFunction(fixture)) {
    const filter = fixture(model);
    const existing = exports.get({
      type: filter.type,
      name: filter.name
    });
    if (existing) {
      existing.active = true;
      exports.update(existing);
    } else {
      exports.update(filter);
    }
    if (!options.quiet) {
      Event.trigger('spaceUpdate');
      Event.trigger('filterAdded');
    }
  } else {
    Log.log('Filter fixture "' + name + '" not found!');
  }
};
exports.getCachedFilterType = BaseUtils.temporaryMemoize(() => {
  return exports.getFilterType();
});
const DEFAULT_FILTER_TYPE = 'OR';
exports.getFilterType = () => {
  return ApplicationState.get(exports.LOCALSTORAGE_KEY) || DEFAULT_FILTER_TYPE;
};
exports.setUserFixtureType = type => {
  ApplicationState.set('Stories.UserFixtureType', type);
};
exports.getUserFixtureType = () => {
  return ApplicationState.get('Stories.UserFixtureType') || 'ownedBy';
};
export { exports as default };