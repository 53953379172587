export const calcAverage = (arr = []) => {
  if (arr.length === 0) {
    return 0;
  }
  return arr.reduce((a, b) => a + b) / arr.length;
};
export const calcStandardDeviation = (arr = []) => {
  const avg = calcAverage(arr);
  const diffToAvgSquared = arr.map(val => Math.pow(val - avg, 2));
  const avgDiff = calcAverage(diffToAvgSquared);
  return Math.sqrt(avgDiff);
};

// NOTE: The following is adapted from
// https://stackoverflow.com/a/45309555
export const _quantile = (arr = [], q) => {
  if (!arr.length) return 0;
  const sortedArr = arr.slice().sort((a, b) => a - b);
  const pos = Math.floor(sortedArr.length * q);
  if (sortedArr.length % 2 || pos === 0) {
    return sortedArr[pos];
  } else {
    return (sortedArr[pos - 1] + sortedArr[pos]) / 2;
  }
};
export const q25 = arr => _quantile(arr, 0.25);
export const q50 = arr => _quantile(arr, 0.5);
export const q75 = arr => _quantile(arr, 0.75);
export const median = arr => q50(arr);
export const toLogScale = value => {
  // Adding a 1 here as without it, values between 0 and 1 end up negative in log scale(!)
  return Math.log(value + 1) / Math.LN10;
};
export const fromLogScale = value => {
  // Subtracting a 1 here as without it, values between 0 and 1 end up negative in log scale(!)
  return Math.pow(10, value) - 1;
};
export const isNumber = n => typeof n === 'number';
export const limitMinMax = (n, min, max) => Math.min(Math.max(n, min), max);