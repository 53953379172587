import ApplicationState from '../modules/applicationState';
import View from '../modules/view';
import _ from 'lodash';
const exports = {};
const CURRENT_SEARCH_KEY = 'CurrentSearch';
const RECENT_SEARCHES_KEY = 'RecentSearches';
const RECENT_SEARCHES_MAX_LENGTH = 5;
exports.getRecentSearches = () => {
  return ApplicationState.get(RECENT_SEARCHES_KEY) || [];
};
exports.getNonEmptyRecentSearches = () => {
  return exports.getRecentSearches().filter(query => query.length > 0);
};
exports.addToRecentSearches = query => {
  const searches = exports.getRecentSearches();
  searches.unshift(query);
  ApplicationState.set(RECENT_SEARCHES_KEY, _.take(_.uniq(searches), RECENT_SEARCHES_MAX_LENGTH));
};
exports.setAsCurrentSearch = query => {
  ApplicationState.set(CURRENT_SEARCH_KEY, query);
};
exports.getCurrentSearch = () => {
  return ApplicationState.get(CURRENT_SEARCH_KEY);
};
exports.removeRecentSearch = query => {
  const searches = exports.getRecentSearches();
  _.remove(searches, search => {
    return search.trim() === query.trim();
  });
  ApplicationState.set(RECENT_SEARCHES_KEY, searches);
  if (searches.length === 0) {
    typeof exports.open === 'function' && exports.open();
  }
};
exports.deleteRecentSearch = function () {
  const parent = $(this).closest('.saved-search');
  const query = parent.text().trim();
  exports.removeRecentSearch(query);
  View.fadeAndSlideUp(parent);
  return false;
};
export { exports as default };