import uniq from 'lodash/uniq';
import { useCallback } from 'react';
import { ToastText, useToast } from '@clubhouse/shared/components/Toast';
import { useApplicationStateByKey, useUpdateApplicationState } from 'gql';
import { logAddItem, logRemoveItem } from '../utils/logging';
import { applicationStateKey, createTeamNavigationState, getValidExpandedTeamIds, maxNumberOfTeams } from './teamNavigationState';
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const useTeamNavigationState = () => {
  const {
    data,
    loading,
    error,
    refetch
  } = useApplicationStateByKey({
    applicationStateKey,
    toExpectedType: createTeamNavigationState,
    fetchPolicy: 'cache-and-network'
  });
  const {
    update
  } = useUpdateApplicationState({
    applicationStateKey,
    toExpectedType: createTeamNavigationState
  });
  const {
    addToast
  } = useToast();
  const setTeamIds = useCallback((ids, {
    shouldLog
  } = {}) => {
    const updatedIds = uniq(ids);
    if (updatedIds.length > maxNumberOfTeams) {
      addToast({
        kind: 'alert',
        Content: () => _jsxs(ToastText, {
          children: ["The number of teams in the navigation is limited to ", maxNumberOfTeams, "."]
        }),
        timeout: 5000
      });
      return;
    }
    update(existingData => {
      if (shouldLog) {
        ids.concat(existingData.selectedTeamIds).forEach(id => {
          if (!existingData.selectedTeamIds.includes(id)) {
            logAddItem({
              teamId: id,
              totalTeams: ids.length
            });
          } else if (!ids.includes(id)) {
            logRemoveItem({
              teamId: id,
              totalTeams: ids.length
            });
          }
        });
      }
      return {
        ...existingData,
        selectedTeamIds: ids
      };
    });
  }, [addToast, update]);
  const setExpandedTeamIds = useCallback((ids, selectedTeamIds) => {
    update(existingData => {
      return {
        ...existingData,
        expandedTeamIds: getValidExpandedTeamIds({
          expandedTeamIds: ids,
          selectedTeamIds
        })
      };
    });
  }, [update]);
  const setTeamNavigationState = useCallback(teamNavigationState => {
    return update(() => teamNavigationState);
  }, [update]);
  return {
    data,
    loading,
    setTeamIds,
    setExpandedTeamIds,
    setTeamNavigationState,
    error,
    refetch
  };
};