import { Button } from '../shared/DeprecatedButton';
import { ThirdPartyIcon } from '@useshortcut/shapes-ds';
import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons';
import { jsx as _jsx, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const onClickDefault = () => {};
export const SigninWithGitHub = ({
  href,
  text = 'Sign in with GitHub',
  onClick = onClickDefault
}) => {
  return _jsx(_Fragment, {
    children: _jsx(Button, {
      className: "github-sign-in",
      text: text,
      href: href,
      Icon: () => _jsx(DeprecatedIconAdapter, {
        width: 28,
        children: _jsx(ThirdPartyIcon, {
          icon: "Github"
        })
      }),
      onClick: onClick
    })
  });
};