import { useWorkspaceFeature } from 'gql/queries/useWorkspaceFeatures';
import { BreadcrumbsV2 } from './BreadcrumbsV2';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export function EpicBreadcrumbs({
  onSelection,
  onOpenDropdown,
  onCloseDropdown,
  objectives,
  epic,
  story
}) {
  const usesObjectives = useWorkspaceFeature('objectives');
  if (!usesObjectives) return _jsx(BreadcrumbsV2.EpicsAndStoriesOnly, {
    epic: epic,
    story: story,
    onSelection: onSelection
  });
  return _jsx(BreadcrumbsV2, {
    objectives: objectives,
    epic: epic,
    story: story,
    onSelection: onSelection,
    onOpenDropdown: onOpenDropdown,
    onCloseDropdown: onCloseDropdown
  });
}
EpicBreadcrumbs.displayName = "EpicBreadcrumbs";