import { EpicTotalStoriesFieldFragmentFragmentDoc } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { Center } from '@clubhouse/shared/components/Center';
import { SkeletonRectangle } from '@clubhouse/shared/components/SkeletonRectangle';
import { TableText } from '@clubhouse/shared/components/Table';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const EpicTotalStoriesFieldFragment = EpicTotalStoriesFieldFragmentFragmentDoc;
const TotalStoriesFieldComponent = ({
  entity
}) => {
  return _jsx(TableText, {
    text: entity.stats.numStoriesTotal
  });
};
TotalStoriesFieldComponent.displayName = "TotalStoriesFieldComponent";
export const TotalStoriesField = () => ({
  name: 'numStoriesTotal',
  displayName: 'Stories',
  headerProps: {
    centered: true
  },
  Component: TotalStoriesFieldComponent,
  LoadingComponent: () => _jsx(Center, {
    children: _jsx(SkeletonRectangle, {
      width: 20
    })
  }),
  width: 80,
  sort: 'stats_numStoriesTotal'
});