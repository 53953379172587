import { size } from '@floating-ui/react';
export const sameWidth = () => {
  return size({
    apply({
      elements,
      rects
    }) {
      Object.assign(elements.floating.style, {
        width: `${rects.reference.width}px`
      });
    }
  });
};