import { useMemo } from 'react';
import { useSubscription } from 'use-subscription';
import Globals from 'app/client/core/js/_frontloader/globals';
import { error as logError } from './log';
export const addChangeListener = (...args) => Globals.addChangeListener(...args);
export const removeChangeListener = (...args) => Globals.removeChangeListener(...args);
export const setGlobal = Globals.set;
export const getGlobal = Globals.get;
export const setOnlyIfMissing = Globals.setOnlyIfMissing;
export const useGlobal = (key, getCurrentValue = getGlobal) => {
  if (!Globals.GLOBAL_EVENT_ALLOWLIST.includes(key)) {
    logError(new Error(`${key} must be defined in GLOBAL_EVENT_ALLOWLIST in order to listen to it.`));
  }
  const subscription = useMemo(() => ({
    getCurrentValue: () => getCurrentValue(key),
    subscribe: callback => {
      Globals.addChangeListener(key, callback);
      return () => Globals.removeChangeListener(key, callback);
    }
  }), [getCurrentValue, key]);
  return useSubscription(subscription);
};