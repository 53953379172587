import { ImportIcon } from 'components/shared/ImportIcon';
import View from '../modules/view';
const renderIcon = ({
  id
}) => {
  return View.renderComponentDelayed({
    cssClass: 'imported-user',
    componentKey: id,
    component: ImportIcon
  }).html;
};
export default {
  renderIcon
};