import "core-js/modules/es.array.push.js";
import HotReloadController from 'app/client/core/js/controllers/hotReload.js';
window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || {
    Controller: {},
    Model: {}
  };
  [[['Controller', 'HotReload'], HotReloadController], [['Controller', 'HotReload'], HotReloadController]].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});
import { useCallback } from 'react';
import { ContextMenu, ContextMenuDivider, ContextMenuItem, ContextMenuLoading } from '@clubhouse/shared/components/ContextMenu';
import { Spaced } from '@clubhouse/shared/components/Spaced';
import { useTableGroup } from '@clubhouse/shared/components/Table/TableGroup';
import { ToastText, ToastTitle, addToast, useToast } from '@clubhouse/shared/components/Toast';
import { Nouns } from '@clubhouse/shared/constants';
import { getHref } from 'components/gql/stories/links';
import { CollectionizeStoryAutoLink } from 'components/shared/CollectionizeStoryAutoLink/CollectionizeStoryAutoLink';
import { generateURL, getCurrentIterationForGroup, getNextIterationForGroup } from 'data/entity/iteration';
import { getBranchName, isOwner, saveChanges, setIteration, toggleOwner, useStoryWithFetch } from 'data/entity/story';
import { getLoggedInUserPermission, isLoggedInUserObserver } from 'data/entity/user';
import { sanitize } from '../format';
import { success } from '../message';
import { CopyContextMenuItem, OpenInNewTabContextMenuItem, PinContextMenuItem } from './actions';
import { useContextMenuWithLogging, useContextMenuWithLoggingOptionalEvents } from './useContextMenuWithLogging';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const ToggleOwnerContextMenuItem = ({
  story
}) => {
  const currentUser = getLoggedInUserPermission();
  const willRemove = isOwner(story, currentUser);
  const {
    addToast
  } = useToast();
  return _jsx(ContextMenuItem, {
    isDisabled: isLoggedInUserObserver(),
    icon: willRemove ? 'UserRemove' : 'UserAdd',
    label: `${willRemove ? 'Remove' : 'Add'} me as an Owner`,
    onClick: () => {
      toggleOwner(story, currentUser);
      addToast({
        Content: () => _jsx(ToastText, {
          children: willRemove ? _jsxs(_Fragment, {
            children: ["You have been removed as an owner from", ' ', _jsx(CollectionizeStoryAutoLink, {
              id: story.id,
              name: story.name,
              type: story.story_type
            })]
          }) : _jsxs(_Fragment, {
            children: ["You have been added as an owner to", ' ', _jsx(CollectionizeStoryAutoLink, {
              id: story.id,
              name: story.name,
              type: story.story_type
            })]
          })
        }),
        timeout: 3500
      });
    }
  });
};
ToggleOwnerContextMenuItem.displayName = "ToggleOwnerContextMenuItem";
const MoveToIterationContextMenuItem = ({
  iteration,
  story
}) => {
  const {
    group_id,
    iteration_id
  } = story;
  let foundIteration = null;
  if (group_id) {
    if (iteration === 'current') foundIteration = getCurrentIterationForGroup(group_id);else if (iteration === 'next') foundIteration = getNextIterationForGroup(group_id);
  }

  // Don't move to the same as currently assigned iteration
  if (iteration_id && foundIteration?.id === iteration_id) foundIteration = null;
  return _jsx(ContextMenuItem, {
    isDisabled: !foundIteration || isLoggedInUserObserver(),
    icon: "Iteration",
    label: iteration === 'current' ? 'Move to current iteration' : 'Move to next iteration',
    onClick: () => {
      if (!foundIteration) return;
      const it = foundIteration;
      setIteration(story, Number(it.id)).then(() => {
        success(`Story was moved to <strong>${sanitize(it.name)}</strong>.`, {
          actions: `<a href="${generateURL(it)}" data-controller="HotReload" data-on-click="load" data-page="iterations" class="action mini elevated">
                  <span class="fa fa-eye"></span>
                  View iteration
                </a>`,
          timeout: 5000
        });
      });
    }
  });
};
MoveToIterationContextMenuItem.displayName = "MoveToIterationContextMenuItem";
export const CollectionizeStoryContextMenu = ({
  id,
  onMove
}) => {
  const {
    story,
    loading
  } = useStoryWithFetch({
    id
  });
  if (loading) return _jsx(ContextMenuLoading, {
    template: onMove ? '0-00-000-0000-0' : '0-000-0000-0'
  });
  if (!story) return null;
  return _jsxs(ContextMenu, {
    children: [_jsx(OpenInNewTabContextMenuItem, {
      url: getHref(id, story.name)
    }), _jsx(ContextMenuDivider, {}), !!onMove && _jsxs(_Fragment, {
      children: [_jsx(ContextMenuItem, {
        icon: "ChevronUp",
        label: "Move to top",
        onClick: () => onMove('top'),
        isDisabled: isLoggedInUserObserver()
      }), _jsx(ContextMenuItem, {
        icon: "ChevronDown",
        label: "Move to bottom",
        onClick: () => onMove('bottom'),
        isDisabled: isLoggedInUserObserver()
      }), _jsx(ContextMenuDivider, {})]
    }), _jsx(ToggleOwnerContextMenuItem, {
      story: story
    }), _jsx(MoveToIterationContextMenuItem, {
      story: story,
      iteration: "current"
    }), _jsx(MoveToIterationContextMenuItem, {
      story: story,
      iteration: "next"
    }), _jsx(ContextMenuDivider, {}), _jsx(CopyContextMenuItem, {
      icon: "Link",
      value: getHref(id, story.name, true),
      label: "Link"
    }), _jsx(CopyContextMenuItem, {
      value: String(id),
      label: "ID"
    }), _jsx(CopyContextMenuItem, {
      icon: "CopyDocument",
      value: story.name,
      label: "Title"
    }), _jsx(CopyContextMenuItem, {
      icon: "Branch",
      value: getBranchName(story),
      label: "Branch Name"
    }), _jsx(ContextMenuDivider, {}), _jsx(PinContextMenuItem, {
      id: story.global_id,
      entityType: Nouns.Story,
      loggingContext: {
        typename: 'Story'
      }
    })]
  });
};
CollectionizeStoryContextMenu.displayName = "CollectionizeStoryContextMenu";
export const useCollectionizeStoryContextMenu = storyId => {
  const {
    canReorder
  } = useTableGroup();
  const onMove = useCallback(async dir => {
    try {
      await saveChanges(storyId, {
        move_to: dir === 'top' ? 'first' : 'last'
      });
      addToast({
        kind: 'success',
        timeout: 5000,
        Content: () => _jsx(Spaced, {
          vertically: true,
          amount: "space2",
          children: _jsxs(ToastText, {
            children: ["Story was moved to the ", dir, " of its current workflow state."]
          })
        })
      });
    } catch (e) {
      addToast({
        kind: 'warning',
        timeout: 5000,
        Content: () => _jsxs(Spaced, {
          vertically: true,
          amount: "space2",
          children: [_jsx(ToastTitle, {
            children: "Story could not be moved."
          }), e && typeof e === 'object' && 'message' in e && _jsx(ToastText, {
            children: e.message
          })]
        })
      });
    }
  }, [storyId]);
  const {
    props
  } = useContextMenuWithLogging(useCallback(() => _jsx(CollectionizeStoryContextMenu, {
    id: storyId,
    onMove: canReorder ? dir => onMove(dir) : undefined
  }), [storyId, canReorder, onMove]), {
    entityType: 'Story'
  });
  return props;
};
export const useCollectionizeStoryContextMenuWithQuickSearchSessionLogging = (storyId, logQuickSearchInteractions) => {
  const {
    canReorder
  } = useTableGroup();
  const onMove = useCallback(async dir => {
    try {
      await saveChanges(storyId, {
        move_to: dir === 'top' ? 'first' : 'last'
      });
      addToast({
        kind: 'success',
        timeout: 5000,
        Content: () => _jsx(Spaced, {
          vertically: true,
          amount: "space2",
          children: _jsxs(ToastText, {
            children: ["Story was moved to the ", dir, " of its current workflow state."]
          })
        })
      });
    } catch (e) {
      addToast({
        kind: 'warning',
        timeout: 5000,
        Content: () => _jsxs(Spaced, {
          vertically: true,
          amount: "space2",
          children: [_jsx(ToastTitle, {
            children: "Story could not be moved."
          }), e && typeof e === 'object' && 'message' in e && _jsx(ToastText, {
            children: e.message
          })]
        })
      });
    }
  }, [storyId]);
  const {
    props
  } = useContextMenuWithLoggingOptionalEvents(useCallback(() => _jsx(CollectionizeStoryContextMenu, {
    id: storyId,
    onMove: canReorder ? dir => onMove(dir) : undefined
  }), [storyId, canReorder, onMove]), {
    entityType: 'Story'
  }, logQuickSearchInteractions);
  return props;
};