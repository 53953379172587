import { UseSetTeamScopeForDetailPageIterationAssociatedTeamsQueryDocument } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { UseSetTeamScopeForDetailPageEpicAssociatedTeamsQueryDocument } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { useCallback, useEffect, useMemo } from 'react';
import { gql } from '@clubhouse/datalayer';
import { useLazyQuery } from 'gql';
import { useWorkspaceSlug } from 'utils/navigation';
import { PAGE_NAMES, getMatchForPath } from 'utils/navigation/paths';
export const UseSetTeamScopeForDetailPageEpicAssociatedTeamsQuery = UseSetTeamScopeForDetailPageEpicAssociatedTeamsQueryDocument;
export const UseSetTeamScopeForDetailPageIterationAssociatedTeamsQuery = UseSetTeamScopeForDetailPageIterationAssociatedTeamsQueryDocument;
export const useSetTeamScopeForDetailPage = ({
  setTeamScopeIdState,
  pathname,
  teamScopeId
}) => {
  const slug = useWorkspaceSlug();
  const [queryEpic] = useLazyQuery(UseSetTeamScopeForDetailPageEpicAssociatedTeamsQuery);
  const [queryIteration] = useLazyQuery(UseSetTeamScopeForDetailPageIterationAssociatedTeamsQuery);
  const queries = useMemo(() => ({
    [PAGE_NAMES.EPIC]: queryEpic,
    [PAGE_NAMES.ITERATION]: queryIteration
  }), [queryEpic, queryIteration]);
  const isInQueries = useCallback(pageName => pageName ? pageName in queries : false, [queries]);
  const {
    pageName,
    match
  } = getMatchForPath({
    path: pathname
  });
  useEffect(() => {
    (async () => {
      if (!isInQueries(pageName)) return;
      const {
        data
      } = await queries[pageName]({
        variables: {
          slug: slug ?? '',
          publicId: Number(match?.params.id),
          includeTeams: teamScopeId ? [teamScopeId] : []
        },
        fetchPolicy: 'network-only'
      });
      const isTeamScopeAssociatedWithEntity = data?.workspace2?.entity?.teamsOrAssociatedTeams?.edges?.some(({
        node
      }) => node.id === teamScopeId);
      if (!isTeamScopeAssociatedWithEntity) {
        setTeamScopeIdState(data?.workspace2?.entity?.team?.id ?? null);
      }
    })();
  }, [isInQueries, match?.params.id, pageName, queries, setTeamScopeIdState, slug, teamScopeId]);
};