import { UpdateStoryRequesterFieldDocument } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { StoryRequesterSelectDocument } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { StoryRequesterFieldFragmentDoc } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { useCallback, useMemo } from 'react';
import { gql } from '@clubhouse/datalayer';
import { Center } from '@clubhouse/shared/components/Center';
import { SkeletonCircle } from '@clubhouse/shared/components/SkeletonCircle';
import { Nouns } from '@clubhouse/shared/constants/nouns';
import { GroupedOptionsFieldSingleSelection } from 'components/gql/GroupedOptionsField';
import { getSection } from 'components/gql/owner/utils/sections';
import { RequesterFieldTarget } from 'components/gql/requester/RequesterFieldTarget';
import { createOptimisticStoryMutationResponse } from 'components/gql/utils/mutation';
import { useMutation } from 'gql';
import { useFieldOptionsQuery } from './useFieldOptionsQuery';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const StoryRequesterFieldFragment = StoryRequesterFieldFragmentDoc;
const QUERY_STORY_REQUESTER_SELECT = StoryRequesterSelectDocument;
const MUTATE_STORY_REQUESTER_FIELD = UpdateStoryRequesterFieldDocument;
export const RequesterFieldComponent = ({
  entity: {
    id,
    requester
  }
}) => {
  const fetchRequesters = useFieldOptionsQuery({
    query: QUERY_STORY_REQUESTER_SELECT,
    storyId: id,
    includeCurrentPermissionId: true
  });
  const [save] = useMutation(MUTATE_STORY_REQUESTER_FIELD);
  const handleChange = useCallback(async selectedId => save({
    variables: {
      storyId: id,
      input: {
        requester: selectedId
      }
    },
    optimisticResponse: createOptimisticStoryMutationResponse(id, {
      requester: {
        __typename: 'Permission',
        id
      }
    })
  }), [save, id]);
  const selectedRequester = useMemo(() => {
    const {
      id,
      state,
      email,
      displayName,
      mentionName,
      displayIcon
    } = requester;
    return {
      id,
      state,
      email_address: email,
      name: displayName,
      mention_name: mentionName,
      ...(displayIcon?.thumbnailUrl ? {
        display_icon: {
          url: displayIcon.thumbnailUrl
        }
      } : {
        display_icon: null
      })
    };
  }, [requester]);
  return _jsx(Center, {
    children: _jsx("div", {
      children: _jsx(GroupedOptionsFieldSingleSelection, {
        selectedEntity: selectedRequester,
        fetchOptions: fetchRequesters,
        getSection: getSection,
        onChange: handleChange,
        TargetComponent: RequesterFieldTarget,
        unit: Nouns.Requester,
        required: true
      })
    })
  });
};
RequesterFieldComponent.displayName = "RequesterFieldComponent";
RequesterFieldComponent.displayName = 'RequesterFieldComponent';
export const RequesterField = () => ({
  name: 'requester',
  displayName: 'Requester',
  sort: true,
  headerProps: {
    centered: true
  },
  Component: RequesterFieldComponent,
  LoadingComponent: () => _jsx(Center, {
    children: _jsx(SkeletonCircle, {
      size: 24
    })
  }),
  width: 80
});