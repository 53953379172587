import { useSearchParam } from 'utils/navigation';
import Url from '../../../modules/url';
const paramName = 'ct_workflow';
export const CONFIG_OPTIONS = {
  ct_type: {
    title: 'Type: ',
    description: 'Set Chart Type',
    menuWidth: 180,
    defaultValue: 'cycle time',
    values: [{
      name: 'Cycle Time',
      value: 'cycle time',
      note: 'How long it takes Stories to be completed after being started.'
    }, {
      name: 'Lead Time',
      value: 'lead time',
      note: 'How long it takes Stories to be completed after being created.'
    }, {
      name: 'Custom',
      value: 'custom',
      note: 'Manually select the start and end state used to calculate cycle time.'
    }]
  },
  ct_scale: {
    title: 'Scale: ',
    description: 'Set Chart Scale',
    menuWidth: 180,
    defaultValue: 'log',
    values: [{
      name: 'Log',
      value: 'log',
      note: 'Good for seeing everything when you have outliers.'
    }, {
      name: 'Linear',
      value: 'linear',
      note: 'Good for when you want an accurate sense of scale.'
    }]
  },
  ct_workflow: {
    title: 'Workflow: ',
    defaultValue: undefined,
    menuWidth: 240,
    values: []
  }
};
export const useCycleTimeParam = () => {
  const {
    param
  } = useSearchParam({
    paramName,
    paramType: 'string'
  });
  return param;
};
export const updateWorkflowConfig = (workflows = [], value = Url.getParamFromUrl(paramName)) => {
  const config = CONFIG_OPTIONS.ct_workflow;
  config.values = workflows.map(wf => ({
    name: wf.name,
    value: `${wf.id}`
  }));
  config.values.unshift({
    name: 'All',
    value: 'all'
  });
  if (!value || !config.values.some(wf => wf.value === value)) {
    config.defaultValue = config.values[0]?.value;
    Url.updateParamInUrl(paramName, config.defaultValue);
  }
};
export const getChartConfig = key => {
  return Url.getParamFromUrl(key) || CONFIG_OPTIONS[key]?.defaultValue;
};