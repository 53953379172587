import { useCallback, useRef, useState } from 'react';
import { useUpdateEffect } from 'react-use';
export function useTableSortState({
  initialColumn,
  initialDirection,
  onSort
}) {
  const [currentSortColumn, setCurrentSortColumn] = useState(initialColumn);
  const [currentSortDir, setCurrentSortDir] = useState(initialDirection);
  const onSortRef = useRef(onSort);
  onSortRef.current = onSort;
  useUpdateEffect(() => {
    setCurrentSortColumn(initialColumn);
  }, [initialColumn]);
  const handleClick = useCallback(column => {
    let newSortDirection = currentSortDir;
    if (column === currentSortColumn) {
      newSortDirection = currentSortDir === 'ascending' ? 'descending' : 'ascending';
      setCurrentSortDir(newSortDirection);
    } else {
      setCurrentSortColumn(column);
      newSortDirection = 'ascending';
      setCurrentSortDir('ascending');
    }
    if (typeof onSortRef.current === 'function') onSortRef.current({
      oldSort: currentSortColumn ?? undefined,
      newSort: column,
      newSortDirection
    });
  }, [currentSortColumn, currentSortDir]);
  return {
    sortColumn: currentSortColumn ?? undefined,
    sortDirection: currentSortDir,
    onSortChange: handleClick
  };
}