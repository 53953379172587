import IntegrationModel from '../../../core/js/models/integration';
import Url from '../../../core/js/modules/url';
import Utils from '../../../core/js/modules/utils';
import ExportData from '../../../core/js/modules/exportData';
import CompanyModel from '../../../core/js/models/company';
import OrganizationModel from '../../../core/js/models/organization';
import Message from '../../../core/js/controllers/message';
const exports = {};
exports.handleAuth = () => {
  return new Promise(resolve => {
    const query = Url.parseLocationSearch();
    const state = query.state;
    const origin = Url.getCurrentOrigin();
    if (state && state !== Url.getCurrentSlug()) {
      Utils.redirect(origin + '/' + state + '/settings/integrations/gsheets' + window.location.search);
      return resolve();
    }
    return resolve(getOrCreateIntegration().then(startExport).then(() => {
      return saveSuccessMessage();
    }).catch(err => {
      return saveErrorMessage(err);
    }));
  });
};
const authorize = ({
  integration,
  code
}) => {
  return IntegrationModel.authorizeGsheetsIntegration({
    integration,
    code
  });
};
const createAndAuthorize = ({
  code
}) => {
  return new Promise((resolve, reject) => {
    IntegrationModel.createNew({
      type: 'gsheets'
    }, (err, integration) => {
      if (err) {
        return reject(err);
      }
      return resolve(authorize({
        integration,
        code
      }));
    });
  });
};
const getOrCreateIntegration = () => {
  return new Promise((resolve, reject) => {
    const query = Url.parseLocationSearch();
    const {
      code,
      error
    } = query;
    if (error) {
      return reject(error);
    }
    IntegrationModel.fetchAll(() => {
      const gsheets = IntegrationModel.getGsheetsIntegration();
      if (!gsheets) {
        return resolve(createAndAuthorize({
          code
        }));
      }
      return resolve(gsheets);
    });
  });
};
const startExport = () => {
  const companyId = CompanyModel.getCurrentID();
  const organizationId = OrganizationModel.getCurrentID();
  ExportData.start({
    workspace2_id: organizationId
  }, {
    company_id: companyId,
    organization_id: organizationId
  }, 'gsheet');
  return Promise.resolve();
};
const saveErrorMessage = err => {
  const title = 'There was an error installing the Google Sheets integration.';
  let message;
  if (err) message = `The message we got back was "${err}".`;
  Message.error(title, {
    secondary: message,
    timeout: 10_000
  });
};
const saveSuccessMessage = () => {
  Message.success('Google Sheets integration saved.');
};
export { exports as default };