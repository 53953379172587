import { EpicCreatedDateFieldFragmentFragmentDoc } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { TableDate } from 'components/shared/table/base';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const EpicCreatedDateFieldFragment = EpicCreatedDateFieldFragmentFragmentDoc;
function CreatedDateFieldComponent({
  entity
}) {
  return _jsx(TableDate, {
    value: entity.createdAt
  });
}
CreatedDateFieldComponent.displayName = "CreatedDateFieldComponent";
export const CreatedDateField = () => ({
  name: 'createdAt',
  displayName: 'Created On',
  sort: true,
  Component: CreatedDateFieldComponent,
  width: 110
});