import ApplicationState from '../modules/applicationState';
import Backend from '../modules/backend';
import Collection from '../_frontloader/collection';
import CompanyModel from './company';
import * as Event from '../_frontloader/event';
import Log from '../modules/log';
import OrganizationModel from './organization';
import User from '../modules/user';
import UserModel from './user';
import Utils from '../modules/utils';
import _ from 'lodash';
const exports = {};

/*

Example Permission entity:

{
  created_at: "2015-08-06T20:25:17Z"
  disabled: false
  email_notification_level: "important-events"
  id: "56d8a841-0b1d-4b0e-aeef-570e2fd89b76"
  role: "admin"
  updated_at: "2015-08-24T15:06:21Z"
}

*/

Collection.create('Permission', exports);
exports.normalize = permission => {
  // Only the current user has permission.organization.
  OrganizationModel.updateIfValid(permission.organization);
};
exports.getCurrentPermissionForUser = user => {
  const org_id = OrganizationModel.getCurrentID();
  user = user || User.getCurrentUser();
  return exports.get({
    user_id: user.id,
    organization: {
      id: org_id
    }
  });
};
exports.getCurrentUserPermissionItem = permission => {
  return exports.getCurrentPermissionForUser()[permission];
};

// Lazily migrate ApplicationState properties from workspace -> space naming
// by copying properties to new keys (if they don't already exist).
// Deletes key with workspace-style name (after copying when necessary).
const migrateWorkspaceNamingInJSONObject = json => {
  const data = Utils.parseJSON(json);
  Utils.renameObjectKeys(data, /[Ww]orkspace(?!2)/g, match => {
    return match.charAt(0) === 'W' ? 'Space' : 'space';
  });
  return JSON.stringify(data);
};
exports.fetchCurrentApplicationState = (callback, retryCount, restoreCallback = ApplicationState.restore) => {
  callback = _.isFunction(callback) ? callback : _.noop;
  const MAX_RETRIES = 3;
  Backend.get('/api/private/permission/space', {
    onComplete: (res, xhr) => {
      // A user visiting an org for the first time gets a 200 and res is { space_data: null }.
      // This can return a 401 if the user is on the /organizations page and the
      // current org is disabled, or it can return a 403 if the user session has expired.
      if (xhr.status === 401 || xhr.status === 403) {
        // Either the user, the org, or the company is disabled.
        // Whatever that may be, it is not the concern of this function so, callback!
        callback();
      } else if (xhr.status === 200) {
        if (res && _.isString(res.space_data) && res.space_data) {
          if (_isDoubleEncoded(res.space_data)) {
            Log.log('[ch12910] ApplicationState is double-encoded during a fetch!!', {
              data: res.space_data
            });
            res.space_data = JSON.parse(res.space_data);
          }
          const migratedSpaceData = migrateWorkspaceNamingInJSONObject(res.space_data);
          restoreCallback(migratedSpaceData);
        } else {
          Log.log('[ch12910] ApplicationState request was a 200 but space_data is not a string!', {
            response: res
          });
        }
        callback();
      } else {
        // This is likely either a 0 or a 500 status code.
        retryCount = _.isNumber(retryCount) ? retryCount + 1 : 1;
        Log.log('[ch12910] No application state found! Retry ' + retryCount + ' of ' + MAX_RETRIES + '...', {
          response: res,
          statusCode: xhr.status
        });
        if (retryCount >= MAX_RETRIES) {
          Event.trigger('SystemCrash', {
            description: _getSystemCrashDescription(xhr.status)
          });
        } else {
          exports.fetchCurrentApplicationState(callback, retryCount);
        }
      }
    }
  });
};
function _getSystemCrashDescription(statusCode) {
  if (statusCode === 500) {
    return 'We appear to be having a temporary service issue. We are aware of this issue and are ' + `investigating. Please check <a href="https://${BRAND.DOMAIN_STATUS_WEBSITE}" target="_blank">` + `https://${BRAND.DOMAIN_STATUS_WEBSITE}</a> for updates, or try reloading the page.`;
  } else if (statusCode === 0) {
    return 'The requests to our server failed. If you have any browser extensions that ' + 'affect network requests, please disable them, or try a different browser. If you continue ' + `to experience this issue, please <a href="mailto:${BRAND.SUPPORT_EMAIL}">contact us</a>.`;
  }
  return '';
}
function _isDoubleEncoded(data) {
  return data.indexOf('"{\\"') === 0;
}
exports.saveApplicationState = (data, callback) => {
  // Ref: https://app.shortcut.com/internal/story/27408
  if (_isDoubleEncoded(data)) {
    Log.log('[ch12910] ApplicationState is double-encoded during a save!!', {
      data
    });
    data = JSON.parse(data);
  }
  Backend.put('/api/private/permission/space', {
    data: {
      space_data: data
    },
    onComplete: (res, xhr) => {
      exports.defaultNoResponseHandler(res, xhr, callback);
    }
  });
};
exports.updatePermission = (data, callback) => {
  Backend.put('/api/private/permission', {
    data,
    onComplete: res => {
      UserModel.fetchLoggedInUserProfiles(() => {
        exports.defaultGetHandler(res, callback);
      });
    }
  });
};
exports.updateNavPreferences = ({
  name,
  prevName,
  nextName,
  drawer
}) => {
  const changes = prevName ? {
    after_item: prevName
  } : nextName ? {
    before_item: nextName
  } : {};
  return new Promise((resolve, reject) => {
    Backend.put('/api/private/permission/nav', {
      data: {
        name,
        drawer,
        ...changes
      },
      onSuccess: resolve,
      onError: reject
    });
  }).then(res => {
    const permission = exports.getCurrentPermissionForUser();
    permission.nav = res;
    exports.update(permission);
  });
};
exports.setDefault = (permission, callback) => {
  callback = _.isFunction(callback) ? callback : _.noop;
  const orgId = permission.organization.id;
  Backend.put('/api/private/profile/permissions/' + permission.id + '/default', {
    company_id: CompanyModel.getFromOrg({
      id: orgId
    }).id,
    organization_id: orgId,
    onComplete: (res, xhr) => {
      if (xhr.status === 204) {
        UserModel.fetchLoggedInUserProfiles(callback);
      } else {
        exports.defaultErrorHandler(res, callback);
      }
    }
  });
};
exports.setPosition = (permission, changes, callback) => {
  callback = _.isFunction(callback) ? callback : _.noop;
  const orgId = permission.organization.id;
  Backend.put('/api/private/profile/permissions/' + permission.id, {
    data: changes,
    company_id: CompanyModel.getFromOrg({
      id: orgId
    }).id,
    organization_id: orgId,
    onComplete: res => {
      if (_.get(res, 'id')) {
        UserModel.fetchLoggedInUserProfiles(callback);
      } else {
        exports.defaultErrorHandler(res, callback);
      }
    }
  });
};

// We have different permission types that contain either an address or an id
// Below is to make sure they don't get out of sync if a request comes from
// one endpoint and tries to update an entitiy entered into the model by another
exports.syncEmailPermissions = permission => {
  const existing = exports.getById(permission.id);
  if (existing && exports.isValid(permission)) {
    if (existing.email_id && permission.email_address) {
      delete existing.email_id;
    }
    if (existing.email_address && permission.email_id) {
      delete existing.email_address;
    }
    exports.update(existing);
  }
};
exports.updateSlackNotificationEnabled = enabled => {
  return new Promise((resolve, reject) => {
    exports.updatePermission({
      slack_notification_enabled: enabled
    }, (err, res) => {
      if (err) {
        reject(err);
      } else {
        resolve(res);
      }
    });
  });
};
exports.hasSlackIdentity = permission => permission.has_slack_identity;
exports.updateSlackIdentity = data => {
  return new Promise((resolve, reject) => {
    Backend.put('/api/private/permission/slack-identity', {
      data,
      onComplete: res => {
        if (res.error) {
          reject(res.error);
        } else {
          resolve(res);
        }
      }
    });
  });
};
export { exports as default };