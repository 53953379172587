import { useCallback, useMemo, useRef } from 'react';
export function useBaseKeyboardGridNavigation({
  onKeyDown: _onKeyDown,
  onFocus: _onFocus
}) {
  const tableRef = useRef(null);
  const onKeyDown = useCallback(e => {
    if (!tableRef.current) return;
    if (!tableRef.current.contains(e.target)) return;
    if (e.defaultPrevented) return;
    const handled = _onKeyDown({
      table: tableRef.current,
      event: e,
      element: e.target,
      key: e.key,
      shiftKey: e.shiftKey,
      altKey: e.altKey
    });
    if (handled) {
      e.preventDefault();
      e.stopPropagation();
    }
  }, [_onKeyDown]);
  const onFocus = useMemo(() => {
    if (!_onFocus) return undefined;
    return e => {
      if (!tableRef.current) return;
      if (!tableRef.current.contains(e.target)) return;
      _onFocus({
        table: tableRef.current,
        element: e.target,
        event: e
      });
    };
  }, [_onFocus]);
  const getGridProps = useCallback(() => {
    return {
      role: 'grid',
      ref: tableRef,
      onKeyDown,
      onFocus
    };
  }, [onFocus, onKeyDown]);
  const getGridCellHeaderProps = useCallback(({
    header,
    hasAction
  }) => {
    return {
      role: header === 'row' ? 'rowheader' : 'columnheader',
      scope: header === 'row' ? 'row' : 'col',
      tabIndex: hasAction ? 0 : undefined
    };
  }, []);
  const getGridCellProps = useCallback(({
    isFocusTarget
  }) => {
    return {
      role: 'gridcell',
      tabIndex: isFocusTarget ? 0 : -1
    };
  }, []);
  return {
    getGridProps,
    getGridCellHeaderProps,
    getGridCellProps
  };
}