import { Chip } from '@clubhouse/shared/components/Chip';
import { pluralize } from '@clubhouse/shared/utils';
import { ChipTooltip } from '../ChipTooltip';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const DocCountChip = ({
  count
}) => {
  if (!count) return null;
  return _jsx(ChipTooltip, {
    content: _jsx(ChipTooltip.Text, {
      children: pluralize({
        count,
        singular: 'Doc',
        plural: 'Docs'
      })
    }),
    children: _jsxs(Chip, {
      children: [_jsx(Chip.Icon, {
        icon: "Document",
        label: "Docs count: "
      }), count]
    })
  });
};
DocCountChip.displayName = "DocCountChip";