import { Spaced } from '@clubhouse/shared/components/Spaced';
import { useStory } from 'data/entity/story';
import { StoryCommentById, StoryFileComment, StoryLinkedFileComment } from './StoryComment';
import { created } from 'utils/sort';
import { useAppState } from 'utils/appState';
import { StoryCommentEditor } from './StoryCommentEditor';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const isComment = activity => activity.entity_type === 'story-comment';
const isFileComment = activity => activity.entity_type === 'story-file-comment';
export function StoryActivityList({
  storyId
}) {
  const story = useStory({
    id: storyId
  });
  const [sortDirection] = useAppState({
    appStateKey: 'Comments.sortOrder',
    default: 'ascending'
  });
  const items = [...(story?.activity || [])].sort(created);
  if (sortDirection === 'ascending') items.reverse();
  return _jsxs(_Fragment, {
    children: [sortDirection === 'descending' && _jsx(StoryCommentEditor, {
      storyId: storyId
    }), _jsx(Spaced, {
      vertically: true,
      amount: "space1",
      children: items.filter(activity => !isComment(activity) || !activity.parent_id).map(activity => isComment(activity) ? _jsx(StoryCommentById, {
        storyId: storyId,
        commentId: activity.id
      }, activity.id) : isFileComment(activity) ? _jsx(StoryFileComment, {
        storyId: storyId,
        file: activity
      }, activity.id) : _jsx(StoryLinkedFileComment, {
        storyId: storyId,
        file: activity
      }, activity.id))
    }), sortDirection === 'ascending' && _jsx(StoryCommentEditor, {
      storyId: storyId
    })]
  });
}