import { Icon } from '@useshortcut/shapes-ds';
import { COLORS } from '../../colors';
import { DeprecatedIconAdapter } from './DeprecatedIconAdapter';

/**
 * @deprecated Use <Icon icon="Add" /> from @useshortcut/shapes-ds
 */
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const Plus = ({
  fill = COLORS.BLACK,
  width = '10px'
}) => _jsx(DeprecatedIconAdapter, {
  width: width,
  fill: fill,
  children: _jsx(Icon, {
    icon: "Add"
  })
});
Plus.displayName = "Plus";