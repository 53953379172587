import "core-js/modules/es.array.push.js";
import * as FilterTemplate from 'app/client/stories/views/templates/filter.html';
import * as FilterDialogTemplate from 'app/client/stories/views/templates/filterDialog.html';
import * as FilterSidebarNameTemplate from 'app/client/stories/views/templates/filterSidebarName.html';
import AutocompleteController from '../../../core/js/controllers/autocomplete';
import ContextMenuController from '../../../core/js/controllers/contextMenu';
import DropdownController from '../../../core/js/controllers/dropdown';
import EstimateScaleModel from '../../../core/js/models/estimateScale';
import * as Event from '../../../core/js/_frontloader/event';
import FilterModel from '../../../core/js/models/filter';
import Format from '../../../core/js/modules/format';
import Is from '../../../core/js/modules/is';
import LabelModel from '../../../core/js/models/label';
import MilestoneModel from '../../../core/js/models/milestone';
import ProfileModel from '../../../core/js/models/profile';
import FeatureModel from '../../../core/js/models/feature';
import StoriesFilterModel from '../models/storiesFilter';
import StoriesView from '../modules/storiesView';
import Utils from '../../../core/js/modules/utils';
import View from 'app/client/core/js/modules/view';
import { Icon, StatusIcon } from '@useshortcut/shapes-ds';
import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons/DeprecatedIconAdapter';
import { renderComponentToString } from 'utils/helpers';
import _ from 'lodash';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const exports = {};
const USER_FIXTURE_TYPES = {
  REQUESTED_BY: 'requestedBy',
  OWNED_BY: 'ownedBy'
};
const SIDEBAR_FILTER_TYPES = ['tag', 'user', 'epic'];
exports.render = () => {
  const filters = FilterModel.sortBy('added').reverse();
  const staleFilter = FilterModel.get({
    fnName: 'stale'
  });
  const staleFilterPosition = filters.indexOf(staleFilter);
  if (!FeatureModel.isProjectsFeatureEnabled() && staleFilterPosition !== -1) {
    exports.remove(staleFilter);
    filters.splice(staleFilterPosition, 1);
  }
  const html = FilterTemplate.render({
    filters
  });
  const container = $('#filter-nav');
  container.html(html);
  ContextMenuController.refresh();
  const hasFilters = filters.length > 0;
  exports.toggleFilterTypeVisibility(hasFilters);
};
exports.toggleFilterTypeVisibility = hasFilters => {
  if (hasFilters) {
    $('#current-filter-type').removeClass('hidden');
    $('#filter-nav > button').removeClass('full-width');
  } else {
    $('#current-filter-type').addClass('hidden');
    $('#filter-nav > button').addClass('full-width');
  }
};
exports.renderUserIcon = (id = '', width = 20) => View.renderComponentDelayed({
  componentKey: `user-${id}`,
  component: _jsx(DeprecatedIconAdapter, {
    width: width,
    children: _jsx(Icon, {
      icon: "User"
    })
  })
}).html;
exports.renderSearchIcon = (id = '', width = 20) => View.renderComponentDelayed({
  componentKey: `search-${id}`,
  component: _jsx(DeprecatedIconAdapter, {
    width: width,
    children: _jsx(Icon, {
      icon: "Search"
    })
  })
}).html;
exports.renderEstimateIcon = (id = '', width = 20) => View.renderComponentDelayed({
  componentKey: `estimate-${id}`,
  component: _jsx(DeprecatedIconAdapter, {
    width: width,
    children: _jsx(Icon, {
      icon: "Estimate"
    })
  })
}).html;
exports.renderCommentIcon = (id = '', width = 20) => View.renderComponentDelayed({
  componentKey: `comment-${id}`,
  component: _jsx(DeprecatedIconAdapter, {
    width: width,
    children: _jsx(Icon, {
      icon: "Comment"
    })
  })
}).html;
exports.renderLinkIcon = (id = '', width = 20) => View.renderComponentDelayed({
  componentKey: `link-${id}`,
  component: _jsx(DeprecatedIconAdapter, {
    width: width,
    children: _jsx(Icon, {
      icon: "StoryBlocked"
    })
  })
}).html;
exports.renderAttachmentIcon = (id = '', width = 20) => View.renderComponentDelayed({
  componentKey: `attachment-${id}`,
  component: _jsx(DeprecatedIconAdapter, {
    width: width,
    children: _jsx(Icon, {
      icon: "Attachment"
    })
  })
}).html;
exports.renderWarnIcon = (id = '', width = 20) => View.renderComponentDelayed({
  componentKey: `warn-${id}`,
  component: _jsx(DeprecatedIconAdapter, {
    width: width,
    fill: "var(--iconRedColor)",
    children: _jsx(Icon, {
      icon: "Warn"
    })
  })
}).html;
exports.renderEpicIcon = (id = '', width = 20) => View.renderComponentDelayed({
  componentKey: `epic-${id}`,
  component: _jsx(DeprecatedIconAdapter, {
    width: width,
    children: _jsx(Icon, {
      icon: "Epic"
    })
  })
}).html;
exports.renderMilestoneIcon = (id = '', width = 20) => View.renderComponentDelayed({
  componentKey: `milestone-${id}`,
  component: _jsx(DeprecatedIconAdapter, {
    width: width,
    children: _jsx(Icon, {
      icon: "Milestone"
    })
  })
}).html;
exports.renderCalendarIcon = (id = '', width = 20) => View.renderComponentDelayed({
  componentKey: `calendar-${id}`,
  component: _jsx(DeprecatedIconAdapter, {
    width: width,
    children: _jsx(Icon, {
      icon: "Calendar"
    })
  })
}).html;
exports.renderChevronIcon = (id = '', width = 20) => View.renderComponentDelayed({
  componentKey: `chevron-${id}`,
  component: _jsx(DeprecatedIconAdapter, {
    width: width,
    children: _jsx(Icon, {
      icon: "ChevronRight"
    })
  })
}).html;
exports.renderHistoryIcon = (id = '', width = 20) => View.renderComponentDelayed({
  componentKey: `history-${id}`,
  component: _jsx(DeprecatedIconAdapter, {
    width: width,
    children: _jsx(Icon, {
      icon: "History"
    })
  })
}).html;
exports.toggle = function (e) {
  const filter = Utils.getModelFromContext(this);
  if (Utils.keyPressed(e, 'MIDDLE_CLICK')) {
    exports.remove.call(this);
    return false;
  }
  const update = {
    active: !filter.active,
    id: filter.id
  };
  if (update.active) {
    update.lastActive = Date.now();
  }
  FilterModel.update(update);
  Event.trigger('spaceUpdate');
  StoriesView.resetAndDrawStories();
  return false;
};
exports.removeAll = function () {
  const filterType = Utils.data(this, 'type');
  if (!filterType) {
    FilterModel.remove();
  } else if (_.includes(SIDEBAR_FILTER_TYPES, filterType)) {
    FilterModel.remove({
      type: filterType
    });
  } else {
    FilterModel.remove(filter => {
      return !_.includes(SIDEBAR_FILTER_TYPES, filter.type);
    });
  }
  Event.trigger('spaceUpdate');
  StoriesView.resetAndDrawStories();
  return false;
};
exports.disableAllFilters = () => {
  StoriesFilterModel.disableAll();
  StoriesView.resetAndDrawStories();
  return false;
};
exports.setFilterTypeToOr = () => {
  FilterModel.setFilterType('OR');
  Event.trigger('spaceUpdate');
  StoriesView.resetAndDrawStories();
  exports.updateFilterTypeUI();
  ContextMenuController.close();
  return false;
};
exports.setFilterTypeToAnd = () => {
  FilterModel.setFilterType('AND');
  Event.trigger('spaceUpdate');
  StoriesView.resetAndDrawStories();
  exports.updateFilterTypeUI();
  ContextMenuController.close();
  return false;
};
exports.updateFilterTypeUI = () => {
  $('#current-filter-type > span').html(FilterModel.getCachedFilterType() === 'AND' ? 'And' : 'Or');
};
exports.remove = function (filter) {
  filter = filter || Utils.getModelFromContext(this);
  FilterModel.remove({
    id: filter.id
  });
  Event.trigger('spaceUpdate');
  StoriesView.resetAndDrawStories();
  return false;
};
exports.byLabel = function () {
  const label = Utils.getModelFromContext(this);
  FilterModel.addFixture('byLabel', label);
  return false;
};
const onIterationGroupFilterChange = group => {
  DropdownController.closeById('filter-dialog-parent');
  FilterModel.addFixture('inIterationWithGroup', group);
};
exports.openDialog = function () {
  const data = {
    estimateScaleIsDisabled: EstimateScaleModel.isDisabled(),
    points: EstimateScaleModel.getPointScale(),
    onIterationGroupFilterChange
  };
  DropdownController.open({
    footer: FilterDialogTemplate.render(data),
    target: this,
    id: 'filter-dialog-parent',
    leftOffset: Is.mobile() ? 0 : 120,
    topOffset: Is.mobile() ? 4 : -300,
    width: Is.mobile() ? 310 : 550,
    constrainToViewport: true
  });
  for (let i = 0; i <= 4; i++) {
    View.renderComponent({
      mountNode: document.querySelector(`#filter-dialog #icon-user-${i}`),
      component: _jsx(DeprecatedIconAdapter, {
        width: 16,
        fill: "currentColor",
        children: _jsx(Icon, {
          icon: "User"
        })
      })
    });
  }
  for (let i = 1; i <= 4; i++) {
    View.renderComponent({
      mountNode: document.querySelector(`#filter-dialog #icon-history-${i}`),
      component: _jsx(DeprecatedIconAdapter, {
        width: 16,
        fill: "currentColor",
        children: _jsx(Icon, {
          icon: "History"
        })
      })
    });
  }
  for (let i = 1; i <= 2; i++) {
    View.renderComponent({
      mountNode: document.querySelector(`#filter-dialog #icon-calendar-${i}`),
      component: _jsx(DeprecatedIconAdapter, {
        width: 16,
        fill: "currentColor",
        children: _jsx(Icon, {
          icon: "Calendar"
        })
      })
    });
  }
  for (let i = 1; i <= 2; i++) {
    View.renderComponent({
      mountNode: document.querySelector(`#filter-dialog #icon-storyblocked-${i}`),
      component: _jsx(DeprecatedIconAdapter, {
        width: 16,
        fill: "var(--iconRedColor)",
        children: _jsx(Icon, {
          icon: "StoryBlocked"
        })
      })
    });
  }
  for (let i = 1; i <= 3; i++) {
    View.renderComponent({
      mountNode: document.querySelector(`#filter-dialog #icon-estimate-${i}`),
      component: _jsx(DeprecatedIconAdapter, {
        width: 16,
        fill: "currentColor",
        children: _jsx(Icon, {
          icon: "Estimate"
        })
      })
    });
  }
  for (let i = 1; i <= 2; i++) {
    View.renderComponent({
      mountNode: document.querySelector(`#filter-dialog #icon-milestone-${i}`),
      component: _jsx(DeprecatedIconAdapter, {
        width: 16,
        fill: "currentColor",
        children: _jsx(Icon, {
          icon: "Objectives"
        })
      })
    });
  }
  View.renderComponent({
    mountNode: document.querySelector(`#filter-dialog #icon-link`),
    component: _jsx(DeprecatedIconAdapter, {
      width: 16,
      fill: "currentColor",
      children: _jsx(Icon, {
        icon: "Link"
      })
    })
  });
  View.renderComponent({
    mountNode: document.querySelector(`#filter-dialog #icon-search`),
    component: _jsx(DeprecatedIconAdapter, {
      width: 16,
      fill: "currentColor",
      children: _jsx(Icon, {
        icon: "Search"
      })
    })
  });
  View.renderComponent({
    mountNode: document.querySelector(`#filter-dialog #icon-task`),
    component: _jsx(DeprecatedIconAdapter, {
      width: 16,
      fill: "currentColor",
      children: _jsx(Icon, {
        icon: "CheckCircle"
      })
    })
  });
  for (let i = 1; i <= 2; i++) {
    View.renderComponent({
      mountNode: document.querySelector(`#filter-dialog #icon-label-${i}`),
      component: _jsx(DeprecatedIconAdapter, {
        width: 16,
        fill: "currentColor",
        children: _jsx(Icon, {
          icon: "Label"
        })
      })
    });
  }
  View.renderComponent({
    mountNode: document.querySelector(`#filter-dialog #icon-stale`),
    component: _jsx(DeprecatedIconAdapter, {
      width: 16,
      fill: "var(--shapes-warningDark)",
      children: _jsx(Icon, {
        icon: "Warn"
      })
    })
  });
  View.renderComponent({
    mountNode: document.querySelector(`#filter-dialog #icon-feature`),
    component: _jsx(DeprecatedIconAdapter, {
      width: 16,
      fill: "var(--iconYellowColor)",
      children: _jsx(Icon, {
        icon: "Feature"
      })
    })
  });
  View.renderComponent({
    mountNode: document.querySelector(`#filter-dialog #icon-chore`),
    component: _jsx(DeprecatedIconAdapter, {
      width: 16,
      fill: "var(--iconGrayHoverColor)",
      children: _jsx(Icon, {
        icon: "Chore"
      })
    })
  });
  View.renderComponent({
    mountNode: document.querySelector(`#filter-dialog #icon-iteration-unstarted`),
    component: _jsx(DeprecatedIconAdapter, {
      width: 16,
      children: _jsx(StatusIcon, {
        icon: "Unstarted"
      })
    })
  });
  View.renderComponent({
    mountNode: document.querySelector(`#filter-dialog #icon-iteration-done`),
    component: _jsx(DeprecatedIconAdapter, {
      width: 16,
      children: _jsx(StatusIcon, {
        icon: "Done"
      })
    })
  });
  View.renderComponent({
    mountNode: document.querySelector(`#filter-dialog #icon-iteration-started`),
    component: _jsx(DeprecatedIconAdapter, {
      width: 16,
      children: _jsx(StatusIcon, {
        icon: "Started"
      })
    })
  });
  View.renderComponent({
    mountNode: document.querySelector(`#filter-dialog #icon-epic-unstarted`),
    component: _jsx(DeprecatedIconAdapter, {
      width: 16,
      children: _jsx(StatusIcon, {
        icon: "Unstarted"
      })
    })
  });
  View.renderComponent({
    mountNode: document.querySelector(`#filter-dialog #icon-epic-done`),
    component: _jsx(DeprecatedIconAdapter, {
      width: 16,
      children: _jsx(StatusIcon, {
        icon: "Done"
      })
    })
  });
  View.renderComponent({
    mountNode: document.querySelector(`#filter-dialog #icon-epic-started`),
    component: _jsx(DeprecatedIconAdapter, {
      width: 16,
      children: _jsx(StatusIcon, {
        icon: "Started"
      })
    })
  });
  View.renderComponent({
    mountNode: document.querySelector(`#filter-dialog #icon-bug`),
    component: _jsx(DeprecatedIconAdapter, {
      width: 16,
      fill: "var(--iconRedColor)",
      children: _jsx(Icon, {
        icon: "Bug"
      })
    })
  });
  View.renderComponent({
    mountNode: document.querySelector(`#filter-dialog #icon-attachment`),
    component: _jsx(DeprecatedIconAdapter, {
      width: 16,
      fill: "currentColor",
      children: _jsx(Icon, {
        icon: "Attachment"
      })
    })
  });
  View.renderComponent({
    mountNode: document.querySelector(`#filter-dialog #icon-comment`),
    component: _jsx(DeprecatedIconAdapter, {
      width: 16,
      fill: "currentColor",
      children: _jsx(Icon, {
        icon: "Comment"
      })
    })
  });
  return false;
};
exports.openKeywordSearch = function () {
  AutocompleteController.open({
    className: 'keyword-search',
    placeholder: 'Enter keyword...',
    // Using autocomplete input to apply custom keywords as filter
    // So there are no items to show
    items: null,
    showInput: true,
    onApply: () => {
      const value = $('.autocomplete-input').val().trim();
      if (value) {
        FilterModel.addFixture('search', value);
        DropdownController.closeById('filter-dialog-parent');
      }
      return false;
    },
    target: this,
    topOffset: 5,
    width: 200
  });
  return false;
};
exports.openUserDropdown = function () {
  const items = [];
  ProfileModel.getAllActiveProfileDetails().forEach(user => {
    items.push({
      name: `Owned by ${Format.sanitize(user.name)}`,
      customIconLeft: renderComponentToString(_jsx(DeprecatedIconAdapter, {
        width: 16,
        children: _jsx(Icon, {
          icon: "User"
        })
      })),
      value: () => {
        FilterModel.addFixture('ownedBy', user);
        DropdownController.closeById('filter-dialog-parent');
      }
    });
    items.push({
      name: `Requested by ${Format.sanitize(user.name)}`,
      customIconLeft: renderComponentToString(_jsx(DeprecatedIconAdapter, {
        width: 16,
        children: _jsx(Icon, {
          icon: "User"
        })
      })),
      value: () => {
        FilterModel.addFixture('requestedBy', user);
        DropdownController.closeById('filter-dialog-parent');
      }
    });
  });
  AutocompleteController.open({
    items,
    target: this,
    topOffset: 5,
    width: 300,
    showInput: true
  });
  return false;
};
exports.openLabelDropdown = function () {
  const items = [];
  LabelModel.each(label => {
    items.push({
      name: label.formatted_name,
      customIconLeft: renderComponentToString(_jsx(DeprecatedIconAdapter, {
        width: 16,
        fill: label.color || LabelModel.getDefaultColor(),
        children: _jsx(Icon, {
          icon: "Label"
        })
      })),
      value: () => {
        FilterModel.addFixture('byLabel', label);
        DropdownController.closeById('filter-dialog-parent');
      }
    });
  });
  AutocompleteController.open({
    items,
    target: this,
    topOffset: 5,
    width: 300,
    showInput: true
  });
  return false;
};
exports.openMilestonesDropdown = function () {
  AutocompleteController.open({
    items: MilestoneModel.getItemsForAutocomplete({
      valueFn: milestone => {
        FilterModel.addFixture('inMilestone', milestone);
        DropdownController.closeById('filter-dialog-parent');
      }
    }),
    showCloseButton: false,
    noActive: true,
    target: this,
    topOffset: 4,
    width: 280,
    showInput: true
  });
  return false;
};
exports.openEstimateDropdown = function () {
  const items = [];
  items.push({
    name: 'Unestimated',
    value: () => {
      FilterModel.addFixture('isUnestimated');
      DropdownController.closeById('filter-dialog-parent');
    }
  });
  EstimateScaleModel.getPointScale().forEach(point => {
    items.push({
      name: point + (point === 1 ? ' point' : ' points'),
      value: () => {
        FilterModel.addFixture('hasEstimate', point);
        DropdownController.closeById('filter-dialog-parent');
      }
    });
  });
  AutocompleteController.open({
    items,
    showCloseButton: false,
    showInput: true,
    target: this,
    topOffset: 4,
    width: 160
  });
  return false;
};
function getLabelFilterItems() {
  const noLabelFilter = FilterModel.get({
    fnName: 'unlabeled'
  });
  return [{
    className: noLabelFilter ? 'selected' : '',
    name: 'No Label',
    value: () => {
      noLabelFilter ? exports.remove(noLabelFilter) : FilterModel.addFixture('unlabeled');
      AutocompleteController.updateItems(getLabelFilterItems());
    }
  }, {
    hr: true
  }, ...LabelModel.map(label => {
    const existingFilter = FilterModel.get({
      type: 'tag',
      value: label.id
    });
    return {
      name: label.formatted_name,
      className: existingFilter ? 'selected' : '',
      iconLeft: 'fa-circle',
      iconLeftColor: label.color || LabelModel.getDefaultColor(),
      value: () => {
        existingFilter ? exports.remove(existingFilter) : FilterModel.addFixture('byLabel', label);
        AutocompleteController.updateItems(getLabelFilterItems());
      }
    };
  })];
}
exports.openLabelFilterDropdown = function () {
  AutocompleteController.open({
    items: getLabelFilterItems(),
    multiSelect: true,
    showInput: true,
    target: this,
    targetSelector: '#label-nav-bucket button',
    width: 260
  });
  return false;
};
exports.setUserFixtureToRequester = () => {
  const currentFixtureType = FilterModel.getUserFixtureType();
  if (currentFixtureType === USER_FIXTURE_TYPES.REQUESTED_BY) {
    return false;
  } else {
    FilterModel.setUserFixtureType(USER_FIXTURE_TYPES.REQUESTED_BY);
    AutocompleteController.updateItems(exports.getUserAutocompleteFilterItems());
  }
};
exports.setUserFixtureToOwner = () => {
  const currentFixtureType = FilterModel.getUserFixtureType();
  if (currentFixtureType === USER_FIXTURE_TYPES.OWNED_BY) {
    return false;
  } else {
    FilterModel.setUserFixtureType(USER_FIXTURE_TYPES.OWNED_BY);
    AutocompleteController.updateItems(exports.getUserAutocompleteFilterItems());
  }
};
exports.openUserFilterDropdown = function () {
  AutocompleteController.open({
    target: this,
    targetSelector: '#user-nav-bucket button',
    multiSelect: true,
    showInput: true,
    width: 260,
    items: exports.getUserAutocompleteFilterItems()
  });
  return false;
};
exports.getUserAutocompleteFilterItems = () => {
  const userFixtureType = FilterModel.getUserFixtureType();
  const items = [{
    html: '<div class="caption">Filter Stories by</div>'
  }, {
    name: 'Requester',
    iconLeft: userFixtureType === USER_FIXTURE_TYPES.REQUESTED_BY ? 'fa-circle' : 'fa-circle-o',
    value: exports.setUserFixtureToRequester
  }, {
    name: 'Owner',
    iconLeft: userFixtureType === USER_FIXTURE_TYPES.OWNED_BY ? 'fa-circle' : 'fa-circle-o',
    value: exports.setUserFixtureToOwner
  }, {
    hr: true
  }];
  if (userFixtureType === USER_FIXTURE_TYPES.OWNED_BY) {
    const noOwnerFilter = FilterModel.get({
      fnName: 'noOwner'
    });
    items.push({
      className: noOwnerFilter ? 'selected' : '',
      name: '<em>None</em>',
      value: () => {
        noOwnerFilter ? exports.remove(noOwnerFilter) : FilterModel.addFixture('noOwner');
        AutocompleteController.updateItems(exports.getUserAutocompleteFilterItems());
      }
    });
    items.push({
      hr: true
    });
  }
  const me = ProfileModel.getCurrentUserProfileDetails();
  return items.concat(ProfileModel.getAllActiveProfileDetails().map(user => {
    const existingFilter = user.id === me.id && FilterModel.get({
      fnName: userFixtureType + 'Me'
    }) || FilterModel.get({
      type: 'user',
      fnName: userFixtureType,
      value: user.id
    });
    return {
      className: existingFilter ? 'selected' : '',
      name: Format.sanitize(user.name),
      iconLeft: 'fa-user',
      value: () => {
        existingFilter ? exports.remove(existingFilter) : FilterModel.addFixture(userFixtureType, user);
        AutocompleteController.updateItems(exports.getUserAutocompleteFilterItems());
      }
    };
  }));
};
exports.addEstimateFilter = function () {
  const dropdown = Utils.getModelFromContext(this);
  const value = Utils.data(this, 'filter-value');
  FilterModel.addFixture('hasEstimate', value);
  DropdownController.close(dropdown);
  return false;
};
exports.applyQuickFilter = function () {
  const dropdown = Utils.getModelFromContext(this);
  const filterName = Utils.data(this, 'filter-name');
  FilterModel.addFixture(filterName);
  DropdownController.close(dropdown);
  return false;
};
exports.openFilterTypeDropdown = function () {
  const filterType = FilterModel.getFilterType();
  const dropdownActions = [{
    name: 'OR Filtering',
    note: 'Good for broad searches, such as "show me all stories tagged mockup or design".',
    className: filterType === 'OR' ? 'active' : '',
    value: exports.setFilterTypeToOr
  }, {
    name: 'AND Filtering',
    note: 'Good for narrow searches, such as "show me all bugs that are owned by me".',
    className: filterType === 'AND' ? 'active' : '',
    value: exports.setFilterTypeToAnd
  }];
  DropdownController.open({
    className: 'filter-type historical-detail-dropdown',
    target: this,
    width: 254,
    items: dropdownActions,
    showCloseButton: false
  });
};
exports.contextMenuItems = function () {
  const filter = Utils.getModelFromContext(this);
  return [{
    name: 'Inverse',
    iconLeft: 'fa-exclamation-circle',
    value: () => {
      FilterModel.update({
        id: filter.id,
        inverse: !filter.inverse
      });
      Event.trigger('spaceUpdate');
      if (filter.active) {
        StoriesView.resetAndDrawStories();
      }
      return false;
    }
  }, {
    name: 'Remove',
    iconLeft: 'fa-times',
    value: () => {
      FilterModel.remove({
        id: filter.id
      });
      Event.trigger('spaceUpdate');
      if (filter.active) {
        StoriesView.resetAndDrawStories();
      }
      ContextMenuController.close();
      return false;
    }
  }];
};
exports.init = () => {
  const NS = '.FilterController';
  exports.updateFilterTypeUI();
  FilterModel.on('added updated moved removed flushed bulkEnd', () => {
    exports.render();
  });
  Event.onlyOn('labelFiltered' + NS, labelElement => {
    exports.byLabel.call(labelElement);
  });
  Event.onlyOn('spaceLoaded' + NS, () => {
    exports.updateFilterTypeUI();
  });
  Event.on('orgDataFetched', exports.render);

  // The above is bound too late in cases where we're loading a
  // space with filters on page load, so let's render immediately.
  exports.render();
};
exports.filterSidebarTooltip = function () {
  const filter = Utils.getModelFromContext(this);
  return FilterSidebarNameTemplate.render({
    ...filter,
    key: filter.value + '_tooltip',
    hideCheck: true,
    hideExclamation: true
  });
};
exports.getFilterName = () => FilterModel.getFilterType() === 'AND' ? 'And' : 'Or';
exports.renderDownChevron = () => {
  return View.renderComponentDelayed({
    componentKey: 'quickFilterTypeButton',
    component: _jsx(Icon, {
      icon: "ChevronDown"
    })
  }).html;
};
exports.renderCloseIcon = () => {
  return View.renderComponentDelayed({
    componentKey: 'remove-filters',
    component: _jsx(Icon, {
      icon: "Close"
    })
  }).html;
};
export { exports as default };