import "core-js/modules/esnext.set.difference.v2.js";
import "core-js/modules/esnext.set.intersection.v2.js";
import "core-js/modules/esnext.set.is-disjoint-from.v2.js";
import "core-js/modules/esnext.set.is-subset-of.v2.js";
import "core-js/modules/esnext.set.is-superset-of.v2.js";
import "core-js/modules/esnext.set.symmetric-difference.v2.js";
import "core-js/modules/esnext.set.union.v2.js";
import * as MiroIframeTemplate from 'app/client/core/views/templates/miroIframe.html';
import Is from '../modules/is';
import ApplicationState from '../modules/applicationState';
import { DEFAULT_BOARD_TITLE, DEFAULT_SHOW_PREVIEW_VALUE, SHOW_PREVIEW_STATE_KEY, formatMiroBoardURL, formatMiroEmbedURL, getMiroEmbedMetaData, retrieveBoardIdFromURL } from '@clubhouse/shared/utils/miroLiveEmbed';
import { EVENTS, logEvent } from 'utils/monitoring';
import Url from 'app/client/core/js/modules/url';
const exports = {};
const COLLAPSED_CLASS = 'collapsed';
const alreadySentLogEvents = new Set();
const singleTimeLog = (key, event, extraProps) => {
  if (alreadySentLogEvents.has(key)) {
    return;
  }
  alreadySentLogEvents.add(key);
  setTimeout(() => alreadySentLogEvents.delete(key), 3000);
  logEvent(event, extraProps);
};
exports.shouldShowPreview = () => {
  // Too many embeds crashes mobile Safari. See https://app.shortcut.com/internal/story/185881/stories-with-a-lot-of-attachments-external-links-comments-cause-shortcut-to-crash-on-a-mobile-device
  if (Is.mobile()) return false;
  const showPreview = ApplicationState.get(SHOW_PREVIEW_STATE_KEY);
  return showPreview ?? DEFAULT_SHOW_PREVIEW_VALUE;
};
exports.render = (url, source = 'unknown') => {
  const boardURL = formatMiroEmbedURL(url);
  const showPreviews = exports.shouldShowPreview();
  const boardId = retrieveBoardIdFromURL(boardURL);
  if (showPreviews) {
    singleTimeLog(`unfurl:${source}:${boardId}`, EVENTS.Miro_Unfurled, {
      pageName: Url.getPageFromCurrentPathname(),
      source
    });
  }
  return MiroIframeTemplate.render({
    url: boardURL,
    iframeSrc: formatMiroBoardURL(url),
    source,
    showPreviews,
    hideToggle: Is.mobile(),
    boardId
  });
};
exports.renderEmbedTitle = url => {
  const boardId = retrieveBoardIdFromURL(url);
  getMiroEmbedMetaData(url).then(metaData => {
    document.querySelectorAll(`.title-link.${boardId}`).forEach(el => el.textContent = metaData.title);
  }).catch(() => {
    document.querySelectorAll(`.title-link.${boardId}`).forEach(el => el.textContent = DEFAULT_BOARD_TITLE);
  });
};
exports.togglePreviews = function () {
  const originalValue = exports.shouldShowPreview();
  const newValue = !originalValue;
  ApplicationState.set(SHOW_PREVIEW_STATE_KEY, newValue);
  const source = $(this).data('source') ?? 'unknown';
  singleTimeLog(`unfurl-toggled:${source}`, EVENTS.Miro_Unfurling_Toggled, {
    pageName: Url.getPageFromCurrentPathname(),
    changedFrom: originalValue,
    changedTo: newValue
  });
  const miroIframeElems = document.querySelectorAll('.miro-container .miro-iframe');
  miroIframeElems.forEach(el => {
    if (newValue) {
      el.classList.remove(COLLAPSED_CLASS);
      const elSource = $(el).data('source') ?? 'unknown';
      const elBoardId = $(el).data('board-id');
      singleTimeLog(`unfurl:${elSource}:${elBoardId}`, EVENTS.Miro_Unfurled, {
        pageName: Url.getPageFromCurrentPathname(),
        source: elSource
      });
    } else {
      el.classList.add(COLLAPSED_CLASS);
    }
  });
  const togglePreviewElems = document.querySelectorAll('.toggle-preview-link > span.fa');
  togglePreviewElems.forEach(el => {
    if (newValue) {
      el.classList.add('fa-caret-down');
      el.classList.remove('fa-caret-right');
    } else {
      el.classList.add('fa-caret-right');
      el.classList.remove('fa-caret-down');
    }
  });
  return false;
};
export { exports as default };