import BaseUtils from 'app/client/core/js/_frontloader/baseUtils';
import SignupModel from 'app/client/core/js/models/signup';
export const fetch = BaseUtils.promisify(SignupModel.fetch);
export const fetchAnyMatchingDomains = signupPublicId => SignupModel.Promises.fetchAnyMatchingDomains(signupPublicId);
export const fetchPendingInvites = signupPublicId => SignupModel.Promises.fetchPendingInvites(signupPublicId);

/**
 * Be careful, they are two `registerUser` functions that exist. One is for signup and the other one is for invite.
 * The return type depends on the function that is being called, but also the endpoint passed to it.
 */
export const registerUser = (signupPublicId, endpoint, data) => SignupModel.Promises.registerUser(signupPublicId, endpoint, data);
export const deleteSignup = (...props) => SignupModel.Promises.deleteSignup(...props);