import "core-js/modules/es.array.push.js";
import UploaderController from 'app/client/core/js/controllers/uploader.js';
window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || {
    Controller: {},
    Model: {}
  };
  [[['Controller', 'Uploader'], UploaderController], [['Controller', 'Uploader'], UploaderController]].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});
import { Avatar } from '@clubhouse/shared/components/Avatar';
import { Button } from '@clubhouse/shared/components/Button';
import { SizedIcon } from '@clubhouse/shared/components/SizedIcon';
import { Spaced } from '@clubhouse/shared/components/Spaced';
import { Heading } from '@clubhouse/shared/components/Typography/Heading';
import { Text } from '@clubhouse/shared/components/Typography/Text';
import { HelpMessage } from 'components/shared/HelpMessage';
import { useCurrentProfile } from 'data/entity/profile';
import { useCurrentUser } from 'data/entity/user';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export function IconUploader() {
  const user = useCurrentUser();
  const profile = useCurrentProfile();
  const displayIcon = profile?.display_icon || user.display_icon;
  return _jsx("div", {
    id: "edit-profile-icon-form",
    className: "is-drop-target",
    "data-icon": true,
    "data-icon-entity": "user",
    "data-icon-type": "user",
    "data-aspect-ratio": "1",
    "data-width": "300",
    "data-height": "300",
    "data-uploader-note": "For best results, we recommended the image to be at least 300px by 300px.",
    style: {
      position: 'relative'
    },
    children: _jsxs(Spaced, {
      vertically: true,
      amount: "space4",
      children: [_jsx(Heading, {
        el: "h3",
        size: Heading.SIZE.XSMALL,
        children: "Profile Icon"
      }), _jsx("div", {
        className: "user-display-logo",
        children: displayIcon ? _jsxs(Spaced, {
          vertically: true,
          amount: "space1",
          children: [_jsx("img", {
            src: displayIcon.url,
            alt: user.name,
            style: {
              width: 150,
              height: 150
            }
          }), _jsx(Button, {
            kind: Button.KIND.SECONDARY,
            size: Button.SIZE.SMALL,
            fit: Button.FIT.MEDIUM,
            "data-on-click": "deleteIcon",
            IconLeft: () => _jsx(SizedIcon, {
              icon: "Trash",
              size: 16
            }),
            children: "Delete"
          })]
        }) : _jsx(HelpMessage.Container, {
          kind: HelpMessage.colors.info,
          children: _jsxs(Spaced, {
            horizontally: true,
            amount: "space2",
            align: "center",
            children: [_jsx(Avatar, {
              profile: profile,
              size: Avatar.SIZE.XL
            }), "Until you upload your own profile icon, we'll use your initials."]
          })
        })
      }), _jsx(Text, {
        size: Text.SIZE.MEDIUM,
        children: "Customize how people see you. You can either drag and drop a file into this dialog or click the button below to upload."
      }), _jsxs("div", {
        children: [_jsx(Button, {
          kind: Button.KIND.SECONDARY,
          IconLeft: () => _jsx(SizedIcon, {
            icon: "Upload",
            size: 16
          }),
          "data-on-click": "openChooser",
          children: "Upload Icon..."
        }), _jsx("div", {
          className: "hidden",
          children: _jsx("input", {
            type: "file",
            name: "file",
            className: "html-file-upload",
            "data-controller": "Uploader",
            "data-on-change": "uploadFiles",
            "data-on-click": ""
          })
        })]
      })]
    })
  });
}
IconUploader.displayName = "IconUploader";