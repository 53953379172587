import BaseUtils from '../_frontloader/baseUtils';
import Collection from '../_frontloader/collection';
import Backend from '../modules/backend';
import _ from 'lodash';
const exports = {};

/*

Webhook entity:

{
  created_at: "2015-09-11T17:20:17Z"
  disabled: false
  id: "55f30d51-8eb8-4370-8070-f4cb9d3e1f46"
  name: "GitHub"
  type: "github"
  updated_at: "2015-11-10T16:24:10Z"
  url: "https://hooks.clubhouse.io/hooks/v1/[uuid]/[uuid]"
}

*/

Collection.create('Webhook', exports);
exports.isValid = obj => {
  return obj && obj.id && obj.url;
};
exports.getNameForID = id => {
  const webhook = exports.getById(id);
  return webhook ? webhook.name : '';
};
exports.getBitbucket = () => {
  return exports.get({
    type: 'bitbucket'
  });
};
exports.getCalendar = () => {
  return exports.get({
    type: 'ical'
  });
};
exports.getCalendarURL = () => {
  return _.get(exports.getCalendar(), 'url', '');
};
exports.fetchAll = callback => {
  Backend.get('/api/private/webhooks', {
    onComplete: res => {
      exports.defaultFetchAllHandler(res, callback);
    }
  });
};
exports.create = (data, callback) => {
  Backend.post('/api/private/webhooks', {
    data,
    onComplete: res => {
      exports.defaultGetHandler(res, callback);
    }
  });
};
exports.regenerate = (webhook, callback) => {
  Backend.put('/api/private/webhooks/' + webhook.id + '/regenerate', {
    onComplete: res => {
      exports.defaultGetHandler(res, callback);
    }
  });
};
exports.enableWebhook = (webhook, callback) => {
  callback = _.isFunction(callback) ? callback : _.noop;
  Backend.put('/api/private/webhooks/' + webhook.id + '/enable', {
    onComplete: (res, xhr) => {
      if (xhr.status === 204) {
        webhook.disabled = false;
        exports.update(webhook);
        callback(null);
      } else {
        exports.defaultErrorHandler(res, callback);
      }
    }
  });
};
exports.disableWebhook = (webhook, callback) => {
  callback = _.isFunction(callback) ? callback : _.noop;
  Backend.put('/api/private/webhooks/' + webhook.id + '/disable', {
    onComplete: (res, xhr) => {
      if (xhr.status === 204) {
        webhook.disabled = true;
        exports.update(webhook);
        callback(null);
      } else {
        exports.defaultErrorHandler(res, callback);
      }
    }
  });
};
exports.Promises = {};
exports.Promises.fetchAll = BaseUtils.promisify(exports.fetchAll);
exports.Promises.create = BaseUtils.promisify(exports.create);
exports.Promises.disableWebhook = BaseUtils.promisify(exports.disableWebhook);
exports.Promises.enableWebhook = BaseUtils.promisify(exports.enableWebhook);
export { exports as default };