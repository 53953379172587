import "core-js/modules/es.array.push.js";
import Async from '../../../core/js/modules/async';
import _ from 'lodash';
const exports = {};
exports.scrollToAndFlashStory = (storyElement, container) => {
  return _animateScrollToStoryElement(storyElement, container).then(() => {
    return _flashStory(storyElement);
  });
};
function _animateScrollToStoryElement(element, container) {
  _.times(30, n => {
    setTimeout(() => {
      _scrollToStoryElement(element, container);
    }, n * 10);
  });
  return new Promise(resolve => {
    setTimeout(resolve, 30 * 10);
  });
}
function _scrollToStoryElement(element, container) {
  container = container || $(element).closest('.column-bucket');
  if (element.length === 0 || container.length === 0) {
    return false;
  }
  const columnHeight = container.height();
  const storyHeight = $(element).height();
  const topPadding = Math.max(80, (columnHeight - storyHeight) * 0.4);
  const easing = 0.25;
  const columnOffsetTop = container.offset().top;
  const currentElementOffsetTop = $(element).offset().top;
  const currentScrollTop = container.scrollTop();
  const scrollOffset = currentElementOffsetTop - columnOffsetTop - topPadding;
  container.scrollTop(currentScrollTop + scrollOffset * easing);
}
function _flashStory(element) {
  return new Promise(resolve => {
    if (element.length === 0) {
      resolve();
    }
    _flashStoryElement(element, resolve);
  });
}
function _flashStoryElement(element, callback, times) {
  const speed = 120;
  const flashClass = 'story-is-flashing';
  callback = _.isFunction(callback) ? callback : _.noop;
  times = times || 3;
  function flashOrange(next) {
    $(element).addClass(flashClass);
    setTimeout(next, speed);
  }
  function flashOriginalColor(next) {
    $(element).removeClass(flashClass);
    setTimeout(next, speed);
  }
  const fnArray = [];
  _.times(times, () => {
    fnArray.push(flashOrange);
    fnArray.push(flashOriginalColor);
  });
  Async.eachInSequenceThen(fnArray, callback);
}
export { exports as default };