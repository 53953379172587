import { useFilters } from './useFilters';
import { NO_GROUP_ID } from 'components/groups/GroupFilterDropdown';
import { useTeamScopedTeam } from 'data/entity/group';
import { tokenize } from 'utils/format';
export function useFilteredIterations(iterations) {
  const {
    filters,
    clearFilters
  } = useFilters();
  const {
    query,
    statuses,
    groupIds,
    showAssociated
  } = filters;
  const teamId = useTeamScopedTeam()?.id;
  const group_ids = teamId ? [teamId] : groupIds;
  const tokens = (query ? tokenize(query) : []).map(t => t.toLowerCase());
  const filteredIterations = iterations.filter(iteration => {
    if (statuses.length !== 0 && statuses.every(s => iteration.status !== s)) return false;
    if (tokens.length !== 0 && tokens.some(token => !iteration.name.toLowerCase().includes(token))) return false;
    const matchesNoGroup = !iteration.group_ids?.length && group_ids.includes(NO_GROUP_ID);
    const iterationGroupIds = showAssociated ? [...(iteration.associated_groups || []).map(({
      group_id
    }) => group_id), ...iteration.group_ids] : iteration.group_ids;
    if (group_ids.length !== 0 && group_ids.every(g => !iterationGroupIds.includes(g)) && !matchesNoGroup) return false;
    return true;
  });
  return {
    filters,
    filteredIterations,
    clearAllFilters: clearFilters
  };
}