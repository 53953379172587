import { useEffect, useRef } from 'react';
import { useToggleState } from './useToggleState';
import { useLatestRef } from './useLatestRef';
export function useTimer(callback, {
  startOnMount = true,
  timeoutMs = 1_000
} = {}) {
  const [running, toggleRunning] = useToggleState(startOnMount);
  const timeoutIdRef = useRef();
  const callbackRef = useLatestRef(callback);
  useEffect(() => {
    const reset = () => clearTimeout(timeoutIdRef.current);
    const run = () => {
      timeoutIdRef.current = setTimeout(() => {
        callbackRef.current();
        run();
      }, timeoutMs);
    };
    reset();
    if (running) run();
    return reset;
  }, [callbackRef, timeoutMs, running]);
  return {
    isRunning: running,
    stop: toggleRunning.off,
    start: toggleRunning.on
  };
}