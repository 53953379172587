import { StatusIcon } from '@useshortcut/shapes-ds';
import { WORKFLOW_STATE_TYPES } from '@clubhouse/shared/constants';
import { DeprecatedIconAdapter } from '../Icons';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const mapStateType = {
  [WORKFLOW_STATE_TYPES.UNSTARTED]: 'unstarted',
  [WORKFLOW_STATE_TYPES.STARTED]: 'started',
  [WORKFLOW_STATE_TYPES.DONE]: 'done'
};
const ICON = {
  unstarted: 'Unstarted',
  started: 'Started',
  done: 'Done'
};
export const EpicStateIcon = ({
  type,
  size = 24
}) => {
  const IconName = ICON[type];
  return _jsx("div", {
    children: _jsx(DeprecatedIconAdapter, {
      width: size,
      children: _jsx(StatusIcon, {
        icon: IconName
      })
    })
  });
};
EpicStateIcon.displayName = "EpicStateIcon";
export const EpicIcon = ({
  epic,
  size = 24
}) => {
  const {
    type
  } = epic?.stateObject || {};
  if (!type) return null;
  return _jsx(EpicStateIcon, {
    type: mapStateType[type],
    size: size
  });
};
EpicIcon.displayName = "EpicIcon";