import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export function IntegrationStatus({
  isEnabled,
  subject = 'This integration'
}) {
  return _jsxs("div", {
    className: "integration-status-text",
    children: [subject, " is", ' ', isEnabled ? _jsx("span", {
      className: "integration-status integration-status-active",
      children: "enabled"
    }) : _jsx("span", {
      className: "integration-status",
      children: "disabled"
    }), ' ', "for your workspace."]
  });
}
IntegrationStatus.displayName = "IntegrationStatus";