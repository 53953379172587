import { resetPageCSS } from 'app/client/core/js/modules/resetPageCSS';
import css from 'app/client/dashboard/less/app.less';
import * as Event from '../../../core/js/_frontloader/event';
import NotificationsController from '../../../core/js/controllers/notifications';
import StoryModel from '../../../core/js/models/story';
import TaskModel from '../../../core/js/models/task';
import UserModel from '../../../core/js/models/user';
import ApplicationState from '../../../core/js/modules/applicationState';
import Async from '../../../core/js/modules/async';
import Boot from '../../../core/js/modules/boot';
import ConsolidatedFetch from '../../../core/js/modules/consolidatedFetch';
import Layout from '../../../core/js/modules/layout';
import Updates from '../../../core/js/modules/updates';
import Url from '../../../core/js/modules/url';
import View from '../../../core/js/modules/view';
import DashboardPageController from '../controllers/dashboardPage';
const exports = {};
const _init = () => {
  exports._init();
  exports._fetchDataAndRender();
};
const _reInit = () => {
  exports._init();
  exports._fetchPageSpecificData(() => {
    _renderIfStillOnDashboardPage();
  });
};
exports._init = () => {
  Layout.renderContent('<div id="dashboard" class="page-content"></div>');
  ApplicationState.setLastView(Url.getSlugPath() + '/dashboard');
  Updates.init(exports._handleUpdates);
  DashboardPageController.init();
  Event.on('resize storyEditorAdded', View.genericResizeHandler);
  Event.trigger('resize');
  if (Url.parseLocationSearch()?.showShortcutUniversity) {
    exports._fetchDataAndRender(() => {
      DashboardPageController.render();
    });
  } else if (Url.getCurrentPage() === 'training') {
    exports._fetchDataAndRender(() => {
      DashboardPageController.render();
    });
  }
};
exports._fetchDataAndRender = (callback = () => {}) => {
  ConsolidatedFetch.fetchConsolidatedDataAndActivity(() => {
    exports._fetchPageSpecificData(() => {
      _renderIfStillOnDashboardPage();

      // We render an empty list at first.
      NotificationsController.fetchAndRenderInitialActivity();
      callback();
    });
  });
};
exports._fetchPageSpecificData = callback => {
  Async.eachInParallelThen([UserModel.fetchActiveStoriesForCurrentUser, UserModel.fetchRecentlyCompletedStoriesForCurrentUser, StoryModel.fetchStoriesApproachingDuedate, TaskModel.fetchCurrentUserActiveTasks], () => {
    TaskModel.fetchMissingStoriesForTasks(callback);
  });
};
exports._handleUpdates = () => {
  // We already get story updates from the /updates endpoint.
  TaskModel.fetchCurrentUserActiveTasks(() => {
    TaskModel.fetchMissingStoriesForTasks(() => {
      _renderIfStillOnDashboardPage();
    });
  });
};
function _renderIfStillOnDashboardPage() {
  if (Url.getCurrentPage() === 'dashboard') {
    DashboardPageController.render();
  }
}
exports.boot = async () => {
  await resetPageCSS(css);
  Boot.initializePage(_init, _reInit);
};
export { exports as default };