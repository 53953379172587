import { Icon } from '@useshortcut/shapes-ds';
import { COLORS } from '@clubhouse/shared/colors';
import { ICON_DEFAULT_WIDTH } from '../../utils/icon';
import { DeprecatedIconAdapter } from './DeprecatedIconAdapter';

/**
 * @deprecated Use <Icon icon="Feature" /> from @useshortcut/shapes-ds
 */
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const Certificate = ({
  fill = COLORS.YELLOW45,
  width = ICON_DEFAULT_WIDTH
}) => {
  return _jsx(DeprecatedIconAdapter, {
    fill: fill,
    width: width,
    children: _jsx(Icon, {
      icon: "Feature"
    })
  });
};
Certificate.displayName = "Certificate";