import { useAsyncFn, usePromise } from 'react-use';
import { Tooltip } from '.';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export function AsyncTooltip({
  fetchContent,
  children,
  delay = 100,
  ...rest
}) {
  const mounted = usePromise();
  const [content, fetchTooltipContent] = useAsyncFn(async () => mounted(fetchContent()), [mounted, fetchContent]);
  return _jsx(Tooltip, {
    content: content.value ?? null,
    onHover: fetchTooltipContent,
    delay: delay,
    ...rest,
    children: children
  });
}
AsyncTooltip.displayName = "AsyncTooltip";