import "core-js/modules/esnext.set.difference.v2.js";
import "core-js/modules/esnext.set.intersection.v2.js";
import "core-js/modules/esnext.set.is-disjoint-from.v2.js";
import "core-js/modules/esnext.set.is-subset-of.v2.js";
import "core-js/modules/esnext.set.is-superset-of.v2.js";
import "core-js/modules/esnext.set.symmetric-difference.v2.js";
import "core-js/modules/esnext.set.union.v2.js";
import memoize from 'lodash/memoize';
import { useMemo } from 'react';
import { COLORS } from '@clubhouse/shared/colors';
import { OpinionatedFieldType } from '@clubhouse/shared/types';
import CustomFieldModel, { CUSTOM_FIELD_SEARCH_OPERATORS } from 'app/client/core/js/models/customField';
import { useEntities, useEntity } from './collection';
export const getAllEnabled = () => CustomFieldModel.all().filter(({
  enabled
}) => enabled);
export const move = (oldIndex, newIndex) => CustomFieldModel.move(oldIndex, newIndex);
export const useCustomFields = ({
  filter
} = {}) => {
  const {
    entities,
    diff
  } = useEntities({
    model: CustomFieldModel
  });
  return {
    fields: filter ? entities.filter(filter) : entities,
    diff,
    all: entities
  };
};
export const useCustomField = id => {
  const {
    entity
  } = useEntity({
    model: CustomFieldModel,
    id
  });
  return {
    entity
  };
};
export const updateCustomField = async (id, changes) => CustomFieldModel.Promises.put(id, changes);
export const deleteCustomField = id => CustomFieldModel.Promises.delete(id);
export const createCustomField = async data => CustomFieldModel.Promises.create(data);
export const getValueIdsByFieldId = storyCustomFields => {
  return storyCustomFields.reduce((acc, {
    field_id,
    value_id
  }) => {
    acc[field_id] = value_id;
    return acc;
  }, {});
};
export const useCustomFieldOperators = () => {
  const {
    fields
  } = useCustomFields({
    filter: ({
      enabled
    }) => enabled
  });
  const operators = useMemo(() => fields.filter(({
    canonical_name
  }) => !!CUSTOM_FIELD_SEARCH_OPERATORS[canonical_name]).map(({
    canonical_name
  }) => CUSTOM_FIELD_SEARCH_OPERATORS[canonical_name]), [fields]);
  return [operators];
};

/**
 * Use this if you want to access canonical/built-in custom fields only.
 *
 * Inspired from `useCustomFieldOperators` as it only returns the canonical fields,
 * but doesn't return the operators.
 */
export const useCanonicalCustomFields = () => {
  const {
    fields
  } = useCustomFields({
    filter: ({
      enabled
    }) => enabled
  });
  const canonicalFields = useMemo(() => fields.filter(({
    canonical_name
  }) => !!CUSTOM_FIELD_SEARCH_OPERATORS[canonical_name]), [fields]);
  return [canonicalFields];
};
export const groupValueIdsByFieldId = (valueIds, noneValue) => {
  const fields = CustomFieldModel.all();
  const valueIdsByFieldId = {};
  const addFieldValue = (id, value) => {
    if (!valueIdsByFieldId[id]) {
      valueIdsByFieldId[id] = new Set();
    }
    valueIdsByFieldId[id].add(value);
  };
  for (const field of fields) {
    if (valueIds.includes(`NONE:${field.id}`)) {
      addFieldValue(field.id, noneValue ?? null);
    }
    for (const value of field.values) {
      if (!valueIds.includes(value.id)) continue;
      addFieldValue(field.id, value.id);
    }
  }
  return valueIdsByFieldId;
};

// Used to process output of CustomFieldsSelect
export const storyMatchesFilter = (story, valueIds) => {
  if (valueIds.includes('NONE')) {
    // If we have the top level "No Fields or Values" option selected, then we want to match any
    // story that has no fields or values, regardless of any other filters that might be selected.
    // That option acts as an "OR" with the other filters basically. This is pretty weird behavior,
    // but it's just how all the other filters work so we're going with it.
    if (story.custom_fields.length === 0) return true;

    // Next, if that "No Fields or Values" option is the ONLY option selected, and there ARE some
    // fields set on this story, this story should NOT match.
    if (valueIds.length === 1) return false;

    // If we get here, then there's at least one additional field filter selected, so we continue on
    // to check whether all of the filtered fields match this story...
  }
  const filterValueIdsByFieldId = groupValueIdsByFieldId(valueIds);
  return fieldsMatchFilterWithOR(filterValueIdsByFieldId, story.custom_fields);
};
const fieldsMatchFilter = (AND, filterValueIdsByFieldId, storyFields) => {
  const filterEntries = Object.entries(filterValueIdsByFieldId);
  if (filterEntries.length === 0) {
    // An empty filter set matches all stories
    return true;
  }
  for (const [fieldId, valueIds] of filterEntries) {
    const storyField = storyFields.find(field => field.field_id === fieldId);

    // If there's nothing set on the story for this field, and NONE is an option...
    // OR
    // the story has a value and that value is in the filter...
    if (!storyField && valueIds.has(null) || storyField && valueIds.has(storyField.value_id)) {
      if (AND) {
        // then we move on to the next filter when ANDed
        continue;
      } else {
        // otherwise, return true as we want to short circuit because we are ORing
        return true;
      }
    }

    // Otherwise this field is NOT a match and we short circuit and return false
    // when ANDed
    if (AND) {
      return false;
    }
  }

  // If we make it this far, it means that all filters matched when we are
  // ANDing (so the story matches), but no filters matched when we are ORing (so
  // the story does not match)
  return AND;
};

// Returns true if the given storyFields match ALL of the given filter records.
// Used on the legacy story page.
//
// On that page, we expect the filters to be ANDed, and provide all of the custom
// field values and NONE for all custom field values by default. If a user
// selects a single value for a single field, we send just that value for that
// field, and all values (plus NONE) for other fields, so the user will see
// stories that have that single value set, regardless of the other fields the
// stories have. If they set another field filter to a single value, they should
// see all stories that have *both* (not *either*) of the single values set,
// regardless of the other fields the stories have.
export const fieldsMatchFilterWithAND = (filterValueIdsByFieldId, storyFields) => fieldsMatchFilter(true, filterValueIdsByFieldId, storyFields);

// Returns true if the given storyFields match ANY of the given filter records.
// Used by storyMatchFilter above, which is used on the status page, reports page, and
// story tables.
//
// On those pages, filtering works differently than the legacy stories page: we
// expect the filters to be ORed, and only check filters that are explicitly
// requested (we don't include filters for all of the fields by default as we do
// on the legacy stories page).
export const fieldsMatchFilterWithOR = (filterValueIdsByFieldId, storyFields) => fieldsMatchFilter(false, filterValueIdsByFieldId, storyFields);
export const NO_VALUE_TINT = COLORS.GRAY70;
export const NEW_FIELD_ID = 'create';
export const getEnabledFields = () => CustomFieldModel.getEnabledFields();
export const getById = id => CustomFieldModel.getById(id);
export const areCustomFieldsEnabled = () => CustomFieldModel.areCustomFieldsEnabled();
export const getValueById = (fieldId, valueId) => CustomFieldModel.getValueById(fieldId, valueId);
export const getFieldByValueId = valueId => CustomFieldModel.getFieldByValueId(valueId);
export const getCanonicalNames = () => {
  return getEnabledFields().map(field => field.canonical_name).filter(type => !!type);
};
export const getIdForCanonicalName = memoize(name => {
  const field = getEnabledFields().find(field => field.canonical_name === name);
  return field?.id;
});
export const getNormalizedStoryCustomFields = story => {
  if (story.story_type && story.story_type !== 'bug' && story.custom_fields) {
    const severityFieldId = getIdForCanonicalName(OpinionatedFieldType.SEVERITY);
    return story.custom_fields.filter(({
      field_id
    }) => field_id !== severityFieldId);
  }
  return story.custom_fields ?? [];
};
export const fetchAll = () => CustomFieldModel.Promises.fetchAll();