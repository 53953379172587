import { development as envDevelopment } from 'app/client/core/js/modules/env';
import { logError } from 'utils/monitoring';
export { processError } from 'utils/monitoring';
export function debug(...args) {
  if (envDevelopment()) {
    window.console.debug(...args);
  }
}
export function log(...args) {
  if (envDevelopment()) {
    window.console.log(...args);
  }
}
export function warn(...args) {
  if (envDevelopment()) {
    window.console.warn(...args);
  }
}
export const error = logError;