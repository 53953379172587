import { default as lodashGroupBy } from 'lodash/groupBy';
import { getGroupSort } from './getGroupSort';
export function createGroups({
  entities,
  groupBy
}) {
  const entitiesByKey = lodashGroupBy(entities, groupBy.getKey);
  const groupsByKey = Object.entries(entitiesByKey).reduce((groupsByKey, [groupKey, items]) => {
    const group = {
      items,
      Component: groupBy.getDisplay(items[0], items, entities)
    };
    groupsByKey.set(groupKey, group);
    return groupsByKey;
  }, new Map());
  const groupSort = getGroupSort(groupBy);
  return [...groupsByKey.entries()].sort(([, group1], [, group2]) => {
    const firstItem1 = group1.items[0];
    const firstItem2 = group2.items[0];
    return groupSort(firstItem1, firstItem2);
  });
}