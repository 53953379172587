import { ICON_DEFAULT_WIDTH } from '../../utils/icon';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const Circle = ({
  width = ICON_DEFAULT_WIDTH,
  fill
}) => _jsx("svg", {
  fill: fill,
  width: width,
  height: width,
  viewBox: "0 0 100 100",
  children: _jsx("circle", {
    clipPath: "circle()",
    r: "50",
    cx: "50",
    cy: "50"
  })
});
Circle.displayName = "Circle";