import EstimateScaleModel from '../models/estimateScale';
import MilestoneModel from '../models/milestone';
import * as GroupData from 'data/entity/group';
import Utils from '../modules/utils';
import * as sort from 'utils/sort';
import _ from 'lodash';
const exports = {};
exports.STORY_COLUMNS = {
  ID: 'id',
  Type: (a, b) => {
    const weights = {
      feature: 3,
      bug: 2,
      chore: 1
    };
    return weights[b.story_type] - weights[a.story_type];
  },
  Name: sort.name,
  'Story Name': sort.name,
  Project: sort.storyProject,
  Epic: (a, b) => {
    const epicName1 = (a.epic || {
      name: ''
    }).name.toLowerCase();
    const epicName2 = (b.epic || {
      name: ''
    }).name.toLowerCase();
    if (epicName1 < epicName2) {
      return -1;
    }
    if (epicName1 > epicName2) {
      return 1;
    }
    return 0;
  },
  State: (a, b) => a.position > b.position ? 1 : -1,
  'Cycle Time': (a, b) => {
    if (a.cycle_time && !b.cycle_time) {
      return -1;
    } else if (!a.cycle_time && b.cycle_time) {
      return 1;
    }
    return a.cycle_time > b.cycle_time ? -1 : 1;
  },
  Owners: sort.owners,
  Priority: sort.position,
  Points: 'estimate',
  Modified: 'updated_at',
  Completed: 'completed_at',
  Created: 'created_at',
  Team: GroupData.byGroupNameSortFn
};
exports.EPIC_COLUMNS = {
  ID: 'id',
  State: sort.stateOrPosition,
  Name: sort.name,
  'Epic Name': sort.name,
  Owners: sort.owners,
  Stories: sort.totalStories,
  Points: sort.points,
  Docs: sort.docs,
  Priority: sort.position,
  Progress: sort.epicProgressByStories,
  'Start Date': sort.epicStartDate,
  'Due Date': sort.epicDueDate,
  'Avg Cycle Time': sort.epicAvgCycleTime,
  Milestone: sort.epicMilestone,
  Modified: sort.modified,
  Team: GroupData.byGroupNameSortFn,
  Created: 'created_at'
};
exports.MILESTONE_COLUMNS = {
  Priority: sort.position,
  ID: exports.EPIC_COLUMNS.ID,
  Name: sort.name,
  Epics: (a, b) => {
    if (a.epic_ids.length === b.epic_ids.length) {
      return exports.MILESTONE_COLUMNS.Modified(a, b) * -1;
    }
    return a.epic_ids.length > b.epic_ids.length ? -1 : 1;
  },
  Stories: (a, b) => {
    if (a.num_stories_total === b.num_stories_total) {
      return exports.MILESTONE_COLUMNS.Modified(a, b) * -1;
    }
    return a.num_stories_total > b.num_stories_total ? -1 : 1;
  },
  Docs: (a, b) => {
    const aStats = a.stats || {};
    const bStats = b.stats || {};
    if (aStats.num_related_documents === bStats.num_related_documents) {
      return exports.MILESTONE_COLUMNS.Modified(a, b) * -1;
    }
    return aStats.num_related_documents > bStats.num_related_documents ? -1 : 1;
  },
  Points: exports.EPIC_COLUMNS.Points,
  Progress: (a, b) => {
    if (a.num_stories_total === 0 && b.num_stories_total > 0) {
      return 1;
    } else if (b.num_stories_total === 0 && a.num_stories_total > 0) {
      return -1;
    }
    if (EstimateScaleModel.isUsingPoints()) {
      if (a.percent_completed_points === b.percent_completed_points) {
        if (a.percent_active_points === b.percent_active_points) {
          return exports.MILESTONE_COLUMNS.Stories(a, b);
        }
        return a.percent_active_points > b.percent_active_points ? -1 : 1;
      }
      return a.percent_completed_points > b.percent_completed_points ? -1 : 1;
    } else {
      if (a.percent_completed === b.percent_completed) {
        if (a.percent_active === b.percent_active) {
          return exports.MILESTONE_COLUMNS.Stories(a, b);
        }
        return a.percent_active > b.percent_active ? -1 : 1;
      }
      return a.percent_completed > b.percent_completed ? -1 : 1;
    }
  },
  State: (a, b) => {
    const orderA = MilestoneModel.NATURAL_SORT_ORDER[a.state];
    const orderB = MilestoneModel.NATURAL_SORT_ORDER[b.state];
    if (orderA === orderB) {
      return a.position > b.position ? 1 : -1;
    }
    return orderA > orderB ? -1 : 1;
  },
  Modified: sort.modified,
  Created: 'created_at'
};
exports.DATE_COLUMNS = ['Modified', 'Created'];
exports.sortEpics = (epics, sortBy, sortDirection) => {
  return _sortEntities(epics, sortBy, sortDirection, exports.EPIC_COLUMNS);
};
exports.sortMilestones = (milestones, sortBy, sortDirection) => {
  return _sortEntities(milestones, sortBy, sortDirection, exports.MILESTONE_COLUMNS);
};
exports.sortStories = (stories, sortBy, sortDirection) => {
  return _sortEntities(stories, sortBy, sortDirection, exports.STORY_COLUMNS);
};
function _sortEntities(entities, sortBy, sortDirection, sortingMap) {
  const shouldReverse = sortDirection === 'ascending' && _.includes(exports.DATE_COLUMNS, sortBy) || sortDirection === 'descending' && !_.includes(exports.DATE_COLUMNS, sortBy);
  Utils.sortByProperty(entities, sortingMap[sortBy]);
  if (shouldReverse) {
    entities = entities.reverse();
  }
  return entities;
}
export { exports as default };