import "core-js/modules/es.array.push.js";
import HotReloadController from 'app/client/core/js/controllers/hotReload.js';
window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || {
    Controller: {},
    Model: {}
  };
  [[['Controller', 'HotReload'], HotReloadController], [['Controller', 'HotReload'], HotReloadController]].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});
import * as ButtonSavingStateTemplate from 'app/client/core/views/templates/buttonSavingState.html';
import * as AddNewIterationActionsTemplate from 'app/client/core/views/templates/addNewIterationActions.html';
import * as AddNewIterationTemplate from 'app/client/core/views/templates/addNewIteration.html';
import { GroupDropdown } from 'components/groups/GroupDropdown';
import AddNewController from './addNew';
import Autosize from '../modules/autosize';
import Format from '../modules/format';
import Is from '../modules/is';
import IterationModel from '../models/iteration';
import MessageController from './message';
import PanelController from './panel';
import RangedDatepickerController from './rangedDatepicker';
import Utils from '../modules/utils';
import Validator from '../modules/validator';
import View from '../modules/view';
import { getTeamByTeamScopedId } from 'data/entity/group';
import { NewEntityDescriptionEditor } from 'components/shared/NewEntityDescriptionEditor';
import _ from 'lodash';
import moment from 'moment';
const exports = {};
const PANEL_ID = 'addNewIteration';
exports.LOCALSTORAGE_KEY = 'newIterationState';
exports.saving = false;
exports.onSave = _.noop;
exports.newState = () => {
  return {
    name: '',
    description: '',
    start_date: undefined,
    end_date: undefined,
    group: undefined
  };
};
exports.getState = () => {
  return AddNewController.getState(exports.LOCALSTORAGE_KEY) || exports.newState();
};
exports.getSubmitValues = () => {
  const iteration = exports.getState();
  return {
    name: iteration.name,
    description: iteration.description,
    start_date: iteration.start_date,
    end_date: iteration.end_date,
    group_ids: iteration.group ? [iteration.group.id] : []
  };
};
exports.save = () => {
  if (exports.saving) {
    return;
  }
  if (Validator.isValid('.add-new-dialog')) {
    const submitValues = exports.getSubmitValues();
    exports.saving = true;
    $('.add-new-dialog .add-new-action').html(ButtonSavingStateTemplate.render());
    IterationModel.saveNew(submitValues, (err, savedIteration) => {
      exports.saving = false;
      if (err) {
        $('.add-new-dialog .add-new-action').html(AddNewIterationActionsTemplate.render());
        MessageController.error(err, {
          secondary: `Unable to create <strong>${Format.sanitize(submitValues.name)}</strong>.`
        });
      } else {
        exports.onSave(savedIteration);
        AddNewController.removeState(exports.LOCALSTORAGE_KEY);
        PanelController.closeById(PANEL_ID);
        RangedDatepickerController.closeRangedDatepicker();
        MessageController.success(`Iteration <strong>${Format.sanitize(savedIteration.name)}</strong> created.`, {
          actions: `<a href="${IterationModel.generateURL(savedIteration)}" data-controller="HotReload" data-on-click="load" data-page="iterations" class="action mini elevated">
                <span class="fa fa-eye"></span>
                View iteration
              </a>`,
          timeout: 15000
        });
        exports.onSave = _.noop;
      }
    });
  }
};
exports.updateState = updates => {
  const state = exports.getState();
  _.assignIn(state, updates);
  AddNewController.updateState(exports.LOCALSTORAGE_KEY, state);
};
const _shouldShowWarning = () => {
  const iteration = exports.getState();
  if (iteration.end_date && moment(iteration.end_date, 'YYYY-MM-DD').diff(moment(), 'days') < 0) {
    $('.date-warning').addClass('active');
  } else {
    $('.date-warning').removeClass('active');
  }
};
exports.onKeyDownTitle = e => {
  if (Utils.keyPressed(e, ['ENTER', 'CMD+ENTER', 'CTRL+ENTER'])) {
    exports.save();
    e.preventDefault();
    return false;
  }
};
exports.openDatePicker = function (overrides) {
  const context = _.get(overrides, 'context') || $(this);
  const selected_field = context.data('binding');
  const iteration = exports.getState();
  const $start_date = $('[data-binding="start_date"] input');
  const $end_date = $('[data-binding="end_date"] input');
  RangedDatepickerController.open(this, {
    startDate: iteration.start_date,
    endDate: iteration.end_date,
    isSelectingEndDate: selected_field === 'end_date',
    onStartDateChange: date => {
      updateDateField($start_date, date);
      exports.updateState({
        start_date: date.format('YYYY-MM-DD')
      });
      updateDateField($end_date).focus();
      exports.updateState({
        end_date: undefined
      });
    },
    onEndDateChange: date => {
      updateDateField($end_date, date);
      exports.updateState({
        end_date: date.format('YYYY-MM-DD')
      });
      $start_date.focus();
    },
    showPresets: false
    // autoApply: true,
  });
  return false;
};
exports.formatDate = date => {
  return date ? moment(date).format('MMM D Y') : '';
};
exports.formatDateDay = date => {
  return date && moment(date).isValid() ? moment(date).format('ddd') : '';
};
const updateDateField = (field, date) => {
  field.val(exports.formatDate(date));
  field.siblings('.date-day').text(exports.formatDateDay(date));
  return field;
};
const _renderGroupSelect = () => {
  ({
    unmountComponent: unmountGroupDropdown
  } = View.renderComponent({
    componentKey: 'addNewIterationGroupSelect',
    mountNode: document.querySelector('#iterationGroupSelect'),
    component: GroupDropdown,
    getProps: () => {
      const props = {
        selectedGroup: exports.getState().group,
        onChange: group => {
          exports.updateState({
            group
          });
          _renderGroupSelect();
        }
      };
      return props;
    }
  }));
};
exports.renderFromContent = ({
  name,
  description,
  onSave
}) => {
  exports.updateState({
    name,
    description
  });
  if (onSave) {
    exports.onSave = onSave;
  }
  exports.render();
};
const getInitialGroupIdValue = ({
  group
}) => {
  const team = getTeamByTeamScopedId();
  return {
    group: team ?? group ?? null
  };
};
const getDefaults = state => {
  return {
    ...state,
    ...getInitialGroupIdValue(state)
  };
};
let unmountGroupDropdown = () => {};
const renderDescriptionEditorV2 = panel => {
  if (!panel.element) return;
  View.renderComponent({
    componentKey: 'addNewIterationReactMarkdownEditor',
    mountNode: panel.element.find('#iteration-description-v2')[0],
    component: NewEntityDescriptionEditor,
    props: {
      // The renderId forces a re-render of the editor with clean state.
      // `initialValue` doesn't work because it is usually an empty string (ie no change is triggered)
      renderId: String(Date.now()),
      initialValue: exports.getState()?.description || '',
      onChange: val => {
        exports.updateState({
          description: val
        });
      },
      onSave: async val => {
        exports.updateState({
          description: val
        });
        exports.save();
      }
    }
  });
};
exports.render = () => {
  exports.updateState(getDefaults(exports.getState()));
  const state = exports.getState();
  const panel = PanelController.open({
    html: AddNewIterationTemplate.render(state),
    id: PANEL_ID,
    onClose: () => {
      if (panel && panel.element) {
        const textareas = panel.element.find('textarea');
        textareas.off('keyup blur');
        Autosize.destroy(textareas);
      }
      Validator.destroy('.add-new-dialog');
      unmountGroupDropdown();
    },
    onOpen: panel => {
      if (panel && panel.element) {
        panel.element.find('textarea').first().focus();
        renderDescriptionEditorV2(panel);
      }
      _renderGroupSelect();
    },
    onScroll: Is.mobile() ? () => false : null,
    target: document.getElementById('add-new-button')
  });
  if (!panel) {
    return false;
  }
  Validator.init('.add-new-dialog');
  _shouldShowWarning();
  panel.element.find('.title-container textarea').focus().select();
  const textareas = panel.element.find('textarea');
  Autosize.bind(textareas);
  textareas.on('keyup blur', _.debounce(function () {
    if (!panel || !panel.element) {
      return;
    }
    const key = $(this).data('binding');
    const update = {
      [key]: $(this).val().trim()
    };
    exports.updateState(update);
  }, 50));
  return false;
};
export { exports as default };