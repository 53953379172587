import { Fragment } from 'react';
import { ReportHeader } from 'components/shared/reports/ReportHeader';
import { useDebounce } from '../hooks/useDebounce';
import { useRefreshKey } from '../hooks/useRefreshKey';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const ReportCard = ({
  children,
  title,
  className,
  description,
  Explanation,
  Filters = null,
  refetchChartCb,
  chartId
}) => {
  const [refreshKeys, handleRefresh] = useRefreshKey();
  const debouncedHandleRefresh = useDebounce(() => {
    if (refetchChartCb) {
      refetchChartCb();
    } else {
      handleRefresh(chartId);
    }
  }, 1000);
  return _jsxs(Fragment, {
    children: [_jsx(ReportHeader, {
      title: title,
      reportExplanation: _jsx(Explanation, {
        isEmbedded: false
      }),
      reportFilters: Filters,
      reportDescription: description,
      handleReportsRefresh: debouncedHandleRefresh,
      className: className
    }), children]
  }, refreshKeys[chartId]);
};
ReportCard.displayName = "ReportCard";