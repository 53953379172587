import { useCallback, useMemo } from 'react';
import { FilterField } from '@clubhouse/shared/components/FilterField';
import { FilterTarget } from '@clubhouse/shared/components/FilterTarget';
import { GroupedOptionsSelect, useItems } from '@clubhouse/shared/components/GroupedOptionsSelect';
import { All, Divider, None, useAsyncSelect } from '@clubhouse/shared/components/Select';
import { groupBy } from '@clubhouse/shared/utils';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const DEFAULT_MAX_FILTER_SELECTIONS = 50;
export const useFetchGroupedOptions = ({
  selectedValues,
  fetchOptions,
  maxSelected = DEFAULT_MAX_FILTER_SELECTIONS,
  getSection,
  onError,
  staticOptions,
  filterType
}) => {
  const staticItems = useMemo(() => staticOptions || [All(filterType), None(filterType), Divider('after-none')], [staticOptions, filterType]);
  const fetcher = useCallback(async ({
    inputValue
  }) => {
    try {
      const connection = await fetchOptions({
        inputValue
      });
      const groupedEdges = groupBy(connection?.edges ?? [], edge => edge.groupKey ?? '');
      const totalItemCount = connection?.pageInfo?.totalSize ?? connection?.edges?.length ?? undefined;
      const info = {
        ...connection?.pageInfo,
        hasMultiplePages: typeof totalItemCount === 'number' ? totalItemCount > (connection?.edges?.length ?? 0) : false,
        isFiltered: inputValue.trim().length > 0,
        maxSelected
      };
      const sections = [...groupedEdges.entries()].map(([groupKey, options]) => getSection(groupKey, options, info));
      return {
        sections,
        totalItemCount
      };
    } catch (e) {
      if (e instanceof Error) onError?.(e);
    }
  }, [fetchOptions, getSection, maxSelected, onError]);
  const {
    sections,
    totalItemCount,
    fetchSections,
    refetchSections,
    filtered
  } = useAsyncSelect(fetcher);
  const items = useItems({
    sections,
    filtered,
    selectedValues,
    staticItems
  });
  return {
    items,
    totalItemCount,
    fetchSections,
    refetchSections
  };
};
export function CustomGroupedOptionsFilter({
  selectedValues,
  TargetComponent,
  targetComponentProps,
  maxSelected = DEFAULT_MAX_FILTER_SELECTIONS,
  staticOptions,
  filterType,
  filterHeading,
  fetchSections,
  items,
  totalItemCount,
  ...props
}) {
  return _jsx(FilterField, {
    label: filterHeading || filterType.plural,
    warning: selectedValues.length > maxSelected ? `Maximum (${maxSelected}) filter selections have been exceeded, some selections may not apply.` : '',
    children: _jsx(GroupedOptionsSelect, {
      items: items,
      fetchSections: fetchSections,
      totalItemCount: totalItemCount,
      selectedValues: selectedValues,
      label: `Filter by ${filterType.singular}`,
      TargetComponent: TargetComponent,
      targetComponentProps: targetComponentProps,
      unit: filterType,
      maxSelected: maxSelected,
      ...props
    })
  });
}
CustomGroupedOptionsFilter.displayName = "CustomGroupedOptionsFilter";
export const GroupedOptionsFilter = ({
  entityType,
  filterType,
  icon,
  fetchSections,
  totalItemCount,
  items,
  ...props
}) => {
  const targetComponentProps = useMemo(() => ({
    entityType,
    filterType,
    icon
  }), [entityType, filterType, icon]);
  return _jsx(CustomGroupedOptionsFilter, {
    ...props,
    entityType: entityType,
    filterType: filterType,
    fetchSections: fetchSections,
    totalItemCount: totalItemCount,
    items: items,
    TargetComponent: FilterTarget,
    targetComponentProps: targetComponentProps
  });
};
GroupedOptionsFilter.displayName = "GroupedOptionsFilter";