import * as GroupData from 'data/entity/group';
import { isNew } from 'data/entity/story';
import * as UserData from 'data/entity/user';
import { updateState } from 'utils/addNew';
import { updateStoryGroup } from 'utils/story';
import { formatTeam, formatTeams } from '../../formatters/team';
import { getCommandBar } from '../../getCommandBar';
import { isUserReadOnly } from '../../isUserReadOnly';
import { lazilyLoadArgumentChoices } from '../../lazilyLoadArgumentChoices';
export const addContextForStoryTeam = story => {
  lazilyLoadArgumentChoices('currentTeamForStory', () => getCurrentTeamForStory(story));
  lazilyLoadArgumentChoices('teams', () => formatTeams(UserData.getLoggedInUserPermissionID()));
  if (!isUserReadOnly()) {
    getCommandBar().addCallback('updateStoryTeam', updateStoryTeam(story));
  }
};
const getCurrentTeamForStory = story => {
  if (!story) return null;
  const currentMemberId = UserData.getLoggedInUserPermissionID();
  if (!currentMemberId) return null;
  const team = GroupData.getById(story.group_id);
  return formatTeam(currentMemberId)(team);
};
const updateStoryTeam = story => ({
  team
}) => {
  const newTeam = story.group_id !== team.id ? team : null;
  if (isNew(story)) {
    updateState({
      group_id: newTeam.id
    });
  } else {
    updateStoryGroup(story, newTeam);
  }
};