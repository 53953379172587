import { NavigationAccordion } from './components';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export function TeamItem({
  children,
  id,
  isSelectedTeam,
  isExpandedOrHoveredOrMobile,
  isActive,
  onAddTeamNav,
  onRemoveTeamNav,
  onRemoveInvalidTeam
}) {
  return _jsxs(NavigationAccordion.Item, {
    value: id,
    isSelectedTeam: isSelectedTeam,
    children: [_jsx(NavigationAccordion.TeamHeader, {
      id: id,
      isNavExpanded: isExpandedOrHoveredOrMobile,
      isActive: isActive,
      isSelectedTeam: isSelectedTeam,
      onAddTeamNav: onAddTeamNav,
      onRemoveTeamNav: onRemoveTeamNav,
      onRemoveInvalidTeam: onRemoveInvalidTeam
    }), _jsx(NavigationAccordion.Panel, {
      isNavExpanded: isExpandedOrHoveredOrMobile,
      children: children
    })]
  }, id);
}
TeamItem.displayName = "TeamItem";