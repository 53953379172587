import { Button } from '../shared/DeprecatedButton';
import { GoogleIcon } from '../shared/GoogleIcon';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const onClickDefault = () => {};
export const SigninWithGoogle = ({
  href,
  text = 'Sign in with Google',
  onClick = onClickDefault
}) => {
  return _jsx(Button, {
    className: "google-sign-in",
    text: text,
    href: href,
    Icon: GoogleIcon,
    onClick: onClick
  });
};
SigninWithGoogle.displayName = "SigninWithGoogle";