import { ReportFilterDropdown } from '../../shared/reports/ReportFilterDropdown';
import { getAggregateTypesAsDropdownOptions, getCurrentAggregationType } from 'data/entity/report';
import { getParamFromUrl, updateParamInUrl } from 'utils/navigation';
import { sendBurndownEvent } from 'utils/segment';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const Filters = ({
  onChange
}) => _jsx(ReportFilterDropdown, {
  configId: "burndown_aggregate_id",
  title: "Sum using: ",
  items: getAggregateTypesAsDropdownOptions(),
  value: getParamFromUrl('burndown_aggregate_id') || getParamFromUrl('reports_aggregate_id') || getCurrentAggregationType(),
  onChange: (configId, value) => {
    sendBurndownEvent({
      interaction_type: 'sum using',
      selection: value
    });
    updateParamInUrl(configId, value);
    onChange(value);
  }
}, "burndown_aggregate_id");
Filters.displayName = "Filters";