import FlashController from '../../../core/js/controllers/flash';
import ManageBillingController from './manageBilling';
import PaymentPlanModel from '../../../core/js/models/paymentPlan2';
import Utils from '../../../core/js/modules/utils';
import Validator from '../../../core/js/modules/validator';
import View from '../../../core/js/modules/view';
import SettingsPage from '../../../settings/js/controllers/settingsPage';
import _ from 'lodash';
const exports = {};
const PARENT_SELECTOR = '#manage-email-plan';
const BUTTON_SELECTOR = PARENT_SELECTOR + ' .add-billing-email';
exports.open = () => {
  if ($(SettingsPage.PARENT_SELECTOR).length > 0) {
    ManageBillingController.openPane(() => {
      $('#manage-email-link').click();
    });
  } else {
    ManageBillingController.open();
  }
  return false;
};
exports.updateBillingEmail = email => {
  $(`${PARENT_SELECTOR} .blurb [data-id="billingEmail"]`).text(email);
};
exports.addBillingEmail = () => {
  const context = $(PARENT_SELECTOR);
  const data = Utils.formData(context);
  const oldButtonHTML = $(BUTTON_SELECTOR).html();
  const noExistingEmail = !!_.get(PaymentPlanModel.getPlanForCurrentOrg(), 'billing_contact');
  if (Validator.isValid(context)) {
    View.changeButtonToSaving(BUTTON_SELECTOR);
    PaymentPlanModel.updateBillingEmail(data.email_address, err => {
      if (err) {
        View.revertButtonState(BUTTON_SELECTOR, oldButtonHTML);
        FlashController.error(PARENT_SELECTOR, 'We were unable to ' + (noExistingEmail ? 'add' : 'update') + ' your billing email.', err);
      } else {
        exports.open();
        exports.updateBillingEmail(data.email_address);
        const message = 'Billing email ' + (noExistingEmail ? 'added' : 'updated') + ' to <strong>' + data.email_address + '</strong>.';
        FlashController.success(PARENT_SELECTOR, message);
      }
    });
  }
  return false;
};
export { exports as default };