import "core-js/modules/es.array.push.js";
/* eslint-disable no-restricted-globals */
import LocalStorage from 'app/client/core/js/modules/localStorage';
import { getCurrentUserPrefix } from 'data/entity/user';
import { useEffect, useState } from 'react';

/** @deprecated */
export const get = LocalStorage.get;

/** @deprecated */
export const set = LocalStorage.set;

/** @deprecated */
export const remove = LocalStorage.remove;

/** @deprecated */
export const listen = LocalStorage.listen;

/* New implementations. Preferred. */

const withPrefix = (key, prefix) => {
  if (!prefix) return key;
  const prefixStr = prefix === true ? getCurrentUserPrefix() : `${prefix}.`;
  return `${prefixStr}${key}`;
};
const parseValue = rawValue => {
  try {
    const {
      val,
      exp
    } = JSON.parse(rawValue);
    if (!exp || exp > Date.now()) return val;
  } catch {}
  return null;
};
export const getValue = (key, {
  prefix,
  raw,
  defaultValue = null
} = {}) => {
  const rawValue = LocalStorage.get(withPrefix(key, prefix));
  if (!rawValue) return defaultValue;
  if (raw) return rawValue;
  const parsedValue = parseValue(rawValue);
  if (parsedValue === null) return defaultValue;
  return parsedValue;
};
export const setValue = (key, val, {
  prefix,
  raw,
  ttl
} = {}) => {
  if (raw) {
    LocalStorage.set(withPrefix(key, prefix), val);
    return;
  }
  const item = {
    val
  };
  if (ttl) item.exp = Date.now() + ttl;
  LocalStorage.set(withPrefix(key, prefix), JSON.stringify(item));
};
export const removeValue = (key, {
  prefix
} = {}) => {
  LocalStorage.remove(withPrefix(key, prefix));
};
export const useValue = (key, {
  prefix,
  raw,
  defaultValue = null
} = {}) => {
  const [value, setValue] = useState(getValue(key, {
    prefix,
    raw,
    defaultValue
  }));
  useEffect(() => {
    return listen(withPrefix(key, prefix), () => {
      setValue(getValue(key, {
        prefix,
        raw,
        defaultValue
      }));
    });
  }, [key, prefix, raw, defaultValue]);
  return value;
};

/* Util methods: */

export const getLargestItem = () => {
  try {
    return Object.entries(localStorage).reduce((acc, [key, value]) => {
      if (acc.value.length > value.length) {
        return acc;
      }
      return {
        key,
        value
      };
    }, {
      key: '',
      value: ''
    });
  } catch (e) {}
  return {
    key: '',
    value: ''
  };
};
export const isQuotaExceeded = () => {
  const key = String(Math.random());
  try {
    localStorage.setItem(key, 'test-value');
  } catch (e) {
    if (String(e).toLowerCase().includes('quota')) {
      return true;
    }
  }
  try {
    localStorage.removeItem(key);
  } catch (e) {}
  return false;
};
export const getSizeInBytes = () => {
  try {
    return Object.entries(localStorage).reduce((acc, [key, value]) => {
      return acc + (key.length + value.length) * 2; // 2x for utf-16
    }, 0);
  } catch (e) {}
  return 0;
};
export const findMatchingKeys = (partialKey, {
  prefix,
  raw
} = {}) => {
  const found = [];
  for (const [key, value] of Object.entries(localStorage)) {
    if (key.startsWith(withPrefix(partialKey, prefix))) {
      if (raw) found.push([key, value]);else found.push([key, parseValue(value)]);
    }
  }
  return found;
};

/* eslint-enable no-restricted-globals */