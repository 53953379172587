import * as ManageTemplatesTemplate from 'app/client/settingsShared/views/templates/manageTemplates.html';
import * as ManageTemplateControlsTemplate from 'app/client/settingsShared/views/templates/manageTemplateControls.html';
import * as CreateStoryLinkTemplate from 'app/client/core/views/templates/createStoryLink.html';
import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons';
import { AskAnOwnerAlert } from 'components/settings/AskAnOwnerAlert';
import { Icon } from '@useshortcut/shapes-ds';
import AddNewStoryController from 'app/client/core/js/controllers/addNewStory';
import Dialog from 'app/client/core/js/modules/dialog';
import DropdownController from 'app/client/core/js/controllers/dropdown';
import FlashController from 'app/client/core/js/controllers/flash';
import Format from 'app/client/core/js/modules/format';
import InPlaceEditorController from 'app/client/core/js/controllers/inPlaceEditor';
import Is from 'app/client/core/js/modules/is';
import MessageController from 'app/client/core/js/controllers/message';
import OrganizationModel from 'app/client/core/js/models/organization';
import ProfileModel from 'app/client/core/js/models/profile';
import StoryTemplateModel from 'app/client/core/js/models/storyTemplate';
import ToggleController from 'app/client/core/js/controllers/toggle';
import Tooltip from 'app/client/core/js/modules/tooltip';
import Url from 'app/client/core/js/modules/url';
import UserModel from 'app/client/core/js/models/user';
import ColumnModel from 'app/client/core/js/models/column';
import WorkflowModel from 'app/client/core/js/models/workflow';
import Utils from 'app/client/core/js/modules/utils';
import View from 'app/client/core/js/modules/view';
import SettingsPage from 'app/client/settings/js/controllers/settingsPage';
import _ from 'lodash';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const exports = {};
let _isSaving = false;
const PARENT_SELECTOR = '#manage-templates';
exports.title = `Story Templates | ${BRAND.NAME}`;
exports.route = () => {
  return Url.getSlugPath() + '/settings/story_templates';
};
function _addAggregatedDataToTemplates(templates = []) {
  return templates.map(template => {
    const author = ProfileModel.getById(template.author_id);
    template.author = author?.profile?.name ?? '';
    const column = ColumnModel.getById(template.story_contents?.workflow_state_id);
    const workflow = WorkflowModel.getById(column?.workflow_id);
    template.workflow = workflow ? workflow.name : '';
    return template;
  });
}
exports.open = () => {
  SettingsPage.loadPane(exports);
};
exports.render = () => {
  // The user may have the Create Story menu open at this point.
  DropdownController.closeAll();
  const me = UserModel.getLoggedInUserPermission();
  const isAdmin = Is.admin(me);
  const isObserver = Is.observer(me);
  const templateMountNode = document.querySelector(SettingsPage.PARENT_SELECTOR);
  View.renderWithComponents({
    template: ManageTemplatesTemplate,
    templateMountNode,
    templateData: {
      active: 'manageTemplates',
      me,
      isAdmin,
      isObserver,
      isActive: OrganizationModel.templatesAreEnabled()
    },
    components: {
      readOnlyMessage: {
        component: AskAnOwnerAlert,
        props: {
          description: isObserver ? 'Observers cannot edit or delete story templates.' : 'Only admins can enable or disable story templates.'
        }
      }
    }
  });
  OrganizationModel.fetchCurrent(exports.renderControls);
  StoryTemplateModel.on('fetched.manageTemplates', exports.renderControls);
  StoryTemplateModel.on('updated.manageTemplates', () => {
    if (StoryTemplateModel.isNotBulk()) {
      exports.renderControls();
    }
  });
  SettingsPage.onDestroy('ManageTemplates', () => {
    // NOTE: this will get called as many times as rendered is called
    View.unmountComponents({
      templateMountNode: document.querySelector(SettingsPage.PARENT_SELECTOR)
    });
    StoryTemplateModel.off('fetched.manageTemplates');
    StoryTemplateModel.off('updated.manageTemplates');
  });
};
exports.renderControls = () => {
  const me = UserModel.getLoggedInUserPermission();
  const isActive = OrganizationModel.templatesAreEnabled();
  const isAdmin = Is.admin(me);
  const isObserver = Is.observer(me);
  const templates = _addAggregatedDataToTemplates(StoryTemplateModel.all());
  const sortedTemplates = _.sortBy(templates, template => {
    return template.name.toLowerCase();
  });
  const html = ManageTemplateControlsTemplate.render({
    me,
    isAdmin,
    isObserver,
    isActive,
    templates: sortedTemplates
  });
  $('.manage-template-controls').html(html);
};
exports.renderEditIcon = id => View.renderComponentDelayed({
  componentKey: 'edit' + id,
  component: _jsx(DeprecatedIconAdapter, {
    fill: "var(--iconYellowColor)",
    width: 12,
    children: _jsx(Icon, {
      icon: "Edit"
    })
  })
}).html;
exports.renderMoreIcon = id => View.renderComponentDelayed({
  componentKey: 'more' + id,
  component: _jsx(Icon, {
    icon: "More"
  })
}).html;
exports.deleteTemplate = template => {
  if (window.confirm('Are you sure you want to delete this template?')) {
    StoryTemplateModel.deleteTemplate(template).then(() => {
      View.fadeAndSlideUp($(`tr[data-id="${template.id}"]`));
      FlashController.success('#manage-templates', `Story Template <em>${Format.sanitize(template.name)}</em> deleted.`);
    }).catch(err => {
      FlashController.error('#manage-templates', `Unable to delete <em>${Format.sanitize(template.name)}</em>.`, err);
    });
  }
  return false;
};
exports.openTemplateActions = function () {
  const template = Utils.getModelFromContext(this);
  const dropdownId = 'story-template-actions-dropdown';
  const items = [{
    name: 'Copy Link to Template',
    value: () => {
      DropdownController.closeById(dropdownId).then(() => {
        DropdownController.open({
          footer: CreateStoryLinkTemplate.render({
            template_id: template.id
          }),
          target: this,
          leftOffset: -268,
          width: 300
        });
      });
    },
    iconLeft: 'fa-clipboard'
  }, {
    name: 'Edit Template',
    value: () => {
      Dialog.close({
        force: true
      });
      AddNewStoryController.renderFromTemplate(template);
    },
    iconLeft: 'fa-pencil'
  }, {
    name: 'Delete Template...',
    value: _.wrap(template, exports.deleteTemplate),
    iconLeft: 'fa-trash-o'
  }];
  Tooltip.close();
  DropdownController.open({
    items,
    id: dropdownId,
    target: this,
    width: 180
  });
  return false;
};
exports.updateTemplateName = function () {
  const template = Utils.getModelFromContext(this);
  const oldName = template.name;
  const editorElement = $(this).closest('.in-place-editor');
  const newName = editorElement.find('input').val().trim();
  if (newName && oldName !== newName) {
    $(this).closest('td').addClass('is-updating').find('.fa-pencil').removeClass('fa-pencil').addClass('fa-star').addClass('fa-spin');
    StoryTemplateModel.updateTemplate(template.id, {
      name: newName
    }).then(exports.renderControls).catch(() => {
      FlashController.error('#manage-templates', 'Unable to update template name.');
    });
  }
  InPlaceEditorController.destroy(editorElement);
  return false;
};
exports.toggle = () => {
  if (_isSaving) {
    return false;
  }
  _isSaving = true;
  ToggleController.markAsSaving(PARENT_SELECTOR);
  let toggleFunction;
  let verb;
  if (OrganizationModel.templatesAreEnabled()) {
    toggleFunction = OrganizationModel.disableStoryTemplates;
    verb = 'disable';
  } else {
    toggleFunction = OrganizationModel.enableStoryTemplates;
    verb = 'enable';
  }
  toggleFunction().then(() => {
    $(PARENT_SELECTOR).find('.integration-status').toggleClass('integration-status-active').text(verb + 'd');
    ToggleController.markAsSaved(PARENT_SELECTOR, exports.renderControls);
  }).catch(res => {
    MessageController.error(res.error, {
      secondary: `Unable to ${verb} story templates for your workspace.`
    });
    ToggleController.revert(PARENT_SELECTOR);
  }).finally(() => {
    _isSaving = false;
    if (verb === 'enable') {
      StoryTemplateModel.fetchAll();
    }
  });
  return false;
};
export { exports as default };