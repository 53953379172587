import { createContext, useContextSelector } from 'use-context-selector';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const InternalAutoLinkContext = createContext({
  isArchived: false
});
export const InternalAutoLinkContextProvider = ({
  children,
  isArchived
}) => _jsx(InternalAutoLinkContext.Provider, {
  value: {
    isArchived
  },
  children: children
});
InternalAutoLinkContextProvider.displayName = "InternalAutoLinkContextProvider";
export const useIsArchived = () => useContextSelector(InternalAutoLinkContext, ({
  isArchived
}) => isArchived);