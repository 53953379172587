import { useEffect, useRef } from 'react';
export const useScrollIntoView = ({
  shouldScroll
}) => {
  const ref = useRef(null);
  useEffect(() => {
    if (shouldScroll && ref.current) {
      ref.current?.scrollIntoView();
    }
  }, [shouldScroll]);
  return {
    ref
  };
};