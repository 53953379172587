import { PinStoryActionFragmentDoc } from "../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { MoreActions } from '@clubhouse/shared/components/MoreActions';
import { Nouns } from '@clubhouse/shared/constants';
import { usePinnedItem } from 'components/shared/pin-items/hooks/usePinnedItems';
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const PinStoryActionFragment = PinStoryActionFragmentDoc;
export const PinStoryAction = ({
  story
}) => {
  const {
    isPinned,
    toggle
  } = usePinnedItem({
    id: story.id,
    loggingContext: {
      typename: 'Story'
    }
  });
  return _jsxs(MoreActions.Item, {
    icon: "Pin",
    onClick: toggle,
    children: [isPinned ? 'Unpin' : 'Pin', " ", Nouns.Story.singular]
  });
};
PinStoryAction.displayName = "PinStoryAction";