import LockErrorPng from '@clubhouse/assets/png/lock_error.png';
import { CompositeImage } from '../shared/CompositeImage';
import { Problem } from '../shared/Problem';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const DEFAULT_MESSAGE = 'We seem to have an issue with the invitation. It has either expired or was deleted.';
export const DEFAULT_TITLE = "Sorry there's been a problem";
export const InviteErrorMedia = () => _jsx(CompositeImage, {
  primaryImage: {
    src: LockErrorPng,
    alt: '',
    height: '150px',
    width: '182px'
  }
});
InviteErrorMedia.displayName = "InviteErrorMedia";
export const InviteNotFound = ({
  title = DEFAULT_TITLE,
  message = DEFAULT_MESSAGE
}) => _jsx(Problem, {
  title: title,
  description: message,
  media: _jsx(InviteErrorMedia, {})
});
InviteNotFound.displayName = "InviteNotFound";