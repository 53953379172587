import { TableText } from '@clubhouse/shared/components/Table';
import { FIELD_ID } from 'components/shared/table/types';
import { totalStories } from 'utils/sort';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const TotalStoriesFieldComponent = ({
  entity
}) => {
  return _jsx(TableText, {
    text: entity.totalStories
  });
};
TotalStoriesFieldComponent.displayName = "TotalStoriesFieldComponent";
export const TotalStoriesField = (overrides = {}) => ({
  name: FIELD_ID.TOTAL_STORIES,
  displayName: 'Stories',
  sort: totalStories,
  headerProps: {
    centered: true
  },
  Component: TotalStoriesFieldComponent,
  width: 60,
  ...overrides
});