import { useMemo } from 'react';
import ColumnModel from 'app/client/core/js/models/column';
import WorkflowModel from 'app/client/core/js/models/workflow';
import { useEntities } from './collection';
export const getById = id => id ? ColumnModel.getById(id) : undefined;
export const useWorkflowStates = ({
  groupId
} = {}) => {
  const {
    entities
  } = useEntities({
    model: ColumnModel
  });
  const filtered = useMemo(() => {
    if (!groupId) return entities;
    return entities.filter(state => {
      if (!workflowStateMatchesGroup(state, groupId)) return false;

      // Add additional filters here

      return true;
    });
  }, [entities, groupId]);
  return {
    workflowStates: filtered
  };
};
const workflowStateMatchesGroup = (state, groupId) => {
  const groups = WorkflowModel.getAssociatedGroups(state.workflow_id);
  return groups.some(group => group.id === groupId);
};
export const saveNewWorkflowState = (workflowId, newState) => new Promise((resolve, reject) => {
  ColumnModel.saveNew({
    id: workflowId
  }, newState, (err, res) => {
    if (err) reject(err);else resolve(res);
  });
});
export const saveChangesToWorkflowState = (workflowId, workflowStateId, changes) => new Promise((resolve, reject) => {
  ColumnModel.saveUpdate({
    id: workflowId
  }, {
    id: workflowStateId
  }, changes, (err, res) => {
    if (err) reject(err);else resolve(res);
  });
});