import { Emojify } from '@clubhouse/shared/components/Emojify';
import { Text } from '@clubhouse/shared/components/Typography/Text';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const TableNameText = ({
  text = '-'
}) => {
  return _jsx(Text, {
    textAlign: "left",
    lineClamp: 2,
    bold: true,
    children: _jsx(Emojify, {
      children: text === '' ? '-' : text
    })
  });
};
TableNameText.displayName = "TableNameText";