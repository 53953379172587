import "core-js/modules/web.dom-exception.stack.js";
import "core-js/modules/web.structured-clone.js";
import { useOnUnmount } from '@clubhouse/shared/hooks';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { getApolloClient } from 'gql/utils/initApollo';
import { useCallback, useEffect, useRef, useState } from 'react';
import { trigger } from 'utils/event';
import { isPlaywrightTestEnv } from 'utils/isPlaywrightTestEnv';
import { createAlert } from 'utils/offline';
import { getUpdatesUrl, setupSSEListeners } from 'utils/updates';
import { getDbTime, setDbTime, useDbTime } from './dbTime';
import { logoutAndRedirect } from 'utils/logout';
const getUrl = () => {
  const path = '/api/private/sse-updates';
  return getUpdatesUrl(path);
};
const clearCache = async () => {
  const client = await getApolloClient();
  await client?.cache.reset();
};
const parseUpdatePayload = payload => (payload || '').split('\n').map(str => {
  try {
    return JSON.parse(str.trim());
  } catch {
    return null;
  }
}).filter(Boolean);
const getEventSourceUrl = (workspaceId, dbTime) => {
  return `${getUrl()}/${workspaceId}/${dbTime ?? getDbTime()}?legacy-updates=1`;
};
const FailedToConnectToast = createAlert({
  kind: 'alert',
  message: 'The server connection for receiving real-time updates could not be established. Please try reloading the page, and contact support if the error persists.'
});
class RetriableError extends Error {}
class AccessDeniedError extends Error {}
const useDelayedWarning = () => {
  const timeoutId = useRef(null);
  const show = useCallback(() => {
    if (isPlaywrightTestEnv()) return;
    timeoutId.current = setTimeout(() => {
      FailedToConnectToast.show();
    }, 5000);
  }, []);
  const hide = useCallback(() => {
    if (timeoutId.current) clearTimeout(timeoutId.current);
    FailedToConnectToast.hide();
  }, []);
  useOnUnmount(() => {
    if (timeoutId.current) clearTimeout(timeoutId.current);
  });
  return {
    show,
    hide
  };
};
export function useEventSource({
  onUpdate,
  onLegacyUpdate,
  publicId
}) {
  const {
    show: showDelayedWarning,
    hide: hideDelayedWarning
  } = useDelayedWarning();
  const [url, setUrl] = useState(null);
  const reconnectCountRef = useRef(0);
  const dbTime = useDbTime();
  useEffect(() => {
    if (typeof publicId !== 'string' || !dbTime) setUrl(null);else setUrl(getEventSourceUrl(publicId, dbTime));
  }, [dbTime, publicId]);
  useEffect(() => {
    if (!url) return;
    setupSSEListeners();
    const abortController = new AbortController();
    fetchEventSource(url, {
      credentials: 'include',
      signal: abortController.signal,
      async onopen(response) {
        reconnectCountRef.current = 0;
        hideDelayedWarning();
        if (response.ok) console.debug('SSE - EventSource state changed: open');
        // User's session has expired.
        else if (response.status === 401) throw new AccessDeniedError();else throw new RetriableError();
      },
      async onmessage({
        event,
        id: dbTime,
        data: json
      }) {
        switch (event) {
          case 'reset':
            {
              console.debug('SSE - Reset');
              trigger('datalayer-reset');
              onLegacyUpdate({
                type: 'reset',
                dbTime
              });
              setDbTime(dbTime);
              await clearCache();
              break;
            }
          case 'update':
            {
              const data = parseUpdatePayload(json);
              if (data?.length) {
                setDbTime(dbTime);
                onUpdate(data);
              }
              break;
            }
          case 'legacy-update':
            {
              const data = parseUpdatePayload(json);
              if (data?.length) {
                onLegacyUpdate({
                  type: 'update',
                  data,
                  dbTime
                });
                trigger('datalayer-update', {
                  data: structuredClone(data),
                  dbTime
                });
              }
              break;
            }
        }
      },
      onclose() {
        console.debug('SSE - EventSource state changed: close (be)');
        // Backend closed connection. This could be due to a deployment. We should retry.
        throw new RetriableError();
      },
      onerror(err) {
        if (err instanceof AccessDeniedError) {
          console.debug('SSE - EventSource error: access denied error');
          logoutAndRedirect();
          return;
        }
        if (err instanceof RetriableError) console.debug('SSE - EventSource error: retriable error');else console.debug('SSE - EventSource error: unknown error', err);
        showDelayedWarning();

        // wait with an exponential backoff up to 5 seconds before reconnecting
        const reconnectDelayMs = Math.min(5000, 2 ** reconnectCountRef.current * 100);
        console.debug('SSE - Reconnecting in', reconnectDelayMs, 'ms');
        reconnectCountRef.current += 1;
        return reconnectDelayMs;
      }
    });
    return () => {
      console.debug('SSE - EventSource state changed: close (fe)');
      abortController.abort();
    };
  }, [onUpdate, onLegacyUpdate, url, showDelayedWarning, hideDelayedWarning]);
}