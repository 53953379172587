import { useOnDocumentVisibilityChange } from '@clubhouse/shared/hooks';
import { useCallback } from 'react';
export const useRefetchWhenTabIsActive = ({
  refetch,
  skip
}) => {
  useOnDocumentVisibilityChange(useCallback(visibilityState => {
    if (skip) return;
    if (visibilityState === 'visible') refetch();
  }, [refetch, skip]));
};