import { getActiveSpan, setTags, spanToJSON } from '@sentry/browser';
import { SPAN_STATUS_OK } from '@sentry/core';
import { useEffect, useRef } from 'react';
export const useFinishActiveTransaction = ({
  shouldFinishTransaction,
  tags
}) => {
  const tagsRef = useRef(tags);
  tagsRef.current = tags;
  const activeSpanId = getActiveSpan()?.spanContext().spanId;
  useEffect(() => {
    if (activeSpanId && shouldFinishTransaction) {
      finishActiveTransaction(tagsRef.current);
    }
  }, [activeSpanId, shouldFinishTransaction]);
};
export const finishActiveTransaction = tags => {
  const activeSpan = getActiveSpan();
  if (activeSpan) {
    const activeSpanStatus = spanToJSON(activeSpan)?.status;
    if (!activeSpanStatus) {
      activeSpan.setStatus?.({
        code: SPAN_STATUS_OK
      });
    }
    if (tags) {
      setTags(tags);
    }
    Object.entries(tags ?? {}).forEach(([key, value]) => {
      activeSpan.setAttribute(key, value);
    });
    if (activeSpan.isRecording()) {
      activeSpan.end?.();
    }
  }
};