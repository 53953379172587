import { useMemo } from 'react';
import { useLatestRef } from '@clubhouse/shared/hooks';
import { BulkSelectContext, createState } from './bulkSelectContext';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export function BulkSelectProvider({
  items,
  children
}) {
  const itemsRef = useLatestRef(items);
  return _jsx(BulkSelectContext.Provider, {
    value: useMemo(() => createState(itemsRef), [itemsRef]),
    children: children
  });
}
BulkSelectProvider.displayName = "BulkSelectProvider";