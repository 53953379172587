import { Button } from '@clubhouse/shared/components/Button';
import { FilterField } from '@clubhouse/shared/components/FilterField';
import { useClearableFilters } from './useClearableFilters';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export function ClearAllFiltersButton({
  filters
}) {
  const {
    hasClearableFilters,
    clearFilters
  } = useClearableFilters(filters);
  if (!hasClearableFilters) return null;
  return _jsx(FilterField, {
    label: "",
    children: _jsx(Button, {
      kind: Button.KIND.SECONDARY,
      size: Button.SIZE.MEDIUM,
      onClick: clearFilters,
      children: "Clear Filters"
    })
  });
}
ClearAllFiltersButton.displayName = "ClearAllFiltersButton";