export const isValidUrl = data => {
  data = (data + '').trim();
  const regex = /^https?:\/{2}[\wÞßþøÀ-ÿ.-]+(?:\.[\wÞßþøÀ-ÿ.-]+)+[\wÞßþøÀ-ÿ\/._~:?#-[\]@!$&%'()*+,;=]+$/u;
  if (data.indexOf('http://localhost:') === 0) return true;
  return regex.test(data);
};
export const notEmpty = data => {
  data = (data + '').trim();
  return data !== '';
};
export const isUsername = data => {
  data = (data + '').trim();
  const regex = /^[a-z0-9\-\_\.]+$/;
  return regex.test(data);
};
export const cannotEndWithAPeriod = data => {
  data = (data + '').trim();
  const regex = /\.$/;
  return !regex.test(data);
};