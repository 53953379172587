import { Emojify } from '@clubhouse/shared/components/Emojify';
import { EpicStateIcon } from '@clubhouse/shared/components/EpicIcon';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const OWNER_CATEGORY = {
  categoryId: 'owner',
  categoryTitle: 'Owned Epics'
};
const TEAMS_CATEGORY = {
  categoryId: 'team',
  categoryTitle: 'Team Epics'
};
const OTHER_TEAMS_CATEGORY = {
  categoryId: 'other',
  categoryTitle: 'Other Epics'
};
const SELECTED_TEAMS_CATEGORY = {
  categoryId: 'mine',
  categoryTitle: "Selected Team's Epics"
};
const getOptionalConfigForGroupKey = {
  unstarted: allowBulkSelect => ({
    ...OTHER_TEAMS_CATEGORY,
    sectionId: 'unstarted',
    sectionTitle: 'Unstarted',
    allowBulkSelect
  }),
  started: allowBulkSelect => ({
    ...OTHER_TEAMS_CATEGORY,
    sectionId: 'started',
    sectionTitle: 'Started',
    allowBulkSelect
  }),
  done: allowBulkSelect => ({
    ...OTHER_TEAMS_CATEGORY,
    sectionId: 'done',
    sectionTitle: 'Done',
    allowBulkSelect
  }),
  ownerUnstarted: allowBulkSelect => ({
    ...OWNER_CATEGORY,
    sectionId: 'ownerUnstarted',
    sectionTitle: 'Unstarted',
    allowBulkSelect
  }),
  ownerStarted: allowBulkSelect => ({
    ...OWNER_CATEGORY,
    sectionId: 'ownerStarted',
    sectionTitle: 'Started',
    allowBulkSelect
  }),
  ownerDone: allowBulkSelect => ({
    ...OWNER_CATEGORY,
    sectionId: 'ownerDone',
    sectionTitle: 'Done',
    allowBulkSelect
  }),
  teamUnstarted: allowBulkSelect => ({
    ...TEAMS_CATEGORY,
    sectionId: 'teamUnstarted',
    sectionTitle: 'Unstarted',
    allowBulkSelect
  }),
  teamStarted: allowBulkSelect => ({
    ...TEAMS_CATEGORY,
    sectionId: 'teamStarted',
    sectionTitle: 'Started',
    allowBulkSelect
  }),
  teamDone: allowBulkSelect => ({
    ...TEAMS_CATEGORY,
    sectionId: 'teamDone',
    sectionTitle: 'Done',
    allowBulkSelect
  }),
  storyTeamEpicUnstarted: allowBulkSelect => ({
    ...SELECTED_TEAMS_CATEGORY,
    sectionId: 'selectedTeamEpicUnstarted',
    sectionTitle: 'Unstarted',
    allowBulkSelect
  }),
  storyTeamEpicStarted: allowBulkSelect => ({
    ...SELECTED_TEAMS_CATEGORY,
    sectionId: 'selectedTeamEpicStarted',
    sectionTitle: 'Started',
    allowBulkSelect
  }),
  storyTeamEpicDone: allowBulkSelect => ({
    ...SELECTED_TEAMS_CATEGORY,
    sectionId: 'selectedTeamEpicDone',
    sectionTitle: 'Done',
    allowBulkSelect
  }),
  storyEpic: null,
  selectedEpic: null
};
export const getSection = (groupKey, options, {
  hasMultiplePages,
  isFiltered
}) => ({
  ...getOptionalConfigForGroupKey[groupKey]?.(!hasMultiplePages && !isFiltered),
  items: options.map(({
    node: {
      id,
      name,
      epicState
    }
  }) => ({
    value: id,
    name: _jsx(Emojify, {
      children: name
    }),
    Icon: () => _jsx(EpicStateIcon, {
      size: 18,
      type: epicState?.type
    })
  }))
});