import * as LoadOldStoriesButtonTemplate from 'app/client/stories/views/templates/loadOldStoriesButton.html';
import BulkEditorController from '../../../core/js/controllers/bulkEditor';
import BulkSelectionModel from '../../../core/js/models/bulkSelection';
import ColumnController from './column';
import ColumnModel from '../../../core/js/models/column';
import EpicController from './epic';
import EpicModel from '../../../core/js/models/epic';
import * as Event from '../../../core/js/_frontloader/event';
import FilterModel from '../../../core/js/models/filter';
import Globals from '../../../core/js/_frontloader/globals';
import Iterate from '../../../core/js/modules/iterate';
import Log from '../../../core/js/modules/log';
import ProjectModel from '../../../core/js/models/project';
import StoriesColumnModel from '../models/storiesColumn';
import StoriesView from '../modules/storiesView';
import StoryAnimation from '../modules/storyAnimation';
import StoryController from '../../../core/js/controllers/story';
import StoryModel from '../../../core/js/models/story';
import TeamModel from '../../../core/js/models/team';
import Tooltip from '../../../core/js/modules/tooltip';
import Utils from '../../../core/js/modules/utils';
import MessageController from '../../../core/js/controllers/message';
import _ from 'lodash';
import moment from 'moment';
const exports = {};
exports.storiesPageInit = () => {
  Event.on('newStoryCreated', story => {
    if (StoriesView.getViewType() === StoriesView.KANBAN_VIEW) {
      try {
        _revealStoriesInColumnUntil(story);
      } catch (e) {
        MessageController.alert(e.message, {
          timeout: 20000
        });
      }
    }
  });
};
exports.allowStoryActions = () => {
  $('#columns').removeClass('no-story-actions');
};
exports.disallowStoryActions = () => {
  $('#columns').addClass('no-story-actions');
};
exports.revealStory = function (story) {
  story = StoryModel.isValid(story) ? story : Utils.getModelFromContext(this);
  if (!story) {
    return false;
  }
  const teamWasUpdated = _updateTeamSelector(ProjectModel.get({
    id: story.project_id
  }));
  let storyElement = StoryController.getStoryElementInColumn(story);
  if (storyElement.length === 0) {
    exports.revealStoryProject(story);
    exports.revealStoryEpic(story);
    exports.revealStoryColumn(story);
    exports.disableFiltersHidingStory(story);
    Event.trigger('spaceUpdate');
    if (teamWasUpdated) {
      ColumnController.reset();
    } else {
      ColumnController.render();
    }
    try {
      _revealStoriesInColumnUntil(story);
      storyElement = StoryController.getStoryElementInColumn(story); // Try to get the element after loading more stories
    } catch (e) {
      // TODO: When could this happen?
      return false;
    }
  }
  if (storyElement.length) {
    const columnElement = $(storyElement).closest('.column');
    ColumnController.scrollToColumn(columnElement);
    const quick_search_could_overlap = columnElement.offset().left < 750;
    if (quick_search_could_overlap) {
      $('.quick-search').animate({
        opacity: 0.1
      }, 150);
    }
    Tooltip.close();
    StoryAnimation.scrollToAndFlashStory(storyElement).then(() => {
      $('.quick-search').animate({
        opacity: 1
      }, 150);
    });
  }
  return false;
};
function _revealStoriesInColumnUntil(story) {
  const isStoryInVisibleWorkflow = ColumnModel.allFromActiveWorkflow()?.map(w => w.id).includes(story.workflow_state_id);
  if (!isStoryInVisibleWorkflow) {
    throw new Error('Story not visible due to selected workflow.');
  }
  const column = ColumnModel.getById(story.workflow_state_id);
  const visibleStories = StoriesView.getVisibleStoriesInColumn(column);
  const storyIndex = _.indexOf(_.map(visibleStories, 'id'), story.id);
  if (storyIndex < 0) {
    throw new Error('Story not visible due to current filters.');
  }

  // performance optimization: don't reveal a story that is positioned more than 100 stories below the top: sc-192175
  if (storyIndex > 100) {
    throw new Error(`Scroll down in the "${column.name}" column to see the story.`);
  }
  const existingStoryLimit = StoriesColumnModel.getStoryLimitForColumn(column);
  StoriesColumnModel.setStoryLimitForColumn(column, Math.max(storyIndex + 1, existingStoryLimit));
  StoriesView.replaceAllStoriesInColumn(column, visibleStories);
}
function _updateTeamSelector(project) {
  if (!project) return false;
  const teamContext = 'stories';
  const activeTeam = TeamModel.getActive(teamContext);
  if (activeTeam.id !== project.team_id) {
    const team = TeamModel.get({
      id: project.team_id
    });
    TeamModel.setActive(team, teamContext);
    return true;
  }
  return false;
}
exports.revealStoryProject = story => {
  if (!ProjectModel.isActive({
    id: story.project_id
  })) {
    ProjectModel.update({
      id: story.project_id,
      active: true
    });
  }
};
exports.revealStoryEpic = story => {
  if (story.epic_id) {
    EpicModel.update({
      id: story.epic_id,
      active: true
    });
  } else {
    EpicModel.displayStoriesNotInEpics();
    EpicController.render();
  }
};
exports.revealStoryColumn = story => {
  const column = ColumnModel.get({
    id: story.workflow_state_id
  });
  if (!column) {
    Log.log('Unable to find column. Ref Rollbar #58.', {
      workflowStateID: story.workflow_state_id
    });
  } else if (!column.active) {
    ColumnModel.update({
      id: column.id,
      active: true
    });
  }
};
exports.disableFiltersHidingStory = story => {
  const activeFilters = FilterModel.filter({
    active: true
  });
  if (activeFilters.length > 0) {
    Iterate.each(activeFilters, filter => {
      if (!StoryModel.isFilteredByFilter(story, filter)) {
        FilterModel.update({
          id: filter.id,
          active: false
        });
      }
    });
  }
};
exports.loadOldStories = ev => {
  const $el = $(ev.target).parent('.load-old-stories');
  const workflowStateId = Number.parseInt($el.data('workflow-state-id'));
  const column = {
    id: workflowStateId
  };
  if (ColumnModel.isLoadingOldStories(column) || typeof workflowStateId !== 'number' || isNaN(workflowStateId)) {
    return false;
  }
  ColumnModel.setLoadingOldStories(column, true);
  $(`.load-old-stories[data-workflow-state-id="${workflowStateId}"]`).replaceWith(LoadOldStoriesButtonTemplate.render({
    isLoading: true,
    workflowStateId
  }));
  const lastCompletedBefore = ColumnModel.getOldStoriesCompletedBefore(column) || moment();
  StoryModel.fetchOldCompletedStories({
    lastCompletedBefore,
    workflowStateId
  }, nextCompletedBefore => {
    ColumnModel.setOldStoriesCompletedBefore(column, nextCompletedBefore);
    ColumnModel.setLoadingOldStories(column, false);
    StoriesView.drawStories();
    $(`.load-old-stories[data-workflow-state-id="${workflowStateId}"]`).replaceWith(LoadOldStoriesButtonTemplate.render({
      isLoading: false,
      workflowStateId
    }));
  });
  return false;
};
exports.updateOneOrManyOnDrop = (element, updates) => {
  Globals.set('isValidSidebarTarget', true);
  if (BulkSelectionModel.getSelectionCount() > 0) {
    exports.updateBulkStoriesAndRedraw(updates, {
      applyUpdatesOptimistically: true
    });
  } else {
    const story_id = Utils.data(element, 'id');
    const story = StoryModel.getById(story_id);
    StoryModel.serverSave(story, updates, {
      afterRender: () => {
        $('#sidebar .story').remove();
        StoriesView.drawStories();
      }
    });
  }
};
exports.updateBulkStoriesAndRedraw = (updates, options = {}) => {
  const bulkUpdatedStories = BulkSelectionModel.getStoriesForEachSelection();
  if (options.applyUpdatesOptimistically) {
    _applyOptimisticUpdatesToStoriesAndRedraw(bulkUpdatedStories, updates);
  }
  BulkSelectionModel.save(updates, err => {
    if (err) {
      // If there was an error from the server, let's fetch the bulk-updated
      // stories again for cases when they've been optimistically updated.
      StoryModel.getStoriesById(_.map(bulkUpdatedStories, 'id'), StoriesView.drawStories);
    } else {
      StoriesView.drawStories();
    }
    exports.allowStoryActions();
  });

  // Now that the BulkEditor request has been sent, we can safely deselect the stories.
  BulkEditorController.deselectAll({
    excludeRedraw: true
  });
};
function _applyOptimisticUpdatesToStoriesAndRedraw(stories, updates) {
  stories.forEach(story => {
    StoryModel.update(_.assign({
      id: story.id
    }, updates));
  });
  StoriesView.drawStories();
}
export { exports as default };