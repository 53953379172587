import isEqual from 'lodash/isEqual';
import { useRef } from 'react';
import { useApplicationState } from './useApplicationState';
export const useApplicationStateByKey = ({
  applicationStateKey,
  toExpectedType,
  fetchPolicy
}) => {
  const {
    loading,
    parsedCurrentUserPermissionSpaceData,
    updateQuery,
    error,
    refetch
  } = useApplicationState({
    fetchPolicy
  });
  const transformedData = !error && parsedCurrentUserPermissionSpaceData !== undefined ? toExpectedType(parsedCurrentUserPermissionSpaceData?.[applicationStateKey]) : undefined;
  const currentData = useRef(transformedData);
  if (!isEqual(currentData.current, transformedData)) {
    currentData.current = transformedData;
  }
  return {
    data: currentData.current,
    loading,
    updateQuery,
    error,
    refetch
  };
};