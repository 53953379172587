import ImportModel from 'app/client/core/js/models/import';
export const mergePermissions = (ghostPermissionId, memberPermissionId) => ImportModel.Promises.mergePermissions(ghostPermissionId, memberPermissionId);
export const updateById = imp => ImportModel.updateById(imp);
export const getAll = () => ImportModel.all();
export const undoImport = impId => ImportModel.Promises.undo(impId);
export const getNumberOfImports = () => ImportModel.size();
export const createImport = (...args) => ImportModel.Promises.create(...args);
export const getImportMapping = importId => ImportModel.Promises.getMapping(importId);
export const setMappingByType = (...args) => ImportModel.Promises.setMappingByType(...args);
export const commitMappingByType = (...args) => ImportModel.Promises.commitMappingByType(...args);
export const getStats = importId => ImportModel.Promises.getStats(importId);