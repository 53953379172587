import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const Flag = ({
  fill,
  width
}) => _jsx("svg", {
  fill: fill,
  width: width,
  height: width,
  viewBox: "0 0 32 32",
  children: _jsx("path", {
    d: "M7.12 5a2.5 2.5 0 1 0-3.5 2.29V28.5a1 1 0 0 0 2 0V7.29A2.5 2.5 0 0 0 7.12 5zm1.01 3.72l10.96-2.44v3.03L29.69 7l-2.94 7.53 2.72 6.72-12.38 2.47v-3l-8.96 2.03V8.72z"
  })
});
Flag.displayName = "Flag";