import { Icon } from '@useshortcut/shapes-ds';
import { ICON_DEFAULT_WIDTH } from '../../utils/icon';
import { DeprecatedIconAdapter } from './DeprecatedIconAdapter';

/**
 * @deprecated Use <Icon icon="User" /> from @useshortcut/shapes-ds
 */
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const UserSvg = ({
  width = ICON_DEFAULT_WIDTH,
  fill
}) => _jsx(DeprecatedIconAdapter, {
  fill: fill,
  width: width,
  children: _jsx(Icon, {
    icon: "User",
    label: "User"
  })
});
UserSvg.displayName = "UserSvg";