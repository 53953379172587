import { createRoot } from 'react-dom/client';
import { ShapesProvider } from '@useshortcut/shapes-ds';
import { Badges } from './Badges';
import { ThemeContextProvider } from '@clubhouse/shared/utils/theme';
import { getMilestoneById, useMilestone } from 'data/entity/milestone';
import { milestoneToSearchProps } from '../../../searchResults/utils';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const MilestoneBadges = ({
  id
}) => {
  const milestone = useMilestone({
    id
  });
  const searchData = milestoneToSearchProps(milestone);
  return _jsx(Badges, {
    data: searchData
  });
};
MilestoneBadges.displayName = "MilestoneBadges";
export const formatMilestone = ({
  id
}, themeName) => {
  const stateObject = getMilestoneById(id);
  let badges = null;
  try {
    badges = div => {
      const root = createRoot(div);
      root.render(_jsx(ThemeContextProvider, {
        themeName: themeName,
        children: _jsx(ShapesProvider, {
          themeName: themeName,
          children: _jsx(MilestoneBadges, {
            id: id
          })
        })
      }));
    };
  } catch (e) {}
  return {
    label: stateObject?.name ?? '',
    url: stateObject?.app_url,
    __extraHTML: badges
  };
};
export const formatMilestones = (milestones, themeName) => milestones.map(milestone => formatMilestone(milestone, themeName));