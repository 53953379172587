import { findTable } from 'components/shared/MarkdownEditor/utils';
import { TableFnsMenu } from '../TableFnsMenu';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const TABLE_FNS_PREFIX = '=';
const TABLE_FNS_REGEX = /^=[a-z]*$/i;
export const TableAutocomplete = {
  hasPrefix: true,
  test: (text, {
    value,
    start
  }) => !!text && TABLE_FNS_REGEX.test(text) && !!findTable(value, start),
  Component: ({
    triggerRef,
    fragment
  }) => _jsx(TableFnsMenu, {
    triggerRef: triggerRef,
    filter: fragment
  })
};