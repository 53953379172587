import { CreateEpicHealthStatusDocument } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { EpicHealthUpdateSelectFragmentDoc } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { useCallback, useRef } from 'react';
import { gql } from '@clubhouse/datalayer';
import { ToastText, addToast } from '@clubhouse/shared/components/Toast';
import { UnstyledButton } from '@clubhouse/shared/components/UnstyledButton';
import { useToggleState } from '@clubhouse/shared/hooks';
import { EpicHealthPopover } from 'components/gql/epics/EpicHealthPopover';
import { useMutation } from 'gql';
import { logError } from 'utils/monitoring';
import { useWorkspaceSlug } from 'utils/navigation';
import { HealthStatusBadge } from '../../shared/healthStatus/HealthStatusBadge';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const EpicHealthUpdateSelectFragment = EpicHealthUpdateSelectFragmentDoc;
const CreateEpicHealthStatus = CreateEpicHealthStatusDocument;
export function EpicHealthUpdateSelect({
  id,
  currentHealthStatus,
  stats,
  onOpen,
  onClose,
  onChangeCurrent,
  onChangeHistory,
  showCommentCount
}) {
  const [isOpen, toggle] = useToggleState(false, open => open ? onOpen?.() : onClose?.());
  const triggerRef = useRef(null);
  const slug = useWorkspaceSlug();
  const [create] = useMutation(CreateEpicHealthStatus);
  const handleSave = useCallback(async (status, text) => {
    try {
      toggle.off();
      const hasComment = text.length > 0;
      await create({
        variables: {
          slug,
          input: {
            epic: id,
            status,
            text: hasComment ? text : null
          }
        }
      });
      onChangeCurrent?.(status, hasComment ? text : null);
    } catch (error) {
      addToast({
        kind: 'alert',
        timeout: 10_000,
        Content: () => _jsx(ToastText, {
          children: "Unable to save epic health."
        })
      });
      logError(error);
    }
  }, [toggle, create, slug, id, onChangeCurrent]);
  return _jsxs(_Fragment, {
    children: [_jsx("div", {
      ref: triggerRef,
      children: _jsx(UnstyledButton, {
        onClick: toggle,
        children: _jsx(HealthStatusBadge, {
          status: currentHealthStatus ?? 'noHealth',
          children: !!showCommentCount && _jsx(HealthStatusBadge.CommentCount, {
            count: stats.numHealthComments
          })
        })
      })
    }), isOpen && _jsx(EpicHealthPopover, {
      triggerRef: triggerRef,
      onClose: toggle.off,
      id: id,
      currentHealthStatus: currentHealthStatus ?? null,
      onSaveCurrent: handleSave,
      onChangeHistory: onChangeHistory
    })]
  });
}