import { Chip } from '@clubhouse/shared/components/Chip';
import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons';
import { pluralize } from '@clubhouse/shared/utils';
import { ChipTooltip } from '../ChipTooltip';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const TooltipContent = ({
  blockersCount,
  hasBlockedLabel
}) => {
  let text = 'This Story ';
  if (blockersCount) {
    text += 'is blocked by ' + pluralize({
      count: blockersCount,
      singular: 'Story',
      plural: 'Stories'
    });
  }
  if (hasBlockedLabel) {
    text += `${blockersCount ? ', and ' : ' '}has the "blocked" label`;
  }
  text += '.';
  return _jsx(ChipTooltip.Text, {
    children: text
  });
};
TooltipContent.displayName = "TooltipContent";
export const BlockedChip = ({
  blockersCount,
  hasBlockedLabel
}) => {
  if (!blockersCount && !hasBlockedLabel) return null;
  const blockedChip = _jsxs(Chip, {
    kind: "red",
    children: [_jsx(DeprecatedIconAdapter, {
      width: 18,
      children: _jsx(Chip.Icon, {
        icon: "StoryBlocked",
        label: "Blocked"
      })
    }), blockersCount || null]
  });
  return _jsx(ChipTooltip, {
    content: _jsx(TooltipContent, {
      blockersCount: blockersCount,
      hasBlockedLabel: hasBlockedLabel
    }),
    children: blockedChip
  });
};
BlockedChip.displayName = "BlockedChip";