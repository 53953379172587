import { useCallback, useMemo } from 'react';
import { StatelessTable } from '@clubhouse/shared/components/Table/StatelessTable';
import { TableGroup } from '@clubhouse/shared/components/Table/TableGroup';
import { ScrollContainerGroup } from '@clubhouse/shared/components/Table/internalComponents/ScrollContainer';
import { useTableBulkSelection } from 'components/gql/bulkSelect/hooks/useTableBulkSelection';
import { LOADING_STATE } from 'components/gql/utils/Loading';
import { StaleLoading } from 'components/shared/StaleLoading';
import { GROUP_BY } from 'components/shared/table/GroupBySelect/stories_graphql';
import { useStoryReordering } from '../../hooks/useStoryReordering';
import { CreateBacklogStoryTableAction } from './CreateBacklogStoryTableAction';
import { StoryRow, StoryRowForInfiniteScroll } from './StoryRow';
import { createGroups } from './groupBy';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const GroupedTable = ({
  group,
  offset,
  isLoading,
  onAddStory,
  infiniteScroll,
  ...props
}) => {
  const onAddStoryToGroup = useCallback(() => {
    onAddStory();
  }, [onAddStory]);
  return _jsx(StatelessTable, {
    HeaderComponent: group.header,
    indexOffset: offset,
    isCollapsible: true,
    initiallyCollapsed: false,
    allItems: group.items,
    items: group.items,
    RowComponent: infiniteScroll ? StoryRowForInfiniteScroll : StoryRow,
    fixedTopRow: _jsx(CreateBacklogStoryTableAction, {
      onClick: onAddStoryToGroup
    }),
    ...props,
    ...(isLoading && LOADING_STATE)
  });
};
GroupedTable.displayName = "GroupedTable";
export const GroupedTables = ({
  groupBy,
  items,
  columns,
  sortColumn,
  sortDirection,
  onSort,
  isLoading,
  isStale,
  onAddStory,
  footer,
  updateQuery,
  infiniteScroll
}) => {
  const [, getIsRowSelected] = useTableBulkSelection();
  const onReorder = useStoryReordering({
    stories: items,
    sortDirection,
    updateQuery
  });
  const groups = useMemo(() => createGroups(groupBy, items), [items, groupBy]);
  let offset = 0;
  return _jsxs(ScrollContainerGroup, {
    children: [groups.map(group => {
      const currOffset = offset;
      offset += group.items.length;
      return _jsx(TableGroup, {
        canReorder: groupBy === GROUP_BY.STATE && sortColumn === 'position',
        items: group.items,
        children: _jsx(GroupedTable, {
          infiniteScroll: infiniteScroll,
          onAddStory: onAddStory,
          group: group,
          offset: currOffset,
          columns: columns,
          onReorder: onReorder,
          isLoading: isLoading,
          onSort: onSort,
          sortDirection: sortDirection,
          sortColumn: sortColumn,
          getIsRowSelected: getIsRowSelected
        })
      }, group.key || 'none');
    }), footer, isStale && _jsx(StaleLoading, {})]
  });
};
GroupedTables.displayName = "GroupedTables";