export const withProgressState = (fn, onProgressChange) => {
  return async (...args) => {
    let result = fn(...args);
    if (result instanceof Promise) {
      onProgressChange(true);
      result = await result;
      onProgressChange(false);
    }
    return result;
  };
};