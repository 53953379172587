// TODO: do we need this component if we migrate empty states to use flex like in StoriesNotFound.js? See ch123874
const exports = {};
exports.EXTRA_OFFSETS = {
  M: 60,
  L: 100
};
exports.getContentHeight = (extraOffset = 0) => {
  const headerSelector = '#header-raven';
  const header = $(headerSelector);
  const headerHeight = header?.outerHeight() || 0;
  const headerTopOffset = header?.offset().top || 0;
  return `calc(100vh - ${headerHeight + headerTopOffset + extraOffset}px)`;
};
export { exports as default };