import { deleteSignup, registerUser } from 'data/entity/signup';
import { redirectFromSignup } from 'utils/createOrgOrWorkspaceController';
export const multiInviteSubmit = async (formData, {
  sso,
  signupId
}) => {
  // We need to remove some fields defined in `CreateAccountFormData`, but that
  // shouldn't be used as-is.
  const {
    password,
    password_confirm,
    name,
    ...restFormData
  } = formData;
  const prunedFormData = {
    ...restFormData,
    user_name: name,
    user_password: password
  };
  try {
    const endpoint = sso ? 'join-sso' : 'join';
    const promises = [registerUser(String(signupId), endpoint, prunedFormData), ...(formData && formData.invite_ids && formData.invite_ids?.length > 0 && signupId ? [deleteSignup(signupId)] : [])];
    return Promise.all(promises);
  } catch (err) {
    return Promise.reject(err);
  }
};
export const redirectSuccess = permissionId => {
  redirectFromSignup(permissionId);
};