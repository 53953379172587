import { useCallback, useRef } from 'react';
import { insertIf as insertObjectIf } from '@clubhouse/shared/utils/object';
import { GROUP_BY } from 'components/shared/table/GroupBySelect/stories_graphql';
import { useSearchParam } from 'utils/navigation';
import { BacklogGroupByConfig } from '../StoriesGroupBySelect';
export const SORT_BY_PARAM_NAME = 'sort_by';
export const SORT_DIR_PARAM_NAME = 'sort_dir';
export function useBacklogTableSortUrlState({
  initialColumn,
  initialDirection,
  onSort,
  validValues
}) {
  const {
    param: currentSortColumn,
    setSearchParam: setCurrentSortColumn
  } = useSearchParam({
    paramName: SORT_BY_PARAM_NAME,
    paramType: 'string',
    defaultValue: initialColumn,
    ...insertObjectIf(!!validValues, {
      validValues
    })
  });
  const {
    param: currentSortDir,
    setSearchParam: setCurrentSortDir
  } = useSearchParam({
    paramName: SORT_DIR_PARAM_NAME,
    paramType: 'string',
    defaultValue: initialDirection,
    validValues: ['ascending', 'descending']
  });
  const {
    param: currentGroupBy,
    setSearchParam: setCurrentGroupBy
  } = useSearchParam(BacklogGroupByConfig);
  const onSortRef = useRef(onSort);
  onSortRef.current = onSort;
  const handleClick = useCallback(column => {
    if (validValues && !validValues.includes(column)) return;
    if (column === currentSortColumn) {
      setCurrentSortDir(currentSortDir === 'ascending' ? 'descending' : 'ascending');
    } else {
      if (column === 'position' && currentGroupBy !== GROUP_BY.STATE) {
        setCurrentGroupBy(GROUP_BY.STATE);
      }
      setCurrentSortColumn(column);
      setCurrentSortDir('ascending');
    }
    if (typeof onSortRef.current === 'function') onSortRef.current({
      oldSort: currentSortColumn,
      newSort: column
    });
  }, [validValues, currentSortColumn, setCurrentSortDir, currentSortDir, currentGroupBy, setCurrentSortColumn, setCurrentGroupBy]);
  return {
    sortColumn: currentSortColumn,
    sortDirection: currentSortDir,
    onSortChange: handleClick
  };
}