import "core-js/modules/es.array.push.js";
import { useCallback, useEffect, useMemo } from 'react';
import { isMac } from '@clubhouse/shared/utils';
import { hasModifierKey, toShortcutString } from '@clubhouse/shared/utils/keyboard';
import { getCommands } from './commands';
import { getSelection } from './utils';

/**
 * Insert the result of a command into the textarea.
 * Simpler commands just define a prefix and (optional) postfix and this function handles insert those at the correct place (based on cursor position).
 * Complex commands can define their own function that will own all logic for how to modify the textarea.
 *
 * @private (only exported for tests)
 */
export const insertFormatting = ({
  fn,
  prefix,
  postfix = ''
}, textarea, setValue) => {
  // Handle more complex commands
  if (fn) return fn(textarea, setValue);

  // Otherwise use default logic
  const {
    before,
    selected,
    after,
    selectionStart,
    selectionEnd
  } = getSelection(textarea);
  const hasPostfix = !!postfix;
  if (hasPostfix && before.endsWith(prefix) && after.startsWith(postfix)) {
    // Remove the formatting
    const newValue = `${before.slice(0, -prefix.length)}${selected}${after.slice(postfix.length)}`;
    setValue(newValue, {
      selectionStart: selectionStart - prefix.length,
      selectionEnd: selectionEnd - prefix.length
    });
    return;
  }
  const currentLine = before.substring(before.lastIndexOf('\n') + 1);
  if (!hasPostfix && currentLine.startsWith(prefix)) {
    // Remove the formatting at the beginning of the line
    const newCurrentLine = currentLine.slice(prefix.length);
    setValue(`${before.substring(0, before.lastIndexOf('\n') + 1)}${newCurrentLine}${selected}${after}`, {
      selectionStart: selectionStart - prefix.length,
      selectionEnd: selectionEnd - prefix.length
    });
    return;
  }

  // Add formatting

  if (hasPostfix) {
    // If selected text ends in line-break, ignore it:
    if (/(?:[^\n]+|^)\n$/.test(selected)) {
      const newValue = `${before}${prefix}${selected.slice(0, -1)}${postfix}\n${after}`;
      setValue(newValue, {
        selectionStart: selectionStart + prefix.length,
        selectionEnd: selectionEnd - 1 + prefix.length
      });
    } else if (/(^[ ]+)|([ ]+$)/.test(selected)) {
      const spacesBefore = selected.match(/^[ ]+/)?.[0] ?? '';
      const spacesAfter = selected.match(/[ ]+$/)?.[0] ?? '';
      const trimmedSelected = selected.trim();
      const newValue = `${before}${spacesBefore}${prefix}${trimmedSelected}${postfix}${spacesAfter}${after}`;
      setValue(newValue, {
        selectionStart: selectionStart + prefix.length + spacesBefore.length,
        selectionEnd: selectionStart + prefix.length + trimmedSelected.length + spacesBefore.length
      });
    } else {
      const newValue = `${before}${prefix}${selected}${postfix}${after}`;
      setValue(newValue, {
        selectionStart: selectionStart + prefix.length,
        selectionEnd: selectionEnd + prefix.length
      });
    }
  } else {
    const newCurrentLine = `${prefix}${currentLine}`;
    setValue(`${before.substring(0, before.lastIndexOf('\n') + 1)}${newCurrentLine}${selected}${after}`, {
      selectionStart: selectionStart + prefix.length,
      selectionEnd: selectionEnd + prefix.length
    });
  }
};

/**
 * Listens for key down events. If the key event is lacking modifiers, it will be ignored. Otherwise the modifiers and the key will be compared against each available command.
 * First matching command will be executed.
 */
const useKeyHandlers = ({
  commands,
  textareaRef,
  setValue
}) => {
  useEffect(() => {
    const commandList = Object.values(commands);
    const onKeyDown = e => {
      if (!textareaRef.current || textareaRef.current !== document.activeElement) return;
      if (!hasModifierKey(e)) return;
      const command = commandList.find(c => {
        if (!c.key) return false;
        const str = toShortcutString(e);
        const modifiers = c.modifiers || (isMac() ? 'cmd' : 'ctrl');
        if (modifiers !== str) return false;
        return c.key === e.key || c.keyAlias === e.key;
      });
      if (!command) return;
      e.preventDefault();
      e.stopPropagation();
      insertFormatting(command, textareaRef.current, setValue);
    };
    document.addEventListener('keydown', onKeyDown, {
      capture: true
    });
    return () => {
      document.removeEventListener('keydown', onKeyDown, {
        capture: true
      });
    };
  }, [commands, setValue, textareaRef]);
};

/**
 * Some commands define their own event listeners. All of these event listeners are setup in this hook. A single listener is setup for each event.
 * Whenever that event is triggered, each listener will be run in turn. Each command is responsible for only running when appropriate, since it might be
 * triggered when another command should be executed as well.
 */
const useEventListeners = ({
  commands,
  textareaRef,
  setValue
}) => {
  useEffect(() => {
    const commandList = Object.values(commands);
    const pressedKeys = {
      Shift: false,
      Meta: false,
      Control: false,
      Alt: false
    };
    const onKeyDown = e => {
      if (typeof pressedKeys[e.key] === 'undefined') return;
      pressedKeys[e.key] = true;
    };
    const onKeyUp = e => {
      if (typeof pressedKeys[e.key] === 'undefined') return;
      pressedKeys[e.key] = false;
    };
    document.addEventListener('keydown', onKeyDown, {
      capture: true
    });
    document.addEventListener('keyup', onKeyUp, {
      capture: true
    });
    const eventHandlers = commandList.reduce((acc, command) => {
      if (!command.events) return acc;
      Object.entries(command.events).forEach(([event, handler]) => {
        acc[event] = acc[event] || [];
        acc[event].push(handler);
      });
      return acc;
    }, {});
    const unsubscribers = [];
    for (const event in eventHandlers) {
      if (!Object.prototype.hasOwnProperty.call(eventHandlers, event)) continue;
      const fn = e => {
        if (e.target !== textareaRef.current) return;
        eventHandlers[event].forEach(handler => handler(e, textareaRef.current, setValue, pressedKeys));
      };
      document.addEventListener(event, fn);
      unsubscribers.push(() => document.removeEventListener(event, fn));
    }
    return () => {
      document.removeEventListener('keydown', onKeyDown, {
        capture: true
      });
      document.removeEventListener('keyup', onKeyUp, {
        capture: true
      });
      unsubscribers.forEach(fn => fn());
    };
  }, [commands, setValue, textareaRef]);
};
export const useCommands = ({
  textareaRef,
  onTextChange,
  disableOpinionatedFeatures = false
}) => {
  const commands = useMemo(() => getCommands({
    disablePasteWithFormatting: disableOpinionatedFeatures,
    disableAutoLists: disableOpinionatedFeatures,
    disableAutoTables: disableOpinionatedFeatures,
    disableAutoQuotes: true
  }), [disableOpinionatedFeatures]);
  const setValue = useCallback((value, extra) => {
    onTextChange(value, extra);
    const {
      current: textarea
    } = textareaRef;
    if (textarea) {
      textarea.value = value;
      const scrollPos = extra?.scrollPos || textarea.scrollTop;
      if (typeof extra?.selectionStart === 'number' || typeof extra?.selectionEnd === 'number') {
        textarea.focus();
        textarea.scrollTop = scrollPos;
      }
      if (typeof extra?.selectionStart === 'number' && typeof extra?.selectionEnd === 'number') textarea.setSelectionRange(extra.selectionStart, extra.selectionEnd);else if (typeof extra?.selectionStart === 'number') textarea.setSelectionRange(extra.selectionStart, extra.selectionStart);else if (typeof extra?.selectionEnd === 'number') textarea.setSelectionRange(textarea.selectionEnd, extra.selectionEnd);
    }
  }, [onTextChange, textareaRef]);
  useKeyHandlers({
    commands,
    textareaRef,
    setValue
  });
  useEventListeners({
    commands,
    textareaRef,
    setValue
  });
  return {
    commands,
    onCommand: useCallback(command => {
      if (textareaRef.current) insertFormatting(command, textareaRef.current, setValue);
    }, [setValue, textareaRef])
  };
};