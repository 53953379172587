import { useTheme } from '@emotion/react';
import { Icon } from '@useshortcut/shapes-ds';
import { DeprecatedIconAdapter } from './DeprecatedIconAdapter';

/**
 * @deprecated Use <Icon icon="InfoCircle" /> from @useshortcut/shapes-ds
 */
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const InfoCircle = ({
  fill,
  width = '12px'
}) => {
  const theme = useTheme();
  return _jsx(DeprecatedIconAdapter, {
    width: width,
    fill: fill || theme.iconBlueColor,
    children: _jsx(Icon, {
      icon: "InfoCircle"
    })
  });
};
InfoCircle.displayName = "InfoCircle";