import { Text } from '@clubhouse/shared/components/Typography/Text';
import { useClearableCurrentFilters } from 'components/gql/filters/useClearableCurrentFilters';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export function EmptyState({
  state
}) {
  const {
    hasClearableFilters
  } = useClearableCurrentFilters();
  return _jsx("div", {
    children: _jsx(Text, {
      color: Text.COLOR.LIGHT,
      size: Text.SIZE.XXSMALL,
      children: `There are no ${state} epics${hasClearableFilters ? ' matching your filters' : ''}.`
    })
  });
}
EmptyState.displayName = "EmptyState";