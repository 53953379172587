import { ICON_DEFAULT_WIDTH } from '../../utils/icon';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const ReportCumulativeFlow = ({
  width = ICON_DEFAULT_WIDTH
}) => _jsxs("svg", {
  width: width,
  height: width,
  viewBox: "0 0 46 46",
  fill: "none",
  children: [_jsx("path", {
    d: "M18.6405 35.2048C25.2609 35.2048 25.2609 21.6804 31.8812 21.6804C38.5016 21.6804 38.5016 35.2048 45.122 35.2048L38.0287 35.2994H15.9924V35.1102L18.6405 35.2048Z",
    fill: "#ADC2DF"
  }), _jsx("path", {
    d: "M1.15 35.65H44.85V34.4893L37.9105 34.586C32.8466 34.586 27.5013 31.0071 25.3444 26.4609C25.2506 26.1707 25.0631 25.8806 24.9693 25.5904C22.9062 20.4638 20.9369 14.9504 16.8107 14.9504C8.93348 14.8536 8.93348 34.3925 1.15 34.3925V35.65Z",
    fill: "#BFDEC7"
  })]
});
ReportCumulativeFlow.displayName = "ReportCumulativeFlow";