import { COLORS } from '@clubhouse/shared/colors';
import { WORKFLOW_STATE_TYPES } from '@clubhouse/shared/constants';
import LabelModel from 'app/client/core/js/models/label';
import { useEntities, useEntityWithFetch, useOptimizedEntities } from './collection';
import { isUsingPoints } from './estimateScale';
export const getCavemanName = () => LabelModel.cavemanName;
export const all = () => LabelModel.all();
export const denormalizeLabels = labels => LabelModel.denormalizeLabels(labels);
export const sortByLowercaseName = labels => LabelModel.sortByLowercaseName(labels);
export const getLabelByGlobalId = globalId => LabelModel.get({
  global_id: globalId
});
export const getLabelById = id => LabelModel.getById(id);
export const isActiveLabel = label => Boolean(label) && !label.archived;
export const useLabels = () => {
  const {
    entities: labels
  } = useEntities({
    model: LabelModel
  });
  return {
    labels
  };
};
export const useOptimizedLabels = ({
  fields,
  filter
}) => useOptimizedEntities({
  model: LabelModel,
  filter,
  fields
});
export const getLabelProgressStats = ({
  stats
}) => {
  return isUsingPoints() ? {
    [WORKFLOW_STATE_TYPES.DONE]: stats.num_points_completed,
    [WORKFLOW_STATE_TYPES.STARTED]: stats.num_points_in_progress,
    [WORKFLOW_STATE_TYPES.UNSTARTED]: stats.num_points_total - (stats.num_points_completed + stats.num_points_in_progress),
    [WORKFLOW_STATE_TYPES.BACKLOG]: stats.num_points_backlog
  } : {
    [WORKFLOW_STATE_TYPES.DONE]: stats.num_stories_completed,
    [WORKFLOW_STATE_TYPES.STARTED]: stats.num_stories_in_progress,
    [WORKFLOW_STATE_TYPES.UNSTARTED]: stats.num_stories_total - (stats.num_stories_completed + stats.num_stories_in_progress),
    [WORKFLOW_STATE_TYPES.BACKLOG]: stats.num_stories_backlog
  };
};
export const getLabelProgressGQL = ({
  stats
}) => {
  return isUsingPoints() ? {
    [WORKFLOW_STATE_TYPES.DONE]: stats.numPointsCompleted,
    [WORKFLOW_STATE_TYPES.STARTED]: stats.numPointsInProgress,
    [WORKFLOW_STATE_TYPES.UNSTARTED]: stats.numPointsUnstarted,
    [WORKFLOW_STATE_TYPES.BACKLOG]: stats.numPointsBacklog
  } : {
    [WORKFLOW_STATE_TYPES.DONE]: stats.numStoriesCompleted,
    [WORKFLOW_STATE_TYPES.STARTED]: stats.numStoriesInProgress,
    [WORKFLOW_STATE_TYPES.UNSTARTED]: stats.numStoriesUnstarted,
    [WORKFLOW_STATE_TYPES.BACKLOG]: stats.numStoriesBacklog
  };
};
export const getLabelEpicsProgressGQL = ({
  stats
}) => {
  return {
    [WORKFLOW_STATE_TYPES.DONE]: stats.numEpicsCompleted,
    [WORKFLOW_STATE_TYPES.STARTED]: stats.numEpicsInProgress,
    [WORKFLOW_STATE_TYPES.UNSTARTED]: stats.numEpicsUnstarted
  };
};
export const getDefaultLabelColor = () => {
  return COLORS.YELLOW82;
};
export const getItemsForLabelsAutocomplete = entity => LabelModel.getItemsForLabelsAutocomplete(entity);
export const addLabelAsString = (existingLabels, labelString) => LabelModel.addLabelAsString(existingLabels, labelString);
export const hasLabel = (entity, label) => LabelModel.hasLabel(entity, label);
export const fetchLabel = id => LabelModel.Promises.fetch({
  id
});
export const useLabelWithFetch = ({
  id,
  shouldFetch
}) => {
  const {
    entity,
    loading
  } = useEntityWithFetch({
    id,
    fetchById: fetchLabel,
    model: LabelModel,
    shouldFetch
  });
  return {
    label: entity,
    loading
  };
};