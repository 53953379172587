import ApplicationState from '../modules/applicationState';
import Async from '../modules/async';
import Backend from '../modules/backend';
import Collection from '../_frontloader/collection';
import ColumnModel from './column';
import Format from '../modules/format';
import ProjectModel from './project';
import Url from '../modules/url';
import WorkflowModel from './workflow';
import { isProjectsFeatureEnabled } from 'data/entity/feature';
import { deprecatedGetDefaultTeamScopedWorkflowForNonReactAccess, deprecatedGetTeamScopedWorkflowsForNonReactAccess } from 'data/entity/team';
import { deprecatedGetTeamScopeIdForNonReactAccess } from 'components/team-navigation';
import { getTeamScopedApplicationStateKey } from 'components/team-navigation/utils/getTeamScopedApplicationStateKey';
import _ from 'lodash';
const exports = {};

/*

Example Team entity:

{
  "entity_type": "team",
  "id": 13575,
  "created_at": "2016-11-24T20:40:27Z",
  "updated_at": "2016-11-24T20:40:27Z",
  "name": null,
  "description": null,
  "position": 1,
  "workflow_id": 1488,
  "event_ids": [4058,4059],
  "project_ids": [13574,274,10001053886,723,1283,1079,15687,42,6147,1289]
}

*/

Collection.create('Team', exports);
exports.normalize = team => {
  // TODO: project_ids includes null values!
  // Remove me once this is fixed on the server.
  team.project_ids = _.compact(team.project_ids);
};
exports.allSortedByName = () => {
  return exports.sortBy((t // Default teams have a null name
  ) => {
    return (t.name || 'Default').toLowerCase();
  });
};
exports.fetchAll = callback => {
  Backend.get('/api/private/teams', {
    onComplete: res => {
      if (_.isArray(res)) {
        const workflows = _.map(res, 'workflow');
        WorkflowModel.fetchAllHandler(workflows);
      }
      exports.defaultFetchAllHandler(res, callback);
    }
  });
};

/*
- name
- description (optional)
- global_workflow_id (optional)
- team_id (optional)
- project_ids (optional)
*/
exports.saveNew = (team, callback) => {
  Backend.post('/api/private/teams', {
    data: team,
    onComplete: res => {
      // Projects may have been moved
      const fns = [ProjectModel.fetchAll, exports.fetchAll];
      Async.eachInParallelThen(fns, () => {
        exports.defaultGetHandler(res, callback, 'teamSaved');
      });
    }
  });
};

/*
// Not in use, endpoint will probably be removed.
exports.setAsDefault = function (team, callback) {
  Backend.put('/api/private/teams/' + team.id + '/default', {
    onComplete: function (res, xhr) {
      exports.defaultNoResponseHandler(res, xhr, callback);
    }
  });
};
*/

/*
- name
- description
- before_id
- after_id
*/
exports.updateTeam = (team, updates, callback) => {
  Backend.put('/api/private/teams/' + team.id, {
    data: updates,
    onComplete: res => {
      exports.defaultGetHandler(res, callback, 'teamUpdated');
    }
  });
};
exports.deleteTeam = (team, callback) => {
  Backend.delete('/api/private/teams/' + team.id, {
    onComplete: (res, xhr) => {
      exports.defaultDeleteHandler(team, res, xhr, callback);
    }
  });
};
exports.activeTeamHasProjects = context => {
  const team = exports.getActive(context);
  return exports.getProjectsForTeam(team).length > 0;
};
exports.getProjectsForTeam = team => {
  const projects = ProjectModel.filter({
    team_id: team.id
  });
  return _.sortBy(projects, 'lowercase_name');
};
exports.getActiveProjectsForTeam = team => {
  const projects = ProjectModel.filter({
    team_id: team.id,
    archived: false
  });
  return _.sortBy(projects, 'lowercase_name');
};
exports.getWorkflowForTeam = team => {
  return WorkflowModel.getById(team.workflow.id);
};
exports.getWorkflowStatesForTeam = team => {
  return ColumnModel.allFromWorkflow(team.workflow);
};
exports.isActive = (team, context) => {
  return team.id === exports.getActive(context).id;
};
exports.setActive = (team, context) => {
  context = context || Url.getCurrentPage();

  // This can happen if user opens a story in a new tab and closes it.
  if (context === 'story') {
    context = 'stories';
  }
  const teamId = deprecatedGetTeamScopeIdForNonReactAccess();
  const appStateKey = exports.getWorkflowApplicationStateKey(teamId, context);
  return ApplicationState.set(appStateKey, team.id);
};
exports.setActiveTeamById = (teamId, teamContext = 'stories') => {
  if (!teamId) {
    return;
  }
  const activeTeam = exports.getActive(teamContext);
  if (activeTeam.id !== teamId) {
    const team = exports.getById(teamId);
    exports.setActive(team, teamContext);
  }
};
exports.getActive = context => {
  context = context || Url.getCurrentPage();

  // This can happen if user opens a story in a new tab and closes it.
  if (context === 'story') {
    context = 'stories';
  }
  const teamId = deprecatedGetTeamScopeIdForNonReactAccess();
  const appStateKey = exports.getWorkflowApplicationStateKey(teamId, context);
  const storedActiveWorkflowId = ApplicationState.get(appStateKey);
  const activeByTeamScope = exports.getById(storedActiveWorkflowId) ?? deprecatedGetDefaultTeamScopedWorkflowForNonReactAccess();
  return activeByTeamScope ?? exports.getDefaultTeam();
};
exports.getDefaultTeam = () => exports.first();
exports.teamContainsProject = (team, projectId) => {
  return team.project_ids.some(id => {
    return id === projectId;
  });
};
exports._teamsAreCompatible = (team1, team2) => {
  const states1 = exports.getWorkflowStatesForTeam(team1);
  const states2 = exports.getWorkflowStatesForTeam(team2);
  const teamsAreSameLength = states1.length === states2.length;
  const teamsAreSameNames = _.map(states1, 'name').join() === _.map(states2, 'name').join();
  return teamsAreSameLength && teamsAreSameNames;
};
exports.getItemsForAutocomplete = options => {
  options = options || {};
  const workflows = options.filterByTeamScope ? deprecatedGetTeamScopedWorkflowsForNonReactAccess() : exports.all();

  // Let's ensure all states are sorted by position before the _teamsAreCompatible check.
  // They should, unless a column was just added or updated.
  ColumnModel.sortByPosition();
  return workflows.map(team => {
    const projects = ProjectModel.allNotArchivedInTeam(team);
    let icon = '';
    if (options.onlyAllowCompatible && team.id !== options.activeTeam.id) {
      icon = exports._teamsAreCompatible(options.activeTeam, team) ? '<span class="fa fa-check-circle compatibility-icon" data-tooltip="This Project can be moved to ' + 'this Team, because the two Workflows are the same."></span>' : '<span class="fa fa-exclamation-triangle compatibility-icon" data-tooltip="This Project cannot be ' + 'moved to this Team, because the two Workflows are not the same."></span>';
    }
    const projectLengthNote = '<div class="team-dropdown-details">' + `${isProjectsFeatureEnabled() ? Format.pluralize(projects.length, 'Project', 'Projects') + ', ' : ''}` + team.workflow.states.length + ' Workflow States' + '</div>';
    return {
      name: '<div class="team-dropdown">' + Format.sanitize(team.name) + icon + projectLengthNote + '</div>',
      className: options.activeTeam.id === team.id ? 'active' : '',
      value: team
    };
  });
};
exports.getLabel = ({
  isPlural = false
} = {}) => {
  return isPlural ? 'Workflows' : 'Workflow';
};
exports.getWorkflowApplicationStateKey = (teamScopeId, context) => {
  return getTeamScopedApplicationStateKey(teamScopeId, `ActiveTeam.${context}`);
};
export { exports as default };