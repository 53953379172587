import { ToastProvider } from '@clubhouse/shared/components/Toast';
import { UserThemeProvider } from '@clubhouse/shared/utils/theme';
import { LaunchDarklyProvider } from 'utils/launch-darkly';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const AppContext = ({
  children
}) => {
  return _jsx(LaunchDarklyProvider, {
    children: _jsx(UserThemeProvider, {
      children: _jsx(ToastProvider, {
        children: children
      })
    })
  });
};
AppContext.displayName = "AppContext";