import { useSearchParam } from 'utils/navigation';
import Url from '../../../modules/url';
const paramName = 'cf_workflow';
export const CUMULATIVE_FLOW_CONFIG_OPTIONS = {
  cf_aggregate: {
    title: 'Sum using: ',
    defaultValue: 'stories',
    values: [{
      name: 'Points',
      value: 'points'
    }, {
      name: 'Story count',
      value: 'stories'
    }]
  },
  cf_workflow: {
    title: 'Workflow: ',
    defaultValue: undefined,
    values: []
  }
};
export const useCumulativeFlowParam = () => {
  const {
    param
  } = useSearchParam({
    paramName,
    paramType: 'string'
  });
  return param;
};
export const updateCumulativeFlowWorkflowConfig = (workflows = [], value = Url.getParamFromUrl(paramName)) => {
  const config = CUMULATIVE_FLOW_CONFIG_OPTIONS.cf_workflow;
  config.values = workflows.map(wf => ({
    name: wf.name,
    value: `${wf.id}`
  }));
  if (!value || !config.values.some(wf => wf.value === value)) {
    config.defaultValue = config.values[0]?.value;
    Url.updateParamInUrl(paramName, config.defaultValue);
  }
};
export const getCumulativeFlowChartConfig = key => {
  return Url.getParamFromUrl(key) || CUMULATIVE_FLOW_CONFIG_OPTIONS[key]?.defaultValue;
};