import { useEffect, useState } from 'react';
import { Fragment as _Fragment, jsx as _jsx } from "@emotion/react/jsx-runtime";
/**
 * Renders children after a delay.
 *
 * @example
 * ```tsx
 * <Delayed delay={1000}>
 *   <div>Rendered after 1 second</div>
 * </Delayed>
 * ```
 */
export function Delayed({
  delay,
  children
}) {
  const [shouldRender, setShouldRender] = useState(false);
  useEffect(() => {
    setShouldRender(false);
    const timeout = setTimeout(() => {
      setShouldRender(true);
    }, delay);
    return () => {
      clearTimeout(timeout);
    };
  }, [delay]);
  if (!shouldRender) {
    return null;
  }
  return _jsx(_Fragment, {
    children: children
  });
}