import "core-js/modules/es.array.push.js";
import emojione from 'emojione';
import { DISABLED_SHORTNAMES } from './constants';
export const emoji = emojione;
const curateEmojiList = () => {
  DISABLED_SHORTNAMES.forEach(shortname => {
    delete emoji.emojioneList[':' + shortname + ':'];
  });
};
export const setAsSingleImage = emojiPath => {
  emoji.imageType = 'png';
  emoji.imagePathPNG = emojiPath;
  emoji.sprites = false;
  emoji.unicodeAlt = false;
};
export const setAsSprite = () => {
  emoji.imageType = 'png';
  emoji.sprites = true;
  emoji.unicodeAlt = false;
};
export const init = emojiPath => {
  curateEmojiList();
  setAsSingleImage(emojiPath);
};
export const parseSkinTones = shortname => {
  const arr = shortname.split(':');
  return arr.reduce((result, part, i) => {
    if (part.slice(0, -1) === 'skin-tone-') {
      const parts = part.split('skin-tone-');
      const replacement = arr[i - 2] + '_tone' + (Number.parseInt(parts[1], 10) - 1);
      if (emoji.emojioneList[':' + replacement + ':']) {
        result.splice(result.length - 2, 2);
        result.push(replacement);
      } else {
        result.push(part);
      }
    } else {
      result.push(part);
    }
    return result;
  }, []).join(':');
};
export const convert = shortname => {
  return emoji.shortnameToImage(parseSkinTones(shortname));
};
export const isEmojiCodeSupported = code => {
  if (!code.startsWith(':')) code = ':' + code;
  if (!code.endsWith(':')) code = code + ':';
  return new RegExp(emoji.shortnames).test(code);
};
export const getEmojiCodeFromUnicode = unicode => {
  const entry = Object.entries(emoji.emojioneList).find(([, value]) => (value.unicode || []).includes(unicode));
  if (entry) return entry[0];
  return null;
};