import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons/DeprecatedIconAdapter';
import { IterationStatus } from '@clubhouse/shared/types';
import { Icon } from '@useshortcut/shapes-ds';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const IterationIcon = ({
  width,
  fill
}) => _jsx(DeprecatedIconAdapter, {
  fill: fill,
  width: width,
  children: _jsx(Icon, {
    icon: "Iteration"
  })
});
IterationIcon.displayName = "IterationIcon";
const iconColorMap = {
  [IterationStatus.UNSTARTED]: 'var(--iconGrayHoverColor)',
  [IterationStatus.STARTED]: 'var(--iconBlueColor)',
  [IterationStatus.DONE]: 'var(--iconGreenColor)'
};
export const IterationStatusIcon = ({
  iteration,
  width
}) => {
  const color = iconColorMap[iteration?.status || IterationStatus.UNSTARTED];
  return _jsx(IterationIcon, {
    fill: color,
    width: width
  });
};
IterationStatusIcon.displayName = "IterationStatusIcon";