import { Chip } from '@clubhouse/shared/components/Chip';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const MentionName = ({
  mentionName
}) => {
  if (!mentionName) return null;
  return _jsx("div", {
    children: _jsx(Chip, {
      kind: "default",
      children: `@${mentionName}`
    })
  });
};
MentionName.displayName = "MentionName";