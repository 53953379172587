import { QueryApplicationStateDocument } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { useQuery } from 'gql';
import { useWorkspaceSlug } from 'utils/navigation';
export const QUERY_APPLICATION_STATE = QueryApplicationStateDocument;

/**
 * @description Prefer `useApplicationStateByKey`.
 */
export const useApplicationState = ({
  onCompleted,
  fetchPolicy
} = {}) => {
  const slug = useWorkspaceSlug();
  const {
    data,
    updateQuery,
    refetch,
    loading,
    error
  } = useQuery(QUERY_APPLICATION_STATE, {
    variables: {
      slug: slug
    },
    onCompleted,
    fetchPolicy,
    skip: !slug
  });
  return {
    parsedCurrentUserPermissionSpaceData: data?.workspace2?.parsedCurrentUserPermissionSpaceData,
    updateQuery,
    loading,
    error,
    refetch
  };
};