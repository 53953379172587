import { DisplayIconFragmentFragmentDoc } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { BrandIcon, CustomFieldIcon, PriorityIcon, SeverityIcon, ShortcutFieldIcon, StatusIcon } from '@useshortcut/shapes-ds';
import { gql } from '@clubhouse/datalayer';
import { DisplayIcon as DisplayIconComponent } from '@clubhouse/shared/components/DisplayIcon';
import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons';
import { getColor } from '../Color';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const DisplayIconFragment = DisplayIconFragmentFragmentDoc;
const isInArray = (str, arr) => {
  return !!str && Boolean(arr.find(s => s === str));
};
export const DisplayIcon = ({
  iconIdentifier,
  color,
  size,
  fill,
  ...props
}) => {
  if (isInArray(iconIdentifier, StatusIcon.validIconProp)) {
    return _jsx(DeprecatedIconAdapter, {
      fill: fill,
      width: size,
      children: _jsx(StatusIcon, {
        icon: iconIdentifier
      })
    });
  } else if (isInArray(iconIdentifier, DisplayIconComponent.validIconProp)) {
    return _jsx(DisplayIconComponent, {
      ...props,
      iconIdentifier: iconIdentifier,
      fill: fill ?? getColor(color),
      size: size
    });
  } else if (isInArray(iconIdentifier, SeverityIcon.validIconProp)) {
    return _jsx(DeprecatedIconAdapter, {
      width: size,
      children: _jsx(SeverityIcon, {
        icon: iconIdentifier
      })
    });
  } else if (isInArray(iconIdentifier, PriorityIcon.validIconProp)) {
    return _jsx(DeprecatedIconAdapter, {
      width: size,
      children: _jsx(PriorityIcon, {
        icon: iconIdentifier
      })
    });
  } else if (isInArray(iconIdentifier, CustomFieldIcon.validIconProp)) {
    return _jsx(DeprecatedIconAdapter, {
      fill: getColor(color),
      width: size,
      children: _jsx(CustomFieldIcon, {
        icon: iconIdentifier
      })
    });
  } else if (isInArray(iconIdentifier, ShortcutFieldIcon.validIconProp)) {
    return _jsx(DeprecatedIconAdapter, {
      width: size,
      children: _jsx(ShortcutFieldIcon, {
        icon: iconIdentifier
      })
    });
  } else if (isInArray(iconIdentifier, BrandIcon.validIconProp)) {
    return _jsx(DeprecatedIconAdapter, {
      width: size,
      children: _jsx(BrandIcon, {
        icon: iconIdentifier
      })
    });
  } else {
    return null;
  }
};