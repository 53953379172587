import { getById, useTeamScopedTeam } from 'data/entity/group';
import { getAllActiveProfileDetails } from 'data/entity/profile';
import { TeamTooltip } from '@clubhouse/shared/components/TeamTooltip';
import { TeamCohort } from '@clubhouse/shared/components/TeamCohort';
import { TeamIcon } from '@clubhouse/shared/components/TeamIcon';
import { getTeamColor } from '@clubhouse/shared/utils/teams';
import { Nouns } from '@clubhouse/shared/constants';
import { getNumStories } from 'data/entity/iteration';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const Teams = ({
  iteration
}) => {
  const hasTeamOrAssociatedTeams = iteration.group_ids && iteration.group_ids.length || iteration.associated_groups && iteration.associated_groups.length;
  if (!hasTeamOrAssociatedTeams) return null;
  const owningTeam = getById(iteration.group_ids[0]);
  const associatedGroups = iteration.associated_groups || [];
  const users = getAllActiveProfileDetails();
  const teamScopeId = useTeamScopedTeam()?.id;
  const associatedGroupsWithGroup = associatedGroups.map(({
    group_id,
    associated_stories_count
  }) => {
    const group = getById(group_id);
    if (!group) return null;
    return {
      group: {
        id: group.id,
        name: group.name,
        imageUrl: group.display_icon?.url,
        colorKey: group.color_key
      },
      numStoriesOwned: associated_stories_count
    };
  }).filter(group => Boolean(group));
  let primaryTeam;
  let otherTeams = associatedGroupsWithGroup;
  if (teamScopeId) {
    primaryTeam = associatedGroupsWithGroup.find(({
      group
    }) => group.id === teamScopeId && owningTeam?.id !== teamScopeId);
    if (primaryTeam) {
      otherTeams = associatedGroupsWithGroup.filter(({
        group
      }) => group.id !== primaryTeam?.group?.id);
    }
  }
  const displayTeams = primaryTeam ? [primaryTeam, ...otherTeams] : otherTeams;
  const owningTeamNumberOfStories = associatedGroupsWithGroup.reduce((acc, {
    numStoriesOwned
  }) => acc - numStoriesOwned, getNumStories(iteration));
  return _jsx(TeamTooltip, {
    associatedEntityType: Nouns.Iteration.singular,
    associatedTeams: displayTeams,
    team: owningTeam,
    users: users,
    owningTeamNumberOfStories: owningTeamNumberOfStories,
    children: _jsx("div", {
      children: _jsxs(TeamCohort, {
        size: 24,
        children: [_jsx(TeamIcon, {
          backgroundColor: getTeamColor(owningTeam),
          iconUrl: owningTeam?.display_icon?.url,
          alt: owningTeam?.name
        }, owningTeam?.id), displayTeams.map(({
          group
        }) => {
          return _jsx(TeamIcon, {
            iconUrl: group?.imageUrl,
            backgroundColor: getTeamColor(group),
            alt: group?.name
          }, group?.id);
        })]
      })
    })
  });
};
Teams.displayName = "Teams";