import View from '../modules/view';
import { GroupDropdown, KIND } from 'components/groups/GroupDropdown';
const exports = {};
exports.render = ({
  key,
  onChange
}) => {
  return View.renderComponentDelayed({
    componentKey: key,
    component: GroupDropdown,
    props: {
      onChange,
      kind: KIND.QUICK_FILTER
    }
  }).html;
};
export { exports as default };