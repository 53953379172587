import { constructClearFiltersInteractionEvent, constructFilterInteractionEvent } from 'utils/reportsFilterInteractionEvent';
import { EVENTS, logEvent } from './monitoring';
import { getCurrentPage } from './navigation';
export const sendEvent = (...args) => logEvent(...args);
const CONFIG_ID_TO_INTERACTION_TYPE = {
  vc_aggregate: 'sum_using',
  vc_group_by: 'group by',
  cc_aggregate: 'sum_using',
  wf_state_workflow: 'workflow',
  wf_state_type: 'type',
  wf_state_calculation: 'calculation',
  ct_type: 'type',
  ct_scale: 'scale',
  ct_workflow: 'workflow',
  ct_workflow_states: 'workflow states',
  cf_aggregate: 'sum_using',
  cf_workflow: 'workflow'
};
export const sendReportsEvent = ({
  chart_type,
  interaction_type,
  selection
}) => {
  const page = getCurrentPage();
  const source = page === 'reports' ? 'reports page' : `${page} detail page`;
  sendEvent(EVENTS.Interaction_Reporting_Chart, {
    source,
    chart_type,
    interaction_type,
    selection
  });
};
export const sendReportsConfigurationEvent = ({
  displayed_charts
}) => {
  sendEvent(EVENTS.Interaction_Reporting_Chart, {
    source: 'reports page',
    interaction_type: 'configure reports page',
    displayed_charts
  });
};
export const sendVelocityEvent = ({
  configId,
  selection
}) => {
  let selectionLabel = selection;

  // TODO: @melissakeller Remove 'stories' when old Velocity chart is removed
  if (configId === 'vc_aggregate' && (selection === 'stories' || selection === 'count')) {
    selectionLabel = 'story count';
  }
  sendReportsEvent({
    chart_type: 'Velocity',
    interaction_type: CONFIG_ID_TO_INTERACTION_TYPE[configId],
    selection: selectionLabel
  });
};
export const sendBurndownEvent = ({
  interaction_type,
  selection
}) => {
  sendReportsEvent({
    chart_type: 'burndown',
    interaction_type,
    selection
  });
};
export const sendTimeSpentInWFStateEvent = ({
  configId,
  selection
}) => {
  sendReportsEvent({
    chart_type: 'time spent',
    interaction_type: CONFIG_ID_TO_INTERACTION_TYPE[configId],
    selection
  });
};
export const sendCreatedVsCompletedEvent = ({
  configId,
  selection
}) => {
  sendReportsEvent({
    chart_type: 'Created vs. Completed',
    interaction_type: CONFIG_ID_TO_INTERACTION_TYPE[configId],
    selection: selection === 'count' ? 'story count' : selection
  });
};
export const sendCumulativeFlowEvent = ({
  configId,
  selection
}) => {
  sendReportsEvent({
    chart_type: 'Cumulative Flow',
    interaction_type: CONFIG_ID_TO_INTERACTION_TYPE[configId],
    selection: selection === 'count' ? 'story count' : selection
  });
};
export const sendCycleTimeEvent = ({
  configId,
  properties = {}
}) => {
  const page = getCurrentPage();
  const source = page === 'reports' ? 'reports page' : `${page} detail page`;
  sendEvent(EVENTS.Interaction_Reporting_Chart, {
    source,
    chart_type: 'Cycle Time / Lead Time',
    interaction_type: CONFIG_ID_TO_INTERACTION_TYPE[configId],
    ...properties
  });
};
export const sendReportsFilterEvent = ({
  key,
  prev,
  next
}) => {
  sendEvent(EVENTS.Interaction_Reporting_Filter, {
    event_trigger_location: 'reports page',
    ...constructFilterInteractionEvent({
      key,
      prev,
      next
    })
  });
};
export const sendClearFiltersEvent = () => sendEvent(EVENTS.Interaction_Reporting_Filter, {
  event_trigger_location: 'reports page',
  ...constructClearFiltersInteractionEvent()
});
export const sendStoriesViewSettingsEvent = ({
  setting_type,
  selection
}) => {
  sendEvent(EVENTS.Interaction_StoriesPage_ViewSettings, {
    event_trigger_location: 'stories page',
    interaction_type: 'view settings',
    setting_type,
    selection
  });
};