import { useToast as sharedUseToast } from '@clubhouse/shared/components/Toast';
import { CanceledError } from '@clubhouse/shared/hooks';
const noop = () => null;
export const useToast = ({
  success = noop,
  failure = noop,
  fn
}) => {
  const {
    addToast
  } = sharedUseToast();
  return async (...args) => {
    try {
      const value = await fn(...args);
      const element = success({
        value
      });
      if (element) addToast({
        timeout: 6000,
        kind: 'success',
        Content: () => element
      });
      return value;
    } catch (e) {
      if (e instanceof CanceledError && e.isCanceled) {
        throw e;
      } else {
        let err = undefined;
        if (e instanceof Error && 'responseIntercepted' in e) {
          err = e;
        } else if (typeof e === 'string') {
          err = new Error(e);
        }
        const element = failure({
          e: err
        });
        if (element) addToast({
          timeout: 6000,
          kind: 'alert',
          Content: () => element
        });
      }
      throw e;
    }
  };
};