import { useCallback } from 'react';
import { saveChanges } from 'data/entity/story';
import { storyType } from 'utils/sort';
import { FIELD_ID } from 'components/shared/table/types';
import { TableStoryType } from './tableComponents';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export function TypeFieldComponent({
  entity
}) {
  const save = useCallback(([newStoryType]) => {
    saveChanges(entity.id, {
      story_type: newStoryType
    });
  }, [entity.id]);
  return _jsx(TableStoryType, {
    onChange: save,
    type: entity.story_type
  });
}
TypeFieldComponent.displayName = "TypeFieldComponent";
export const TypeField = () => ({
  name: FIELD_ID.TYPE,
  displayName: 'Type',
  sort: storyType,
  Component: TypeFieldComponent,
  width: 120
});