import noop from 'lodash/noop';
import { emptyArray } from '@clubhouse/shared/utils/emptyArray';
import { WorkflowStateSelect } from 'components/shared/WorkflowStateSelect';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const TableState = ({
  isSelectionDisabled = false,
  onChange = noop,
  state,
  states = emptyArray
}) => {
  return _jsx(WorkflowStateSelect, {
    isSelectionDisabled: isSelectionDisabled,
    onChange: onChange,
    state: state,
    states: states
  });
};
TableState.displayName = "TableState";