import "core-js/modules/es.array.push.js";
import BaseUtils from '../_frontloader/baseUtils';
import LocalStorage from './localStorage';
import Log from './log';
import Utils from './utils';
import _ from 'lodash';
const exports = {};
const STORAGE_PREFIX = 'TextareaSnapshot';

// We throttle this function because it is called on every story render,
// and the request to LocalStorage can be relatively expensive.
exports.getDirtyFieldIds = _.throttle(() => {
  const keys = LocalStorage.getKeys();
  return keys.filter(key => key.indexOf(STORAGE_PREFIX) !== -1).map(key => BaseUtils.toNumber(key.split(STORAGE_PREFIX)[1]));
}, 100);
const generateEmptyState = (prefix = exports.getStorageKey()) => ({
  [prefix]: null,
  [`${prefix}Height`]: null,
  [`${prefix}SelectStart`]: null,
  [`${prefix}SelectEnd`]: null,
  [`${prefix}InitialState`]: null
});
exports.getStorageKey = (entity = {}, snapshotType = '') => {
  const key = [STORAGE_PREFIX];
  if (snapshotType) {
    key.push(snapshotType);
  } else {
    Log.warn('Deprecated behavior: Always pass a prefix for Snapshot storage');
  }
  if (entity.id) key.push(entity.id);

  // Handle legacy behavior where the root key always ended with a dot
  return key.length === 1 ? `${STORAGE_PREFIX}.` : key.join('.');
};
exports.clearSnapshot = (obj, snapshotType) => {
  if (snapshotType) {
    // TODO: Why don't we just remove this from localstorage?
    exports.setSnapshot(obj, generateEmptyState(snapshotType));
  } else {
    Log.warn('Deprecated behavior: Always pass a prefix for Snapshot storage');
    LocalStorage.remove(exports.getStorageKey(obj), {
      prefix: true
    });
  }
};
exports.clearPartialSnapshot = (obj, snapshotType) => {
  if (snapshotType) {
    exports.setSnapshot(obj, {
      ...exports.getSnapshot(obj),
      ...generateEmptyState(snapshotType)
    });
  } else {
    Log.warn('Deprecated behavior: Always pass a prefix for Snapshot storage');
    LocalStorage.remove(exports.getStorageKey(obj), {
      prefix: true
    });
  }
};

// NOTE: This does some weird stuff, setSnapshot is a bit more predictable
exports.putSnapshot = (obj, state) => {
  const existingState = exports.getSnapshot(obj);
  state = _.isPlainObject(state) ? _.assignIn({}, existingState, state) : existingState;
  const compactState = _.pickBy(state, val => {
    return Utils.exists(val);
  });
  if (_.isEmpty(compactState)) {
    LocalStorage.remove(exports.getStorageKey(obj), {
      prefix: true
    });
  } else {
    LocalStorage.set(exports.getStorageKey(obj), compactState, {
      prefix: true
    });
  }
};
exports.setSnapshot = (obj, state, type) => {
  const storageKey = exports.getStorageKey(obj, type || obj.entityType || null);
  const normalizedState = {
    ...generateEmptyState(storageKey),
    ...(_.isPlainObject(state) ? state : {})
  };
  const compactState = Object.entries(normalizedState).filter(([, value]) => Utils.exists(value)).reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key]: value
    };
  }, {});
  if (Object.keys(compactState).length) {
    LocalStorage.set(storageKey, compactState, {
      prefix: true
    });
  } else {
    LocalStorage.remove(storageKey, {
      prefix: true
    });
  }
};
exports.getSnapshot = (obj, type) => {
  const storageKey = exports.getStorageKey(obj, type || obj.entityType || null);
  const snapshot = LocalStorage.get(storageKey, {
    prefix: true
  });
  return Utils.exists(snapshot) ? Utils.parseJSON(snapshot) : {};
};
exports.hasSnapshot = obj => {
  const snapshot = exports.getSnapshot(obj);
  return snapshot && Object.keys(snapshot).length > 0;
};
exports.restoreSnapshot = (entity, callback) => {
  callback(exports.getSnapshot(entity), entity);
};
export { exports as default };