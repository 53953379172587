import Collection from '../_frontloader/collection';
import _ from 'lodash';
const exports = {};
Collection.create('Panel', exports);
exports.findByTarget = target => {
  const element = _.isElement(target) ? target : target[0];
  return exports.get(panel => {
    return panel.target[0] === element;
  });
};
export { exports as default };