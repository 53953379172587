import { StoryContextMenuQueryDocument } from "../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { useQuery } from 'gql';
const StoryContextMenuQuery = StoryContextMenuQueryDocument;
export const useFetchStoryContextMenuData = ({
  id
}) => {
  const {
    data,
    previousData
  } = useQuery(StoryContextMenuQuery, {
    variables: {
      storyId: id
    }
  });
  const normalizedData = data || previousData;
  if (normalizedData?.node?.__typename !== 'Story') return null;
  return normalizedData.node;
};