import { Spaced } from '@clubhouse/shared/components/Spaced';
import { DetailPageSidebarDivider } from './DetailPageSidebarDivider';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export function DetailPageSidebarSection({
  children,
  divider
}) {
  return _jsxs(_Fragment, {
    children: [!!divider && divider !== 'bottom' && _jsx(DetailPageSidebarDivider, {}), _jsx(Spaced, {
      vertically: true,
      amount: "space1",
      children: children
    }), !!divider && divider !== 'top' && _jsx(DetailPageSidebarDivider, {})]
  });
}