import "core-js/modules/es.array.push.js";
window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || {
    Controller: {},
    Model: {}
  };
  [[['Utils'], Utils], [['Utils'], Utils]].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});
import { insertIf as insertObjectIf } from '@clubhouse/shared/utils/object';
import * as EmptyTaskTemplate from 'app/client/core/views/templates/emptyTask.html';
import * as StoryTasksTemplate from 'app/client/core/views/templates/storyTasks.html';
import * as TaskTemplate from 'app/client/core/views/templates/task.html';
import * as TaskActionsTemplate from 'app/client/core/views/templates/taskActions.html';
import AddNewStoryController from './addNewStory';
import AutocompleteComponentsController from './autocompleteComponents';
import ContextMenuController from './contextMenu';
import InPlaceTextareaController from './inPlaceTextarea';
import MessageController from './message';
import StoryController from './story';
import StoryDialogController from './storyDialog';
import * as Event from '../_frontloader/event';
import Globals from '../_frontloader/globals';
import StoryModel from '../models/story';
import TaskModel from '../models/task';
import UserModel from '../models/user';
import Autosize from '../modules/autosize';
import Caret from '../modules/caret';
import Dialog from '../modules/dialog';
import Format from '../modules/format';
import Is from '../modules/is';
import Utils from '../modules/utils';
import View from '../modules/view';
import _ from 'lodash';
const exports = {};
const EVENT_NS = '.TaskController';
exports.init = () => {
  TaskModel.on('taskDeleted', task => {
    exports.removeTaskElement(task);
  });
  Event.onlyOn('afterStoryDetailsRender' + EVENT_NS, storyElement => {
    exports.initSortable(storyElement.find('.sortable-tasks'));
  });
  Event.onlyOn('storyElementTearDown' + EVENT_NS, storyElement => {
    const sortableElement = $(storyElement).find('.sortable-tasks.ui-sortable');
    if (sortableElement.length > 0) {
      Utils.destroySortable(sortableElement);
    }
  });
};
exports.redrawTaskElement = task => {
  const element = TaskModel.toElement(task);
  if (element.length > 0) {
    const html = TaskTemplate.render(task);
    const newElement = View.replaceElement(element, html);
    Utils.autoTabIndex(newElement.closest('.story'));
  }
  exports.redrawTaskCounts();
};
exports.removeTaskElement = task => {
  const element = TaskModel.toElement(task);
  if (element.length > 0) {
    element.fadeOut(120, function () {
      $(this).remove();
    });
    Utils.autoTabIndex(element.closest('.story'));
  }
  exports.redrawTaskCounts();
};
exports.resetTasks = () => {
  exports.cancelNewTask();
  exports.redrawAllTasks({
    tasks: []
  });
  exports.redrawTaskCounts();
};
exports.redrawTaskCounts = () => {
  exports.redrawStoryTaskCount();
  exports.redrawNewStoryTaskCount();
};
exports.redrawStoryTaskCount = () => {
  const element = Utils.getFirstElementWithPermaID('story-dialog-task-count');
  const story = StoryDialogController.getCurrentlyDisplayedStory();
  if (element && story) {
    redrawTaskCountElement(element, story);
  }
};
exports.redrawNewStoryTaskCount = () => {
  const element = Utils.getFirstElementWithPermaID('new-story-dialog-task-count');
  if (element) {
    const story = AddNewStoryController.getState();
    redrawTaskCountElement(element, story);
  }
};
function redrawTaskCountElement(element, story) {
  element.innerHTML = story.tasks && story.tasks.length > 0 ? `(${StoryModel.getNumberOfCompletedTasks(story)}/${story.tasks.length})` : '';
}
exports.redrawAllTasks = story => {
  const html = StoryTasksTemplate.render(story);
  if (story.id) {
    $('.story-' + story.id + ' .tasks .sortable-tasks').html(html);
  } else {
    $('.add-new-story .tasks .sortable-tasks').html(html);
  }
};
exports.renderNewTaskElement = task => {
  const storyElement = task.storyElement || TaskModel.getStoryElement(task);
  delete task.storyElement;
  exports.redrawEmptyTaskElement(storyElement);
  if (storyElement.length > 0) {
    const html = TaskTemplate.render(task);
    storyElement.find('.sortable-tasks').append(html);
    storyElement.find('.empty-task').click();
    Utils.autoTabIndex(storyElement);
  }
  exports.redrawTaskCounts();
};
exports.prepareDescriptionForEditing = description => {
  return Format.convertCustomMarkdownMentionsToNaked(description);
};
exports.redrawEmptyTaskElement = storyElement => {
  if (storyElement.length > 0) {
    const html = EmptyTaskTemplate.render();
    View.replaceElement(storyElement.find('.empty-task-container'), html);
    Autosize.bind($(html).find('textarea'));
  }
};
exports.saveNewTask = function () {
  const inputField = $(this).closest('.empty-task-form').find('.empty-task-description');
  const description = InPlaceTextareaController.getValueWithCustomLinks(inputField).trim();
  const newTask = {
    complete: false,
    description
  };
  let story;
  if (description === '') {
    $(this).closest('.empty-task-form').find('.empty-task-description').focus();
    return false;
  }
  if (TaskModel.isNewStory()) {
    story = AddNewStoryController.getState();
    // Passed in to override fetching story elements by ID
    newTask.storyElement = $(this).closest($('.add-new-story'));
    // A temporary ID before the story is created so we can delete the tasks by reference
    newTask.id = Utils.generateUUID();
  } else {
    story = Utils.getModelFromContext(this);
  }
  View.changeButtonToSaving(this);
  const emptyTaskDescription = $(this).closest('.empty-task-container').find('.empty-task-description').attr('readonly', true);
  Autosize.destroy(emptyTaskDescription);
  $(this).attr('data-on-click', 'App.Utils.returnFalse').closest('.actions').find('.cancel-new-task').hide();
  TaskModel.saveNew(story, newTask, (err, res) => {
    if (err) {
      $(this).closest('.empty-task-form').find('.empty-task-description').removeAttr('readonly').focus();
      exports.revertTaskButtonState.call(this);
      MessageController.error(err, {
        secondary: 'Your task has been restored.'
      });
    } else {
      TaskModel.normalize(res);
      exports.renderNewTaskElement(res);
    }
  });
  return false;
};
exports.openNewTaskForm = function () {
  const me = $(this);
  const emptyTaskDescription = me.closest('.empty-task-container').find('.empty-task-form').show().find('.empty-task-description').focus();
  Autosize.destroy(emptyTaskDescription);
  Autosize.bind(emptyTaskDescription);
  me.hide();
  return false;
};
exports.cancelNewTask = function () {
  const emptyTaskDescription = $('.empty-task-form').find('.empty-task-description').val('').end().hide();
  Autosize.destroy(emptyTaskDescription);
  $('.empty-task-container').find('.empty-task').show();
  if (TaskModel.isNewStory()) {
    AddNewStoryController.updateState();
  } else {
    const story = Utils.getModelFromContext($(this), 'Story');
    if (story) {
      StoryModel.trigger('storyTouched', story);
    }
  }
  return false;
};
exports.revertTaskButtonState = function () {
  $(this).closest('.actions').html(TaskActionsTemplate.render());
};
exports.toggleComplete = function () {
  if (Is.readOnly(UserModel.getLoggedInUserPermission())) {
    return false;
  }
  const task = Utils.getModelFromContext(this);
  $(this).attr('data-on-click', 'App.Utils.returnFalse');
  exports.markAsSaving(this);
  TaskModel.saveChanges(task, {
    complete: !task.complete
  }, (err, newTask) => {
    if (err) {
      MessageController.error(err, {
        secondary: 'Unable to update task.'
      });
    }
    exports.redrawTaskElement(newTask || task);
  });
  return false;
};
exports.showDescriptionTextfield = function () {
  if (!Is.readOnly(UserModel.getLoggedInUserPermission())) {
    const task = $(this).closest('.task').addClass('editing');
    const editField = task.find('.task-description-editor');
    Autosize.bind(editField);
    Caret.setPosition(editField[0], 0);
  }
  return false;
};
exports.hideDescriptionTextfield = function () {
  const task = $(this).closest('.task').removeClass('editing');
  Autosize.destroy(task.find('.task-description-editor'));
  return false;
};
exports.onNewTaskKeyDown = function (e) {
  if (Utils.keyPressed(e, 'SHIFT+ENTER')) {
    return false;
  } else if (Utils.keyPressed(e, 'ENTER')) {
    const taskForm = $(this).closest('.empty-task-form');
    if (this.value.trim() !== '') {
      taskForm.find('textarea').prop('readonly', true);
      setTimeout(() => {
        taskForm.find('.save-new-task').click();
      }, 50);
    }
    return false;
  }
};
exports.onNewTaskKeyUp = function (e) {
  if (Utils.keyPressed(e, 'ESCAPE')) {
    $(this).closest('.empty-task-form').find('.cancel-new-task').click();
    return false;
  }
  AutocompleteComponentsController.handleInput(this, e);
};
exports.onDescriptionKeyDown = function (e) {
  if (Utils.keyPressed(e, 'SHIFT+ENTER')) {
    return;
  } else if (Utils.keyPressed(e, 'ENTER')) {
    this.blur();
  }
};
exports.saveUncommittedTask = () => {
  const isNewStory = TaskModel.isNewStory();
  const parent = $(isNewStory ? '.add-new-story' : '.story-details');
  const newTaskTextArea = parent?.find('.empty-task-description');
  const taskDescription = newTaskTextArea?.val()?.trim();
  const hasUncommittedTask = taskDescription !== '';
  if (hasUncommittedTask) {
    const taskCount = AddNewStoryController.getState().tasks.length;
    const newTask = {
      complete: false,
      description: taskDescription,
      ...insertObjectIf(isNewStory, {
        id: Utils.generateUUID(),
        storyElement: parent,
        position: taskCount
      })
    };
    if (isNewStory) {
      TaskModel.updateNewStoryTasks(newTask);
    } else {
      const story = Utils.getModelFromContext(parent);
      const saveNewTaskBtn = parent.find('.empty-task-container .save-new-task');

      // Set loading UI for the "Save New Task" btn.
      View.changeButtonToSaving(saveNewTaskBtn);
      // Set new task text area to read-only.
      parent.find('.empty-task-description').attr('readonly', true);
      TaskModel.saveNew(story, newTask, (err, res) => {
        if (err) {
          MessageController.error(err, {
            secondary: 'Your new task has not been saved. Please try again.'
          });
        } else {
          TaskModel.normalize(res);
          exports.renderNewTaskElement(res);
        }
      });
    }
  }
};
exports.saveUncommittedTaskOnReload = () => {
  const isNewStory = TaskModel.isNewStory();
  const parent = isNewStory ? '.add-new-story' : '.story-details';
  const taskDescriptionTextArea = document.querySelector(`${parent} .task-description-editor.focus-visible`);
  const isUpdatingAnExistingTask = Boolean(taskDescriptionTextArea);
  if (isUpdatingAnExistingTask) {
    if (isNewStory) {
      const currentTaskTextArea = $(parent)?.find('.task-description-editor.focus-visible');
      const currentTaskId = currentTaskTextArea?.closest('.task[data-id]')?.data('id');
      const currentTask = TaskModel.getById(currentTaskId);
      const updatedDescription = currentTaskTextArea?.val()?.trim();

      // If the description has not changed, do nothing.
      if (currentTask.description === updatedDescription) return;
      currentTask.description = updatedDescription;
      TaskModel.updateNewStoryTasks(currentTask);
    } else {
      exports.updateDescription.call(taskDescriptionTextArea);
    }
  } else {
    exports.saveUncommittedTask();
  }
};
exports.onDescriptionFocus = function () {
  $(this).closest('.task').addClass('editing');
  Autosize.bind($(this).closest('.task').find('.task-description-editor'));
};
exports.onDescriptionKeyUp = function (e) {
  AutocompleteComponentsController.handleInput(this, e);
};
exports.updateDescription = function () {
  const task = Utils.getModelFromContext(this);
  if (!task) {
    // Another user has deleted this task, so let's move this to the new task form.
    return exports.restoreAsNewTask.call(this);
  }
  if (task.componentIsUpdating) {
    // This can happen if the current user is editing the task, while another user
    // is moving the task. We want to return false here because we prematurely
    // trigger data-on-change and data-on-blur events.
    return false;
  }
  if (AutocompleteComponentsController.isOpen()) {
    return false;
  }
  const description = InPlaceTextareaController.getValueWithCustomLinks($(this)).trim();
  if (task.description === description) {
    exports.hideDescriptionTextfield.call(this);
  } else {
    exports.markAsSaving(this);
    TaskModel.saveChanges(task, {
      description
    }, (err, newTask) => {
      if (err) {
        MessageController.error(err, {
          secondary: 'Your task description has been restored.'
        });
      }
      exports.redrawTaskElement(newTask || task);
    });
  }
  return false;
};
exports.restoreAsNewTask = function () {
  MessageController.alert("<strong>Someone deleted the task you were editing.</strong> We've saved your " + "edit in case you'd like to save it as a new task.");
  const parent = $(this).closest('.tasks');
  const selection = $(this).getSelection();
  parent.find('.empty-task').click();
  parent.find('.empty-task-description').val(this.value).setSelection(selection.start, selection.end);
  return false;
};
exports.deleteTask = function (callback) {
  const task = Utils.getModelFromContext(this);
  callback = _.isFunction(callback) ? callback : _.noop;
  $(this).attr('data-on-click', 'App.Utils.returnFalse');
  exports.markAsSaving(this);
  ContextMenuController.close();
  _deleteTask(task, callback);
  return false;
};
const _deleteTask = (task, callback) => {
  TaskModel.deleteTask(task, err => {
    if (err) {
      MessageController.error(err, {
        secondary: 'Unable to delete task.'
      });
      exports.redrawTaskElement(task);
    } else if (!TaskModel.isNewStory()) {
      StoryModel.fetchStory(task.story_id);
    }
    exports.redrawTaskCounts();
    callback(err);
  }, {
    withDelay: true
  });
};
exports.createStoryFromTask = function () {
  const task = Utils.getModelFromContext(this);
  exports._createStoryFromTask(task);
  return false;
};
exports._createStoryFromTask = task => {
  const story = StoryModel.getById(task.story_id);
  const templateStory = TaskModel.transformTaskToNewStoryState(task, story);
  Dialog.close();
  ContextMenuController.close();
  AddNewStoryController.resetState();
  AddNewStoryController.updateState(templateStory);
  AddNewStoryController.render();
  return false;
};
exports.initSortable = storyElement => {
  const isReadOnly = Is.readOnly(UserModel.getLoggedInUserPermission());
  storyElement = $(storyElement);
  if (isReadOnly || storyElement.hasClass('ui-sortable')) {
    return false;
  }
  storyElement.sortable({
    axis: 'y',
    handle: '.task-drag-handle',
    helper: 'clone',
    tolerance: 'pointer',
    scroll: false,
    start: (event, ui) => {
      Globals.set('isDragging', true);
      ui.placeholder.height(ui.item.height());
    },
    stop: (event, ui) => {
      if (ui && ui.item) {
        exports.repositionTask(ui.item);
      }

      // Allow sortable component to finish up before allowing updates.
      setTimeout(() => {
        Globals.set('isDragging', false);
      }, 10);
    }
  });
};
exports.markAsSaving = context => {
  $(context).closest('.task').css({
    opacity: 0.5
  }).find('.task-checkbox .fa').removeClass('fa-check-square fa-square-o').addClass('fa-star fa-spin');
};
exports.repositionTask = taskElement => {
  if (TaskModel.isNewStory()) {
    return exports.repositionNewStoryTask(taskElement);
  }
  const changes = {};
  let neighbor = $(taskElement).prev();
  if (neighbor.length > 0) {
    changes.after_id = Utils.data(neighbor, 'id');
  } else {
    neighbor = $(taskElement).next();
    if (neighbor.length > 0) {
      changes.before_id = Utils.data(neighbor, 'id');
    }
  }
  if (changes.before_id || changes.after_id) {
    const task = Utils.getModelFromContext(taskElement);
    TaskModel.moveTask(task, changes, err => {
      if (err) {
        MessageController.error(err, {
          secondary: 'Unable to update task position.'
        });
        exports.redrawAllTasks(StoryModel.getById(task.story_id));
      }
    });
  }
};
exports.repositionNewStoryTask = taskElement => {
  $(taskElement).closest('.sortable-tasks').find('.task').each(function (i) {
    const task = Utils.getModelFromContext(this);
    task.position = i;
  });
  const tasks = TaskModel.getTemporaryTasks();
  AddNewStoryController.updateState({
    tasks: _.sortBy(tasks, 'position')
  });
};
exports.generateTaskIDForContextMenu = function () {
  if ($(this).closest('.story-dialog').length > 0) {
    return '.story-dialog #' + this.id;
  }
  return '#' + this.id;
};
exports.contextMenuItems = function () {
  const actions = [{
    iconLeft: 'fa-user',
    tooltip: 'Add or remove owners to this task',
    value: StoryController.openTaskOwnerUpdater.bind(this, err => {
      if (err) {
        MessageController.error(err, {
          secondary: 'Unable to update owners.'
        });
      }
    })
  }];
  if (!AddNewStoryController.isOpen()) {
    actions.push({
      iconLeft: 'fa-share',
      tooltip: 'Convert to Story',
      value: exports.createStoryFromTask.bind(this)
    });
  }
  actions.push({
    iconLeft: 'fa-trash',
    tooltip: 'Delete this Task',
    value: exports.deleteTask.bind(this)
  });
  return actions;
};
exports.openContextMenu = e => {
  ContextMenuController.initContextMenu(e);
};
export { exports as default };