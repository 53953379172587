import { MarkdownEditor } from 'components/shared/MarkdownEditor/MarkdownEditor';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export function NewEntityDescriptionEditor({
  initialValue,
  onChange,
  onSave,
  renderId
}) {
  return _jsx("div", {
    children: _jsx(MarkdownEditor, {
      context: "Description",
      autoFocus: false,
      initialValue: initialValue,
      onChange: onChange,
      onCommandSave: onSave
    })
  }, renderId);
}
NewEntityDescriptionEditor.displayName = "NewEntityDescriptionEditor";