import { useCallback } from 'react';
import { insertIf } from '@clubhouse/shared/utils/object';
import { useLazyQuery } from 'gql';
import { useLazyWorkspaceCurrentPermissionIdQuery } from 'gql/queries/useWorkspaceCurrentPermissionIdQuery';
export function useFieldOptionsQuery({
  query,
  epicId,
  includeCurrentPermissionId
}) {
  const [executeQuery] = useLazyQuery(query);
  const lazyCurrentPermission = useLazyWorkspaceCurrentPermissionIdQuery();
  return useCallback(async ({
    inputValue = ''
  }) => {
    const {
      data
    } = await executeQuery({
      variables: {
        epicId,
        input: {
          ...insertIf(includeCurrentPermissionId, {
            currentPermissionId: await lazyCurrentPermission()
          }),
          ...insertIf(Boolean(inputValue.trim()), {
            fuzzyName: inputValue.trim()
          })
        }
      }
    });
    return data && 'node' in data ? data.node?.options : undefined;
  }, [epicId, executeQuery, includeCurrentPermissionId, lazyCurrentPermission]);
}