import "core-js/modules/es.array.push.js";
export function valueMatchesFilter(value, filter) {
  if (!filter || filter === '*' || value === undefined && filter.includes(null)) {
    return true;
  }
  return filter.includes(value);
}
export function fieldValuesMatchFilters(storyFields, filters = []) {
  return filters.every(filter => {
    const field = storyFields.find(f => f.field_id === filter.fieldId);
    return valueMatchesFilter(field?.value_id, filter.valueIds);
  });
}
export function getSingleFilterValue(filter) {
  if (Array.isArray(filter) && filter.length === 1 && filter[0] !== null) {
    return filter[0];
  }
}
export function getStoryCustomFieldsFromFilters(filters) {
  if (!filters) return [];
  const fields = [];
  filters.forEach(filter => {
    const singleValue = getSingleFilterValue(filter.valueIds);
    if (singleValue) {
      fields.push({
        field_id: filter.fieldId,
        value_id: singleValue,
        value: ''
      });
    }
  });
  return fields;
}

/**
 * Returns Story entity attributes from Space filters when they're set to a single non-null value
 */
export function getStoryAttributesFromSpaceData(data) {
  return {
    epic_id: getSingleFilterValue(data?.Epic),
    group_id: getSingleFilterValue(data?.Group),
    custom_fields: getStoryCustomFieldsFromFilters(data?.CustomField),
    project_id: getSingleFilterValue(data?.Project),
    iteration_id: getSingleFilterValue(data?.Iteration)
  };
}
/*
 * Maps a Story attribute to its Space filter key
 */
const ATTRIBUTE_FILTER_MAP = {
  custom_fields: 'CustomField',
  project_id: 'Project',
  group_id: 'Group',
  iteration_id: 'Iteration',
  epic_id: 'Epic'
};
/*
 * Maps a Story attribute to its Space filter matcher function
 */
const ATTRIBUTE_FILTER_MATCHER_MAP = {
  custom_fields: fieldValuesMatchFilters,
  project_id: valueMatchesFilter,
  group_id: valueMatchesFilter,
  iteration_id: valueMatchesFilter,
  epic_id: valueMatchesFilter
};
const defaultMatcher = () => true;
export function storyMatchesSpaceFilters(story, data, opts) {
  const {
    ignore = {}
  } = opts ?? {};
  const storyAttributeKeys = Object.keys(story);
  return storyAttributeKeys.every(attributeKey => {
    const filterKey = ATTRIBUTE_FILTER_MAP[attributeKey];
    const matcherFunction = ATTRIBUTE_FILTER_MATCHER_MAP[attributeKey] ?? defaultMatcher;
    return ignore[filterKey] || matcherFunction(story[attributeKey], data[filterKey]);
  });
}