import { EVENTS, logEvent } from 'utils/monitoring';
export const logNavigateToItem = () => logEvent(EVENTS.Interaction_PinnedItems_NavigateToItem);
export const logAddItem = ({
  typename
}) => logEvent(EVENTS.Interaction_PinnedItems_AddItem, {
  typename
});
export const logRemoveItem = ({
  typename
}) => logEvent(EVENTS.Interaction_PinnedItems_RemoveItem, {
  typename
});
export const logDockVisibilityChanged = ({
  isExpanded
}) => logEvent(EVENTS.Interaction_PinnedItems_DockVisibilityChanged, {
  isExpanded
});