import { Nouns } from '@clubhouse/shared/constants';
import { TableProgress } from 'components/shared/table/base';
import { FIELD_ID } from 'components/shared/table/types';
import * as EstimateScale from 'data/entity/estimateScale';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export function ProgressFieldComponent({
  entity,
  getProgressStats
}) {
  const usingPoints = EstimateScale.isUsingPoints();
  return _jsx(TableProgress, {
    entityType: entity.entity_type,
    progressStats: getProgressStats(entity, usingPoints),
    unit: usingPoints ? Nouns.Point : Nouns.Story
  });
}
ProgressFieldComponent.displayName = "ProgressFieldComponent";
export const ProgressField = (getProgressStats, overrides = {}) => ({
  name: FIELD_ID.PROGRESS,
  displayName: 'Progress',
  Component: ({
    entity
  }) => _jsx(ProgressFieldComponent, {
    entity: entity,
    getProgressStats: getProgressStats
  }),
  width: 110,
  ...overrides
});