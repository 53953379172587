import { ConfirmationDialog } from '../ConfirmationDialog/ConfirmationDialog';
import { ConfirmationDialogProvider, useConfirmationDialogState } from '../ConfirmationDialog/ConfirmationDialogProvider';
import { Menu } from './shared';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const MenuWrapper = ({
  children
}) => {
  const {
    isConfirmationDialogOpen,
    dialogProps,
    closeDialog
  } = useConfirmationDialogState();
  return _jsxs(_Fragment, {
    children: [dialogProps && _jsx(ConfirmationDialog, {
      ...dialogProps,
      onClose: closeDialog,
      visible: isConfirmationDialogOpen
    }), !isConfirmationDialogOpen && _jsx(Menu, {
      children: children
    })]
  });
};
export const ContextMenu = ({
  children
}) => {
  return _jsx(ConfirmationDialogProvider, {
    children: _jsx(MenuWrapper, {
      children: children
    })
  });
};
ContextMenu.displayName = "ContextMenu";