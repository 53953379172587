export const getDirtyValues = formState => {
  const {
    values,
    dirtyFields
  } = formState;
  return Object.keys(dirtyFields).reduce((acc, key) => {
    return {
      ...acc,
      [key]: values[key] === undefined ? '' : values[key]
    };
  }, {});
};
export const shouldDisableSave = ({
  submitting,
  form
}) => {
  return submitting || form.getState().hasValidationErrors || !form.getState().dirty;
};