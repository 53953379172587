import * as FigmaIframeTemplate from 'app/client/core/views/templates/figmaIframe.html';
import { isFigmaLink } from '@clubhouse/shared/utils/figmaLiveEmbed';
import Is from '../modules/is';
import ApplicationState from '../modules/applicationState';
import View from '../modules/view';
import _ from 'lodash';
const exports = {};
const STATE_KEY = 'Figma.ShowPreview';
const DEFAULT_VALUE = true;
const FIGMA_IFRAME_SRC = 'https://www.figma.com/embed?embed_host=shortcut&url=';
function shouldShowPreview() {
  // Too many Figma embeds crashes mobile Safari. See https://app.shortcut.com/internal/story/185881/stories-with-a-lot-of-attachments-external-links-comments-cause-shortcut-to-crash-on-a-mobile-device
  if (Is.mobile()) return false;
  const showPreview = ApplicationState.get(STATE_KEY);
  return _.isBoolean(showPreview) ? showPreview : DEFAULT_VALUE;
}
exports.isFigmaLink = isFigmaLink;
exports.render = url => {
  url = exports.unescapeURL(url);
  return FigmaIframeTemplate.render({
    url,
    showPreviews: shouldShowPreview(),
    title: exports.getTitleFromFigmaUrl(url),
    isPrototype: exports.isFigmaPrototype(url),
    iframeSrc: FIGMA_IFRAME_SRC + url,
    hideToggle: !shouldShowPreview() && Is.mobile()
  });
};
exports.isFigmaPrototype = url => {
  return url.indexOf('figma.com/proto/') !== -1;
};

// Replace HTML entities that are escaped by marked.
exports.unescapeURL = url => url.replace(/&amp;/g, '&');

// Example URL:
// https://www.figma.com/file/Drh4V75UEvMXuEJitZLKRK/Free-Plan-Billing?node-id=460%3A83
// returns 'Free Plan Billing'
// https://www.figma.com/file/obfh4uRaYQpvscHZqhf8FL/Projects-%2F-Dashboard-Tidyup?node-id=4%3A0
// returns 'Projects / Dashboard Tidyup'
exports.getTitleFromFigmaUrl = url => {
  const lastSegment = url.split('/').pop();
  return decodeURIComponent(lastSegment.split('?')[0]).replace(/-/g, ' ');
};
exports.reRenderTemplates = () => {
  $('.figma-container').each((i, el) => {
    const parent = $(el);
    const url = parent.find('.figma-link a').attr('href');
    View.replaceElement(parent, exports.render(url));
  });
};

// Actions

exports.togglePreviews = () => {
  const showPreview = !shouldShowPreview();
  ApplicationState.set(STATE_KEY, showPreview);
  exports.reRenderTemplates();
  return false;
};
export { exports as default };