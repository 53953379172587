import { useQuery } from './useQuery';
export function useNodeQuery(query, {
  id,
  ...options
}) {
  const {
    data,
    ...rest
  } = useQuery(query, {
    variables: {
      id
    },
    ...options
  });
  const node = data?.node;
  return {
    node: node ? node : null,
    ...rest
  };
}