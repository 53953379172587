import "core-js/modules/es.array.push.js";
import OrganizationModel from 'app/client/core/js/models/organization.js';
window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || {
    Controller: {},
    Model: {}
  };
  [[['Model', 'Organization'], OrganizationModel], [['Model', 'Organization'], OrganizationModel]].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});
import { PaidSeatsRemaining } from 'components/shared/PaidSeatsRemaining';
import { isOrgDisabled, useCurrentOrgId } from 'data/entity/organization';
import { getLoggedInUserPermission } from 'data/entity/user';
import { adminOrOwner, withinOrg } from 'utils/is';
import { navigateTo } from 'utils/navigation';
import { ActiveUsers } from './ActiveUsers';
import { InvitedUsers } from './InvitedUsers';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
export function ActiveUsersDirectory({
  users,
  scimEnabled,
  invites,
  inviteUsersRoute,
  onUpdateRole
}) {
  const workspaceId = useCurrentOrgId();
  const isAdmin = adminOrOwner(getLoggedInUserPermission());
  const isWithinOrg = withinOrg();
  const isWorkspaceDisabled = isOrgDisabled(workspaceId);
  return _jsxs(_Fragment, {
    children: [isAdmin && isWithinOrg && _jsxs("div", {
      className: "button-container",
      children: [!scimEnabled && _jsx("button", {
        onClick: () => navigateTo({
          url: inviteUsersRoute
        }),
        className: "action mini green invite-users-button",
        children: "Invite New Users"
      }), _jsx(PaidSeatsRemaining, {})]
    }), _jsx(ActiveUsers, {
      users: users,
      scimEnabled: scimEnabled,
      onUpdateRole: onUpdateRole
    }), _jsx("div", {
      className: "existing-invites",
      "data-model": "Organization",
      "data-id": workspaceId,
      children: !!invites?.length && _jsx(InvitedUsers, {
        invites: invites,
        isReadOnly: !isAdmin,
        showResendButton: !isWorkspaceDisabled && !scimEnabled
      })
    }), _jsx("div", {
      className: "mini-blurb",
      children: "Note that invited users are included in your active user count."
    })]
  });
}