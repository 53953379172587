import "core-js/modules/es.array.push.js";
import DeskLogo from '@clubhouse/assets/png/third-party-logos/desk_32x32.png';
import FigmaLogo from '@clubhouse/assets/png/third-party-logos/figma_32x32.png';
import FreshDeskLogo from '@clubhouse/assets/png/third-party-logos/freshdesk_32x32.png';
import FrontLogo from '@clubhouse/assets/png/third-party-logos/front_32x32.png';
import GithubLogo from '@clubhouse/assets/png/third-party-logos/github_32x32.png';
import HelpscoutLogo from '@clubhouse/assets/png/third-party-logos/helpscout_32x32.png';
import IntercomLogo from '@clubhouse/assets/png/third-party-logos/intercom_32x32.png';
import JiraServiceDeskLogo from '@clubhouse/assets/png/third-party-logos/jira_service_desk_32x32.png';
import KustomerLogo from '@clubhouse/assets/png/third-party-logos/kustomer_32x32.png';
import RollbarLogo from '@clubhouse/assets/png/third-party-logos/rollbar_32x32.png';
import SentryLogo from '@clubhouse/assets/png/third-party-logos/sentry_32x32.png';
import ZendeskLogo from '@clubhouse/assets/png/third-party-logos/zendesk_32x32.png';
import Collection from '../_frontloader/collection';
import Format from '../modules/format';
import Iterate from '../modules/iterate';
import Utils from '../modules/utils';
import _ from 'lodash';
const exports = {};

/*

External Links are attached to the Story Model. After they are sanitized
and metadata is attached to them, the data will look like:

{
  "id": "4286",
  "url": "https://help.shortcut.com/agent/tickets/4286",
  "icon": "/images/logos/freshdesk_32x32.png",
  "name": "Freshdesk",
  "invertForDarkMode": true,
  "match": true
}

*/

let EXTERNAL_LINKS;
exports.getExternalLinks = () => {
  if (!EXTERNAL_LINKS) {
    EXTERNAL_LINKS = {
      DESK: {
        NAME: 'Desk',
        ICON: DeskLogo,
        URL_PATTERN: str => _contains(str, 'desk.com/web/agent/case'),
        PATTERN: /http[s]?:\/\/(?:.*?)\.desk\.com\/web\/agent\/case\/([^(?:/|?)]+)/gi
      },
      FRESHDESK: {
        NAME: 'Freshdesk',
        ICON: FreshDeskLogo,
        URL_PATTERN: str => {
          if (_contains(str, 'freshdesk.com/a/tickets')) return true;

          // Customer TSPlus uses a custom url for Freshdesk and asked if we can show the icon for their URL as well.
          // Adding some joy for them! https://useshortcut.slack.com/archives/C04V9AVFX3J/p1722261725353769
          if (_startsWith(str, 'https://support.tsplus.net/')) return true;
          return false;
        },
        PATTERN: /(?:http[s]?:\/\/(?:.*?)\.freshdesk\.com\/a\/tickets\/([^(?:/|?)]+))|(?:https:\/\/support\.tsplus\.net\/)/gi
      },
      FRONT: {
        NAME: 'Front',
        ICON: FrontLogo,
        URL_PATTERN: str => _contains(str, 'app.frontapp.com'),
        PATTERN: /http[s]?:\/\/app\.frontapp\.com\/(?:.*[\/])([^?]+)/gi
      },
      GITHUB: {
        NAME: 'GitHub',
        ICON: GithubLogo,
        URL_PATTERN: str => _contains(str, 'github.com/'),
        // forcing entire URL to be hashed as the ID
        // Ref: https://app.shortcut.com/internal/story/96540/github-external-links-can-t-be-deleted
        PATTERN: false
      },
      HELPSCOUT: {
        NAME: 'Help Scout',
        ICON: HelpscoutLogo,
        URL_PATTERN: str => _contains(str, 'secure.helpscout.net'),
        PATTERN: /http[s]?:\/\/secure\.helpscout\.net\/conversation\/([^(?:/|?)]+)/gi
      },
      INTERCOM: {
        NAME: 'Intercom',
        ICON: IntercomLogo,
        URL_PATTERN: str => _contains(str, 'app.intercom.io/') || _contains(str, 'app.intercom.com/'),
        PATTERN: /http[s]?:\/\/app\.intercom\.io\/a\/apps\/(?:.*?)\/conversations\/([^(?:/|?)]+)/gi
      },
      JIRA_SERVICE_DESK: {
        NAME: 'JIRA Service Desk',
        ICON: JiraServiceDeskLogo,
        URL_PATTERN: str => _contains(str, 'atlassian.net/'),
        PATTERN: /http[s]?:\/\/(?:.*?)\.atlassian\.net\/(?:projects|servicedesk)\/(?:.*[\/])([^?]+)/gi
      },
      KUSTOMER: {
        NAME: 'Kustomer',
        ICON: KustomerLogo,
        URL_PATTERN: str => _contains(str, 'kustomerapp.com/app'),
        PATTERN: /http[s]?:\/\/(?:.*?)\.kustomerapp\.com\/app\/(?:.*[\/])([^?]+)/gi
      },
      FIGMA: {
        NAME: 'Figma',
        ICON: FigmaLogo,
        URL_PATTERN: str => _contains(str, 'figma.com/'),
        // forcing entire URL to be hashed as the ID
        // Ref: https://app.shortcut.com/internal/story/89448/external-figma-links-transforming-within-stories
        PATTERN: false
      },
      ROLLBAR: {
        NAME: 'Rollbar',
        ICON: RollbarLogo,
        URL_PATTERN: str => _contains(str, 'rollbar.com/'),
        PATTERN: /http[s]?:\/\/rollbar\.com\/(?:.*?)\/(?:.*?)\/items\/([^(?:/|?)]+)/gi
      },
      SENTRY: {
        NAME: 'Sentry',
        ICON: SentryLogo,
        URL_PATTERN: str => _contains(str, 'sentry.io/'),
        PATTERN: /http[s]?:\/\/sentry\.io\/(?:.*?)\/(?:.*?)\/issues\/([^(?:/|?)]+)/gi
      },
      ZENDESK: {
        NAME: 'Zendesk',
        ICON: ZendeskLogo,
        URL_PATTERN: str => _contains(str, 'zendesk.com/agent/tickets'),
        INVERT: true,
        PATTERN: /http[s]?:\/\/(?:.*?)\.zendesk\.com\/agent\/tickets\/([^(?:/|?)]+)/gi
      }
    };
  }
  return EXTERNAL_LINKS;
};
const UNRECOGNIZED_EXTERNAL_LINK_NAME = 'Other External Links';
function _contains(url, match) {
  return !!url && (url + '').indexOf(match) !== -1;
}
function _startsWith(url, match) {
  return !!url && (url + '').indexOf(match) === 0;
}
Collection.create('ExternalLink', exports);
exports.normalize = externalLink => {
  _sanitizePropsAndGetMetaData(externalLink);
};
exports.isDesk = externalLink => {
  return _isExternalLinkType(externalLink, exports.getExternalLinks().DESK);
};
exports.isFigma = externalLink => {
  return _isExternalLinkType(externalLink, exports.getExternalLinks().FIGMA);
};
exports.isFreshdesk = externalLink => {
  return _isExternalLinkType(externalLink, exports.getExternalLinks().FRESHDESK);
};
exports.isFront = externalLink => {
  return _isExternalLinkType(externalLink, exports.getExternalLinks().FRONT);
};
exports.isGitHub = externalLink => {
  return _isExternalLinkType(externalLink, exports.getExternalLinks().GITHUB);
};
exports.isHelpScout = externalLink => {
  return _isExternalLinkType(externalLink, exports.getExternalLinks().HELPSCOUT);
};
exports.isIntercom = externalLink => {
  return _isExternalLinkType(externalLink, exports.getExternalLinks().INTERCOM);
};
exports.isJiraServiceDesk = externalLink => {
  return _isExternalLinkType(externalLink, exports.getExternalLinks().JIRA_SERVICE_DESK);
};
exports.isKustomer = externalLink => {
  return _isExternalLinkType(externalLink, exports.getExternalLinks().KUSTOMER);
};
exports.isRollbar = externalLink => {
  return _isExternalLinkType(externalLink, exports.getExternalLinks().ROLLBAR);
};
exports.isSentry = externalLink => {
  return _isExternalLinkType(externalLink, exports.getExternalLinks().SENTRY);
};
exports.isZendesk = externalLink => {
  return _isExternalLinkType(externalLink, exports.getExternalLinks().ZENDESK);
};
function _isExternalLinkType(externalLink, EXTERNAL_LINK_TYPE) {
  return EXTERNAL_LINK_TYPE.URL_PATTERN(externalLink);
}
exports.getExternalLinksCount = story => {
  return (story.external_links || []).length;
};
exports.groupExternalLinksByType = externalLinks => {
  const tuples = _.toPairs(_.groupBy(_sanitizePropsAndGetMetadataForMultiple(externalLinks), 'name'));
  const unrecognized = _.remove(tuples, tuple => {
    return tuple[0] === UNRECOGNIZED_EXTERNAL_LINK_NAME;
  })[0];
  const sorted = _.sortBy(tuples, tuple => {
    return tuple[0];
  });

  // Unrecognized externalLinks should always be at the end of the list
  if (unrecognized) {
    sorted.push(unrecognized);
  }
  return sorted;
};
exports.getBadgesForStory = story => {
  const badges = [];
  const allExternalLinks = _.clone(story.external_links);
  const deskExternalLinks = _.remove(allExternalLinks, exports.isDesk);
  const freshdeskExternalLinks = _.remove(allExternalLinks, exports.isFreshdesk);
  const frontExternalLinks = _.remove(allExternalLinks, exports.isFront);
  const figmaExternalLinks = _.remove(allExternalLinks, exports.isFigma);
  const gitHubExternalLinks = _.remove(allExternalLinks, exports.isGitHub);
  const helpScoutExternalLinks = _.remove(allExternalLinks, exports.isHelpScout);
  const intercomExternalLinks = _.remove(allExternalLinks, exports.isIntercom);
  const jiraServiceDeskExternalLinks = _.remove(allExternalLinks, exports.isJiraServiceDesk);
  const kustomerExternalLinks = _.remove(allExternalLinks, exports.isKustomer);
  const rollbarExternalLinks = _.remove(allExternalLinks, exports.isRollbar);
  const sentryExternalLinks = _.remove(allExternalLinks, exports.isSentry);
  const zendeskExternalLinks = _.remove(allExternalLinks, exports.isZendesk);
  const externalLinkClassName = 'has-external-link';
  if (deskExternalLinks.length) {
    badges.push({
      className: externalLinkClassName,
      text: deskExternalLinks.length,
      image: exports.getExternalLinks().DESK.ICON,
      tooltip: _badgeTooltip(deskExternalLinks.length, exports.getExternalLinks().DESK.NAME)
    });
  }
  if (freshdeskExternalLinks.length) {
    badges.push({
      className: externalLinkClassName,
      text: freshdeskExternalLinks.length,
      image: exports.getExternalLinks().FRESHDESK.ICON,
      tooltip: _badgeTooltip(freshdeskExternalLinks.length, exports.getExternalLinks().FRESHDESK.NAME)
    });
  }
  if (figmaExternalLinks.length) {
    badges.push({
      className: externalLinkClassName,
      text: figmaExternalLinks.length,
      image: exports.getExternalLinks().FIGMA.ICON,
      tooltip: _badgeTooltip(figmaExternalLinks.length, exports.getExternalLinks().FIGMA.NAME)
    });
  }
  if (gitHubExternalLinks.length) {
    badges.push({
      className: externalLinkClassName,
      text: gitHubExternalLinks.length,
      image: exports.getExternalLinks().GITHUB.ICON,
      tooltip: _badgeTooltip(gitHubExternalLinks.length, exports.getExternalLinks().GITHUB.NAME)
    });
  }
  if (frontExternalLinks.length) {
    badges.push({
      className: externalLinkClassName,
      text: frontExternalLinks.length,
      image: exports.getExternalLinks().FRONT.ICON,
      tooltip: _badgeTooltip(frontExternalLinks.length, exports.getExternalLinks().FRONT.NAME)
    });
  }
  if (helpScoutExternalLinks.length) {
    badges.push({
      className: externalLinkClassName,
      text: helpScoutExternalLinks.length,
      image: exports.getExternalLinks().HELPSCOUT.ICON,
      tooltip: _badgeTooltip(helpScoutExternalLinks.length, exports.getExternalLinks().HELPSCOUT.NAME)
    });
  }
  if (intercomExternalLinks.length) {
    badges.push({
      className: externalLinkClassName,
      text: intercomExternalLinks.length,
      image: exports.getExternalLinks().INTERCOM.ICON,
      tooltip: _badgeTooltip(intercomExternalLinks.length, exports.getExternalLinks().INTERCOM.NAME)
    });
  }
  if (jiraServiceDeskExternalLinks.length) {
    badges.push({
      className: externalLinkClassName,
      text: jiraServiceDeskExternalLinks.length,
      image: exports.getExternalLinks().JIRA_SERVICE_DESK.ICON,
      tooltip: _badgeTooltip(jiraServiceDeskExternalLinks.length, exports.getExternalLinks().JIRA_SERVICE_DESK.NAME)
    });
  }
  if (kustomerExternalLinks.length) {
    badges.push({
      className: externalLinkClassName,
      text: kustomerExternalLinks.length,
      image: exports.getExternalLinks().KUSTOMER.ICON,
      tooltip: _badgeTooltip(kustomerExternalLinks.length, exports.getExternalLinks().KUSTOMER.NAME)
    });
  }
  if (rollbarExternalLinks.length) {
    badges.push({
      className: externalLinkClassName,
      text: rollbarExternalLinks.length,
      image: exports.getExternalLinks().ROLLBAR.ICON,
      tooltip: _badgeTooltip(rollbarExternalLinks.length, exports.getExternalLinks().ROLLBAR.NAME)
    });
  }
  if (sentryExternalLinks.length) {
    badges.push({
      className: externalLinkClassName,
      text: sentryExternalLinks.length,
      image: exports.getExternalLinks().SENTRY.ICON,
      tooltip: _badgeTooltip(sentryExternalLinks.length, exports.getExternalLinks().SENTRY.NAME)
    });
  }
  if (zendeskExternalLinks.length) {
    badges.push({
      className: externalLinkClassName,
      text: zendeskExternalLinks.length,
      image: exports.getExternalLinks().ZENDESK.ICON,
      invertForDarkMode: true,
      tooltip: _badgeTooltip(zendeskExternalLinks.length, exports.getExternalLinks().ZENDESK.NAME)
    });
  }
  if (allExternalLinks.length) {
    badges.push({
      className: externalLinkClassName,
      text: allExternalLinks.length,
      icon: 'fa-link',
      tooltip: _badgeTooltip(allExternalLinks.length)
    });
  }
  return badges;
};
function _badgeTooltip(externalLinkCount, type) {
  type = type || 'External';
  return 'This Story has ' + Format.pluralize(externalLinkCount, type + ' Link', type + ' Links') + ' attached.';
}
function _sanitizePropsAndGetMetadataForMultiple(externalLinks) {
  return _.sortBy(_.map(externalLinks, _sanitizePropsAndGetMetaData), externalLink => {
    return Number.parseInt(externalLink.id);
  });
}
function _sanitizePropsAndGetMetaData(externalLink) {
  const metaData = _getMetaData(externalLink);
  const sanitizedProps = {
    id: Utils.simpleHash(externalLink).toString(),
    url: Format.sanitizeHref(externalLink)
  };
  return Object.assign(metaData, sanitizedProps);
}
function _getMetaData(externalLink) {
  let icon = '';
  let invertForDarkMode = false;
  let name = UNRECOGNIZED_EXTERNAL_LINK_NAME;
  let match = false;
  Iterate.each(exports.getExternalLinks(), EXTERNAL_LINK_TYPE => {
    if (!match && _isExternalLinkType(externalLink, EXTERNAL_LINK_TYPE)) {
      name = EXTERNAL_LINK_TYPE.NAME;
      icon = EXTERNAL_LINK_TYPE.ICON;
      invertForDarkMode = EXTERNAL_LINK_TYPE.INVERT || false;
      match = true;
    }
  });
  return {
    icon,
    invertForDarkMode,
    name
  };
}
exports.generateIdWithUrl = url => {
  const id = Utils.simpleHash(url).toString();
  return {
    id,
    url
  };
};
export { exports as default };