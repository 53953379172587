import { TableText } from '@clubhouse/shared/components/Table';
import { FIELD_ID } from 'components/shared/table/types';
import { docs } from 'utils/sort';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const DocsFieldComponent = ({
  entity
}) => {
  return _jsx(TableText, {
    text: entity.stats.num_related_documents
  });
};
DocsFieldComponent.displayName = "DocsFieldComponent";
export const DocsField = (overrides = {}) => ({
  name: FIELD_ID.DOCS,
  displayName: 'Docs',
  sort: docs,
  headerProps: {
    centered: true
  },
  Component: DocsFieldComponent,
  width: 70,
  ...overrides
});