import { QueryEpicEpicStateSelectDocument } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { useCallback } from 'react';
import { gql } from '@clubhouse/datalayer';
import { Nouns } from '@clubhouse/shared/constants';
import { GroupedOptionsFieldSingleSelection } from 'components/gql/GroupedOptionsField';
import { EpicStatesSelectTarget } from 'components/gql/epicState/EpicStatesSelectTarget';
import { useUpdateEpicState } from 'components/gql/epicState/useUpdateEpicState';
import { getSection } from 'components/gql/epicState/utils/sections';
import { useFieldOptionsQuery } from './useFieldOptionsQuery';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const QUERY_EPIC_EPIC_STATE_SELECT = QueryEpicEpicStateSelectDocument;
const EpicStatesFieldComponent = ({
  entity: {
    id,
    epicState
  }
}) => {
  const fetchEpicStates = useFieldOptionsQuery({
    query: QUERY_EPIC_EPIC_STATE_SELECT,
    epicId: id,
    includeCurrentPermissionId: false
  });
  const updateEpicState = useUpdateEpicState();
  const handleChange = useCallback((selectedId, item) => {
    updateEpicState({
      id
    }, {
      id: selectedId,
      ...item?.additionalData
    });
  }, [updateEpicState, id]);
  return _jsx(GroupedOptionsFieldSingleSelection, {
    selectedEntity: epicState,
    fetchOptions: fetchEpicStates,
    getSection: getSection,
    TargetComponent: EpicStatesSelectTarget,
    onChange: handleChange,
    unit: Nouns.State,
    required: true
  });
};
EpicStatesFieldComponent.displayName = "EpicStatesFieldComponent";
EpicStatesFieldComponent.displayName = 'EpicStatesFieldComponent';
export const EpicStatesField = () => ({
  name: 'epicState_position',
  displayName: 'State',
  sort: true,
  Component: EpicStatesFieldComponent,
  width: 220
});