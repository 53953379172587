import Backend from 'app/client/core/js/modules/backend';
import Log from 'app/client/core/js/modules/log';
import Utils from 'app/client/core/js/modules/utils';

/**
 *
 *
 * SHORTCUT => NOTION
 *
 *
 */

export const revokeNotionAuthorization = ({
  onComplete,
  onSuccess,
  onError
}) => {
  return Backend.post('/api/private/user/revoke-notion-token', {
    onError: (err, resp) => {
      if (onError) {
        onError(resp.status);
      }
      let errorMessage = 'Failed to revoke Notion authorizaton. ';
      switch (resp.status) {
        case 404:
          errorMessage += 'Endpoint not found.';
          break;
        case 422:
          errorMessage += 'No existing Notion token.';
          break;
        default:
          errorMessage += 'Unknown error.';
      }
      Log.error(new Error(errorMessage), {
        errorInfo: JSON.stringify(err)
      });
    },
    ...{
      onSuccess,
      onComplete
    }
  });
};

/**
 *
 *
 * NOTION => SHORTCUT
 *
 *
 */

export const allowNotionAuthorization = params => {
  Utils.redirect('/backend/oauth-authorization-code-flow/code' + params);
};
export const denyNotionAuthorization = redirectUri => {
  Utils.redirect(`${redirectUri}?error=access_denied`);
};