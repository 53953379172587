import { useState } from 'react';
import { useRequest } from './useRequest';
export const useRequestTimer = (request, {
  minTime = 3000
} = {}) => {
  const [isLoading, setLoading] = useState(false);
  const {
    request: withRequest,
    ...rest
  } = useRequest({
    fn: request
  });
  const withTimer = (...args) => new Promise((resolve, reject) => {
    setLoading(true);
    const then = Date.now();
    withRequest(...args).then(res => {
      const diff = minTime - (Date.now() - then);
      setTimeout(() => {
        setLoading(false);
        resolve(res);
      }, diff);
    }).catch(reject);
  });
  return {
    request: withTimer,
    ...rest,
    isLoading
  };
};