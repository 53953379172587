import { Icon } from '@useshortcut/shapes-ds';
import { Tooltip } from '@clubhouse/shared/components/Tooltip';
import { DetailPageSidebarAction, DetailPageSidebarRow, SecondaryActionButton } from 'components/shared/DetailPageSidebar';
import { EmptyValue } from './EmptyValue';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export function EpicStartDate({
  epic
}) {
  return _jsx(DetailPageSidebarRow, {
    label: "Start Date",
    "data-perma-id": "epic-startdate-field",
    children: _jsx(DetailPageSidebarAction, {
      icon: "Calendar",
      "data-field-name": "planned_start_date",
      "data-on-click": "openDateControl",
      secondarySlot: epic.formatted_planned_start_date ? _jsx(Tooltip, {
        content: "Clear Start Date",
        children: _jsx(SecondaryActionButton, {
          "data-perma-id": "clearDate",
          "data-field-name": "planned_start_date",
          "data-on-click": "clearDate",
          children: _jsx(Icon, {
            icon: "Remove",
            fill: "main",
            label: "Clear Start Date"
          })
        })
      }) : undefined,
      children: epic.formatted_planned_start_date ? epic.formatted_planned_start_date : _jsx(EmptyValue, {
        children: "No Date"
      })
    })
  });
}
EpicStartDate.displayName = "EpicStartDate";