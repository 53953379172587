import { useRequiredParent } from '@useshortcut/shapes-ds';
import { useContext } from 'react';
import { Label, Root, Thumb } from './components';
import { LabelContext } from './components/Label/LabelContext';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const Switch = ({
  isChecked,
  isDisabled = false,
  onChange,
  size = 'medium'
}) => {
  const context = useContext(LabelContext);
  useRequiredParent({
    child: 'Switch',
    context: context
  });
  const {
    id
  } = context;
  return _jsx(Root, {
    "aria-disabled": isDisabled,
    checked: isChecked,
    disabled: isDisabled,
    id: id,
    onCheckedChange: onChange,
    size: size,
    children: _jsx(Thumb, {
      isChecked: isChecked,
      size: size
    })
  });
};
Switch.displayName = "Switch";
Switch.Label = Label;