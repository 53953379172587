import "core-js/modules/es.array.push.js";
import * as InPlaceTextareaTemplate from 'app/client/core/views/templates/inPlaceTextarea.html';
import Autosize from '../modules/autosize';
import Caret from '../modules/caret';
import CodeBlockController from './codeBlock';
import Format from '../modules/format';
import MessageController from './message';
import ProfileModel from '../models/profile';
import GroupModel from '../models/group';
import UserModel from '../models/user';
import Is from '../modules/is';
import Snapshot from '../modules/snapshot';
import { highlightElement } from 'utils/syntaxHighlight';
import Url from '../modules/url';
import Utils from '../modules/utils';
import View from '../modules/view';
import { Icon } from '@useshortcut/shapes-ds';
import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons';
import { CLICKABLE_MARKDOWN_TAGS } from '@clubhouse/shared/constants';
import _ from 'lodash';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const exports = {};
const MAX_LENGTH = 50000;

/** @deprecated Use modules/snapshot > getDirtyFieldIds(); instead */
exports.getEntityIdsWithEdits = () => Snapshot.getDirtyFieldIds();
exports.render = (context, data) => {
  const rawText = data.text;
  const html = InPlaceTextareaTemplate.render(_prepareDataForEditing(data));
  const textarea = View.attach(html, context, 'insertAfter').find('textarea');
  exports.saveMentionNameMappings(textarea, rawText);
  Autosize.bind(textarea);
  exports.updatePreview(textarea);
  $(context).hide();
  setTimeout(() => {
    if (textarea) {
      textarea.focus().keyup();
    }
  }, 0);
};
exports.renderReactionIcon = id => View.renderComponentDelayed({
  componentKey: `reaction-icon-${id}`,
  component: _jsx(DeprecatedIconAdapter, {
    width: 18,
    children: _jsx(Icon, {
      icon: "Reaction",
      fill: "currentColor"
    })
  })
}).html;
exports.renderAttachmentIcon = id => View.renderComponentDelayed({
  componentKey: `attachment-icon-${id}`,
  component: _jsx(DeprecatedIconAdapter, {
    width: 18,
    children: _jsx(Icon, {
      icon: "Attachment",
      fill: "currentColor"
    })
  })
}).html;
exports.renderMentionIcon = id => View.renderComponentDelayed({
  componentKey: `mention-icon-${id}`,
  component: _jsx(DeprecatedIconAdapter, {
    width: 18,
    children: _jsx(Icon, {
      icon: "Mention",
      fill: "currentColor"
    })
  })
}).html;
exports.renderLinkIcon = id => View.renderComponentDelayed({
  componentKey: `link-icon-${id}`,
  component: _jsx(DeprecatedIconAdapter, {
    width: 18,
    children: _jsx(Icon, {
      icon: "Link",
      fill: "currentColor"
    })
  })
}).html;
exports.renderMarkdownIcon = id => View.renderComponentDelayed({
  componentKey: `markdown-icon-${id}`,
  component: _jsx(DeprecatedIconAdapter, {
    width: 18,
    children: _jsx(Icon, {
      icon: "Markdown",
      fill: "currentColor"
    })
  })
}).html;
exports.onSwitchToPreview = function () {
  const parent = $(this).closest('.in-place-textarea');
  const context = parent.find('textarea');
  parent.find('.formatting-note').hide();
  exports.updatePreview(context);
};
exports.updatePreview = context => {
  const textarea = $(context);
  const value = exports.getValueWithCustomLinks(textarea);
  const html = Format.markdownify(value, 'text-area-update-preview');
  const previewElement = textarea.closest('.in-place-textarea').find('.preview-tab');
  previewElement.html(html || '<p class="nothing-to-preview"><em>Nothing to preview.</em></p>');
  highlightElement(previewElement);
  CodeBlockController.insertControls();
};
exports.getValueWithCustomLinks = (textarea, options) => {
  options = options || {};
  const $textarea = $(textarea);
  _updateCustomLinks($textarea);
  const text = $textarea.val();
  const mappings = options.mappings || $textarea.data('mentions');
  return mappings.reduce((text, mapping) => {
    const pattern = new RegExp('\\B@' + Utils.escapeRegex(mapping.mention_name) + '(?![^[]*])');
    return Format.parseOutsideMarkdownCodeBlocks(text, chunk => {
      return chunk.replace(pattern, mapping.formatted);
    });
  }, text);
};
exports.insertValueAndUpdateCustomLinks = ({
  element,
  value,
  caretPosition,
  charsToReplace,
  lengthTransform
}) => {
  const $textarea = $(element);
  const text = Utils.splice($textarea.val(), caretPosition, charsToReplace, value);
  const length = (_.isFunction(lengthTransform) ? lengthTransform(value) : value).length;
  const position = caretPosition + length;
  $textarea.val(text).focus().trigger('input');
  // FF won't recognize this unless we run it separate from the input trigger:
  $textarea.trigger('keyup');
  _updateCustomLinks($textarea);
  Caret.setPosition($textarea[0], position);
};
function _updateCustomLinks(textarea) {
  const contents = textarea.val();
  const data = _prepareDataForEditing({
    text: contents
  });
  exports.saveMentionNameMappings(textarea, contents);
  textarea.val(data.text);
}
function _prepareDataForEditing(data) {
  data.text = Format.convertCustomMarkdownMentionsToNaked(data.text);
  return data;
}
function _consolidateMappings(rawMentions, existingMappings, newMappings) {
  let existingCopy = _.clone(existingMappings);
  let newCopy = _.clone(newMappings);
  return rawMentions.reduce((mappings, mention) => {
    const existingMatch = _.find(existingCopy, {
      mention_name: mention
    });
    const newMatch = _.find(newCopy, {
      mention_name: mention
    });
    if (existingMatch) {
      existingCopy = _.without(existingCopy, existingMatch);
      mappings.push(existingMatch);
    } else if (newMatch) {
      newCopy = _.without(newCopy, newMatch);
      mappings.push(newMatch);
    } else {
      const bestGuess = ProfileModel.getDetailsByUsername(mention) || GroupModel.get({
        mention_name: mention
      });
      if (bestGuess) {
        mappings.push(Format.createMentionMapping(Format.generateCustomMentionMarkdown(bestGuess), bestGuess.mention_name, bestGuess.id));
      }
    }
    return mappings;
  }, []);
}
exports.saveMentionNameMappings = (textarea, text) => {
  const content = text || '';
  const allMentions = Format.getAllNakedMentions(content);
  const existingMappings = textarea.data('mentions') || [];
  const newMappings = Format.extractMentionDetails(content);
  const consolidatedMappings = _consolidateMappings(allMentions, existingMappings, newMappings);

  // If consolidatedMappings length doesn't match that of allMentions,
  // should we alert the user here that we haven't found a profile?

  textarea.data('mentions', consolidatedMappings);
};
exports.showEditorTab = function () {
  $(this).closest('.in-place-textarea').find('.in-place-textarea-editor-tab').click();
  return false;
};
exports.onSwitchToWrite = function () {
  $(this).closest('.in-place-textarea').find('.formatting-note').show();
};
exports.onTextareaFocus = function () {
  $(this).closest('.in-place-textarea').addClass('focused');
};
exports.onTextareaBlur = function () {
  $(this).closest('.in-place-textarea').removeClass('focused');
};
exports.isOpen = context => {
  const textarea = $(context).closest('.in-place-textarea');
  return textarea.length !== 0;
};
exports.destroy = context => {
  const parent = $(context).closest('.in-place-textarea');
  parent.prev().show();
  Autosize.destroy(parent.find('textarea'));
  parent.remove();
};

/** @deprecated Use modules/snapshot > clearSnapshot(...); instead */
exports.clearSnapshot = (obj, prefix) => Snapshot.clearSnapshot(obj, prefix);

/** @deprecated Use `setAsLoading(context, {message: 'Loading string...'})` */
exports.setAsSaving = context => exports.setAsLoading(context);
exports.setAsLoading = (context, {
  message = 'Saving...'
} = {}) => {
  const parent = $(context).closest('.in-place-textarea');
  const textarea = parent.find('textarea');
  textarea.prop('readonly', true); // prevent further editing
  parent.find('.actions').hide().after(`<div class="actions saving-actions"><div class="action mini disabled"><span class="fa fa-spin fa-star"></span> ${message}</div></div>`);
};

/** @deprecated Use `backOutOfLoading(context)` instead. */
exports.backOutOfSaving = context => exports.backOutOfLoading(context);
exports.backOutOfLoading = context => {
  const parent = $(context).closest('.in-place-textarea');
  const textarea = parent.find('textarea');
  textarea.prop('readonly', false); // prevent further editing
  parent.find('.actions').show();
  parent.find('.saving-actions').remove();
};

// Throttling this function to avoid performance issues
// since localStorage (and maybe getSelection) can be slow.
exports.updateSnapshot = _.throttle((context, prefix) => {
  const obj = Utils.getModelFromContext(context);
  const $textarea = $(context);

  // Guard against additional keypresses that are still triggered even on a readonly textarea.
  // Ref: https://app.shortcut.com/internal/story/13490/previously-added-comment-shows-up-again
  if ($textarea.length === 0 || $textarea.prop('readonly') === true) {
    return false;
  }
  let text = $textarea.val();
  if (text.length > MAX_LENGTH) {
    text = text.slice(0, MAX_LENGTH);
    $textarea.val(text);
    $textarea.trigger('input.textareaAutosize');
    MessageController.info(`<strong>You've reached the maximum length for this textarea.</strong>
      For longer text, please try saving it as a text file, and then uploading as a file attachment.`, {
      id: 'max-text-length-reached'
    });
  }
  const selection = getTextSelection($textarea);

  // Used by textarea helpers
  $textarea.attr('data-caret-position', selection.start);
  const state = {};
  state[prefix] = $textarea.val();
  state[prefix + 'Height'] = $textarea.height();
  state[prefix + 'SelectStart'] = selection.start;
  state[prefix + 'SelectEnd'] = selection.end;
  Snapshot.putSnapshot(obj, state);
}, 100);
function getTextSelection($el) {
  const start = $el[0]?.selectionStart || 0;
  const end = $el[0]?.selectionEnd || 0;
  return {
    start,
    end
  };
}

/** @deprecated Use modules/snapshot > putSnapshot(...) or setSnapshot(...) instead */
exports.setSnapshot = (obj, state) => Snapshot.putSnapshot(obj, state);

/** @deprecated Use modules/snapshot > getSnapshot(...) instead */
exports.getSnapshot = obj => Snapshot.getSnapshot(obj);

/** @deprecated Use modules/snapshot > hasSnapshot(...) instead */
exports.hasSnapshot = obj => Snapshot.hasSnapshot(obj);

/** @deprecated Use modules/snapshot > restoreSnapshot(obj, callback), DOM specific logic should be */
// done within the callback from the desired controller, please do not add to this method.
exports.restoreSnapshot = (context, obj) => {
  // Update description fields
  Snapshot.restoreSnapshot(obj, state => {
    if (_.isString(state.description)) {
      $(context).find('.edit-description').click();
      $(context).find('.description-container textarea').val(state.description).height(state.descriptionHeight).focus();
      $(context)?.setSelection?.(state.descriptionSelectStart, state.descriptionSelectEnd);
    }
  });
  Snapshot.restoreSnapshot(obj, state => _restoreSnapshots($(context), state));
};
function _restoreSnapshots(context, state) {
  _.forEach(state, (val, key) => {
    if (/^reply[0-9]+$/.test(key) && _.isString(val)) {
      const replyContext = exports._getReplyContext(key, context);
      if (replyContext.length > 0) {
        replyContext.find('button').click();
        replyContext.find('textarea').val(val).height(state[key + 'Height']).focus();
        replyContext?.setSelection?.(state[key + 'SelectStart'], state[key + 'SelectEnd']);
      }
    } else if (/^edit[0-9]+$/.test(key) && _.isString(val)) {
      const editContext = exports._getEditContext(key, context);
      if (editContext.length > 0) {
        editContext.find('> .comment-meta > .actions > .editable-comment-actions > [data-on-click="editComment"]').click();
        editContext.find('textarea').val(val).height(state[key + 'Height']).focus();
        editContext?.setSelection?.(state[key + 'SelectStart'], state[key + 'SelectEnd']);
      }
    } else if (key === 'createnew' && _.isString(val)) {
      const createContext = context.find('.comment-form > .comment');
      if (createContext.length > 0) {
        createContext.find('button').click();
        createContext.find('textarea').val(val).height(state[key + 'Height']).focus();
        createContext?.setSelection?.(state[key + 'SelectStart'], state[key + 'SelectEnd']);
      }
    }
  });
}
exports._getReplyContext = (key, context) => {
  const replyID = key.replace(/[^0-9]/g, '');
  if (Url.getCurrentPage() === 'epic') {
    return context.find('.comment-thread[data-id="' + replyID + '"] > .comment-reply-button');
  }
  return context.find('.comment-wrapper[data-id="' + replyID + '"] > .comment-replies > .comment-reply-wrapper');
};
exports._getEditContext = (key, context) => {
  const editID = key.replace(/[^0-9]/g, '');
  if (Url.getCurrentPage() === 'epic') {
    // TODO: Fix this
    return context.find('.comment-thread[data-id="' + editID + '"] > .comment-reply-button');
  }
  return context.find('.comment-wrapper[data-id="' + editID + '"] > .comment');
};
exports.isDirty = _.curry((type, entity, prop) => {
  const currentSnapshot = Snapshot.getSnapshot(entity);
  return currentSnapshot && currentSnapshot[prop] && entity[prop] && currentSnapshot[`${prop}InitialState`] !== entity[prop] &&
  //Check if attribute changed since opened
  currentSnapshot[prop] !== entity[prop]; // Check if current text is diferent
});
exports.editDescriptionOnShiftClick = editDescription => function (e) {
  if (e.shiftKey && !Is.readOnly(UserModel.getLoggedInUserPermission())) {
    editDescription.call($(this).parent().find('.edit-description')[0]);
    return false;
  }
  return CLICKABLE_MARKDOWN_TAGS.includes(e.target.tagName.toLowerCase());
};
exports.focus = context => {
  const parent = $(context).parent().find('.in-place-textarea');
  const textarea = parent.find('textarea');
  textarea.focus();
};
export { exports as default };