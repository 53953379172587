import "core-js/modules/es.array.push.js";
import Constants from './constants';
import Format from './format';
import Iterate from './iterate';
import Log from './log';
import Utils from './utils';
import _ from 'lodash';
import moment from 'moment';
const exports = {};
exports.init = context => {
  $(context).find('[data-validate]').each(function () {
    const container = $(this).addClass('validate-unedited');
    container.find('select').on('change', function () {
      if ($(this).val()) {
        container.removeClass('validate-unedited');
        exports.validate(context);
      }
    });
    container.find('input, textarea').on('blur change', function () {
      if ($(this).val() || $(this).is(':checked') || isOptional(container)) {
        container.removeClass('validate-unedited');
        exports.validate(context);
      }
    });
  });
};
exports.validateOnKeyup = context => {
  $(context).find('[data-validate]').each(function () {
    const container = $(this);
    container.find('input, select, textarea').on('keyup', function () {
      if ($(this).val()) {
        container.removeClass('validate-unedited');
      }
    });
  });
};
exports.destroy = context => {
  $(context).find('[data-validate]').find('select').off('change');
  $(context).find('[data-validate]').find('input, textarea').off('blur');
};
const getRules = element => {
  return Utils.splitCSV(Utils.data(element, 'validate') || '');
};
const isOptional = element => {
  return getRules(element).includes('optional');
};
exports.validate = context => {
  $(context).find('[data-validate]').not('.validate-unedited').each(function () {
    const rules = getRules(this);
    const container = $(this);
    container.find('input, select, textarea').each(function () {
      const errors = [];
      const input = $(this);
      if (isOptional(container) && input.val() === '') {
        exports.clearErrors(container);
        return;
      }
      rules.forEach(rule => {
        if (!exports.rules[rule]) {
          Log.log('Rule type "' + rule + '" not found.', {
            type: 'Template'
          });
        }
        const data = input.val();
        const errorString = exports.rules[rule](data, container);
        if (errorString) {
          errors.push(Utils.data(container, 'validate-message') || errorString);
        }
      });
      if (errors.length > 0) {
        exports.renderError(container, errors);
      } else {
        exports.clearErrors(container);
      }
    });
  });
};
exports.forceValidation = context => {
  $(context).find('.validate-unedited').removeClass('validate-unedited');
  exports.validate(context);
};
exports.clearErrors = context => {
  $(context).removeClass('hasErrors').find('.validate-errors').remove();
};
exports.renderError = (context, errors) => {
  let html = '';
  const existingMarkup = $(context).find('.validate-errors').html() || '';
  html += '<li>' + _.head(errors) + '</li>';
  if (html.trim() !== existingMarkup.trim()) {
    const element = $('<ul class="validate-errors"></ul>');
    exports.clearErrors(context);
    const inputElement = $(context).find('input, textarea');
    $(context).addClass('hasErrors');
    if (inputElement.length === 0) {
      $(context).append(element);
    } else if ($(inputElement).parent().hasClass('input-prefix-container')) {
      $(inputElement).parent().after(element);
    } else {
      inputElement.after(element);
    }
    element.html(html);
    if (!Utils.animationDisabled()) {
      element.hide().slideDown(200);
    }
  }
};
exports.isValid = context => {
  exports.forceValidation(context);
  return $(context).find('.validate-errors').length === 0;
};
exports.focusOnFirstError = context => {
  $(context).find('.validate-errors').first().closest('[data-validate]').find('textarea, input, select').focus();
};
exports.rules = {};
exports.rules.optional = () => {};
exports.rules.minLength = (data, context) => {
  const minLength = Utils.data(context, 'min-length');
  if (data.length < minLength) {
    return 'This field must be at least ' + minLength + ' characters.';
  }
};
exports.rules.notEmpty = data => {
  data = (data + '').trim();
  if (data === '') {
    return 'This field is required.';
  }
};
exports.rules.isEmail = data => {
  data = (data + '').trim();
  const regex = /\S+@\S+\.\S+/;
  if (!regex.test(data)) {
    return 'This must be a valid email address.';
  }
};
exports.rules.isUsername = data => {
  data = (data + '').trim();
  const regex = /^[a-z0-9\-\_\.]+$/;
  if (!regex.test(data)) {
    return 'Lowercase letters, numbers, underscores, dashes and periods only.';
  }
};
exports.rules.cannotEndWithAPeriod = data => {
  data = (data + '').trim();
  const regex = /\.$/;
  if (regex.test(data)) {
    return 'Cannot end with a period.';
  }
};
exports.rules.isUrl = data => {
  data = (data + '').trim();
  const regex = /^https?:\/{2}[\wÞßþøÀ-ÿ.-]+(?:\.[\wÞßþøÀ-ÿ.-]+)+[\wÞßþøÀ-ÿ\/._~:?#-[\]@!$&%'()*+,;=]+$/u;
  if (data.indexOf('http://localhost:') === 0) {
    return;
  }
  if (!regex.test(data)) {
    return 'This must be a valid URL.';
  }
};
exports.rules.isOptionalUrl = data => {
  if (data) {
    return exports.rules.isUrl(data);
  } else {
    return;
  }
};
exports.rules.isSubdomain = data => {
  data = (data + '').trim();
  const regex = /^[a-z0-9\-]+$/;
  if (!regex.test(data)) {
    return 'Lowercase letters, numbers, and dashes only.';
  }
};
exports.rules.hasValidIcon = (data, context) => {
  const errorIcon = context.find('.fa-times');
  if (errorIcon.length === 1) {
    const reason = errorIcon.data('reason');
    return reason || 'This is either already taken or not valid.';
  }
};
exports.rules.isOptionalMultipleEmails = data => {
  if (!data) {
    return;
  }
  return exports.rules.isMultipleEmails(data);
};
exports.rules.isMultipleEmails = data => {
  if (!data) {
    return 'This field is required.';
  }
  const emails = (data + '').trim().split(/[\s\,]+/g);
  let errorFound;
  Iterate.each(emails, email => {
    const result = exports.rules.isEmail(email);
    if (result) {
      errorFound = '<em>' + Format.sanitize(email.trim()) + '</em> is not a valid email address.';
      return false;
    }
  });
  return errorFound;
};
exports.rules.isPointScale = data => {
  const points = (data + '').trim().split(/[\s\,]+/g);
  const regex = /^[0-9]+$/;
  let lastPoint;
  let errorFound;
  Iterate.each(points, point => {
    if (!regex.test(point.trim())) {
      errorFound = 'Point <em>' + Format.sanitize(point) + '</em> is not a whole number.';
      return false;
    }
    if (lastPoint && Number.parseInt(point, 10) <= lastPoint) {
      errorFound = 'Points should be ordered from lowest to highest.';
      return false;
    }
    lastPoint = Number.parseInt(point, 10);
  });
  return errorFound;
};
exports.rules.passwordStrength = (data, context) => {
  const score = context.data('score');
  if (!_.isNumber(score)) {
    return 'This field is required.';
  }
  if (score < 2) {
    return 'This password is too weak.';
  }
};
exports.rules.match = (data, context) => {
  const selector = Utils.data(context, 'matching');
  const name = $(selector).closest('[data-validate]').find('label').text();
  if (!data) {
    return 'This field is required.';
  }
  if (data !== $(selector).val()) {
    return "This doesn't match the <em>" + name + '</em> field.';
  }
};
exports.rules.zipCode = data => {
  data = (data + '').trim();
  const regex = /^[0-9]{5}$/;
  if (data && !regex.test(data)) {
    return 'This should be a 5 digit number.';
  }
};
exports.rules.cardCVV = data => {
  data = (data + '').trim();
  const regex = /^[0-9]{3,4}$/;
  if (!regex.test(data)) {
    return 'Your CVV is a 3 or 4 digit number, found on the back of your card.';
  }
};
exports.rules.creditCardNumber = data => {
  data = (data + '').trim();
  if (!window.Stripe.card.validateCardNumber(data)) {
    return 'Not a valid credit card number.';
  }
};
exports.rules.cardExpirationDate = () => {
  const month = $('#exp-month option:selected').attr('name');
  const year = $('#exp-year').val();
  // var month = $('#exp-month option:selected').attr('name');

  if (!window.Stripe.card.validateExpiry(month, year)) {
    return 'Not a valid expiration date.';
  }
};
exports.rules.isChecked = (data, context) => {
  if (!context[0].querySelector('input[type="checkbox"]').checked) {
    return 'This field is required.';
  }
};
exports.rules.isValidDate = (data, context) => {
  const format = Utils.data(context, 'format') || Constants.SHORT_DATE_FORMAT;
  if (!moment(data.trim(), format, true).isValid()) {
    return 'This must be a valid date in ' + format + ' format.';
  }
};
exports.rules.isAfterDate = (data, context) => {
  const isValidFailed = exports.rules.isValidDate(data, context);
  if (isValidFailed) {
    return isValidFailed;
  }
  const selectedDate = moment((data + '').trim());
  const afterDate = moment(Utils.data(context, 'min-date'));
  if (!selectedDate.isAfter(afterDate)) {
    // We allow a custom message here, so we don't override the
    // default for other validation types used on the same field
    return Utils.data(context, 'min-date-message') || `This must be after ' + ${afterDate.format(Constants.SHORT_DATE_FORMAT)}.`;
  }
};
export { exports as default };