import { GroupAndOwnersOwnersPermissionFragmentDoc } from "../../../../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { Avatar } from '@clubhouse/shared/components/Avatar';
import { UserCohort } from '@clubhouse/shared/components/UserCohort';
import { UserCohortAdapter } from 'components/shared/UserCohortAdapter';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const GroupAndOwnersOwnersPermissionFragment = GroupAndOwnersOwnersPermissionFragmentDoc;
const userCohortDefaultProps = {
  numberOfUsers: 2,
  size: Avatar.SIZE.S,
  withTooltip: true,
  withDropdown: true,
  hasBorder: false
};

/**
 * Legacy implementing of `<Owners>`, expecting Collectionize data and directly linked to `UserCohort`.
 *
 * @deprecated Use `<Owners>` with Data Layer data instead.
 */
export const LegacyOwners = ({
  showEmail,
  users
}) => {
  return _jsx(UserCohort, {
    ...userCohortDefaultProps,
    users: users,
    showEmail: showEmail
  });
};
LegacyOwners.displayName = "LegacyOwners";
/**
 * Newer implementation of `<Owners>`, expecting Data Layer data and linked to `UserCohortAdapter`.
 */
export const Owners = ({
  showEmail,
  users
}) => {
  return _jsx(UserCohortAdapter, {
    ...userCohortDefaultProps,
    users: users,
    showEmail: showEmail
  });
};
Owners.displayName = "Owners";