import * as ManageUsersTemplate from 'app/client/settingsShared/views/templates/manageUsers.html';
import * as ManageUsersControlsTemplate from 'app/client/settingsShared/views/templates/manageUsersControls.html';
import { ImportUsers } from 'components/settings/user-directory/ImportUsers';
import Async from '../../../core/js/modules/async';
import CompanyModel from '../../../core/js/models/company';
import * as Event from '../../../core/js/_frontloader/event';
import FlashController from '../../../core/js/controllers/flash';
import Format from '../../../core/js/modules/format';
import Globals from '../../../core/js/_frontloader/globals';
import InviteModel from '../../../core/js/models/invite';
import InviteUsersController from './inviteUsers';
import Is from '../../../core/js/modules/is';
import LocalStorage from '../../../core/js/modules/localStorage';
import ManageUsersController from 'app/client/settingsShared/js/controllers/manageUsers';
import OrganizationModel from '../../../core/js/models/organization';
import PaymentPlan2Model from '../../../core/js/models/paymentPlan2';
import ProductModel from '../../../core/js/models/product';
import ProfileModel from '../../../core/js/models/profile';
import Url from '../../../core/js/modules/url';
import UserModel from '../../../core/js/models/user';
import Validator from '../../../core/js/modules/validator';
import View from '../../../core/js/modules/view';
import Errors from '../../../core/js/modules/errors';
import SettingsPage from '../../../settings/js/controllers/settingsPage';
import MessageController from '../../../core/js/controllers/message';
import Utils from '../../../core/js/modules/utils';
import { ActiveUsersDirectory } from 'pages/manage-users/ActiveUsersDirectory';
import { DisabledUsersDirectory } from 'pages/manage-users/DisabledUsersDirectory';
import _ from 'lodash';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const exports = {};
const PARENT_SELECTOR = '#manage-users-form';
const USER_DIRECTORY_SELECTOR = '#user-directory';
exports.title = `User Directory | ${BRAND.NAME}`;
exports.route = () => {
  const slug = Url.getSlugPath() || '/organizations';
  return slug + '/settings/users';
};
exports.open = () => {
  SettingsPage.loadPane(exports);
};
exports.openAtImportTab = () => {
  LocalStorage.set('Users.TabViewType', 'imported');
  SettingsPage.loadPane(exports);
};
exports.render = () => {
  const html = ManageUsersTemplate.render({
    active: 'manageUsers'
  });
  $(SettingsPage.PARENT_SELECTOR).html(html);
  Async.eachInParallelThen([UserModel.fetchAll, InviteModel.fetchAll, ProductModel.fetchAll, PaymentPlan2Model.fetch], () => {
    if (Url.isCurrentPath(exports.route())) {
      exports.openWithData();
      Validator.init(PARENT_SELECTOR);
      Event.on('CurrentUserRoleChanged.ManageUsers', () => {
        exports.openWithData();
      });
      SettingsPage.onDestroy('ManageUsers', () => {
        View.unmountComponents({
          templateMountNode: document.querySelector(USER_DIRECTORY_SELECTOR)
        });
        Validator.destroy(PARENT_SELECTOR);
        Event.off('CurrentUserRoleChanged.ManageUsers');
      });
    }
  });
};
exports.openWithData = () => {
  const orgId = Globals.get('organizationID');
  const permission = UserModel.getLoggedInUserPermission(orgId);
  const org = OrganizationModel.get({
    id: orgId
  }) || OrganizationModel.getCurrent();
  // FIXME (toby): these aren't users, so shouldn't be called users (they are profiles)
  const activeUsers = ProfileModel.getAllActiveProfileDetails(org.id);
  const company = CompanyModel.getFromOrg(org);
  const paymentPlan2 = PaymentPlan2Model.getPlanForOrg(org.id);
  // FIXME (toby): these aren't users, so shouldn't be called users (they are profiles)
  const importedUsers = ProfileModel.getAllImportedProfileDetails(org.ig);
  const tabType = exports.getTabViewType(permission);
  const tabView = tabType !== 'imported' ? tabType : importedUsers.length > 0 ? 'imported' : 'enabled';
  const activeUserCount = OrganizationModel.getActiveUserCount();
  View.renderWithComponents({
    templateMountNode: document.querySelector(USER_DIRECTORY_SELECTOR),
    template: ManageUsersControlsTemplate,
    templateData: {
      isAdmin: Is.adminOrOwner(permission),
      isOwner: Is.ownerOnly(permission),
      isUsersPage: Url.isCurrentPath(ManageUsersController.route()),
      hasObservers: ProfileModel.getAllActiveObservers(orgId).length > 0 || InviteModel.allPendingObserversForOrg(orgId).length > 0,
      active: 'manageUsers',
      me: permission,
      organization: org,
      scim_enabled: org.scim_enabled,
      invites: InviteModel.allPendingForOrganization(org.id),
      importedUsers,
      // FIXME (toby): these aren't users, so shouldn't be called users (they are profiles)
      disabledUsers: ProfileModel.getAllDisabledProfileDetails(org.id),
      // FIXME (toby): these aren't users, so shouldn't be called users (they are profiles)
      users: _.sortBy(activeUsers, 'lowercase_name'),
      activeUserCount,
      tabViewType: tabView,
      isMultiOrgCompany: CompanyModel.hasMultipleOrgs(company),
      companyDashboardUrl: CompanyModel.getDashboardUrl(company),
      paymentPlan2
    },
    components: {
      importedComponent: {
        component: ImportUsers,
        mountNode: document.getElementById('imported-users'),
        getProps: () => {
          return {
            users: importedUsers
          };
        }
      }
    }
  });
};
exports.getTabViewType = permission => {
  const defaultTab = 'enabled';
  return Is.admin(permission) ? LocalStorage.get('Users.TabViewType') || defaultTab : defaultTab;
};
exports.disableUser = function () {
  const profile = ProfileModel.getProfileDetailsFromContext(this);
  $(this).html('<span class="fa fa-spin fa-star"></span>').removeAttr('data-on-click');
  UserModel.disableUser(profile, err => {
    exports.openWithData();
    if (err) {
      FlashController.error(USER_DIRECTORY_SELECTOR, 'Unable to disable <strong>' + Format.sanitize(profile.name) + '</strong>.', err);
    } else {
      FlashController.success(USER_DIRECTORY_SELECTOR, '<strong>' + Format.sanitize(profile.name) + '</strong> disabled.');
    }
  });
  return false;
};
exports.deleteInvite = function () {
  Event.off('inviteDeleted.ManageUsers');
  Event.once('inviteDeleted.ManageUsers', () => {
    exports.openWithData();
  });
  const invite = Utils.getModelFromContext(this);
  $(this).html('<span class="fa fa-star fa-spin"></span>').removeAttr('data-on-click');
  InviteModel.deleteInvite(invite, err => {
    if (err) {
      MessageController.error(err, {
        secondary: 'Unable to delete invite.'
      });
    } else {
      MessageController.success('Invite deleted.');
      Event.trigger('inviteDeleted');
    }
  });
  return false;
};
exports.resendInvite = function () {
  return InviteUsersController.resendInvite.call(this);
};
exports.showEnabledUsers = () => {
  LocalStorage.set('Users.TabViewType', 'enabled');
  return false;
};
exports.showDisabledUsers = () => {
  LocalStorage.set('Users.TabViewType', 'disabled');
  return false;
};
exports.showImportedUsers = () => {
  LocalStorage.set('Users.TabViewType', 'imported');
};
exports.renderActiveUsersDirectory = (users, scimEnabled, invites) => View.renderComponentDelayed({
  componentKey: `active-users`,
  cssClass: 'user-cards',
  component: _jsx(ActiveUsersDirectory, {
    users: users,
    scimEnabled: scimEnabled,
    invites: invites,
    inviteUsersRoute: InviteUsersController.route(),
    onUpdateRole: async (userId, newRole) => {
      const profile = ProfileModel.getAllDetailsById(userId);
      await updateRole(profile, newRole);
    }
  })
}).html;
exports.renderDisabledUsersDirectory = (disabledUsers, scimEnabled) => View.renderComponentDelayed({
  componentKey: `disabled-users`,
  component: _jsx(DisabledUsersDirectory, {
    users: disabledUsers,
    scimEnabled: scimEnabled,
    onRestoreUser: async userId => {
      const profile = ProfileModel.getAllDetailsById(userId);
      await restoreUser(profile);
    }
  })
}).html;
function updateRole(profile, role) {
  return new Promise((resolve, reject) => {
    UserModel.updateRole(profile, role, err => {
      exports.openWithData();
      if (err) {
        err = Errors.wrapWithCardLink(err);
        FlashController.error(USER_DIRECTORY_SELECTOR, 'Unable to update role for <strong>' + Format.sanitize(profile.name) + '</strong>.', err);
        reject(err);
      } else {
        FlashController.success(USER_DIRECTORY_SELECTOR, 'Role updated for <strong>' + Format.sanitize(profile.name) + '</strong>.');
        resolve();
      }
    });
  });
}
function restoreUser(profile) {
  return new Promise((resolve, reject) => {
    UserModel.restoreUser(profile, err => {
      exports.openWithData();
      if (err) {
        err = Errors.wrapWithCardLink(err);
        FlashController.error(USER_DIRECTORY_SELECTOR, 'Unable to restore <strong>' + Format.sanitize(profile.name) + '</strong>.', err);
        reject(err);
      } else {
        View.fadeAndSlideUp(profileElement);
        FlashController.success(USER_DIRECTORY_SELECTOR, '<strong>' + Format.sanitize(profile.name) + '</strong> restored.');
        resolve();
      }
    });
  });
}
export { exports as default };