import * as StoryRelationshipsTemplate from 'app/client/core/views/templates/storyRelationships.html';
import { ContextualStoryCardWithViewSettings, WithFullStoryById } from 'components/shared/ContextualStoryCard';
import { ModifyStoryRelationshipDropdown } from 'components/stories/ModifyStoryRelationshipDropdown';
import MessageController from './message';
import StoryLinkModel from '../models/storyLink';
import UserModel from '../models/user';
import Is from '../modules/is';
import View from '../modules/view';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const exports = {};
const updateStoryLink = async ({
  existingStoryLink: {
    id
  },
  update
}) => {
  try {
    await StoryLinkModel.Promises.update({
      id,
      ...update
    });
  } catch (e) {
    MessageController.error(`Story Relationship update failed: ${e}`);
  }
};
const removeStoryLink = async ({
  existingStoryLink: {
    id
  }
}) => {
  try {
    await StoryLinkModel.Promises.delete(id);
  } catch (e) {
    MessageController.error(`Story Relationship delete failed: ${e}`);
  }
};
exports.updateStoryLinkComponent = (story, element, {
  update = updateStoryLink,
  remove = removeStoryLink
} = {}) => {
  const container = $(element).find('.story-relationships');
  if (container.length > 0) {
    const isReadOnly = Is.readOnly(UserModel.getLoggedInUserPermission());
    View.renderWithComponents({
      templateMountNode: container[0],
      template: StoryRelationshipsTemplate,
      templateData: {
        readOnly: isReadOnly,
        ...story
      },
      restoreActiveElement: true,
      components: {
        modifyStoryRelationship: {
          component: ModifyStoryRelationshipDropdown,
          getProps: ({
            props: {
              storyLinkKey
            }
          }) => {
            const storyLink = story.story_links.find(link => StoryLinkModel.getKey(link) === storyLinkKey);
            const relatedStory = StoryLinkModel.getRelatedStory(storyLink);
            return {
              story,
              storyLink,
              relatedStory,
              update,
              remove
            };
          }
        }
      }
    });
  }
};
exports.renderStoryCard = story => {
  return View.renderComponentDelayed({
    componentKey: `storyCard-${story.id}`,
    cssClass: 'js-story-card react-wrapper story',
    containerProps: {
      'data-model': 'Story',
      'data-id': story.id,
      'data-updated-at': story.updated_at,
      role: 'listitem',
      'aria-labelledby': `story-${story.id}`
    },
    component: _jsx(WithFullStoryById, {
      showCardColor: true,
      showSelectToggle: true,
      openModalOnClick: false,
      component: props => _jsx(ContextualStoryCardWithViewSettings, {
        ...props,
        useDensity: true,
        level: "1"
      }),
      storyId: story.id
    }, story.id)
  }).html;
};
exports.removeInvalidStoryLinks = async links => {
  const storyLinkIsValidStatuses = await Promise.all(links.map(link => StoryLinkModel.isValidWithFetch(link)));
  return links.filter((_, index) => storyLinkIsValidStatuses[index]);
};
export { exports as default };