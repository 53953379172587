import { MutateStoryPositionDocument } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { StoryReorderFragmentFragmentDoc } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { useCallback } from 'react';
import { gql } from '@clubhouse/datalayer';
import { insertIf } from '@clubhouse/shared/utils/object';
import { moveWithinArray } from '@clubhouse/shared/utils/reorder';
import { getPositionChange } from 'components/shared/hooks/useOnChangePriority';
import { useMutation } from 'gql';
export const StoryReorderFragment = StoryReorderFragmentFragmentDoc;
export const MUTATE_STORY_POSITION = MutateStoryPositionDocument;
const useUpdateStoryPosition = () => {
  const [updatePosition] = useMutation(MUTATE_STORY_POSITION);
  return updatePosition;
};
export const useOptimisticReordering = ({
  stories,
  sortDirection,
  updatePosition,
  updateQuery
}) => {
  return useCallback(async ({
    oldIndex,
    newIndex
  }) => {
    if (oldIndex === newIndex) return;
    const story = stories[oldIndex];
    const changes = getPositionChange({
      oldIndex,
      newIndex,
      entityList: stories,
      sortDirection
    });
    const promise = updatePosition({
      variables: {
        id: story.id,
        input: {
          ...insertIf(!!changes.before_id, {
            before: String(changes.before_id)
          }),
          ...insertIf(!!changes.after_id, {
            after: String(changes.after_id)
          })
        }
      }
    });
    updateQuery(previousQueryResult => {
      const edges = moveWithinArray([...previousQueryResult.workspace2.stories.edges], oldIndex, newIndex);
      return {
        ...previousQueryResult,
        workspace2: {
          ...previousQueryResult.workspace2,
          stories: {
            ...previousQueryResult.workspace2.stories,
            edges
          }
        }
      };
    });
    await promise;
  }, [stories, sortDirection, updatePosition, updateQuery]);
};
export function useStoryReordering({
  stories,
  sortDirection,
  updateQuery
}) {
  const updatePosition = useUpdateStoryPosition();
  return useOptimisticReordering({
    stories,
    sortDirection,
    updateQuery,
    updatePosition
  });
}