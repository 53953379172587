import { Icon } from '@useshortcut/shapes-ds';
import { useRef } from 'react';
import { FloatingElement } from '@clubhouse/shared/components/FloatingElement';
import { PullRight } from '@clubhouse/shared/components/Spaced';
import { useKeyboardMenuNavigation, useToggleState } from '@clubhouse/shared/hooks';
import { useMenuClose, useMenuElement, useMenuItemProps, useSubMenuOnHover } from '@clubhouse/shared/utils/menuState';
import { navigateOnClick } from 'utils/navigation';
import { MenuItemButton, MenuItemIcon, MenuItemLink, MenuItemText, MenuItemWrapper } from './shared';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const useMenuItem = ({
  subMenuId,
  hasSubMenu
}) => {
  const ref = useRef(null);
  const subMenuRef = useRef(null);
  const [isSubMenuOpen, {
    on: onOpenSubMenu,
    off: onCloseSubMenu
  }] = useToggleState();
  const {
    closeThis,
    closeAll,
    closeAllWhenResolved
  } = useMenuClose();
  const menu = useMenuElement();
  const menuItemProps = useMenuItemProps();
  useSubMenuOnHover({
    hasSubMenu,
    subMenuId,
    isSubMenuOpen,
    menuItemRef: ref,
    subMenuRef,
    menuRef: menu,
    onShow: onOpenSubMenu,
    onHide: onCloseSubMenu
  });
  const {
    onKeyDown
  } = useKeyboardMenuNavigation({
    parentRef: menu,
    closeMenu: closeThis !== closeAll ? closeThis : undefined,
    openSubMenu: hasSubMenu ? onOpenSubMenu : undefined
  });
  return {
    ref,
    subMenuRef,
    menuItemProps,
    onKeyDown,
    subMenuIsOpen: isSubMenuOpen,
    onOpenSubMenu,
    onCloseSubMenu,
    closeAll,
    closeAllWhenResolved
  };
};
const SubMenuButton = ({
  icon,
  label,
  SubMenu
}) => {
  const {
    ref,
    subMenuRef,
    menuItemProps,
    onKeyDown,
    subMenuIsOpen,
    onOpenSubMenu,
    onCloseSubMenu,
    closeAll
  } = useMenuItem({
    hasSubMenu: true
  });
  return _jsxs(MenuItemWrapper, {
    role: "presentation",
    children: [_jsxs(MenuItemButton, {
      ref: ref,
      ...menuItemProps,
      onKeyDown: onKeyDown,
      onClick: onOpenSubMenu,
      role: "menuitem",
      "aria-haspopup": "menu",
      "aria-expanded": subMenuIsOpen,
      isOpen: subMenuIsOpen,
      children: [icon, _jsx(MenuItemText, {
        children: label
      }), _jsx(PullRight, {
        children: _jsx(Icon, {
          icon: "ChevronRight",
          fill: "disabled"
        })
      })]
    }), subMenuIsOpen && _jsx(FloatingElement, {
      trigger: ref.current,
      onClose: closeAll,
      detectClicksOutside: "none",
      offsetMainAxis: 2,
      children: _jsx(SubMenu, {
        ref: subMenuRef,
        onClose: onCloseSubMenu
      })
    })]
  });
};
SubMenuButton.displayName = "SubMenuButton";
const SplitSubMenuButton = ({
  icon,
  label,
  onClick,
  SubMenu,
  subMenuId
}) => {
  const {
    ref,
    subMenuRef,
    menuItemProps,
    onKeyDown,
    subMenuIsOpen,
    onCloseSubMenu,
    closeAll,
    closeAllWhenResolved
  } = useMenuItem({
    subMenuId,
    hasSubMenu: true
  });
  return _jsxs(MenuItemWrapper, {
    role: "presentation",
    children: [_jsxs(MenuItemButton, {
      ...menuItemProps,
      onClick: e => {
        const result = onClick(e);
        if (result instanceof Promise) closeAllWhenResolved(result);else closeAll();
      },
      onKeyDown: onKeyDown,
      role: "menuitem",
      "aria-haspopup": "menu",
      "aria-expanded": subMenuIsOpen,
      children: [icon, _jsx(MenuItemText, {
        children: label
      })]
    }), _jsx(PullRight, {
      children: _jsx(MenuItemButton, {
        ref: ref,
        as: "div",
        id: subMenuId,
        ...menuItemProps,
        isOpen: subMenuIsOpen,
        children: _jsx(Icon, {
          icon: "ChevronRight",
          fill: "disabled"
        })
      })
    }), subMenuIsOpen && _jsx(FloatingElement, {
      trigger: ref.current,
      onClose: closeAll,
      detectClicksOutside: "none",
      offsetMainAxis: 2,
      children: _jsx(SubMenu, {
        ref: subMenuRef,
        onClose: onCloseSubMenu
      })
    })]
  });
};
SplitSubMenuButton.displayName = "SplitSubMenuButton";
const ActionButton = ({
  icon,
  label,
  onClick
}) => {
  const {
    menuItemProps,
    onKeyDown,
    closeAll,
    closeAllWhenResolved
  } = useMenuItem({
    hasSubMenu: false
  });
  return _jsx(MenuItemWrapper, {
    role: "presentation",
    children: _jsxs(MenuItemButton, {
      ...menuItemProps,
      onClick: e => {
        const result = onClick(e);
        if (result instanceof Promise) closeAllWhenResolved(result);else closeAll();
      },
      onKeyDown: onKeyDown,
      role: "menuitem",
      children: [icon, _jsx(MenuItemText, {
        children: label
      })]
    })
  });
};
ActionButton.displayName = "ActionButton";
const ActionLink = ({
  icon,
  label,
  href
}) => {
  const {
    menuItemProps,
    onKeyDown,
    closeAll
  } = useMenuItem({
    hasSubMenu: false
  });
  const onClick = navigateOnClick(href);
  return _jsx(MenuItemWrapper, {
    role: "presentation",
    children: _jsxs(MenuItemLink, {
      ...menuItemProps,
      href: href,
      onClick: e => {
        closeAll();
        onClick(e);
      },
      onKeyDown: onKeyDown,
      role: "menuitem",
      children: [icon, _jsx(MenuItemText, {
        children: label
      })]
    })
  });
};
ActionLink.displayName = "ActionLink";
export const MenuItem = ({
  href,
  onClick,
  SubMenu,
  ...props
}) => {
  if (onClick && SubMenu) return _jsx(SplitSubMenuButton, {
    ...props,
    onClick: onClick,
    SubMenu: SubMenu
  });
  if (SubMenu) return _jsx(SubMenuButton, {
    ...props,
    SubMenu: SubMenu
  });
  if (href) return _jsx(ActionLink, {
    ...props,
    href: href
  });
  if (onClick) return _jsx(ActionButton, {
    ...props,
    onClick: onClick
  });
  throw new Error('A MenuItem requires that either SubMenu or onClick be defined.');
};
export const MenuItemWithIcon = ({
  icon,
  ...props
}) => _jsx(MenuItem, {
  ...props,
  icon: _jsx(MenuItemIcon, {
    icon: icon
  })
});
MenuItemWithIcon.displayName = "MenuItemWithIcon";