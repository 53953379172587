import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormStateContext, useFormState } from 'pages/signup/FormState';
export const useSignupForm = (options = {}) => {
  const {
    getFormState,
    setFormState
  } = useFormState();
  const result = useForm({
    defaultValues: getFormState(),
    context: FormStateContext,
    ...options
  });
  const {
    watch
  } = result;
  useEffect(() => {
    const subscription = watch(values => {
      setFormState(values);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [setFormState, watch]);
  return result;
};