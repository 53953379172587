import { useCallback } from 'react';
import { isSeverity, isSeverityOrPriority } from '@clubhouse/shared/utils/customFields';
import { getAllEnabled } from 'data/entity/customField';
import { saveCustomFieldValue } from 'data/entity/story';
import { getCustomFieldSort } from 'utils/sort';
import { TableCustomField } from './tableComponents/TableCustomField';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export function CustomFieldComponentHOC(field) {
  const CustomFieldComponent = ({
    entity
  }) => {
    const storyField = entity.custom_fields.find(f => f.field_id === field.id);
    const handleChange = useCallback(valueId => {
      saveCustomFieldValue(entity, field.id, valueId);
    }, [entity, field.id]);
    if (entity.story_type !== 'bug' && isSeverity(field.canonical_name)) return null;
    return _jsx(TableCustomField, {
      field: field,
      valueId: storyField?.value_id,
      onChange: handleChange,
      onlyIcon: isSeverityOrPriority(field.canonical_name)
    });
  };
  return CustomFieldComponent;
}
export const CustomFieldTableField = field => {
  const shouldOnlyDisplayIcon = isSeverityOrPriority(field.canonical_name);
  const CustomFieldComponent = CustomFieldComponentHOC(field);
  const columnConfig = {
    displayName: field.name,
    name: `${field.id}`,
    Component: CustomFieldComponent,
    width: shouldOnlyDisplayIcon ? 80 : 160,
    sort: getCustomFieldSort(field)
  };
  if (shouldOnlyDisplayIcon) {
    columnConfig.headerProps = {
      centered: true
    };
  }
  return columnConfig;
};
export const CustomFieldTableFields = () => getAllEnabled().map(CustomFieldTableField);