import * as AuthorizeApplicationTemplate from 'app/client/appauth/views/templates/authorizeApplication.html';
import * as NoApplicationFoundTemplate from 'app/client/appauth/views/templates/noApplicationFound.html';
import ApplicationModel from '../../../core/js/models/application';
import * as Event from '../../../core/js/_frontloader/event';
import FlashController from '../../../core/js/controllers/flash';
import InstallationModel from '../../../core/js/models/installation';
import OrganizationModel from '../../../core/js/models/organization';
import ProfileModel from '../../../core/js/models/profile';
import Url from '../../../core/js/modules/url';
import Utils from '../../../core/js/modules/utils';
import SadRobot from '@clubhouse/assets/png/sad-robot.png';
import Logo from '@clubhouse/assets/svg/shortcut-logos/shortcut-logo-mark.svg?url';
import _ from 'lodash';
const exports = {};
const PARENT_SELECTOR = '#auth';
exports.render = () => {
  const activeOrgs = OrganizationModel.getAllActive();
  const application = ApplicationModel.getById(_getAppIdFromUrl());
  let html = '';
  if (application) {
    html = AuthorizeApplicationTemplate.render({
      application,
      organizations: activeOrgs,
      profile: ProfileModel.getCurrentUserProfileDetails(),
      shortcutLogo: Logo
    });
  } else {
    html = NoApplicationFoundTemplate.render({
      img: SadRobot
    });
  }
  $(PARENT_SELECTOR).html(html);
  exports.updateOrg.call($('#orgs-list'));
  Event.trigger('resize.AppAuth');
  Event.trigger('pageRendered', 'AppAuth');
};
exports.updateOrg = function () {
  const element = $(this);
  const orgId = element.val();
  const org = OrganizationModel.get({
    id: orgId
  });
  if (!org) {
    element.removeClass('active');
    _markButtonAsDisabled($('#authorize-button'));
  } else {
    element.addClass('active');
    _markButtonAsEnabled($('#authorize-button'));
  }
};
exports.authorize = function () {
  const orgId = $('#orgs-list').val();
  if (orgId) {
    const org = OrganizationModel.get({
      id: orgId
    });
    if (org) {
      _markButtonAsActionPending(this);
      const appId = _getAppIdFromUrl();
      const data = {};
      if (appId === ApplicationModel.APPLICATION_IDS.Sentry) {
        const {
          orgSlug,
          code,
          installationId
        } = Url.parseLocationSearch();
        _.assign(data, {
          external_id: installationId,
          sentry_code: code,
          sentry_organization_slug: orgSlug
        });
      }
      InstallationModel.installInstallationForOrg(appId, orgId, data, (err, newInstallation) => {
        if (!err) {
          redirectToIntegrationSettings(org, newInstallation);
        } else {
          _markButtonAsEnabled($('#authorize-button'));
          showErrorMessage(err, appId);
        }
      });
    }
  }
};
function redirectToIntegrationSettings(org, newInstallation) {
  const integrationPath = ApplicationModel.getIntegrationSettingsPath(newInstallation.application_id);
  const redirectPath = `/${org.url_slug}${integrationPath}`;
  Utils.redirect(redirectPath);
}
function showErrorMessage(err, appId) {
  const app = ApplicationModel.getById(appId);
  let message = err;
  if (appId === ApplicationModel.APPLICATION_IDS.Sentry) {
    const {
      orgSlug
    } = Url.parseLocationSearch();
    message = `${message}
<a href="https://sentry.io/settings/${orgSlug}">Return to Sentry settings</a>`;
  }
  FlashController.error('.application', `Unable to enable ${app.name} application`, message, true);
}
function _markButtonAsActionPending(button) {
  $(button).attr('disabled', true).addClass('disabled').removeAttr('data-on-click').html('<span class="fa fa-star fa-spin"></span>');
}
function _markButtonAsDisabled(button) {
  $(button).attr('disabled', true).addClass('disabled').removeAttr('data-on-click');
}
function _markButtonAsEnabled(button) {
  $(button).removeAttr('disabled').removeClass('disabled').attr('data-on-click', 'authorize').html('Authorize');
}
function _getAppIdFromUrl() {
  return Url.parsePath().appauth;
}
export { exports as default };