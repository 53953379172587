import "core-js/modules/es.array.push.js";
import { isMac } from './operatingSystem';
export const isEnterOrSpace = e => e.key === 'Enter' || e.key === ' ';
export const onKeyboardActivation = (fn, {
  preventDefault = true,
  stopPropagation = true
} = {}) => e => {
  if (isEnterOrSpace(e)) {
    if (preventDefault) e.preventDefault();
    if (stopPropagation) e.stopPropagation();
    fn(e);
  }
};
export const getSpecialKey = event => isMac() ? event.metaKey : event.ctrlKey;
export const toShortcutString = e => {
  const keys = [];
  if (e.metaKey) keys.push('cmd');
  if (e.ctrlKey) keys.push('ctrl');
  if (e.altKey) keys.push('alt');
  if (e.shiftKey) keys.push('shift');
  return keys.join('+');
};
export const toShortcutLabel = shortcut => {
  return isMac() ? shortcut.replace(/cmd/gi, '⌘').replace(/alt/gi, '⌥').replace(/shift/gi, '⇧').replace(/ctrl/gi, '⌃').replace(/\s*\+\s*/g, ' ') : shortcut.replace(/cmd/gi, 'Win').replace(/alt/gi, 'Alt').replace(/shift/gi, 'Shift').replace(/ctrl/gi, 'Ctrl').replace(/\s*\+\s*/g, ' + ');
};
export const toScreenReaderLabel = shortcut => {
  const mac = isMac();
  return shortcut.replace(/cmd/gi, mac ? 'Command' : 'Win').replace(/alt/gi, mac ? 'Option' : 'Alt').replace(/shift/gi, 'Shift').replace(/ctrl/gi, 'Control').replace(/\s*\+\s*/g, ' + ');
};
export const hasModifierKey = (e, ignore = {}) => {
  let hasModifierKey = false;
  if (ignore?.altKey !== true) hasModifierKey = hasModifierKey || e.altKey;
  if (ignore?.ctrlKey !== true) hasModifierKey = hasModifierKey || e.ctrlKey;
  if (ignore?.metaKey !== true) hasModifierKey = hasModifierKey || e.metaKey;
  if (ignore?.shiftKey !== true) hasModifierKey = hasModifierKey || e.shiftKey;
  return hasModifierKey;
};