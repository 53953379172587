import { MutateStoryTeamFieldDocument } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { QueryStoryTeamSelectDocument } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { StoriesTableTeamFragmentFragmentDoc } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { useCallback } from 'react';
import { gql } from '@clubhouse/datalayer';
import { Center } from '@clubhouse/shared/components/Center';
import { SkeletonCircle } from '@clubhouse/shared/components/SkeletonCircle';
import { useToast } from '@clubhouse/shared/components/Toast';
import { Nouns } from '@clubhouse/shared/constants';
import { GroupedOptionsFieldSingleSelection } from 'components/gql/GroupedOptionsField';
import { useFieldOptionsQuery } from 'components/gql/stories/table/fields/useFieldOptionsQuery';
import { TeamSelectTarget } from 'components/gql/team/TeamSelectTarget';
import { getSection } from 'components/gql/team/utils/sections';
import { createOptimisticStoryMutationResponse } from 'components/gql/utils/mutation';
import { useMutation } from 'gql';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const StoriesTableTeamFragment = StoriesTableTeamFragmentFragmentDoc;
const QUERY_STORY_TEAM_SELECT = QueryStoryTeamSelectDocument;
const MUTATE_TEAM_FIELD = MutateStoryTeamFieldDocument;
export const TeamFieldComponent = ({
  entity: {
    id,
    team
  }
}) => {
  const {
    addToast
  } = useToast();
  const fetchTeams = useFieldOptionsQuery({
    query: QUERY_STORY_TEAM_SELECT,
    storyId: id,
    includeCurrentPermissionId: true
  });
  const [executeMutation] = useMutation(MUTATE_TEAM_FIELD);
  const handleChange = useCallback(async selectedId => {
    const response = await executeMutation({
      variables: {
        storyId: id,
        input: {
          team: selectedId
        },
        skipAllExceptId: true
      },
      optimisticResponse: createOptimisticStoryMutationResponse(id, {
        team: selectedId ? {
          __typename: 'Team',
          id: selectedId
        } : null
      })
    });
    if (!response.data?.storyUpdate && response.extensions) {
      response.extensions.warnings?.forEach(({
        message
      }) => {
        addToast({
          Content: () => _jsx("div", {
            children: message
          }),
          kind: 'alert',
          timeout: 5000
        });
      });
    }
  }, [executeMutation, id, addToast]);
  return _jsx(Center, {
    children: _jsx("div", {
      children: _jsx(GroupedOptionsFieldSingleSelection, {
        selectedEntity: team,
        fetchOptions: fetchTeams,
        getSection: getSection,
        TargetComponent: TeamSelectTarget,
        onChange: handleChange,
        unit: Nouns.Team
      })
    })
  });
};
TeamFieldComponent.displayName = "TeamFieldComponent";
TeamFieldComponent.displayName = 'TeamFieldComponent';
export const TeamField = () => ({
  name: 'team',
  displayName: 'Team',
  headerProps: {
    centered: true
  },
  Component: TeamFieldComponent,
  LoadingComponent: () => _jsx(Center, {
    children: _jsx(SkeletonCircle, {
      size: 24
    })
  }),
  width: 60,
  sort: 'team_name'
});