import { FilterField } from '@clubhouse/shared/components/FilterField';
import { SearchInput } from '@clubhouse/shared/components/SearchInput';
import { useIsMobile } from '@clubhouse/shared/hooks';
import { useFilterState } from './hooks/useFilterState';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const QueryFilter = ({
  entityType,
  placeholder
}) => {
  const [query, setQuery] = useFilterState({
    paramName: 'query',
    paramType: 'string',
    defaultValue: ''
  });
  const isMobile = useIsMobile();
  placeholder = isMobile ? `Search...` : `Search ${entityType.plural}...`;
  return _jsx(FilterField, {
    label: "Filter",
    children: _jsx(SearchInput, {
      width: isMobile ? 'auto' : 190,
      value: query,
      placeholder: placeholder,
      onChange: setQuery,
      onChangeDebounceMs: 300
    })
  });
};
QueryFilter.displayName = "QueryFilter";