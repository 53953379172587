import { Combobox } from '@clubhouse/shared/components/Combobox';
import { getStoryReferenceFromUrl, isEmbeddableLink } from '../MarkdownEditor/utils';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const StoryReferenceItem = ({
  fragment
}) => {
  const storyRef = getStoryReferenceFromUrl(fragment);
  if (!storyRef) return null;
  return _jsx(Combobox.SelectableItem, {
    value: storyRef,
    children: "Create Story Reference"
  });
};
StoryReferenceItem.displayName = "StoryReferenceItem";
const EmbeddableLinkItem = ({
  fragment
}) => {
  if (!isEmbeddableLink(fragment)) return null;
  return _jsx(Combobox.SelectableItem, {
    value: fragment,
    children: "Embed Link"
  });
};
EmbeddableLinkItem.displayName = "EmbeddableLinkItem";
export function LinkMenu({
  triggerRef,
  fragment
}) {
  return _jsx(Combobox.Menu, {
    triggerRef: triggerRef,
    isModal: false,
    children: _jsxs(Combobox.List, {
      children: [_jsx(StoryReferenceItem, {
        fragment: fragment
      }), _jsx(EmbeddableLinkItem, {
        fragment: fragment
      }), _jsx(Combobox.SelectableItem, {
        value: `[link](${fragment})`,
        children: "Create Link"
      }), _jsx(Combobox.SelectableItem, {
        value: fragment,
        children: "Dismiss"
      })]
    })
  });
}
LinkMenu.displayName = "LinkMenu";