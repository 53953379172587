import BaseUtils from 'app/client/core/js/_frontloader/baseUtils';
import Format from 'app/client/core/js/modules/format';
import Utils from 'app/client/core/js/modules/utils';
export const tokenize = (...args) => Utils.tokenizeQuery(...args);
export const sanitize = (...args) => Format.sanitize(...args);
export const formatUtcOffset = offset => offset === 'Z' ? '±00:00' : offset;

/** @deprecated Use clubhouse/shared/src/utils/pluralize instead */
export const pluralize = (...args) => Format.pluralize(...args);
export const toMoney = str => {
  if (str == null) return '';
  return Utils.toMoney(str);
};
export const grammaticalJoin = (...args) => Utils.grammaticalJoin(...args);
export const toNumber = (...args) => BaseUtils.toNumber(...args);
/**
 * Returns a link renderer function that overrides marked's default link renderer to add target=_blank to external links.
 *
 * @see: {@link https://marked.js.org/using_pro#renderer}
 */
export function createLinkRenderer(Renderer, instance) {
  return (href, title, text) => {
    const isLocalLink = href ? href.startsWith(`${location.protocol}//${location.hostname}`) : true;
    const html = Renderer.prototype.link.call(instance, href, title, text);
    if (isLocalLink) {
      return html;
    }
    return html.replace(/^<a /, `<a target="_blank" rel="noopener noreferrer" `);
  };
}
export const markdownify = (str, source, options) => Format.markdownify(str, source, options);
export const escapeRegex = str => Utils.escapeRegex(str);
export const parseOutsideMarkdownCodeBlocks = (str, fn) => Format.parseOutsideMarkdownCodeBlocks(str, fn);