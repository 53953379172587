import { TableText } from '@clubhouse/shared/components/Table';
import { FIELD_ID } from 'components/shared/table/types';
import { id } from 'utils/sort';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const IdFieldComponent = ({
  entity
}) => {
  return _jsx(TableText, {
    text: entity.id
  });
};
IdFieldComponent.displayName = "IdFieldComponent";
export const IdField = (overrides = {}) => ({
  name: FIELD_ID.ID,
  displayName: 'ID',
  sort: id,
  headerProps: {
    centered: true
  },
  Component: IdFieldComponent,
  width: 75,
  maxWidth: 90,
  ...overrides
});