import * as EpicTooltipTemplate from 'app/client/core/views/templates/epicTooltip.html';
import AutocompleteController from './autocomplete';
import DropdownController from './dropdown';
import EpicModel from '../models/epic';
import EpicStateModel from '../models/epicState';
import Format from '../modules/format';
import Is from '../modules/is';
import InviteUsersController from '../../../settingsShared/js/controllers/inviteUsers';
import MessageController from './message';
import MilestoneModel from '../models/milestone';
import OrganizationModel from '../models/organization';
import ProfileModel from '../models/profile';
import Tooltip from '../modules/tooltip';
import Utils from '../modules/utils';
import View from '../modules/view';
import { EpicProgressBar } from 'components/epic/EpicProgressBar';
import _ from 'lodash';
import moment from 'moment';
const exports = {};
exports.epicStateControl = function (epic, onApply, onSave) {
  DropdownController.open({
    items: EpicStateModel.getItemsForAutocomplete(epic.epic_state_id),
    title: 'Update Epic State',
    target: this,
    targetSelector: '#epicStateControl .details-stat-value',
    topOffset: 4,
    onApply: epicState => {
      const {
        id
      } = epicState;
      if (epic.epic_state_id === id) {
        return;
      }
      onApply();
      EpicModel.saveChanges(epic, {
        epic_state_id: id
      }, onSave);
    },
    width: 240
  });
};
exports.getMilestonesForAutocomplete = epic => {
  const items = MilestoneModel.getItemsForAutocomplete({
    mapFn: milestone => ({
      className: epic.milestone_id === milestone.id ? 'active' : '',
      customIconLeft: milestone.state_icon,
      name: Format.sanitizeAndEmojify(milestone.name),
      value: milestone.id
    })
  });
  items.unshift({
    hr: true
  });
  items.unshift({
    className: epic.milestone_id === null ? 'active' : '',
    name: '<em>None</em>',
    value: null
  });
  return items;
};
exports.epicMilestoneControl = function (epic, onApply, onSave) {
  AutocompleteController.open({
    items: exports.getMilestonesForAutocomplete(epic),
    title: 'Update Epic Objective',
    target: this,
    targetSelector: '#epicMilestoneControl .details-stat-value',
    topOffset: 4,
    onApply: value => {
      if (epic.milestone_id === value) {
        return;
      }
      onApply();
      EpicModel.saveChanges(epic, {
        milestone_id: value
      }, onSave);
    },
    width: 240,
    showInput: true
  });
};
exports.updateRequester = (epic, changes) => new Promise((resolve, reject) => {
  EpicModel.Promises.saveChanges(epic, changes).then(resolve).catch(err => {
    MessageController.error(err, {
      secondary: 'Unable to update requester.'
    });
    reject();
  });
});
exports.updateOwner = epic => new Promise((resolve, reject) => {
  EpicModel.Promises.saveOwners(epic).then(resolve).catch(err => {
    MessageController.error(err, {
      secondary: 'Unable to update owners.'
    });
    reject();
  });
});
exports.ownerControl = function (epic, onApply, onSave) {
  function onSaveWrapper(err) {
    if (err) {
      AutocompleteController.close();
      MessageController.error(err, {
        secondary: 'Unable to update owners.'
      });
    } else {
      AutocompleteController.enable();
    }
    onSave();
  }

  // Might be hovering over an avatar here, so let's close the tooltip.
  Tooltip.close();
  AutocompleteController.open({
    items: ProfileModel.getItemsForOwnerAutocomplete(epic, epic.owners),
    footer: InviteUsersController.getInviteUsersFooter(),
    onResultsUpdate: InviteUsersController.getAutocompleteWithInviteUpdateHandler,
    multiSelect: true,
    title: 'Update Epic Owners',
    onApply: profile => {
      // Handle case where user enters non-matching string in input field.
      // This will simply reset the autocomplete to its original state.
      if (profile && !ProfileModel.isValid(profile)) {
        return false;
      }
      onApply();
      AutocompleteController.disable();
      AutocompleteController.input.val(profile ? profile.name : 'Nobody');
      if (!profile) {
        EpicModel.removeAllOwners(epic, onSaveWrapper);
      } else if (EpicModel.isOwner(epic, profile)) {
        EpicModel.removeOwner(epic, profile, onSaveWrapper);
      } else {
        EpicModel.addOwner(epic, profile, onSaveWrapper);
      }
    },
    showInput: true,
    target: this,
    targetSelector: '#epicOwnersControl .details-stat-value',
    topOffset: 4,
    value: $(this).attr('data-value'),
    width: 240
  });
  return false;
};
exports.editDateInRow = function ({
  onSuccess = () => {}
} = {}) {
  const epic = Utils.getModelFromContext(this);
  const fieldName = Utils.data($(this).closest('td'), 'field-name');
  exports.openDeadlineDatepicker({
    context: this,
    epic,
    fieldName,
    callback: value => {
      if (epic[fieldName] === value) {
        return;
      }
      $(`.epic-tr[data-id="${epic.id}"] td[data-field-name="${fieldName}"]`).addClass('is-updating');
      EpicModel.saveChanges(epic, {
        [fieldName]: value
      }, err => {
        if (err) {
          MessageController.error(err, {
            secondary: 'Unable to update epic date.'
          });
        }
        onSuccess();
      });
    }
  });
  return false;
};
exports.openDeadlineDatepicker = function (overrides = {}) {
  const context = overrides.context || $(this);
  const epic = overrides.epic || Utils.getModelFromContext(this);
  const {
    fieldName = 'deadline'
  } = overrides;
  const utcOffset = OrganizationModel.getCurrentUtcOffset();
  let dropdown;
  dropdown = DropdownController.open({
    footer: `<div class="datepicker unselectable"></div>
    <div class="autocomplete-footer-tip" id="datepicker-tip">
      Dates are relative to your Workspace's UTC offset (${utcOffset}).</div>`,
    onClose: () => {
      if (!(dropdown && dropdown.dropdownElement)) {
        return;
      }
      Utils.destroyDatepicker(dropdown.dropdownElement.find('.datepicker'));
    },
    beforeOutsideClick: DropdownController.isClickOnDatepicker,
    target: context,
    targetSelector: '.details-stat-value',
    width: Is.mobile() ? 229 : 410
  });
  if (!dropdown) {
    return false;
  }
  dropdown.dropdownElement.find('.datepicker').datepicker({
    defaultDate: epic[fieldName] ? new Date(epic[fieldName]) : new Date(),
    nextText: 'Next',
    prevText: 'Previous',
    numberOfMonths: Is.mobile() ? 1 : 2,
    showAnim: '',
    onSelect: dateText => {
      let date = null;
      if (moment(dateText).format() !== moment(epic[fieldName]).format()) {
        date = moment(dateText).format();
      }
      if (_.isFunction(overrides.callback)) {
        overrides.callback(date);
      }
      DropdownController.close(dropdown);
      Utils.deferFocus(context);
    }
  });
  DropdownController.bindToClicksOutsideDropdown(dropdown);
  return false;
};
exports.renderEpicTooltip = function (epic) {
  epic = EpicModel.isValid(epic) ? epic : Utils.getModelFromContext(this);
  let html = '';
  if (epic) {
    html = EpicTooltipTemplate.render({
      epic
    });
    exports.fetchEpicAndUpdateTooltip(epic);
  } else {
    html = '<em>Epic was not found.</em>';
  }
  return html;
};
exports.fetchEpicAndUpdateTooltip = epic => {
  EpicModel.fetchLatestVersion(epic, () => {
    const parent = $('.epic-tooltip');
    if (parent.length > 0) {
      const loadedEpic = Utils.getModelFromContext(parent, 'Epic');
      if (loadedEpic.id === epic.id) {
        const html = EpicTooltipTemplate.render({
          epic,
          loaded: true
        });
        View.replaceElement(parent, html);
      }
    }
  });
};

// Template helper
exports.generateProgressBar = (epic, variation = 'unknown') => {
  return View.renderComponentDelayed({
    componentKey: `epic-progressbar-${variation}-${epic.id}`,
    cssClass: 'epic-progress-bar-container',
    props: {
      epicId: epic.id,
      height: variation === 'card' ? 8 : 16
    },
    component: EpicProgressBar
  }).html;
};
export { exports as default };