import { Icon } from '@useshortcut/shapes-ds';
import { ICON_DEFAULT_WIDTH } from '../../utils/icon';
import { DeprecatedIconAdapter } from './DeprecatedIconAdapter';

/**
 * @deprecated Use <Icon icon="Check" /> from @useshortcut/shapes-ds
 */
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const Check = ({
  fill = 'var(--iconGreenColor)',
  width = ICON_DEFAULT_WIDTH,
  className
}) => _jsx(DeprecatedIconAdapter, {
  width: width,
  fill: fill,
  className: className,
  children: _jsx(Icon, {
    icon: "Check"
  })
});
Check.displayName = "Check";