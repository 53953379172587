import { ReportFilterDropdown } from '../../shared/reports/ReportFilterDropdown';
import { getCreatedVsCompletedConfig } from 'data/entity/report';
import { getParamFromUrl, updateParamInUrl } from 'utils/navigation';
import { sendCreatedVsCompletedEvent } from 'utils/segment';
import { jsx as _jsx, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
export const Filters = ({
  onChange
}) => {
  const filters = ['cc_aggregate'].map(key => {
    const {
      defaultValue,
      title,
      values
    } = getCreatedVsCompletedConfig(key);
    return _jsx(ReportFilterDropdown, {
      configId: key,
      value: getParamFromUrl(key) || defaultValue,
      title: title,
      onChange: (configId, selection) => {
        sendCreatedVsCompletedEvent({
          configId,
          selection
        });
        updateParamInUrl(configId, selection);
        onChange();
      },
      items: values
    }, key);
  });
  return _jsx(_Fragment, {
    children: filters
  });
};