import { StoryGroupByTypeFragmentFragmentDoc } from "../../../../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { Nouns } from '@clubhouse/shared/constants';
import { GroupByHeader } from 'components/gql/groupBy/GroupByHeader';
import { storyTypeIconMap, storyTypeNameMap } from 'components/shared/stories/groupBy';
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const StoryGroupByTypeFragment = StoryGroupByTypeFragmentFragmentDoc;
export const StoryTypeGroupHeader = ({
  type
}) => _jsxs(GroupByHeader, {
  entityType: Nouns.StoryType,
  children: [storyTypeIconMap[type], " ", storyTypeNameMap[type] || 'No Type']
});
StoryTypeGroupHeader.displayName = "StoryTypeGroupHeader";