import "core-js/modules/es.array.push.js";
import * as StoryProgressReportTooltipTemplate from '../../views/templates/reports/storyProgressReportTooltip.html';
import { capitalize } from '@clubhouse/shared/utils';
import { getOrgWorkingDays } from 'data/entity/organization';
import Format from '../modules/format';
import ReportModel from '../models/report';
import Url from '../modules/url';
import View from '../modules/view';
import { NON_WORKING_DAYS_LABEL, formatIterationDateRange, getChartDateObject, getInterval, getNumTrailingIntervals, getTrailingAverage, importChartingLibraries, mergeNonWorkingData, processTrailingDataset, renderChartMetrics } from 'utils/charts';
import { isWorkingDay } from 'utils/date';
import { VelocityReportCard } from 'components/reports/velocity/Report';
import moment from 'moment';
const exports = {};

// TODO: @melissakeller Replace with ReportModel.CHARTS.VELOCITY when old velocity chart is removed
const CHART_ID = 'iteration-velocity';
const DATE_FORMAT = ReportModel.DEFAULT_DATE_FORMAT;
const TOGGLE_STATES = {
  Features: true,
  Bugs: true,
  Chores: true,
  TrailingAverage: true
};

// TODO: @melissakeller Change 'stories' to 'count' when old velocity chart is removed
exports.CONFIG_OPTIONS = {
  vc_aggregate: {
    title: 'Sum using: ',
    defaultValue: 'stories',
    values: [{
      name: 'Points',
      value: 'points'
    }, {
      name: 'Story count',
      value: 'stories'
    }]
  },
  vc_group_by: {
    title: 'Group by: ',
    defaultValue: 'day',
    values: [{
      name: 'Day',
      value: 'day'
    }, {
      name: 'Week',
      value: 'week'
    }, {
      name: 'Month',
      value: 'month'
    }, {
      name: 'Iteration',
      value: 'iteration'
    }]
  }
};
exports.renderChartComponent = ({
  chartData,
  errorMsg,
  options,
  onChangeFilter
}) => {
  const mountNode = document.getElementById(`${CHART_ID}-chart`);
  if (mountNode) {
    View.renderComponent({
      mountNode,
      component: VelocityReportCard,
      props: {
        chartId: CHART_ID,
        chartData,
        errorMsg,
        onChangeFilter,
        renderChart: () => exports.renderChart({
          chartData,
          options
        })
      }
    });
  }
};
exports.getChartConfig = key => {
  return Url.getParamFromUrl(key) || exports.CONFIG_OPTIONS[key]?.defaultValue;
};

/**
 * We sometimes fetch extra iterations at the beginning of a range, because we need them to
 * calculate the 4-iteration trailing average. However, we want to be able to ignore these "prelude"
 * iterations when doing certain things, like checking if we actually have any data to chart. So
 * this function filters out those iterations before the startDate of the chart.
 */
exports.getIterationIntervalsInRange = ({
  chartData = [],
  startDate
}) => {
  return chartData.filter(interval => moment(interval.range.start).diff(startDate, 'days') >= 0);
};
exports.getDateRangeWithTrailingDays = ({
  interval,
  dateRange
}) => getChartDateObject(interval, dateRange);
const formatTrailingDataForIterations = trailingData => {
  // We grabbed the last 3 months of iteration data and need to cut the trailing data down to the
  // last 3 iterations. If there are < 3 in the last 3 months, we add placeholder totals.
  if (trailingData.length > 3) {
    return trailingData.slice(-3);
  } else {
    return [...new Array(3 - trailingData.length).fill(0), ...trailingData];
  }
};
const getDataForAggregateType = storyTypeData => {
  const type = exports.getChartConfig('vc_aggregate');
  return storyTypeData[type === 'stories' ? 'count' : type];
};
const groupDataByIntervalStart = ({
  chartData,
  toggleStates
}) => {
  const storyDataByIntervalStartDate = {};
  chartData.forEach(interval => {
    const features = getDataForAggregateType(interval.completed.features);
    const bugs = getDataForAggregateType(interval.completed.bugs);
    const chores = getDataForAggregateType(interval.completed.chores);
    const formattedStartDate = moment(interval.range.start).format(DATE_FORMAT);
    storyDataByIntervalStartDate[formattedStartDate] = storyDataByIntervalStartDate[formattedStartDate] || [];
    storyDataByIntervalStartDate[formattedStartDate].push({
      features,
      bugs,
      chores,
      total: (toggleStates.Features ? features : 0) + (toggleStates.Chores ? chores : 0) + (toggleStates.Bugs ? bugs : 0),
      totalUnestimatedStories: (toggleStates.Features ? interval.completed.features.count_unestimated : 0) + (toggleStates.Chores ? interval.completed.chores.count_unestimated : 0) + (toggleStates.Bugs ? interval.completed.bugs.count_unestimated : 0),
      iterationLabel: ''
    });
  });
  return storyDataByIntervalStartDate;
};
const formatForChart = ({
  chartData,
  options,
  trailingAverageLabel,
  toggleStates
}) => {
  const storyDataByIntervalStartDate = groupDataByIntervalStart({
    chartData,
    toggleStates
  });
  const columns = [['x'], ['Features'], ['Bugs'], ['Chores'], [trailingAverageLabel]];
  const interval = getInterval(options.interval?.id);
  let trailingData = [];
  const x_axis = interval === 'iteration' ? {
    type: 'category',
    categories: []
  } : {
    type: 'timeseries',
    tick: {
      format: '%b %e %Y'
    }
  };

  // Totals for each date in range, not including trailing data
  const totalStoriesOrPoints = [];
  let totalUnestimatedStories = 0;
  const dates = Object.keys(storyDataByIntervalStartDate);
  dates.forEach(date => {
    const momentDate = moment(date);
    // Non-working days are excluded from calculations when using a 'day' interval
    const includeInCalculations = interval !== 'day' || isWorkingDay(date);
    storyDataByIntervalStartDate[date].forEach(dataForDate => {
      if (momentDate.isBefore(options.dateRange.start)) {
        // Not a displayed date, so just add to trailing data
        trailingData.push({
          date: momentDate,
          total: dataForDate.total,
          includeInCalculations
        });
        return;
      }
      if (interval === 'iteration') {
        trailingData = formatTrailingDataForIterations(trailingData);
        x_axis.categories.push(dataForDate.iterationLabel);
      }

      // Add this date to the trailing total
      trailingData.push({
        date: momentDate,
        total: dataForDate.total,
        includeInCalculations
      });
      columns[0].push(date);
      columns[1].push(dataForDate.features);
      columns[2].push(dataForDate.bugs);
      columns[3].push(dataForDate.chores);
      // Since trailingData is scoped to closure above, we can get into calculation errors if we
      //  just pass it as is, hence the array cloning here
      columns[4].push(getTrailingAverage(processTrailingDataset([...trailingData], momentDate)));

      // Update trailing data for next date
      trailingData.shift();

      // Add story total to overall total
      totalStoriesOrPoints.push({
        total: dataForDate.total,
        includeInCalculations
      });
      totalUnestimatedStories += dataForDate.totalUnestimatedStories;
    });
  });
  if (interval === 'iteration') {
    const iterationDateStrings = exports.getIterationIntervalsInRange({
      chartData,
      startDate: options.dateRange.start
    }).map(interval => {
      return formatIterationDateRange(interval.range.start, interval.range.end);
    });
    columns[0] = ['x', ...iterationDateStrings];
    x_axis.categories = iterationDateStrings;
  }
  return {
    columns,
    totalUnestimatedStories,
    average: getTrailingAverage(totalStoriesOrPoints),
    x_axis
  };
};
exports.renderChart = async ({
  chartData,
  options = {},
  toggleStates = {
    ...TOGGLE_STATES
  }
}) => {
  const {
    c3
  } = await importChartingLibraries();
  const type = exports.getChartConfig('vc_aggregate');
  const interval = getInterval(options.interval?.id);
  const x_data = interval !== 'iteration' ? {
    x: 'x',
    xFormat: '%Y-%m-%d'
  } : {};
  const trailingAverageLabel = `${getNumTrailingIntervals(interval) + 1} ${capitalize(interval)} Trailing Average`;
  const formattedData = formatForChart({
    chartData,
    options,
    trailingAverageLabel,
    toggleStates
  });
  const c3Input = {
    bindto: options.element || document.getElementById(CHART_ID),
    data: {
      ...x_data,
      columns: formattedData.columns,
      groups: [['Features', 'Bugs', 'Chores'], [trailingAverageLabel]],
      colors: {
        Features: 'var(--reportFeature)',
        Bugs: 'var(--reportBug)',
        Chores: 'var(--reportChore)',
        [trailingAverageLabel]: 'var(--progressBarCompletedColor)',
        TrailingAverage: 'var(--progressBarCompletedColor)',
        'Total Stories': '#394756',
        'Total Points': '#394756'
      },
      types: {
        Features: 'bar',
        Bugs: 'bar',
        Chores: 'bar',
        [trailingAverageLabel]: 'line'
      }
    },
    grid: {
      focus: {
        show: false
      },
      y: {
        lines: [{
          value: formattedData.average,
          text: formatAverageLabel({
            average: formattedData.average,
            type
          }),
          class: 'average-velocity-line'
        }]
      }
    },
    axis: {
      x: formattedData.x_axis,
      y: {
        label: {
          text: `${capitalize(type)} completed`,
          position: 'outer-middle'
        },
        tick: {
          format: val => {
            return val === Math.floor(val) ? val : '';
          }
        }
      }
    },
    tooltip: {
      contents: (data, defaultTitleFormat, defaultValueFormat, color) => {
        /* For group by iteration we need to discard of the 1st item in data[], which is the unneeded "x" item,
         * it resulted in an "off-by-one" error with the tooltip values & colors displayed.
         */
        const toolTipData = interval === 'iteration' ? data.slice(1) : data;
        return formatTooltipContents({
          data: toolTipData.filter(x => x.id !== NON_WORKING_DAYS_LABEL),
          color,
          chartData,
          interval,
          dateRange: options.dateRange,
          toggleStates,
          trailingAverageLabel
        });
      }
    },
    legend: {
      // Need to hide `x` legend item specifically for group by iteration use case.
      hide: ['x'],
      item: {
        onclick: id => {
          // We handle legend states ourselves because we have to update average data when toggle states change
          velocityChart.toggle(id);
          const toggleStateId = id === trailingAverageLabel ? 'TrailingAverage' : id;
          toggleStates[toggleStateId] = !toggleStates[toggleStateId];
          exports.renderChart({
            chartData,
            options,
            toggleStates
          });
        }
      }
    }
  };
  const workingDays = getOrgWorkingDays();
  const c3InputWithNonWorkingDays = mergeNonWorkingData(workingDays, options.interval.id, c3Input);
  const velocityChart = c3.generate(c3InputWithNonWorkingDays);
  Object.keys(toggleStates).forEach(id => {
    if (!toggleStates[id]) {
      const legendId = id === 'TrailingAverage' ? trailingAverageLabel : id;
      velocityChart.hide([legendId]);
    }
  });
  renderChartMetrics({
    chartId: CHART_ID,
    metrics: {
      'Unestimated Stories': formattedData.totalUnestimatedStories
    }
  });
  return velocityChart;
};
const formatAverageLabel = ({
  average,
  type
}) => {
  const single = type === 'points' ? 'Point' : 'Story';
  const plural = type === 'points' ? 'Points' : 'Stories';
  return 'Average: ' + Format.pluralize(+average.toFixed(2), single, plural);
};
const formatTooltipTitle = ({
  x,
  chartData,
  interval,
  dateRange
}) => {
  if (interval === 'iteration') {
    const iterationIntervals = exports.getIterationIntervalsInRange({
      chartData,
      startDate: dateRange.start
    });
    const interval = iterationIntervals[x];
    return {
      name: interval?.meta?.iteration_name || 'No iteration',
      subtitle: interval ? `${moment(interval.range.start).format('MMMM D')} to ${moment(interval.range.end).format('MMMM D')}` : null
    };
  }
  const longDate = moment(x).format('dddd, MMM D, YYYY');
  if (interval !== 'none' && interval !== 'day') {
    return longDate;
  }
  const workingDays = getOrgWorkingDays();
  if (!workingDays.includes(moment(x).day())) {
    return `${longDate} (No Work)`;
  }
  return longDate;
};
const formatTooltipContents = ({
  data,
  color,
  chartData,
  interval,
  dateRange,
  toggleStates,
  trailingAverageLabel
}) => {
  const type = exports.getChartConfig('vc_aggregate');
  const totalLabel = `Total ${capitalize(type)}`;
  const config = {
    title: formatTooltipTitle({
      x: data[0].x,
      chartData,
      interval,
      dateRange
    }),
    total: {
      name: totalLabel,
      color: 'rgba(0, 0, 0, 0)',
      // We want a fully transparent ("colorless") tooltip icon for the total.
      value: 0
    }
  };
  let dataIndex = 0;
  Object.keys(toggleStates).forEach(id => {
    if (!toggleStates[id]) return;
    if (id !== 'TrailingAverage') config.total.value += data[dataIndex].value;
    config[id] = {
      name: id === 'TrailingAverage' ? trailingAverageLabel : id,
      color: color(data[dataIndex]),
      value: id === 'TrailingAverage' ? (+data[dataIndex].value).toFixed(2) : data[dataIndex].value ?? 0
    };
    dataIndex++;
  });
  return StoryProgressReportTooltipTemplate.render(config);
};
export { exports as default };