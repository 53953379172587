import "core-js/modules/es.array.push.js";
import DOMPurify from 'dompurify';
import * as Emoji from '../emoji';
export const _splitByClosingCodeTag = (str, output) => {
  const closingTag = '</code>';
  str = str.split(closingTag);
  str.forEach((s, i) => {
    output.push({
      type: 'txt',
      str: s
    });
    if (i < str.length - 1) {
      output.push({
        type: 'closingTag',
        str: closingTag
      });
    }
  });
  return output;
};
export const _splitByCodeTag = str => {
  let output = [];
  const original = str;

  // The tag might be <code>, but it could also be <code class="lang-python">.
  const openingTagRegex = /<code[ a-z0-9\=\-\"]*>/i;
  let match;
  let loops = 0;
  const maxLoops = 100;
  while (loops <= maxLoops) {
    match = openingTagRegex.exec(str);
    if (match) {
      _splitByClosingCodeTag(str.substr(0, match.index), output);
      output.push({
        type: 'openingTag',
        str: str.substr(match.index, match[0].length)
      });
    } else {
      _splitByClosingCodeTag(str, output);
      break;
    }
    str = str.substr(match.index + match[0].length);

    // Paranoid guard against infinite loop / slow parsing.
    // String might be a giant copy paste of an HTML page, for example.
    // So we better just not parse the string at all.
    loops += 1;
  }
  if (loops > maxLoops) {
    console.error('Reached 100 loops in splitByCodeTag!', {
      content: original,
      loops
    });
    output = [{
      type: 'txt',
      str: original
    }];
  }
  return output;
};
export const _parseAroundCodeTags = (str, fn, parseInside = false) => {
  const chunks = _splitByCodeTag(str);
  let parsed = '';
  let nestCount = 0;
  chunks.forEach(chunk => {
    if (chunk.type === 'txt') {
      if (parseInside) {
        parsed += nestCount > 0 ? fn(chunk.str) : chunk.str;
      } else {
        parsed += nestCount > 0 ? chunk.str : fn(chunk.str);
      }
    } else if (chunk.type === 'openingTag') {
      parsed += chunk.str;
      nestCount += 1;
    } else if (chunk.type === 'closingTag') {
      parsed += chunk.str;
      nestCount -= 1;
    }
  });
  return parsed;
};
export const _parseOutsideCodeTags = (str, fn) => {
  return _parseAroundCodeTags(str, fn);
};
export const emojify = str => {
  if (!str || typeof str !== 'string') {
    return str;
  }

  // Explicitly making sure shruggy survives by double escaping the \
  str = str.replace('¯_(ツ)_/¯', '¯\\_(ツ)_/¯');
  return _parseOutsideCodeTags(str, Emoji.convert);
};
export const sanitize = str => {
  if (typeof str === 'undefined' || str === null) {
    return '';
  }
  return (str + '').replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/\'/g, '&#39;').replace(/\"/g, '&quot;');
};
export const sanitizeAndEmojify = str => {
  return emojify(sanitize(str));
};
export const purify = DOMPurify.sanitize;
export const capitalize = (str = '') => {
  if (typeof str !== 'string') {
    return str;
  }
  return str?.charAt(0).toUpperCase() + str?.slice(1).toLowerCase();
};