import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.set.difference.v2.js";
import "core-js/modules/esnext.set.intersection.v2.js";
import "core-js/modules/esnext.set.is-disjoint-from.v2.js";
import "core-js/modules/esnext.set.is-subset-of.v2.js";
import "core-js/modules/esnext.set.is-superset-of.v2.js";
import "core-js/modules/esnext.set.symmetric-difference.v2.js";
import "core-js/modules/esnext.set.union.v2.js";
import Constants from 'app/client/core/js/modules/constants'; // eslint-disable-line import/no-restricted-paths
import Emoji from 'app/client/core/js/modules/emoji'; // eslint-disable-line import/no-restricted-paths
import { DEFAULT_SKIN_TONE } from './useSkinTone';
const getEmojiFilter = (query, tone, combineSkinTones) => (emoji, i, emojis) => {
  if (query.length > 0 && !emoji.toLowerCase().includes(query.toLowerCase())) return false;
  if (!combineSkinTones) return true;
  const next = emojis[i + 1];
  const isDefaultTone = next && next.replace('_tone1', '') === emoji;
  if (isDefaultTone) return tone === DEFAULT_SKIN_TONE;else if (emoji.includes('_tone')) return tone === emoji.substr(emoji.length - 5);
  return true;
};
const toComboboxItem = (emoji, position) => {
  if (emoji.includes('_tone')) emoji = Emoji.mapOldToneToNew(emoji);
  return {
    id: emoji,
    value: `:${emoji}:`,
    position: position + 1
  };
};
export const getEmojis = (query, tone, combineSkinTones) => {
  const filter = getEmojiFilter(query, tone, combineSkinTones);
  if (query.trim().length) {
    const uniqueMatches = Object.entries(Constants.EMOJI_GROUPS).reduce((acc, [, group]) => {
      group.filter(filter).forEach(emoji => acc.add(emoji));
      return acc;
    }, new Set());
    if (!uniqueMatches.size) return [];
    return [{
      name: '',
      emojis: [...uniqueMatches].map((emoji, i) => toComboboxItem(emoji, i))
    }];
  }
  return Object.entries(Constants.EMOJI_GROUPS).reduce((acc, [name, group]) => {
    let count = 0;
    acc.push({
      name,
      emojis: group.filter(filter).map(emoji => {
        return toComboboxItem(emoji, count++);
      })
    });
    return acc;
  }, []);
};