import CycleTimeDark from '@clubhouse/assets/png/cycle-time-dark-min.png';
import OldCycleTimeDark from '@clubhouse/assets/png/cycle-time-dark-old-min.png';
import CycleTimeLight from '@clubhouse/assets/png/cycle-time-light-min.png';
import OldCycleTimeLight from '@clubhouse/assets/png/cycle-time-light-old-min.png';
import { THEME_NAMES, useThemeName } from '@clubhouse/shared/utils/theme';
import { PAGE_NAMES, getCurrentPage } from 'utils/navigation';
import { ReportExplanation } from '../../shared/reports/ReportExplanation';
import { ReportsHelpFooter } from '../ReportsHelpFooter';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const Description = ({
  page
}) => _jsxs(_Fragment, {
  children: [_jsx("h2", {
    children: "Overview"
  }), _jsx("p", {
    children: "The Cycle Time report shows how long it takes to complete stories. This provides a foundation for understanding and improving team processes and delivery."
  }), _jsx("p", {
    children: "The more defined your process is for moving Stories to completed, the more accurate your data will be. For cycle time, it's important to only move work to in-progress when it's actually ready (and move it back to unstarted if needed)."
  }), _jsx("h3", {
    children: "Cycle Time Use Cases"
  }), _jsx("p", {
    children: "Cycle Time measures how long it takes for a story to go from any Started state to any Done state."
  }), _jsxs("p", {
    children: ["A Story is considered started when it is ", _jsx("strong", {
      children: "first"
    }), " moved into a started workflow state. A Story is considered complete when it is ", _jsx("strong", {
      children: "last"
    }), " moved into any Done workflow state."]
  }), _jsxs("p", {
    children: [_jsx("strong", {
      children: "Work completion predictions:"
    }), " Cycle times allow you to look at similar projects and provide data-driven predictions on the time it will take to complete them. Lower cycle times mean you can better predict what work you can achieve. Basically, the less time it takes to do work, the better accuracy you have in that prediction."]
  }), _jsxs("p", {
    children: [_jsx("strong", {
      children: "Unstable cycle times:"
    }), " Chaotic or unstable cycle times can mean that team processes are not well established. This creates a good opportunity for discussion. On the other side, a stable cycle time indicates a team is working at a consistent pace, and not encountering too many disruptions."]
  }), _jsx("h3", {
    children: "Lead Time Use Cases"
  }), _jsx("p", {
    children: "Lead Time measures the time between when a story is created and when it is completed."
  }), _jsx("p", {
    children: "Lead Time is particularly useful in bug tracking, showing how long it takes to complete bugs after they have been reported. Reducing this time means reducing the time that users are exposed to bugs."
  }), page === PAGE_NAMES.REPORTS && _jsxs(_Fragment, {
    children: [_jsx("h3", {
      children: "Custom Type Use Cases"
    }), _jsxs("p", {
      children: ["If a workflow other than ", _jsx("strong", {
        children: "All"
      }), " is selected, a new ", _jsx("strong", {
        children: "Custom"
      }), " option will be available in the Chart Type dropdown that allows you to change which states are included in the chart's calculation."]
    }), _jsx("p", {
      children: "Custom Cycle Time is useful if you want to drill down to specific start and end states. For example, you may want to look at how long it takes for stories to go from Ready for Review to Completed if there is a bottleneck occurring here."
    })]
  }), _jsx("h2", {
    children: "Chart Mechanics"
  }), page === PAGE_NAMES.ITERATION && _jsxs(_Fragment, {
    children: [_jsx("strong", {
      children: "Note:"
    }), " Each tick on the x-axis represents the beginning of a day, rather than the end of a day as in Burndown and CFD. Data is not aggregated in the Cycle Time chart \u2014 this helps with viewability of the data as well as in identifying patterns."]
  }), _jsx("h3", {
    children: "Time Scale"
  }), _jsx("p", {
    children: "The chart defaults to a logarithmic scale, which is a method for graphing and analyzing a large range of values in a compact form. This is useful for viewing cycle time data when there are outliers. For an accurate sense of scale, the linear charting method may be chosen."
  }), _jsx("h3", {
    children: "Trailing Average"
  }), _jsx("p", {
    children: "The Trailing Average calculation begins after the first story is completed, and accumulates for up to 7 days."
  })]
});
export const Explanation = ({
  isEmbedded = false
}) => {
  const page = getCurrentPage();
  const isDarkMode = useThemeName() === THEME_NAMES.DARK;
  const NewCycleTimeImg = isDarkMode ? CycleTimeDark : CycleTimeLight;
  const OldCycleTimeImg = isDarkMode ? OldCycleTimeDark : OldCycleTimeLight;
  return _jsx(ReportExplanation, {
    isEmbedded: isEmbedded,
    title: `${!isEmbedded ? 'About the ' : ''} Cycle Time Chart`,
    imgSrc: page === PAGE_NAMES.ITERATION ? NewCycleTimeImg : OldCycleTimeImg,
    description: _jsx(Description, {
      page: page
    }),
    footer: _jsx(ReportsHelpFooter, {})
  });
};
Explanation.displayName = "Explanation";