import { CustomFieldsSelect } from '@clubhouse/shared/components/CustomFieldsSelect';
import { useCustomFields } from 'data/entity/customField';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const CustomFieldsFilter = ({
  selectedValues = [],
  onChange
}) => {
  const {
    fields
  } = useCustomFields({
    filter: ({
      enabled
    }) => enabled
  });
  return _jsx(CustomFieldsSelect, {
    selectedValues,
    onChange,
    fields
  });
};
CustomFieldsFilter.displayName = "CustomFieldsFilter";