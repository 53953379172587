import { useEffect, useRef } from 'react';
import { createScrollSync } from '../utils/scrollSync';
export const useScrollSyncLegacy = ({
  leaderNodeRef,
  scrollerRefs
}) => {
  const scrollSyncRef = useRef(createScrollSync());
  useEffect(() => {
    const {
      current: leaderNode
    } = leaderNodeRef;
    const followerNodes = scrollerRefs.map(r => r.current).filter(node => !!node);
    if (!leaderNode || !followerNodes.length) return;
    scrollSyncRef.current.initScrollSync({
      leaderNode,
      followerNodes
    });
    return () => {
      scrollSyncRef.current.destroyScrollSync();
    };
  }, [leaderNodeRef, scrollerRefs]);
};