import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import { ApolloLink, from } from '@apollo/client';
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { persistCache } from 'apollo3-cache-persist';
import localforage from 'localforage';
import { possibleTypes } from '@clubhouse/datalayer/possibleTypes';
import { getGraphQLServerUrl } from 'utils/backend'; // eslint-disable-line import/no-restricted-paths
import { getCurrentSlug } from 'utils/navigation';
import { LastFocusedTabOnlyStorage } from './LastFocusedTabOnlyStorage';
import { CACHE_PREFIX } from './cacheUtils';
import { getTypePolicies } from './getTypePolicies';
export const getUri = operation => {
  const uri = new URL('api/private/datalayer-graphql', `${getGraphQLServerUrl()}/`); // Trailing slash is important here to keep the URL relative.

  if (operation?.operationName) {
    uri.searchParams.append('op', operation.operationName);
  }
  return uri.toString();
};
const configureIndexedDbCache = async ({
  cache
}) => {
  const name = `${CACHE_PREFIX}-${getCurrentSlug()}-v5`;
  localforage.config({
    driver: [localforage.INDEXEDDB, localforage.LOCALSTORAGE],
    name
  });
  await persistCache({
    cache,
    storage: new LastFocusedTabOnlyStorage(localforage),
    serialize: true,
    maxSize: 1024 * 1024 * 10 // 10 MB, which is the parsed size of the current cache, not compressed size.
  });
};

// Apollo does not forward "extensions" when the response is not an error.
// See https://github.com/apollographql/apollo-feature-requests/issues/117
const forwardExtensionsLink = new ApolloLink((operation, forward) => {
  return forward(operation).map(result => {
    if (result.data && result.extensions) result.data.extensions = result.extensions;
    return result;
  });
});
const APP_ENV = process.env.ENV_NAME ?? null;
const APP_DEPLOY_ID = process.env.DEPLOY_ID ?? 'UNKNOWN';
export const createLink = ({
  workspace2Id
} = {}) => {
  const searchParams = new URLSearchParams(window.location.search);
  const headers = {
    'Shortcut-App-Build-Id': APP_ENV === 'debug' ? 'localhost' : APP_DEPLOY_ID,
    ...(searchParams.has('lacinia-tracing') ? {
      'lacinia-tracing': '1'
    } : {}),
    ...(searchParams.has('enable-tracing') ? {
      'shortcut-enable-tracing': '1'
    } : {}),
    ...(workspace2Id ? {
      'Tenant-Workspace2': workspace2Id
    } : {})
  };
  const linkConfig = {
    headers,
    uri: getUri,
    credentials: 'include'
  };
  const httpLink = createHttpLink(linkConfig);
  return from([forwardExtensionsLink, httpLink]);
};
export const createCache = (options = {}) => {
  return new InMemoryCache({
    possibleTypes,
    typePolicies: getTypePolicies(options)
  });
};

// TODO: Replace `initApollo` with this once the app supports a single ApolloWrapper.
const _initApollo = async (link, options) => {
  const cache = createCache(options);
  await configureIndexedDbCache({
    cache
  });
  return new ApolloClient({
    link,
    cache,
    connectToDevTools: process.env.APOLLO_DEV_TOOLS === 'DATA_LAYER',
    defaultOptions: {
      watchQuery: {
        // Globally disable polling when the tab is inactive.
        skipPollAttempt: () => document.hidden
      }
    }
  });
};
let clientSingletonPromise = null;
export const clearClientCache = () => {
  clientSingletonPromise = null;
};
export const getApolloClient = () => {
  return clientSingletonPromise;
};
export const initApollo = (link, options) => {
  if (clientSingletonPromise) {
    return clientSingletonPromise;
  }
  clientSingletonPromise = _initApollo(link, options);
  return clientSingletonPromise;
};