import { DEFAULT_MAX_FILTER_SELECTIONS } from 'components/gql/filters/GroupedOptionsFilter';
import { useTeamScopeId } from 'components/team-navigation';
import { useSearchParam } from 'utils/navigation';
import { useWorkspaceProjectsFeature } from '../../hooks/useWorkspaceProjectsFeature';
import { EpicsPageFilterConfig } from './filterConfig';
const MAX_FILTER_VALUES = DEFAULT_MAX_FILTER_SELECTIONS;
const enforceFilterLimits = filters => Object.fromEntries(Object.entries(filters).map(([key, value]) => [key, value.slice(0, MAX_FILTER_VALUES)]));
export const useFilters = () => {
  const {
    value: teamId
  } = useTeamScopeId();
  const query = useSearchParam(EpicsPageFilterConfig.query).param;
  const showArchived = useSearchParam(EpicsPageFilterConfig.showArchived).param;
  const showAssociated = useSearchParam(EpicsPageFilterConfig.showAssociated).param;
  const areProjectsEnabled = useWorkspaceProjectsFeature();
  const projectIds = useSearchParam(EpicsPageFilterConfig.projectIds).param;
  const ownerIds = useSearchParam(EpicsPageFilterConfig.ownerIds).param;
  const milestoneIds = useSearchParam(EpicsPageFilterConfig.milestoneIds).param;
  const objectiveIds = useSearchParam(EpicsPageFilterConfig.objectiveIds).param;
  const groupIds = useSearchParam(EpicsPageFilterConfig.groupIds).param;
  const stateIds = useSearchParam(EpicsPageFilterConfig.stateIds).param;
  const labelIds = useSearchParam(EpicsPageFilterConfig.labelIds).param;
  const filtersWithLimits = enforceFilterLimits({
    ...(areProjectsEnabled ? {
      projectIds
    } : {
      projectIds: []
    }),
    ownerIds,
    milestoneIds,
    objectiveIds,
    groupIds: teamId ? [teamId] : groupIds,
    stateIds,
    labelIds
  });
  return {
    query,
    showArchived,
    showAssociated,
    ...filtersWithLimits
  };
};