import { PALETTE_TINT, getTint } from '@clubhouse/shared/color';
import BaseUtils from '../_frontloader/baseUtils';
import Collection from '../_frontloader/collection';
import Backend from '../modules/backend';
const exports = {};
Collection.create('Group', exports);
exports.getAllSortedByName = () => {
  return exports.sortBy('name');
};
exports.isArchived = group => {
  return group.archived;
};
exports.allNotArchived = () => {
  return exports.filter({
    archived: false
  });
};
exports.fetchAll = callback => {
  Backend.get('/api/private/groups', {
    onComplete: res => {
      exports.defaultFetchAllHandler(res, callback);
    }
  });
};
const create = (data, callback) => {
  const {
    display_icon,
    ...rest
  } = data;
  if (display_icon) {
    data = {
      ...rest,
      display_icon_id: display_icon.id
    };
  }
  Backend.post('/api/private/groups', {
    data,
    actionContext: `team updated:new team modal`,
    onComplete: res => {
      exports.defaultGetHandler(res, callback);
    }
  });
};
const update = (group, callback) => {
  const {
    id,
    ...rest
  } = group;
  Backend.put(`/api/private/groups/${id}`, {
    data: rest,
    onComplete: res => {
      exports.defaultGetHandler(res, callback);
    }
  });
};
const updateGroupWorkflows = (group, {
  teamUpdated
}, callback) => {
  const {
    id,
    ...rest
  } = group;
  Backend.put(`/api/private/groups/${id}`, {
    data: rest,
    onComplete: res => {
      exports.defaultGetHandler(res, callback);
    },
    actionContext: `team updated:${teamUpdated}`
  });
};
exports.isValidWorkflowForGroup = (group_id, workflow_id) => {
  const group = exports.getById(group_id);
  return Boolean(group?.workflow_ids.includes(workflow_id));
};
exports.getGroupColor = team => {
  return getTint(exports.getGroupTint(team));
};
exports.getGroupTint = team => {
  if (team.color_key) return team.color_key;
  if (team.color) return team.color;
  if (team.colorKey) return team.colorKey;

  // Not all teams will have a color assigned, and we want a stable random color. So we just use the
  // ID as a "random" number and use it to index the color palette
  const id = String(team.publicId || team.id);
  const derivedIndex = id.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0);
  const color = PALETTE_TINT[derivedIndex % PALETTE_TINT.length];
  return color;
};
exports.getRequiredWorkflows = (groupId, callback) => Backend.get(`/api/private/groups/${groupId}/required-workflows`, {
  onSuccess: res => callback(undefined, res),
  onError: body => callback(body)
});
exports.Promises = {
  create: BaseUtils.promisify(create, {
    passErrorContext: true
  }),
  fetchAll: BaseUtils.promisify(exports.fetchAll),
  update: BaseUtils.promisify(update),
  updateGroupWorkflows: BaseUtils.promisify(updateGroupWorkflows),
  getRequiredWorkflows: BaseUtils.promisify(exports.getRequiredWorkflows)
};
export { exports as default };