import { getLocation } from 'components/shared/utils/windowAdapter';
import { getCurrentOrigin, getPageFromCurrentPathname } from './navigation';

/**
 * Returns API Server path to look up the Single Sign-On URL for the
 * current Workspace, with redirect to workspace after sign-on.
 */
export function getSsoLookupUrlForSpaceWithRedirect(spaceSlug) {
  const origin = getCurrentOrigin();
  const currentPage = getPageFromCurrentPathname();
  const relayState = ['invites', 'invite-page'].some(page => page === currentPage) ? `${origin}/${spaceSlug}` : getLocation().href;
  return `/login/saml-signon-url/${spaceSlug}?RelayState=${encodeURIComponent(relayState)}`;
}