import { Emojify } from '@clubhouse/shared/components/Emojify';
import { MoreActions } from '@clubhouse/shared/components/MoreActions';
import { ToastText, addToast } from '@clubhouse/shared/components/Toast';
import { deleteStory } from 'data/entity/story';
import { useCallback } from 'react';
import { closeCurrentStoryDialog } from 'utils/storyDialog';
import { useStoryConfirmationDialog } from '../hooks/useStoryConfirmationDialog';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const DeleteStoryAction = ({
  storyId,
  name
}) => {
  const {
    openDialog,
    closeDialog
  } = useStoryConfirmationDialog();
  const onDelete = useCallback(() => {
    openDialog('delete', {
      onClick: async () => {
        try {
          closeDialog();
          await deleteStory(storyId);
          closeCurrentStoryDialog();
          addToast({
            kind: 'success',
            timeout: 5000,
            Content: () => _jsxs(ToastText, {
              children: ["Story", ' ', _jsx("strong", {
                children: _jsx(Emojify, {
                  children: name
                })
              }), ' ', "deleted."]
            })
          });
        } catch {
          addToast({
            kind: 'alert',
            Content: () => _jsxs(ToastText, {
              children: ["We were unable to delete", ' ', _jsx("strong", {
                children: _jsx(Emojify, {
                  children: name
                })
              })]
            })
          });
        }
      }
    });
  }, [name, storyId, closeDialog, openDialog]);
  return _jsx(MoreActions.Item, {
    icon: "Trash",
    aside: "shift D",
    onClick: onDelete,
    children: "Delete Story"
  });
};
DeleteStoryAction.displayName = "DeleteStoryAction";