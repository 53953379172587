import { Icon } from '@useshortcut/shapes-ds';
import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons';
import { Tooltip } from '@clubhouse/shared/components/Tooltip';
import { UnstyledButton } from '@clubhouse/shared/components/UnstyledButton';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const ReportsRefreshButton = ({
  handleReportsRefresh
}) => {
  return _jsx(Tooltip, {
    content: "Sync chart with latest data",
    children: _jsx(UnstyledButton, {
      onClick: handleReportsRefresh,
      children: _jsx(DeprecatedIconAdapter, {
        width: 16,
        children: _jsx(Icon, {
          icon: "Sync"
        })
      })
    })
  });
};
ReportsRefreshButton.displayName = "ReportsRefreshButton";