import "core-js/modules/esnext.set.difference.v2.js";
import "core-js/modules/esnext.set.intersection.v2.js";
import "core-js/modules/esnext.set.is-disjoint-from.v2.js";
import "core-js/modules/esnext.set.is-subset-of.v2.js";
import "core-js/modules/esnext.set.is-superset-of.v2.js";
import "core-js/modules/esnext.set.symmetric-difference.v2.js";
import "core-js/modules/esnext.set.union.v2.js";
import groupBy from 'lodash/groupBy';
import moment from 'moment';
import { useMemo } from 'react';
import { WORKFLOW_STATE_TYPES } from '@clubhouse/shared/constants';
import { IterationStatus } from '@clubhouse/shared/types';
import IterationModel from '../../app/client/core/js/models/iteration';
import { useEntities, useEntity, useEntityWithFetch, useOptimizedEntity } from './collection';
import { getById as getGroupById, groupHasManyGroupEntityByGroupIds } from './group';
import { getProfilesByIds } from './profile';
export const getById = id => IterationModel.getById(id);
export const getAll = () => IterationModel.all();
export const getAllByStatus = status => IterationModel.getAllByStatus(status);
export const generateURL = iteration => IterationModel.generateURL(iteration);
export const getNumStories = iteration => (iteration?.stats?.num_stories_backlog || 0) + (iteration?.stats?.num_stories_unstarted || 0) + (iteration?.stats?.num_stories_started || 0) + (iteration?.stats?.num_stories_done || 0);
export const getNumPoints = iteration => (iteration?.stats?.num_points_backlog || 0) + (iteration?.stats?.num_points_unstarted || 0) + (iteration?.stats?.num_points_started || 0) + (iteration?.stats?.num_points_done || 0);
export const addStoryDataToIterations = () => IterationModel.addStoryDataToIterations();
export const fetchStoriesForIterations = iterationIds => IterationModel.Promises.fetchStoriesForIterations({
  iteration_ids: iterationIds
});
export const getIterationsByGroupAndState = ({
  iterations,
  groupIds
}) => {
  const obj = groupHasManyGroupEntityByGroupIds({
    set: new Set(groupIds),
    entities: iterations
  });
  return Object.entries(obj).reduce((acc, [key, iterations]) => {
    return {
      ...acc,
      [key]: groupByStatus({
        iterations
      })
    };
  }, {});
};
export const getCurrentIterationForGroup = groupId => {
  const today = moment().format('YYYY-MM-DD');
  const possibleIterations = getAllByStatus(IterationStatus.STARTED).filter(it => {
    if (!it.group_ids.includes(groupId)) return false;
    return today >= it.start_date && today <= it.end_date;
  });
  if (possibleIterations.length === 1) return possibleIterations[0];else if (possibleIterations.length > 1) return possibleIterations.sort((a, b) => a.start_date.localeCompare(b.start_date))[0];
  return null;
};
export const getNextIterationForGroup = groupId => {
  const today = moment().format('YYYY-MM-DD');
  const possibleIterations = getAllByStatus(IterationStatus.UNSTARTED).filter(it => {
    if (!it.group_ids.includes(groupId)) return false;
    return today <= it.start_date;
  });
  if (possibleIterations.length === 1) return possibleIterations[0];else if (possibleIterations.length > 1) return possibleIterations.sort((a, b) => a.start_date.localeCompare(b.start_date))[0];
  return null;
};
export const groupByStatus = ({
  iterations
}) => {
  iterations = [...iterations].sort((a, b) => b.start_date.localeCompare(a.start_date));
  const orderedStatuses = [IterationStatus.STARTED, IterationStatus.UNSTARTED, IterationStatus.DONE];
  const groupedByStatus = groupBy(iterations, 'status');
  return orderedStatuses.map(status => ({
    status,
    iterations: groupedByStatus[status] || []
  }));
};
export const statusToLabel = {
  [IterationStatus.UNSTARTED]: 'Unstarted',
  [IterationStatus.STARTED]: 'Started',
  [IterationStatus.DONE]: 'Done'
};
export const getLabelForStatus = status => {
  return statusToLabel[status];
};
export const useIterations = () => {
  const {
    entities
  } = useEntities({
    model: IterationModel
  });
  return {
    iterations: entities
  };
};
export const useIteration = ({
  id
}) => {
  const {
    entity
  } = useEntity({
    model: IterationModel,
    id
  });
  return entity;
};
export const useIterationWithFetch = ({
  id
}) => {
  const {
    entity,
    loading
  } = useEntityWithFetch({
    id,
    model: IterationModel,
    fetchById: fetchIteration
  });
  return {
    iteration: entity,
    loading
  };
};
export const fetchIteration = id => IterationModel.fetch({
  id
});
export const useIterationWithGroups = ({
  id
}) => {
  const iteration = useIteration({
    id
  });
  return useMemo(() => {
    const groupId = iteration?.group_ids.length > 0 ? iteration.group_ids[0] : '';
    const team = getGroupById(groupId);
    const users = getProfilesByIds(team?.member_ids ?? []);
    return {
      ...iteration,
      ...(team && {
        team
      }),
      ...(users && {
        users
      })
    };
  }, [iteration]);
};
export const useOptimizedIteration = id => {
  const entity = useOptimizedEntity(id, {
    model: IterationModel,
    hasChanges: (oldIteration, newIteration) => oldIteration?.updated_at !== newIteration?.updated_at
  });
  return entity;
};
export const deleteIteration = id => IterationModel.Promises.deleteIteration({
  id
});
export const saveChanges = (id, changes) => IterationModel.Promises.saveChanges({
  id
}, changes);
export const create = iteration => IterationModel.Promises.saveNew(iteration);
export const getIterationProgressStats = iteration => {
  const storyStats = {
    [WORKFLOW_STATE_TYPES.BACKLOG]: iteration.stats.num_stories_backlog || 0,
    [WORKFLOW_STATE_TYPES.UNSTARTED]: iteration.stats.num_stories_unstarted || 0,
    [WORKFLOW_STATE_TYPES.STARTED]: iteration.stats.num_stories_started || 0,
    [WORKFLOW_STATE_TYPES.DONE]: iteration.stats.num_stories_done || 0
  };
  const pointStats = {
    [WORKFLOW_STATE_TYPES.BACKLOG]: iteration.stats.num_points_backlog || 0,
    [WORKFLOW_STATE_TYPES.UNSTARTED]: iteration.stats.num_points_unstarted || 0,
    [WORKFLOW_STATE_TYPES.STARTED]: iteration.stats.num_points_started || 0,
    [WORKFLOW_STATE_TYPES.DONE]: iteration.stats.num_points_done || 0
  };
  return {
    pointStats,
    storyStats
  };
};