import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons';
import { Icon, getTokens } from '@useshortcut/shapes-ds';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const getOptionalConfigForGroupKey = {
  storyIteration: null,
  storyTeamDone: null,
  storyTeamStarted: null,
  storyTeamUnstarted: null,
  unstarted: () => ({
    sectionId: 'unstarted',
    sectionTitle: 'Unstarted',
    allowBulkSelect: false
  }),
  started: () => ({
    sectionId: 'started',
    sectionTitle: 'Started',
    allowBulkSelect: false
  }),
  done: () => ({
    sectionId: 'done',
    sectionTitle: 'Done',
    allowBulkSelect: false
  })
};
export const getSection = (groupKey, iterationSelectOptions) => {
  return {
    ...getOptionalConfigForGroupKey[groupKey]?.(),
    items: iterationSelectOptions.map(({
      node
    }) => {
      const {
        id,
        name,
        state
      } = node;
      return {
        value: id,
        name,
        Icon: () => {
          let fill = getTokens().color.raw.gray80;
          if (state === 'started') {
            fill = getTokens().color.raw.blue80;
          } else if (state === 'done') {
            fill = getTokens().color.raw.green80;
          }
          return _jsx(DeprecatedIconAdapter, {
            width: 16,
            fill: fill,
            children: _jsx(Icon, {
              icon: "Iteration"
            })
          });
        }
      };
    })
  };
};