import { useCallback } from 'react';
import { Combobox } from '@clubhouse/shared/components/Combobox';
import { useToggleState } from '@clubhouse/shared/hooks';
import noop from 'lodash/noop';
import { ComboboxSelectProvider } from './ComboboxSelectContext';
import { Footer, Menu, Option, Target, Trigger } from './components';
import { LabelItem } from './components/Labeltem';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const ComboboxSelect = ({
  children,
  multiSelect = false,
  onChange,
  initialIsOpen = false,
  onClose = noop
}) => {
  const [isOpen, toggle] = useToggleState(initialIsOpen);
  const handleClose = () => {
    onClose();
    toggle.off();
  };
  return _jsx(ComboboxSelectProvider, {
    isOpen: isOpen,
    toggleIsOpen: toggle.toggleOrSet,
    multiSelect: multiSelect,
    children: _jsx(Combobox, {
      onSelect: useCallback(update => {
        onChange(update);
        if (!multiSelect) toggle.off();
      }, [multiSelect, onChange, toggle]),
      onClose: handleClose,
      children: children
    })
  });
};
ComboboxSelect.displayName = "ComboboxSelect";
ComboboxSelect.Option = Option;
ComboboxSelect.Menu = Menu;
ComboboxSelect.List = Combobox.List;
ComboboxSelect.Trigger = Trigger;
ComboboxSelect.ListLoading = Combobox.ListLoading;
ComboboxSelect.LabelItem = LabelItem;
ComboboxSelect.Divider = Combobox.Divider;
ComboboxSelect.Input = Combobox.Input;
ComboboxSelect.ContentContainer = Combobox.ContentContainer;
ComboboxSelect.Target = Target;
ComboboxSelect.Footer = Footer;