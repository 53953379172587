import IntegrationModel from 'app/client/core/js/models/integration';
import { useEntities } from './collection';
export const fetchAll = () => new Promise((resolve, reject) => IntegrationModel.fetchAll((err, res) => err ? reject(err) : resolve(res)));
export const fetchSlackIntegrationProjects = () => new Promise(resolve => IntegrationModel.fetchSlackIntegrationProjects(resolve));
export const fetchSlackIntegrationTeams = () => new Promise(resolve => IntegrationModel.fetchSlackIntegrationTeams(resolve));
export const fetchSlackIntegrationFields = () => new Promise(resolve => IntegrationModel.fetchSlackIntegrationFields(resolve));
export const deleteTeamIntegration = integration => new Promise((resolve, reject) => {
  IntegrationModel.deleteTeamIntegration(integration, err => {
    if (err) reject(err);else resolve();
  });
});
export const deleteProjectIntegration = integration => new Promise((resolve, reject) => {
  IntegrationModel.deleteProjectIntegration(integration, err => {
    if (err) reject(err);else resolve();
  });
});
export const deleteFieldIntegration = integration => new Promise((resolve, reject) => {
  IntegrationModel.deleteFieldIntegration(integration, err => {
    if (err) reject(err);else resolve();
  });
});
export const useIntegrations = () => useEntities({
  model: IntegrationModel
}).entities || [];
export const getIntegration = type => IntegrationModel.find(i => i.type === type) ?? null;
export const useIntegration = type => useIntegrations().find(i => i.type === type) ?? null;
export const useGenericIntegrations = () => useIntegrations().filter(i => i.type === 'generic');
export const enableIntegration = integration => new Promise((resolve, reject) => {
  IntegrationModel.enableIntegration(integration, (err, res) => err ? reject(err) : resolve(res));
});
export const disableIntegration = integration => new Promise((resolve, reject) => {
  IntegrationModel.disableIntegration(integration, (err, res) => err ? reject(err) : resolve(res));
});
export const deleteIntegration = integrationId => new Promise((resolve, reject) => {
  IntegrationModel.deleteIntegration(integrationId, (err, res) => err ? reject(err) : resolve(res));
});
export const createGenericIntegration = integration => new Promise((resolve, reject) => {
  IntegrationModel.createNew({
    ...integration,
    type: 'generic'
  }, (err, res) => err ? reject(err) : resolve(res));
});
export const getSlackRedirectUrl = () => IntegrationModel.getSlackRedirectUrl();
export const updateIntegration = ({
  id,
  ...changes
}) => new Promise((resolve, reject) => {
  IntegrationModel.updateIntegration({
    id
  }, changes, (err, res) => err ? reject(err) : resolve(res));
});