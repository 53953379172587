import { EVENTS, logEvent } from 'utils/monitoring';
export const logAddItem = ({
  teamId,
  totalTeams
}) => logEvent(EVENTS.Interaction_TeamNavigation_AddTeam, {
  teamId,
  totalTeams
});
export const logRemoveItem = ({
  teamId,
  totalTeams
}) => logEvent(EVENTS.Interaction_TeamNavigation_RemoveTeam, {
  teamId,
  totalTeams
});