import { FIELD_ID } from 'components/shared/table/types';
import { name } from 'utils/sort';
export const NameField = (overrides = {}) => ({
  name: FIELD_ID.NAME,
  displayName: 'Name',
  sort: name,
  isHeader: true,
  ...overrides,
  headerProps: {
    ...overrides?.headerProps
  }
});