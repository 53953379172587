import { Icon } from '@useshortcut/shapes-ds';
import { ICON_DEFAULT_WIDTH } from '../../utils/icon';
import { DeprecatedIconAdapter } from './DeprecatedIconAdapter';

/**
 * @deprecated Use <Icon icon="Iteration" /> from @useshortcut/shapes-ds
 */
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const IterationDone = ({
  fill = '#0CB350',
  width = ICON_DEFAULT_WIDTH
}) => _jsx(DeprecatedIconAdapter, {
  "data-testid": "iteration-done-icon",
  width: width,
  fill: fill,
  children: _jsx(Icon, {
    icon: "Iteration"
  })
});
IterationDone.displayName = "IterationDone";