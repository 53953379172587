import { useMemo } from 'react';
import { GroupedOptionsSelect, useFetchGroupedOptions } from '@clubhouse/shared/components/GroupedOptionsSelect';
import { Divider, Nothing } from '@clubhouse/shared/components/Select';
import { logError } from 'utils/monitoring';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const staticItems = [Nothing(), Divider('after-none')];
export const GroupedOptionsField = ({
  selectedEntities,
  required,
  getSection,
  fetchOptions,
  ...props
}) => {
  const {
    unit,
    maxSelected
  } = props;
  const selectedValues = useMemo(() => selectedEntities.map(({
    id
  }) => id), [selectedEntities]);
  const targetComponentProps = useMemo(() => ({
    selectedEntities: selectedEntities ?? []
  }), [selectedEntities]);
  const {
    fetchSections,
    items,
    totalItemCount
  } = useFetchGroupedOptions({
    fetchOptions,
    selectedValues,
    getSection,
    staticItems: required ? undefined : staticItems,
    onError: logError,
    maxSelected
  });
  return _jsx(GroupedOptionsSelect, {
    fetchSections: fetchSections,
    items: items,
    totalItemCount: totalItemCount,
    selectedValues: selectedValues,
    label: `Update ${maxSelected === 1 ? unit.singular : unit.plural}`,
    targetComponentProps: targetComponentProps,
    ...props
  });
};
GroupedOptionsField.displayName = "GroupedOptionsField";