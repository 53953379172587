import { groupBy } from 'lodash';
import { DEFAULT_MAX_FILTER_SELECTIONS } from 'components/gql/filters/GroupedOptionsFilter';
import { createSearchParamConfigs } from 'components/gql/utils/createSearchParamConfigs';
import { useTeamScopeId } from 'components/team-navigation';
import { useSearchParam } from 'utils/navigation';
export const BacklogPageFilterConfig = createSearchParamConfigs({
  query: {
    paramName: 'query',
    paramType: 'string',
    defaultValue: ''
  },
  storyType: {
    paramName: 'story_type',
    paramType: 'string',
    defaultValue: ''
  },
  ownerIds: {
    paramName: 'owner_ids',
    paramType: 'string[]'
  },
  requesterIds: {
    paramName: 'requester_ids',
    paramType: 'string[]'
  },
  epicIds: {
    paramName: 'epic_ids',
    paramType: 'string[]'
  },
  labelIds: {
    paramName: 'label_ids',
    paramType: 'string[]'
  },
  groupIds: {
    paramName: 'group_ids',
    paramType: 'string[]'
  },
  priorities: {
    paramName: 'priority',
    paramType: 'string[]'
  },
  skillSetIds: {
    paramName: 'skill_sets',
    paramType: 'string[]'
  },
  technicalAreaIds: {
    paramName: 'technical_areas',
    paramType: 'string[]'
  },
  productAreaIds: {
    paramName: 'product_areas',
    paramType: 'string[]'
  },
  severityIds: {
    paramName: 'severities',
    paramType: 'string[]'
  },
  customFieldIds: {
    paramName: 'custom_fields',
    paramType: 'string[]'
  }
});
const MAX_FILTER_VALUES = DEFAULT_MAX_FILTER_SELECTIONS;
const enforceFilterLimits = filters => Object.fromEntries(Object.entries(filters).map(([key, value]) => [key, value.slice(0, MAX_FILTER_VALUES)]));
export const useFilters = () => {
  const {
    value: teamId
  } = useTeamScopeId();
  const query = useSearchParam(BacklogPageFilterConfig.query).param;
  const storyType = useSearchParam(BacklogPageFilterConfig.storyType).param;
  const ownerIds = useSearchParam(BacklogPageFilterConfig.ownerIds).param;
  const requesterIds = useSearchParam(BacklogPageFilterConfig.requesterIds).param;
  const labelIds = useSearchParam(BacklogPageFilterConfig.labelIds).param;
  const epicIds = useSearchParam(BacklogPageFilterConfig.epicIds).param;
  const groupIds = useSearchParam(BacklogPageFilterConfig.groupIds).param;
  const priorities = useSearchParam(BacklogPageFilterConfig.priorities).param;
  const skillSetIds = useSearchParam(BacklogPageFilterConfig.skillSetIds).param;
  const severityIds = useSearchParam(BacklogPageFilterConfig.severityIds).param;
  const technicalAreaIds = useSearchParam(BacklogPageFilterConfig.technicalAreaIds).param;
  const productAreaIds = useSearchParam(BacklogPageFilterConfig.productAreaIds).param;
  const customFieldIds = useSearchParam(BacklogPageFilterConfig.customFieldIds).param;
  const customFields = Object.entries(groupBy(customFieldIds, idAndVal => idAndVal.split('@')[0])).map(([field, values]) => ({
    field,
    values: values.map(vals => vals.split('@')[1])
  }));
  return {
    query,
    storyType,
    ...enforceFilterLimits({
      ownerIds,
      requesterIds,
      epicIds,
      labelIds,
      groupIds: teamId ? [teamId] : groupIds,
      priorities,
      skillSetIds,
      severityIds,
      technicalAreaIds,
      productAreaIds,
      customFields
    })
  };
};