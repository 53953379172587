import "core-js/modules/es.array.push.js";
import TabsController from 'app/client/core/js/controllers/tabs.js';
window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || {
    Controller: {},
    Model: {}
  };
  const TabsController = exports;
  [[['Controller', 'Tabs'], TabsController], [['Controller', 'Tabs'], TabsController]].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});
import * as Event from '../_frontloader/event';
import Utils from '../modules/utils';
const exports = {};
exports.tab = function () {
  const me = $(this);
  if (me.hasClass('active')) {
    return false;
  }
  const new_id = Utils.data(this, 'tab');
  const container = me.closest('[data-controller="Tabs"]');
  const activeTab = container.find('.active');
  const old_id = Utils.data(activeTab, 'tab');
  activeTab.removeClass('active');
  me.addClass('active');
  $('#' + old_id).hide();
  $('#' + new_id).show();
  Utils.fireEventHandler(me, 'on-tab-focus');
  // Hook into tab changes with the ID of the tab node and `tab-focus` namespace
  Event.trigger(`${this.id || new_id}.tab-focus`);
  Event.trigger('resize');
  return false;
};
export { exports as default };