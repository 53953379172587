import { convertToGQL } from './utils';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const LABEL_BY_STATE = {
  toDo: 'To Do',
  inProgress: 'In Progress',
  done: 'Done'
};
export const ObjectiveStateDisplay = ({
  state
}) => {
  const objectiveState = convertToGQL(state);
  return _jsx("span", {
    children: LABEL_BY_STATE[objectiveState]
  });
};
ObjectiveStateDisplay.displayName = "ObjectiveStateDisplay";