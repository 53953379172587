import { v4 as uuidv4 } from 'uuid';
import View from '../modules/view';
import { TeamSelectionPanel } from 'pages/workflows/components/TeamSelectionPanel';
const exports = {};
exports.render = ({
  key = uuidv4(),
  onChange,
  groups
}) => {
  return View.renderComponentDelayed({
    componentKey: key,
    component: TeamSelectionPanel,
    props: {
      onChange,
      groups
    }
  }).html;
};
export { exports as default };