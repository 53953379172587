import { useTeamScopeId } from 'components/team-navigation';
import { Fragment as _Fragment, jsx as _jsx } from "@emotion/react/jsx-runtime";
export const IfNoTeamScope = ({
  children
}) => {
  const {
    value
  } = useTeamScopeId();
  return !value ? _jsx(_Fragment, {
    children: children
  }) : null;
};