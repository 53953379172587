import * as AuthorizeApplicationTemplate from 'app/client/oauth/views/templates/authorizeApplication.html';
import * as NoApplicationFoundTemplate from 'app/client/oauth/views/templates/noApplicationFound.html';
import * as AuthOrgSelectorTemplate from 'app/client/oauth/views/templates/authOrgSelector.html';
import * as ApproveOrDenyTemplate from 'app/client/oauth/views/templates/approveOrDeny.html';
import * as OAuthInstallationInstructionsTemplate from 'app/client/oauth/views/templates/oAuthInstallationInstructions.html';
import ApplicationModel from '../../../core/js/models/application';
import Async from '../../../core/js/modules/async';
import Backend from '../../../core/js/modules/backend';
import Format from '../../../core/js/modules/format';
import InstallationModel from '../../../core/js/models/installation';
import Is from '../../../core/js/modules/is';
import MessageController from '../../../core/js/controllers/message';
import OAuthModel from '../../../core/js/models/oAuth';
import OrganizationModel from '../../../core/js/models/organization';
import ProfileModel from '../../../core/js/models/profile';
import Url from '../../../core/js/modules/url';
import UserModel from '../../../core/js/models/user';
import * as Event from '../../../core/js/_frontloader/event';
import SadRobot from '@clubhouse/assets/png/sad-robot.png';
import Logo from '@clubhouse/assets/svg/shortcut-logos/shortcut-logo-mark.svg?url';
import _ from 'lodash';
const exports = {};
const PARENT_SELECTOR = '#auth';
exports.render = () => {
  const activeOrgs = OrganizationModel.getAllActive();
  const application = _getApplicationFromUrl();
  let html = '';
  let installation = null;
  let authDetails = {};
  if (application) {
    if (activeOrgs.length === 1) {
      installation = InstallationModel.getByApplicationAndOrgIds(application.id, activeOrgs[0].id);
      authDetails = {
        credential: OAuthModel.getByInstallationId(_.get(installation, 'id')),
        redirectUri: _getRedirectUri(),
        redirectState: _getRedirectState()
      };
    }
    const authorizeApplicationData = _.assign({
      application,
      endpoint: Backend.getServerURL() + '/api/private/authorize',
      installation,
      organizations: activeOrgs,
      profile: ProfileModel.getCurrentUserProfileDetails(),
      shortcutLogo: Logo
    }, authDetails);
    html = AuthorizeApplicationTemplate.render(authorizeApplicationData);
  } else {
    html = NoApplicationFoundTemplate.render({
      img: SadRobot
    });
  }
  $(PARENT_SELECTOR).html(html);
  Event.trigger('pageRendered', 'OauthPage');
};
exports.updateOrg = function () {
  const element = $(this);
  const orgId = element.val();
  const application = _getApplicationFromUrl();
  element.attr('disabled', true).addClass('disabled').closest('#org-selector').append('<span class="fa fa-star fa-spin"></span>');
  if (!orgId) {
    exports.render();
  } else {
    Async.eachInParallelThen([InstallationModel.fetchInstallationForOrg.bind(this, application.id, orgId), OAuthModel.fetchAllByOrg.bind(this, orgId)], () => {
      const installation = InstallationModel.getByApplicationAndOrgIds(application.id, orgId);
      const org = OrganizationModel.get({
        id: orgId
      });
      if (installation) {
        _renderApprovalActionsForOrg(installation, org);
      } else if (Is.admin(UserModel.getLoggedInUserPermission())) {
        InstallationModel.installInstallationForOrg(application.id, orgId, {}, (err, newInstallation) => {
          _renderApprovalActionsForOrg(newInstallation, org, err);
        });
      } else {
        _renderInstallationInactiveForOrg(application, org);
      }
    });
  }
};
exports.approve = function () {
  _markButtonAsActionPending.call(this);
  $('#authorize-form').submit();
};
exports.deny = function () {
  _markButtonAsActionPending.call(this);
  // TODO redirect to app with query string param for denial?
  window.close();
};
function _markButtonAsActionPending() {
  $(this).attr('disabled', true).addClass('disabled').removeAttr('data-on-click').html('<span class="fa fa-star fa-spin"></span>');
}
function _getApplicationFromUrl() {
  const appId = Url.parsePath().auth;
  return ApplicationModel.get({
    id: appId
  });
}
function _renderApprovalActionsForOrg(installation, org, err) {
  installation = installation || {};
  org = org || {};
  if (err) {
    MessageController.error(err, {
      secondary: 'Unable to select <strong>' + Format.sanitize(org.name) + '</strong>.'
    });
  }
  _updateInstallationInstructionsForOrg();
  _updateOrgSelector(org);
  _updateApproveDenyActions(installation, org);
}
function _renderInstallationInactiveForOrg(application, org) {
  application = application || {};
  org = org || {};
  _updateInstallationInstructionsForOrg(application, org);
  _updateOrgSelector(org);
  _updateApproveDenyActions();
}
function _getRedirectUri() {
  return _.get(Url.parseLocationSearch(), 'redirect_uri', '');
}
function _getRedirectState() {
  return _.get(Url.parseLocationSearch(), 'state', '');
}
function _updateOrgSelector(org) {
  $('#org-selector').html(AuthOrgSelectorTemplate.render({
    activeOrgId: _.get(org, 'id'),
    organizations: OrganizationModel.getAllActive()
  }));
}
function _updateApproveDenyActions(installation, org) {
  installation = installation || {};
  org = org || {};
  let html = '';
  if (installation.id) {
    html = ApproveOrDenyTemplate.render({
      application: ApplicationModel.get({
        id: installation.application_id
      }),
      credential: OAuthModel.getByInstallationId(installation.id),
      installation,
      organization: org,
      redirectUri: _getRedirectUri(),
      redirectState: _getRedirectState()
    });
  }
  $('#install-action').html(html);
}
function _updateInstallationInstructionsForOrg(application, org) {
  application = application || {};
  org = org || {};
  const integrationPath = ApplicationModel.getIntegrationSettingsPath(application.id);
  let html = '';
  if (integrationPath) {
    html = OAuthInstallationInstructionsTemplate.render({
      applicationName: application.name,
      orgName: org.name,
      url: '/' + org.url_slug + integrationPath
    });
  }
  $('#installation-instructions').html(html);
}
export { exports as default };