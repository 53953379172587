import { ValidateSpaceForLegacyStoriesPageDocument } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { useQuery } from 'gql';
import { useWorkspaceSlug } from 'utils/navigation';
export const ValidateSpaceForLegacyStoriesPage = ValidateSpaceForLegacyStoriesPageDocument;
export const useSpace = ({
  publicId
}) => {
  const slug = useWorkspaceSlug();
  const {
    data,
    loading
  } = useQuery(ValidateSpaceForLegacyStoriesPage, {
    variables: {
      slug,
      publicId
    },
    fetchPolicy: 'network-only'
  });
  const space = data?.workspace2?.space;
  return {
    loading,
    space
  };
};