import { EpicCard } from '../shared/EpicCard';
import { LoadMore } from './LoadMore';
import { Loading } from './Loading';
import { NoResults } from './NoResults';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const EpicResults = ({
  fetchProps: {
    loading,
    data: epics,
    hasFetched,
    next
  },
  actions: {
    loadMore
  },
  onClick,
  openModalOnClick
}) => {
  if (loading && !hasFetched) {
    return _jsx(Loading, {});
  }
  if (hasFetched && !epics?.length) {
    return _jsx(NoResults, {
      entityType: "Epics"
    });
  }
  return _jsxs(_Fragment, {
    children: [epics && epics.map(epic => _jsx(EpicCard, {
      epic: epic,
      onClick: onClick,
      openModalOnClick: openModalOnClick,
      type: "elevated"
    }, epic.id)), next && _jsx(LoadMore, {
      loading: loading,
      loadMore: loadMore
    })]
  });
};