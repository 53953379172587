import { Button } from 'components/shared/DeprecatedButton';
import { NoResultImage } from 'components/shared/EmptyStateComponent';
import { Problem } from 'components/shared/Problem';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export function NoMatchingIterations({
  onClearFilters
}) {
  return _jsx(Problem, {
    className: "no-iterations-found",
    description: "No Iterations found matching your filters.",
    media: _jsx(NoResultImage, {}),
    buttons: _jsx(Button, {
      text: "Clear filters",
      onClick: onClearFilters,
      color: Button.colors.blue,
      size: Button.sizes.small,
      style: {
        flexBasis: 125,
        margin: 0
      }
    })
  });
}
NoMatchingIterations.displayName = "NoMatchingIterations";