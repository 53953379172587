import { useClearableCurrentFilters } from 'components/gql/filters/useClearableCurrentFilters';
import { TableError } from 'gql/components/TableError';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export function EpicsErrorState({
  error
}) {
  const {
    hasClearableFilters,
    clearFilters
  } = useClearableCurrentFilters();
  return _jsx(TableError, {
    error: error,
    hasClearableFilters: () => hasClearableFilters,
    onClickClearFilters: clearFilters
  });
}
EpicsErrorState.displayName = "EpicsErrorState";