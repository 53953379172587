import { UseInitializeTeamNavigationStateQueryDocument } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { useEffect, useRef } from 'react';
import { gql } from '@clubhouse/datalayer';
import { useLazyQuery } from 'gql';
import { useLazyWorkspaceCurrentPermissionIdQuery } from 'gql/queries/useWorkspaceCurrentPermissionIdQuery';
import { useWorkspaceSlug } from 'utils/navigation';
import { ALL_WORK, maxNumberOfTeams } from './teamNavigationState';
export const UseInitializeTeamNavigationStateQuery = UseInitializeTeamNavigationStateQueryDocument;
export const useInitializeTeamNavigationState = ({
  isInitialized,
  loading,
  error,
  setTeamNavigationState,
  teamScopeId
}) => {
  const slug = useWorkspaceSlug();
  const fetchCurrentPermissionId = useLazyWorkspaceCurrentPermissionIdQuery();
  const [query] = useLazyQuery(UseInitializeTeamNavigationStateQuery);
  const hasRunRef = useRef(false);
  useEffect(() => {
    (async () => {
      if (!hasRunRef.current && !loading && !error && isInitialized === false) {
        hasRunRef.current = true;
        const currentPermissionId = await fetchCurrentPermissionId();
        if (!currentPermissionId || !slug) {
          hasRunRef.current = false;
          return;
        }
        const {
          data,
          error
        } = await query({
          variables: {
            workspaceSlug: slug,
            input: {
              currentPermissionId
            }
          }
        });
        const edges = data?.workspace2?.teamSelectOptions.edges.filter(node => node.groupKey === 'currentPermissionTeam');
        if (error || !data) {
          hasRunRef.current = false;
          return;
        }

        // pick at most maxNumberOfTeams teams from the current Permission's teams
        // by default, all the user's selected teams will be shown in the team nav
        const selectedTeamIds = edges?.slice(0, maxNumberOfTeams).map(({
          node: {
            id
          }
        }) => id) ?? [];
        let expandedTeamIds = [ALL_WORK];

        // if the user has at least one team, pick the first one and
        // only expand that team. all work will be collapsed.
        const firstTeamId = selectedTeamIds[0];
        if (firstTeamId) {
          expandedTeamIds = [firstTeamId];
        }

        // if the user is visiting a team scoped page and experiencing the team nav for the first time
        // and the teamScopeId is not the firstTeamId, THEN
        // ONLY the teamScopeId team is expanded
        if (teamScopeId && teamScopeId !== firstTeamId && selectedTeamIds.includes(teamScopeId)) {
          expandedTeamIds = [teamScopeId];
        }
        setTeamNavigationState({
          selectedTeamIds,
          expandedTeamIds,
          isInitialized: true
        });
      }
    })();
  }, [error, fetchCurrentPermissionId, isInitialized, loading, query, setTeamNavigationState, slug, teamScopeId]);
};