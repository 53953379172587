import { SearchDocsQueryDocument } from "../../../datalayer/__generated_graphql_types__/graphql";
import { useLazyQuery } from '@apollo/client';
import { useCallback, useEffect, useRef } from 'react';
import { gql } from '@clubhouse/datalayer';
import { useLatestRef } from '@clubhouse/shared/hooks';
import { getById as getEpicById } from 'data/entity/epic';
import { getById as getIterationById } from 'data/entity/iteration';
import { getMilestoneById } from 'data/entity/milestone';
import { getById as getStoryById } from 'data/entity/story';
import { GET_DOCS_SEARCH } from 'pages/write/lib/apollo/queries/getDocsSearch';
import { useWorkspaceSlug } from 'utils/navigation';
import { elasticSearchEpics, elasticSearchIterations, elasticSearchObjectives, elasticSearchStories } from 'utils/search';
import { useSearch } from '../shared/hooks/useSearch';
import { SEARCH_ENTITY } from './types';
const getStory = ({
  id
}) => getStoryById(id);
const getEpic = ({
  id
}) => getEpicById(id);
const getMilestone = ({
  id
}) => getMilestoneById(id);
const getIteration = ({
  id
}) => getIterationById(id);
function useSearchStories({
  enabled
}) {
  const [storiesFetchState, searchStoriesActions] = useSearch(elasticSearchStories, getStory);
  const {
    search
  } = searchStoriesActions;
  const searchStories = useCallback(query => {
    if (enabled) return search({
      query
    });
    return Promise.resolve();
  }, [enabled, search]);
  return {
    storiesState: {
      storiesFetchState,
      searchStoriesActions
    },
    searchStories
  };
}
function useSearchEpics({
  enabled
}) {
  const [epicsFetchState, searchEpicsActions] = useSearch(elasticSearchEpics, getEpic);
  const {
    search
  } = searchEpicsActions;
  const searchEpics = useCallback(query => {
    if (enabled) return search({
      query
    });
    return Promise.resolve();
  }, [enabled, search]);
  return {
    epicsState: {
      epicsFetchState,
      searchEpicsActions
    },
    searchEpics
  };
}
function useSearchObjectives({
  enabled
}) {
  /*
   * getMilestone should probably stay because these are ultimately driven by collectionize now.
   */
  const [objectivesFetchState, searchObjectivesActions] = useSearch(elasticSearchObjectives, getMilestone);
  const {
    search
  } = searchObjectivesActions;
  const searchObjectives = useCallback(query => {
    if (enabled) return search({
      query
    });
    return Promise.resolve();
  }, [enabled, search]);
  return {
    objectivesState: {
      objectivesFetchState,
      searchObjectivesActions
    },
    searchObjectives
  };
}
function useSearchIterations({
  enabled
}) {
  const [iterationsFetchState, searchIterationsActions] = useSearch(elasticSearchIterations, getIteration);
  const {
    search
  } = searchIterationsActions;
  const searchIterations = useCallback(query => {
    if (enabled) return search({
      query
    });
    return Promise.resolve();
  }, [enabled, search]);
  return {
    iterationsState: {
      iterationsFetchState,
      searchIterationsActions
    },
    searchIterations
  };
}
const SearchDocsQuery = SearchDocsQueryDocument;
export function useSearchDocs({
  enabled,
  useQuickSearchV1
}) {
  const usesQuickSearchV2 = !useQuickSearchV1;
  const slug = useWorkspaceSlug();
  const query = usesQuickSearchV2 ? SearchDocsQuery : GET_DOCS_SEARCH;
  const [search, docsFetchState] = useLazyQuery(query, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network'
  });
  const fetchMoreDocs = useCallback(() => {
    if (enabled) {
      if (usesQuickSearchV2) {
        docsFetchState.fetchMore({
          variables: {
            input: {
              ...docsFetchState.variables?.input,
              after: docsFetchState.data.workspace2.docsSearch.pageInfo.endCursor
            }
          },
          updateQuery: (previousResult, {
            fetchMoreResult
          }) => {
            const newEdges = fetchMoreResult.workspace2.docsSearch.edges;
            const pageInfo = fetchMoreResult.workspace2.docsSearch.pageInfo;
            return newEdges.length ? {
              workspace2: {
                ...fetchMoreResult.workspace2,
                docsSearch: {
                  __typename: previousResult.workspace2.docsSearch.__typename,
                  edges: [...previousResult.workspace2.docsSearch.edges, ...newEdges],
                  pageInfo
                }
              }
            } : previousResult;
          }
        });
      } else {
        // We need to keep this fallback in case we're not using Data Layer and still using the legacy search.
        docsFetchState.fetchMore({
          variables: {
            after: docsFetchState.data.docsSearch.pageInfo.endCursor
          },
          updateQuery: (previousResult, {
            fetchMoreResult
          }) => {
            const newEdges = fetchMoreResult.docsSearch.edges;
            const pageInfo = fetchMoreResult.docsSearch.pageInfo;
            return newEdges.length ? {
              docsSearch: {
                __typename: previousResult.docsSearch.__typename,
                edges: [...previousResult.docsSearch.edges, ...newEdges],
                pageInfo
              }
            } : previousResult;
          }
        });
      }
    }
  }, [docsFetchState, enabled, usesQuickSearchV2]);
  const queryRef = useRef('');
  const searchDocs = useCallback(query => {
    if (enabled) {
      queryRef.current = query;
      if (usesQuickSearchV2) {
        search({
          variables: {
            workspaceSlug: slug,
            input: {
              q: query
            }
          }
        });
      } else {
        search({
          variables: {
            q: query
          }
        });
      }
    }
    return Promise.resolve();
  }, [enabled, usesQuickSearchV2, search, slug]);
  return {
    docsState: {
      docsFetchState,
      fetchMoreDocs,
      query: queryRef
    },
    searchDocs
  };
}
export function useQuickSearch({
  query,
  onSearch,
  onSearchComplete,
  resultTypes,
  useQuickSearchV1
}) {
  const trimmedQuery = query.trim();
  const onSearchRef = useLatestRef(onSearch);
  const onSearchCompleteRef = useLatestRef(onSearchComplete);
  const {
    searchStories,
    storiesState
  } = useSearchStories({
    enabled: resultTypes.includes(SEARCH_ENTITY.STORY)
  });
  const {
    searchEpics,
    epicsState
  } = useSearchEpics({
    enabled: resultTypes.includes(SEARCH_ENTITY.EPIC)
  });
  const {
    searchObjectives,
    objectivesState
  } = useSearchObjectives({
    enabled: resultTypes.includes(SEARCH_ENTITY.MILESTONE) || resultTypes.includes(SEARCH_ENTITY.OBJECTIVE)
  });
  const {
    searchIterations,
    iterationsState
  } = useSearchIterations({
    enabled: resultTypes.includes(SEARCH_ENTITY.ITERATION)
  });
  const {
    searchDocs,
    docsState
  } = useSearchDocs({
    enabled: resultTypes.includes(SEARCH_ENTITY.DOC),
    useQuickSearchV1
  });
  useEffect(() => {
    if (trimmedQuery) {
      Promise.all([searchStories(trimmedQuery), searchEpics(trimmedQuery), searchObjectives(trimmedQuery), searchIterations(trimmedQuery), searchDocs(trimmedQuery)]).then(() => {
        onSearchCompleteRef.current?.();
      });
    }
    onSearchRef.current?.(trimmedQuery);
  }, [trimmedQuery, onSearchRef, onSearchCompleteRef, searchStories, searchEpics, searchObjectives, searchIterations, searchDocs]);
  return {
    stories: storiesState,
    epics: epicsState,
    milestones: objectivesState,
    objectives: objectivesState,
    iterations: iterationsState,
    docs: docsState
  };
}