import { StateIcon } from '@clubhouse/shared/components/StateIcon';
import { renderComponentToString } from 'utils/helpers';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const formatWorkflowState = workflowState => ({
  ...workflowState,
  icon: renderComponentToString(_jsx(StateIcon, {
    type: workflowState.type
  }))
});
export const formatWorkflowStates = workflowStates => {
  return workflowStates.sort((a, b) => a.position - b.position).map(formatWorkflowState);
};