import { TeamIcon } from '@clubhouse/shared/components/TeamIcon';
import { getTeamColor } from '@clubhouse/shared/utils/teams';
import { MenuItem } from 'components/shared/menu';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const MenuItemWithTeam = ({
  team,
  SubMenu
}) => {
  const teamColor = team ? getTeamColor(team) : undefined;
  return _jsx(MenuItem, {
    SubMenu: SubMenu,
    icon: _jsx(TeamIcon, {
      iconUrl: team?.display_icon?.url,
      size: 24,
      backgroundColor: teamColor,
      iconType: "new-team-icon"
    }),
    label: team?.name ?? 'No Team'
  });
};
MenuItemWithTeam.displayName = "MenuItemWithTeam";