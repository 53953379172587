import { useEffect } from 'react';
import { useLatestRef } from './useLatestRef';
export const useKeyDown = ({
  keys,
  handler,
  shouldAddEventListener
}) => {
  const callbackRef = useLatestRef(handler);
  const keysRef = useLatestRef(keys);
  useEffect(() => {
    if (!shouldAddEventListener) return;
    const onKeyDown = e => {
      if (keysRef.current.includes(e.key)) {
        callbackRef.current?.(e);
      }
    };
    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [callbackRef, keysRef, shouldAddEventListener]);
};