import scrollIntoView from 'scroll-into-view-if-needed';
import { convertAccentedCharacters } from '@clubhouse/shared/utils';
import { getScrollableParents } from '@clubhouse/shared/utils/focus';
import * as AutocompleteTemplate from 'app/client/core/views/templates/autocomplete.html';
import * as AutocompleteItemsTemplate from 'app/client/core/views/templates/autocompleteItems.html';
import Is from '../modules/is';
import Utils from '../modules/utils';
import View from '../modules/view';
import _ from 'lodash';
const exports = {};
const NS = '.App.Autocomplete'; // Event namespace.

let closeTimeout;
exports.isClosing = false;
exports.reset = () => {
  exports.defaults = {
    applyOnClick: true,
    maxHeight: 300,
    multiSelect: false,
    closeOnClicksOutside: false,
    noActive: false,
    showInput: false,
    topOffset: -1
  };
  exports.options = {};
  exports.dropdownElement = null;
  exports.target = null;
  exports.input = null;
  exports.hasMouse = false;
  exports.keepOpen = false; // keep the autocomplete open on input blur and mouse leave events
  exports.closeOnLeave = false;
  exports.isDisabled = false;
};
exports.onInputBlur = () => {
  if (exports.options.keepOpen) {
    return;
  } else if (exports.hasMouse) {
    exports.closeOnLeave = true;
  } else {
    exports.close();
  }
};
exports.onInputFocus = () => {
  if (exports.closeOnLeave) {
    exports.closeOnLeave = false;
  }
};
exports.isOpen = () => {
  return !!exports.dropdownElement;
};
exports.open = options => {
  if (exports.isClosing) {
    return false;
  }
  if (exports.target && exports.target[0] === options.target) {
    exports.close();
    return false;
  }
  clearTimeout(closeTimeout);
  if (exports.dropdownElement) {
    exports.close();
  }
  if (options.multiSelect) {
    options.noActive = true;
  }
  exports.options = _.assignIn({}, exports.defaults, options);
  exports.hasMouse = false;
  exports.closeOnLeave = false;
  exports.isDisabled = false;
  const html = AutocompleteTemplate.render({
    className: exports.options.className,
    footer: exports.options.footer,
    header: exports.options.header,
    items: exports.options.items,
    activeValue: exports.options.activeValue,
    placeholder: exports.options.placeholder,
    title: exports.options.title,
    applyOnClick: exports.options.applyOnClick
  });
  exports.dropdownElement = View.attach(html, 'body');
  if (!Utils.animationDisabled()) {
    exports.dropdownElement.addClass('element-is-hiding');
    setTimeout(() => {
      if (exports.dropdownElement) {
        exports.dropdownElement.removeClass('element-is-hiding').addClass('element-is-open');
      }
    }, 0);
  }
  exports.updateTarget(options.target);
  if (exports.options && _.isFunction(exports.options.onOpen)) {
    exports.options.onOpen.call(exports.dropdownElement);
  }
  if (exports.options.showInput) {
    exports.input = $('.autocomplete-input', exports.dropdownElement).show();
    if (exports.options.inputValue || exports.options.value) {
      exports.input.val(exports.options.inputValue || exports.options.value);
    }
    if (!Is.mobile() && !Is.tablet()) {
      exports.input.select();
    }
  } else {
    if (exports.options.noActive) {
      $('.autocomplete-input', exports.dropdownElement).show().css({
        position: 'absolute',
        top: '-9999px'
      });

      // Trying to make this less jarring for mobile users,
      // since auto-focusing on a field does a lot of surprising zooming and panning.
      if (!Is.mobile() && !Is.tablet()) {
        $('.autocomplete-input', exports.dropdownElement).on('blur', exports.onInputBlur);
      }
    }
    exports.input = exports.target;
  }
  if (exports.options.noActive === false) {
    const inputVal = exports.options.value || exports.input.val();
    const active = $('.list .item', exports.dropdownElement).filter(function () {
      const itemValue = _getItemFromElement(this)?.value;
      return itemValue === inputVal || itemValue === exports.options.activeValue || $(this).text() === inputVal;
    });
    if (active.length) {
      exports.setActive(active);
    } else {
      exports.setActive();
    }
  }
  if (exports.options.closeOnClicksOutside) {
    exports.bindToClicksOutsideDropdown();
  }
  if (exports.input) {
    exports.input.on('keyup', exports.onKeyUp).on('blur', exports.onInputBlur).on('focus', exports.onInputFocus);
  }
  exports.setPosition(options.css);
  getScrollParent().on('scroll' + NS, () => {
    exports.setPosition(options.css);
  });
  exports.setScrollTop();
  exports.dropdownElement.mouseenter(() => {
    exports.hasMouse = true;
  }).mouseleave(() => {
    if (exports.closeOnLeave && !exports.keepOpen) {
      exports.close();
    }
    exports.hasMouse = false;
  });
  return exports.dropdownElement;
};

/**
 * calls exports.open, replacing onApply with a Promise, and supports a validation callback
 */
exports.openAsync = options => {
  return new Promise(resolve => {
    options.onApply = value => {
      if (_.isFunction(options.validateInput) && !options.validateInput(value)) {
        // Failing validation will simply reset the autocomplete to its original state.
        // (exports.open expects a synchronous result to close the dropdown)
        return false;
      } else {
        resolve(value);
      }
    };
    exports.open(options);
  });
};
exports.updateTarget = context => {
  if (exports.dropdownElement) {
    exports.target = $(context);
  }
};
exports.updateItems = items => {
  if (exports.isOpen()) {
    exports.options.items = items;
    const html = AutocompleteItemsTemplate.render({
      items: exports.options.items,
      active: exports.options.activeValue,
      applyOnClick: exports.options.applyOnClick
    });
    exports.dropdownElement.find('.list').html(html);
    if (exports.options.showInput && !_.isFunction(exports.options.onFilter)) {
      exports.filter();
    }

    // Reset positioning.
    getScrollParent().trigger('scroll' + NS);
  }
};
exports.getItems = () => {
  return exports.options.items;
};
const matchersCache = new WeakMap();
function _getMatchers(item) {
  if (!item) return [];
  if (matchersCache.has(item)) {
    return matchersCache.get(item);
  }
  const {
    name = '',
    matchers = []
  } = item;
  // The name will usually be wrapped in html, but for cases it isn't
  // jquery can throw an error for plain strings that contain a "/".
  const itemText = convertAccentedCharacters($(`<div>${name}</div>`).text().toLowerCase().trim());
  const allMatchers = [...matchers, itemText];
  matchersCache.set(item, allMatchers);
  return allMatchers;
}
const isSectionParent = item => $(item).hasClass('group-caption');
const isSectionChild = item => $(item).hasClass('caption') && !isSectionParent(item);
const isLeaf = item => !isSectionParent(item) && !isSectionChild(item) && !isDivider(item);
const isDivider = item => $(item).hasClass('hr');
const findLeafInSection = ({
  section,
  index,
  items
}) => {
  index = index + 1;
  const item = items[index];
  if (!item) {
    return undefined;
  }
  if (isLeaf(item)) {
    return item;
  }
  if (isSectionParent(section) && isSectionChild(item)) {
    return findLeafInSection({
      section,
      index,
      items
    });
  }
  return undefined;
};
const hasVisibleItems = () => $('.item:visible', exports.dropdownElement).length > 0;
exports.filter = () => {
  if (!exports.input) {
    return;
  }
  const value = exports.input.val().trim();
  const unquotedValue = convertAccentedCharacters(value.toLowerCase().replace(/^\"|\"$/g, ''));
  let activeElement = null;
  let foundFullMatch = false;
  if (unquotedValue) {
    $('.hr, .caption', exports.dropdownElement).hide();
    $('.item', exports.dropdownElement).each(function () {
      const dropdownMatchers = _getMatchers(_getItemFromElement(this));
      const {
        hasAnyMatch,
        hasFullMatch
      } = dropdownMatchers.reduce((acc, matcher) => {
        acc.hasAnyMatch ||= matcher.indexOf(unquotedValue) !== -1;
        acc.hasFullMatch ||= matcher.toLowerCase() === unquotedValue.toLowerCase();
        return acc;
      }, {
        hasAnyMatch: false,
        hasFullMatch: false
      });
      foundFullMatch = foundFullMatch || hasFullMatch;
      if (hasAnyMatch) {
        $(this).addClass('show').removeClass('hide');
      } else {
        $(this).addClass('hide').removeClass('show');
      }
    });
  } else {
    $('.item', exports.dropdownElement).addClass('show').removeClass('hide');
    $('.hr, .caption', exports.dropdownElement).show();
  }
  if (exports.options.preserveCaptions) {
    $('.no-items-found', exports.dropdownElement).remove();
    $('.hr, .caption', exports.dropdownElement).show();
    const items = $('.item:visible, .hr, .caption', exports.dropdownElement);
    [...items].forEach((item, index) => {
      if (!isLeaf(item)) {
        if (isDivider(item)) {
          if (!findLeafInSection({
            section: items[index + 1],
            index: index + 1,
            items
          })) {
            $(item).hide();
          }
          return;
        }
        if (!findLeafInSection({
          section: item,
          index,
          items
        })) {
          if (isSectionParent(item)) {
            $(item).after(exports.getNoItemsFoundHtml());
            return;
          }
          $(item).hide();
        }
      }
    });
    const firstItem = $('.list > *:visible', exports.dropdownElement).first();
    if (isDivider(firstItem)) {
      $(firstItem).hide();
    }
    if (!hasVisibleItems()) {
      $('.no-items-found, .hr, .caption').hide();
    }
  }
  const callbackProps = {
    hasResults: hasVisibleItems(),
    value,
    footer: $('.autocomplete-footer'),
    setFooter: html => $('.autocomplete-footer').html(html),
    noResults: $('.no-results-found'),
    setNoResults: html => $('.no-results-found').html(html)
  };
  if (hasVisibleItems()) {
    $('.no-results-found', exports.dropdownElement).hide();
  } else {
    $('.no-results-found', exports.dropdownElement).show();
  }
  if (_.isFunction(exports.options.onResultsUpdate)) {
    exports.options.onResultsUpdate(callbackProps);
  }
  if (!$('.no-results-found', exports.dropdownElement).is(':visible')) {
    const partialEl = $('.partial-match', exports.dropdownElement).show();
    activeElement = exports.getFirstItem();
    if (value && !foundFullMatch && _.isFunction(exports.options.onPartialMatch)) {
      partialEl.html(exports.options.onPartialMatch(value));
    } else {
      partialEl.hide();
    }
  } else {
    exports.hidePartialMatch();
    activeElement = $('.no-results-found', exports.dropdownElement);
  }
  exports.setActive(activeElement);
};
exports.getNoItemsFoundHtml = () => {
  return '<div class="caption no-items-found">No items found</div>';
};
exports.forceClose = () => {
  clearTimeout(closeTimeout);
  exports.cleanup();
  exports.isClosing = false;
  exports.reset();
};
exports.cleanup = () => {
  $('body').off('click' + NS);
  getScrollParent().off('scroll' + NS);
  if (exports.options && _.isFunction(exports.options.onClose)) {
    exports.options.onClose.call(exports.dropdownElement);
  }
  if (exports.dropdownElement) {
    exports.dropdownElement.remove();
  }
};
exports.close = () => {
  exports.isClosing = true;
  exports.cleanup();
  if (!Is.mobile() && exports.target) {
    exports.target.focus();
  }

  // Guard against double-clicks and clicks on already closing target
  if (Utils.animationDisabled()) {
    exports.isClosing = false;
    exports.target = null;
  } else {
    clearTimeout(closeTimeout);
    closeTimeout = setTimeout(() => {
      exports.target = null;
      exports.isClosing = false;
    }, 200);
  }
  exports.reset();
  return false;
};
function getNextElement(defaultFn, fallbackFn) {
  const activeElement = $('.item.active', exports.dropdownElement);
  let element = activeElement[defaultFn]('.item:visible').first();
  if (element.length === 0) {
    const partialMatch = $('.partial-match', exports.dropdownElement);
    if (partialMatch.is(':visible') && !partialMatch.hasClass('active')) {
      element = partialMatch;
    } else {
      element = $('.item:visible', exports.dropdownElement)[fallbackFn]();
    }
  }
  return element;
}
exports.onKeyUp = e => {
  let activeElement = null;
  if (Utils.keyPressed(e, 'DOWN_ARROW')) {
    exports.setActive(getNextElement('nextAll', 'first'));
  } else if (Utils.keyPressed(e, 'UP_ARROW')) {
    exports.setActive(getNextElement('prevAll', 'last'));
  } else if (Utils.keyPressed(e, 'ENTER')) {
    activeElement = $('.list .active', exports.dropdownElement)[0];
    exports.applyValue(activeElement || exports.input, !activeElement);
  } else if (Utils.keyPressed(e, 'ESCAPE')) {
    exports.close();
  } else if (_.isFunction(exports.options.onFilter)) {
    exports.options.onFilter(exports.input.val());
  } else {
    exports.filter();
  }
};
exports.applyValueFromInput = () => {
  exports.applyValue(exports.input, true);
  return false;
};
const getScrollParent = (element = exports.target?.[0]) => {
  if (!element) return $(window);
  const el = getScrollableParents(element, {
    dirToCheck: 'vertical',
    method: 'css'
  })?.[0]?.el;
  if (el && (el === document.body || document.body.contains(el))) return $(el);
  return $(window);
};
exports.getDropdownValue = () => {
  const $activeElement = $('.active', exports.dropdownElement);
  const displayValue = _getItemFromElement($activeElement)?.displayValue;
  const text = $activeElement.text();
  return (displayValue || text).trim();
};
exports.setPosition = customCSS => {
  if (!exports.dropdownElement) {
    return false;
  }
  let offset = exports.target.offset();
  if (offset.top === 0 && offset.left === 0 && exports.options.targetSelector) {
    const newTarget = $(exports.options.targetSelector);
    if (newTarget.length > 0) {
      exports.target = newTarget;
      offset = exports.target.offset();
    } else {
      return false;
    }
  }
  const scrollEl = getScrollParent();
  const inputElementPadding = 30;
  const leftPosition = offset.left - scrollEl.scrollLeft();
  const topPosition = offset.top + exports.target.outerHeight() + exports.options.topOffset - scrollEl.scrollTop();
  const css = _.assignIn({
    left: leftPosition,
    top: topPosition,
    width: exports.options.width || exports.target.outerWidth() - 2
  }, customCSS || {});
  exports.dropdownElement.css(css);
  exports.dropdownElement.find('.list').css({
    maxHeight: exports.options.maxHeight
  });
  $('.autocomplete-input', exports.dropdownElement).css({
    width: exports.dropdownElement.outerWidth() - inputElementPadding
  });
  exports.dropdownElement.css({
    left: Math.min(leftPosition, $(window).width() - exports.dropdownElement.outerWidth() - 3) + scrollEl.scrollLeft(),
    top: Math.min(topPosition, $(window).height() - exports.dropdownElement.outerHeight() - 3) + scrollEl.scrollTop()
  });
};
exports.hidePartialMatch = () => {
  $('.partial-match', exports.dropdownElement).hide();
};
exports.clearActive = () => {
  $('.active', exports.dropdownElement).removeClass('active');
};
exports.setActive = element => {
  element = element || exports.getFirstItem();
  exports.clearActive();
  $(element).addClass('active');
  exports.setScrollTop();
};
exports.getFirstItem = () => {
  return $('.item:visible', exports.dropdownElement).first();
};
exports.setScrollTop = () => {
  const dropdownElement = exports.dropdownElement?.[0];
  if (!dropdownElement) {
    return;
  }
  const active = dropdownElement.querySelector('.active');
  if (!active) {
    return;
  }
  const menuNode = dropdownElement.querySelector('.list');
  if (!menuNode) {
    return;
  }
  scrollIntoView(active, {
    boundary: menuNode,
    block: 'center',
    scrollMode: 'always'
  });
};
exports.bindToClicksOutsideDropdown = () => {
  if (!exports.dropdownElement) {
    return false;
  }
  const bounds = Utils.getElementBounds(exports.dropdownElement);
  $('body').off('click' + NS).on('click' + NS, e => {
    if (Utils.outsideBounds(bounds, e.pageX, e.pageY)) {
      exports.close();
    }
  });
};
const _getItemFromElement = el => {
  const index = Number.parseInt($(el).data('i'), 10);
  if (isNaN(index)) return null;
  return exports.getItems()[index];
};
exports.applyValue = function (element, isInput) {
  if (exports.isDisabled) {
    return false;
  }
  element = _.isElement(element) ? element : this;
  const value = isInput ? exports.input.val() : _getItemFromElement(element)?.value;
  if (exports.options.onApply) {
    exports.options.onApply.call(element, value);
  } else if (_.isFunction(value)) {
    value.call(exports.target);
  } else if (exports.options.fallback) {
    exports.options.fallback.call(element, value);
  }
  if (exports.options && exports.options.multiSelect) {
    if (exports.input) {
      exports.clearActive();
    }
    if (value === null) {
      $('.item', exports.dropdownElement).removeClass('selected multiple-selected');
    } else {
      if ($(element).hasClass('multiple-selected')) {
        $(element).removeClass('multiple-selected').find('.fa-minus-square').removeClass('fa-minus-square').addClass('fa-check');
      } else {
        $(element).toggleClass('selected');
      }
    }
  } else {
    exports.close();
  }
  return false;
};
exports.disable = () => {
  if (exports.dropdownElement) {
    exports.isDisabled = true;
    exports.dropdownElement.find('.item').css({
      opacity: 0.4
    });
  }
};
exports.enable = () => {
  if (exports.dropdownElement) {
    exports.isDisabled = false;
    exports.dropdownElement.find('.item').css({
      opacity: 1
    });
  }
};
exports.reset();
export { exports as default };