import "core-js/modules/es.array.push.js";
import EpicsController from 'app/client/core/js/controllers/epics.js';
import EpicModel from 'app/client/core/js/models/epic.js';
import Tooltip from 'app/client/core/js/modules/tooltip.js';
window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || {
    Controller: {},
    Model: {}
  };
  [[['Controller', 'Epics'], EpicsController], [['Model', 'Epic'], EpicModel], [['Tooltip'], Tooltip], [['Controller', 'Epics'], EpicsController], [['Model', 'Epic'], EpicModel], [['Tooltip'], Tooltip]].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});

/**
 * TODO:
 *
 * - Update to use Tooltip component insted of data-tooltip
 */

import { useContext } from 'react';
import { StoryContext } from 'components/shared/StoryCard/contexts';
import { StoryCardInternal } from '../../internal';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const EpicName = () => {
  const story = useContext(StoryContext);
  if (!story || !story.epic) return null;
  return _jsx("div", {
    "data-model": "Epic",
    "data-id": story.epic_id,
    "data-tooltip": "",
    "data-tooltip-fn": "App.Controller.Epics.renderEpicTooltip",
    children: _jsx(StoryCardInternal.EpicName, {
      name: story.epic.name
    })
  });
};
EpicName.displayName = "EpicName";