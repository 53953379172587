import { BILLING_CYCLE_TYPES, BILLING_TYPES, PLAN_STATUSES, TIERS } from '@clubhouse/shared/types/';
import { ENTITLEMENT_VIOLATION_RESOLUTION } from 'data/entity/entitlements';
import { getTier } from 'utils/tiers';
import CompanyModel from './company';
import OrganizationModel from './organization';
import ProductModel, { PRODUCT_FEATURES } from './product';
import UserModel from './user';
import BaseUtils from '../_frontloader/baseUtils';
import Collection from '../_frontloader/collection';
import * as Event from '../_frontloader/event';
import Globals from '../_frontloader/globals';
import Backend from '../modules/backend';
import Is from '../modules/is';
import moment from 'moment';
import _ from 'lodash';
const exports = {};
const dateKeys = ['current_period_start', 'current_period_end'];
const utcDateKeys = ['created_at', 'updated_at', 'referral_period_end'];
Collection.create('PaymentPlan2', exports);
exports.normalize = paymentPlan => {
  paymentPlan.companyId = CompanyModel.getCurrentID();
  dateKeys.forEach(key => {
    if (paymentPlan[key]) {
      paymentPlan[key] = moment(paymentPlan[key]).toDate();
    }
  });
  utcDateKeys.forEach(key => {
    if (paymentPlan[key]) {
      paymentPlan[key] = moment.utc(paymentPlan[key]).toDate();
    }
  });

  // Invalidate prices cache because payment plan could have changed
  paymentPlan.prices = null;
};
const MANAGED_PLAN_NAMES = {
  invoiced: 'Invoiced',
  'non-profit': 'Non Profit',
  complimentary: 'Complimentary'
};
exports.getPlanDisplayName = plan => {
  const product = exports.getCurrentProduct(plan);
  return exports.isManaged(plan) ? MANAGED_PLAN_NAMES[plan.managed_billing_class] : product.displayName;
};
exports.setID = plan => {
  const currentOrgId = plan.org_id || Globals.get('organizationID') || OrganizationModel.getCurrentID();
  const currentCompanyId = plan.company_id || CompanyModel.getCurrentID();
  plan.org_id = currentOrgId;
  plan.company_id = currentCompanyId;

  // Remapping the ID to the org ID, as we can only have one payment plan per org
  plan.id = plan.org_id;
};
exports.getStatus = (plan, status) => {
  return plan.statuses[status];
};
exports.hasStatus = (plan, status) => {
  return status in plan.statuses;
};
exports.fetch = (callback = () => {}) => {
  let endpoint = '/api/private/payment-plan-2';
  if (Is.ownerOnly(UserModel.getLoggedInUserPermission())) {
    endpoint = '/api/private/owner/payment-plan-2';
  }
  Backend.get(endpoint, {
    excludeOrgHeader: true,
    onComplete: async res => {
      exports.getHandler(res, callback);
    }
  });
};
exports.getHandler = (res, callback) => {
  exports.defaultGetHandler(res, callback);
};
exports.updateHandler = (res, callback) => {
  if (res && res.created_at) {
    exports.setID(res);
  }
  Event.trigger('paymentPlanUpdated', res);
  exports.defaultGetHandler(res, callback);
};
exports.fetchSubscriptionDetails = plan => {
  if (!Is.owner(UserModel.getLoggedInUserPermission())) return Promise.resolve();
  if (!plan.has_subscription_details) return Promise.resolve();
  if (plan.subscription_details) return Promise.resolve(plan.subscription_details);
  return new Promise(resolve => {
    Backend.get('/api/private/owner/payment-plan-2/subscription-details', {
      excludeOrgHeader: true,
      onComplete: async res => {
        plan.subscription_details = res;
        resolve(res);
      }
    });
  });
};

/*
 * The prices are cached in the paymentplan in order to reduce the number of calls to the BE.
 * The complexity here is due to the fact that everywhere where we use `usePaymentplan` the paymentplan gets duplicated,
 * this is the reason for looking up the `originPlan` in order to use that as a point of control for the multiple requests coming in.
 * This reduces all the requests (more than 10 on the plan tab) to just one.
 */
exports.fetchPrices = plan => {
  const originPlan = exports.getById(plan.id);
  plan.prices = originPlan.prices;
  if (plan.prices) return Promise.resolve(plan.prices);
  if (originPlan.fetchingPrices) {
    return originPlan.fetchingPrices.then(prices => {
      plan.prices = prices;
      return prices;
    });
  }
  originPlan.fetchingPrices = new Promise(resolve => {
    let endpoint = '/api/private/payment-plan-2/prices';
    if (Is.owner(UserModel.getLoggedInUserPermission())) {
      endpoint = '/api/private/owner/payment-plan-2/prices';
    }
    Backend.get(endpoint, {
      excludeOrgHeader: true,
      onComplete: async res => {
        originPlan.prices = res;
        plan.prices = res;
        delete originPlan.fetchingPrices;
        resolve(res);
      }
    });
  });
  return originPlan.fetchingPrices;
};
exports.updatePlan = (data, callback) => {
  Backend.put('/api/private/owner/payment-plan-2', {
    data,
    excludeOrgHeader: true,
    onComplete: (res, xhr) => {
      if (xhr.status === 200) {
        exports.updateHandler(res, callback);
      } else {
        exports.defaultErrorHandler(res, callback);
      }
    }
  });
};
exports.updateBillingEmail = (email, callback) => {
  Backend.put('/api/private/owner/payment-plan-2/set-billing-contact', {
    data: {
      billing_contact: email
    },
    excludeOrgHeader: true,
    onComplete: (res, xhr) => {
      if (xhr.status === 200) {
        exports.updateHandler(res, callback);
      } else {
        exports.defaultGetHandler(res, callback);
      }
    }
  });
};
exports.updatePlanType = (period, callback) => {
  callback = _.isFunction(callback) ? callback : _.noop;
  exports.updatePlan({
    subscription_period: period
  }, function () {
    callback.apply(this, arguments);
  });
};
exports.updateTier = ({
  tier,
  period,
  couponCode
}, callback) => {
  const data = {
    subscription_tier: tier,
    subscription_period: period
  };
  if (couponCode) {
    data.promotion_code = couponCode;
  }
  Backend.put('/api/private/owner/payment-plan-2/set-tier', {
    data,
    excludeOrgHeader: true,
    onComplete: (res, xhr) => {
      if (xhr.status === 200) {
        exports.updateHandler(res, callback);
      } else {
        exports.defaultErrorHandler(res, callback);
      }
    }
  });
};
exports.cancelDowngrade = (plan, callback) => {
  const currentProduct = exports.getCurrentProduct(plan);
  exports.updateTier({
    tier: currentProduct.display_key,
    period: plan.period
  }, callback);
};
exports.updatePeriod = (period, callback) => {
  const data = {
    subscription_period: period
  };
  Backend.put('/api/private/owner/payment-plan-2', {
    data,
    excludeOrgHeader: true,
    onComplete: (res, xhr) => {
      if (xhr.status === 200) {
        exports.getHandler(res, callback);
      } else {
        exports.defaultErrorHandler(res, callback);
      }
    }
  });
};
exports.switchToMonthly = callback => {
  exports.updatePlanType('month', callback);
};
exports.switchToAnnual = callback => {
  exports.updatePlanType('year', callback);
};
exports.isTrialing = plan => {
  return exports.hasStatus(plan, PLAN_STATUSES.TRIALING);
};

/** @deprecated Don't use methods that read the product name, for this, use isAProfitablePlan instead */
exports.isOnFreeTier = plan => !exports.isAProfitablePlan(plan);
exports.isAProfitablePlan = plan => {
  const product = exports.getCurrentProduct(plan);
  return !!product && product.display_key !== TIERS.FREE_LIMITED;
};
exports.isOnEnterpriseTier = plan => {
  const product = exports.getCurrentProduct(plan);
  return product && product.display_key === TIERS.ENTERPRISE;
};
exports.isManaged = plan => {
  return plan.billing_method === 'managed';
};
exports.getPlanBillingType = plan => exports.isManaged(plan) ? plan.managed_billing_class : null;
exports.isNonProfitPlan = plan => {
  return plan.managed_billing_class === BILLING_TYPES.NON_PROFIT;
};
exports.isComplimentaryPlan = plan => {
  return plan.managed_billing_class === BILLING_TYPES.COMPLIMENTARY;
};
exports.hasCreditCard = plan => {
  return plan.has_credit_card === true;
};
exports.isMissingCreditCard = plan => {
  return plan && !plan.has_credit_card;
};
exports.hasReferral = plan => {
  return exports.hasStatus(plan, PLAN_STATUSES.IN_REFERRAL_PERIOD);
};
exports.getReferralStatus = plan => exports.getStatus(plan, PLAN_STATUSES.IN_REFERRAL_PERIOD);
exports.isReferralExpired = plan => {
  const today = moment().startOf('day');
  const referralPeriod = exports.getStatus(plan, PLAN_STATUSES.IN_REFERRAL_PERIOD);
  return !!referralPeriod && moment(referralPeriod.referral_period_end).startOf('day').isBefore(today);
};
exports.isDelinquent = plan => {
  return exports.hasStatus(plan, PLAN_STATUSES.DELINQUENT);
};
exports.getDelinquentStatus = plan => exports.getStatus(plan, PLAN_STATUSES.DELINQUENT);
exports.isMonthly = plan => {
  return plan.subscription_period === 'month';
};
exports.isYearly = plan => {
  return plan.subscription_period === 'year';
};
exports.getCurrentProduct = plan => {
  return ProductModel.getById(plan.current_product_id);
};
exports.getPendingProduct = plan => {
  const tierDowngrade = exports.getStatus(plan, PLAN_STATUSES.PENDING_TIER_DOWNGRADE);
  return tierDowngrade && ProductModel.getById(tierDowngrade.pending_product_id);
};
exports.hasPendingTierDowngrade = plan => {
  return exports.hasStatus(plan, PLAN_STATUSES.PENDING_TIER_DOWNGRADE);
};
exports.getPendingPeriodDowngrade = plan => {
  const periodDowngrade = exports.getStatus(plan, PLAN_STATUSES.PENDING_PERIOD_DOWNGRADE);
  return periodDowngrade && periodDowngrade.pending_subscription_period;
};

// Gets remaining free seats
exports.availableFreeSeats = plan => {
  const seats = exports.getStatus(plan, PLAN_STATUSES.HAS_AVAILABLE_FREE_SEATS);
  return seats?.available_seats ?? 0;
};

// Checks for any available seats, free or paid
exports.availableSeats = plan => {
  const hasAvailableSeats = exports.isOnFreeTier(plan) ? exports.getStatus(plan, PLAN_STATUSES.HAS_AVAILABLE_FREE_SEATS) : exports.getStatus(plan, PLAN_STATUSES.HAS_AVAILABLE_PAID_SEATS);
  return hasAvailableSeats?.available_seats ?? 0;
};
exports.trialingWillDowngradeToFreeInfo = plan => {
  const info = exports.getStatus(plan, PLAN_STATUSES.TRIALING_WILL_DOWNGRADE_TO_FREE);
  return info && {
    current_period_start: plan.current_period_start,
    current_period_end: plan.current_period_end,
    ...info
  };
};
exports.violationOfPendingTierDowngradeInfo = plan => {
  const info = exports.getStatus(plan, PLAN_STATUSES.VIOLATION_OF_PENDING_TIER_DOWNGRADE);
  if (!info) return false;
  const violations = Object.entries(info.violated_entitlements).map(([key, {
    max,
    current
  }]) => ({
    key,
    label: ENTITLEMENT_VIOLATION_RESOLUTION[key] || key,
    max,
    current
  }));
  return {
    current_period_start: plan.current_period_start,
    current_period_end: plan.current_period_end,
    pending_product: ProductModel.getById(info.pending_product_id),
    violations
  };
};
exports.planTrialInfo = plan => {
  const info = exports.getStatus(plan, PLAN_STATUSES.TRIALING);
  return info && {
    current_period_start: plan.current_period_start,
    current_period_end: plan.current_period_end,
    ...info
  };
};
exports.freePlanIneligibleForTrial = plan => {
  const info = exports.getStatus(plan, PLAN_STATUSES.TRIALING);
  const activeSeats = exports.getNumActiveUsers(plan);
  return !!info && activeSeats > 10 ? {
    current_period_start: plan.current_period_start,
    current_period_end: plan.current_period_end,
    activeSeats,
    ...info
  } : null;
};
exports.getNumActiveUsers = plan => plan.active_seats;

// Total seats is a combination of available seats and active seats.
exports.getNumSeats = plan => {
  const activeSeats = exports.getNumActiveUsers(plan);
  const availableSeats = exports.availableSeats(plan);
  return availableSeats + activeSeats;
};
exports.getName = plan => {
  const product = exports.getCurrentProduct(plan);
  const {
    title
  } = getTier(product, product.display_key);
  return title;
};
exports.getPlanPeriodAdverb = plan => plan.subscription_period === BILLING_CYCLE_TYPES.ANNUAL ? 'annually' : 'monthly';
exports.getPlanPeriod = plan => plan.subscription_period;
exports.getPlanPricePerSeat = ({
  prices,
  tier,
  frequency = 'year',
  monthly = true,
  basePrice = false
}) => {
  if (prices && prices[tier] && prices[tier][frequency]) {
    return prices[tier][frequency][monthly ? 'monthly' : 'yearly'].per_seat[basePrice ? 'base' : 'actual'] / 100;
  }
};
exports.getPlanPriceTotal = ({
  prices,
  tier,
  frequency = 'year',
  monthly = true,
  basePrice = false
}) => {
  if (prices && prices[tier] && prices[tier][frequency]) {
    return prices[tier][frequency][monthly ? 'monthly' : 'yearly'].total[basePrice ? 'base' : 'actual'] / 100;
  }
};
exports.getPlanForCurrentOrg = () => {
  const orgID = Globals.get('organizationID') || OrganizationModel.getCurrentID();

  // This can happen if the user belonged to a company/org2 but was not only disabled but deactivated from the org2.
  if (!orgID) return;
  return exports.getPlanForOrg(orgID);
};
exports.getPlanForCompany = ({
  id
}) => {
  return exports.get({
    companyId: id
  });
};
exports.getPlanForOrg = orgID => {
  const company = CompanyModel.getFromOrg({
    id: orgID
  });
  if (!company) return;
  return exports.getPlanForCompany(company);
};
exports.hasEntitlement = (plan, entitlement) => {
  return plan.accessible_entitlements.includes(entitlement);
};
exports.canInviteUsers = plan => plan.can_invite;
exports.canInviteObservers = plan => {
  return exports.hasEntitlement(plan, PRODUCT_FEATURES.ROLE_CREATE_OBSERVER);
};
exports.isBusinessTier = tier => [TIERS.BUSINESS, TIERS.BUSINESS_WRITE].some(businessTier => businessTier === tier);
exports.getNextAvailableProduct = plan => {
  let product = exports.getCurrentProduct(plan);
  let checkPath = true;
  let nextAvailableProduct = null;
  while (checkPath) {
    if (!product.upgrade || product.display_key === TIERS.ENTERPRISE) {
      checkPath = false;
    } else {
      const upgradeProduct = ProductModel.getById(product.upgrade.id);
      if (upgradeProduct.display_key === TIERS.ENTERPRISE) {
        checkPath = false;
      } else {
        if (plan.tier_eligibility[upgradeProduct.display_key]) {
          nextAvailableProduct = upgradeProduct;
          checkPath = false;
        } else {
          product = upgradeProduct;
        }
      }
    }
  }
  return nextAvailableProduct;
};
exports.canUpgradePlan = plan => Boolean(exports.getNextAvailableProduct(plan));
exports.Promises = {};
exports.Promises.fetch = BaseUtils.promisify(exports.fetch);
exports.Promises.updatePlanType = BaseUtils.promisify(exports.updatePlanType);
exports.Promises.updateTier = BaseUtils.promisify(exports.updateTier);
exports.Promises.cancelDowngrade = BaseUtils.promisify(exports.cancelDowngrade);
exports.Promises.updatePeriod = BaseUtils.promisify(exports.updatePeriod);
export { exports as default };