import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as queryString from '../utils/queryString';
const setParam = ({
  location,
  history
}) => (key, value) => {
  const currentSearch = queryString.parse(location.search);
  history.replace({
    search: value ? queryString.encode({
      ...currentSearch,
      [key]: true
    }) : queryString.remove(location.search, key)
  });
};
const getParam = ({
  location
}) => key => {
  return queryString.parse(location.search)[key];
};

/* This is strictly tied to React Router usage */
export const useQueryParams = () => {
  const location = useLocation();
  const history = useHistory();
  return {
    setParam: setParam({
      location,
      history
    }),
    getParam: getParam({
      location
    })
  };
};
export const useQueryParam = key => {
  const location = useLocation();
  const history = useHistory();
  const set = useCallback(value => {
    const currentSearch = queryString.parse(location.search);
    history.replace({
      search: value ? queryString.encode({
        ...currentSearch,
        [key]: value
      }) : queryString.remove(location.search, key)
    });
    return value;
  }, [location.search, history, key]);
  const value = useMemo(() => queryString.parse(location.search)[key], [key, location]);
  return [value, set];
};

/**
 * @deprecated: Use `useSearchParam` or `useSearchParams` in utils/navigation instead.
 */
export const useGenericQueryParams = () => {
  const {
    location,
    history
  } = window;
  return {
    setParam: (key, value) => {
      const parsed = queryString.parse(location.search);
      if (!value) {
        delete parsed[key];
      } else {
        parsed[key] = value;
      }
      history.replaceState({}, document.title, `${location.origin}${location.pathname}${queryString.encode(parsed)}`);
    },
    getParam: key => {
      const parsed = queryString.parse(location.search);
      return parsed[key];
    }
  };
};