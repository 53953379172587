import { useMemo, useState } from 'react';
import { useLatestRef } from '@clubhouse/shared/hooks';
import * as LS from './localStorage';
import { useWorkspaceSlug } from './navigation';
const hasChanges = (obj, defaults) => {
  if (!obj) return false;
  if (!defaults) return false;
  for (const key in obj) {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      if (hasChanges(obj[key], defaults?.[key])) return true;
    } else if (!!obj[key] && !!defaults[key] && obj[key] !== defaults[key]) return true;else if (Boolean(obj[key]) !== Boolean(defaults[key])) return true;
  }
  return false;
};
export const useDraft = (key, defaultValue = null) => {
  const defaultValueRef = useLatestRef(defaultValue);
  const slug = useWorkspaceSlug();
  const prefix = `drafts-${slug}`;
  const [savedDraft] = useState(() => LS.getValue(key, {
    prefix,
    defaultValue: null
  }));
  const [, setCurrentDraft] = useState(savedDraft);
  const draft = useMemo(() => {
    setCurrentDraft(LS.getValue(key, {
      prefix
    }));
    const set = value => {
      LS.setValue(key, value, {
        prefix
      });
      setCurrentDraft(value);
    };
    const update = value => {
      setCurrentDraft(curr => {
        const next = {
          ...curr,
          ...value
        };
        LS.setValue(key, next, {
          prefix
        });
        return next;
      });
    };
    const exists = () => {
      const storedValue = LS.getValue(key, {
        prefix,
        defaultValue: null
      });
      if (!hasChanges(storedValue, defaultValueRef.current)) return false;
      return true;
    };
    const clear = () => {
      LS.removeValue(key, {
        prefix
      });
      setCurrentDraft(null);
    };
    return {
      update,
      set,
      exists,
      clear
    };
  }, [defaultValueRef, key, prefix]);
  return [savedDraft || defaultValue, draft];
};