import { VisuallyHidden } from '@react-aria/visually-hidden';
import { ThirdPartyIcon } from '@useshortcut/shapes-ds';
import { Chip } from '@clubhouse/shared/components/Chip';
import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons';
import { ChipTooltip } from '../ChipTooltip';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const JiraSyncChip = ({
  externalId
}) => {
  if (!externalId) return null;
  return _jsx(ChipTooltip, {
    content: _jsx(ChipTooltip.Text, {
      children: "This Story is synced with a JIRA Issue"
    }),
    children: _jsxs(Chip, {
      children: [_jsx(DeprecatedIconAdapter, {
        width: "16",
        children: _jsx(ThirdPartyIcon, {
          icon: "Jira"
        })
      }), _jsx(VisuallyHidden, {
        children: "Jira Ticket:"
      }), externalId]
    })
  });
};
JiraSyncChip.displayName = "JiraSyncChip";