import * as AddNewProjectTemplate from 'app/client/core/views/templates/addNewProject.html';
import * as TeamLabelTemplate from 'app/client/core/views/templates/teamLabel.html';
import * as NewProjectTeamControlTemplate from 'app/client/core/views/templates/newProjectTeamControl.html';
import * as ButtonSavingStateTemplate from 'app/client/core/views/templates/buttonSavingState.html';
import * as AddNewProjectActionsTemplate from 'app/client/core/views/templates/addNewProjectActions.html';
import AddNewController from './addNew';
import Autosize from '../modules/autosize';
import ColorPickerController from './colorPicker';
import Constants from '../modules/constants';
import DropdownController from './dropdown';
import * as Event from '../_frontloader/event';
import Format from '../modules/format';
import Is from '../modules/is';
import LocalStorage from '../modules/localStorage';
import MessageController from './message';
import PanelController from './panel';
import ProjectModel from '../models/project';
import TeamModel from '../models/team';
import UserModel from '../models/user';
import Utils from '../modules/utils';
import Validator from '../modules/validator';
import View from '../modules/view';
import { NewEntityDescriptionEditor } from 'components/shared/NewEntityDescriptionEditor';
import _ from 'lodash';
const exports = {};

// ----- Render -----

const PANEL_ID = 'addNewProject';
exports.LOCALSTORAGE_KEY = 'newProjectState';
exports.saving = false;
exports.newTemplate = () => {
  const template = {
    color: Utils.sample(Constants.PROJECT_COLORS),
    description: '',
    name: '',
    team_id: TeamModel.getActive().id
  };
  return template;
};
exports.getState = () => {
  return AddNewController.getState(exports.LOCALSTORAGE_KEY) || exports.newTemplate();
};
exports.updateState = updates => {
  const state = exports.getState();
  _.assignIn(state, updates);
  LocalStorage.set(exports.LOCALSTORAGE_KEY, state, {
    prefix: true
  });
  Utils.autoTabIndex($('.add-new-project'));
};
exports.render = () => {
  if (Is.readOnly(UserModel.getLoggedInUserPermission())) {
    return false;
  }
  const state = exports.getState();
  exports.updateState(state);

  // The user may have the Create Story menu open at this point.
  DropdownController.closeAll();
  const panel = PanelController.open({
    html: AddNewProjectTemplate.render(state),
    id: PANEL_ID,
    onOpen: panel => {
      if (panel && panel.element) {
        panel.element.find('textarea').first().focus();
        renderDescriptionEditorV2(panel);
      }
    },
    onClose: () => {
      if (panel && panel.element) {
        const textarea = panel.element.find('textarea');
        textarea.off('keyup blur');
        Autosize.destroy(textarea);
      }
      Validator.destroy('.add-new-dialog');
      Event.off('resize.AddNewProject');
    },
    onScroll: Is.mobile() ? () => {
      return false;
    } : null,
    target: document.getElementById('add-new-button')
  });
  if (!panel) {
    return false;
  }
  Validator.init('.add-new-dialog');
  panel.element.find('.title-container textarea').focus().select();
  const textareas = panel.element.find('textarea');
  Autosize.bind(textareas);
  textareas.on('keyup blur', _.debounce(function () {
    if (!panel || !panel.element) {
      return;
    }
    const key = $(this).data('binding');
    const update = {};
    update[key] = $(this).val().trim();
    exports.updateState(update);
  }, 50));
  return false;
};
const renderDescriptionEditorV2 = panel => {
  if (!panel.element) return;
  View.renderComponent({
    componentKey: 'addNewProjectReactMarkdownEditor',
    mountNode: panel.element.find('#project-description-v2')[0],
    component: NewEntityDescriptionEditor,
    props: {
      // The renderId forces a re-render of the editor with clean state.
      // `initialValue` doesn't work because it is usually an empty string (ie no change is triggered)
      renderId: String(Date.now()),
      initialValue: exports.getState()?.description || '',
      onChange: val => {
        exports.updateState({
          description: val
        });
      },
      onSave: async val => {
        exports.updateState({
          description: val
        });
        exports.save();
      }
    }
  });
};

// ----- Actions -----

exports.addNewInTeam = function () {
  const team = Utils.getModelFromContext(this);
  exports.updateState({
    name: '',
    description: '',
    team_id: team.id
  });
  return exports.render();
};
exports.onKeyDownTitle = function (e) {
  if (Utils.keyPressed(e, ['ENTER', 'CMD+ENTER', 'CTRL+ENTER'])) {
    $(this).prop('readonly', true);
    setTimeout(exports.save, 50);
    return false;
  }
};
exports._updateColor = function (color) {
  exports.updateState({
    color
  });
  $(this).find('.fa-square').css({
    color
  });
};
exports.openColorPicker = function () {
  const project = exports.getState();
  ColorPickerController.open({
    activeValue: project.color,
    context: this,
    onApply: exports._updateColor.bind(this)
  });
  return false;
};
exports.openTeamSelector = function () {
  const state = exports.getState();
  DropdownController.open({
    items: TeamModel.getItemsForAutocomplete({
      activeTeam: TeamModel.getById(state.team_id) || TeamModel.getActive()
    }),
    target: this,
    title: TeamLabelTemplate.render(),
    width: 200,
    onApply: team => {
      exports.updateState({
        team_id: team.id
      });
      $('#new-project-team').html(NewProjectTeamControlTemplate.render({
        team_id: team.id
      }));
    }
  });
  return false;
};
exports.save = () => {
  if (exports.saving) {
    return;
  }
  if (Validator.isValid('.add-new-dialog')) {
    exports.saving = true;
    $('.add-new-dialog .add-new-action').html(ButtonSavingStateTemplate.render());
    const newProject = exports.getState();
    if (!newProject.team_id || newProject.team_id && !TeamModel.getById(newProject.team_id)) {
      newProject.team_id = TeamModel.getActive().id;
    }
    ProjectModel.saveNew(newProject, err => {
      if (err) {
        $('.add-new-dialog .add-new-action').html(AddNewProjectActionsTemplate.render());
        MessageController.error(err, {
          secondary: 'Unable to create <strong>' + Format.sanitize(newProject.name) + '</strong>.'
        });
      } else {
        LocalStorage.remove(exports.LOCALSTORAGE_KEY, {
          prefix: true
        });
        PanelController.closeById(PANEL_ID);
      }
      exports.saving = false;
    });
  } else {
    $('.add-new-dialog textarea').prop('readonly', false);
    $('body').focus();
    Validator.focusOnFirstError('.add-new-dialog');
  }
};
export { exports as default };