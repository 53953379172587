import { Nouns } from '@clubhouse/shared/constants';
import { useFilterState } from 'components/gql/filters/hooks/useFilterState';
import { PermissionsFilter } from '../permission/PermisssionsFilter';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export function RequestersFilter({
  entityType,
  filterHeading
}) {
  const [selectedValues, setSelectedValues] = useFilterState({
    paramName: 'requester_ids',
    paramType: 'string[]'
  });
  return _jsx(PermissionsFilter, {
    value: selectedValues,
    onChange: setSelectedValues,
    entityType: entityType,
    filterHeading: filterHeading,
    filterType: Nouns.Requester
  });
}
RequestersFilter.displayName = "RequestersFilter";