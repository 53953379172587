import TeamModel from 'app/client/core/js/models/team';
import ApplicationState from 'app/client/core/js/modules/applicationState';
import { useTeamScopeId } from 'components/team-navigation';
import { useAppState } from 'utils/appState';
import { getCurrentPage } from 'utils/navigation';
import { useEntities } from './collection';
import { getTeamByTeamScopedId, useTeamScopedTeam } from './group';
export const getById = id => TeamModel.getById(id);
export const getByIdOrDefault = id => getById(id) || TeamModel.get();
export const hasTeams = () => TeamModel.size() > 1;
export const getActive = context => TeamModel.getActive(context);
export const setActive = (team, context) => TeamModel.setActive(team, context);
export const setActiveTeamById = (teamId, context) => TeamModel.setActiveTeamById(teamId, context);
export const getAll = () => TeamModel.all();
export const useTeams = () => {
  const {
    entities: teams
  } = useEntities({
    model: TeamModel
  });
  return {
    teams
  };
};
export const useTeamScopedWorkflows = () => {
  const {
    teams: workflows
  } = useTeams();
  const currentTeam = useTeamScopedTeam();
  return getWorkflowsFilteredByTeam(currentTeam, workflows);
};

/*
 * @deprecated React components should use useTeamScopedWorkflows instead
 */
export function deprecatedGetTeamScopedWorkflowsForNonReactAccess() {
  const currentGroup = getTeamByTeamScopedId();
  const all = TeamModel.all();
  return getWorkflowsFilteredByTeam(currentGroup, all);
}
export function getWorkflowsFilteredByTeam(team, workflows) {
  if (!team) return workflows;
  return workflows.filter(({
    workflow
  }) => (team.workflow_ids ?? []).includes(workflow.id));
}
export function getWorkflowStatesForTeam(team) {
  return TeamModel.getWorkflowStatesForTeam(team);
}
export function useDefaultTeamScopedWorkflow() {
  const workflows = useTeamScopedWorkflows();
  const [allWorkActiveWorkflowId] = useAppState({
    appStateKey: 'ActiveTeam.stories'
  });
  return workflows.find(workflow => workflow.id === allWorkActiveWorkflowId) ?? workflows[0];
}

/* @deprecated React components should use useDefaultTeamScopedWorkflow */
export function deprecatedGetDefaultTeamScopedWorkflowForNonReactAccess() {
  const workflows = deprecatedGetTeamScopedWorkflowsForNonReactAccess();
  const allWorkActiveWorkflowId = ApplicationState.get('ActiveTeam.stories');
  return workflows.find(workflow => workflow.id === allWorkActiveWorkflowId) ?? workflows[0];
}
export const useActiveTeam = context => {
  let page = context || getCurrentPage();
  const {
    value: teamId
  } = useTeamScopeId();
  const defaultWorkflow = useDefaultTeamScopedWorkflow();
  const appStateKey = TeamModel.getWorkflowApplicationStateKey(teamId, page);

  // This can happen if user opens a story in a new tab and closes it.
  if (page === 'story') {
    page = 'stories';
  }
  return useAppState({
    appStateKey,
    default: defaultWorkflow?.id
  });
};
export const getLabel = isPlural => TeamModel.getLabel({
  isPlural
});