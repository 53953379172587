import Backend from '../modules/backend';
import Collection from '../_frontloader/collection';
import CompanyModel from './company';
import Globals from '../_frontloader/globals';
import OrganizationModel from './organization';
import UserModel from './user';
import _ from 'lodash';
const exports = {};
Collection.create('Organization', exports);
exports.getDefault = () => {
  return _.find(UserModel.getLoggedInUserProfiles(), {
    default: true
  });
};
exports.getOrgProfileForLoggedInUser = (orgID, profiles) => {
  orgID = orgID || Globals.get('organizationID') || OrganizationModel.getCurrentID();
  profiles = profiles || UserModel.getLoggedInUserProfiles() || [];
  const profile = profiles.find(profile => {
    const hasPermissionForOrg = profile.permissions.some(permission => {
      const isMatch = permission.organization.id === orgID;
      return isMatch;
    });
    return hasPermissionForOrg;
  });
  return profile || {};
};
exports.setDefault = (orgID, callback) => {
  const profile = exports.getOrgProfileForLoggedInUser(orgID);
  callback = _.isFunction(callback) ? callback : _.noop;
  Backend.put('/api/private/user/profiles/' + profile.id + '/default', {
    company_id: CompanyModel.getFromOrg({
      id: orgID
    }).id,
    organization_id: orgID,
    onComplete: (res, xhr) => {
      if (xhr.status === 204) {
        UserModel.fetchLoggedInUserProfiles(callback);
      } else {
        exports.defaultErrorHandler(res, callback);
      }
    }
  });
};
exports.setPosition = (profile, changes, callback) => {
  callback = _.isFunction(callback) ? callback : _.noop;
  Backend.put('/api/private/user/profiles/' + profile.id, {
    company_id: profile.company.id,
    excludeOrgHeader: true,
    data: changes,
    onComplete: res => {
      if (_.get(res, 'id')) {
        UserModel.fetchLoggedInUserProfiles(callback);
      } else {
        exports.defaultErrorHandler(res, callback);
      }
    }
  });
};
export { exports as default };