import { getActiveSpan, startInactiveSpan, withActiveSpan } from '@sentry/browser';
import { useEffect, useRef } from 'react';
import { emptyObject } from '@clubhouse/shared/utils/emptyObject';
import { useOnNextProps } from './useOnNextProps';

// constants inspired from https://github.com/getsentry/sentry-javascript/blob/master/packages/react/src/constants.ts
const REACT_RERENDER_OP = 'ui.react.rerender';
const REACT_RENDER_OP = 'ui.react.render';
export const useRenderSpansWithPropChanges = (name, props) => {
  return useProfiler(name, props);
};
export const useRenderSpans = name => {
  return useProfiler(name);
};
const useProfiler = (name, props) => {
  const activeSpan = getActiveSpan();
  const renderSpan = useRef(undefined);
  if (!activeSpan) {
    renderSpan.current = undefined;
  } else if (activeSpan !== renderSpan.current) {
    renderSpan.current = startInactiveSpan({
      onlyIfParent: true,
      name: `<${name}>`,
      op: REACT_RENDER_OP
    });
  }
  useOnNextProps({
    name,
    props: props ?? emptyObject,
    onNextProps: ({
      name,
      changes
    }) => {
      if (!renderSpan.current) {
        return () => {};
      }
      return withActiveSpan(renderSpan?.current, () => {
        // If we don't have an active span anymore on props change, we don't want to start a new span.
        if (!getActiveSpan()) {
          return () => {};
        }
        const rerenderSpan = startInactiveSpan({
          ...(props ? {
            data: {
              changedProps: Object.keys(changes)
            }
          } : {}),
          name: `<${name}>`,
          op: REACT_RERENDER_OP
        });
        return () => {
          rerenderSpan?.end();
        };
      });
    }
  });

  // biome-ignore lint/correctness/useExhaustiveDependencies: this effect should run each time the current render span changes
  useEffect(() => {
    renderSpan.current?.end();
  }, [renderSpan.current]);
  return {
    span: renderSpan.current
  };
};