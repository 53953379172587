import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import { useEffect } from 'react';
import { useSetHotReloadRenderId } from './navigation';
import { useReplaceState } from './useReplaceState';
export const RedirectTo = ({
  to
}) => {
  const replaceState = useReplaceState();
  const setRenderId = useSetHotReloadRenderId();

  // biome-ignore lint/correctness/useExhaustiveDependencies: only call once to prevent multiple redirects
  useEffect(() => {
    const redirect = url => {
      replaceState(() => new URL(url, window.location.origin));
      setRenderId();
    };
    if (typeof to === 'function') {
      const result = to();
      if (typeof result === 'string') redirect(result);else if (result instanceof Promise) result.then(redirect);else throw new Error('invalid redirect destination: ' + String(result));
    } else redirect(to);
  }, []);
  return null;
};