import { getById as getColumnById } from 'data/entity/column';
import { getStateWithName, getById as getWorkflowById } from 'data/entity/workflow';
export const getWorkflowUpdates = ({
  entity,
  workflowId
}) => {
  const workflow = getWorkflowById(workflowId);
  if (!workflow) return {
    workflow_state_id: null,
    project_id: null
  };
  const currentState = getColumnById(entity.workflow_state_id);
  const compatibleState = getStateWithName(workflow, currentState.name);
  return {
    workflow_state_id: compatibleState ? compatibleState.id : workflow.default_state_id,
    project_id: null
  };
};