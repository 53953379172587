import "core-js/modules/es.array.push.js";
import { StatusIcon } from '@useshortcut/shapes-ds';
import { includes, isArray, isFunction } from 'lodash';
import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons/DeprecatedIconAdapter';
import { useForceUpdate } from '@clubhouse/shared/hooks';
import BaseUtils from 'app/client/core/js/_frontloader/baseUtils';
import ColumnModel from 'app/client/core/js/models/column';
import TeamModel from 'app/client/core/js/models/team';
import WorkflowModel from 'app/client/core/js/models/workflow';
import Format from 'app/client/core/js/modules/format';
import { renderComponentToString } from 'utils/helpers';
import { useEntity, useModelChangeListeners } from './collection';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const useColumn = ({
  id
}) => {
  const {
    entity
  } = useEntity({
    model: ColumnModel,
    id
  });
  return entity;
};
export const useAllColumnsInterleaved = () => {
  const update = useForceUpdate();
  useModelChangeListeners({
    model: ColumnModel,
    onChange: update,
    onBulkChange: update
  });
  return ColumnModel.allInterleaved().reverse();
};
export const deleteWorkflowState = workflowStateId => new Promise((resolve, reject) => {
  const state = getById(workflowStateId);
  const workflow = WorkflowModel.getById(state?.workflow_id);
  if (!state || !workflow) {
    reject(new Error('Invalid workflow state'));
  } else {
    ColumnModel.saveDelete(workflow, state, err => {
      if (err) reject(err);else resolve();
    });
  }
});
export const firstFromActiveWorkflow = () => ColumnModel.firstFromActiveWorkflow();
export const isSafeToDelete = columnId => {
  const column = getById(columnId);
  const workflow = WorkflowModel.getById(column?.workflow_id);
  return !!ColumnModel.isSafeToDelete(column, workflow);
};
export const isOnlyOneOfType = columnId => {
  const column = getById(columnId);
  const workflow = WorkflowModel.getById(column?.workflow_id);
  return !!ColumnModel.isOnlyOneOfType(column, workflow);
};
export const getReactIcon = (column, width = 20) => ColumnModel.getReactIcon(column, width);
export const getById = workflow_state_id => workflow_state_id ? ColumnModel.getById(workflow_state_id) : undefined;
export const getTeamFromColumn = column => ColumnModel.getTeamFromColumn(column);
export const getTeamName = column => ColumnModel.getTeamName(column);
export const allFromActiveWorkflow = () => ColumnModel.allFromActiveWorkflow();
export const allFromWorkflowByTeamId = id => {
  const team = TeamModel.getById(id);
  if (!team) return [];
  return TeamModel.getWorkflowStatesForTeam(team);
};
export const allActiveBacklogFromActiveWorkflow = () => ColumnModel.allActiveBacklogFromActiveWorkflow();
export const getOldStoriesCompletedBefore = column => ColumnModel.getOldStoriesCompletedBefore(column);
export const setOldStoriesCompletedBefore = (column, date) => ColumnModel.setOldStoriesCompletedBefore(column, date);
export const isLoadingOldStories = column => ColumnModel.isLoadingOldStories(column);
export const setLoadingOldStories = (column, loading) => ColumnModel.setLoadingOldStories(column, loading);
export const isDone = column => ColumnModel.isDone(column);
export const WorkflowStateIcon = ({
  type
}) => {
  if (type === 'backlog') {
    return _jsx("span", {
      style: {
        display: 'inline-flex',
        alignItems: 'flex-end',
        marginRight: 4
      },
      children: _jsx(DeprecatedIconAdapter, {
        width: 20,
        children: _jsx(StatusIcon, {
          icon: "Backlog"
        })
      })
    });
  } else if (type === 'started') {
    return _jsx("span", {
      style: {
        display: 'inline-flex',
        alignItems: 'flex-end',
        marginRight: 4
      },
      children: _jsx(DeprecatedIconAdapter, {
        width: 20,
        children: _jsx(StatusIcon, {
          icon: "Started"
        })
      })
    });
  } else if (type === 'done') {
    return _jsx("span", {
      style: {
        display: 'inline-flex',
        alignItems: 'flex-end',
        marginRight: 4
      },
      children: _jsx(DeprecatedIconAdapter, {
        width: 20,
        children: _jsx(StatusIcon, {
          icon: "Done"
        })
      })
    });
  } else {
    return _jsx("span", {
      style: {
        display: 'inline-flex',
        alignItems: 'flex-end',
        marginRight: 4
      },
      children: _jsx(DeprecatedIconAdapter, {
        width: 20,
        children: _jsx(StatusIcon, {
          icon: "Unstarted"
        })
      })
    });
  }
};

// options could be a Story object - see Controller.Story.openStoryUpdater fn.
export const getItemsForAutocomplete = options => {
  const items = [];
  const activeWorkflow = options.workflow || WorkflowModel.getActive();
  ColumnModel.getStateTypes().forEach(stateType => {
    if (options.includeDone === false && stateType.value === 'done') {
      return false;
    }
    const columns = ColumnModel.filter(column => column.type === stateType.value && column.workflow_id === activeWorkflow.id);
    if (!columns.length) {
      return false;
    }
    if (items.length > 0) {
      items.push({
        hr: true
      });
    }
    const selectAllButton = isFunction(options.selectAllFn) ? options.selectAllFn(stateType.value) : '';
    items.push({
      html: '<div class="caption">' + stateType.name + ' ' + selectAllButton + '</div>'
    });
    columns.forEach(column => {
      const item = {
        name: Format.sanitizeAndEmojify(column.name),
        customIconLeft: renderComponentToString(_jsx(WorkflowStateIcon, {
          type: column.type
        })),
        value: column.id
      };
      if (options.isMultiSelect === true && isArray(options.selectedIds)) {
        item.className = includes(options.selectedIds, column.id) ? 'selected' : '';
        item.iconRight = 'fa-check';
      }
      items.push(item);
    });
  });
  return items;
};
export const updateSave = (workflowId, columnId, updates) => {
  const updateColumnPromise = BaseUtils.promisify(ColumnModel.saveUpdate);
  return updateColumnPromise({
    id: workflowId
  }, {
    id: columnId
  }, updates);
};