import { Spaced } from '@clubhouse/shared/components/Spaced';
import { ToastText, ToastTitle, addToast, hasToast, removeToast } from '@clubhouse/shared/components/Toast';
import { getCurrentPage } from './navigation';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const OFFLINE_ID = 'user-is-offline-toast';
const ONLINE_ID = 'user-is-online-toast';
const OfflineToast = ({
  title = 'Reconnecting...',
  message
}) => _jsxs(Spaced, {
  vertically: true,
  amount: "space2",
  children: [_jsx(ToastTitle, {
    children: title
  }), _jsx(ToastText, {
    children: message
  })]
});
OfflineToast.displayName = "OfflineToast";
export const createAlert = ({
  kind,
  title,
  message
}) => {
  const show = () => {
    if (!hasToast(OFFLINE_ID)) {
      removeToast(ONLINE_ID);
      addToast({
        id: OFFLINE_ID,
        customIcon: 'Offline',
        kind: kind,
        Content: () => _jsx(OfflineToast, {
          title: title,
          message: message
        })
      });
    }
  };
  const hide = () => {
    if (hasToast(OFFLINE_ID)) {
      removeToast(OFFLINE_ID);
      addToast({
        id: ONLINE_ID,
        customIcon: 'CheckCircle',
        kind: 'info',
        timeout: 2500,
        Content: () => _jsx(ToastText, {
          children: "Connection restored."
        })
      });
    }
  };
  return {
    show,
    hide
  };
};
const OfflineAlert = createAlert({
  kind: 'alert',
  message: 'You are offline. You will not be able to receive real-time updates or save changes until your connection is restored.'
});
const WriteOfflineAlert = createAlert({
  kind: 'info',
  title: `You're Offline`,
  message: `We're trying to reconnect you now...`
});
export const showOfflineAlert = () => {
  const isWrite = getCurrentPage() === 'write';
  if (isWrite) WriteOfflineAlert.show();else OfflineAlert.show();
};
export const hideOfflineAlert = () => {
  OfflineAlert.hide();
};