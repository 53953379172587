import { usePlanStatus } from 'data/entity/paymentPlan2';
import { toMoney } from 'utils/format';
import { jsxs as _jsxs, Fragment as _Fragment, jsx as _jsx } from "@emotion/react/jsx-runtime";
export const CardStatuses = ({
  paymentPlan,
  organization,
  card
}) => {
  const deliquentStatus = usePlanStatus('delinquent', paymentPlan);
  const trialingStatus = usePlanStatus('trialing', paymentPlan);
  const availableFreeSeats = usePlanStatus('has_available_free_seats', paymentPlan);
  const hasAvailableFreeSeats = !!availableFreeSeats?.available_seats;
  const orgDisabledOrLocked = organization.locked_out || organization.disabled;
  const cardText = card ? 'updated card' : 'card';
  switch (true) {
    case !!deliquentStatus:
      return _jsxs(_Fragment, {
        children: ["Your card will be charged to cover your unpaid balance of", ' ', _jsxs("strong", {
          children: ["$", toMoney(deliquentStatus?.account_balance ?? 0), "."]
        })]
      });
    case !!trialingStatus:
      return hasAvailableFreeSeats ? `Your card will only be charged if use the remaining ${availableFreeSeats.available_seats} seats at the end of your trial.` : 'Your card will not be charged until the end of your trial.';
    case orgDisabledOrLocked:
      return _jsx(_Fragment, {
        children: "Your card will be charged immediately for the current plan."
      });
    case hasAvailableFreeSeats:
      return _jsxs(_Fragment, {
        children: ["Your ", cardText, " will be used for future charges, if you upgrade your Plan."]
      });
    default:
      return _jsxs(_Fragment, {
        children: ["Your ", cardText, " will be used for future charges. You will not be charged immediately."]
      });
  }
};