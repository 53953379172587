import EventEmitter from 'eventemitter3';
import { useCallback, useEffect, useState } from 'react';
import { PLAN_TABLE_TRIGGER_LOCATIONS } from 'components/settings/manage-billing/tracking';
const eventEmitter = new EventEmitter();
// triggerLocation is used to send an event to backend to track where plan selection is opened
const urlsToScreen = [{
  triggerLocation: PLAN_TABLE_TRIGGER_LOCATIONS.MILESTONE_CREATION,
  url: '/api/private/objectives'
}, {
  triggerLocation: PLAN_TABLE_TRIGGER_LOCATIONS.TEAM_CREATION,
  url: '/api/private/groups'
}, {
  triggerLocation: PLAN_TABLE_TRIGGER_LOCATIONS.WORKFLOW_CREATION,
  url: '/api/private/teams'
},
// Workspaces events are already being captured elsewhere
{
  url: '/api/private/workspace2'
}, {
  url: '/api/private/owner/workspaces2'
}];
export const entitlementViolationMiddleware = {
  id: 'entitlementViolationMiddleware',
  handler: ({
    url,
    response
  }) => {
    const resource = urlsToScreen.find(u => url.includes(u.url));
    if (resource) {
      if (response.status === 403 && response.data?.reason_tag === 'entitlement-violation') {
        response.message = response.data?.message;
        eventEmitter.emit('entitlementViolation', {
          ...response.data,
          ...(resource.triggerLocation ? {
            triggerLocation: resource.triggerLocation
          } : {})
        });
        return false;
      }
    }
    return true;
  }
};
export const useEntitlementViolationMessage = () => {
  const [message, setMessage] = useState(null);
  useEffect(() => {
    eventEmitter.addListener('entitlementViolation', setMessage);
    return () => {
      eventEmitter.removeListener('entitlementViolation', setMessage);
    };
  }, []);
  return {
    message,
    clear: useCallback(() => setMessage(null), [])
  };
};
export const triggerEntitlementViolation = violation => {
  eventEmitter.emit('entitlementViolation', {
    ...violation,
    triggerLocation: violation.triggerLocation ? violation.triggerLocation : PLAN_TABLE_TRIGGER_LOCATIONS.UNKNOWN
  });
};