import { getTokens } from '@useshortcut/shapes-ds';
import { useEffect, useState } from 'react';
import { emptyArray } from '../utils/emptyArray';
const breakpoints = getTokens().breakpoint;
const makeQuery = (min, max) => [...(!!min && !!breakpoints[min] ? [`(min-width: ${breakpoints[min].minimum}px)`] : emptyArray), ...(!!max && !!breakpoints[max] ? [`(max-width: ${breakpoints[max].maximum}px)`] : emptyArray)].join(' and ');
const runQuery = query => window.matchMedia(query);
const addListener = (mql, fn) => {
  if ('addEventListener' in mql) {
    mql.addEventListener('change', fn);
    return () => {
      mql.removeEventListener('change', fn);
    };
  } else {
    mql.addListener(fn);
    return () => {
      mql.removeListener(fn);
    };
  }
};
export function useBreakpoints({
  min,
  max
}) {
  const query = makeQuery(min, max);
  const [state, setState] = useState(() => runQuery(query).matches);
  useEffect(() => {
    let mounted = true;
    const mql = runQuery(query);
    const removeListener = addListener(mql, e => {
      if (mounted) setState(e.matches);
    });
    setState(mql.matches);
    return () => {
      mounted = false;
      removeListener();
    };
  }, [query]);
  return state;
}