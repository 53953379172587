import { QueryCurrentUserPermissionCohortDocument } from "../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { useLazyQuery } from 'gql';
import { useCallback } from 'react';
import { useWorkspaceSlug } from 'utils/navigation';
const QueryCurrentUserPermissionCohort = QueryCurrentUserPermissionCohortDocument;
export const useOnboardingCohort = () => {
  const slug = useWorkspaceSlug();
  const [query] = useLazyQuery(QueryCurrentUserPermissionCohort, {
    variables: {
      slug
    },
    fetchPolicy: 'cache-first'
  });
  return useCallback(async () => {
    const {
      data
    } = await query();
    return data?.workspace2?.currentUserPermission?.cohort;
  }, [query]);
};