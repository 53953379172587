import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const Down = ({
  fill,
  width,
  className
}) => _jsx("svg", {
  className: className,
  x: "0px",
  y: "0px",
  fill: fill,
  width: width,
  height: width,
  viewBox: "0 0 12 12",
  children: _jsx("path", {
    d: "M12,3.4c0,0.2-0.1,0.4-0.2,0.5L6.5,9.1C6.4,9.3,6.2,9.4,6,9.4c-0.2,0-0.4-0.1-0.5-0.2L0.2,3.9 C0.1,3.8,0,3.6,0,3.4S0.1,3,0.2,2.9c0.1-0.1,0.3-0.2,0.5-0.2h10.5c0.2,0,0.4,0.1,0.5,0.2C11.9,3,12,3.2,12,3.4z"
  })
});
Down.displayName = "Down";