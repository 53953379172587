import "core-js/modules/es.array.push.js";
import StoryModel from 'app/client/core/js/models/story.js';
window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || {
    Controller: {},
    Model: {}
  };
  [[['Model', 'Story'], StoryModel], [['Model', 'Story'], StoryModel]].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});
import { groupExternalLinksByType } from 'data/entity/externalLink';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const ExternalLink = ({
  external_link
}) => _jsxs("li", {
  "data-model": "Story",
  "data-id": external_link.id,
  children: [external_link.icon && _jsx("img", {
    src: external_link.icon,
    alt: "External Link Icon",
    className: external_link.invertForDarkMode ? 'invert-for-dark-mode' : ''
  }), !external_link.icon && _jsx("span", {
    className: "fa fa-link"
  }), _jsx("a", {
    href: external_link.url,
    target: "_blank",
    rel: "noopener noreferrer",
    children: external_link.url
  }), _jsx("span", {
    className: "fa fa-trash remove",
    "data-on-click": "removeExternalLink",
    "data-value": external_link.url,
    "data-tooltip": "Remove External Link"
  })]
}, external_link.id);
ExternalLink.displayName = "ExternalLink";
export function ExternalLinks({
  story,
  addExternalLink
}) {
  if (!story.external_links.length) return null;
  const externalLinkGroups = groupExternalLinksByType(story.external_links);
  return _jsxs("div", {
    className: "story-attachment",
    "data-type": "external-link",
    children: [_jsx("div", {
      className: "section-head",
      children: "External Links"
    }), _jsxs("div", {
      className: "external-links",
      children: [externalLinkGroups.map(([type, external_links], j) => _jsxs("div", {
        children: [_jsxs("h4", {
          children: [type, " ", _jsxs("span", {
            className: "external-link-count",
            children: ["(", external_links.length, ")"]
          })]
        }), _jsx("ul", {
          children: external_links.map((external_link, i) => _jsx(ExternalLink, {
            external_link: external_link
          }, i))
        })]
      }, j)), !externalLinkGroups.length && story.readOnly && _jsx("p", {
        className: "none-found",
        children: "No external links."
      })]
    }), _jsx("div", {
      className: "add-external-link-button",
      children: _jsxs("button", {
        className: "action micro flat-white add-story-link",
        onClick: addExternalLink,
        "data-tabindex": true,
        children: [_jsx("span", {
          className: "fa fa-plus"
        }), " Add External Link..."]
      })
    })]
  });
}
ExternalLinks.displayName = "ExternalLinks";