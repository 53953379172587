import "core-js/modules/es.array.push.js";
import AddNewStoryController from '../controllers/addNewStory';
import Backend from '../modules/backend';
import Collection from '../_frontloader/collection';
import Format from '../modules/format';
import Log from '../modules/log';
import StoryModel from './story';
import UserModel from './user';
import Utils from '../modules/utils';
import { getDefaultStateIdForWorkflow, getLastDoneStateForWorkflow } from 'data/entity/workflow';
import _ from 'lodash';
const exports = {};

/*

Example Task entity:

{
  complete: true
  completed_at: "2015-09-09T18:41:31Z"
  created_at: "2015-09-02T21:08:03Z"
  description: "Enable github webhook"
  id: 2676
  position: 0
  story_id: 2202
  updated_at: "2015-09-09T18:41:31Z"
  owner_ids: []
}

*/

Collection.create('Task', exports);
exports.normalize = task => {
  task.formatted_description = Format.formatWithLimitedMarkdown(task.description, 'task-description-normalize') || '<em class="ghost">Empty task</em>';
};
exports.isValid = task => {
  return task && task.id && _.isBoolean(task.complete);
};
exports.isNewStory = task => {
  return task ? !task.story_id : AddNewStoryController.isOpen();
};
exports.isOwner = (task, profile) => {
  return (task.owner_ids || []).some(id => `${id}` === `${profile.id}`);
};
exports.removeOwner = (task, profile, callback) => {
  exports.saveOwnerChange(_.assign({}, task, {
    owner_ids: _.without(task.owner_ids, profile.id)
  }), callback);
};
exports.addOwner = (task, profile, callback) => {
  const ids = _.isArray(task.owner_ids) ? task.owner_ids.slice(0) : [];
  ids.push(profile.id);
  exports.saveOwnerChange(_.assign({}, task, {
    owner_ids: ids
  }), callback);
};
exports.removeAllOwners = (task, callback) => {
  exports.saveOwnerChange(_.assign({}, task, {
    owner_ids: []
  }), callback);
};
exports.saveOwnerChange = (task, callback) => {
  exports.saveChanges(task, {
    owner_ids: task.owner_ids
  }, callback);
};
exports.getActiveTasksForUser = user => {
  const tasks = exports.filter(task => {
    if (task.complete === true) {
      return false;
    }
    if (!_taskBelongsToUser(task, user)) {
      return false;
    }
    const story = StoryModel.getById(task.story_id);
    if (!story) {
      return false;
    }
    const storyIsNotDone = !StoryModel.isDoneState(story);
    const storyIsNotArchived = !StoryModel.isArchived(story);
    return storyIsNotDone && storyIsNotArchived;
  });
  return _sortTasksByStoryPosition(tasks);
};
exports.getStartedStoryTasksForUser = user => {
  const tasks = exports.filter(task => {
    if (task.complete === true) {
      return false;
    }
    if (!_taskBelongsToUser(task, user)) {
      return false;
    }
    const story = StoryModel.getById(task.story_id);
    if (!story) {
      return false;
    }
    const storyIsStarted = StoryModel.isActiveState(story);
    const storyIsNotArchived = !StoryModel.isArchived(story);
    return storyIsStarted && storyIsNotArchived;
  });
  return _sortTasksByStoryPosition(tasks);
};
function _sortTasksByStoryPosition(tasks) {
  return _.sortBy(tasks, task => {
    const story = StoryModel.getById(task.story_id);
    return story.position;
  });
}
function _taskBelongsToUser(task, user) {
  const hasMention = _.includes(task.mention_ids, user.id);
  const userOwnsTask = _.includes(task.owner_ids, user.id);
  return hasMention || userOwnsTask;
}
exports.filterTasksInColumn = (tasks, column) => {
  return _.filter(tasks, task => {
    const story = StoryModel.getById(task.story_id);
    return StoryModel.isInColumn(story, column);
  });
};
exports.fetchMissingStoriesForTasks = callback => {
  const story_ids = [];
  exports.each(task => {
    if (task.story_id && !StoryModel.getById(task.story_id)) {
      story_ids.push(task.story_id);
    }
  });
  StoryModel.getStoriesById(story_ids, callback);
};
exports.updateNewStoryTasks = task => {
  let tasks = AddNewStoryController.getState().tasks;
  if (_.some(tasks, {
    id: task.id
  })) {
    tasks = _.reject(tasks, {
      id: task.id
    });
  }
  tasks.push(task);
  AddNewStoryController.updateState({
    tasks: _.sortBy(tasks, 'position')
  });
};
exports.removeNewStoryTask = task => {
  const tasks = AddNewStoryController.getState().tasks;
  const updatedTasks = _.reject(tasks, {
    id: task.id
  });
  AddNewStoryController.updateState({
    tasks: updatedTasks
  });
};
exports.fetchCurrentUserActiveTasks = callback => {
  callback = _.isFunction(callback) ? callback : _.noop;
  Backend.get('/api/private/permission/tasks', {
    onComplete: res => {
      exports.defaultFetchSomeHandler(res, callback);
    }
  });
};
exports.saveNew = (story, task, callback) => {
  callback = _.isFunction(callback) ? callback : _.noop;
  function saveNew(res) {
    res.position = story.tasks.length;
    story.tasks.push(res);
    exports.update(res);
    exports.trigger('newTaskCreated', exports.getById(res.id));
  }
  if (exports.isNewStory()) {
    saveNew(task);
    exports.updateNewStoryTasks(task);
    callback(null, task);
  } else {
    Backend.post('/api/private/stories/' + story.id + '/tasks', {
      data: task,
      onComplete: res => {
        if (_.get(res, 'error')) {
          Log.log('There was a problem saving the task.', {
            response: res,
            type: 'API'
          });
          StoryModel.fetchStory(task.story_id);
          callback(res.error);
        } else if (exports.isValid(res)) {
          saveNew(res);
          callback(null, res);
        }
      }
    });
  }
};
exports.saveChanges = (task, changes, callback) => {
  callback = _.isFunction(callback) ? callback : _.noop;
  if (exports.isNewStory(task)) {
    const updatedTask = _.assignIn({}, task, changes);
    exports.update(updatedTask);
    exports.updateNewStoryTasks(updatedTask);
    callback(false, exports.getById(task.id));
  } else {
    Backend.put('/api/private/stories/' + task.story_id + '/tasks/' + task.id, {
      data: changes,
      onComplete: res => {
        exports.defaultGetHandler(res, callback);
      }
    });
  }
};
exports.deleteTask = (task, callback, {
  withDelay = false
} = {}) => {
  const story = StoryModel.getById(task.story_id);
  callback = _.isFunction(callback) ? callback : _.noop;
  function performDeletion() {
    if (exports.isNewStory(task)) {
      const newStory = AddNewStoryController.getState();
      deleteTask(task, newStory);
      exports.removeNewStoryTask(task);
      callback();
    } else {
      Backend.delete('/api/private/stories/' + task.story_id + '/tasks/' + task.id, {
        onComplete: res => {
          if (res.error) {
            StoryModel.fetchStory(task.story_id);
            callback(res.error);
          } else {
            deleteTask(task);
            callback();
          }
        }
      });
    }
  }

  /* We need to delay the evaluation of the isNewStory() when we are creating a story from
   * a task & are deleting the task on the original(source) story.
   * *With no delay isNewStory returns true in this scenario which results in the task not being deleted on the source story.
   */
  if (withDelay) {
    setTimeout(performDeletion);
  } else {
    performDeletion();
  }
  function deleteTask(task, currentStory) {
    currentStory = currentStory || story;
    _.remove(currentStory.tasks, {
      id: task.id
    });
    exports.remove({
      id: task.id
    });
    exports.trigger('taskDeleted', task);
  }
};
exports.getTemporaryTasks = () => {
  return exports.filter(task => {
    return _.isString(task.id);
  });
};
exports.deleteTemporaryTasks = () => {
  exports.remove(task => {
    return _.isString(task.id);
  });
};
exports.toElement = task => {
  return $('.task[data-id="' + task.id + '"]');
};
exports.getStoryElement = task => {
  return StoryModel.getElements({
    id: task.story_id
  });
};
exports.getTaskIndexByID = id => {
  const task = exports.getById(id);
  const story = exports.isNewStory() ? AddNewStoryController.getState() : StoryModel.getById(task.story_id);
  return _.findIndex(story.tasks, {
    id
  });
};
exports.moveTask = (task, changes, callback) => {
  const story = exports.isNewStory(task) ? AddNewStoryController.getState() : StoryModel.getById(task.story_id);
  const oldIndex = exports.index({
    id: task.id
  });
  let newIndex = 0;
  if (changes.before_id) {
    newIndex = exports.getTaskIndexByID(changes.before_id);
  } else {
    newIndex = exports.getTaskIndexByID(changes.after_id);
  }
  if (story.tasks[oldIndex] && story.tasks[newIndex]) {
    Utils.arrayMove(story.tasks, oldIndex, newIndex);
  }
  exports.saveChanges(task, changes, callback);
};
exports.transformTaskToNewStoryState = (task, story) => {
  const {
    workflow_id,
    group_id
  } = story;
  const lastDoneState = getLastDoneStateForWorkflow(workflow_id).id;
  const defaultState = getDefaultStateIdForWorkflow(workflow_id);
  const newStory = StoryModel.transformToTemplate({
    name: task.description,
    description: 'This was originally a task in Story sc-' + story.id + '.',
    requested_by_id: UserModel.getLoggedInUserPermissionID(),
    project_id: story.project_id,
    story_links: [{
      subject_id: story.id,
      verb: 'relates to'
    }],
    epic_id: story.epic_id,
    owner_ids: task.owner_ids,
    story_type: 'feature',
    workflow_id,
    group_id,
    workflow_state_id: task.complete ? lastDoneState : defaultState,
    iteration_id: story.iteration_id
  });
  newStory.source_task_story_id = story.id;
  newStory.source_task_id = task.id;
  return newStory;
};
exports.transformTaskToNewStoryRequestParams = (task, story) => {
  const notRequiredKeys = ['project', 'workflow_id'];
  return _.omit(exports.transformTaskToNewStoryState(task, story), notRequiredKeys);
};
exports.createStoriesFromTasks = (story, callback) => {
  callback = _.isFunction(callback) ? callback : _.noop;
  StoryModel.createMultiple(_.map(story.tasks, task => {
    const {
      source_task_story_id,
      source_task_id,
      ...templateStory
    } = exports.transformTaskToNewStoryRequestParams(task, story);
    return templateStory;
  }), callback);
};
exports.resetTaskStatus = task => {
  task.complete = false;
};
export { exports as default };