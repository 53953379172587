import { IterationStoriesTable } from 'pages/iteration/IterationStoriesTable';
import * as Event from '../../../core/js/_frontloader/event';
import ExportData from '../../../core/js/modules/exportData';
import IterationModel from '../../../core/js/models/iteration';
import View from '../../../core/js/modules/view';
import * as GroupByUtils from 'utils/groupBy';
import StoryDialogController from 'app/client/core/js/controllers/storyDialog';
const exports = {};
const PARENT_SELECTOR = '#iteration-stories-section';
const getEventWithNs = eventName => `${eventName}.IterationPageStories`;
exports.init = () => {
  Event.onlyOn(getEventWithNs('pageDestroy'), () => {
    View.unmountDetachedComponents();
  });
};
exports.fetch = ({
  id
}) => {
  return IterationModel.Promises.fetchStoriesForIterations({
    iteration_ids: [id]
  });
};
exports.render = ({
  iteration
}) => {
  exports.filterKey = 'IterationPage.' + iteration.id + '.StoryFilter';
  View.renderComponent({
    containerSelector: PARENT_SELECTOR,
    component: IterationStoriesTable,
    props: {
      iterationId: iteration.id,
      onExportAsCsv: _exportIteration
    },
    componentKey: 'iteration-stories-table'
  });
};
const _exportIteration = () => {
  const iteration = IterationModel.getFromUrl();
  ExportData.start({
    iteration_id: iteration.id
  });
};
exports.openStoryDialog = function (...args) {
  GroupByUtils.storeGroupByForDialogs();
  return StoryDialogController.open.apply(this, args);
};
exports.exportIteration = e => {
  e.preventDefault();
  _exportIteration();
};
export { exports as default };