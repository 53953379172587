import { useCallback, useEffect } from 'react';
import { isRightClick } from '@clubhouse/shared/utils/mouse';
import { FloatingElement } from '../FloatingElement';
import { useActiveContextMenu } from './useActiveContextMenu';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const createRect = (x, y, w = 0, h = 0) => ({
  x,
  y,
  left: x,
  top: y,
  right: x + w,
  bottom: y + h,
  width: w,
  height: h
});
const FloatingMenu = ({
  trigger,
  children,
  onClose
}) => {
  useEffect(() => {
    const onMouseDown = e => {
      if (isRightClick(e)) {
        onClose();
      }
    };
    document.body.addEventListener('mousedown', onMouseDown, {
      capture: true
    });
    return () => {
      document.body.removeEventListener('mousedown', onMouseDown, {
        capture: true
      });
    };
  }, [onClose]);
  return _jsx(FloatingElement, {
    trigger: trigger,
    offsetMainAxis: 5,
    onClose: onClose,
    children: children
  });
};
FloatingMenu.displayName = "FloatingMenu";
export function ContextMenuRoot() {
  const {
    oy,
    ox,
    Menu,
    target,
    clickTarget,
    close,
    onEvent
  } = useActiveContextMenu();
  const trigger = useCallback(() => ({
    getBoundingClientRect() {
      const rect = target?.getBoundingClientRect();
      if (!rect) return createRect(0, 0);
      return createRect(rect.x + (ox ?? 0), rect.y + (oy ?? 0));
    },
    contextElement: target || undefined
  }), [target, ox, oy]);
  const handleClose = useCallback(() => {
    onEvent?.('close');
    close();
  }, [close, onEvent]);
  return Menu ? _jsx(FloatingMenu, {
    trigger: trigger,
    onClose: handleClose,
    children: _jsx(Menu, {
      rootElement: target,
      clickedElement: clickTarget
    })
  }) : null;
}