import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.set.difference.v2.js";
import "core-js/modules/esnext.set.intersection.v2.js";
import "core-js/modules/esnext.set.is-disjoint-from.v2.js";
import "core-js/modules/esnext.set.is-subset-of.v2.js";
import "core-js/modules/esnext.set.is-superset-of.v2.js";
import "core-js/modules/esnext.set.symmetric-difference.v2.js";
import "core-js/modules/esnext.set.union.v2.js";
export const mergeSpaceResultsConnectionsV1 = (existingConnection, incomingConnection, options) => {
  if (options.variables?.disableMerge) return incomingConnection;
  const readId = item => options.readField('id', item.node);
  if (!incomingConnection) return existingConnection;

  // See https://studio.apollographql.com/graph/Data-Layer-mfx43/variant/current/schema/reference/objects/SpaceResultsConnection#queryKey
  if (existingConnection?.queryKey !== incomingConnection.queryKey) {
    return incomingConnection;
  }
  const existingEdges = existingConnection?.edges;
  const incomingEdges = [...incomingConnection.edges];
  if (!existingEdges || existingEdges?.length === 0) return incomingConnection;
  const edges = incomingEdges.reduce((acc, incomingEdge, index) => {
    const matchingExistingEdge = existingEdges.find(edge => edge.groupId === incomingEdge.groupId);
    if (!matchingExistingEdge) {
      return acc;
    }
    const updatedEdges = [...matchingExistingEdge.edges];
    updatedEdges.splice(incomingEdge.pageInfo.offset, incomingEdge.edges.length, ...incomingEdge.edges);
    const slicedEdges = updatedEdges.slice(0, incomingEdge.pageInfo.totalSize);
    acc[index] = {
      ...incomingEdge,
      edges: slicedEdges,
      pageInfo: {
        ...incomingEdge.pageInfo,
        offset: Math.min(incomingEdge.pageInfo.offset, matchingExistingEdge.pageInfo.offset),
        limit: Math.max(slicedEdges.length, incomingEdge.pageInfo.limit)
      }
    };
    return acc;
  }, incomingEdges);
  const splicedExistingEdges = [...existingEdges];
  splicedExistingEdges.splice(incomingConnection.pageInfo.offset, edges.length, ...edges);
  const updatedConnection = {
    ...incomingConnection,
    edges: splicedExistingEdges.slice(0, incomingConnection.pageInfo.totalSize)
  };
  const updatedEdges = markOutOfSyncGroupsAndStoriesAsStale({
    existingConnection,
    updatedConnection,
    incomingConnection,
    readId
  });
  return {
    ...updatedConnection,
    edges: updatedEdges,
    pageInfo: {
      ...incomingConnection.pageInfo,
      offset: Math.min(incomingConnection.pageInfo.offset, existingConnection.pageInfo.offset),
      limit: Math.max(updatedEdges.length, incomingConnection.pageInfo.limit)
    }
  };
};
const markOutOfSyncGroupsAndStoriesAsStale = ({
  existingConnection,
  incomingConnection,
  updatedConnection,
  readId
}) => {
  const incomingGroupIds = new Set(incomingConnection.edges.map(state => state.groupId));
  const {
    offset,
    limit
  } = incomingConnection.pageInfo;
  const filteredWorkflowStates = markOutOfSyncItemsAsStale({
    existingList: existingConnection.edges,
    incomingList: incomingConnection.edges,
    updatedList: updatedConnection.edges,
    offset,
    limit,
    ids: incomingGroupIds,
    readId: state => state.groupId
  });
  const incomingStoryIds = new Set(incomingConnection.edges.flatMap(state => state.edges.map(edge => readId(edge))));
  return filteredWorkflowStates.map(state => {
    const incomingState = incomingConnection.edges.find(incomingState => incomingState.groupId === state.groupId);
    const {
      offset,
      limit
    } = incomingState?.pageInfo ?? {
      offset: Number.POSITIVE_INFINITY,
      limit: 0
    };
    const updatedEdges = markOutOfSyncItemsAsStale({
      existingList: existingConnection.edges.find(existingState => existingState.groupId === state.groupId)?.edges ?? [],
      incomingList: incomingState?.edges ?? [],
      updatedList: state.edges,
      offset,
      limit,
      ids: incomingStoryIds,
      readId
    });
    return {
      ...state,
      edges: updatedEdges,
      pageInfo: {
        ...state.pageInfo,
        limit: Math.max(updatedEdges.length, state.pageInfo.limit)
      }
    };
  });
};
const markOutOfSyncItemsAsStale = ({
  existingList,
  incomingList,
  updatedList,
  offset,
  limit,
  ids,
  readId
}) => {
  const isRangeInSync = existingList.slice(offset, offset + limit).every((edge, index) => {
    const id = readId(edge);
    const incomingEdge = incomingList[index];
    if (incomingEdge) {
      return id === readId(incomingEdge);
    }
    return true;
  });
  const hasDuplicateBelowRange = existingList.slice(offset + limit).some(edge => ids.has(readId(edge)));
  return updatedList.reduce((acc, edge, index) => {
    const isDuplicate = ids.has(readId(edge));
    if (index < offset) {
      acc.push({
        ...edge,
        isStale: edge.isStale || !isRangeInSync || isDuplicate
      });
    } else if (index < offset + limit) {
      acc.push({
        ...edge,
        isStale: false
      });
    } else {
      if (isRangeInSync && !hasDuplicateBelowRange) {
        acc.push({
          ...edge,
          isStale: false
        });
      }
    }
    return acc;
  }, []);
};