import "core-js/modules/es.array.push.js";
import * as EpicData from 'data/entity/epic';
import { addEpic, hasEpic, isNew, removeEpic } from 'data/entity/story';
import * as AddNew from 'utils/addNew';
import { formatAndSortEpicsWithCategory, formatEpicWithCategory } from '../../formatters/epic';
import { getCommandBar } from '../../getCommandBar';
import { isUserReadOnly } from '../../isUserReadOnly';
import { lazilyLoadArgumentChoices } from '../../lazilyLoadArgumentChoices';
export const addContextForStoryEpic = (story, themeName) => {
  lazilyLoadArgumentChoices('currentEpicForStory', () => getCurrentEpicForStory(story, themeName));
  lazilyLoadArgumentChoices('epicsForCurrentStory', () => getEpicsForCurrentStory(story, themeName));
  if (!isUserReadOnly()) {
    getCommandBar().addCallback('updateStoryEpic', updateStoryEpic(story));
  }
};
const getEpicsForCurrentStory = (story, themeName) => {
  if (!story) return [];
  const allEpics = EpicData.getAll();
  const epicsToSort = [];
  let currentEpic = null;
  allEpics.forEach(epic => {
    if (epic.id === story.epic_id) {
      currentEpic = epic;
    } else {
      epicsToSort.push(epic);
    }
  });
  return currentEpic ? [formatEpicWithCategory(currentEpic, themeName), ...formatAndSortEpicsWithCategory(epicsToSort, themeName)] : formatAndSortEpicsWithCategory(epicsToSort, themeName);
};
const getCurrentEpicForStory = (story, themeName) => {
  if (!story || !story.epic_id) return null;
  const epic = EpicData.getById(story.epic_id);
  if (!epic) return null;
  return formatEpicWithCategory(epic, themeName);
};
const updateStoryEpic = story => ({
  epic
}) => {
  if (!story) return;
  if (isNew(story)) {
    const newEpicId = story.epic_id !== epic.id ? epic.id : null;
    AddNew.updateStoryEpic(newEpicId);
  } else {
    if (hasEpic(story, epic)) {
      removeEpic(story);
    } else {
      addEpic(story, epic);
    }
  }
};