import noResultFoundImage from '@clubhouse/assets/png/no-results.png';
import { EmptyState } from '@clubhouse/shared/components/EmptyState';
import { getDisplayName } from 'data/typeConfigs';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const FilteredEmptyState = ({
  onClearFilters,
  entityDisplay,
  className,
  img,
  isArchived
}) => _jsx(EmptyState, {
  className: className,
  image: typeof img === 'string' ? _jsx("img", {
    src: img,
    alt: ""
  }) : img ? img : _jsx("img", {
    src: noResultFoundImage,
    alt: ""
  }),
  imageMaxHeight: 120,
  body: `No ${isArchived ? 'archived ' : ''}${getDisplayName({
    type: entityDisplay,
    isSingular: false
  })} found matching your filters.`,
  onClickCTA: onClearFilters,
  cta: "Clear filters"
});
FilteredEmptyState.displayName = "FilteredEmptyState";