import "core-js/modules/es.array.push.js";
import { generateAbbreviation } from '@clubhouse/shared/utils';
import { getTint } from '@clubhouse/shared/color';
import Async from '../modules/async';
import Backend from '../modules/backend';
import Collection from '../_frontloader/collection';
import Constants from '../modules/constants';
import FasterMoment from '../modules/fasterMoment';
import Format from '../modules/format';
import Globals from '../_frontloader/globals';
import Iterate from '../modules/iterate';
import ProfileModel from './profile';
import ProjectModel from './project';
import Router from '../_frontloader/router';
import StoryModel from './story';
import TeamModel from './team';
import Url from '../modules/url';
import Utils from '../modules/utils';
import WebhookModel from './webhook';
import WorkflowModel from './workflow';
import SpaceModel from './space';
import Dom from '../modules/dom';
import _ from 'lodash';
import moment from 'moment';
const exports = {
  Promises: {}
};

/*

Example Project entity:

{
  abbreviation: "API",
  archived: false,
  color: "#dd6e13",
  created_at: null,
  days_to_thermometer: 7,
  description: "Stories related to the API Server.",
  entity_type: "project",
  external_id: null,
  follower_ids: ["56d8a82b-088c-4333-9e39-c4746699776f", "56d8a82b-b408-4803-9e0c-ceb26d900a62",…],
  id: 274,
  name: "API",
  show_thermometer: true,
  start_time: "2014-09-19T19:39:08Z",
  team_id: 13575,
  updated_at: "2017-02-24T15:15:49Z"
}

*/

Collection.create('Project', exports);
Router.addPageTitle('project', url => {
  const path = Url.parsePathSkipCache(url);
  const project = exports.getById(path.project);
  const fallback = `Projects | ${BRAND.NAME}`;
  return project ? project.name + ' | ' + fallback : fallback;
});
exports.isValid = project => {
  return project && project.id && project.name;
};
exports.isArchived = project => {
  return project.archived === true;
};
exports.isActive = query => {
  const project = exports.get(query);
  return !!(project && project.active && !project.archived);
};
exports.hasProjects = () => {
  return exports.size() > 0;
};
exports.getFromUrl = url => {
  const path = Url.parsePath(url);
  return exports.get({
    id: path.project
  });
};

// "active" means is selected in the Stories page sidebar.
exports.allActive = () => {
  return exports.filter({
    active: true,
    archived: false
  });
};
exports.allNotArchivedInActiveTeam = () => {
  const team = TeamModel.getActive();
  if (team) {
    return exports.allNotArchivedInTeam(team);
  }
  return exports.allNotArchived();
};
exports.allNotArchivedInTeam = team => {
  return exports.filter({
    archived: false,
    team_id: team.id
  });
};
exports.allActiveInActiveTeam = () => {
  const team = TeamModel.getActive();
  if (!team) {
    return exports.allActive();
  }
  return exports.filter({
    active: true,
    archived: false,
    team_id: team.id
  });
};
exports.allNotArchived = () => {
  return exports.filter({
    archived: false
  });
};
exports.allArchived = () => {
  return exports.filter({
    archived: true
  });
};
exports.singleActive = () => {
  const projects = exports.allActive();
  return projects.length === 1 ? projects[0] : null;
};
exports.isFollower = (project, profile) => {
  return _.includes(project.follower_ids, profile.id);
};
exports.currentUserIsFollower = project => {
  const profile = ProfileModel.getCurrentUserProfileDetails();
  return exports.isFollower(project, profile);
};
exports.describeFollowers = project => {
  if (project.follower_ids.length === 0) {
    return '<em>Nobody</em>';
  }
  if (exports.currentUserIsFollower(project)) {
    if (project.follower_ids.length === 1) {
      return 'Just You';
    } else {
      return 'You +' + (project.follower_ids.length - 1);
    }
  } else {
    return project.follower_ids.length;
  }
};
exports.normalize = project => {
  project.active = !!project.active;
  project.lowercase_name = project.name.toLowerCase();
  project.color = Format.sanitizeColor(project.color, exports.getNextColor(project));
  project.url = exports.generateURL(project);
  if (!project.abbreviation) {
    project.abbreviation = generateAbbreviation(project.name).substr(0, 5);
  }
  exports.normalizeStats(project);
};
exports.normalizeDescription = project => {
  project.formatted_description = project.description ? Format.markdownify(project.description, 'project-description-normalize') : '<p><span class="ghost">No description given.</span></p>';
};
exports.sortByName = () => {
  exports.db.sort((a, b) => {
    if (a.lowercase_name > b.lowercase_name) {
      return 1;
    }
    if (a.lowercase_name < b.lowercase_name) {
      return -1;
    }
    return 0;
  });
};
exports.getWorkflow = project => {
  const team = TeamModel.getById(project.team_id);
  return WorkflowModel.getById(team.workflow.id);
};
exports.normalizeStats = project => {
  // Temporary until we remove top-level properties.
  if (project.stats) {
    Iterate.each(_.keys(project.stats), key => {
      project[key] = project.stats[key];
    });
  }
};
exports.generateURL = project => {
  return Url.getSlugPath() + '/project/' + project.id + '/' + Utils.slugify(project.name, {
    limit: 120
  });
};
exports.getCalendarLink = project => {
  return WebhookModel.getCalendarURL() + '/calendars/projects/' + project.id;
};
exports.getStoryCountForProjects = projects => {
  let storyCount = 0;
  Iterate.each(projects, project => {
    storyCount += exports.getStories(project).length;
  });
  return storyCount;
};
exports.hasAnyStoriesInWorkspace = () => {
  return exports.all().some(project => {
    return project.num_stories !== 0;
  });
};
exports.getStories = project => {
  return StoryModel.filter({
    project_id: project.id
  });
};
exports.getNextColor = project => {
  const index = exports.getById(project.id);
  const colorCount = Constants.PROJECT_COLORS.length;
  if (_.isNumber(index)) {
    return Constants.PROJECT_COLORS[index % colorCount];
  } else {
    const next = exports.all().length;
    return Constants.PROJECT_COLORS[next % colorCount];
  }
};
exports.getFilteredItemsForAutocomplete = workflowId => {
  const items = [{
    name: '<em>None</em>',
    value: null
  }];
  TeamModel.each(team => {
    if (team.workflow.id === Number(workflowId)) {
      _getItemsForTeam(team, items, {
        shouldShowHeader: false
      });
    }
  });
  return items;
};
exports.getItemsForAutocomplete = ({
  includeNone = false
} = {}) => {
  const items = includeNone ? [{
    name: '<em>None</em>',
    value: null
  }] : [];
  const activeTeam = TeamModel.getActive();
  _getItemsForTeam(activeTeam, items);
  TeamModel.each(team => {
    if (team.id !== activeTeam.id) {
      _getItemsForTeam(team, items);
    }
  });
  return items;
};
function _getItemsForTeam(team, items, options = {
  shouldShowHeader: true
}) {
  if (items.length > 0) {
    items.push({
      hr: true
    });
  }
  if (options.shouldShowHeader) {
    items.push({
      html: '<div class="caption">' + Format.sanitize(team.name) + ' Workflow</div>'
    });
  }
  const projects = exports.filter({
    team_id: team.id,
    archived: false
  });
  Iterate.each(projects, project => {
    items.push({
      name: '<span class="inline-swatch" style="background: ' + project.color + '"></span>' + Format.sanitizeAndEmojify(project.name),
      value: project.id
    });
  });
}
exports.fetchSingle = (id, callback) => {
  Backend.get('/api/private/projects/' + id, {
    onComplete: res => {
      exports.defaultGetHandler(res, callback);
    }
  });
};
exports.fetchAll = callback => {
  const url = '/api/private/projects';
  Backend.get(url, {
    onComplete: res => {
      exports.fetchAllHandler(res, callback);
    }
  });
};
exports.fetchAllHandler = (res, callback) => {
  callback = _.isFunction(callback) ? callback : _.noop;
  exports.defaultFetchSomeHandler(res);
  exports.sortByName();
  callback();
};
exports.fetchStoriesForActiveProjects = callback => {
  callback = _.isFunction(callback) ? callback : _.noop;
  Globals.set('fetchingAllStories', true);
  const projects = exports.allNotArchived();
  if (projects.length === 0) {
    callback();
    return false;
  }
  const fns = [];
  Iterate.each(projects, project => {
    fns.push(next => {
      Backend.get('/api/private/projects/' + project.id + '/unfinished-finished-stories', {
        onComplete: res => {
          StoryModel.defaultFetchSomeHandler(res, next);
        }
      });
    });
  });
  Async.eachInParallelThen(fns, () => {
    StoryModel.sortStoriesByPosition();
    callback();
  });
};
exports.fetchStoriesForProjects = (projects, callback) => {
  callback = _.isFunction(callback) ? callback : _.noop;
  if (projects.length === 0) {
    callback();
    return false;
  }
  StoryModel.searchStories({
    archived: false,
    project_ids: _.map(projects, 'id')
  }, callback);
};
exports.fetchStoriesForProject = (project, callback) => {
  exports.fetchStoriesForProjects([project], callback);
};
exports.fetchStoriesForProjectIncludingArchived = (project, callback) => {
  StoryModel.searchStories({
    project_ids: [project.id]
  }, callback);
};
exports.fetchCycleTimeStoriesForProject = (project, callback) => {
  const dateSince = moment().subtract(3, 'months').format();
  const query = {
    project_ids: [project.id],
    archived: false,
    workflow_state_types: ['done'],
    completed_at_start: dateSince
  };
  StoryModel.searchStories(query, () => {
    const stories = StoryModel.filter(story => {
      return story.project_id === project.id && story.archived === false && story.completed === true && FasterMoment.isMoreRecentThan(dateSince, story.completed_at);
    });
    callback(stories);
  });
};
exports.saveNew = (project, callback) => {
  Backend.post('/api/private/projects', {
    data: project,
    onComplete: res => {
      exports.defaultGetHandler(res, callback);
    }
  });
};
exports.saveChanges = (project, changes, callback) => {
  callback = _.isFunction(callback) ? callback : _.noop;
  Backend.put('/api/private/projects/' + project.id, {
    data: changes,
    onComplete: res => {
      exports.defaultGetHandler(res, callback, 'projectSaved');
    }
  });
};
exports.saveDaysUntilStale = (project, callback) => {
  callback = _.isFunction(callback) ? callback : _.noop;
  const data = {
    days_to_thermometer: project.days_to_thermometer
  };
  if (!project.show_thermometer) {
    _.assign(data, {
      show_thermometer: true
    });
  }
  exports.saveChanges(project, data, (err, res) => {
    callback(err, res);
  });
};
exports.toggleDaysUntilStale = (project, callback) => {
  callback = _.isFunction(callback) ? callback : _.noop;
  exports.saveChanges(project, {
    show_thermometer: !project.show_thermometer
  }, (err, res) => {
    callback(err, res);
  });
};
exports.saveFollowerIds = (project, callback) => {
  callback = _.isFunction(callback) ? callback : _.noop;
  exports.saveChanges(project, {
    follower_ids: project.follower_ids
  }, (err, res) => {
    if (!err) {
      exports.trigger('followersUpdated', res);
    }
    callback(err, res);
  });
};
exports.addFollower = (project, profile, callback) => {
  const ids = _.isArray(project.follower_ids) ? project.follower_ids.slice(0) : [];
  ids.push(profile.id);
  exports.saveFollowerIds(_.assign({}, project, {
    follower_ids: ids
  }), callback);
};
exports.removeFollower = (project, profile, callback) => {
  exports.saveFollowerIds(_.assign({}, project, {
    follower_ids: _.without(project.follower_ids, profile.id)
  }), callback);
};
exports.addMeAsFollower = (project, callback) => {
  _addOrRemoveMeAsFollower(project, 'follow', callback);
};
exports.removeMeAsFollower = (project, callback) => {
  _addOrRemoveMeAsFollower(project, 'unfollow', callback);
};

// This is a seperate from saveFollowerIds, as it's open to observers as well
function _addOrRemoveMeAsFollower(project, type, callback) {
  type = type === 'unfollow' ? 'unfollow' : 'follow';
  callback = _.isFunction(callback) ? callback : _.noop;
  Backend.post('/api/private/projects/' + project.id + '/' + type, {
    onComplete: res => {
      exports.defaultGetHandler(res, callback, 'projectSaved');
    }
  });
}
exports.archiveProject = (project, callback) => {
  exports.saveChanges(project, {
    archived: true
  }, callback);
};
exports.unarchiveProject = (project, callback) => {
  exports.saveChanges(project, {
    archived: false
  }, callback);
};
exports.deleteProject = (project, callback) => {
  Backend.delete('/api/private/projects/' + project.id, {
    onComplete: (res, xhr) => {
      exports.defaultDeleteHandler(project, res, xhr, callback);
    }
  });
};
exports.fuzzyMatch = str => {
  const matches = [];
  str = (str || '').toLowerCase();
  exports.each(project => {
    if (project.id === Number.parseInt(str, 10)) {
      matches.push(project);
    } else if ((project.name || '').toLowerCase().indexOf(str) === 0) {
      matches.push(project);
    }
  });
  return matches;
};
exports.setActiveProjectsForSpace = space => {
  const spaceProjectIds = space.data.Project;
  const initialProjects = ProjectModel.allNotArchivedInActiveTeam();
  const hasProjectsFiltered = !!spaceProjectIds.length;
  const hasProjectsSelectedForOtherTeam = SpaceModel.spaceDataRelatesToAnotherTeam(initialProjects, spaceProjectIds);
  const spaceDataIsIrrelevant = hasProjectsSelectedForOtherTeam && !hasProjectsFiltered;
  ProjectModel.each(project => {
    project.active = spaceDataIsIrrelevant || SpaceModel.isModelInSpace(space, 'Project', project);
  });
};
exports.Promises.saveNew = project => new Promise((resolve, reject) => {
  Backend.post('/api/private/projects', {
    data: project,
    onComplete: res => {
      exports.defaultGetHandler(res, (err, data) => {
        if (err) reject(err);
        resolve(data);
      });
    }
  });
});
exports.NO_PROJECT = {
  id: null,
  name: 'No Project',
  url: ''
};
exports.renderCSS = () => {
  let css = `
  .project.story-project-null {
    border-left-color: ${getTint('light-gray')};
  }\n`;
  exports.each(project => {
    if (project.name && project.color) {
      css += `
        .project.story-project-${project.id}{
          border-left-color: ${project.color}
        }`;
    }
  });
  if (css) {
    Dom.writeCSS('story-projects', css);
  }
};
export { exports as default };