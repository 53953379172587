import { DockedObjectiveFragmentDoc } from "../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { SizedIcon } from '@clubhouse/shared/components/SizedIcon';
import { getHref as getObjectiveHref } from 'components/gql/objective/links';
import { useObjectiveContextMenu } from 'utils/contextMenus/useObjectiveContextMenu';
import { DockedItemTemplate } from './shared/DockedItemTemplate';
import { Title } from './shared/Title';
import { useObjectivesEnabledForCurrentOrg } from 'data/entity/organization';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const DockedObjectiveFragment = DockedObjectiveFragmentDoc;
export const isObjective = node => {
  return node?.__typename === 'Objective';
};
export function DockedObjective({
  node,
  index,
  onUnpinClicked
}) {
  const url = getObjectiveHref(node.publicId, {
    name: node.objectiveName
  });
  const contextMenuProps = useObjectiveContextMenu(node.publicId);
  const isObjectivesEnabled = useObjectivesEnabledForCurrentOrg();
  if (!isObjectivesEnabled) return null;
  return _jsx(DockedItemTemplate, {
    id: node.id,
    index: index,
    onUnpinClicked: onUnpinClicked,
    icon: _jsx(SizedIcon, {
      icon: "Objectives",
      fill: "interactive",
      size: 14
    }),
    title: _jsx(Title, {
      url: url,
      children: node.objectiveName
    }),
    contextMenuProps: contextMenuProps
  });
}
DockedObjective.displayName = "DockedObjective";