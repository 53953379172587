// Order matters here. It should match designs; please do not sort
export const CompatibleIconSets = [{
  iconId: 'Heart'
}, {
  iconId: 'Bookmark',
  label: 'Bookmark placed on page'
}, {
  iconId: 'Folder',
  label: 'Tabbed Folder'
},
//default
{
  iconId: 'Star'
}, {
  iconId: 'Bookmark2',
  label: 'Bookmark'
}, {
  iconId: 'User',
  label: "Person's Face"
}, {
  iconId: 'Graph',
  label: 'Bar Graph'
}, {
  iconId: 'Hand',
  label: 'Raised Hand'
}, {
  iconId: 'Check',
  label: 'Checkmark'
}, {
  iconId: 'PieChart',
  label: 'Pie Chart'
}, {
  iconId: 'Cloud'
}, {
  iconId: 'Watch',
  label: 'Wristwatch'
}, {
  iconId: 'Art',
  label: 'Art Palette'
}, {
  iconId: 'Phone',
  label: 'Mobile Phone'
}, {
  iconId: 'ArrowLeft',
  label: 'Arrow pointing left'
}, {
  iconId: 'ArrowRight',
  label: 'Arrow pointing right'
},
// { iconId: 'Travel', label: 'Airplane' }, // TODO Add back in when icon is ready
{
  iconId: 'Person',
  label: 'Person with outstretched arms'
}, {
  iconId: 'Shield'
}, {
  iconId: 'Mail',
  label: 'Letter Envelope'
}, {
  iconId: 'Target'
}, {
  iconId: 'Dice',
  label: 'Dice face with 5 dots'
}, {
  iconId: 'Lock',
  label: 'Padlock'
}, {
  iconId: 'Flame'
}, {
  iconId: 'Book',
  label: 'Open-faced Book'
}, {
  iconId: 'Bolt'
}, {
  iconId: 'Play'
}, {
  iconId: 'Phone1',
  label: 'Phone Handset'
}, {
  iconId: 'Flower'
}, {
  iconId: 'Media',
  label: 'Film Clapperboard'
}, {
  iconId: 'Camera'
}, {
  iconId: 'Mountain'
},
// { iconId: 'Automotive', label: 'Car' }, // TODO Add back in when icon is ready
{
  iconId: 'Cup',
  label: 'Trophy Cup'
}, {
  iconId: 'ThumbsUp',
  label: 'Thumbs Up'
}, {
  iconId: 'ThumbsDown',
  label: 'Thumbs Down'
}, {
  iconId: 'A',
  label: 'Letter A'
}, {
  iconId: 'B',
  label: 'Letter B'
}, {
  iconId: 'C',
  label: 'Letter C'
}, {
  iconId: 'D',
  label: 'Letter D'
}, {
  iconId: 'E',
  label: 'Letter E'
}, {
  iconId: 'F',
  label: 'Letter F'
}, {
  iconId: 'One',
  label: 'Number 1'
}, {
  iconId: 'Two',
  label: 'Number 2'
}, {
  iconId: 'Three',
  label: 'Number 3'
}, {
  iconId: 'Four',
  label: 'Number 4'
}, {
  iconId: 'Five',
  label: 'Number 5'
}, {
  iconId: 'Six',
  label: 'Number 6'
}];
export function isCompatibleCustomFieldIconSet(iconSet) {
  return CompatibleIconSets.some(({
    iconId
  }) => iconSet === iconId);
}