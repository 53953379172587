import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import { getCurrentBackgroundLocationState } from 'utils/navigation/backgroundLocationState';
import { replaceState } from '../_frontloader/detangledRouter';
import Globals from '../_frontloader/globals';
const exports = {};
exports.extractHashFromRaw = (rawHashString = '') => decodeURI(rawHashString.replace(/^#/, ''));
exports.get = () => {
  return exports.extractHashFromRaw(window.location.hash);
};
exports.set = hash => {
  const url = new URL(window.location.href);
  url.hash = '#' + encodeURI(hash);
  replaceState(url.href, undefined, getCurrentBackgroundLocationState());
};
exports.getCached = () => {
  return Globals.get('hashCache');
};
exports.setCached = () => {
  Globals.set('hashCache', exports.get());
};
exports.clearCached = () => {
  Globals.set('hashCache', null);
};
export { exports as default };