import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const FlagOutlined = ({
  fill,
  width
}) => _jsx("svg", {
  fill: fill,
  width: width,
  height: width,
  "data-name": "Layer 15",
  viewBox: "0 0 32 32",
  children: _jsx("path", {
    d: "M7.05 5a2.5 2.5 0 1 0-3.5 2.29V28.5a1 1 0 0 0 2 0V7.29A2.5 2.5 0 0 0 7.05 5zm22.57 2L19 9.31v-3L8 8.75v14l9-2v3l12.4-2.5-2.72-6.72zM17 18.72l-7 1.55v-10l7-1.55v10zm2 2.58v-10l7.38-1.58-1.92 4.77 2.17 5.26z"
  })
});
FlagOutlined.displayName = "FlagOutlined";