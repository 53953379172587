import { StatelessTable } from '@clubhouse/shared/components/Table/StatelessTable';
import { useTableBulkSelection } from 'components/gql/bulkSelect/hooks/useTableBulkSelection';
import { LOADING_STATE } from 'components/gql/utils/Loading';
import { StaleLoading } from 'components/shared/StaleLoading';
import { CreateBacklogStoryTableAction } from './CreateBacklogStoryTableAction';
import { StoryRow, StoryRowForInfiniteScroll } from './StoryRow';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const BacklogTable = ({
  isLoading,
  isStale,
  stories,
  columns,
  sortColumn,
  sortDirection,
  onSort,
  onAddStory,
  footer,
  infiniteScroll
}) => {
  const [, getIsRowSelected] = useTableBulkSelection();
  return _jsxs(_Fragment, {
    children: [_jsx(StatelessTable, {
      allItems: stories,
      items: stories,
      columns: columns,
      fixedTopRow: _jsx(CreateBacklogStoryTableAction, {
        onClick: onAddStory
      }),
      getIsRowSelected: getIsRowSelected,
      sortColumn: sortColumn,
      sortDirection: sortDirection,
      onSort: onSort,
      RowComponent: infiniteScroll ? StoryRowForInfiniteScroll : StoryRow,
      footer: footer,
      ...(isLoading && LOADING_STATE)
    }), isStale && _jsx(StaleLoading, {})]
  });
};