import { closestAncestor } from '@clubhouse/shared/utils';
import { CollectionizeEpicContextMenu } from './useCollectionizeEpicContextMenu';
import { CollectionizeStoryContextMenu } from './useCollectionizeStoryContextMenu';
import { useDynamicContextMenuWithLogging } from './useContextMenuWithLogging';
import { IterationContextMenu } from './useIterationContextMenu';
import { ObjectiveContextMenu } from './useObjectiveContextMenu';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const getEntityTypeAndId = el => {
  const selector = `[data-model][data-id]`;
  let entityElement;
  if (el.matches(selector)) entityElement = el;else entityElement = closestAncestor(el, selector);
  if (!entityElement) return {};
  return {
    entityType: entityElement.dataset.model,
    entityId: entityElement.dataset.id
  };
};
const ContextMenusByEntityType = {
  Story: CollectionizeStoryContextMenu,
  Epic: CollectionizeEpicContextMenu,
  Milestone: ObjectiveContextMenu,
  Objective: ObjectiveContextMenu,
  Iteration: IterationContextMenu
};
const isSupportedEntityType = entityType => !!entityType && !!ContextMenusByEntityType[entityType];
export const getContextMenu = ({
  clickedElement
}) => {
  const {
    entityType,
    entityId
  } = getEntityTypeAndId(clickedElement);
  if (!entityId || !isSupportedEntityType(entityType)) return null;
  const ContextMenu = ContextMenusByEntityType[entityType];
  if (!ContextMenu) return null;
  return {
    Menu: () => _jsx(ContextMenu, {
      id: Number.parseInt(entityId)
    }),
    loggingContext: {
      entityType
    }
  };
};
export const useContextMenuForCaveman = () => {
  const {
    props
  } = useDynamicContextMenuWithLogging(getContextMenu);
  return props;
};