import { useCallback } from 'react';
export const getRelativePosition = ({
  oldIndex,
  newIndex,
  sortDirection
}) => {
  const isMovingAfterNewIndex = sortDirection === 'ascending' ? oldIndex < newIndex : oldIndex > newIndex;
  return isMovingAfterNewIndex ? 'after' : 'before';
};
export function getPositionChange({
  oldIndex,
  newIndex,
  entityList,
  sortDirection
}) {
  const position = getRelativePosition({
    oldIndex,
    newIndex,
    sortDirection
  });
  if (position === 'after') return {
    after_id: entityList[newIndex].id
  };else return {
    before_id: entityList[newIndex].id
  };
}
export function useOnChangePriority({
  save
}) {
  return useCallback(async params => {
    const {
      oldIndex,
      newIndex,
      entityList
    } = params;
    if (oldIndex === newIndex) return;
    const changes = getPositionChange(params);
    return save({
      entity: entityList[oldIndex],
      changes
    });
  }, [save]);
}