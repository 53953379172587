import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.set.difference.v2.js";
import "core-js/modules/esnext.set.intersection.v2.js";
import "core-js/modules/esnext.set.is-disjoint-from.v2.js";
import "core-js/modules/esnext.set.is-subset-of.v2.js";
import "core-js/modules/esnext.set.is-superset-of.v2.js";
import "core-js/modules/esnext.set.symmetric-difference.v2.js";
import "core-js/modules/esnext.set.union.v2.js";
import * as BulkEditorTemplate from 'app/client/core/views/templates/bulkEditor.html';
import * as DropdownProjectTemplate from 'app/client/core/views/templates/dropdownProject.html';
import * as DropdownStoryTypeTemplate from 'app/client/core/views/templates/dropdownStoryType.html';
import * as DropdownUserTemplate from 'app/client/core/views/templates/dropdownUser.html';
import * as DropdownOwnersTemplate from 'app/client/core/views/templates/dropdownOwners.html';
import * as DropdownStoryEpicTemplate from 'app/client/core/views/templates/dropdownStoryEpic.html';
import * as DropdownStoryEstimateTemplate from 'app/client/core/views/templates/dropdownStoryEstimate.html';
import * as StoryFollowerFooterTemplate from 'app/client/core/views/templates/storyFollowerFooter.html';
import * as LabelUpdateFooterTemplate from 'app/client/core/views/templates/labelUpdateFooter.html';
import * as BulkDeleteDialogTemplate from 'app/client/core/views/templates/bulkDeleteDialog.html';
import * as BulkDeleteDialogActionsTemplate from 'app/client/core/views/templates/bulkDeleteDialogActions.html';
import { AlertMessage } from '@clubhouse/shared/components/AlertMessage';
import { Text } from '@clubhouse/shared/components/Typography/Text';
import { getItemsForAutocomplete } from 'data/entity/column';
import AutocompleteController from './autocomplete';
import BaseUtils from '../_frontloader/baseUtils';
import BulkSelectionModel from '../models/bulkSelection';
import ColumnModel from '../models/column';
import Constants from '../modules/constants';
import Dialog from '../modules/dialog';
import DropdownController from './dropdown';
import EpicModel from '../models/epic';
import EstimateScaleModel from '../models/estimateScale';
import * as Event from '../_frontloader/event';
import Format from '../modules/format';
import Globals from '../_frontloader/globals';
import InlineMessageController from './inlineMessage';
import IterationModel from '../models/iteration';
import IterationModule from '../modules/iteration';
import LabelModel from '../models/label';
import MessageController from './message';
import PanelController from './panel';
import ProfileModel from '../models/profile';
import ProjectModel from '../models/project';
import StoryController from './story';
import StoryModel from '../models/story';
import Utils from '../modules/utils';
import View from '../modules/view';
import WorkflowModel from '../models/workflow';
import GroupModel from '../models/group';
import { BulkSelectGroupDropdown } from 'components/groups/BulkSelectGroupDropdown';
import { CustomFieldSelectList } from 'components/shared/custom-fields/CustomFieldSelectList';
import { WorkflowField } from 'components/shared/forms/fields/WorkflowField';
import _ from 'lodash';
import CustomField from '../models/customField';
import { BULK_SELECT_LIMIT } from 'components/shared/bulk-actions/BulkSelectAll';
import { getById as getWorkflowById } from 'data/entity/workflow';
import { isProjectsFeatureEnabled } from 'data/entity/feature';
import { areCustomFieldsEnabled } from 'data/entity/customField';
import moment from 'moment';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const exports = {};
const MULTIPLE_VALUES_TEXT = 'Multiple';
const DROPDOWN_WIDTH = 252;
let panel = null;
let state = {};
let initialState = {};
let deleteSelection = [];
let onUpdateTrackingMethod = _.noop;
exports._getState = () => {
  return state;
};
exports._clearState = () => {
  state = {};
  initialState = {};
};
exports.openOnTarget = (target, tracking) => {
  if (tracking) {
    onUpdateTrackingMethod = tracking.onUpdate;
  } else {
    onUpdateTrackingMethod = _.noop;
  }
  exports._clearState();
  const count = BulkSelectionModel.getSelectionCount();
  const isLimitReached = count === BULK_SELECT_LIMIT;
  exports._asyncInitialValues().then(initialValues => {
    initialState = initialValues;
    panel = PanelController.open({
      id: 'bulk-editor',
      html: BulkEditorTemplate.render({
        count,
        values: initialState,
        areCustomFieldsEnabled: areCustomFieldsEnabled(),
        isLimitReached,
        limit: BULK_SELECT_LIMIT
      }),
      target,
      width: 300
    });
    if (isLimitReached) {
      View.renderComponent({
        component: _jsx(AlertMessage, {
          size: AlertMessage.SIZE.SMALL,
          children: _jsx(Text, {
            size: Text.SIZE.SMALL,
            children: `The max number of stories selected (${BULK_SELECT_LIMIT}) has been reached.`
          })
        }),
        mountNode: document.querySelector('#bulk-edit-limit-reached')
      });
    }
    exports.renderCustomFields();
  });
};
exports.open = function () {
  exports.openOnTarget(this, {
    onUpdate: () => {}
  });
};
exports.close = () => {
  if (panel) {
    PanelController.close(panel);
    AutocompleteController.close();
    panel = null;
  }
  onUpdateTrackingMethod = _.noop;
};
exports._asyncInitialValues = () => BulkSelectionModel.getAndOptionallyFetchStoriesForEachSelection().then(() => exports._getInitialValues());
exports._getInitialValues = () => {
  const firstSelectedStory = _.head(BulkSelectionModel.getStoriesForEachSelection());
  if (!firstSelectedStory) return null;
  return {
    workflow: exports.isCommonValue('workflow_id') ? firstSelectedStory.workflow_id : 'is-multiple',
    project: exports._getInitialValue('project_id', firstSelectedStory.project, {
      color: '#c0c0c0',
      raw_name: MULTIPLE_VALUES_TEXT
    }),
    story_type: exports._getInitialValue('story_type', {
      story_type: firstSelectedStory.story_type,
      story_type_icon: firstSelectedStory.story_type_icon
    }, {
      story_type: MULTIPLE_VALUES_TEXT,
      story_type_icon: 'fa-question-circle-o'
    }),
    requested_by: exports._getInitialValue('requested_by_id', {
      id: firstSelectedStory.requested_by.id,
      name: firstSelectedStory.requested_by.name,
      user: firstSelectedStory.requested_by,
      type: 'Requester'
    }, {
      disabled: true,
      user: null,
      type: 'Requester',
      name: MULTIPLE_VALUES_TEXT
    }),
    owners: exports._getInitialValue('owner_ids', {
      owners: firstSelectedStory.owners
    }, {
      owners: exports.getUsersForDisplay('owners')
    }),
    epic: exports._getInitialValue('epic_id', {
      epic_id: firstSelectedStory.epic && firstSelectedStory.epic.id
    }, {
      epic: {
        raw_name: MULTIPLE_VALUES_TEXT
      }
    }),
    iteration: exports._getInitialValue('iteration_id', {
      iteration_id: firstSelectedStory.iteration_id
    }, {
      displayText: MULTIPLE_VALUES_TEXT
    }),
    group: exports.isCommonValue('group_id') ? firstSelectedStory.group_id : 'is-multiple',
    estimate: exports._getInitialValue('estimate', {
      estimate: firstSelectedStory.estimate
    }, {
      estimate_name: MULTIPLE_VALUES_TEXT
    }),
    deadline: exports._getInitialValue('deadline', {
      deadline: firstSelectedStory.deadline,
      formatted_deadline: firstSelectedStory.formatted_deadline || 'No date'
    }, {
      formatted_deadline: MULTIPLE_VALUES_TEXT
    }),
    workflow_state: exports._getInitialValue('workflow_state_id', {
      id: firstSelectedStory.workflow_state_id,
      name: StoryModel.getWorkflowStateName(firstSelectedStory),
      type: StoryModel.getWorkflowStateType(firstSelectedStory)
    }, {
      name: MULTIPLE_VALUES_TEXT
    }),
    followers: exports._getInitialValue('follower_ids', {
      followers: firstSelectedStory.followers,
      followers_value: StoryModel.describeFollowers(firstSelectedStory)
    }, {
      followers_value: MULTIPLE_VALUES_TEXT
    }),
    archived: exports._getInitialValue('archived', {
      archived: firstSelectedStory.archived,
      value: Utils.yesOrNo(firstSelectedStory.archived)
    }, {
      value: MULTIPLE_VALUES_TEXT
    })
  };
};

// ----- Actions -----

exports.toggleSelection = story => {
  if (BulkSelectionModel.isSelected(story)) {
    BulkSelectionModel.remove({
      id: story.id
    });
  } else {
    BulkSelectionModel.updateIfValid(story);
  }
};
exports.toggleTableSelection = function (e, entity) {
  const story = entity ?? Utils.getModelFromContext(this);
  exports.toggleSelection(story, {
    table: true
  });
  const lastToggledStoryId = Globals.get('lastToggledStoryId');
  if (e.shiftKey && lastToggledStoryId && lastToggledStoryId !== story.id) {
    // Need the extra existence check since we have some details tables not converted yet
    const isUsingReactTable = !$(`.story-tr.story-${story.id}`).length;
    if (isUsingReactTable) {
      const startingRow = $(`[data-id="${lastToggledStoryId}"].story`).closest('tr');
      const endingRow = $(`[data-id="${story.id}"].story`).closest('tr');
      const methodName = endingRow.index() > startingRow.index() ? 'nextUntil' : 'prevUntil';
      const isStartingStorySelected = BulkSelectionModel.isSelected(story);
      const betweenRows = startingRow[methodName](endingRow);
      betweenRows.each(function () {
        const id = $(this).find('.story').attr('data-id');
        const currentStory = StoryModel.getById(id);
        const isCurrentStorySelected = BulkSelectionModel.isSelected(currentStory);
        if (isStartingStorySelected !== isCurrentStorySelected) {
          exports.toggleSelection(currentStory, {
            table: true
          });
        }
      });
      const endingStory = StoryModel.getById(lastToggledStoryId);
      const isEndingStoryIsSelected = BulkSelectionModel.isSelected(endingStory);
      if (isStartingStorySelected !== isEndingStoryIsSelected) {
        exports.toggleSelection(endingStory, {
          table: true
        });
      }
    } else {
      exports.toggleRange(this, story);
    }
  }
  StoryModel.trigger('tableSelectionUpdated');
  Globals.set('lastToggledStoryId', story.id);
};
exports.toggleRange = (context, story) => {
  // .story-link for Dashboard, Team, and Stories pages
  // .story-tr for Epic and Search pages
  const storyElement = $(context).closest('.js-story-card, .story-tr');
  const storyIsSelected = BulkSelectionModel.isSelected(story);
  const storyElements = exports.getStoryElementsBetween(storyElement, Globals.get('lastToggledStoryId'));
  const lastStory = StoryModel.getById(Globals.get('lastToggledStoryId'));

  // We don't want to toggle the header button if this is a table.
  const toggleOptions = storyElement.is('tr') ? {
    table: true
  } : {};
  if (!storyElements || !lastStory) {
    return;
  }
  const lastStoryIsSelected = BulkSelectionModel.isSelected(lastStory);
  if (storyIsSelected && !lastStoryIsSelected || !storyIsSelected && lastStoryIsSelected) {
    exports.toggleSelection(lastStory, toggleOptions);
  }
  storyElements.each(function () {
    const story = Utils.getModelFromContext(this, 'Story');
    const otherStoryIsSelected = BulkSelectionModel.isSelected(story);
    if (storyIsSelected && !otherStoryIsSelected || !storyIsSelected && otherStoryIsSelected) {
      exports.toggleSelection(story, toggleOptions);
    }
  });
};
exports.getStoryElementsBetween = (startingElement, endingId) => {
  const endingElementClassName = !startingElement.is('tr') ? `[data-component-key="storyCard-${endingId}"]` : `.story-${endingId}`;
  const endingStoryElement = startingElement.siblings(endingElementClassName);
  if (endingStoryElement.length === 1) {
    const methodName = endingStoryElement.index() > startingElement.index() ? 'nextUntil' : 'prevUntil';
    return startingElement[methodName](endingElementClassName);
  }
};
exports.toggleAllTableSelection = function () {
  const toggle = $(this);
  if (toggle.hasClass('selected')) {
    BulkSelectionModel.flush();
  } else {
    let target = toggle.parents('.sortable-table');
    const selector = toggle.attr('data-table-selector');
    if (selector) {
      target = $(selector);
    }
    target.find('.id-column').each((i, el) => {
      const id = BaseUtils.toNumber($(el).text());
      BulkSelectionModel.update({
        id
      });
    });
  }
  StoryModel.trigger('tableSelectionUpdated');
};
function _updateAttributeContainer(element) {
  $(element).removeClass('unchanged').removeAttr('data-value');
}
exports.destroy = () => {
  BulkSelectionModel.removeEverything();
  exports._clearState();
};
exports.deselectAll = () => {
  BulkSelectionModel.removeEverything();
  StoryModel.trigger('tableSelectionUpdated');
  exports.close();
  exports._clearState();
  Globals.set('lastToggledStoryId', null);
  return false;
};
exports.isCommonValue = key => {
  const values = _.map(BulkSelectionModel.getStoriesForEachSelection(), key);
  const sample = _.head(values);
  if (_.isArray(sample)) {
    return _.every(values, arrayValues => {
      return _.isEqual(arrayValues.sort(), sample.sort());
    });
  } else {
    return _.uniq(values).length === 1;
  }
};
exports._getInitialValue = (key, singleValue, multipleValue) => {
  const isCommon = exports.isCommonValue(key);
  return _.assign(isCommon ? singleValue : multipleValue, {
    isMultiple: !isCommon
  });
};
exports._getSharedValue = element => {
  const sharedValue = Utils.data(element, 'value');
  return sharedValue ? JSON.parse(sharedValue) : null;
};
function _renderAndUpdateProject(container, project) {
  _updateAttributeContainer(container);
  container.html(DropdownProjectTemplate.render(project));
  state.project_id = project.id;
}
exports.updateProject = function () {
  const container = $(this);
  const selectedProject = ProjectModel.getById(state.project_id) || exports._getSharedValue(container);
  InlineMessageController.close();
  return AutocompleteController.open({
    items: state.workflow_id ? ProjectModel.getFilteredItemsForAutocomplete(state.workflow_id) : ProjectModel.getItemsForAutocomplete({
      includeNone: true
    }),
    onApply: id => {
      const project = ProjectModel.getById(id);
      if (project) {
        _renderAndUpdateProject(container, project);
      } else {
        _renderAndUpdateProject(container, {
          id: null
        });
      }
      Utils.deferFocus(container);
    },
    inputValue: selectedProject ? selectedProject.name : null,
    title: 'Select Project',
    value: selectedProject ? selectedProject.id : null,
    showInput: true,
    target: this,
    topOffset: 0,
    width: DROPDOWN_WIDTH
  });
};
exports.updateStoryType = function () {
  const container = $(this);
  const sharedValue = exports._getSharedValue(container);
  const mockStory = {
    story_type: state.story_type || sharedValue && sharedValue.story_type || 'feature'
  };
  InlineMessageController.close();
  return DropdownController.open({
    items: Utils.getStoryTypes(mockStory),
    title: 'Update Story Type',
    onApply: function (value) {
      _updateAttributeContainer(container);
      container.html(DropdownStoryTypeTemplate.render({
        story_type: value
      }));
      state.story_type = value === 'none' ? null : value;
      exports.renderCustomFields();
      Utils.deferFocus(this);
    },
    target: this,
    topOffset: 0,
    width: DROPDOWN_WIDTH
  });
};
exports.updateRequester = function () {
  const container = $(this);
  const requester = ProfileModel.getAllDetailsById(state.requested_by_id) || exports._getSharedValue(container) || {};
  InlineMessageController.close();
  return AutocompleteController.open({
    items: ProfileModel.getItemsForRequesterAutocomplete(),
    onApply: id => {
      const profile = ProfileModel.getAllDetailsById(id);

      // Guard against non-matching input
      if (!profile) {
        return false;
      }
      _updateAttributeContainer(container);
      container.html(DropdownUserTemplate.render({
        user: profile,
        type: 'Requester'
      }));
      state.requested_by_id = profile.id;
      Utils.deferFocus(container);
    },
    inputValue: requester.name,
    value: requester.id,
    showInput: true,
    noActive: !requester.id,
    title: 'Select Story Requester',
    target: this,
    topOffset: 0,
    width: DROPDOWN_WIDTH
  });
};
exports.getStoryIDsForEntity = (entity, identifier) => {
  const stories = BulkSelectionModel.getStoriesForEachSelection();
  return _.reduce(stories, (map, story) => {
    _.each(story[entity], item => {
      const key = item[identifier] || item.id;
      if (_.isArray(map[key])) {
        map[key].push(story.id);
      } else {
        map[key] = [story.id];
      }
    });
    return map;
  }, {});
};
exports.getUsersForDisplay = entityType => {
  const storiesForEachType = exports.getStoryIDsForEntity(entityType);
  const totalSelection = BulkSelectionModel.size();
  return _.compact(_.map(ProfileModel.getAllProfileDetails(), profile => {
    const count = storiesForEachType[profile.id] ? storiesForEachType[profile.id].length : 0;
    if (count) {
      const profileCopy = _.clone(profile);
      if (count !== totalSelection) {
        profileCopy.multiple = true;
        profileCopy.className = 'multiple-selected';
        profileCopy.iconRight = 'fa-minus-square';
      }
      return profileCopy;
    }
  }));
};
exports.getMixedUsers = (entityType, addedUserIDs, removedUserIDs) => {
  const addedUsers = ProfileModel.mapIDsToProfileDetails(addedUserIDs);
  const mixedUsers = _.reject(exports.getUsersForDisplay(entityType), user => {
    return _.includes(removedUserIDs.concat(addedUserIDs), user.id);
  });
  return {
    users: _.uniqBy(mixedUsers.concat(addedUsers), 'id'),
    isMixed: !!_.filter(mixedUsers, user => {
      return user.multiple;
    }).length
  };
};
exports.updateOwners = function () {
  const container = $(this);
  const removedOwnerIDs = state.owner_ids_remove = state.owner_ids_remove || [];
  const savedOwnerIDs = state.owner_ids_add = state.owner_ids_add || [];
  const mixedUserData = exports.getMixedUsers('owners', savedOwnerIDs, removedOwnerIDs);
  let owners = mixedUserData.users;
  const options = {
    items: ProfileModel.getItemsForOwnerAutocomplete({
      owners
    }, owners, {
      isOwner: (entity, profile) => _.some(entity.owners, profile)
    }),
    multiSelect: true,
    onApply: profile => {
      // Guard against non-matching input
      if (profile && !ProfileModel.isValid(profile)) {
        return false;
      }
      if (!profile) {
        owners = [];
        state.owner_ids_add = [];
        state.owner_ids_remove = _.map(ProfileModel.getAllProfileDetails(), 'id');
      } else if (_.some(owners, profile)) {
        _.remove(owners, {
          id: profile.id
        });
        _.pull(state.owner_ids_add, profile.id);
        state.owner_ids_remove.push(profile.id);
      } else {
        owners.push(profile);
        state.owner_ids_add.push(profile.id);
        _.pull(state.owner_ids_remove, profile.id);
      }
      const updatedOwners = exports.getMixedUsers('owners', state.owner_ids_add, state.owner_ids_remove).users;
      _updateAttributeContainer(container);
      container.html(DropdownOwnersTemplate.render({
        owners: updatedOwners
      }));
      Utils.deferFocus(container);
    },
    noActive: true,
    title: 'Select Story Owners',
    showInput: true,
    target: this,
    topOffset: 0,
    width: DROPDOWN_WIDTH
  };
  if (mixedUserData.isMixed) {
    options.footer = '<div class="bulk-note note">* An owner in only some stories.</div>';
  }
  InlineMessageController.close();
  return AutocompleteController.open(options);
};
exports.updateEpic = function () {
  const container = $(this);
  const selectedGroupId = (() => {
    const id = state.group_id ?? initialState.group;
    if (id === 'is-multiple') return null;
    return id ?? null;
  })();
  const sharedValue = exports._getSharedValue(container);
  const items = EpicModel.getItemsForAutocomplete({
    selectedGroupId
  });
  const initialID = state.epic_id || sharedValue && sharedValue.epic_id || sharedValue && sharedValue.epic && sharedValue.epic.id || null;
  const inputValue = (EpicModel.getById(initialID) || {}).name;
  if (!inputValue) {
    state.epic_id = null;
  }
  InlineMessageController.close();
  return AutocompleteController.open({
    items,
    onApply: id => {
      if (id) {
        const epic = EpicModel.getById(id);
        if (!epic) {
          EpicModel.saveNew({
            name: id
          }, (err, epic) => {
            if (err) {
              MessageController.error(err, {
                secondary: 'Unable to create a new epic.'
              });
            }
            if (epic) {
              container.html(DropdownStoryEpicTemplate.render({
                epic_id: epic.id
              }));
              state.epic_id = epic.id;
              Utils.deferFocus(container);
            }
          });
          return false;
        } else {
          container.html(DropdownStoryEpicTemplate.render({
            epic_id: epic.id
          }));
          state.epic_id = epic.id;
        }
      } else {
        container.html(DropdownStoryEpicTemplate.render());
        state.epic_id = null;
      }
      _updateAttributeContainer(container);
      Utils.deferFocus(container);
    },
    inputValue,
    value: initialID,
    showInput: true,
    title: 'Select or Create an Epic',
    onResultsUpdate: ({
      value,
      setNoResults,
      hasResults
    }) => {
      if (!hasResults) {
        setNoResults('Enter to create new epic <strong>' + Format.sanitize(value) + '</strong>');
      }
    },
    target: this,
    topOffset: 0,
    width: DROPDOWN_WIDTH
  });
};
exports.updateIteration = function () {
  const context = $(this);
  const sharedValue = exports._getSharedValue(context);
  const initialID = state.iteration_id || sharedValue && sharedValue.iteration_id || null;
  const inputValue = (IterationModel.getById(initialID) || {}).name;
  if (!inputValue) {
    state.iteration_id = null;
  }
  InlineMessageController.close();
  const getState = exports._getState;
  const setValue = id => {
    return new Promise(resolve => {
      _updateAttributeContainer(context);
      Utils.deferFocus(context);
      state.iteration_id = id;
      return resolve({
        iteration_id: id
      });
    });
  };
  return AutocompleteController.open({
    ...IterationModule.getIterationPickerConfig({
      getState,
      setValue,
      context,
      initialGroupId: initialState.group
    })
  });
};
exports.updateWorkflow = newWorkflowId => {
  state.workflow_id = newWorkflowId;
  if (state.project_id) {
    const projectWorkflow = ProjectModel.getWorkflow(ProjectModel.getById(state.project_id));
    if (projectWorkflow.id !== state.workflow_id) {
      const projectContainer = $('.attribute.story-project');
      _updateAttributeContainer(projectContainer);
      projectContainer.html(DropdownProjectTemplate.render({
        id: null
      }));
      state.project_id = null;
    }
  }
};
exports.renderWorkflowField = ({
  workflowId
}) => {
  const isMultiple = workflowId === 'is-multiple';
  const hasChanged = state.workflow_id !== undefined;
  const group = GroupModel.getById(state.group_id);
  let permittedWorkflowIds = group ? group.workflow_ids : _getPermittedWorkflowIdsFromSelection();

  // There are no Workflow restrictions when the Team is None
  if (state.group_id === null) {
    permittedWorkflowIds = undefined;
  }
  const isDisabled = Boolean(permittedWorkflowIds?.length === 0);
  return View.renderComponentDelayed({
    componentKey: 'bulkModifyWorkflowDropdown',
    cssClass: 'bulk-workflow-dropdown',
    component: WorkflowField,
    props: {
      label: 'Select Workflow',
      isDisabled,
      permittedWorkflowIds,
      targetProps: {
        name: isMultiple ? MULTIPLE_VALUES_TEXT : undefined,
        isTouched: !isMultiple && hasChanged,
        isDisabled
      },
      input: {
        value: !isMultiple ? [workflowId] : [],
        onChange: ([selectedId = null] = []) => {
          const newWorkflowId = Number(selectedId);
          exports.updateWorkflow(newWorkflowId);
          exports.renderWorkflowField({
            workflowId: newWorkflowId
          });
        }
      }
    }
  }).html;
};
exports.renderGroupDropdown = ({
  selectedGroupId
}) => {
  return View.renderComponentDelayed({
    componentKey: 'bulkEditorDialogGroupDropdown',
    component: BulkSelectGroupDropdown,
    props: {
      selectedGroupId,
      isMultiple: selectedGroupId === 'is-multiple',
      hasChanged: state.group_id !== undefined,
      onChange: group => {
        state.group_id = group ? group.id : null;
        exports.renderGroupDropdown({
          selectedGroupId: state.group_id
        });
        exports.renderWorkflowField({
          workflowId: state.workflow_id ?? initialState.workflow
        });
      }
    }
  }).html;
};
const isTouched = fieldId => {
  const state = exports._getState();
  return Boolean(state?.custom_fields_add?.[fieldId]) || Boolean(state?.custom_fields_remove?.[fieldId]);
};
exports.renderCustomFields = () => {
  if (!areCustomFieldsEnabled()) {
    return false;
  }
  const stories = BulkSelectionModel.getStoriesForEachSelection();
  const enabledFields = CustomField.getEnabledFields();
  const state = exports._getState();
  state.custom_fields_add = state.custom_fields_add ?? {};
  state.custom_fields_remove = state.custom_fields_remove ?? {};
  const fieldValuesOnStories = {};
  enabledFields.forEach(({
    id
  }) => fieldValuesOnStories[id] = {
    uniqueValues: new Set(),
    // stringified field object
    numStories: 0 // number of Stories that have a value in this field
  });
  stories.forEach(story => story.custom_fields.forEach(field => {
    fieldValuesOnStories[field.field_id].uniqueValues.add(JSON.stringify(field));
    fieldValuesOnStories[field.field_id].numStories++;
  }));
  const bulkValues = [];
  enabledFields.forEach(({
    id: field_id
  }) => {
    const {
      uniqueValues,
      numStories
    } = fieldValuesOnStories[field_id];
    if (uniqueValues.size === 1 && numStories === stories.length) {
      // Single non-None value for all Stories
      const [value] = uniqueValues;
      bulkValues.push(JSON.parse(value));
    } else if (uniqueValues.size !== 0) {
      // Multiple values for all Stories
      bulkValues.push({
        field_id,
        value_id: 'multiple',
        value: 'Multiple'
      });
    }
  });
  View.renderComponent({
    mountNode: document.getElementById('bulk-custom-fields-container'),
    component: CustomFieldSelectList,
    props: {
      storyCustomFields: bulkValues,
      storyType: state.story_type ?? initialState.story_type.story_type,
      isTouched,
      onChange: (field_id, value_id) => {
        // Bulk request expects different format for custom fields. See _cleanupState.
        if (value_id) {
          delete state.custom_fields_remove[field_id];
          state.custom_fields_add[field_id] = [{
            field_id,
            value_id
          }];
        } else {
          delete state.custom_fields_add[field_id];
          state.custom_fields_remove[field_id] = fieldValuesOnStories[field_id].uniqueValues;
        }
      }
    }
  });
};
exports.updateEstimate = function () {
  const container = $(this);
  const count = BulkSelectionModel.getSelectionCount();
  const sharedValue = exports._getSharedValue(container);
  const activeEstimate = _.isNumber(state.estimate) ? state.estimate : _.isNumber(_.get(sharedValue, 'estimate')) ? _.get(sharedValue, 'estimate') : null;
  const items = [{
    className: activeEstimate === null ? 'active' : '',
    name: '<em>Unestimated</em>',
    value: null
  }];
  EstimateScaleModel.getPointScale().forEach(point => {
    point = BaseUtils.toNumber(point);
    items.push({
      className: activeEstimate === point ? 'active' : '',
      name: Format.pluralize(point, 'point', 'points'),
      value: point
    });
  });
  InlineMessageController.close();
  return DropdownController.open({
    items,
    title: 'Estimate ' + Format.pluralize(count, 'Story', 'Stories'),
    onApply: function (value) {
      _updateAttributeContainer(container);
      container.html(DropdownStoryEstimateTemplate.render({
        estimate: value
      }));
      state.estimate = value;
      Utils.deferFocus(this);
    },
    target: this,
    topOffset: 0,
    width: DROPDOWN_WIDTH
  });
};
exports.updateDeadline = function () {
  const state = exports._getState();
  const container = $(this);
  const sharedValues = exports._getSharedValue(container);
  const savedValues = {
    deadline: state.deadline,
    formatted_deadline: _formatDeadline(state.deadline)
  };
  const fallbackValues = {
    deadline: new Date(Date.now()),
    formatted_deadline: _formatDeadline(Date.now())
  };
  InlineMessageController.close();
  StoryController.openDeadlineDatepicker({
    context: container,
    story: state.deadline ? savedValues : sharedValues || fallbackValues,
    callback: deadline => {
      const formatted = _formatDeadline(deadline);
      _updateAttributeContainer(container);
      container.find('.value').html(formatted || '<em>No date</em>');
      $('#due-date-clear-button')[deadline ? 'show' : 'hide']();
      state.deadline = deadline;
    }
  });
  return false;
};
function _formatDeadline(deadline) {
  return deadline ? moment(deadline).format(Constants.SHORT_DATE_FORMAT) : null;
}
exports.clearDeadline = () => {
  const container = $('.bulk-editor-content .story-deadline');
  const state = exports._getState();
  _updateAttributeContainer(container);
  container.find('.value').html('<em>No date</em>');
  $('#due-date-clear-button').hide();
  state.deadline = null;
  return false;
};
exports.updateWorkflowState = function () {
  const state = exports._getState();
  const container = $(this);

  // This can be null if the user selects stories from different teams.
  const columnFromContainer = exports._getSharedValue(container);
  let column = null;
  if (state.workflow_state_id) {
    column = ColumnModel.getById(state.workflow_state_id);
  } else if (columnFromContainer) {
    column = ColumnModel.getById(columnFromContainer.id);
  } else if (state.project_id) {
    column = _.head(ColumnModel.allFromWorkflow(ProjectModel.getWorkflow(ProjectModel.getById(state.project_id))));
  } else if (_selectedStoriesAreFromTheSameTeam()) {
    column = _firstWorkflowStateFromSelectedStories();
  } else if (state.workflow_id) {
    column = _.head(ColumnModel.allFromWorkflow(WorkflowModel.getById(state.workflow_id)));
  } else {
    InlineMessageController.open({
      html: "<strong>You've selected Stories from different Workflows.</strong> " + `Select which ${isProjectsFeatureEnabled() ? 'Project or Workflow' : 'Workflow'} you would like these Stories to be moved to, then select a state.`,
      id: 'workflow-state-alert',
      target: '.bulk-editor-content'
    });
    return false;
  }
  let workflow;
  if (state.workflow_id) {
    workflow = WorkflowModel.getById(state.workflow_id);
  } else if (state.project_id) {
    workflow = ProjectModel.getWorkflow(ProjectModel.getById(state.project_id));
  } else {
    workflow = WorkflowModel.getById(column.workflow_id);
  }
  return AutocompleteController.open({
    items: getItemsForAutocomplete({
      workflow
    }),
    onApply: id => {
      // Fallback to first column if selected column has been deleted.
      const selectedColumn = ColumnModel.getById(id) || ColumnModel.firstFromActiveWorkflow();
      _updateAttributeContainer(container);
      container.find('.left-icon').children().replaceWith(ColumnModel.renderStateIconFromStateType(selectedColumn.type));
      container.find('.value').text(selectedColumn.name);
      state.workflow_state_id = selectedColumn.id;
      Utils.deferFocus(container);
    },
    inputValue: column.name,
    value: column.id,
    title: 'Select State',
    showInput: true,
    target: this,
    topOffset: 0,
    width: DROPDOWN_WIDTH
  });
};

// Here 'Team' refers to 'Workflows' in the UI
function _selectedStoriesAreFromTheSameTeam() {
  const stories = BulkSelectionModel.getStoriesForEachSelection();
  const team_ids = _.uniq(stories.map(story => story.workflow_id ? getWorkflowById(story.workflow_id).team_id : undefined).filter(Boolean));
  return team_ids.length === 1;
}
function _firstWorkflowStateFromSelectedStories() {
  const stories = BulkSelectionModel.getStoriesForEachSelection();
  return ColumnModel.getById(_.head(stories).workflow_state_id);
}
exports.updateFollowers = function () {
  const state = exports._getState();
  const container = $(this);
  const removedFollowerIDs = state.follower_ids_remove = state.follower_ids_remove || [];
  const savedFollowerIDs = state.follower_ids_add = state.follower_ids_add || [];
  const mixedUserData = exports.getMixedUsers('followers', savedFollowerIDs, removedFollowerIDs);
  const followers = mixedUserData.users;
  const options = {
    items: ProfileModel.getItemsForFollowerAutocomplete({
      followers
    }, {
      isFollower: (entity, profile) => _.some(entity.followers, profile)
    }),
    multiSelect: true,
    footer: StoryFollowerFooterTemplate.render(),
    onApply: profile => {
      // Guard against non-matching input
      if (profile && !ProfileModel.isValid(profile)) {
        return false;
      }
      if (_.some(followers, profile)) {
        _.remove(followers, {
          id: profile.id
        });
        _.pull(state.follower_ids_add, profile.id);
        state.follower_ids_remove.push(profile.id);
      } else {
        followers.push(profile);
        state.follower_ids_add.push(profile.id);
        _.pull(state.follower_ids_remove, profile.id);
      }
      const isMixed = exports.getMixedUsers('followers', state.follower_ids_add, state.follower_ids_remove).isMixed;
      const updatedFollowers = isMixed ? MULTIPLE_VALUES_TEXT : StoryModel.describeFollowers({
        follower_ids: _.union(state.follower_ids_add, _.map(followers, 'id'))
      });
      _updateAttributeContainer(container);
      container.find('.value').html(updatedFollowers);
      Utils.deferFocus(container);
    },
    noActive: true,
    title: 'Select Story Followers',
    showInput: true,
    target: this,
    topOffset: 0,
    width: DROPDOWN_WIDTH
  };
  if (mixedUserData.isMixed) {
    options.footer = '<div class="bulk-note note">* A follower in only some stories.</div>';
  }
  InlineMessageController.close();
  return AutocompleteController.open(options);
};
exports.updateArchived = function () {
  const state = exports._getState();
  const container = $(this);
  const sharedValue = exports._getSharedValue(container);
  const count = BulkSelectionModel.getSelectionCount();
  const archived = state.archived === true || sharedValue && sharedValue.archived === true || false;
  InlineMessageController.close();
  return DropdownController.open({
    items: [{
      className: archived ? 'active' : '',
      name: 'Yes',
      iconLeft: 'fa-check',
      value: true
    }, {
      className: archived ? '' : 'active',
      name: 'No',
      iconLeft: 'fa-times',
      value: false
    }],
    onApply: value => {
      _updateAttributeContainer(container);
      container.find('.value').text(Utils.yesOrNo(value));
      state.archived = value;
      Utils.deferFocus(container);
    },
    title: 'Archive ' + Format.pluralize(count, 'Story', 'Stories'),
    target: this,
    topOffset: 0,
    width: DROPDOWN_WIDTH
  });
};
exports.getLabelsForDisplay = () => {
  const storiesForEachType = exports.getStoryIDsForEntity('labels', 'name');
  const totalSelection = BulkSelectionModel.size();
  return _.compact(_.map(LabelModel.all(), label => {
    const count = storiesForEachType[label.name] ? storiesForEachType[label.name].length : 0;
    if (count) {
      const labelCopy = _.clone(label);
      if (count !== totalSelection) {
        labelCopy.multiple = true;
        labelCopy.className = 'multiple-selected';
        labelCopy.iconRight = 'fa-minus-square';
      } else {
        labelCopy.multiple = false;
      }
      return labelCopy;
    }
  }));
};
exports.getMixedLabels = (addedLabels, removedLabels) => {
  const mixedLabels = _.reject(exports.getLabelsForDisplay(), label => {
    return _.some(removedLabels.concat(addedLabels), {
      name: label.name
    });
  });
  return _.uniqBy(mixedLabels.concat(addedLabels), 'name');
};
exports.updateLabels = function () {
  const state = exports._getState();
  const container = $(this);
  const removedLabels = state.labels_remove = state.labels_remove || [];
  const savedLabels = state.labels_add = state.labels_add || [];
  let existingLabels = exports.getMixedLabels(savedLabels, removedLabels);
  InlineMessageController.close();
  AutocompleteController.open({
    items: LabelModel.getItemsForLabelsAutocomplete({
      labels: existingLabels
    }),
    target: this,
    noActive: true,
    footer: LabelUpdateFooterTemplate.render(),
    multiSelect: true,
    title: 'Add or Remove Labels',
    showInput: true,
    placeholder: 'mockup, "tech debt"',
    onResultsUpdate: ({
      hasResults,
      setNoResults,
      value
    }) => {
      if (!hasResults) {
        setNoResults(LabelModel.createNewLabelText(value));
      }
    },
    onApply: label => {
      if (_.isString(label)) {
        existingLabels = exports.addLabelAsString(existingLabels, label);
      } else {
        existingLabels = exports.toggleExistingLabel(existingLabels, label);
      }
      _updateAttributeContainer(container);
      container.find('.value').html(exports.getLabelDescriptions());
    },
    topOffset: 4,
    width: DROPDOWN_WIDTH
  });
  return false;
};
exports.toggleExistingLabel = (existingLabels, label) => {
  const labelSlim = {
    name: label.name
  };
  if (_.some(existingLabels, labelSlim)) {
    _.remove(existingLabels, labelSlim);
    _.remove(state.labels_add, labelSlim);
    state.labels_remove.push(labelSlim);
  } else {
    existingLabels.push(label);
    state.labels_add.push(labelSlim);
    _.remove(state.labels_remove, labelSlim);
  }
  return existingLabels;
};
exports.addLabelAsString = (existingLabels, labelString) => {
  const foundLabel = LabelModel.get({
    lowercase_name: labelString.toLowerCase()
  });
  if (foundLabel) {
    existingLabels = exports.toggleExistingLabel(existingLabels, foundLabel);
  } else {
    Utils.tokenizeQuery(labelString).forEach(label => {
      label = {
        name: label
      };
      existingLabels = exports.toggleExistingLabel(existingLabels, label);
    });
  }
  return existingLabels;
};
exports.getLabelDescriptions = () => {
  const existing = exports.getLabelsForDisplay();
  const added = _.reject(state.labels_add, label => {
    return _.some(existing, {
      name: label.name,
      multiple: false
    });
  });
  const removed = _.filter(state.labels_remove, label => {
    return _.some(existing, {
      name: label.name
    });
  });
  const count = added.length + removed.length;
  if (count) {
    return Format.pluralize(count, 'label change', 'label changes');
  } else {
    return 'No label changes';
  }
};
function _hasChanges(state) {
  return _.keys(state).length > 0;
}
function _cleanupState(state) {
  // Convert custom fields to correct format: [{ field_id, value_id }, ...]
  // Current format (to save on performance while bulk panel is open):
  //   custom_fields_add: { [field_id]: [{ field_id, value}], ... }
  //   custom_fields_remove: Same as above, but arrays contain stringified values with an extra value prop
  state.custom_fields_add = [].concat(...Object.values(state.custom_fields_add || {}));
  state.custom_fields_remove = [].concat(...Object.values(state.custom_fields_remove || {}).reduce((acc, curr) => [...acc, ...Array.from(curr).map(val => (({
    field_id,
    value_id
  }) => ({
    field_id,
    value_id
  }))(JSON.parse(val)))], []));

  // Remove properties with empty arrays
  Object.entries(state).forEach(([k, v]) => {
    if (_.isArray(v) && !v.length) {
      delete state[k];
    }
  });
  delete state.workflow_id;
  return state;
}
exports._isTeamChange = (teamIDs, state) => {
  const newProjectId = state.project_id;
  if (newProjectId === undefined) {
    return false;
  }
  if (teamIDs.length > 1 || newProjectId === null) {
    return true;
  }
  const newProject = ProjectModel.getById(newProjectId);
  return newProject.team_id !== teamIDs[0];
};
exports._isValidStateForWorkflow = state => {
  const workflowStateId = state.workflow_state_id ?? initialState.workflow_state.id;
  const workflow = WorkflowModel.getById(state.workflow_id);
  return workflow ? WorkflowModel.hasStateWithId(workflow, workflowStateId) : false;
};
exports._workflowStateNotInCorrectTeam = state => {
  // All workflow states are valid with the "None" Project.
  if (state.project_id === null) {
    return false;
  }
  const workflowStateId = state.workflow_state_id ?? initialState.workflow_state.id;
  if (!workflowStateId) {
    return true;
  }
  const column = ColumnModel.getById(workflowStateId);
  const team = ColumnModel.getTeamFromColumn(column);
  const project = ProjectModel.getById(state.project_id);
  return Boolean(project) && project.team_id !== team.id;
};
exports._getTeamIDsFromSelectedStories = () => {
  const selectedStories = BulkSelectionModel.getStoriesForEachSelection();
  const existingProjectIDs = _.uniq(_.map(selectedStories, 'project_id').filter(Boolean));
  return _.uniq(_.map(existingProjectIDs, projectID => {
    const project = ProjectModel.getById(projectID);
    return project.team_id;
  }));
};
exports._validateState = state => {
  const validations = {};
  const incompatibleModel = exports._getWorkflowStateIncompatibleModel(state);
  if (incompatibleModel) {
    validations.alert = `You are updating the ${incompatibleModel} of the selected Stories. Which Workflow State should the selected Stories be moved to?`;
  }
  if (state.group_id && !GroupModel.isValidWorkflowForGroup(state.group_id, state.workflow_id ?? initialState.workflow)) {
    validations.alert = 'You are updating the Team of the selected Stories. Choose the Workflow the selected stories should be moved to.';
  }
  if (!_hasChanges(state)) {
    validations.alert = 'No updates have been made to the selected Stories.';
  }
  return validations;
};
exports._getWorkflowStateIncompatibleModel = state => {
  const teamIDs = exports._getTeamIDsFromSelectedStories();
  const isTeamChange = exports._isTeamChange(teamIDs, state);
  const workflowStateNotInCorrectTeam = exports._workflowStateNotInCorrectTeam(state);
  if (isTeamChange && workflowStateNotInCorrectTeam) {
    return 'Project';
  }
  if (state.workflow_id && !exports._isValidStateForWorkflow(state)) {
    return 'Workflow';
  }
  return null;
};
exports.moreOptions = function () {
  const count = BulkSelectionModel.getSelectionCount();
  const titlePrefix = BulkSelectionModel.areAllArchived() ? 'Delete' : 'Archive &amp; delete';
  DropdownController.open({
    items: [{
      name: titlePrefix + ' ' + Format.pluralize(count, 'Story', 'Stories') + '...',
      value: () => {
        deleteSelection = BulkSelectionModel.getStoriesForEachSelection();
        _openDeleteDialog();
      },
      iconLeft: 'fa-trash-o'
    }],
    target: this,
    targetSelector: '.bulk-editor-content .more-options',
    title: 'More Options',
    topOffset: 0,
    width: DROPDOWN_WIDTH
  });
  return false;
};
function _openDeleteDialog() {
  const html = BulkDeleteDialogTemplate.render({
    canDelete: BulkSelectionModel.areAllArchived(),
    count: BulkSelectionModel.getSelectionCount(),
    stories: deleteSelection
  });
  const options = {
    closeOnEscape: true,
    id: 'over-panels',
    width: 720
  };
  DropdownController.closeAll();
  if (Dialog.isOpen()) {
    Dialog.rerender(html, options);
  } else {
    Dialog.open(html, options);
  }
  return false;
}
exports.closeDeleteDialog = () => {
  deleteSelection = [];
  Dialog.close({
    force: true
  });
};
exports.archiveAll = () => {
  const count = BulkSelectionModel.getSelectionCount();
  $('.bulk-delete-dialog .actions').html('<button class="action mini gray" disabled><span class="fa fa-star fa-spin"></span> Archiving...</button>');
  BulkSelectionModel.archiveAndMaintainSelection(err => {
    if (!err) {
      _openDeleteDialog();
      MessageController.success(Format.pluralize(count, 'story has', 'stories have') + ' been successfully archived.');
    } else {
      _updateBulkDeleteActions();
      MessageController.error(err, {
        secondary: 'No stories have been archived.'
      });
    }
  });
  return false;
};
exports.deleteAll = () => {
  const count = BulkSelectionModel.getSelectionCount();
  const msg = 'Are you sure you want to delete ' + (count === 1 ? 'this' : 'these') + ' ' + Format.pluralize(count, 'story', 'stories') + '?';
  if (window.confirm(msg)) {
    $('.bulk-delete-dialog .actions').html('<button class="action mini gray" disabled><span class="fa fa-star fa-spin"></span> Deleting...</button>');
    BulkSelectionModel.delete(err => {
      if (!err) {
        MessageController.success(Format.pluralize(count, 'story has', 'stories have') + ' been successfully deleted.');
        _clearAllSelections();
        exports.closeDeleteDialog();
        exports.close();
      } else {
        MessageController.error(err, {
          secondary: 'No stories have been deleted.'
        });
        exports.closeDeleteDialog();
        exports.close();
      }
    });
  }
  return false;
};
exports.save = () => {
  const state = exports._getState();
  const {
    alert: alertMessage
  } = exports._validateState(state);
  if (!alertMessage) {
    $('.bulk-editor-content .actions').html('<button class="action mini gray" disabled><span class="fa fa-star fa-spin"></span> Saving...</button>');
    const count = BulkSelectionModel.getSelectionCount();
    BulkSelectionModel.save(_cleanupState(state), err => {
      if (!err) {
        MessageController.success(Format.pluralize(count, 'story has', 'stories have') + ' been successfully updated.');
        onUpdateTrackingMethod();
        _clearAllSelections();
      } else {
        MessageController.error(err, {
          secondary: 'No stories have been updated.'
        });
        exports.close();
      }
    });
  } else {
    MessageController.alert(alertMessage);
  }
  return false;
};
function _updateBulkDeleteActions() {
  const html = BulkDeleteDialogActionsTemplate.render({
    canDelete: BulkSelectionModel.areAllArchived(),
    count: BulkSelectionModel.getSelectionCount()
  });
  $('.bulk-delete-dialog .actions').html(html);
}
function _clearAllSelections() {
  exports.close();
  exports._clearState();
  Globals.set('lastToggledStoryId', null);
  StoryModel.trigger('bulkStoriesUpdated');
}
function _getPermittedWorkflowIdsFromSelection() {
  // Selected stories don't have a group assigned to them
  if (initialState.group === null) {
    return undefined;
  }
  const stories = BulkSelectionModel.getStoriesForEachSelection();
  const permittedWorkflowIds = stories.map(story => {
    const group = GroupModel.getById(story.group_id);
    return group && group.workflow_ids;
  }).filter(Boolean);
  return _.intersection(...permittedWorkflowIds);
}
exports.init = () => {
  Event.on('storySelectionUpdated', () => {
    $('.bulk-story-count').text(Format.pluralize(BulkSelectionModel.getSelectionCount(), 'story', 'stories'));
    _updateBulkDeleteActions();
  });
};
export { exports as default };