import CompanyModel from './company';
import OrganizationModel from './organization';
import BaseUtils from '../_frontloader/baseUtils';
import Collection from '../_frontloader/collection';
import Globals from '../_frontloader/globals';
import Backend from '../modules/backend';
import Constants from '../modules/constants';
import Log from '../modules/log';
import _ from 'lodash';
const exports = {};

/*

Card model:

{
  address_zip: "12345"
  brand: "Visa"
  exp_month: 1
  exp_year: 2017
  id: "card_16xfaoBp1dTh9EeCDv6P91uW"
  last4: "1111"
  name: "ASd ASF"
}

*/

Collection.create('Card', exports);
exports.normalize = card => {
  const currentOrgId = card.org_id || Globals.get('organizationID') || OrganizationModel.getCurrentID();
  const currentCompanyId = card.company_id || CompanyModel.getFromOrg(OrganizationModel.getById(currentOrgId)).id;
  card.org_id = currentOrgId;
  card.company_id = currentCompanyId;
  card.icon = Constants.CARD_ICONS[card.brand];
};

// "Visa", "MasterCard", "American Express", "Discover", "Diners Club", and "JCB".
// If a card isn't recognized, the return value is "Unknown".
exports.detectCardType = str => {
  return window.Stripe.card.cardType(str);
};
exports.getExpirationDateForCard = card => {
  if (!card || !card.exp_month || !card.exp_year) {
    return '';
  }

  // We want the last day in the month as that's how credit card expiration works.
  // card.exp_month starts at 1, but Date month starts at 0!
  const d = new Date(Date.UTC(card.exp_year, card.exp_month, 1));
  d.setSeconds(-1);
  return d.toISOString();
};
exports.fetchAll = callback => {
  Backend.get('/api/private/owner/cards', {
    excludeOrgHeader: true,
    onComplete: res => {
      exports.defaultFetchAllHandler(res, callback);
    }
  });
};
exports.organizationHasCard = organization => Boolean(exports.getCardForOrg(organization));

/** @deprecated Use getCardForCurrentOrg or getCardForOrg instead */
exports.getCardForOrganization = id => {
  if (id) {
    return exports.getCardForOrg(OrganizationModel.getById(id));
  } else {
    return exports.getCardForCurrentOrg();
  }
};
exports.getCardForCurrentOrg = () => {
  return exports.getCardForOrg(OrganizationModel.getById(Globals.get('organizationID') || OrganizationModel.getCurrentID()));
};
exports.getCardForOrg = organization => {
  const companyID = CompanyModel.getFromOrg(organization).id;
  return exports.get({
    org_id: organization.id
  }) || exports.get({
    company_id: companyID
  });
};
exports.createCard = (data, callback, couponCode) => {
  callback = _.isFunction(callback) ? callback : _.noop;
  window.Stripe.card.createToken(data, (status, res) => {
    const existingCard = exports.getCardForOrganization();
    if (res.error) {
      Log.error(new Error(res.error), {
        stripeResponse: res
      });
      callback(res.error && res.error.message || Constants.STRIPE_ERROR);
    } else {
      if (existingCard) {
        _deleteCard(existingCard, () => {
          _createCardFromToken(res, callback, couponCode);
        });
      } else {
        _createCardFromToken(res, callback, couponCode);
      }
    }
  });
};
function _createCardFromToken(stripeResponse, callback, couponCode) {
  // Confirm this is supported
  const data = {
    stripe_token: stripeResponse.id
  };
  if (couponCode) {
    data.promotion_code = couponCode;
  }
  Backend.post('/api/private/owner/cards', {
    data,
    excludeOrgHeader: true,
    onComplete: res => {
      exports.defaultGetHandler(res, callback);
    }
  });
}
function _deleteCard(card, callback) {
  Backend.delete('/api/private/owner/cards/' + card.id, {
    excludeOrgHeader: true,
    onComplete: (res, xhr) => {
      exports.defaultDeleteHandler(card, res, xhr, callback);
    }
  });
}
exports.Promises = {};
exports.Promises.fetchAll = BaseUtils.promisify(exports.fetchAll);
export { exports as default };