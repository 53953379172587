import { useCallback, useState } from 'react';
import { useUpdateEffect } from 'react-use';
import { reorderByPosition } from '@clubhouse/shared/utils/reorder';
import { StatelessTable } from './StatelessTable';
import { SUPPRESS_FOOTER_ITEM_COUNT } from './config';
import { TableFooter } from './internalComponents/TableFooter';
import { sortEntities } from './utils/sortEntities';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export function TableInternal({
  items,
  columns,
  onChangePriority,
  onError,
  sortColumn,
  sortDirection = 'ascending',
  collection,
  initiallyCollapsed,
  HeaderComponent,
  RowComponent,
  pageSize,
  disableHeader,
  shouldHideBoxShadow,
  shouldHideContainer,
  rowHeight,
  onSort,
  getIsRowSelected,
  className
}) {
  const [rowCount, setRowCount] = useState(pageSize);
  const {
    sort: maybeSortFn
  } = columns.find(col => col.name === sortColumn) || {};
  const [orderedItems, setOrderedItems] = useState(sortEntities(items, maybeSortFn, sortDirection));
  useUpdateEffect(() => {
    setOrderedItems(sortEntities(items, maybeSortFn, sortDirection));
  }, [items, maybeSortFn, sortDirection]);
  const addMoreRows = useCallback(() => setRowCount(rowCount => rowCount + pageSize), [pageSize]);
  const handleChangePriority = useCallback(({
    oldIndex,
    newIndex
  }) => {
    if (!onChangePriority) return;

    // If this function is called, we assume the entity has `position` since we only allow reordering based on priority (which uses `position` attribute)
    // TODO: Since this table should be generic, re-ordering based on priority should be moved out of this component.
    let reorderedEntities, sortedEntities;
    new Promise(resolve => {
      resolve(onChangePriority({
        oldIndex,
        newIndex,
        entityList: orderedItems,
        sortDirection
      }));
    }).catch(err => {
      if (err && onError) {
        onError(err);
      }

      // Set to previous arrangement before drag
      reorderedEntities = reorderByPosition([...orderedItems], newIndex, oldIndex);
      sortedEntities = sortEntities(reorderedEntities, maybeSortFn, sortDirection);
      setOrderedItems(sortedEntities);
    });
    reorderedEntities = reorderByPosition([...orderedItems], oldIndex, newIndex);
    sortedEntities = sortEntities(reorderedEntities, maybeSortFn, sortDirection);
    setOrderedItems(sortedEntities);
  }, [onChangePriority, orderedItems, maybeSortFn, sortDirection, onError]);
  const lastItem = Math.min(rowCount, items.length);
  const isListShort = items.length < SUPPRESS_FOOTER_ITEM_COUNT;
  const isShowingAllRows = items.length === lastItem;
  return _jsx(StatelessTable, {
    className: className,
    allItems: orderedItems,
    items: orderedItems.slice(0, rowCount),
    columns: columns,
    isCollapsible: !!HeaderComponent,
    HeaderComponent: HeaderComponent,
    initiallyCollapsed: initiallyCollapsed,
    onReorder: handleChangePriority,
    shouldHideBoxShadow: shouldHideBoxShadow,
    shouldHideContainer: shouldHideContainer,
    collection: collection,
    disableHeader: disableHeader,
    onSort: onSort,
    sortColumn: sortColumn,
    sortDirection: sortDirection,
    RowComponent: RowComponent,
    rowHeight: rowHeight,
    getIsRowSelected: getIsRowSelected,
    footer: !isListShort || !isShowingAllRows ? _jsx(TableFooter, {
      first: 1,
      last: lastItem,
      onClickLoadMore: isShowingAllRows ? undefined : addMoreRows,
      total: items.length
    }) : undefined
  });
}
TableInternal.displayName = "TableInternal";