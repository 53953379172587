import { slugify } from '@clubhouse/shared/utils';
import { PAGE_NAMES, generatePathWithSlug, getCurrentOrigin, getPathForPage } from 'utils/navigation';
export const getHref = (id, {
  name,
  includeDomain = false
} = {}) => {
  const sluggedName = slugify(name ?? '', {
    limit: 120
  });
  return (includeDomain ? getCurrentOrigin() : '') + generatePathWithSlug(getPathForPage(PAGE_NAMES.OBJECTIVE), {
    id,
    objectiveName: sluggedName || undefined
  });
};