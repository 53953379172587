import CfdCalculationsImgDark from '@clubhouse/assets/png/screenshots/cfd-calculations-dark.png';
import CfdCalculationsImgLight from '@clubhouse/assets/png/screenshots/cfd-calculations.png';
import CfdStatesImgDark from '@clubhouse/assets/png/screenshots/cfd-states-dark.png';
import CfdStatesImgLight from '@clubhouse/assets/png/screenshots/cfd-states.png';
import ExampleCfdOld from '@clubhouse/assets/png/screenshots/example-cfd-old.png';
import { THEME_NAMES, useThemeName } from '@clubhouse/shared/utils/theme';
import { PAGE_NAMES, getCurrentPage, getSlugPath } from 'utils/navigation';
import { usesIterations } from 'utils/tests';
import { ReportExplanation } from '../../shared/reports/ReportExplanation';
import { ReportsHelpFooter } from '../ReportsHelpFooter';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const Description = ({
  showWorkingDaysData,
  page,
  isDarkMode
}) => page === PAGE_NAMES.ITERATION ? _jsxs(_Fragment, {
  children: [_jsx("h3", {
    children: "Overview"
  }), _jsx("p", {
    children: "The Cumulative Flow Diagram (CFD) shows the total amount of work in each workflow state over a period of time. The CFD is a powerful tool that can be used to understand a team's workflow, increases in scope, cycle time, and throughput."
  }), _jsx("h3", {
    children: "Chart Mechanics"
  }), _jsx("p", {
    children: "The CFD is organized into three major groups. Each of these are expected to behave differently. It's important to understand their roles to get the most out of the CFD."
  }), _jsx("img", {
    src: isDarkMode ? CfdStatesImgDark : CfdStatesImgLight,
    width: "100%",
    alt: "Example Chart"
  }), _jsxs("ul", {
    children: [_jsxs("li", {
      children: [_jsx("b", {
        children: "Yellow Area"
      }), ": Unstarted"]
    }), _jsxs("li", {
      children: [_jsx("b", {
        children: "Blue Area"
      }), ": In Progress"]
    }), _jsxs("li", {
      children: [_jsx("b", {
        children: "Green Area"
      }), ": Done"]
    })]
  }), _jsx("h4", {
    children: "Yellow Area"
  }), _jsx("p", {
    children: "The yellow area represents work in any Unstarted workflow state. This area should shrink over the timeline of an iteration as stories move to In Progress, and then to Done. If this area is not shrinking, it means that work is being added at the same or higher rate as work being completed. Increases in the Unstarted area will lead to an increase in the total chart height. This is a quick way to identify a change in scope."
  }), _jsx("h4", {
    children: "Blue Area"
  }), _jsx("p", {
    children: "The blue area represents work in any Started workflow state. This area should maintain a relatively consistent height as work enters and leaves at a steady pace. Increases in height can indicate a team is overloaded or experiencing a bottleneck. Decrease in height may indicate there is not enough work ready for dev."
  }), _jsx("p", {
    children: "If your CFD has multiple Started states within the blue area, these can be looked at individually to further understand the stages of development. For example, a \"Ready for Review\" workflow state that has grown larger than the other Started states may indicate a slow-down at the PR review stage."
  }), _jsx("h4", {
    children: "Green Area"
  }), _jsx("p", {
    children: "The green area should always grow as this represents work that is Done. The slope of the line conveys how fast or slow work is entering a Done state. A vertical line would represent a cycle time of zero, whereas a flat line would mean no work is being completed. Changes in slope may indicate work is slowing or that a bottleneck occurred and a lot of work was delivered at once. Ideally the slope of the Done area should be consistent, indicating a steady delivery rate."
  }), _jsx("h4", {
    children: "Gray Area"
  }), _jsx("p", {
    children: "Depending how a team manages work, stories in the Backlog may end up in an iteration. Ideally these are moved to an Unstarted workflow state prior to the iteration to indicate they are ready. If present, the Gray Area should be slim and quickly shrink as stories move to Unstarted or Started workflow states."
  }), _jsx("h3", {
    children: "Measure Cycle Time and Velocity"
  }), _jsx("img", {
    src: isDarkMode ? CfdCalculationsImgDark : CfdCalculationsImgLight,
    width: "100%",
    alt: ""
  }), _jsxs("p", {
    children: [_jsx("b", {
      children: "Average Cycle Time"
    }), " can be measured by drawing a straight line from anywhere on the top of the In Progress (blue) area to where it meets the Done (green) area. The length of this line represents the average time it takes a story to move from In Progress to Done. In the above chart, it's about 2.5 days (Tuesday + Wednesday + Half of Thursday = 2.5 days)."]
  }), _jsxs("p", {
    children: [_jsx("b", {
      children: "Average Velocity"
    }), " can be determined by drawing a diagonal between the dotted In Progress line and the Cycle Time line. The slope of the line shows the pace of work, and can be used to calculate velocity. In the chart above, the Cycle Time is 2.5 days, and the number of stories completed is 8, which equals a velocity of 3.2 stories per day (8 stories \xF7 2.5 days = 3.2 stories/day)."]
  })]
}) : _jsxs(_Fragment, {
  children: [_jsxs("p", {
    children: ["The Cumulative Flow Diagram shows the total amount of work within each workflow state over a period of time.", (page === PAGE_NAMES.REPORTS || page === PAGE_NAMES.PROJECT) && ' Stories will be included in the counts once they are marked as completed.', ' ', "As workflow states are unique for each workflow, only one workflow can be viewed at a time."]
  }), _jsxs("p", {
    children: ["The x-axis represents time.\xA0", page === PAGE_NAMES.REPORTS ? _jsxs(_Fragment, {
      children: ["The intervals can be changed by using the Group By filter at the top of the page.\xA0", usesIterations() && _jsx(_Fragment, {
        children: "Note that Group By Iteration is not supported by this chart.\xA0"
      }), "See Group By help for more details.\xA0"]
    }) : _jsx(_Fragment, {
      children: "The Group By interval (Day, Week, or Month) is calculated based on the number of days in the date range.\xA0"
    }), showWorkingDaysData && _jsxs(_Fragment, {
      children: ["When Group By is set to Day, non-working days will be highlighted on the chart. Working days can be adjusted in your ", _jsx("a", {
        href: `${getSlugPath()}/settings/workspace`,
        children: "Workspace Settings"
      }), ".\xA0"]
    })]
  }), _jsx("p", {
    children: "The y-axis represents the number of points or Stories in a workflow state during an interval. The Sum Using dropdown on the top-right configures the chart to count points or Stories."
  }), _jsx("p", {
    children: "While Sum Using is set to Points, unestimated Stories, and Stories estimated at 0 points, are excluded from the count."
  })]
});
export const Explanation = ({
  isEmbedded = false
}) => {
  const page = getCurrentPage();
  const isDarkMode = useThemeName() === THEME_NAMES.DARK;
  const hasImgSrc = page !== PAGE_NAMES.ITERATION;
  return _jsx(ReportExplanation, {
    isEmbedded: isEmbedded,
    title: `${!isEmbedded ? 'About the ' : ''}Cumulative Flow Diagram`,
    imgSrc: hasImgSrc ? ExampleCfdOld : '',
    description: _jsx(Description, {
      showWorkingDaysData: true,
      page: getCurrentPage(),
      isDarkMode: isDarkMode
    }),
    footer: _jsx(ReportsHelpFooter, {})
  });
};
Explanation.displayName = "Explanation";