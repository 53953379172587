import Backend from './backend';
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';
const throttledFns = {};
const counts = {};
const loggedCounts = {};
const log = (key, currentCount) => {
  const time = new Date();
  Backend.post('/log', {
    data: {
      throttled_function_called_too_many_times: key,
      num_calls: currentCount,
      timestamp: time.getTime()
    }
  });
};
const logIfTooManyInOneSecond = debounce((key, currentCount, tooManyInOneSecond) => {
  loggedCounts[key] ||= 0;
  const difference = currentCount - loggedCounts[key];
  loggedCounts[key] = currentCount;
  if (difference >= tooManyInOneSecond) {
    log(key, currentCount);
  }
}, 1000);
export const callThrottledWithLogging = (key, fn, rate = 1000, tooManyInOneSecond = 2) => {
  counts[key] ||= 0;
  counts[key] += 1;
  logIfTooManyInOneSecond(key, counts[key], tooManyInOneSecond);
  throttledFns[key] ||= throttle(fn, rate);
  throttledFns[key]();
};