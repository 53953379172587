import "core-js/modules/es.array.push.js";
import EditProfileController from 'app/client/settingsShared/js/controllers/editProfile.js';
window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || {
    Controller: {},
    Model: {}
  };
  [[['Controller', 'EditProfile'], EditProfileController]].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});
import { Spaced } from '@clubhouse/shared/components/Spaced';
import { ProfileForm } from './ProfileForm';
import { IconUploader } from './IconUploader';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export function ManageProfilePage() {
  return _jsx("div", {
    id: "edit-profile-form",
    "data-controller": "EditProfile",
    children: _jsxs(Spaced, {
      vertically: true,
      amount: "space8",
      children: [_jsx(ProfileForm, {}), _jsx(IconUploader, {})]
    })
  });
}
ManageProfilePage.displayName = "ManageProfilePage";