import { StorySkillSetFieldUpdateDocument } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { SkillSetFieldFragmentFragmentDoc } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { QueryStorySkillSetSelectDocument } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { Icon } from '@useshortcut/shapes-ds';
import { useCallback } from 'react';
import { gql } from '@clubhouse/datalayer';
import { getTint } from '@clubhouse/shared/color';
import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons';
import { NONE_VALUE, Nouns } from '@clubhouse/shared/constants';
import { GroupedOptionsFieldSingleSelection } from 'components/gql/GroupedOptionsField';
import { SkillSetSelectTarget } from 'components/gql/skillSet/SkillSetSelectTarget';
import { createOptimisticStoryMutationResponse } from 'components/gql/utils/mutation';
import { useMutation } from 'gql';
import { useFieldOptionsQuery } from './useFieldOptionsQuery';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const QUERY_STORY_SKILL_SET_SELECT = QueryStorySkillSetSelectDocument;
export const SkillSetFieldFragment = SkillSetFieldFragmentFragmentDoc;
const MUTATE_SKILL_SET = StorySkillSetFieldUpdateDocument;
const SkillSetIcon = ({
  colorKey
}) => _jsx(DeprecatedIconAdapter, {
  fill: getTint(colorKey),
  children: _jsx(Icon, {
    icon: "Skillset"
  })
});
SkillSetIcon.displayName = "SkillSetIcon";
const getSection = (groupKey, options) => {
  return {
    sectionId: groupKey,
    items: options.map(option => ({
      value: option.node.id,
      name: option.node.stringValue,
      Icon: () => _jsx(SkillSetIcon, {
        colorKey: option.node.colorKey
      })
    }))
  };
};
const SkillSetFieldComponent = ({
  entity: {
    id,
    skillSet
  }
}) => {
  const fetchStorySkillSets = useFieldOptionsQuery({
    query: QUERY_STORY_SKILL_SET_SELECT,
    storyId: id,
    includeCurrentPermissionId: false
  });
  const [updateStorySkillSet] = useMutation(MUTATE_SKILL_SET);
  const handleChange = useCallback(async selectedId => {
    await updateStorySkillSet({
      variables: {
        storyId: id,
        input: {
          canonicalName: 'skillSet',
          valueId: selectedId ?? NONE_VALUE
        }
      },
      optimisticResponse: createOptimisticStoryMutationResponse(id, {
        skillSet: selectedId ? {
          __typename: 'CustomFieldEnumValue',
          id: selectedId
        } : null
      })
    });
  }, [updateStorySkillSet, id]);
  return _jsx(GroupedOptionsFieldSingleSelection, {
    selectedEntity: skillSet,
    fetchOptions: fetchStorySkillSets,
    getSection: getSection,
    TargetComponent: SkillSetSelectTarget,
    onChange: handleChange,
    unit: Nouns.State
  });
};
SkillSetFieldComponent.displayName = "SkillSetFieldComponent";
export const SkillSetField = () => ({
  name: 'skillSet',
  displayName: 'Skill Set',
  Component: SkillSetFieldComponent,
  width: 140,
  sort: true
});