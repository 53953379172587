import Dialog from '../modules/dialog';
import * as Event from '../_frontloader/event';
import moment from 'moment';
const exports = {};
exports.reRender = () => {
  Event.trigger('ReportsReRender');
};
exports.processDatesForRequest = date => {
  return date.format('YYYY-MM-DD');
};
exports.formatDate = (date, utcOffset) => {
  date = moment(date);
  if (utcOffset) {
    date = date.utcOffset(utcOffset);
  }
  return date.format('dddd, MMMM Do YYYY');
};
exports.openAboutThisChart = ({
  template
}, data = {}) => {
  Dialog.setPreviousUrl();
  const html = template.render(data);
  Dialog.open(html, {
    closeOnEscape: true,
    fullscreen: true,
    width: 720
  });
  return false;
};
export { exports as default };