import { MutateEpicPositionDocument } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { EpicReorderFragmentFragmentDoc } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { useCallback } from 'react';
import { gql } from '@clubhouse/datalayer';
import { insertIf } from '@clubhouse/shared/utils/object';
import { moveWithinArray } from '@clubhouse/shared/utils/reorder';
import { getPositionChange } from 'components/shared/hooks/useOnChangePriority';
import { useMutation } from 'gql';
EpicReorderFragmentFragmentDoc;
const MUTATE_EPIC_POSITION = MutateEpicPositionDocument;
const useUpdateEpicPosition = () => {
  const [updatePosition] = useMutation(MUTATE_EPIC_POSITION);
  return updatePosition;
};
export const useOptimisticReordering = ({
  epics,
  sortDirection,
  updatePosition,
  updateQuery
}) => {
  return useCallback(async ({
    oldIndex,
    newIndex
  }) => {
    if (oldIndex === newIndex) return;
    const epic = epics[oldIndex];
    const changes = getPositionChange({
      oldIndex,
      newIndex,
      entityList: epics,
      sortDirection
    });
    const promise = updatePosition({
      variables: {
        id: epic.id,
        input: {
          ...insertIf(!!changes.before_id, {
            before: String(changes.before_id)
          }),
          ...insertIf(!!changes.after_id, {
            after: String(changes.after_id)
          })
        }
      }
    });
    updateQuery(previousQueryResult => {
      const edges = moveWithinArray([...previousQueryResult.workspace2.epics.edges], oldIndex, newIndex);
      return {
        ...previousQueryResult,
        workspace2: {
          ...previousQueryResult.workspace2,
          epics: {
            ...previousQueryResult.workspace2.epics,
            edges
          }
        }
      };
    });
    await promise;
  }, [epics, sortDirection, updatePosition, updateQuery]);
};
export function useEpicReordering({
  epics,
  sortDirection,
  updateQuery
}) {
  const updatePosition = useUpdateEpicPosition();
  return useOptimisticReordering({
    epics,
    sortDirection,
    updateQuery,
    updatePosition
  });
}