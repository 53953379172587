import { QueryEpicOwnersSelectDocument } from "../../../../../../../datalayer/__generated_graphql_types__/graphql";
import { MutateEpicOwnersFieldDocument } from "../../../../../../../datalayer/__generated_graphql_types__/graphql";
import { EpicOwnersFieldFragmentFragmentDoc } from "../../../../../../../datalayer/__generated_graphql_types__/graphql";
import { useCallback, useMemo } from 'react';
import { gql } from '@clubhouse/datalayer';
import { Center } from '@clubhouse/shared/components/Center';
import { SkeletonCircle } from '@clubhouse/shared/components/SkeletonCircle';
import { Nouns } from '@clubhouse/shared/constants/nouns';
import { GroupedOptionsField } from 'components/gql/GroupedOptionsField';
import { getSection } from 'components/gql/owner/utils/sections';
import { createOptimisticEpicMutationResponse } from 'components/gql/utils/mutation';
import { useMutation } from 'gql';
import { useFieldOptionsQuery } from '../useFieldOptionsQuery';
import { OwnersFieldTarget } from './OwnersFieldTarget';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const EpicOwnersFieldFragment = EpicOwnersFieldFragmentFragmentDoc;
const MUTATE_EPIC_OWNERS_FIELD = MutateEpicOwnersFieldDocument;
export const QUERY_EPIC_OWNERS_SELECT = QueryEpicOwnersSelectDocument;
export const OwnersFieldComponent = ({
  entity: {
    id,
    owners
  }
}) => {
  const fetchOwners = useFieldOptionsQuery({
    query: QUERY_EPIC_OWNERS_SELECT,
    epicId: id,
    includeCurrentPermissionId: true
  });
  const [executeMutation] = useMutation(MUTATE_EPIC_OWNERS_FIELD);
  const handleChange = useCallback(async selectedIds => {
    executeMutation({
      variables: {
        epicId: id,
        input: {
          owners: {
            reset: selectedIds
          }
        }
      },
      optimisticResponse: createOptimisticEpicMutationResponse(id, {
        owners: {
          __typename: 'PermissionConnection',
          edges: selectedIds.map(id => ({
            __typename: 'PermissionEdge',
            node: {
              __typename: 'Permission',
              id
            }
          }))
        }
      })
    });
  }, [id, executeMutation]);
  const selectedOwners = useMemo(() => (owners?.edges || []).map(({
    node: {
      id,
      state,
      email,
      displayName,
      mentionName,
      displayIcon
    }
  }) => ({
    id,
    state,
    email_address: email,
    name: displayName,
    mention_name: mentionName,
    ...(displayIcon?.thumbnailUrl ? {
      display_icon: {
        url: displayIcon.thumbnailUrl
      }
    } : {
      display_icon: null
    })
  })), [owners?.edges]);
  return _jsx(Center, {
    children: _jsx("div", {
      children: _jsx(GroupedOptionsField, {
        selectedEntities: selectedOwners,
        fetchOptions: fetchOwners,
        getSection: getSection,
        onChange: handleChange,
        TargetComponent: OwnersFieldTarget,
        unit: Nouns.Owner
      })
    })
  });
};
OwnersFieldComponent.displayName = "OwnersFieldComponent";
OwnersFieldComponent.displayName = 'OwnersFieldComponent';
export const OwnersField = () => ({
  name: 'owners',
  displayName: 'Owners',
  sort: true,
  headerProps: {
    centered: true
  },
  Component: OwnersFieldComponent,
  LoadingComponent: () => _jsx(Center, {
    children: _jsx(SkeletonCircle, {
      size: 24
    })
  }),
  width: 110
});