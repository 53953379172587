import { TeamInfo } from '../../TeamInfo';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const AssociatedTeam = ({
  count,
  imageUrl,
  name,
  teamColor
}) => {
  return _jsxs(TeamInfo, {
    children: [_jsx(TeamInfo.Icon, {
      iconUrl: imageUrl,
      iconFill: teamColor
    }), _jsxs(TeamInfo.Info, {
      children: [_jsx(TeamInfo.Name, {
        children: name
      }), _jsx(TeamInfo.StoryCount, {
        count: count
      })]
    })]
  });
};
AssociatedTeam.displayName = "AssociatedTeam";