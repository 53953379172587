import uniqueId from 'lodash/uniqueId';
import { useRef } from 'react';
import { onKeyboardActivation } from '../utils/keyboard';
import { useKeyboardListNavigation } from './useKeyboardListNavigation';
export function useTabs({
  onSelect,
  description,
  disableEnhancedKeyboardNav
}) {
  const tablistRef = useRef(null);
  const activeRef = useRef(null);
  const tabsId = useRef(uniqueId('tabs-'));
  const tabListKeyboardProps = useKeyboardListNavigation({
    listRef: tablistRef,
    activeRef,
    direction: 'horizontal'
  });
  return {
    getTabListProps: () => ({
      ...tabListKeyboardProps,
      ref: tablistRef,
      role: 'tablist',
      'aria-label': description
    }),
    getTabPanelProps: ({
      key
    }) => ({
      role: 'tabpanel',
      id: `${tabsId.current}-panel-${key}`,
      'aria-labelledby': `${tabsId.current}-tab-${key}`
    }),
    getTabProps: ({
      key,
      value,
      active,
      tabPanelRef
    }) => {
      const onKeyDown = e => {
        if (!disableEnhancedKeyboardNav && e.key === 'ArrowRight') {
          const tabElems = tablistRef?.current?.children;
          const tabElemsArray = tabElems ? Array.from(tabElems) : [];
          const currentTabIndex = tabElemsArray.findIndex(tabElem => tabElem === e.target);
          const nextTabIndex = currentTabIndex === tabElemsArray.length - 1 ? 0 : currentTabIndex + 1;
          onSelect(nextTabIndex);
        }
        if (!disableEnhancedKeyboardNav && e.key === 'ArrowLeft') {
          const tabElems = tablistRef?.current?.children;
          const tabElemsArray = tabElems ? Array.from(tabElems) : [];
          const currentTabIndex = tabElemsArray.findIndex(tabElem => tabElem === e.target);
          const nextTabIndex = currentTabIndex === 0 ? tabElemsArray.length - 1 : currentTabIndex - 1;
          onSelect(nextTabIndex);
        }
        if (!disableEnhancedKeyboardNav && e.key === 'ArrowDown') {
          tabPanelRef?.current?.firstChild?.focus();
        }
        onKeyboardActivation(() => {
          onSelect(value);
        });
      };
      const onClick = e => {
        e.preventDefault();
        onSelect(value);
      };
      return {
        ref: active ? activeRef : undefined,
        onKeyDown,
        onClick,
        id: `${tabsId.current}-tab-${key}`,
        role: 'tab',
        tabIndex: active ? 0 : -1,
        'aria-selected': active,
        'aria-controls': `${tabsId.current}-panel-${key}`
      };
    }
  };
}