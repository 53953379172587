import { useEffect } from 'react';
export const isEventInBounds = (e, maybeRef, offset = 0) => {
  const ref = typeof maybeRef === 'function' ? maybeRef() : maybeRef;
  const target = e?.target;
  if (!target || !target.isConnected) return false;
  return ref.current ? ref.current.contains(target) : false;
};
export const useClickOutside = (refs, callback, attachHandler = true) => {
  useEffect(() => {
    let mouseDownWasOutside = false;
    const handleMouseUp = e => {
      if (mouseDownWasOutside && refs.every(ref => !isEventInBounds(e, ref))) {
        requestAnimationFrame(() => callback?.());
        mouseDownWasOutside = false;
      }
    };

    // Since the click handler is attached to the document, it will be triggered
    // even if the click was initiated inside the element and finished outside,
    // this way, we check the mousedown was executed outside.
    const handleMouseDown = e => {
      mouseDownWasOutside = refs.every(ref => !isEventInBounds(e, ref));
    };
    if (attachHandler) {
      document.addEventListener('mousedown', handleMouseDown);
      document.addEventListener('mouseup', handleMouseUp);
    } else {
      document.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('mouseup', handleMouseUp);
    }
    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [attachHandler, callback, refs]);
};