import { QueryWorkspaceProjectSelectDocument } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { useCallback } from 'react';
import { gql } from '@clubhouse/datalayer';
import { Nouns } from '@clubhouse/shared/constants';
import { GroupedOptionsFilter, useFetchGroupedOptions } from 'components/gql/filters/GroupedOptionsFilter';
import { useFilterOptionsQuery } from 'components/gql/filters/hooks/useFilterOptionsQuery';
import { useFilterState } from 'components/gql/filters/hooks/useFilterState';
import { getSection } from './utils/sections';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const QUERY_WORKSPACE_PROJECT_SELECT = QueryWorkspaceProjectSelectDocument;
export function ProjectsFilter({
  entityType
}) {
  const [selectedValues, setSelectedValues] = useFilterState({
    paramName: 'project_ids',
    paramType: 'string[]'
  });
  const _fetchProjects = useFilterOptionsQuery({
    query: QUERY_WORKSPACE_PROJECT_SELECT,
    selectedIds: selectedValues
  });
  const fetchProjects = useCallback(async args => {
    const connection = await _fetchProjects(args);
    return {
      ...connection,
      edges: (connection?.edges || []).map(edge => ({
        ...edge,
        groupKey: edge.group?.id
      }))
    };
  }, [_fetchProjects]);
  const {
    fetchSections,
    items,
    totalItemCount
  } = useFetchGroupedOptions({
    fetchOptions: fetchProjects,
    getSection,
    selectedValues,
    filterType: Nouns.Project
  });
  return _jsx(GroupedOptionsFilter, {
    fetchSections: fetchSections,
    items: items,
    totalItemCount: totalItemCount,
    selectedValues: selectedValues,
    onChange: setSelectedValues,
    entityType: entityType,
    filterType: Nouns.Project,
    icon: "Project"
  });
}
ProjectsFilter.displayName = "ProjectsFilter";