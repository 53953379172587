import { Button } from '@clubhouse/shared/components/Button';
import { Dialog, DialogText, DialogTitle } from '@clubhouse/shared/components/Dialog';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const ConfirmationDialog = ({
  buttonKind,
  onClick,
  cta,
  title,
  body,
  onClose,
  visible
}) => {
  return _jsxs(Dialog, {
    ActionComponent: ({
      onClose
    }) => _jsxs(_Fragment, {
      children: [_jsx(Button, {
        kind: Button.KIND.SECONDARY,
        onClick: onClose,
        children: "Cancel"
      }), _jsx(Button, {
        kind: buttonKind,
        onClick: onClick,
        children: cta
      })]
    }),
    onClose: onClose,
    visible: visible,
    children: [_jsx(DialogTitle, {
      children: title
    }), _jsx(DialogText, {
      children: body
    })]
  });
};
ConfirmationDialog.displayName = "ConfirmationDialog";