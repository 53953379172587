import ExampleImg from '@clubhouse/assets/png/screenshots/example-time-spent-in-wf-state-chart-v3.png';
import { KNOWLEDGE_BASE_SLUGS } from 'utils/knowledgeBase';
import { getCurrentPage, getSlugPath } from 'utils/navigation';
import { usesIterations } from 'utils/tests';
import { ReportExplanation } from '../../shared/reports/ReportExplanation';
import { ReportsHelpFooter } from '../ReportsHelpFooter';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const Description = ({
  showWorkingDaysData,
  page
}) => _jsxs(_Fragment, {
  children: [_jsx("p", {
    children: "The Time Spent in Workflow State chart shows how long completed Stories spent in each workflow state. As workflow states are unique for each workflow, only one workflow can be viewed at a time."
  }), _jsxs("p", {
    children: ["The x-axis represents time.\xA0", page === 'reports' ? _jsxs(_Fragment, {
      children: ["The intervals can be changed by using the Group By filter at the top of the page.\xA0", usesIterations() && _jsx(_Fragment, {
        children: "Note that Group By Iteration is not supported by this chart.\xA0"
      }), "See Group By help for more details.\xA0"]
    }) : _jsx(_Fragment, {
      children: "The Group By interval (Day, Week, or Month) is calculated based on the number of days in the date range.\xA0"
    })]
  }), _jsxs("ul", {
    children: [_jsxs("li", {
      children: ["When Group By is set to ", _jsx("strong", {
        children: "Day,"
      }), " all Stories completed that day ", _jsx("em", {
        children: "and the previous 6 days"
      }), ' ', "will be included in the calculation. This allows for a trending calculation to help smooth out daily fluctuations.\xA0", showWorkingDaysData && _jsxs(_Fragment, {
        children: ["Additionally, non-working days will be highlighted on the chart. Working days can be adjusted in your", ' ', _jsx("a", {
          href: `${getSlugPath()}/settings/workspace`,
          children: "Workspace Settings"
        }), "."]
      })]
    }), _jsxs("li", {
      children: ["When Group By is set to ", _jsx("strong", {
        children: "Week,"
      }), " all Stories completed that week ", _jsx("em", {
        children: "and the previous 6 days"
      }), ' ', "will be included in the calculation. This is 13 days in total and will further smooth the trend lines."]
    }), _jsxs("li", {
      children: ["When Group By is set to ", _jsx("strong", {
        children: "Month,"
      }), " all Stories completed in that month will be included in the calculation. This should be the smoothest trend line overall and will help you see if your team is making consistent, long term improvements in how work progresses."]
    })]
  }), _jsxs("p", {
    children: ["The y-axis represents the number of amount of time (in days) spent in each workflow state. This number will change depending on the calculation type selected on the right. For more information about the different calculation types,\xA0", _jsxs("a", {
      href: KNOWLEDGE_BASE_SLUGS.REPORTS_CALCULATION_TYPES,
      target: "_blank",
      rel: "noreferrer",
      children: ["visit our Help Center here. ", _jsx("span", {
        className: "fa fa-external-link"
      })]
    })]
  }), _jsx("p", {
    children: "You can change the visualization type by selecting stacked area, stacked bar, or line in the dropdown on the right. The stacked area and bar charts are helpful for visualizing the total number of days spent in all workflow states for a particular interval. For example, if we are displaying 2 days \"In Development\" and 1 day \"In Review\", these workflow states will be stacked on top of each other, displaying a total of 3 days on the y-axis. The line chart is not stacked and is helpful for comparing time spent in different states."
  })]
});
export const Explanation = ({
  isEmbedded = false
}) => {
  return _jsx(ReportExplanation, {
    isEmbedded: isEmbedded,
    title: `${!isEmbedded ? 'About the ' : ''} Time Spent In Workflow State Chart`,
    imgSrc: ExampleImg,
    description: _jsx(Description, {
      showWorkingDaysData: true,
      page: getCurrentPage()
    }),
    footer: _jsx(ReportsHelpFooter, {})
  });
};
Explanation.displayName = "Explanation";