import Backend from '../modules/backend';
import LocalStorage from '../modules/localStorage';
import Log from '../modules/log';
import MessageController from './message';
import Url from '../modules/url';
import User from '../modules/user';
import UserModel from '../models/user';
import Utils from '../modules/utils';
import * as ServiceWorkerUtils from 'components/shared/utils/serviceWorker';
import { clearPersistentCache as clearPersistentApolloCache } from 'gql/utils/cacheUtils';
import { pyneEndSession } from '../../../../../utils/monitoring/monitors/pyne';
import _ from 'lodash';
const exports = {};
exports.gracefulLogout = () => {
  const user = User.getCurrentUser() || {};
  Log.log('logout', {
    name: user.name,
    email: _.get(UserModel.getDefaultEmail(user), 'email_address')
  });
  exports.logoutAndRedirect();
  return false;
};
exports.gracefulLogoutEverywhere = () => {
  const user = User.getCurrentUser() || {};
  Log.log('logout everywhere', {
    name: user.name,
    email: _.get(UserModel.getDefaultEmail(user), 'email_address')
  });
  exports.logoutAndRedirect({
    everywhere: true
  });
  return false;
};
exports.endImpersonation = () => {
  Backend.get('/end-impersonation', {
    onComplete: err => {
      if (_.get(err, 'error') && User.isLoggedIn()) {
        MessageController.error(err.error, {
          secondary: 'Ending impersonation failed.'
        });
      } else {
        User.setCurrentUser(null);
        LocalStorage.clear();
        const url = '/organizations';
        if (Utils.isNotCurrentPage(url)) {
          Utils.redirect(url);
        } else {
          Utils.reloadPage();
        }
      }
    }
  });
  return false;
};
exports.logoutAndRedirect = options => {
  options = options || {};
  const destination = '?dest=' + encodeURIComponent(Url.getCurrentPathnameWithSearchAndHash());
  const message = options.messageCode ? '&msg=' + options.messageCode : '';
  const url = options.url || Url.getSlugPath() + '/login' + destination + message;
  ServiceWorkerUtils.unregisterAndDeleteCache();
  clearPersistentApolloCache();
  Backend.get('/logout' + (options.everywhere ? '?everywhere=true' : ''), {
    onComplete: async (err, xhr) => {
      // Handle case where user session has already been invalidated.
      if (_.get(err, 'error') && User.isLoggedIn() && xhr.status !== 401) {
        MessageController.error(err.error, {
          secondary: 'You are still currently logged in.'
        });
      } else {
        await pyneEndSession();
        User.setCurrentUser(null);
        LocalStorage.clear();
        if (Utils.isNotCurrentPage(url)) {
          Utils.redirect(url);
        } else {
          Utils.reloadPage();
        }
      }
    }
  });
  return false;
};
export { exports as default };