import "core-js/modules/es.array.push.js";
import { oneOf } from '@charpeni/one-of';
import { getRandomFloatExclusive } from '@clubhouse/shared/utils/random';
const COLORS = [
// Brand Guideline Colors per https://design.shortcut.com/brand-guidelines
'#FFCD7B', '#58B1E4', '#E75569', '#71B259', '#8F809D'];
const DEG_TO_RAD = Math.PI / 180;
const SPEED = 1;
const MARGIN = 20;
const DEFAULT_COUNT = 50;
const createCanvas = () => {
  const canvas = document.createElement('canvas');
  document.body.appendChild(canvas);
  canvas.width = window.innerWidth;
  canvas.height = window.innerHeight;
  canvas.setAttribute('style', 'position: fixed; top: 0; left: 0; width: 100%; height: 100%; pointer-events: none; z-index: 999999;');
  return canvas;
};
const createParticle = (ctx, {
  images,
  delay = 0,
  origin,
  force = 15,
  angle = 0,
  spread = 360
}, onCreate) => {
  let dir, imgs, vx, vy, x, y;
  if (images) {
    imgs = images.map(url => {
      const img = new Image();
      img.src = url;
      return img;
    });
  }
  if (origin) {
    // "Burst" confetti
    x = origin.x;
    y = origin.y;
    vx = getRandomFloatExclusive(force, 5);
    vy = getRandomFloatExclusive(force, 5);
    dir = getRandomFloatExclusive(angle + spread / 2, angle - spread / 2) * DEG_TO_RAD;
  } else {
    // "Fall" confetti
    x = getRandomFloatExclusive(ctx.canvas.width);
    y = 0;
    vx = getRandomFloatExclusive(5);
    vy = getRandomFloatExclusive(5, 1);
    dir = getRandomFloatExclusive(180) * DEG_TO_RAD;
  }
  const dx = Math.cos(dir);
  const dy = Math.sin(dir);
  const p = {
    life: 0,
    delay: delay / 1e3,
    x,
    y,
    angle: getRandomFloatExclusive(360),
    da: getRandomFloatExclusive(90, -90),
    dx: dx * vx,
    dy: dy * vy,
    w: getRandomFloatExclusive(12, 8),
    h: getRandomFloatExclusive(6, 2),
    color: oneOf(COLORS),
    gx: 0,
    gy: getRandomFloatExclusive(4.5, 2),
    xw: getRandomFloatExclusive(6, 1),
    img: imgs ? oneOf(imgs) : undefined
  };
  if (onCreate) {
    return onCreate(p);
  }
  return p;
};
const createParticles = (ctx, options) => {
  if (!Array.isArray(options)) {
    options = [options];
  }
  const particles = [];
  for (const {
    count = DEFAULT_COUNT,
    onCreateParticle,
    ...particleOptions
  } of options) {
    for (let i = 0; i < count; ++i) {
      particles.push(createParticle(ctx, particleOptions, onCreateParticle));
    }
  }
  return particles;
};
const renderParticle = (ctx, {
  x,
  y,
  angle,
  w,
  h,
  color,
  img,
  delay,
  life
}) => {
  if (life < delay) return;
  ctx.save();
  ctx.fillStyle = color;
  ctx.translate(x, y);
  ctx.rotate(angle * DEG_TO_RAD);
  if (img) {
    ctx.drawImage(img, -img.width / 2, -img.height / 2);
  } else {
    ctx.beginPath();
    ctx.rect(w * -0.5, h * -0.5, w, h);
    ctx.fill();
  }
  ctx.restore();
};
const renderParticles = (ctx, particles) => {
  ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
  ctx.shadowColor = 'rgba(0, 0, 0, 0.5)';
  ctx.shadowBlur = 0.5;
  ctx.shadowOffsetY = 1;
  for (let i = 0; i < particles.length; ++i) {
    if (particles[i]) {
      renderParticle(ctx, particles[i]);
    }
  }
};
const updateParticle = (p, dt, onUpdate) => {
  p.life += dt;
  if (p.life < p.delay) return;
  p.angle += p.da * SPEED * dt;
  p.dy += p.gy * SPEED * dt;
  p.dx += getRandomFloatExclusive(4, 2) * Math.sin(p.life * p.xw) * SPEED * dt;
  p.dx *= 0.98;
  p.dy *= 0.98;
  p.x += p.dx * SPEED;
  p.y += p.dy * SPEED;
  onUpdate?.(p, dt);
};
const updateParticles = (ctx, dt, particles, onUpdateParticle) => {
  let validParticles = 0;
  for (let i = 0; i < particles.length; ++i) {
    if (particles[i]) {
      updateParticle(particles[i], dt, onUpdateParticle);
      if (particles[i].life > particles[i].delay && (particles[i].x < -MARGIN || particles[i].x > ctx.canvas.width + MARGIN || particles[i].y > ctx.canvas.height + MARGIN)) {
        particles[i] = null;
        continue;
      }
      ++validParticles;
    }
  }
  return validParticles > 0;
};
const create = () => {
  let canvas,
    ctx,
    frameId,
    options,
    particles,
    playing = false,
    ts;
  const _stop = () => {
    cancelAnimationFrame(frameId);
    canvas?.remove();
    playing = false;
  };
  const _play = (_options = {}) => {
    if (playing) return;
    playing = true;
    options = _options;
    canvas = createCanvas();
    ctx = canvas.getContext('2d');
    particles = createParticles(ctx, options);
    ts = performance.now();
    frameId = requestAnimationFrame(_run);
  };
  const _run = tn => {
    const dt = tn - ts;
    ts = tn;
    renderParticles(ctx, particles);
    if (!updateParticles(ctx, dt / 1e3, particles, options.onUpdateParticle)) {
      _stop();
    } else {
      frameId = requestAnimationFrame(_run);
    }
  };
  return {
    play: _play,
    stop: _stop
  };
};
export function render(options) {
  const instance = create();
  instance.play(options);
  return instance;
}