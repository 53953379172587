import Backend from '../modules/backend';
import Collection from '../_frontloader/collection';
import ProfileModel from './profile';
import UserModel from './user';
import _ from 'lodash';
const exports = {};

/*

Example model as of 9/25/15:

{
  confirmed: false,
  created_at: "2015-09-01T13:39:59Z",
  email_address: "andrew@shortcut.com",
  id: "55e5aaaf-78c2-4d54-aa7f-0cda08a2f8fc",
  primary: true,
  updated_at: "2015-09-01T13:39:59Z"
}

*/

Collection.create('Email', exports);
exports.isValid = obj => {
  return obj && obj.id && obj.email_address;
};
exports.getByAddress = emailAddress => {
  return exports.get({
    email_address: emailAddress
  });
};
exports.getCurrentUsersPrimaryEmail = () => {
  const id = _.get(UserModel.getAllLoggedInProfileDetails(), 'email_id');
  return exports.getById(id) || UserModel.getDefaultEmail();
};
exports.fetchAll = callback => {
  Backend.get('/api/private/emails', {
    onComplete: res => {
      exports.defaultFetchAllHandler(res, callback);
    }
  });
};
exports.setAsUserPrimary = (email, callback) => {
  Backend.put('/api/private/emails/' + email.id + '/primary', {
    onComplete: (res, xhr) => {
      UserModel.fetchCurrentUser(() => {
        exports.defaultNoResponseHandler(res, xhr, callback);
      });
    }
  });
};
exports.setAsOrgPrimary = (email, callback) => {
  ProfileModel.updateProfile({
    email_id: email.id
  }, (err, res) => {
    UserModel.fetchAll(() => {
      callback(err, res);
    });
  });
};
exports.sendConfirmation = (email, callback) => {
  Backend.put('/api/private/emails/' + email.id + '/confirm', {
    onComplete: (res, xhr) => {
      UserModel.fetchCurrentUser(() => {
        exports.defaultNoResponseHandler(res, xhr, callback);
      });
    }
  });
};
exports.addNewEmail = (data, callback) => {
  callback = _.isFunction(callback) ? callback : _.noop;
  Backend.post('/api/private/emails', {
    data,
    onComplete: res => {
      if (exports.isValid(res)) {
        exports.sendConfirmation(res, callback);
      } else {
        exports.defaultErrorHandler(res, callback);
      }
    }
  });
};
exports.deleteEmail = (email, callback) => {
  callback = _.isFunction(callback) ? callback : _.noop;
  Backend.delete('/api/private/emails/' + email.id, {
    onComplete: (res, xhr) => {
      if (xhr.status === 204) {
        exports.remove({
          id: email.id
        });
        UserModel.fetchCurrentUser(callback);
      } else {
        exports.defaultErrorHandler(res, callback);
      }
    }
  });
};
exports.confirmEmail = (email_id, confirm_code, callback) => {
  Backend.get('/api/private/emails/' + email_id + '/confirm/' + confirm_code, {
    onComplete: res => {
      exports.defaultGetHandler(res, callback);
    }
  });
};
export { exports as default };