import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import { getTeamScopedURL, useTeamScopeId } from 'components/team-navigation';
import { createBackgroundLocationState } from './backgroundLocationState';
import { generatePathForPage } from './paths';
import { useReplaceState } from './useReplaceState';
import { useWorkspaceSlug } from './useWorkspaceSlug';
export const useSetBackgroundPage = () => {
  const replaceState = useReplaceState();
  const slug = useWorkspaceSlug();
  const {
    value: teamId
  } = useTeamScopeId();
  return ({
    pageName
  }) => {
    replaceState(() => {
      return {
        state: createBackgroundLocationState(getTeamScopedURL(new URL(generatePathForPage(pageName, {
          slug
        }), window.location.origin).href, teamId))
      };
    });
  };
};