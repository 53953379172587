import Collection from '../_frontloader/collection';
import Backend from '../modules/backend';
import Url from '../modules/url';
import _ from 'lodash';
const exports = {
  Promises: {}
};
Collection.create('Import', exports);
exports.generateImportURL = importModel => {
  return Url.getSlugPath() + '/settings/import/' + importModel.id;
};
exports.getFromUrl = url => {
  const id = _.last(url.split('/'));
  return exports.getById(id);
};
const withResults = (resolve, reject) => (error, result) => {
  error ? reject(error) : resolve(result);
};
exports.Promises.get = () => new Promise(resolve => {
  Backend.get('/api/private/imports', {
    onComplete: res => {
      exports.defaultFetchAllHandler(res, () => resolve(res));
    }
  });
});
exports.Promises.fetchById = importId => new Promise((resolve, reject) => {
  Backend.get(`/api/private/imports/${importId}`, {
    onComplete: res => {
      exports.defaultGetHandler(res, withResults(resolve, reject));
    }
  });
});
exports.Promises.create = formData => new Promise((resolve, reject) => {
  Backend.post('/api/private/imports', {
    data: {
      source: formData.importType,
      source_container_id: formData.boardId,
      auth_key: formData.authKey,
      auth_token: formData.authToken,
      api_hostname: formData.apiHostname
    },
    onComplete: res => {
      exports.defaultGetHandler(res, withResults(resolve, reject));
    },
    onError: reject
  });
});
exports.Promises.undo = importId => new Promise((resolve, reject) => {
  Backend.put(`/api/private/imports/${importId}/undo`, {
    onComplete: res => {
      exports.defaultGetHandler(res, withResults(resolve, reject));
    }
  });
});
exports.Promises.getMapping = importId => new Promise((resolve, reject) => {
  Backend.get(`/api/private/imports/${importId}/mapping`, {
    onComplete: res => {
      if (res.members) {
        resolve(res);
      } else {
        exports.defaultErrorHandler(res, err => reject(err));
      }
    }
  });
});
exports.Promises.setMapping = (importId, importMap) => new Promise(resolve => {
  Backend.put(`/api/private/imports/${importId}/mapping`, {
    data: importMap,
    onComplete: res => {
      exports.defaultGetHandler(res, () => resolve(res));
    }
  });
});
exports.Promises.setMappingByType = (importId, importMap, importType) => new Promise(resolve => {
  Backend.put(`/api/private/imports/${importId}/${importType}/mapping`, {
    data: importMap,
    onComplete: res => {
      exports.defaultGetHandler(res, () => resolve(res));
    }
  });
});
exports.Promises.commitMapping = importId => new Promise(resolve => {
  Backend.post(`/api/private/imports/${importId}/commit-mapping`, {
    onComplete: res => {
      exports.defaultGetHandler(res, () => resolve(res));
    }
  });
});
exports.Promises.commitMappingByType = (importId, importType) => new Promise(resolve => {
  Backend.post(`/api/private/imports/${importId}/${importType}/commit-mapping`, {
    onComplete: res => {
      exports.defaultGetHandler(res, () => resolve(res));
    }
  });
});
exports.Promises.getStats = importId => new Promise(resolve => {
  Backend.get(`/api/private/imports/${importId}/stats`, {
    onComplete: res => {
      exports.defaultGetHandler(res, () => resolve(res));
    }
  });
});
exports.Promises.mergePermissions = (ghostPermissionId, memberPermissionId) => new Promise((resolve, reject) => {
  Backend.post(`/api/private/imports/merge-permission`, {
    data: {
      import_permission_id: ghostPermissionId,
      replacement_permission_id: memberPermissionId
    },
    onComplete: res => {
      if (res.error) {
        reject(res);
      }
      resolve(res);
    }
  });
});
export { exports as default };