import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.set.difference.v2.js";
import "core-js/modules/esnext.set.intersection.v2.js";
import "core-js/modules/esnext.set.is-disjoint-from.v2.js";
import "core-js/modules/esnext.set.is-subset-of.v2.js";
import "core-js/modules/esnext.set.is-superset-of.v2.js";
import "core-js/modules/esnext.set.symmetric-difference.v2.js";
import "core-js/modules/esnext.set.union.v2.js";
import times from 'lodash/times';
import moment from 'moment';
import { useMemo } from 'react';
import { useEffectOnce } from 'react-use';
import { StoryTypes } from '@clubhouse/shared/types';
import { getIsOverdue } from '@clubhouse/shared/utils';
import StoryController from 'app/client/core/js/controllers/story';
import CommentModel from 'app/client/core/js/models/comment';
import FilterModel from 'app/client/core/js/models/filter';
import LabelModel from 'app/client/core/js/models/label';
import StoryModel from 'app/client/core/js/models/story';
import Utils from 'app/client/core/js/modules/utils';
import { useEntities, useEntity, useEntityWithFetch, useOptimizedEntity } from './collection';
import { getById as getWorkflowTeamById } from './team';

/**
 * @deprecated Use Shapes icons instead
 * @note This is duplicated from constants.js because I got problems otherwise.
 *       This doesn't change though and should be removed, so hopefully okay.
 */
export const STORY_TYPE_ICONS = {
  feature: 'fa-certificate',
  bug: 'fa-bug',
  chore: 'fa-wrench'
};
export const subscribeTo = (event, fn) => {
  StoryModel.on(event, fn);
  return () => StoryModel.off(event);
};
export const fetchAllOldCompletedStories = (options, callback) => StoryModel.fetchAllOldCompletedStories(options, callback);

// BEWARE: This really does not do anything to the story other than tack on some meta data
//  the meta data is reactive, but not the story data itself!
export const getStoryFull = ({
  story
}) => {
  return {
    ...story,
    badges: StoryController.getNewBadgesForStory(story),
    specialTags: StoryController.getSpecialTagsForStory(story),
    labels: story.labels.map(label => ({
      ...label,
      ...LabelModel.getById(label.id)
    }))
  };
};
export const createLinkedStory = (template, data) => StoryController.createLinkedStory(template, data);
export const getPullRequests = storyId => {
  return StoryModel.getPullRequests(getById(storyId));
};
export const isUsingGitLab = storyId => {
  return StoryModel.isUsingGitLab(getById(storyId));
};
export const deleteStory = id => StoryModel.Promises.deleteStory({
  id
});
export const fetchStory = storyId => {
  StoryModel.fetchStory(storyId);
};
export const fetchStoryPromise = storyId => StoryModel.Promises.fetchStory(storyId);
export const getStoriesById = storyIds => new Promise(resolve => {
  StoryModel.getStoriesById(storyIds, () => resolve());
});
export const getOrFetchStory = id => StoryModel.getOrFetchStory(id);
export const createTemplateFromStory = story => StoryController.createTemplateFromStory(story);
export const isValidForAutoLink = story => StoryModel.isValidForAutoLink(story);
export const all = () => StoryModel.all();
export const getById = id => StoryModel.getById(id);
export const isNew = story => StoryModel.isNew(story) || !story.id;
export const isDoneState = story => StoryModel.isDoneState(story);
export const isFeature = story => StoryModel.isFeature(story);
export const isBug = story => StoryModel.isBug(story);
export const isChore = story => StoryModel.isChore(story);
export const isOverdue = story => getIsOverdue(story.deadline);
export const allWithLabel = ({
  id
}) => StoryModel.allWithLabel({
  id
});
export const useStories = ({
  epicId,
  projectId,
  iterationId,
  labelId,
  text,
  archived,
  includeArchived
} = {}) => {
  const {
    entities
  } = useEntities({
    model: StoryModel
  });
  const filtered = useMemo(() => {
    const hasFilters = Boolean(epicId || projectId || labelId || text || iterationId || typeof archived === 'boolean' || typeof includeArchived === 'boolean');
    if (!hasFilters) return entities;
    const storyMatchesText = getFilterStoryFunction(text);
    return entities.filter(story => {
      if (!storyMatchesEpic(story, epicId)) return false;
      if (!storyMatchesProject(story, projectId)) return false;
      if (!storyMatchesIteration(story, iterationId)) return false;
      if (!storyMatchesLabel(story, labelId)) return false;
      if (!storyMatchesArchived(story, archived)) return false;
      if (includeArchived === false && story.archived) return false;

      // Add additional filters here

      // Put this one last cuz it's expensive
      if (text && !storyMatchesText(story)) return false;
      return true;
    });
  }, [epicId, projectId, labelId, text, iterationId, archived, includeArchived, entities]);
  return {
    stories: filtered
  };
};
const storyMatchesProject = (story, projectId) => !projectId || story.project_id === projectId;
const storyMatchesEpic = (story, epicId) => !epicId || story.epic_id === epicId;
const storyMatchesIteration = (story, iterationId) => !iterationId || story.iteration_id === iterationId;
const storyMatchesLabel = (story, labelId) => !labelId || story.label_ids.includes(labelId);
const storyMatchesArchived = (story, archived) => archived === undefined || story.archived === archived;
export const normalize = story => StoryModel.normalize(story);
export const getBranchName = story => StoryModel.toBranchName(story);
export const generateStoryUrl = story => StoryModel.generateStoryURL(story);
export const isArchived = story => story.archived;
export const hasArchivedParent = story => StoryModel.hasArchivedParent(story);
export const isActive = story => StoryModel.isActive(story);
export const isActiveState = story => StoryModel.isActiveState(story);
export const save = ({
  id,
  ...changes
}) => StoryModel.Promises.serverSaveBasic({
  id,
  ...changes
});
export const addStory = (story, options) => StoryModel.Promises.addStory(story, options);
export const searchForUnfinishedAndRecentlyFinishedStories = async ({
  query
}) => {
  const responses = await Promise.all([StoryModel.Promises.searchStories({
    query: {
      workflow_state_types: ['done'],
      completed_at_start: moment().subtract(30, 'days').format(),
      ...query
    }
  }), StoryModel.Promises.searchStories({
    query: {
      workflow_state_types: ['started', 'unstarted', 'backlog'],
      ...query
    }
  })]);
  return responses.reduce((acc, response) => [...acc, ...response], []);
};
export const fetchRecentStoriesForWorkflows = async ({
  workflowIds,
  shouldUsePagination = false
}) => {
  const results = await Promise.allSettled(workflowIds.map(id => shouldUsePagination ? fetchAllPagesOfRelevantStoriesForWorkflow({
    id
  }) : StoryModel.Promises.fetchRecentStoriesForWorkflow({
    id
  })));
  return results.filter(result => result.status === 'fulfilled').flatMap(result => result.value);
};
export function fetchRecentStoriesForWorkflowTeam(workflowTeamId) {
  const workflowId = getWorkflowTeamById(workflowTeamId)?.workflow?.id;
  if (!workflowId) return Promise.reject(new Error('Workflow team not found'));
  return StoryModel.Promises.fetchRecentStoriesForWorkflow({
    id: workflowId
  });
}
export function fetchRecentStoriesForWorkflow(id) {
  return StoryModel.Promises.fetchRecentStoriesForWorkflow({
    id
  });
}
const LIMIT = 1000;
const INITIAL_LIMIT = 100;
const fetchAllPagesOfRelevantStoriesForWorkflow = async ({
  id
}) => {
  const [{
    pageInfo: {
      totalSize
    }
  }, {
    edges
  }] = await Promise.all([StoryModel.Promises.fetchRecentStoriesForWorkflowCount({
    id
  }), StoryModel.Promises.fetchRecentStoriesForWorkflowPage({
    id,
    limit: INITIAL_LIMIT,
    offset: 0
  })]);
  if (totalSize <= INITIAL_LIMIT) {
    return edges;
  }
  const numberOfRequests = Math.ceil(totalSize / LIMIT);
  const promises = await Promise.allSettled(times(numberOfRequests).map(i => StoryModel.Promises.fetchRecentStoriesForWorkflowPage({
    id,
    limit: LIMIT,
    offset: INITIAL_LIMIT + LIMIT * i
  })));
  const results = promises.filter(result => result.status === 'fulfilled').flatMap(result => result.value.edges);
  return edges.concat(results);
};
export const useStory = ({
  id
}) => {
  const {
    entity
  } = useEntity({
    model: StoryModel,
    id
  });
  return entity;
};
export const useStoryWithFetch = ({
  id
}) => {
  const {
    entity,
    loading
  } = useEntityWithFetch({
    model: StoryModel,
    id,
    fetchById: fetchStoryPromise
  });
  return {
    story: entity,
    loading
  };
};

/**
 * @deprecated Use useStoryWithFetch instead.
 */
export const useAndMaybeFetchStory = ({
  id
}) => {
  const {
    entity
  } = useEntity({
    model: StoryModel,
    id
  });
  useEffectOnce(() => {
    setTimeout(() => {
      if (!StoryModel.getById(id)) {
        StoryModel.fetchStory(id);
      }
    }, 1000);
  });
  return entity;
};
export const hasEpic = (story, epic) => StoryModel.hasEpic(story, epic);
export const removeEpic = (story, callback) => StoryModel.removeEpic(story, callback);
export const addEpic = (story, epic, callback) => StoryModel.addEpic(story, epic, callback);
export const isOwner = (story, profile) => StoryModel.isOwner(story, profile);
export const toggleOwner = (story, profile, callback) => StoryModel.toggleOwner(story, profile, callback);
export const saveOwner = ownerIds => StoryModel.saveOwnerChange({
  owner_ids: ownerIds
});
export const saveCustomFieldValue = (story, fieldId, valueId) => {
  const patchedCustomFields = story.custom_fields
  // Remove the current field, if it exists, we're going to change it anyway
  .filter(({
    field_id
  }) => field_id !== fieldId)
  // Remove any extra properties that the backend might die on
  .map(({
    field_id,
    value_id
  }) => ({
    field_id,
    value_id
  }));

  // Add the changed field and selected value if one was chosen (NONE handling)
  if (valueId) patchedCustomFields.push({
    field_id: fieldId,
    value_id: valueId
  });
  return saveChanges(story.id, {
    custom_fields: patchedCustomFields
  });
};
export const addComment = (storyId, comment, parentId) => new Promise((resolve, reject) => {
  CommentModel.saveComment({
    id: storyId
  }, comment, parentId, (err, res) => {
    if (err) reject(err);else resolve(res);
  });
});
export const updateComment = (storyId, commentId, comment) => new Promise((resolve, reject) => {
  CommentModel.updateComment({
    id: storyId
  }, commentId, comment, (err, res) => {
    if (err) reject(err);else resolve(res);
  });
});
export const deleteFileAttachment = (storyId, fileId) => {
  StoryModel.deleteFileAttachment(getById(storyId), fileId);
};
export const deleteLinkedFileAttachment = (storyId, fileId) => {
  StoryModel.deleteLinkedFileAttachment(getById(storyId), fileId);
};
export const deleteComment = (storyId, commentId) => new Promise((resolve, reject) => {
  CommentModel.deleteComment(storyId, commentId, err => {
    if (err) reject(err);else resolve();
  });
});
export const addReaction = (storyId, commentId, reaction) => {
  CommentModel.addReaction(storyId, commentId, reaction);
};
export const deleteReaction = (storyId, commentId, reaction) => {
  CommentModel.deleteReaction(storyId, commentId, reaction);
};
export const unlinkFromSlack = (storyId, commentId) => new Promise((resolve, reject) => CommentModel.unlinkFromSlack(storyId, commentId, err => err ? reject(err) : resolve()));
export const saveChanges = async (id, changes) => {
  return StoryModel.Promises.serverSave({
    id
  }, changes);
};
export const addTeam = (story, teamId, callback) => StoryModel.addTeam(story, teamId, callback);
export const removeTeam = (story, callback) => StoryModel.removeTeam(story, callback);
export const archiveStory = (story, callback) => StoryModel.archiveStory(story, callback);
export const getFormattedName = story => StoryModel.getFormattedName(story);
export const getImpliedLabels = story => StoryModel.getImpliedLabels(story);
export const getDeadlineClass = story => StoryController.getDeadlineClass(story);
export const describeFollowers = story => StoryModel.describeFollowers(story);
export const describeFollowersOrNull = story => StoryModel.describeFollowersOrNull(story);
export const formatEstimate = story => StoryModel.formatEstimate(story);
export const getTeamName = story => StoryModel.getTeamName(story);
export const getWorkflowStateName = story => StoryModel.getWorkflowStateName(story);
export const setWorkflowState = (story, workflowStateId, callback) => StoryModel.setWorkflowState(story, workflowStateId, callback);
export const getStoryTypeIcon = story => StoryModel.getStoryTypeIcon(story);
export const setType = (story, type, callback) => StoryModel.setType(story, type, callback);
export const setIteration = (story, iterationId) => new Promise((resolve, reject) => {
  StoryModel.setIteration(story, iterationId, {
    callback: (err, res) => {
      if (err) reject(err);else resolve(res);
    }
  });
});
export const hasNameOrDescriptionChanged = (updates = {}) => {
  const [key, ...rest] = Object.keys(updates);
  return rest.length === 0 && ['description', 'name'].includes(key);
};
export const saveFollowers = (story, callback) => StoryModel.saveFollowers(story, callback);
export const currentUserIsFollower = story => StoryModel.currentUserIsFollower(story);
export const addMeAsFollower = (story, callback) => StoryModel.addMeAsFollower(story, callback);
export const removeMeAsFollower = (story, callback) => StoryModel.removeMeAsFollower(story, callback);
const cachedNormalizedUpdates = new WeakMap();
const _supportedRelations = new Set(['updated_epics', 'updated_groups', 'updated_iterations', 'updated_projects', 'updated_labels']);
const _getIds = entityUpdates => new Set([...(entityUpdates?.modified?.map(iteration => iteration.id) || []), ...(entityUpdates?.deleted || [])]);
const _getNormalizedUpdates = updates => {
  if (!cachedNormalizedUpdates.has(updates)) {
    cachedNormalizedUpdates.set(updates, Array.from(_supportedRelations.values()).reduce((acc, value) => {
      acc[value] = _getIds(updates[value]);
      return acc;
    }, {}));
  }
  return cachedNormalizedUpdates.get(updates);
};
export const getFilteredStoryType = () => {
  // Retrieve the active type filter
  const typeFilter = FilterModel.get({
    type: 'type',
    active: true
  });
  const isAnd = FilterModel.getFilterType() === 'AND';
  let storyType = StoryTypes.FEATURE;

  // We only want to retrieve the story type on the AND filter type
  if (typeFilter && !typeFilter.inverse && isAnd) {
    storyType = typeFilter.value;
  }
  return storyType;
};
export const supportsAllRelatedChanges = ({
  updates,
  updated_stories,
  ...otherUpdates
}) => Object.keys(otherUpdates).every(key => _supportedRelations.has(key));
export const hasRelatedEntitiesChanged = (updates, story) => {
  const {
    epic_id,
    iteration_id,
    project_id,
    group_id,
    labels
  } = story;
  const {
    updated_epics,
    updated_projects,
    updated_iterations,
    updated_groups,
    updated_labels
  } = _getNormalizedUpdates(updates);
  return epic_id && updated_epics.has(epic_id) || project_id && updated_projects.has(project_id) || iteration_id && updated_iterations.has(iteration_id) || group_id && updated_groups.has(group_id) || labels.some(label => updated_labels.has(label.id));
};
export const useOptimizedStory = id => {
  const story = useOptimizedEntity(id, {
    model: StoryModel,
    hasChanges: (oldValue, newValue) => {
      // biome-ignore lint/suspicious/noDoubleEquals: We want null and undefined to compare as the same
      return oldValue?.updated_at != newValue?.updated_at;
    }
  });
  return story;
};
export const getFilterStoryFunction = query => {
  const terms = Utils.tokenizeQuery(query ?? '');
  return FilterModel.searchStories(terms);
};
const getRelationshipSum = storyResults => storyResults.filter(result => result.status === 'fulfilled' && !result.value.archived && !result.value.completed).length;
export const getBlocksCount = async story => {
  if (!story.blocker || story.completed || story.archived) return 0;
  const storyResults = await Promise.allSettled(story.story_links.filter(link => link.verb === 'blocks' && link.type === 'subject').map(link => getOrFetchStory(link.object_id)));
  return getRelationshipSum(storyResults);
};
export const getBlockedCount = async story => {
  if (!story.blocked || story.completed || story.archived) return 0;
  const storyResults = await Promise.allSettled(story.story_links.filter(link => link.verb === 'blocks' && link.type === 'object').map(link => getOrFetchStory(link.subject_id)));
  return getRelationshipSum(storyResults);
};
export const getNumberOfCompletedTasks = story => StoryModel.getNumberOfCompletedTasks(story);
export const fetchOldCompletedStories = (opts, callback) => StoryModel.fetchOldCompletedStories(opts, callback);
export const toggleSelection = e => StoryController.toggleSelection.call(e.target, e);
export const isNotFiltered = story => StoryModel.isNotFiltered(story);
export const clearSkipFilterStories = () => StoryModel.clearSkipFilterStories();
export const shouldSkipFilters = storyId => StoryModel.shouldSkipFilters(storyId);
export const fetchStoriesApproachingDuedate = () => new Promise((resolve, reject) => StoryModel.fetchStoriesApproachingDuedate(err => {
  if (err) {
    reject(err);
  } else resolve();
}));
export const toBranchName = story => StoryModel.toBranchName(story);
export const searchStories = ({
  query
}) => StoryModel.Promises.searchStories({
  query
});
export const toDemoBranchName = branchFormat => StoryModel.toDemoBranchName(branchFormat);