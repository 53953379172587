import { useCallAll } from './useCallAll';
import { useEpicsByState } from './useEpicsByState';
export { PAGE_SIZE } from './useEpicsByState';
const getColumnProps = ({
  pageInfo,
  error,
  isStale,
  isLoading,
  epics,
  docs,
  estimates,
  updateQuery,
  currentUserId,
  isInitialDataFromCache
}) => {
  const totalEpicCount = pageInfo?.totalSize || 0;
  const pageOffset = pageInfo?.offset || 0;
  const isEmpty = !isLoading && !epics.length && !totalEpicCount;
  return {
    epics,
    currentUserId,
    docs,
    estimates,
    pageOffset,
    totalEpicCount,
    isEmpty,
    isLoading,
    isStale,
    hasError: !!error,
    updateQuery,
    isInitialDataFromCache
  };
};
export function useEpicsColumnData() {
  const unstarted = useEpicsByState('unstarted');
  const started = useEpicsByState('started');
  const done = useEpicsByState('done');
  const refetchAll = useCallAll('refetch', [unstarted, started, done]);
  return {
    columnData: {
      unstarted: getColumnProps(unstarted),
      started: getColumnProps(started),
      done: getColumnProps(done)
    },
    refetchAll
  };
}