import { EpicMoreActionsFieldFragmentFragmentDoc } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { MoreActions } from '@clubhouse/shared/components/MoreActions';
import { ArchiveEpicAction } from '../../actions/ArchiveEpicAction';
import { CopyEpicLinkAction } from '../../actions/CopyEpicLinkAction';
import { DeleteEpicAction } from '../../actions/DeleteEpicAction';
import { DuplicateEpicAction } from '../../actions/DuplicateEpicAction';
import { GoToEpicAction } from '../../actions/GoToEpicAction';
import { PinEpicAction } from '../../actions/PinEpicAction';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const EpicMoreActionsFieldFragment = EpicMoreActionsFieldFragmentFragmentDoc;
function MoreActionsFieldComponent({
  entity
}) {
  return _jsxs(MoreActions, {
    children: [_jsx(MoreActions.TableButton, {}), _jsxs(MoreActions.Menu, {
      children: [_jsx(GoToEpicAction, {
        epic: entity
      }), _jsx(MoreActions.Divider, {}), _jsx(PinEpicAction, {
        epic: entity
      }), _jsx(CopyEpicLinkAction, {
        epic: entity
      }), _jsx(MoreActions.Divider, {}), _jsx(ArchiveEpicAction, {
        epic: entity
      }), _jsx(DuplicateEpicAction, {
        epic: entity
      }), _jsx(DeleteEpicAction, {
        epic: entity
      })]
    })]
  });
}
MoreActionsFieldComponent.displayName = "MoreActionsFieldComponent";
export const MoreActionsField = () => ({
  name: 'more_actions',
  displayName: '',
  Component: MoreActionsFieldComponent,
  LoadingComponent: null,
  width: 44,
  frozen: {
    right: -35
  }
});