import groupBy from 'lodash/groupBy';
import { useCallback } from 'react';
import { getRandomPaletteTint } from '@clubhouse/shared/color';
import { useRequest } from '@clubhouse/shared/hooks/useRequest';
import { causify } from '@clubhouse/shared/utils/causify';
import { DEFAULT_TEAM_TINT as _DEFAULT_TEAM_TINT, getTeamColor } from '@clubhouse/shared/utils/teams';
import GroupModel from 'app/client/core/js/models/group';
import { deprecatedGetTeamScopeIdForNonReactAccess, getTeamScopedURL, useTeamScopeId } from 'components/team-navigation';
import { getById as getWorkflowById } from 'data/entity/workflow';
import { getServerURL } from 'utils/backend';
import { logError } from 'utils/monitoring';
import { useEntities, useEntity, useEntityWithFetch, useOptimizedEntity } from './collection';
import { getCurrentOrgId } from './organization';
export const DEFAULT_TEAM_TINT = _DEFAULT_TEAM_TINT;
export const all = () => GroupModel.all();
export const getGroupByGlobalId = globalId => GroupModel.get({
  global_id: globalId
});
export const getById = id => GroupModel.getById(id);
export const create = (...args) => GroupModel.Promises.create(...args);
export const update = (...args) => GroupModel.Promises.update(...args);
export const find = fn => GroupModel.find(fn);

/**
 * @deprecated Use Group in @clubhouse/shared/src/types/entity.ts
 */

export const getEmptyGroup = () => ({
  app_url: '',
  name: '',
  mention_name: '',
  description: '',
  display_icon: null,
  member_ids: [],
  workflow_ids: [],
  archived: false,
  entity_type: 'group',
  color_key: getRandomPaletteTint(),
  num_epics_started: 0,
  num_stories_started: 0
});
export const getAllActiveGroups = () => GroupModel.filter({
  archived: false
}) ?? [];
export const groupStoriesByGroupId = ({
  stories = []
}) => {
  return stories.reduce((acc, story) => {
    const group = story.group_id ? getById(story.group_id) : undefined;
    const key = group?.id ?? '';
    const {
      count = 0
    } = acc[key] || {};
    return {
      ...acc,
      [key]: {
        group,
        count: count + 1
      }
    };
  }, {});
};
export const getGroupNameById = id => id ? getById(id)?.name : undefined;
export const byGroupNameSortFn = (a, b) => {
  if (a.group_id && !b.group_id) {
    return -1;
  } else if (b.group_id && !a.group_id) {
    return 1;
  } else if (!a.group_id && !b.group_id) {
    return 0;
  }
  const aName = getGroupNameById(a.group_id) || '';
  const bName = getGroupNameById(b.group_id) || '';
  return aName.localeCompare(bName);
};
export const sortGroupByName = (a, b) => a.name.localeCompare(b.name);
export const isDifferentGroup = (a, b) => {
  return a != null && b != null && a !== b;
};
export const isInGroup = ({
  group_id,
  group_ids
}) => {
  return (group_ids || []).some(id => !isDifferentGroup(group_id, id));
};
export const groupHasOneGroupEntityByGroupIds = ({
  set,
  entities
}) => {
  return {
    set: [],
    none: [],
    ...groupBy(entities, entity => set.has(entity.group_id) ? 'set' : 'none')
  };
};
export const groupHasManyGroupEntityByGroupIds = ({
  set,
  entities
}) => {
  return {
    set: [],
    none: [],
    ...groupBy(entities, entity => (entity.group_ids ?? []).some(id => set.has(id)) ? 'set' : 'none')
  };
};
export const isActiveGroup = group => Boolean(group) && !group.archived;
export const useGroups = () => {
  const {
    entities: groups,
    diff: groupDiff
  } = useEntities({
    model: GroupModel
  });
  return {
    groups,
    groupDiff
  };
};
export const useGroup = ({
  id
}) => {
  const {
    entity
  } = useEntity({
    model: GroupModel,
    id
  });
  return entity;
};
export const useGroupWithFetch = id => {
  const {
    entity,
    loading
  } = useEntityWithFetch({
    model: GroupModel,
    id,
    fetchById: fetchGroupById
  });
  return {
    group: entity,
    loading
  };
};
const fetchGroupById = async id => {
  const groups = await rawFetchGroups(getCurrentOrgId());
  return (groups || []).find(group => group.id === id);
};
export const useOptimizedGroup = id => {
  const group = useOptimizedEntity(id, {
    model: GroupModel,
    hasChanges: (oldValue, newValue) => oldValue?.updated_at !== newValue?.updated_at
  });
  return group;
};
export const getGroupTint = group => GroupModel.getGroupTint(group);
export const getGroupColor = group => GroupModel.getGroupColor(group);
export const useUpdateGroupWorkflows = options => {
  const {
    request,
    isRequesting,
    data
  } = useRequest({
    fn: GroupModel.Promises.updateGroupWorkflows
  });
  return {
    request: useCallback(changes => request(changes, options), [options, request]),
    isRequesting,
    data
  };
};
export const getRequiredWorkflows = groupId => {
  return GroupModel.Promises.getRequiredWorkflows(groupId);
};
export const isArchived = group => group.archived;
export const isValidWorkflowForGroup = (groupId, workflowId) => GroupModel.isValidWorkflowForGroup(groupId, workflowId);
export const getTeamColorById = groupId => getTeamColor(GroupModel.getById(groupId));
export const getTeamScopedCollectionizeId = () => {
  const teamId = deprecatedGetTeamScopeIdForNonReactAccess();
  return find(group => group.global_id === teamId)?.id;
};
export const getTeamByTeamScopedId = () => {
  const teamId = deprecatedGetTeamScopeIdForNonReactAccess();
  return find(group => group.global_id === teamId);
};
export const useTeamScopedTeam = () => {
  const {
    value: teamId
  } = useTeamScopeId();
  const {
    groups
  } = useGroups();
  return groups.find(({
    global_id
  }) => global_id === teamId);
};

/**
 * Raw fetch used for cases where the app hasn't been bootstrapped but group data is needed.
 */
export async function rawFetchGroups(workspaceId) {
  try {
    const response = await fetch(`${getServerURL()}/api/private/groups`, {
      headers: {
        'Tenant-Workspace2': workspaceId
      }
    });
    if (!response.ok) {
      throw new Error(`HTTP Error, status code: ${response.status}`, {
        cause: causify(response.json())
      });
    }
    return response.json();
  } catch (e) {
    logError(new Error('Unable to raw fetch groups', {
      cause: causify(e)
    }));
    return null;
  }
}
export async function rawUpdateGroup(workspaceId, groupId, member_ids) {
  try {
    await fetch(`${getServerURL()}/api/private/groups/${groupId}`, {
      method: 'PUT',
      headers: {
        'Tenant-Workspace2': workspaceId,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        member_ids
      })
    });
  } catch (e) {
    logError(new Error('Unable to raw update group', {
      cause: e
    }));
  }
}
export async function fetchAndGetTeamScopedUrl(href, workspaceId, base) {
  const teamId = (await rawFetchGroups(workspaceId))?.[0]?.global_id;
  return getTeamScopedURL(href, teamId, base);
}
export async function fetchAndGetTeamScopedUrlForUser(href, workspaceId, memberId, base) {
  if (!memberId) return fetchAndGetTeamScopedUrl(href, workspaceId, base);
  const teams = await rawFetchGroups(workspaceId);
  const firstTeam = (teams || []).find(team => (team?.member_ids || []).includes(memberId));
  return getTeamScopedURL(href, firstTeam?.global_id ?? null, base);
}
export function hasDuplicateGroupWorkflowStateName(allStories, currentStory) {
  let hasDuplicateStateGroupName = false;

  /** If the current story's groupedBy state name is the same as another story's state name,
   *  but that story belongs to a different workflow we want to distinguish these 2 groups via their heading text.
   **/
  if (allStories) {
    hasDuplicateStateGroupName = allStories.some(story => story.stateObject?.name === currentStory.stateObject?.name && story.stateObject.id !== currentStory.stateObject.id);
  }
  return hasDuplicateStateGroupName;
}
export function getGroupByStateNameHeaderText(story, hasDuplicateStateGroupName) {
  const workflowName = getWorkflowById(story.stateObject.workflow_id)?.name;
  const shouldAppendWorkflowName = hasDuplicateStateGroupName && Boolean(workflowName);
  return story.stateObject?.name ? `${story.stateObject?.name}${shouldAppendWorkflowName ? ` (${workflowName})` : ''}` : 'No State';
}