import { SearchMenu } from '../SearchMenu';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const SEARCH_PREFIX_SLASH = '/';
export const SEARCH_PREFIX_HASH = '#';
const SEARCH_PREFIX_REGEX = new RegExp(`^(?:${SEARCH_PREFIX_SLASH}|${SEARCH_PREFIX_HASH})[a-zA-Z0-9\\-\\_\\.\\+ ]+$`);
export const SearchAutocomplete = {
  hasPrefix: true,
  test: text => !!text && SEARCH_PREFIX_REGEX.test(text),
  Component: ({
    triggerRef,
    fragment
  }) => _jsx(SearchMenu, {
    triggerRef: triggerRef,
    filter: fragment,
    isSearchable: false
  })
};