import { KIND, Popover } from '@clubhouse/shared/components/Popover';
import { useToggleState } from '@clubhouse/shared/hooks';
import { getDefaultUserEmail } from '@clubhouse/shared/utils/user';
import { getAllLoggedInProfileDetails } from 'data/entity/user';
import { useUserOrgAndApplicationStateFetched } from 'utils/consolidatedFetch';
import { withinOrgAndWithSlug } from 'utils/is';
import { ProfileContent } from './ProfileContent';
import { ProfileTrigger } from './ProfileTrigger';
import { useProfileRenderId } from './profileRenderId';
import { useProfile } from './useProfile';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const ProfileComponent = ({
  profile
}) => {
  useProfileRenderId();
  const [visible, togglePopover] = useToggleState();
  return _jsx(Popover, {
    visible: visible,
    onClickOutside: togglePopover.off,
    withArrow: true,
    placement: Popover.PLACEMENT.BOTTOM_RIGHT,
    content: () => _jsx(ProfileContent, {
      profile: profile,
      onClose: togglePopover.off
    }),
    kind: KIND.WORKSPACE,
    children: _jsx(ProfileTrigger, {
      profile: profile,
      visible: visible,
      togglePopover: togglePopover
    })
  });
};
ProfileComponent.displayName = "ProfileComponent";
const ProfileWithGqlProvider = () => _jsx(ProfileComponent, {
  profile: useProfile()
});
ProfileWithGqlProvider.displayName = "ProfileWithGqlProvider";
const mapToExpectedShape = profile => profile ? {
  email: profile.email_address || (getDefaultUserEmail(profile) || {}).email_address || '',
  displayName: profile.name || '',
  id: profile.id || '',
  displayIcon: profile.display_icon
} : null;
const ProfileWithCollectionizeProvider = () => useUserOrgAndApplicationStateFetched() ? _jsx(ProfileComponent, {
  profile: mapToExpectedShape(getAllLoggedInProfileDetails())
}) : null;

// TODO: This is a temporary solution until we have schema support for profiles on non-org pages.
export const Profile = () => {
  const hasFetchedUserOrgAndApplicationState = useUserOrgAndApplicationStateFetched();
  if (!hasFetchedUserOrgAndApplicationState) return null;
  return withinOrgAndWithSlug() ? _jsx(ProfileWithGqlProvider, {}) : _jsx(ProfileWithCollectionizeProvider, {});
};