import { SEMANTIC_ATTRIBUTE_SENTRY_SOURCE, captureException, getClient, startBrowserTracingNavigationSpan } from '@sentry/browser';
export const startNavigationTransaction = ({
  pattern,
  tags
}) => {
  try {
    const sentryClient = getClient();
    if (!sentryClient) {
      return;
    }
    startBrowserTracingNavigationSpan(sentryClient, {
      op: 'navigation',
      name: pattern ?? '__PATTERN_NOT_FOUND__',
      attributes: {
        ...tags,
        [SEMANTIC_ATTRIBUTE_SENTRY_SOURCE]: 'route'
      }
    });
  } catch (e) {
    captureException(e);
  }
};