import { Spaced } from '../Spaced';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const ButtonSet = ({
  children
}) => _jsx(Spaced, {
  horizontally: true,
  inline: true,
  amount: 12,
  children: children
});
ButtonSet.displayName = "ButtonSet";