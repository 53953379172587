import { Icon } from '@useshortcut/shapes-ds';
import { DeprecatedIconAdapter } from './DeprecatedIconAdapter';

/**
 * @deprecated Use <Icon icon="RemoveCircle" /> from @useshortcut/shapes-ds
 */
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const MinusCircle = ({
  width = '12',
  fill
}) => {
  return _jsx(DeprecatedIconAdapter, {
    width: width,
    fill: fill,
    children: _jsx(Icon, {
      icon: "RemoveCircle"
    })
  });
};
MinusCircle.displayName = "MinusCircle";