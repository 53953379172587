import { getProfileColor } from '@clubhouse/shared/utils';
import { UserTooltip } from '../UserTooltip';
import { AvatarContainer } from './AvatarContainer';
import { AvatarContent } from './AvatarContent';
import { SIZE } from './config';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export * from './config';
export const Avatar = ({
  withTooltip,
  profile,
  size,
  showEmail,
  hasBorder,
  style
}) => {
  const color = profile ? getProfileColor(profile) : undefined;
  const content = _jsx(AvatarContainer, {
    style: style,
    size: size,
    children: _jsx(AvatarContent, {
      fullName: profile?.name,
      size,
      hasBorder,
      color,
      imageUrl: profile?.display_icon?.url
    })
  });
  if (profile && withTooltip) {
    return _jsx(UserTooltip, {
      profile: profile,
      showEmail: showEmail,
      children: content
    });
  }
  return content;
};
Avatar.defaultProps = {
  size: SIZE.M,
  style: undefined,
  withTooltip: false,
  showEmail: false
};
Avatar.SIZE = SIZE;