import { ArchiveStoryActionFragmentDoc } from "../../../../../datalayer/__generated_graphql_types__/graphql";
import { useCallback } from 'react';
import { gql } from '@clubhouse/datalayer';
import { Emojify } from '@clubhouse/shared/components/Emojify';
import { MoreActions } from '@clubhouse/shared/components/MoreActions';
import { ToastText, addToast } from '@clubhouse/shared/components/Toast';
import { Nouns } from '@clubhouse/shared/constants';
import { isLoggedInUserObserver } from 'data/entity/user';
import { useArchiveStoryMutation } from '../hooks/useArchiveStoryMutation';
import { useStoryConfirmationDialog } from '../hooks/useStoryConfirmationDialog';
import { useUnarchiveStoryMutation } from '../hooks/useUnarchiveStoryMutation';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const ArchiveStoryActionFragment = ArchiveStoryActionFragmentDoc;
const showToast = (success, message) => {
  addToast({
    kind: success ? 'success' : 'alert',
    timeout: 5000,
    Content: () => _jsx(ToastText, {
      children: message
    })
  });
};
export const ArchiveStoryAction = ({
  story,
  aside
}) => {
  const {
    openDialog,
    closeDialog
  } = useStoryConfirmationDialog();
  const archiveStory = useArchiveStoryMutation();
  const unarchiveStory = useUnarchiveStoryMutation();
  const {
    id: storyId,
    archived,
    name
  } = story;
  const onArchive = useCallback(() => {
    openDialog('archive', {
      onClick: async () => {
        try {
          closeDialog();
          await archiveStory(storyId);
          showToast(true, _jsxs(_Fragment, {
            children: ["Story", ' ', _jsx("strong", {
              children: _jsx(Emojify, {
                children: name
              })
            }), ' ', "archived."]
          }));
        } catch {
          showToast(false, _jsxs(_Fragment, {
            children: ["Unable to archive", ' ', _jsx("strong", {
              children: _jsx(Emojify, {
                children: name
              })
            }), "."]
          }));
        }
      }
    });
  }, [archiveStory, closeDialog, openDialog, name, storyId]);
  const onUnarchive = useCallback(async () => {
    try {
      await unarchiveStory(storyId);
      showToast(true, _jsxs(_Fragment, {
        children: ["Story", ' ', _jsx("strong", {
          children: _jsx(Emojify, {
            children: name
          })
        }), ' ', "unarchived."]
      }));
    } catch {
      showToast(false, _jsxs(_Fragment, {
        children: ["Unable to unarchive", ' ', _jsx("strong", {
          children: _jsx(Emojify, {
            children: name
          })
        }), "."]
      }));
    }
  }, [storyId, name, unarchiveStory]);
  return _jsxs(MoreActions.Item, {
    icon: "Archive",
    onClick: archived ? onUnarchive : onArchive,
    isDisabled: isLoggedInUserObserver(),
    aside: aside,
    children: [archived ? 'Unarchive' : 'Archive', " ", Nouns.Story.singular]
  });
};
ArchiveStoryAction.displayName = "ArchiveStoryAction";