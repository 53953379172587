import Globals from '../_frontloader/globals';
import Iterate from './iterate';
import _ from 'lodash';
const exports = {};
const KEY = 'c3Charts';
exports.getCharts = () => {
  return Globals.setOnlyIfMissing(KEY, {});
};
exports.saveChart = (name, chartObj) => {
  const charts = exports.getCharts();
  charts[name] = chartObj;
};
exports.getChart = name => {
  const charts = exports.getCharts();
  return charts[name];
};
exports.refresh = name => {
  const charts = exports.getCharts();
  if (charts[name] && _.isFunction(charts[name].flush)) {
    charts[name].flush();
  }
};
exports.destroy = name => {
  const charts = exports.getCharts();
  if (charts[name] && _.isFunction(charts[name].destroy)) {
    charts[name] = charts[name].destroy();
  }
};
exports.destroyAll = () => {
  const charts = exports.getCharts();
  Iterate.each(_.keys(charts), name => {
    exports.destroy(name);
  });
};
export { exports as default };