import { EPIC, useEntitySelected, useEntityStateFilter, WORKFLOW } from './utils';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export function EmptyMessage({
  stories
}) {
  if (!stories) return null;
  const [entity] = useEntitySelected();
  const [entityStateFilter] = useEntityStateFilter();
  const isEpic = entity === EPIC && entityStateFilter.epicId && !stories.length;
  const isWorkflow = entity === WORKFLOW && entityStateFilter.workflowTeamId && !stories.length;
  return isEpic || isWorkflow ? _jsx("div", {
    className: "empty-state-message",
    children: _jsx("p", {
      children: "No stories match this criteria."
    })
  }) : null;
}