import "core-js/modules/es.array.push.js";
export const getCenteredRange = ({
  center,
  min = 0,
  max,
  size
}) => {
  if (size === 0 || min > max || center < min || center > max) return [];
  const actualSize = Math.min(max - min + 1, size); // + 1 because the max is inclusive, so min=1, max=2 should be 2.
  const valuesPerSide = Math.floor(actualSize / 2);
  const values = [];
  for (let offset = -valuesPerSide; offset <= valuesPerSide && values.length < actualSize; ++offset) {
    values.push(center + offset);
  }
  while (values[0] < min) {
    values.shift();
    values.push(values[values.length - 1] + 1);
  }
  while (values[values.length - 1] > max) {
    values.pop();
    values.unshift(values[0] - 1);
  }
  return values;
};